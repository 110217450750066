import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar = (props) =>{
    const percentage = props.percentage;
    //console.log("CirclePercentage::",percentage)
    let pathcolor;

    if (percentage < 20) {
        pathcolor = '#dc3545';
    } else if (percentage < 40) {
        pathcolor = 'orangered';
    } else if (percentage < 60) {
        pathcolor = '#ffc107';
    }else if (percentage <= 100) {
        pathcolor = '#198754';
    }
    return (
        <div style={{ width: 65, height: 65 }}>
          <CircularProgressbar
          
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          textColor: "white",
          pathColor: `${pathcolor}`,
          
          trailColor: "gray",
          backgroundColor: '#3e98c7',
          
        })}
      />
      
      </div>
    )
};

export default CircularProgressBar;