import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAxios, convertReadable, convertReadableTime, convertSecondsToMinutes } from "../utils/utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { environment } from "../environment";




const Card = ({ item, id, user, active, setActive, setCallDetails, setDetailsLoading, startDate }) => {
  // console.log(id, item.leadid)

  const navigate = useNavigate();

  useEffect(() => {
    if (id === 0) {
      getCallDetails(item)
    }
  }, [])
  let setDetails = []
  const getCallDetails = (item) => {
    console.log(item)
    setActive(id);
    setDetailsLoading(true);
    // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/get-call-details-by-id", { leadId: leadid,topic:topic,userType:localStorage.getItem("userRole"), email:user?.email }, "post").then((callDetails) => {
      setDetails.push(item)
      setCallDetails(setDetails);
      setDetailsLoading(false);
    // })
  }

  return (

    <>
      <div className={`client-card rounded p-3 mt-2 pointer ${id === active ? "selected" : ""}`} key={id} onClick={() => getCallDetails(item)}>
        {/* <ToastContainer /> */}
        <div className="d-flex justify-content-between gap-2 mb-2">
          <div className="left-cover">
            {item.name ?
            <div className="">
            <div className="client-name fs-5 text-warning text-capitalize">{item.name.split("-")[0]}</div>
              {/* <div className="description small text-info">{item.topic}</div> */}
            </div>
              
              : null}
             
            {item.recording_date_time ?
              <div className="fs-6 text-white text-opacity-75 text-capitalize">{convertReadable(item.recording_date_time)} <span>{convertReadableTime(item.recording_date_time)}</span></div>
              : null}
          </div>
          
        </div>
        {/* {item.new_skillsetjobdescription ?
          <div className="requrement-wrapper pe-4">

            <div className="client-location fs-6 text-light mb-1">Requirement Details</div>
            <div className="description small text-light text-opacity-75 ">{item.new_skillsetjobdescription}</div>

          </div> : null} */}
       
        
      </div>

    
    </>




  )
}

export default Card;