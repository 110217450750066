import React, { useState } from "react";
// import Select from 'react-select';
import imgDefault from '../resources/images/Avatar.png';
import LeaveModal from "../modals/LeaveModal";
import { render } from "@testing-library/react";


const Data = [
    {
        img: "https://mdbootstrap.com/img/new/avatars/8.jpg",
        name: "Abishek Kumar",
        status: "active"
    },
    {
        img: imgDefault,
        name: "Vasheem Ahmad",
        status: "away",
    },
    {
        img: "https://mdbootstrap.com/img/new/avatars/8.jpg",
        name: "Kate Hunington",
        status: "offline"
    },
    {
        img: imgDefault,
        name: "Abishek Kumar Batham",
        status: "call"
    },
    {
        img: "https://mdbootstrap.com/img/new/avatars/8.jpg",
        name: "Miley Cyrus",
        status: "away"
    },
    {
        img: imgDefault,
        name: "Fahriye Evcen",
        status: "active"
    },
]

export const GroupParticipants = (props) => {
    const handleLeave = () =>{
       // console.log("In discard modal")
        render (<LeaveModal showModal={true}/>)
    }
    return (
        <div className="group-participants add-participants card rounded-0 position-absolute end-0 shadow" style={{maxWidth:'300px'}}>
            <div className="list-wrap flex-fill overflow-auto my-1 participant-list" id="group-participants-list" style={{maxHeight: '300px'}}>
                
            </div>
            <div className="list-wrap flex-fill border-top py-2">
                <div className="user-card list pointer px-3 py-1" onClick={props.open}><i className="bi bi-person-add d-inline-block fs-5 text-center lh-1 me-2" style={{width:'30px'}}></i>Add People</div>
                <div className="user-card list pointer px-3 py-1 " onClick={handleLeave}><i className="bi bi-box-arrow-left d-inline-block fs-18 text-center lh-1 me-2" style={{width:'30px'}}></i>Leave</div>
            </div>
            
        </div>
    )
}

// {Data.map((user, id) => (
//     <div className="user-card list d-flex align-items-center justify-content-between gap-2 pointer px-3 py-2" key={id}>
//         <div className="w-75 d-flex align-items-center gap-2">
//             <div className="user-pic position-relative">
//                 <img src={user.img} alt="User" className="user-chip" />
                    
                
//                 <div className="user-status position-absolute z-1">
//                     {user.status === 'active' ? <span className="bg-success text-white"><i className="bi bi-check-circle"></i></span> : ''}
//                     {user.status === 'away' ? <span className="bg-warning text-white"><i className="bi bi-clock"></i></span> : ''}
//                     {user.status === 'offline' ? <span className="bg-white text-muted"><i className="bi bi-x-circle"></i></span> : ''}
//                     {user.status === 'call' ? <span className="bg-danger text-danger"><i className="bi bi-circle-fill"></i></span> : ''}
//                 </div>
//             </div>
//             <div className="user-detail text-nowrap text-truncate">
//                 <div className="user-name text-capitalize text-truncate lh-sm">{user.name}</div>
//             </div>
//         </div>

//         <div className="remove_icon flex-fill text-muted text-end"><i className="bi bi-x-lg fs-6"></i></div>
//     </div>
// ))}
