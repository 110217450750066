import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, database, auth } from "../firebaseConfig";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";
import $ from "jquery";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from 'react-bootstrap/Modal';

export const LogoutModal = ({days, lastLogin}) => {
    //console.log("---->",lastLogin);
    const title = "Why have you been logged into the system for the past "+days+" hours?";
    const labelValue = "I have been available throughout these "+days+" hours dedicatedly";
    const [modalShow, setModalShow] = useState(true);
    const handleClose = () => {
        setModalShow(false)
       
    };
//   $(".modal-backdrop").css("--bs-backdrop-opacity", "1");
 
  
  const [user, loading, error] = useAuthState(auth);
  //console.log(user)
  /* Cam states */
  const [otherDescription, setOtherDescriptionText] = useState('');
  const [optionValue, setOptionValue] = useState('');

   const logoutHandler = async (e) => {
    e.preventDefault();
    $("#logout-submit-button").addClass("disabled");
    $("#force-loader").removeClass("d-none");
    
     const formData = {
        uid: user?.uid,
        email:user?.email,
        question:title,
        answer:optionValue,
        other_reason:otherDescription,
        log_in:lastLogin
     };

          callAxios(
                environment.REACT_APP_API_URL_Live +
                  "api/v1/attendance/save-logout-reason",
                { formData: formData },
                "post"
              ).then(async(response) => {
                if (response.data.status === 200) {
                
                    await callAxios(
                        environment.REACT_APP_API_URL_Live + "api/v1/attendance/login-logout",
                        {
                          uid: user?.uid,
                          action: "Check-Out",
                          email: user?.email,
                          display_name: user?.displayName,
                        },
                        "post"
                      );

                    localStorage.setItem("userRole", undefined);
                    localStorage.setItem("camJoinedLead", null);
                    localStorage.setItem("bdmJoinedLead", null);
                    localStorage.setItem("checkAudio", null);
                    await auth.signOut();
                //   toast.success("Feedback submitted!", {
                //     position: "top-right",
                //     autoClose: 2000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //   });
                } else {
                //   toast.error("something went wrong!", {
                //     position: "top-right",
                //     autoClose: 2000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //   });
                 }
                
             })
  };

 const selectedOther = (value) =>{
    document.getElementById("logout-submit-button").classList.remove("d-none");
    //console.log(value)
    setOptionValue(value)
 }



  return (
    <div>
      {/* <ToastContainer /> */}
   
      {/* CAM Question After Call Section */}
      <div className="cam-question-after-call">
      <Modal show={modalShow} onHide={() => setModalShow(false)} backdrop="static" keyboard={false} size="md" centered contentClassName="shadow text-body" dialogClassName="">
        {/* <div
          className="modal fade"
          id="forceLlogout"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="camQuestionModalLabel"
          aria-hidden="true"
        > */}
          {/* <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"> */}
            {/* <div className="modal-content text-body"> */}
              <form
                id="forceLlogout"
                className="d-flex flex-column overflow-hidden"
                method="post"
                onSubmit={logoutHandler}
              >
                <div className="modal-header bg-primary text-white px-lg-5">
                  <h1 className="modal-title fs-5"><span className="loader-force-logout d-none" id="force-loader"></span></h1>
                  <div
                    id="force-logout-form"
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      marginTop: "10px",
                      display: "none",
                    }}
                  >
                    <div
                      className="meeting-pointer-spinner"
                      style={{ marginRight: "10px" }}
                    ></div>
                    Please wait ....
                  </div>
                  <button
                    type="submit"
                    id="logout-submit-button"
                    className="btn btn-sm rounded-pill  btn-success border-white d-none"
                    style={{ minWidth: "80px" }}
                  >
                    Logout
                  </button>
                
                </div>
                <div className="modal-body px-lg-5 py-4 small">
                 

                  <div className="mb-3">
                    <label className="form-label fw-medium" id="pastWorkingHours">{title}</label>
                    <div className="check-wrapper">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="camRadios1"
                          id="camRadios1"
                          value="I forgot to log out"
                          required
                          onClick={(e) => {
                            selectedOther(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="camRadios1">
                        I forgot to log out
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="camRadios1"
                          id="camRadios2"
                          value={labelValue}
                          required
                          onClick={(e) => {
                            selectedOther(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="camRadios2" id="option2available">
                       {labelValue}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="camRadios1"
                          id="other-reason"
                          value="other"
                          required
                          onClick={(e) => {
                            selectedOther(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="camRadios3">
                        Other
                        </label>
                      </div>
                    </div>
                  </div>

                

                  <div className="mb-3" id="logoutReasonTextArea">
                    {optionValue === "other"?
                    <textarea
                      className="form-control"
                      id="logoutReasonText"
                      rows="4"
                      placeholder=""
                      required
                      onChange={(e) => {
                        setOtherDescriptionText(e.target.value);
                      }}
                    ></textarea>
                    :null}

                  </div>
                </div>
              </form>
            {/* </div> */}
          {/* </div> */}
        </Modal>
      </div>
    </div>
  );
};
