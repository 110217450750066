import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Loader } from '../Components/Loader';
import { EditOptionYes } from './EditOptionYes';
import { EditOptionNo } from './EditOptionNo';
import { EditYesIntern } from './EditYesIntern';
import { EditNoIntern } from './EditNoIntern';
import { environment } from '../../environment';
import { getEmploymentData, getProfileData } from '../../utils/utils';
import WarningModal from '../Components/Warningmodal';

export const EditEmployment = ({ editEmploymentModal, closeEditEmploymentModal, sendEmploymentdata, empdataload, toasterData,profileId, setEditEmploymentModal,setProfileData, profileData, setIsLoading,employmentData, setEmploymentData, setCurrempdata }) => {
    // const getUserId = process.env.REACT_APP_USER_ID
    // console.log(profileId)
    const [curryes, setCurryes] = useState(false)
    const [currno, setCurrno] = useState(false)
    const [empfull, setEmpfull] = useState(true)
    const [empintern, setEmpintern] = useState(false)
    const [showdata, setshowdata] = useState('yesFulltime')
    const [loading, setLoading]=useState(false)
    const [getskilldata, setSkilldata] =  useState([])
    const [removeId, setRemoveId] = useState([]);
    const [remainingCharacters, setRemainingCharacters] = useState(4000);
    const [formData, setFormData] = useState({
        formOneData: {},
        formTwoData: {},
        formThreeData: {},
        formFourData: {}
    });
    const [employmentType, setEmploymentType] = useState('fulltime');
    const selectOption = (value) => {
       
        if(value == 'yes'){
            setCurryes(true)
            setCurrno(false)
        }
        if(value == 'no'){
            setCurrno(true)
            setCurryes(false)
        }
    }

    const selectOptionEmpType = (value) => {
        setEmploymentType(value);
        
        
    }

    const functoset = (skillData) => {
        console.log(skillData)
        setSkilldata(skillData) 
    }
    // skillsForm

    const handleFormChange = (e, formName) => {
        // const { name, value } = e.target;
        // setFormData((prevData) => ({
        //   ...prevData,
        //   [formName]: { ...prevData[formName], [name]: value }
        // }));
        if (e && e.target && e.target.name) {
            const { name, value } = e.target;
           
            setFormData((prevData) => ({
              ...prevData,
              [formName]: { ...prevData[formName], [name]: value }
            }));
          } else {
            console.error("Invalid event or event target in handleFormChange");
          }
    };
    // skillsForm

    // convertYear
    const convertYear = (getdate, type) => {
        const dateString = `${getdate}`;
        const dateComponents = dateString.split('-');
        console.log(dateString)
        if(type == 'year'){
            const year = dateComponents[0];
            return year
        }
        if(type == 'month'){
            const month = dateComponents[1];
            return month
        }
    }
   

    // editEmp
    const updateEmp = async(postData)=>{
        // setLoading(true)
        try {
            postData["removeIds"] = removeId
            setIsLoading(true)
            setEditEmploymentModal(false)
			const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/update-delete-employment`, postData, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const responsedata = response?.data
            console.log("outside statuscode")
			if (responsedata?.statusCode === 200) {
                console.log("inside statuscode")
                // setLoading(false)
                const sendMsg = responsedata?.message
                const getData = await getEmploymentData(profileId, setIsLoading)
                setProfileData(getData.profileData);
                setEmploymentData(getData.employmentData)
                const getiscurremp = getData.employmentData
            const currentEmployments = getiscurremp.filter(employment => employment.is_current_employment === 1);

            // Find the employment with the highest id among the filtered employments
            const maxId = Math.max(...currentEmployments.map(employment => employment.id));

            // Filter again to get the employment with the maximum id value
            const highestEmployment = currentEmployments.find(
                employment => employment.id === maxId
            );
            console.log(highestEmployment)
            setCurrempdata(highestEmployment)
                setRemoveId([])
                toast.success(sendMsg, {
					position: 'bottom-center'
				})
				
                
			}
			
		} catch (error) {
            setProfileData(await getProfileData(profileId,setIsLoading));
			console.log('Error: ', error)
		}
    }

    const refreshbtn = () => {
        if(sendEmploymentdata?.is_current_employment === 1){
            setCurryes(true)
            setCurrno(false)
        }
           
        if(sendEmploymentdata?.is_current_employment === 0){
            setCurryes(false)
            setCurrno(true)
        }
            
    }

    useEffect(() => {
        if(sendEmploymentdata?.is_current_employment === 1){
            setCurryes(true)
            setCurrno(false)
        }
            
        if(sendEmploymentdata?.is_current_employment === 0){
            setCurryes(false)
            setCurrno(true)
        }
            
    }, [sendEmploymentdata]);

    
// latestAdd
    const [showModal, setShowModal] = useState(false);
    const [textmessage, setTextmessage] = useState('');
    const [deleteEmp, setDeleteEmp] = useState('')
    // latestAdd

    const deleteemployment = () => {
        setShowModal(true)
        setTextmessage('Are you sure you want to delete this employment?')
        const sendData = {
            "profileId":profileId ? profileId : '',
            "employementId":sendEmploymentdata?.id,
            "type":"delete"
        }
        setDeleteEmp(sendData)
    }
    const handleCloseModal = () => {
        setShowModal(false)
    }

    const showError = () =>{
        const mesArea = document.getElementById("showEmploymentError");
        if(mesArea)
            mesArea.innerHTML = "Changing employment type is not possible. Delete this and add a new one from profile page."
    }

    console.log("updated employment Type::",employmentType, curryes)

	return (
		<Modal show={editEmploymentModal} centered size='lg' onHide={closeEditEmploymentModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
            <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
				<div className="close-wrapper text-end mb-2">
					<Button variant="link" onClick={() => {
            closeEditEmploymentModal();
            refreshbtn();
            // Call other functions here
        }} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
				</div>
                <WarningModal show={showModal} onClose={handleCloseModal} textmessage={textmessage} updateEmp={updateEmp} deleteEmp={deleteEmp} />

                <div className="heading-wrapper mb-4">
					<div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
						<span className='widgetTitle text-black fs-5 fw-semibold'>Employment 
                        {employmentData && employmentData.length === 0?
                        <span className='profileComScore fs-6 fw-normal text-success ms-1'>Add 8%</span>
                        :null}
                        </span>
						
						{employmentData && employmentData?.length > 0 && employmentData[0].company_name != null?
							<span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0 pointer' onClick={() => deleteemployment(true)} >Delete</span>
                            :null}
							
					</div>
					<p className='subTitle fs-14'>Details like job title, company name, etc, help employers understand your work</p>
				</div>
					<Form.Group className="mb-3">
						<Form.Label className="mb-3">Is this your current employment?</Form.Label>
						<div className='radio-wrapper'>
                            {/* {console.log(currno)} */}
                            <Form.Check inline type="radio" className='w-25' id='Yes' name='currentEmployment' label='Yes' checked={curryes === true ? true : false} onClick={() => selectOption('yes')}/>
							<Form.Check inline type="radio" className='w-25' id='No' name='currentEmployment' label='No' checked={currno === true ? true : false} onClick={() => selectOption('no')}/>
						</div>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label className="mb-3">Employment type</Form.Label>
                        <div className="skills-wrapper d-flex flex-wrap gap-2 mt-3">
                      
                       
                    
                    </div>
                    {employmentData && employmentData?.length > 0 && employmentData[0].company_name === null?
						
                        <div className='radio-wrapper'>
							<Form.Check inline type="radio" className='w-25' id='Full-time' name='employmentType' label='Full-time' checked={employmentType === "fulltime"} required onClick={() => selectOptionEmpType('fulltime')}/>
							<Form.Check inline type="radio" className='w-25' id='Internship' name='employmentType' label='intern' checked={employmentType ==="intern"} onClick={() => selectOptionEmpType('intern')}/>
						</div>
                            :
                            <div className='radio-wrapper'>
                            <div>
                            {sendEmploymentdata?.employment_type === 'Full-time' && (
                                <>
                                 <button key="full-time" type='button' className='btn btn-sm btn-secondary bg-opacity-50 rounded-pill py-1 px-3' onClick={showError}>Full time</button>
                                    {/* <Form.Check 
                                        inline 
                                        type="radio" 
                                        className='w-25' 
                                        id='Full-time' 
                                        name='employmentType' 
                                        label='Full-time' 
                                        required 
                                        checked={true} 
                                        onClick={() => selectOption('fulltime')}
                                    />                                     */}
                                </>
                            )}

                            {sendEmploymentdata?.employment_type === 'Internship' && (
                                <>
                                 <button key="full-time" type='button' className='btn btn-sm btn-secondary bg-opacity-50 rounded-pill py-1 px-3' onClick={showError}>Internship</button>
                                    {/* <Form.Check 
                                        inline 
                                        type="radio" 
                                        className='w-25' 
                                        id='Internship' 
                                        name='employmentType' 
                                        label='Internship'  
                                        checked={true} 
                                        onClick={() => selectOption('intern')}
                                    /> */}
                                </>
                            )}

                            {sendEmploymentdata === '' && (
                                <>
                                    <Form.Check 
                                        inline 
                                        type="radio" 
                                        className='w-25' 
                                        id='Full-time' 
                                        name='employmentType' 
                                        label='Full-time' 
                                        required 
                                        checked={empfull ? true : false} 
                                        onClick={() => selectOption('fulltime')}
                                    />
                                    <Form.Check 
                                        inline 
                                        type="radio" 
                                        className='w-25' 
                                        id='Internship' 
                                        name='employmentType' 
                                        label='Internship'  
                                        checked={empintern ? true : false} 
                                        onClick={() => selectOption('intern')}
                                    />
                                </>
                            )}
                           
                            </div>
                            <span id='showEmploymentError' className='text-warning'></span>
                            </div> 
                        }
					</Form.Group>
                    {employmentType === "intern" && curryes === true?
                    <EditYesIntern sendmydata={sendEmploymentdata !== '' ? sendEmploymentdata : ''} getskills={functoset} closeEditEmploymentModal={closeEditEmploymentModal} updateEmp={updateEmp} isCurrentEmployement={curryes} profileId={profileId}/>
                    :
                    sendEmploymentdata?.employment_type === "Full-time" && curryes === true ? 
                        <EditOptionYes sendmydata={sendEmploymentdata !== '' ? sendEmploymentdata : ''} getskills={functoset} closeEditEmploymentModal={closeEditEmploymentModal} updateEmp={updateEmp}  isCurrentEmployement={curryes} profileId={profileId} profileData={profileData} employmentData={employmentData} setRemoveId={setRemoveId} setRemainingCharacters={setRemainingCharacters} remainingCharacters={remainingCharacters} /> 
                        : (sendEmploymentdata?.employment_type === "Full-time" && curryes === false ? 
                            <EditOptionNo sendmydata={sendEmploymentdata !== '' ? sendEmploymentdata : ''} getskills={functoset} closeEditEmploymentModal={closeEditEmploymentModal} updateEmp={updateEmp} isCurrentEmployement={curryes} profileId={profileId} setRemainingCharacters={setRemainingCharacters} remainingCharacters={remainingCharacters} employmentData={employmentData}/>
                            : (sendEmploymentdata?.employment_type === "Internship" && curryes === true ? 
                                <EditYesIntern sendmydata={sendEmploymentdata !== '' ? sendEmploymentdata : ''} getskills={functoset} closeEditEmploymentModal={closeEditEmploymentModal} updateEmp={updateEmp} isCurrentEmployement={curryes} profileId={profileId}/>
                                
                                : (sendEmploymentdata?.employment_type === "Internship" && curryes === false ? 
                                    <EditNoIntern sendmydata={sendEmploymentdata !== '' ? sendEmploymentdata : ''} getskills={functoset} closeEditEmploymentModal={closeEditEmploymentModal} updateEmp={updateEmp} isCurrentEmployement={curryes} profileId={profileId}/>
                                    : null
                                )
                            )
                        )
                    }

			</Modal.Body>
		</Modal>
	)
}
