import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { environment } from '../environment';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const wsRef = useRef(null);
  const reconnectAttempts = useRef(0);

  const connectWebSocket = () => {
    wsRef.current = new WebSocket(environment.SOCKET_URL);

    wsRef.current.onopen = () => {
      console.log('Centralized WebSocket connection initiated');
      reconnectAttempts.current = 0; // Reset reconnection attempts on successful connection
      setInterval(() => {
        if (wsRef.current.readyState === WebSocket.OPEN) {
          wsRef.current.send(JSON.stringify({ event: 'keepAlive', status: 'keep-alive' }));
        }
      }, 10000); // 10 seconds
    };

    wsRef.current.onclose = () => {
      console.log('WebSocket connection closed');
      attemptReconnect();
    };

    wsRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
      wsRef.current.close();
    };

    setSocket(wsRef.current);
  };

  const attemptReconnect = () => {
    const maxReconnectAttempts = 100;
    if (reconnectAttempts.current < maxReconnectAttempts) {
      reconnectAttempts.current += 1;
      const reconnectDelay = Math.min(1000 * (2 ** reconnectAttempts.current), 30000); // Exponential backoff, max 30 seconds
      setTimeout(() => {
        console.log(`Attempting to reconnect... (${reconnectAttempts.current})`);
        connectWebSocket();
      }, reconnectDelay);
    } else {
      console.log('Max reconnect attempts reached. Please check your connection.');
    }
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      wsRef.current && wsRef.current.close();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={wsRef.current}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
