import React from "react";
import "./BackgroundSetting.css";

import { connect, useSelector } from "react-redux";

import {
  setBackgroundImage,
  setIsRemovedBackground,
  setBackgroundPreviewImage,
  setVideoTrack,
} from "../../store/actions";

import {
  blurBackground,
  imageBackground,
  changeImageBackground,
  RemovedBackground,
  previewImageBackground,
} from "../../utils/twilioUtils";
const { createLocalVideoTrack } = require("twilio-video");

const BackgroundSetting = (props) => {
  const mainImage = useSelector((state) => state.bgImage);
  const selectedBgImagePrev = useSelector((state) => state.bgImagePrev);
  //const imageIndex = useSelector((state) => state.index);

  // console.log(
  //   "Background Prev Image:",
  //   selectedBgImagePrev,
  //   " | main Image:: ",
  //   mainImage
  // );

  const {
    setBackgroundImageAction,
    setIsRemovedBackgroundAction,
    setBackgroundPreviewImageAction,
    setVideoTrackAction,
  } = props;
  // if(props.comingFrom || props.comingFrom === "Bdm"){
  //   // virtual-bg.png
  //   localStorage.setItem("setLocalImg", "virtual-bg.png");
  // }
  const backgrounds = [
    {
      imagePath: require("../../resources/background/blur-image.jpg"),
      imageName: "blur-image.jpg",
    },
    {
      imagePath: require("../../resources/background/virtual-bg.png"),
      imageName: "virtual-bg.png",
    },
    {
      imagePath: require("../../resources/background/1.jpg"),
      imageName: "1.jpg",
    },
    {
      imagePath: require("../../resources/background/2.jpg"),
      imageName: "2.jpg",
    },
    {
      imagePath: require("../../resources/background/3.jpg"),
      imageName: "3.jpg",
    },
    {
      imagePath: require("../../resources/background/4.jpg"),
      imageName: "4.jpg",
    },
  ];

  //document.getElementById("bg-"+imageIndex).classList.add('bg_selected_box')
  // Close Sidebar
  function closeBackSettingSidenav() {
    document.getElementById("BgSettingSidenav").style.transform =
      "translateX(300px)";
    document.getElementById("main").style.marginRight = "0";
  }

  const previewBackgroundBox = async (image) => {
    const track = await createLocalVideoTrack();
    const localMediaContainer = document.getElementById("video_portal_preview");
    localMediaContainer.innerHTML = "";
    if(track){
      localMediaContainer.appendChild(track.attach());
    if (typeof localStorage.getItem("setLocalImg") !== "undefined") {
      if(image === "blur-image.jpg"){
        blurBackground(track); 
      }
      else {
        previewImageBackground(track, image);
      }
      
    }
    if (typeof localStorage.getItem("setLocalImg") == "undefined") {
      RemovedBackground(track, image);
      //setVirtualBackgroundPreview();
    }
    }
    
  };
  const setPrevIndex = (index) => {
    localStorage.setItem("previousIndex", index);
    document.getElementById("preview-btn").classList.remove("btn-secondary");
    document.getElementById("preview-btn").classList.add("btn-primary");
  };
  const getBackgroundImageHandler = (image, index) => {
    const prevIndex = localStorage.getItem("previousIndex");
    //console.log(prevIndex, "-Current:", index);
    if( prevIndex !=null){
      if (index !== prevIndex) {
        document
          .getElementById("bg-" + prevIndex)
          .classList.remove("bg_selected_box");
      }
    }
    

    document.getElementById("bg-" + index).classList.add("bg_selected_box");
    //document.getElementById("bgImage").value = image;
    localStorage.setItem("setLocalImg", image);
    // console.log("BG-Image::", image);
    // setBackgroundPreviewImageAction(image);

    // setBackgroundImageAction(image);
    //setIsRemovedBackgroundAction(false);
  };

  const showVideoDiv = () => {
    document.getElementById("video_portal_preview").style.display = "block";
    var videoTag = document.getElementsByTagName("video");
    videoTag[0].style.display = "block !important";
  };

  const setVirtualBackgroundPreview = () => {
    const image = localStorage.getItem("setLocalImg");
    // console.log("image::::-->", image);
    previewBackgroundBox(image);
    document.getElementById("virtualBackground-Apply").style.display='block';
    //if (typeof selectedBgImagePrev !== "undefined") {

    //}
    //PreviewBackground(selectedBgImagePrev);
  };

  const virtualBackgroundApply = () => {
    const localBgImage = localStorage.getItem("setLocalImg");
    // console.log("local Apply Image to main:", localBgImage);
    setBackgroundImageAction(localBgImage);
    setBackgroundPreviewImageAction(localBgImage);
    setIsRemovedBackgroundAction(false);
  };
// console.log("backgroundSetting::===>",props.comingFrom)
  return (
    <div className="video_bg_container d-flex flex-column justify-content-between h-100">
      <div
        className="video_label_container d-flex align-items-center justify-content-between gap-3 text-white lh-1 border-bottom border-black px-3 py-3"
        onClick={closeBackSettingSidenav}
      >
        <p className="video_label_paragraph text-white fw-semibold mb-0">
          Background Setting
        </p>
        <span className="pointer">
          <i className="bi bi-x fs-2"></i>
        </span>
      </div>
      <div className="lead_details_container d-flex flex-column justify-content-between overflow-auto h-100 p-3">
        <div className="bg_setting_wrapper">
          {backgrounds.filter(function(img) {
  if (img.imageName === "virtual-bg.png" && props.comingFrom === false) {
    return false; // skip
  }
  return true;
}).map((image, index) => (
           
            <div
              className={`${
                localStorage.getItem("setLocalImg") === image.imageName &&
                typeof mainImage !== "undefined"
                  ? "bg_selected_box"
                  : null
              } bg_item border border-2 border-secondary rounded-1 overflow-hidden`}
              key={index}
              id={"bg-" + index}
              onClick={() => {
                getBackgroundImageHandler(image.imageName, index);
                setPrevIndex(index);
              }}
            >
              {localStorage.getItem("setLocalImg") !== "undefined" && localStorage.getItem("setLocalImg") === image.imageName ? (
                <i className="bi bi-check fs-2 selectedTick"></i>
              ) : null}
               
              <img
                src={image.imagePath}
                className="object-fit-cover img-fluid"
                alt="Shared Screen Preview"
              />
              
            </div>
          ))}
        </div>

        <div className="bg_bottom_cover d-grid gap-2 sticky-bottom pt-4">
          {/* Start: Show this when apply for preview */}
          <div className="bg_setting_preview" id="video_portal_preview">
            {/* <img
              src={require("../../resources/images/Introduction-Page.png")}
              className="object-fit-cover img-fluid"
              alt="Shared Screen Preview"
            /> */}
          </div>
          {/* End: Show this when apply for preview */}
          <div className="label-text small">
            <i className="bi bi-exclamation-circle me-2"></i>Others won't see your
            video while you preview.
          </div>
          <button
            className="btn btn-secondary rounded-1 fw-semibold"
            id="preview-btn"
            onClick={() => {
              showVideoDiv();
              setVirtualBackgroundPreview();
            }}
          >
            Preview
          </button>

          <input type="hidden" value="" id="bgImage" />
          <button
            className="btn btn-primary rounded-1 fw-semibold appy-btn"
            id="virtualBackground-Apply"
            onClick={() => virtualBackgroundApply()}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBackgroundImageAction: (image) => dispatch(setBackgroundImage(image)),
    setIsRemovedBackgroundAction: (isremoved) =>
      dispatch(setIsRemovedBackground(isremoved)),
    setBackgroundPreviewImageAction: (image) =>
      dispatch(setBackgroundPreviewImage(image)),
    //setVideoTrackAction: (track) => dispatch(setVideoTrack(track)),
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(BackgroundSetting);