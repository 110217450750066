import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const LeaveModal = ({showModal}) =>{
    //console.log(showModal)
    const [isEditMessage, setIsEditMessage] = useState(true);
    const closeEditMessage = () => setIsEditMessage(false);
      // For Discard model
      const [modalShow, setModalShow] = useState(showModal);
      const handleClose = () => {
          setModalShow(false)
          setIsEditMessage(false)
      };

    return (
        <>
            <Modal show={modalShow} onHide={() => setModalShow(true)} size="md" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="shadow p-2" dialogClassName="p-4">
                <Modal.Body className="p-4">
                    <div className='text-dark fs-18 fw-semibold'>Leave the conversation?</div>
                    <div className="add-participants btn-wrapper d-flex gap-2 justify-content-end mt-3">
                        <Button variant='danger' size='sm' onClick={() => setModalShow(false)} className='shadow cancel-leave-conversation'>Cancel</Button>
                        <Button variant='primary' size='sm'  className='shadow leave-conversation'>Leave</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LeaveModal;