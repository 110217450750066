import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  fetchData,
  fetchDataRefresh,
  fetchNewEmails,
  handleItemClick,
  resetForm,
} from "../utils/emailUtils";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useWebSocket } from "../Context/WebSocketContext";

export const EmailListing = ({setShowCurrentEmail, setCurrentEmail, setCurrentEmailBody, setAttachments, setEmailsById, setNewClick, setInboxClick, setEmailType, emailType, setIsLoading, isLoading, setShowReply, setEmails, emails, emailListLoading, setEmailListLoading}) => {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  // Initialize state to track the active menu
  const [activeMenu, setActiveMenu] = useState(0);

  // For Sidebar list selected card
  const [active, setActive] = useState(0);

  // Handler function to update the active Menu
  const handleClick = (index) => {
    setActiveMenu(index);
  };

  const newMailPage = () => {
    navigate("/new-mail");
  };
  const [isActive, setIsActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  

  const [accessToken, setAccessToken] = useState([]);
  // console.log(user)
  // for issue listing
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
 
 


  const [description, setDescription] = useState("");
  const [issues, setIssues] = useState([]);
  // end issue listing
  const [show, setShow] = useState(false);
  


  const [isSaving, setIsSaving] = useState(false);

  const [noResults, setNoResults] = useState(true);
  const [midCntr, setMidCntr] = useState(false);
  const [pagedata, setPagedata] = useState("");

  // For Add Candidate Model
  const handleClose = () => {
    setShow(false);
    resetForm();
  };
  const handleShow = () => setShow(true);
  const [base64String, setBase64String] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState("");
 
  const [hasAttachments, setHasAttachments] = useState(false);

  // const [showReply, setShowReply] = useState(false);
  const handleReplyClose = () => {
    setShowReply(false);
    resetForm(setSubject, setEmail, setDescription, setFile, setBase64String, setFileName, setFileType);
  };
  const handleReplyShow = () => setShowReply(true);

 
  const messagesEndRef = useRef(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [lastEmailTime, setLastEmailTime] = useState(new Date().toISOString());
  const [lastEmailId, setLastEmailId] = useState("");


  useEffect(() => {
    // unsubscribe();
    fetchData(
      emailType,
      limit,
      page,
      setEmails,
      setIsLoading,
      setShowCurrentEmail,
      setCurrentEmail,
      setCurrentEmailBody,
      setSelectedItem,
      setPage,
      setEmailType,
      setLastEmailTime,
      setLastEmailId,
      setMidCntr,
      setPagedata,
      setEmailListLoading
    );
    
  }, [page,emailType]);


const [scrollHandled, setScrollHandled] = useState(false);

useEffect(() => {
  const scrollHandler = () => {
    handleScroll();
  };

  const div = document.getElementById("email-listing-mail");
  if (div) {
    div.addEventListener("scroll", scrollHandler);
  }

  return () => {
    if (div) {
      div.removeEventListener("scroll", scrollHandler);
    }
  };
}, [emailListLoading, page]);

const handleScroll = () => {
  if (
    !emailListLoading &&
    messagesEndRef.current &&
    messagesEndRef.current.getBoundingClientRect().bottom <=
      window.innerHeight + 200
  ) {
    setScrollHandled(true);
    console.log("Scrolling to bottom. Current page:", page);
    setPage((prevPage) => prevPage + 1);
  }
};

useEffect(() => {
  if (!emailListLoading && scrollHandled) {
    // Reset scrollHandled after new content is loaded
    setScrollHandled(false);
  }
}, [emailListLoading, scrollHandled]);






useEffect(()=>{
  const clickInbox = document.getElementById("mail-inbox-click-0");
  if (clickInbox) {
      clickInbox.click();
  } else {
      console.warn("Element with ID 'mail-inbox-click-0' not found.");
  }
},[emails])






//  console.log(emails)
  return (
    <>
      <div className="user-list-section d-flex flex-column gap-2" id="email-listing-mail">
        
        {isLoading? <div className="loading">Loading&#8230;</div>:null}
        {emails.map((info, index) => (
          <div
            className={`client-card rounded pointer p-3 ${
              index === selectedItem ? "selected" : ""
            }`}
            key={index}
            id={"mail-inbox-click-"+index}
            onClick={() =>
              handleItemClick(
                info[0],
                index,
                setHasAttachments,
                setShowCurrentEmail,
                setCurrentEmail,
                setCurrentEmailBody,
                setSelectedItem,
                selectedItem,
                setAttachments,
                setIsLoading,
                setEmailsById,
                setNewClick,
                setInboxClick,
                setShowReply
              )
            }
          >
            <div className="client-email fs-25 text-white text-opacity-75 text-truncate text-capatilize mb-0">
              {info[0].sender.emailAddress.name}
            </div>
            <div className="fs-6 fw-medium text-truncate text-white mb-1">
              {info[0].subject.replace("Re: ", "")}
            </div>
            <div className="text-white fs-14 d-flex flex-wrap gap-1">
              <div className="client-email fs-14 text-white text-opacity-75 text-truncate text-lowercase mb-1">
                {info[0].bodyPreview}
              </div>
              <span className="name text-uppercase">{info[0].mailDate}</span>
              {/* <span className="time text-uppercase">11:00 PM</span> */}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
        {emailListLoading && <div>Loading...</div>}
      </div>
    </>
  );
};
