import React from "react";

const HorizontalBar = (props) =>{
    const percentage = props.percentage;
    //console.log("CirclePercentage::",percentage)
    let pathcolor;

    if (percentage < 20) {
        pathcolor = '#dc3545';
    } else if (percentage < 40) {
        pathcolor = 'orangered';
    } else if (percentage < 60) {
        pathcolor = '#ffc107';
    }else if (percentage <= 100) {
        pathcolor = '#198754';
    }
    return (
        <div className="progress-bar " role="progressbar" aria-label="Animated striped example" aria-valuenow={`${percentage}%`} aria-valuemin="0" aria-valuemax="100" style={{ width: `${percentage}%`, backgroundColor:pathcolor }}>{`${percentage}%`}</div>
    )
};

export default HorizontalBar;