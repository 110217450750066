import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { callAxios } from "../utils/utils";
import { environment } from "../environment";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../firebaseConfig";
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";
import FilterMenu from "./FilterMenu";
import $ from "jquery";

// import  HrModel from "./cards/HrModel";
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import HrListing from "./cards/HrModel";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../Pagination/Pagination";

const RecruitmentList = (props) => {
	let navigate = useNavigate();

	const { setLoggedinUser } = props;
	const [user, loading] = useAuthState(auth);
	const [selectedItem, setSelectedItem] = useState(null);
	const [requisitionList, setRequisitionList] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const [requisitionFull, setRequisitionFull] = useState([]);
	const [requisitionDetails, setRequisitionDetails] = useState({});
	const [isloading, setIsloading] = useState(true);
	const [pendingCount, setPendingCount] = useState(0);
	const [isDetailsLading, setIsDetailsLoading] = useState(true);
	const [filterOptions, setFilterOptions] = useState([]);
	const [isFilter, setIsFilter] = useState(false);
	const [userRole, setUserRole] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [filterCondition, setFilterCondition] = useState("");


	useEffect(() => {
		if (loading) return;
		if (!user) navigate("/");
		if (user) {
			const userInfo = {
				uid: user?.uid,
				email: user?.email,
				displayName: user?.displayName,
				accessToken: user?.accessToken,
				idToken: user?.idToken,
			};
			setLoggedinUser(userInfo);
		}
	}, [user, loading]);

	useEffect(() => {
		const getUserRole = async () => {
			if (user) {
				const q = query(
					collection(database, environment.REACT_APP_USERS_DB),
					where("email", "==", user?.email),
					orderBy("timeStamp", "asc"),
					limit(1)
				);
				onSnapshot(q, (querySnapshot) => {
					querySnapshot.forEach((doc) => {
						// console.log(doc.data())
						const id = doc.id;
						const data = doc.data();
						data["id"] = doc.id;
						setUserRole(data);
					});
				});
			}
		};
		getUserRole();
	}, [user]);
	// console.log("filter options::",user.email,userRole)

	const handleItemClick = (requisitionId, index) => {
		setIsDetailsLoading(true)
		setSelectedItem(index === selectedItem ? null : index);
		// console.log("ready to go on detail page")
		callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/requisition-details", { requisitionId: requisitionId }, "post").then((requisitionDetails) => {
			setRequisitionDetails(requisitionDetails.data.jobDetails);
			setIsDetailsLoading(false)
		})
	};

	useEffect(() => {
		//?page=1&pageSize=10
		// callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/get-requisitions?type=full", {
		//     userType: userRole.type ? userRole.type : localStorage.getItem("userRole"),
		//     department: "",
		//     minimumExp: "",
		//     employmentType: "",

		// }, "post").then((requisitions) => {
		//     setRequisitionList(requisitions.data.requisitions);
		//     setPaginationData(requisitions.data.pagination)
		//     setIsloading(false)
		//     handleItemClick(requisitions.data.requisitions[0].id, 0)
		// })

		callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/get-requisitions?type=paginate", {
			isFilter: isFilter,
			userType: userRole.type ? userRole.type : localStorage.getItem("userRole"),
		}, "post").then((requisitions) => {
			setPaginationData(requisitions.data.pagination)
			setRequisitionList(requisitions.data.requisitions)
			setPendingCount(requisitions.data.pendingForApprovalCount)
			setIsloading(false)
			handleItemClick(requisitions.data.requisitions[0].id, 0)
		})

		callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/filter-options", { userType: userRole.type ? userRole.type : localStorage.getItem("userRole") }, "post").then((filterOptions) => {
			setFilterOptions(filterOptions.data)
		})
	}, [])

	const handleNewPost = () => {
		navigate('/create-a-new-requisition');
	}

	const handleRequistionList = () => {
		navigate('/requisitions-for-approval');
	}

	// Handle job filter in mobile device
	const [isActive, setIsActive] = useState(false);
	const handleToggle = () => {
		setIsActive(!isActive);
	};

	// console.log(requisitionList)
	let requisitionsHtml;
	if (requisitionList && requisitionList.length > 0) {
		// if(isFilter){
		// console.log("in filter")
		requisitionsHtml = requisitionList.map((item, index) => {
			return (
				<div className={`job-card pointer p-3 ${index === selectedItem ? 'selected' : ''}`} key={"requisitionlist-" + index} onClick={() => handleItemClick(item.id, index)}>
					<div className="job-title fs-18 text-warning text-opacity-75 text-truncate text-capitalize">{item.jobTitle}</div>
					<div className="job-department text-truncate text-capitalize">{item.department}</div>
					<div className="job-location text-light text-opacity-50 text-truncate text-capitalize">{item.location}</div>
					<div className="job-posted text-truncate text-light text-opacity-50">{item.time_ago.split(",")[0].replace("ss", "s") + " ago"}</div>
				</div>
			)
		});

	} else {
		requisitionsHtml = <div className='text-center p-3'>Ops! Requisition not found yet!!</div>
	}


	const handleViewDetails = (requisitionId) => {
		navigate("/requisition-details", { state: { requisitionId: requisitionId, filterOptions: filterOptions.requisitionCandidateStatusOptions, usertype: userRole.type ? userRole.type : localStorage.getItem("userRole") } });
	}

	const handleEditDetails = () => {
		navigate("/edit-requisition", { state: { requistionData: requisitionDetails.requisition } });
	}

	const handleDelete = (requisitionId) => {
		console.log("requisition id::" + requisitionId)
		callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/delete-requisition", { requisitionId: requisitionId }, "post").then((response) => {
			console.log(response)
			if (response.status === 200) {
				toast.success("Requisition deleted successfully!", {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/get-requisitions?type=paginate", {
					department: "",
					minimumExp: "",
					employmentType: "",
					userType: userRole.type ? userRole.type : localStorage.getItem("userRole"),
				}, "post").then((requisitions) => {
					setPaginationData(requisitions.data.pagination)
					setRequisitionList(requisitions.data.requisitions)
					setPendingCount(requisitions.data.pendingForApprovalCount)
				})
			}
		})
	}

	const handleHrListing = (noOfHr) => {
		if (noOfHr > 0) {
			setShowModal(true)
		}
	}
	let inHR = [];
	let outHR = [];
	// console.log("in listing") 
	if (requisitionDetails.requisition) {
		requisitionDetails.requisition.my_requisitions.forEach((hr) => {
			if (hr.veuser.hrType == 1) {
				inHR.push(hr)
			} else {
				outHR.push(hr)
			}
		})
	}

	const noOfHRWorking = userRole.type === "SSA" || userRole.type === "SA" ? inHR.length + outHR.length : inHR.length;
	const noHrWorkingClass = noOfHRWorking > 0 ? "total-hr text-primary pointer" : "total-hr text-primary";
	//   console.log(inHR,outHR)

	const doPaginate = (page) => {
		setIsloading(true)
		callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/get-requisitions?type=paginate&page=" + page, {
			isFilter: isFilter,
			condition: filterCondition,
			userType: userRole.type ? userRole.type : localStorage.getItem("userRole"),
		}, "post").then((requisitions) => {
			setIsloading(false)
			setPaginationData(requisitions.data.pagination)
			setRequisitionList(requisitions.data.requisitions)
			setPendingCount(requisitions.data.pendingForApprovalCount)
			handleItemClick(requisitions.data.requisitions[0].id, 0)
		})
	}
	//   console.log(paginationData)
	let pagination;
	if (paginationData) {
		// console.log(cards)
		if (paginationData.totalPage > 1) {
			pagination = [...Array(paginationData.totalPage)].map((item, index) => {
				const isActive = paginationData.currentPage === index + 1 ? "page-link active" : "page-link";
				return (
					<li className="page-item"><button className={isActive} onClick={() => { doPaginate(index + 1) }}>{index + 1}</button></li>
				)
			})
		}
		// console.log(pagination,isLastpage)
	}

	const [currentPage, setCurrentPage] = useState(1);
	const totalPages = paginationData.totalPage; // Assuming there are 20 pages in total
	const adjacents = 2; // Number of adjacent pages to display

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		doPaginate(pageNumber)
		// You can also perform any other actions when the page changes
	};

	const handleFilter = async (value, filterType) => {
		const filters = ["experienceFilter", "jobNatureFilter", "jobTypeFilter", "shiftFilter"];
		if (value !== "") {
			// console.log(value,filterType)
			setIsFilter(true)
			setIsloading(true)
			setFilterCondition(filterType + "=" + value)
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/get-requisitions?type=paginate", {
				isFilter: true,
				condition: filterType + "=" + value,
				userType: userRole.type ? userRole.type : localStorage.getItem("userRole"),
			}, "post").then((requisitions) => {
				setPaginationData(requisitions.data.pagination)
				setRequisitionList(requisitions.data.requisitions)
				setPendingCount(requisitions.data.pendingForApprovalCount)
				setIsloading(false)
				handleItemClick(requisitions.data.requisitions[0].id, 0)
			})
		} else {
			// console.log("full items")
		}

		if (filters.includes(filterType)) {
			filters.forEach((item) => {
				if (filterType !== item) {

					$("#" + item).val("")
				}
			})
		}
	}

	const clearFilter = () => {
		setIsFilter(false)
		setIsloading(true)
		const filters = ["experienceFilter", "jobNatureFilter", "jobTypeFilter", "shiftFilter"];
		filters.forEach((item) => {
			$("#" + item).val("")
		})

		callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/get-requisitions?type=paginate", {
			isFilter: isFilter,
			condition: filterCondition,
			userType: userRole.type ? userRole.type : localStorage.getItem("userRole"),
		}, "post").then((requisitions) => {
			setIsloading(false)
			setPaginationData(requisitions.data.pagination)
			setRequisitionList(requisitions.data.requisitions)
			setPendingCount(requisitions.data.pendingForApprovalCount)
			handleItemClick(requisitions.data.requisitions[0].id, 0)
		})
	}
	// console.log("details:::", pendingCount)
	return (
		<div className='recruitment-list-page py-3'>
			{/* <ToastContainer /> */}
			<div className='container-fluid'>
				<div className='row align-items-center gx-3 gy-2 mb-2 pb-1'>
					<div className='col-sm-8'>
						<div className='main-heading text-white text-opacity-75'>New Recruitments</div>
					</div>

					<div className='col-sm-4 text-sm-end'>
						<div className="d-flex flex-wrap gap-2 justify-content-sm-end">
							<div className="position-relative">
								{userRole.type === "SA" || userRole.type === "SSA" ?
									<>
										<button className="btn btn-success rounded-pill px-3 me-2" onClick={handleRequistionList}><i className="bi bi-list-columns-reverse"></i> Requisition Approval List</button>
										{pendingCount > 0 ?
											<span className="position-absolute badge rounded-pill bg-danger fs-12 fw-medium" id="pendingrequistion" style={{ top: '-5px', right: '0px', padding: '2px 5px' }}>{pendingCount}</span>
											: null}
									</>
									: null}
							</div>

							<button className="btn btn-success rounded-pill px-3" onClick={handleNewPost}><i className="bi bi-plus-lg me-1"></i>Post New Job</button>
						</div>
					</div>
				</div>

				{/* Cards Listing Section */}
				<div className="content-wrapper">
					<button type="button" className="d-md-none w-100 btn btn-success py-2 mb-2" onClick={handleToggle}><i className="bi bi-funnel"></i> Filter</button>

					{/* Filter Section */}

					{/* <div id="filterWrapper" className={`filter-wrapper d-md-flex flex-column flex-md-row align-items-md-center gap-2 overflow-auto mb-3 ${isActive ? 'd-flex' : ''}`} style={{display:'none'}}> */}
					{/* <FilterMenu categories={['minimumExp', 'jobNatureType', 'employmentType', 'shiftType' ]} onFilterChange={handleFilterChange} filterOptions={filterOptions} setIsFilter={setIsFilter} isActive={isActive}/> */}
					<div id="filterWrapper" className={`filter-wrapper d-md-flex flex-column flex-md-row align-items-md-center gap-2 overflow-auto mb-3 ${isActive ? 'd-flex' : ''} `} style={{ display: 'none' }}>
						<div className="filter-option">
							<Form.Select className="bg-light bg-opacity-75 border-0 py-2" name="experienceFilter" id="experienceFilter" onChange={(e) => { handleFilter(e.target.value, "experienceFilter") }}>
								<option value="">Experience Level</option>
								{filterOptions.experience && filterOptions.experience.map((item) => {
									return (
										<option value={item.minimumExp}>{item.minimumExp}</option>
									)
								})}
							</Form.Select>
						</div>
						<div className="filter-option">
							<Form.Select className="bg-light bg-opacity-75 border-0 py-2" name="jobNatureFilter" id="jobNatureFilter" onChange={(e) => { handleFilter(e.target.value, "jobNatureFilter") }}>
								<option value="">Onsite/Remote</option>
								{filterOptions.jobNatureType && filterOptions.jobNatureType.map((item) => {
									return (
										<option value={item.jobNatureType}>{item.jobNatureType}</option>
									)
								})}
							</Form.Select>
						</div>

						<div className="filter-option">
							<Form.Select className="bg-light bg-opacity-75 border-0 py-2" name="jobTypeFilter" id="jobTypeFilter" onChange={(e) => { handleFilter(e.target.value, "jobTypeFilter") }}>
								<option value="">Job Type</option>
								{filterOptions.employementType && filterOptions.employementType.map((item) => {
									return (
										<option value={item.employmentType}>{item.employmentType}</option>
									)
								})}
							</Form.Select>
						</div>
						<div className="filter-option">

							<Form.Select className="bg-light bg-opacity-75 border-0 py-2" name="shiftFilter" id="shiftFilter" onChange={(e) => { handleFilter(e.target.value, "shiftFilter") }}>
								<option value="">Shift Type</option>
								{filterOptions.shiftType && filterOptions.shiftType.map((item) => {
									return (
										<option value={item.shiftType}>{item.shiftType}</option>
									)
								})}
							</Form.Select>
						</div>
						<button className='btn btn-warning' onClick={clearFilter}>Clear</button>
					</div>

					{/* Job Recruitments List */}
					<div className="row g-3">
						<div className="col-md-4 col-xxl-3">
							<div className="job-card-wrapper dark-theme-bg rounded-3" id="content">
								{isloading ?
									<div style={{ textAlign: 'center' }}>
										<span className="spinner-profile"></span>
									</div>
									: requisitionsHtml}
								{/* {[...Array(10)].map((elementInArray, index) => (
                                    
                ))} */}
								{/* Pagination code */}
								<nav className='p-3'>
									{/* <ul className="pagination justify-content-center m-0">
										{paginationData.currentPage > 1 ?
												<li className="page-item"><button className="page-link" onClick={() => { doPaginate(paginationData.currentPage - 1) }} ><span aria-hidden="true">&laquo;</span></button></li>
												: null}
												{pagination}
												{paginationData.totalPage > 1 && paginationData.currentPage !== paginationData.totalPage ?
														<li className="page-item"><button className="page-link" onClick={() => { doPaginate(paginationData.currentPage + 1) }}><span aria-hidden="true">&raquo;</span></button></li>
												: null}
										</ul> */}
									{paginationData && paginationData.totalPage > 1 ?
										<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} adjacents={adjacents} userRole={userRole} />
										: null}
								</nav>
							</div>
						</div>
						{requisitionList.length > 0 ?
							<div className="col-md-8 col-xxl-9">
								{isDetailsLading ?
									<div className="main-item" id="leftLoader-chat">
										<div className="animated-background"></div>
									</div>
									:
									Object.keys(requisitionDetails).length > 0 ? (
										<div className="job-details-wrapper dark-theme-bg rounded-3 p-3 px-xxl-5 sticky-md-top" >
											<div className="job-card-highlighter border border border-secondary rounded-3 p-3">
												<div className="info-section">
													{requisitionDetails.requisition.jobTitle ?
														<div className="job-title fs-4 fw-medium text-warning text-opacity-75 text-truncate text-capitalize lh-sm mb-1">{requisitionDetails.requisition.jobTitle}</div>
														: null}

													<div className="d-flex flex-wrap mb-2 pb-xxl-1">
														{requisitionDetails.requisition.department ?
															<div className="job-department add-dot text-capitalize">{requisitionDetails.requisition.department}</div>
															: null}

														{requisitionDetails.requisition.location ?
															<div className="job-location add-dot text-capitalize">{requisitionDetails.requisition.location}</div>
															: null}

														{requisitionDetails.time_ago ?
															<div className="job-posted add-dot">{requisitionDetails.time_ago.split(",")[0].replace("ss", "s") + " ago"}</div>
															: null}

														{userRole.type === "BDM" || userRole.type === "CAM" ?
															<div className="job-posted add-dot text-capitalize">{requisitionDetails.applicants + " Applicants"} </div>
															:
															<div className="job-posted text-capitalize">{requisitionDetails.applicants + " Applicants"} </div>
														}

														{userRole.type === "BDM" || userRole.type === "CAM" ?
															requisitionDetails.requisition.requisition_status === "1"
																? <div className="job-posted text-success">Approved</div>
																: requisitionDetails.requisition.requisition_status === "2" ?
																	<div className="job-posted text-danger">Rejected</div>
																	:
																	<div className="job-posted text-warning">Pending for Approval</div>
															: null}
													</div>
													<div className="hr-profile-wrapper d-flex align-items-center gap-2 mb-2 pb-xxl-1">
														<div className="hr-profiles d-flex">
															<img src={require('../resources/images/Avatar.png')} alt="HR Profile" className="img-fluid border rounded-circle" />
														</div>

														<div className={noHrWorkingClass} onClick={() => { handleHrListing(noOfHRWorking) }}> {noOfHRWorking} HR working this job</div>
														{showModal ?
															<HrListing showModal={showModal} setShowModal={setShowModal} whoWorkingonthis={requisitionDetails.requisition.my_requisitions} userType={userRole.type} />
															: null}
													</div>

													<div className="info_wrapper d-md-flex gap-3 mb-2">
														{requisitionDetails.requisition.requested_by_email !== user.email ?
															<>
																<div className="title_name text-nowrap text-secondary">Requested By -</div>
																<div className="value text-capitalize">
																	<span className="req_name me-2">{requisitionDetails.requisition.requested_by}</span>
																</div>
															</>
															: null}
													</div>
													<div className="btn-wrapper d-flex flex-wrap gap-2">
														<button className="btn btn-primary px-3" onClick={() => { handleViewDetails(requisitionDetails.requisition.id) }}><i className="bi bi-file-earmark-medical"></i> View Details</button>
														{user?.email === requisitionDetails.requisition.requested_by_email ?
															<>
																{/* <button className="btn btn-warning px-3" onClick={()=>{handleEditDetails(requisitionDetails.requisition.id)}}><i className="bi bi-pencil"></i> Edit</button> */}
																{/* <button className="btn btn-danger px-3" onClick={()=>{handleDelete(requisitionDetails.requisition.id)}}><i className="bi bi-trash3"></i> Delete</button>  */}
															</>
															: null}
													</div>
												</div>
											</div>

											<div className="other-brief-info mt-4">
												<div className="row g-3 g-xxl-4">
													{requisitionDetails.requisition.jobTitle ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Job Title</div>
																<div className="value text-capitalize">{requisitionDetails.requisition.jobTitle}</div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.minimumExp ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Minimum Experience</div>
																<div className="value text-capitalize">{requisitionDetails.requisition.minimumExp} {parseInt(requisitionDetails.requisition.minimumExp) > 1 ? "Years" : "Year"}
																</div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.location ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Location</div>
																<div className="value text-capitalize">{requisitionDetails.requisition.location}</div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.employmentType ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Employment Type</div>
																<div className="value text-capitalize">{requisitionDetails.requisition.employmentType}</div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.jobNatureType ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Job Nature</div>
																<div className="value text-capitalize">{requisitionDetails.requisition.jobNatureType === "WFO" ? "Work From Office" : "Work From Home"}</div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.keySkills ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Key Skills</div>
																<div className="value text-capitalize">{requisitionDetails.requisition.keySkills}</div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.primaryRole ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Primary Role</div>
																<div className="value text-capitalize">{requisitionDetails.requisition.primaryRole}</div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.clientName ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Client Name</div>
																<div className="value text-capitalize">{requisitionDetails.requisition.clientName}</div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.minimumQualification ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Minimum Qualification</div>
																<div className="value text-capitalize">{requisitionDetails.requisition.minimumQualification}</div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.department ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Department</div>
																<div className="value text-capitalize">{requisitionDetails.requisition.department}</div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.reqiredBy ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Required By</div>
																<div className="value text-capitalize">{requisitionDetails.requisition.reqiredBy}</div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.otherHrWorking ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Other HR Working</div>
																<div className="value text-capitalize"><span>{requisitionDetails.requisition.otherHrWorking}</span></div>
															</div>
														</div>
														: null}

														{requisitionDetails.requisition.prefered_lang_val ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Technical Round Language</div>
																<div className="value text-capitalize"><span>{requisitionDetails.requisition.prefered_lang_val}</span></div>
															</div>
														</div>
														: null}

													{requisitionDetails.requisition.budget ?
														<div className="col-6 col-xl-4 col-xxl-6">
															<div className="info_wrapper d-xxl-flex gap-3 gap-xxl-4">
																<div className="col-xxl-5 title_name text-secondary text-xxl-end">Budget</div>
																<div className="value text-capitalize"><span>{requisitionDetails.requisition.budget}</span></div>
															</div>
														</div>
														: null}
													{requisitionDetails.requisition.jobDescription ?
														<div className="col-12">
															<div className="info_wrapper d-xxl-flex gap-4">
																<div className="col-xxl-2 title_name text-secondary text-xxl-end">Job Description</div>
																<div className="value ms-xxl-n1 text-xxl-start">
																	<div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: requisitionDetails.requisition.jobDescription }}></div>
																</div>
															</div>
														</div>
														: null}
												</div>
											</div>
										</div>
									) : null
								}
							</div>
							: null
						}
					</div>
				</div>
			</div>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedinUser: (user) => dispatch(setUserInfo(user)),
	};
};

const mapStoreStateToProps = (state) => {
	return {
		...state,
	};
};

export default connect(
	mapStoreStateToProps,
	mapDispatchToProps
)(RecruitmentList);
