import React from 'react'

const RoomNotFoundMessage = ({ showRoomNotFoundMessage }) => {
  return (
    <div className='room_not_found_container w-100'>
      {showRoomNotFoundMessage && (
        <div className='room_not_found_paragraph mt-3'>
          Room has not been found. Please try again.
        </div>
      )}
    </div>
  )
}

export default RoomNotFoundMessage