import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { spanStatusfromHttpCode } from "@sentry/react";
am4core.useTheme(am4themes_animated);
let chart;

class CallWithReasonChart extends Component {
  componentDidMount() {
    this.initChart()
  }
  componentDidUpdate(prevProps) {
    this.initChart();
  }

  // initChart() {
  //   let chartData = [];
  //   am4core.useTheme(am4themes_animated);
  //   let chart = am4core.create("pstn-call-reason-chart", am4charts.TreeMap);
  //   chart.hiddenState.properties.opacity = 0;

  //   this.props.chartData.forEach(item => {
  //     chartData.push({
  //       name: item.category,
  //       children: [
  //         {
  //           name: item.category,
  //           value: item.first
  //         }
  //       ]
  //     });
  //   });

  //   chart.data = chartData;
  //   chart.colors.step = 2;

  //   chart.dataFields.value = "value";
  //   chart.dataFields.name = "name";
  //   chart.dataFields.children = "children";

  //   chart.zoomable = false;
  //   let bgColor = new am4core.InterfaceColorSet().getFor("background");

  //   let level0SeriesTemplate = chart.seriesTemplates.create("0");
  //   let level0ColumnTemplate = level0SeriesTemplate.columns.template;

  //   level0ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
  //   level0ColumnTemplate.fillOpacity = 0;
  //   level0ColumnTemplate.strokeWidth = 4;
  //   level0ColumnTemplate.strokeOpacity = 0;

  //   let level1SeriesTemplate = chart.seriesTemplates.create("1");
  //   let level1ColumnTemplate = level1SeriesTemplate.columns.template;

  //   level1SeriesTemplate.tooltip.animationDuration = 0;
  //   level1SeriesTemplate.strokeOpacity = 1;

  //   level1ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
  //   level1ColumnTemplate.fillOpacity = 1;
  //   level1ColumnTemplate.strokeWidth = 4;
  //   level1ColumnTemplate.stroke = bgColor;

  //   // Check if label bullet already exists before adding
  //   let existingBullet = level1SeriesTemplate.bullets.getIndex(0);
  //   if (!existingBullet || existingBullet.type !== "LabelBullet") {
  //     let bullet1 = level1SeriesTemplate.bullets.push(new am4charts.LabelBullet());
  //     bullet1.locationY = 0.5;
  //     bullet1.locationX = 0.5;
  //     bullet1.label.text = "{name}";
  //     bullet1.label.fill = am4core.color("#ffffff");
  //     bullet1.label.wrap = true; // Allow text wrapping
  //     bullet1.label.maxWidth = 400; // Set maximum width for label
  //     bullet1.label.fontSize = 11; // Adjust font size as needed
  //     bullet1.label.truncate = false; // Disable truncating long labels
  //   }
  //   chart.maxLevels = 2;
  // }

  initChart() {
    let chartData = [];
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("pstn-call-reason-chart", am4charts.TreeMap);
    chart.hiddenState.properties.opacity = 0;
    this.props.chartData.forEach(item => {
      chartData.push({
        namey: item.category,
        children: [
          {
            name: item.category,
            value: item.first
          }
        ]
      });
    });

    chart.data = chartData;
    chart.colors.step = 2;

    chart.dataFields.value = "value";
    chart.dataFields.name = "name";
    chart.dataFields.children = "children";

    chart.zoomable = false;
    let bgColor = new am4core.InterfaceColorSet().getFor("background");

    let level0SeriesTemplate = chart.seriesTemplates.create("0");
    let level0ColumnTemplate = level0SeriesTemplate.columns.template;

    level0ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
    level0ColumnTemplate.fillOpacity = 0;
    level0ColumnTemplate.strokeWidth = 4;
    level0ColumnTemplate.strokeOpacity = 0;

    let level1SeriesTemplate = chart.seriesTemplates.create("1");
    let level1ColumnTemplate = level1SeriesTemplate.columns.template;

    level1SeriesTemplate.tooltip.animationDuration = 0;
    level1SeriesTemplate.strokeOpacity = 1;

    level1ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
    level1ColumnTemplate.fillOpacity = 1;
    level1ColumnTemplate.strokeWidth = 4;
    level1ColumnTemplate.stroke = bgColor;

    let bullet1 = level1SeriesTemplate.bullets.push(new am4charts.LabelBullet());
    bullet1.locationY = 0.5;
    bullet1.locationX = 0.5;
    bullet1.label.text = "{name} : {value}"; // Use category instead of name
    bullet1.label.fill = am4core.color("#ffffff");
    bullet1.label.wrap = true; // Allow text wrapping
    bullet1.label.maxWidth = 400; // Set maximum width for label
    bullet1.label.fontSize = 16; // Adjust font size as needed
    bullet1.label.truncate = false; // Disable truncating long labels
    bullet1.label.padding(8, 12, 8, 12); // Add padding

    chart.maxLevels = 2;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id="pstn-call-reason-chart" style={{ width: "100%", height: "800px", margin: "0 auto" }} />;
  }
}

export default CallWithReasonChart;
