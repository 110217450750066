// useWebSocketHandler.js
import { useEffect, useState } from 'react';
import { useWebSocket } from './WebSocketContext';
import { toast } from 'react-toastify';
import $ from "jquery";

const useTranscriptHandler = (user, setTranscriptions_, userRole) => {
  const socket = useWebSocket();
  // const [transcriptions_, setTranscriptions_] = useState([]);
  let fullTranscript_ = [];

  const displayMessage = (data) => {
    const displayNAMEIS = data.usertype === "CAM"? localStorage.getItem("userName"):data.speaker;
    const shortName = displayNAMEIS.charAt(0).toUpperCase()
   
   
    // console.log(displayNAMEIS)d
    // const messagesList = document.getElementById('messages');
    //  <div class="msg-label text-capitalize text-muted small" style={{ marginBottom: '2px' }}>12-01-2023 01:43 PM</div>
    const newMessage = data.usertype === "Client"? `<div class="message-outside">
                <div class="box-wrapper mb-2 d-flex align-items-baseline gap-2">
                  <div class="user-pic position-relative" style="top:23px">
                    <div class="user-chip d-flex align-items-center justify-content-center text-uppercase bg-primary bg-opacity-10 fs-12 fw-semibold text-primary border border-primary rounded-circle" style=" height: 25px;width: 25px" title="inbound user">${shortName}</div>
                  </div>
                  <div class="message-card fs-12">
                   <div class="msg-label text-capitalize text-muted small text-end" style={{ marginBottom: '2px' }}>${data.speaker} [${data.usertype}]</div>
                    <div class="msg bg-secondary bg-opacity-10 text-body px-2 py-1 rounded">${data.incoming_message}</div>
                  </div>
                </div>
              </div>`
              :
               `<div class="message-myside">
                <div class="box-wrapper mb-2 d-flex flex-row-reverse align-items-baseline gap-2">
                  <div class="user-pic position-relative" style="top:23px">
                    <div class="user-chip d-flex align-items-center justify-content-center text-uppercase bg-primary bg-opacity-10 fs-12 fw-semibold text-primary border border-primary rounded-circle" style=" height: 25px;width: 25px" title="outbound user">${shortName}</div>
                  </div>
                  <div class="message-card fs-12">
                    <div class="msg-label text-capitalize text-muted small text-end" style={{ marginBottom: '2px' }}>${displayNAMEIS} [${data.usertype}]</div>
                    <div class="msg bg-secondary bg-opacity-10 text-body px-2 py-1 rounded">${data.incoming_message}</div>
                  </div>
                </div>
              </div>` ;
    // newMessage.textContent = `${data.timestamp} [${data.event}]: ${data.message}`;
    // newMessage.classList.add(`event-${data.event}`);
    // messagesList.append(newMessage);
    $("#messagesincoming-Area").append(newMessage)
    $('#messagesincoming-Area').scrollTop($('#messagesincoming-Area').prop("scrollHeight"))
  }
  useEffect(() => {
    if (!socket) return;

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // console.log(data, data.incoming_message.receiver_email,user?.email);

      if (user?.email && data.event === 'callreceived' && data.incoming_message.receiver_email !== user?.email && data.usertype === userRole) {
        toast.success(`Call Received by ${data.incoming_message.receiver_name}`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      } else if (user?.email && data.event === 'callreceived' && data.incoming_message.receiver_email === user?.email) {
        localStorage.setItem('receiver_email', data.incoming_message.receiver_email);
      }
      if (data.event === 'clearTranscript' && data.type === 'incoming' && data.incoming_message.receiver_email === user?.email){
        fullTranscript_ = [];
          setTranscriptions_([]);
          return;
      }

      if (data.event === 'finalTranscript' && data.type === 'incoming' && localStorage.getItem('receiver_email') === user?.email) {
        // console.log(data);

       
        const speakerName = data.usertype === 'CAM' ? localStorage.getItem('userName') : data.speaker;
        const messageObject = {
            speaker: speakerName,
            message: data.incoming_message,
            timestamp: data.timestamp,
            receiverEmail:localStorage.getItem('receiver_email'),
            user_type:data.usertype
        };
        
        fullTranscript_.push(messageObject);
        setTranscriptions_([...fullTranscript_]);
        displayMessage(data);
      }

      //  if(data.type === "outgoing" && data.user_email === user?.email){
      //     // console.log(update,user?.email)
      //     if (data.event === 'clearTranscript') {
      //       // Clear the fullTranscript array for a new call
      //       fullTranscript = [];
      //       setTranscriptions([]);
      //       return; // Exit the handler to avoid processing the 'clearTranscript' event further
      //   }
         
          
      //       const speakerName = data.speaker === "outbound" ?  data.out_speaker:localStorage.getItem("userName");
      //     const messageObject = {
      //       speaker: speakerName,
      //       message: data.incoming_message,
      //       timestamp: data.timestamp,
      //       callsid:data.callsid,
      //       user_type:data.user_type
      //   };
        
      //   fullTranscript.push(messageObject);
      //   setTranscriptions([...fullTranscript]);
      //   displayMessage__(data);
          
      //   }
      //  console.log(transcriptions_)

    };

    return () => {
      socket.onmessage = null;
    };
  }, [socket, user]);

  return true;
};

export default useTranscriptHandler;
