import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { environment } from "../../environment";
import { callAxios, getProfileData } from "../../utils/utils";

import CreatableSelect from "react-select/creatable";
import Skills from "../../Recruitment/Skills";

const SkillsVal = Skills.map((skill, index) => ({
  value: skill,
  label: skill,
}));

const animatedComponents = makeAnimated();

export const AddKeySkills = ({
  showSkillsModal,
  closeSkillsModal,
  profileData,
  profileId,
  setShowSkillsModal,
  setProfileData,
  setIsLoading
}) => {
  
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [sendskills, setSendskills] = useState([]);
  const [filterSkills, setFilterSkills] = useState([]);
  const [removeId, setRemoveId] = useState([]);

  useEffect(() => {
    const savedKeySkills = new Set(
      profileData?.key_skills &&
        profileData.key_skills.map((item) => item.key_skills)
    );

    const filteredMain = SkillsVal.filter(
      (item) => !savedKeySkills.has(item.value)
    );

    setFilterSkills(filteredMain);
  }, [profileData?.key_skills]);

  // addskills
  const [lowerItems, setLowerItems] = useState([
    { id: 1, content: "Material Ui" },
    { id: 2, content: "Material Design" },
    { id: 3, content: "Responsive Design" },
    { id: 4, content: "Flexbox" },
    { id: 5, content: "Angular Material" },
  ]);

  const [upperItems, setUpperItems] = useState([]);

  const moveItemToUpper = (id) => {
    const selectedItem = lowerItems.find((item) => item.id === id);
    setLowerItems(lowerItems.filter((item) => item.id !== id));
    setUpperItems([...upperItems, selectedItem]);
  };

  const moveItemToBottom = (id) => {
    const selectedItem = upperItems.find((item) => item.id === id);
    setUpperItems(upperItems.filter((item) => item.id !== id));
    setLowerItems([...lowerItems, selectedItem]);
  };
  // addskills

  const handleSkillChange = async (selectedOptions) => {
    console.log(profileData?.key_skills, selectedOptions);
    const exists = profileData?.key_skills.some((item) => {
      console.log(item.key_skills);
    });
    // console.log(exists);
    setSelectedSkills(selectedOptions);
  };
//   console.log(selectedSkills);

  const removeSkill = async (id) => {
    // console.log(id)
    document.getElementById("delete-skill-"+id).style.display = "none"
    setRemoveId(prevRemoveId => [...prevRemoveId, id]);
    // const postData = {
    //   profileId: profileId,
    //   type: "delete",
    //   skill: "",
    //   id: id,
    // };
    // console.log(postData);
    // // return
    // try {
    //   const response = await axios.post(
    //     `${environment.REACT_APP_API_URL_Live}api/v1/profile/key-skills`,
    //     postData,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   if (response?.data) {
    //     const getresponse = response?.data;
    //     if (getresponse?.statusCode === 200) {
    //       setProfileData(await getProfileData(profileId));
    //       setShowSkillsModal(false);
    //     }
    //   }
    // } catch (error) {
    //   toast.error(error, {
    //     position: "bottom-center",
    //   });
    //   // console.error('Error uploading file:', error);
    // }
  };
// console.log(removeId)
  const postSelectedSkills = async () => {
    try {
        setIsLoading(true) 
        setShowSkillsModal(false)
      callAxios(
        environment.REACT_APP_API_URL_Live + "api/v1/profile/key-skills",
        { profileId: profileId, type: "add", skill: selectedSkills, skillToBeDelete:removeId },
        "post"
      ).then(async (response) => {
        if (response.data.statusCode === 200) {
          setProfileData(await getProfileData(profileId,setIsLoading));
          setRemoveId([])
          toast.success(response.data.message, {
            position: "bottom-center",
          });
         
          
        }
        if (response.data.statusCode === 202) {
            setProfileData(await getProfileData(profileId,setIsLoading));
            toast.error(response.data.message, {
                position: "bottom-center",
              });
        }
      });
    } catch (error) {
        setProfileData(await getProfileData(profileId,setIsLoading));
      toast.error(error, {
        position: "bottom-center",
      });
      console.error("Error uploading file:", error);
    }
    // closeSkillsModal();
  };

  const showOption = () => {
    console.log("showOption");
  };
  // React-slick npm style customisation
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "var(--bs-border-color) !important",
      color: "#000",
      borderRadius: "1rem",
      backgroundColor: "#fff",
      padding: "5px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "var(--theme-color4)", // Change the color of the placeholder text
      fontSize: "14px",
      fontWeight: "400",
      padding: "0",
    }),
  };

  return (
    <Modal
      show={showSkillsModal}
      centered
      size="lg"
      onHide={closeSkillsModal}
      backdrop="static"
      keyboard={false}
      dialogClassName="profileCustomModal small text-dark"
      contentClassName="rounded-4"
    >
      <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
        <div className="close-wrapper text-end mb-2">
          <Button
            variant="link"
            onClick={closeSkillsModal}
            className="text-decoration-none border-0 p-0"
          >
            <i className="bi bi-x-lg fs-5 text-secondary"></i>
          </Button>
        </div>
        <div className="heading-wrapper mb-4">
          <div className="widgetTitle text-black fs-18 fw-semibold mb-1">
            Key skills{" "}
            {profileData?.key_skills && profileData?.key_skills.length === 0 ? (
              <span className="profileComScore fs-6 fw-normal text-success ms-1">
                Add 8%
              </span>
            ) : null}
          </div>
          <p className="subTitle fs-14">
            Add skills that best define your expertise, for e.g, Direct
            Marketing, Oracle, Java, etc. (Minimum 1)
          </p>
        </div>
        <div className="skills-wrapper mb-3">
          <div className="d-flex flex-wrap gap-2">
            {profileData?.key_skills &&
              profileData?.key_skills.map((item) => (
                <button
                  key={item.id}
                  type="button"
                  className="btn btn-sm btn-outline-secondary rounded-pill py-1 px-3"
                  id={"delete-skill-"+item.id}
                >
                  {item.key_skills}{" "}
                  <span onClick={() => removeSkill(item.id)}>
                    <i class="bi bi-x fs-18"></i>
                  </span>
                </button>
              ))}
          </div>
        </div>
        <div className="form-cover mb-4 pb-3">
          <div className="mb-4">
            <label
              htmlFor=""
              className="form-label text-dark small fw-semibold"
            >
              Skills
            </label>
            {/* <Select
                            // isMulti 
                            name="skills"
                            closeMenuOnSelect={true} className='custom-select text-dark' classNamePrefix='custom-select' required
                            components={animatedComponents}
                            // defaultValue={[colourOptions[4], colourOptions[5]]}
                            options={Skills}
                            styles={customStyles}
                            placeholder="Add skills"
                            onChange={handleSkillChange}
                            value={selectedSkills}
                        /> */}
            <CreatableSelect
              isMulti
              className="custom-select"
              classNamePrefix="custom-select"
              required
              closeMenuOnSelect={false}
              name="skills"
              options={filterSkills}
              styles={customStyles}
              onChange={(value) => {
                handleSkillChange(value);
                // setErrors((prevErrors) => ({
                //     ...prevErrors,
                //     skills: '',
                // }));
              }}
              placeholder="Add your skills"
            />
          </div>
          {/* <div className="skills-wrapper">
                        <div className="small text-black fw-semibold mb-2">Or you can select from the suggested set of skills</div>
                        <div className="d-flex flex-wrap gap-2">
                            {upperItems.map((item) => (                           
                                <button key={item.id} type='button' className='btn btn-sm btn-outline-secondary rounded-pill py-1 px-3' onClick={() => moveItemToBottom(item.id)}>{item.content} +</button>                            
                            ))}
                        </div>
                    </div> */}
        </div>
        <div className="btn-wrapper d-flex flex-wrap gap-2">
          <Button
            variant="danger"
            onClick={closeSkillsModal}
            className="rounded-pill px-4"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="rounded-pill px-4"
            onClick={() => postSelectedSkills()}
          >
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
