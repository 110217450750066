import React, { useState } from "react";

import CameraButtonImg from "../../resources/images/camera.svg";
import CameraButtonImgOff from "../../resources/images/cameraOff.svg";
import { connect, useSelector } from "react-redux";
import {
  setIsRemovedBackground,
  setBackgroundPreviewImage,
  RemovedBackground,
} from "../../store/actions";

import {
  blurBackground,
  imageBackground,
  changeImageBackground,
  previewImageBackground,
} from "../../utils/twilioUtils";
const { createLocalVideoTrack } = require("twilio-video");

const CameraButton = ({ room, setIsRemovedBgAction, camera }) => {
  // const selectedBgImagePrev = useSelector((state) => state.bgImagePrev);
  const mainBgImage = useSelector((state) => state.bgImage);
  const localImage = localStorage.getItem("setLocalImg");

  const [isCameraOn, setIsLocalVideoTrackDisabled] =
    useState(camera);

  const handleCameraButtonPressed = () => {
    isCameraOn ? stopVideo() : startVideo();
    setIsLocalVideoTrackDisabled(!isCameraOn);
  };

  const removeBackground = () => {
    console.log("in Remove BG");
    localStorage.setItem("setLocalImg", undefined);
    

    document.getElementById("video_portal_preview").style.display = "none";
    if ( localStorage.getItem("setLocalImg")) {
      setIsRemovedBgAction(true);
    } 
    // document.getElementById("BgSettingSidenav").style.transform =
    //   "translateX(300px)";
    // document.getElementById("main").style.marginRight = "0";
    document.getElementById("previewCam").style.display = "none";
  };

  const startVideo = () => {
    // start sending back video stream to other users
    room.localParticipant.videoTracks.forEach((localVideoTrackPublication) => {
      localVideoTrackPublication.track.enable();
    });
  };

  const stopVideo = () => {
    // stop sending camera stream to other users
    room.localParticipant.videoTracks.forEach((localVideoTrackPublication) => {
      localVideoTrackPublication.track.disable();
    });
  };

  const displayPreview = () => {
    createLocalVideoTrack().then((track) => {
      const localMediaContainer = document.getElementById("video-preview");

      localMediaContainer.innerHTML = "";

      localMediaContainer.appendChild(track.attach());
      if (typeof localImage !== "undefined") {
        if(localImage === "blur-image.jpg"){
          blurBackground(track); 
        }
        else {
          previewImageBackground(track, localImage);
        }
       // previewImageBackground(track, mainBgImage);
      }

      document.getElementById("previewCam").style.display = "block";
    });
  };

  // Open Sidebar
  function openBackSettingSidenav() {
    document.getElementById("previewCam").style.display = "none";
    document.getElementById("BgSettingSidenav").style.transform =
      "translateX(0px)";
    document.getElementById("main").style.marginRight = "300px";
  }

  const openBackgroundSetting = () => {
    
    document.getElementById("previewCam").style.display = "block";
    displayPreview();
  };

  const hideBackgroundSettingBox = () => {
    document.getElementById("previewCam").style.display = "none";
  };

  return (
    // <div className="video_button_container">
    //   <img
    //     src={isLocalVideoTrackDisabled ? CameraButtonImgOff : CameraButtonImg}
    //     className="video_button_image"
    //     onClick={handleCameraButtonPressed}
    //   />
    // </div>
    <>
      <a
        className="nav-link text-white text-center"
        onClick={handleCameraButtonPressed}
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="true"
      >
        {/* {isLocalVideoTrackDisabled ? (
          <i className="bi bi-camera-video-off"></i>
        ) : (
          <i
            className="bi bi-camera-video"
            onMouseEnter={openBackgroundSetting}
          ></i>
        )} */}
        
        {isCameraOn ? (
          <i
          className="bi bi-camera-video"
          onMouseEnter={openBackgroundSetting}
        ></i>
        ) : (
          <i className="bi bi-camera-video-off"></i>
          
        )} 

        <div className="menu-title" onMouseEnter={openBackgroundSetting}>Camera</div>
      </a>

      <ul className="dropdown-menu dropdown-menu-end video-preview-wrapper px-3 py-4"
        style={{ width: "300px" }}
        id="previewCam"
        onMouseLeave={hideBackgroundSettingBox}
      >
        <div className="video-wrapper pb-1">
          <div className="preview-label mb-3 small">
            <strong>Private Preview</strong> (Only you can see this)
          </div>
          <div className="video-preview" id="video-preview"></div>
        </div>
        <hr
          className="dropdown-divider my-3"
          style={{ margin: "0px -1rem", borderTop: "2px solid #191919" }}
        />
        <div className="video-effect-wrapper">
          <div
            className="effect-item pointer mt-2"
            onClick={() => removeBackground()}
          >
            <i className="bi bi-person-slash"></i> Remove background effect
          </div>
          <div
            className="effect-item pointer mt-2"
            onClick={openBackSettingSidenav}
          >
            <i className="bi bi-sun"></i> More background effect
          </div>
        </div>
      </ul>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsRemovedBgAction: (isRemoved) =>
      dispatch(setIsRemovedBackground(isRemoved)),
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(CameraButton);