import React, { useEffect, useState } from "react";
import { connect } from "react-redux"
import { app, database, auth } from "../../firebaseConfig";
import {
  collection,
  query,
  where,
  onSnapshot,
  documentId,
  getDocs,
  doc,
  updateDoc
} from "firebase/firestore";

import CircularProgressBar from '../../CircularProgressBar/CircularProgressBar';

// env import
import {environment} from '../../environment';

import { getCountryCallingCode } from 'react-phone-number-input'
import { callAxios } from "../../utils/utils";
import axios from "axios";



const LeadDetails = (props) => {
  const [contactNo, setContact] = useState(0);
  const [country, setCountry] = useState("");
  let leadInfoObj = {};
  const [leadInfo, setLeadData] = useState({});
  const [clientSubmitTime, setClientSubmitTime] = useState('');
  const [leadIdUpdated, setLeadIdFlag] = useState(false);
  const [isInsertedToRDS, setIsInsertedRDS] = useState(false)

  let total = 0;
  let totalPercentage=0;

  const saveFormDataToRds = async(roomId) =>{
 
    const formData = query(
      collection(database, environment.REACT_APP_FIREBASE_DB),
      where("roomId", "==", roomId)
    );
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
      //console.log(docData.id, ' => ', docData.data());
      const formData = { ...docData.data(), id: docData.id };
            
     // console.log("form Saving to RDS--->",formData);
    await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/leads/save-nm-leads',{formData:JSON.stringify(formData)}, "post" );
    setIsInsertedRDS(true)
  
    });
    //window.location.href = siteUrl;

   
  }

  

  const updateLeadIdToFormsAndRDS = async (roomId, leadID, oppId, new_leadid) => {
    // console.log(roomId, leadID, oppId, new_leadid)
    const formData = query(
      collection(database, environment.REACT_APP_FIREBASE_DB),
      where("roomId", "==", roomId)
    );
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
      //console.log(docData.id, ' => ', docData.data());
      const formRef = doc(
        database,
        environment.REACT_APP_FIREBASE_DB,
        docData.id
      );
      
      updateDoc(formRef, {
        leadId: leadID,
        opportunityId:oppId,
        new_leadId:new_leadid

      })
        .then(async() => {
           //console.log("Lead ID updated!");
          await saveFormDataToRds(roomId);
        })
        .catch((error) => {
          console.log(error);
         // window.location.href = siteUrl;
        }); 
    
        
    });

    
  };

  useEffect(() => {
    
   
    const getLeadDetails = async () => {
      if(props?.formId){
        const q = query(collection(database,environment.REACT_APP_FIREBASE_DB), where(documentId(), '==', props.formId));
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;

          leadInfoObj = {
            "name": data?.name, "companyweb": data?.companyweb, "ppcurl": data?.ppcurl,
            "country": data?.country, "description": data?.description, "email":data?.email,
            "phone":data?.phone,"projduration":data?.projduration, "clientJoinedLiveTime":data?.clientJoinedLiveTime,"clientLeftTime":data?.clientLeftTime,
            "state":data?.state,"presence":data?.presence,"streamerStatus":data?.streamerStatus,"clientSubmitTime":data?.clientSubmitTime
          }
          setContact(data?.phone);
          setCountry(data?.country);
          setLeadData(leadInfoObj);
          setClientSubmitTime(data?.clientSubmitTime);

          if(data?.leadId){
            setLeadIdFlag(true)
          }
          
         
        });
      });
      }
      

      
    }
    getLeadDetails();
    if(!isInsertedToRDS){
      saveFormDataToRds(props.roomId)
    }
    
    
  }, [props.roomId]);

  useEffect(()=>{
    //console.log("LEad Details updating");
    
    if(!leadIdUpdated && leadInfo.clientSubmitTime && leadInfo?.email){
      //  console.log("Client submitted form",props?.roomId)
    axios.get("https://u1mn5pz8p1.execute-api.us-east-1.amazonaws.com/dev/nmdata?operation=fetchleadbyemail&email="+leadInfo?.email).then(response => {
      // console.log(response)
        if(response.data.data.length > 0){
          updateLeadIdToFormsAndRDS(props.roomId,response.data.data[0].leadid,response.data.data[0]._qualifyingopportunityid_value,response.data.data[0].new_leadid)
        }
        
      
      
  })
  .catch(error => {
      // handle the error
  });
    
  }
  },[clientSubmitTime])

 
 
  if(leadInfo
&& Object.keys(leadInfo).length === 0
&& Object.getPrototypeOf(leadInfo) === Object.prototype){
  totalPercentage = 0;
}
else{
  total += leadInfo?.name !== ""? 1 : 0;
  total += leadInfo?.email !== ""? 1 : 0;
  total += leadInfo?.phone !== ""? 1 : 0;
  total += leadInfo?.companyweb !== ""? 1 : 0;
  total += leadInfo?.projduration !== ""? 1 : 0;
  total += leadInfo?.description !== ""? 1 : 0; 
  
  totalPercentage = parseFloat((total * 100 / 6)).toFixed(0);
  
  
}
let countryCode = "";

try {
  if(leadInfo?.country?.length < 4){
    countryCode = "+"+getCountryCallingCode(leadInfo?.country);
  }

  
 
} catch (error) {
  //console.log(error)
}
  
//console.log(countryCode)


  return (
    <>
      <div className="lead_details_container overflow-auto h-100 p-3">
        <div className="client-progress-status">
          <div className="progressbar-label fw-semibold mb-1">Client's Form Filling Status</div>
          {/* Multi Color */}
          <CircularProgressBar percentage={totalPercentage} />
        </div>
        <div className="call-status d-flex align-items-center gap-2 my-3">
          <div className="status-label text-nowrap fw-semibold">Client Status:</div>
          <div className="status-badges d-flex flex-wrap">
            {  (leadInfo.presence == 1) && (leadInfo.streamerStatus === 2 || leadInfo.streamerStatus === 1 || leadInfo.streamerStatus === 3) && (leadInfo.state !== 'offline') ?
            <span className={`${ leadInfo.state === "offline"
                ? ""
                : 'green'
            } badge rounded-pill text-bg-danger `}>Currently On Form</span>
            :null }

         
            {  leadInfo.streamerStatus == 4 ?
            <span className="badge rounded-pill text-bg-danger green">Call Joined</span>
            :null }
            { leadInfo.presence == 0 && leadInfo.streamerStatus != 4 && leadInfo.streamerStatus !=0 ?
            <span className="badge rounded-pill text-bg-warning yellow">Away From Form</span>
            : null }

            { leadInfo.state == "offline" && leadInfo.streamerStatus != 4 ? 
            <span className="badge rounded-pill text-bg-danger">Left</span> 
            :null}
          </div>
        </div>

        {leadInfo.name ? (
          <div>
            <div className="lead-label text-capitalize">Name</div>
            <div className="lead-description mb-4">{leadInfo.name}</div>
          </div>
        ) : null}
        {leadInfo.companyweb ? (
          <div>
            <div className="lead-label text-capitalize">Website</div>
            <div className="lead-description mb-4">{leadInfo.companyweb}</div>
          </div>
        ) : null}

        {/* {leadInfo.ppcurl ? (
          <div>
            <div className="lead-label text-capitalize">PPC Page</div>
            <div className="lead-description mb-4">{leadInfo.ppcurl}</div>
          </div>
        ) : null} */}

        {countryCode ? (
          <div>
            <div className="lead-label text-capitalize">Calling Country Code</div>
            <div className="lead-description mb-4">{countryCode}</div> 
          </div>
        ) : null}

        {leadInfo.country ? (
          <div>
            <div className="lead-label text-capitalize">Country</div>
            <div className="lead-description mb-4">{leadInfo.country}</div>
          </div>
        ) : null}

        {leadInfo.description ? (
          <div>
            <div className="lead-label text-capitalize mb-1">
              Work Requirement
            </div>
            <div className="lead-description">{leadInfo.description}</div>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
}

export default connect(mapStoreStateToProps)(LeadDetails)