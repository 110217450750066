import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as Twilio from '@twilio/voice-sdk';
import { callAxios } from '../../utils/utils';
import { environment } from '../../environment';
import { intitializeDeviceIncoming } from '../../utils/pstn';
import Timer from "./Timer";
import axios from 'axios';
import SheelaModal from './SheelaModal';
import { useNavigate } from "react-router-dom";
import "../loading.css"
import $ from "jquery";
import { toast } from 'react-toastify';
import useTranscriptHandler from '../../Context/useTranscriptionHandler';
import { useWebSocket } from '../../Context/WebSocketContext';


const IncomingCallModal = ({ show, handleClose, callerName,user, previousUrl, userRole }) => {
  // console.log(user?.email)
  let navigate = useNavigate();
  const socket = useWebSocket();
  const [transcriptions, setTranscriptions_] = useState([]);
  useTranscriptHandler(user,setTranscriptions_, userRole);
  const transcriptionsRef = useRef(transcriptions);
  let fullTranscript_ = [];
  const [showLiveTranscript, setShowLiveTranscript] = useState(false);
// const toggleTranscriptChat = () => setShowLiveTranscript(!showLiveTranscript);
  const [device, setDevice] = useState(null);
  const [incomingCall, setIncomingCall] = useState(null);
  const [callConnected, setCallConnected] = useState(false);
  const [currentCall, setCurrentCall] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [country, setCountryName] = useState("");

  
  const [isSheelaView, setSheelaView] = useState(false);
  const [sheelaData, setDataForSheela] = useState({});
  const [contactNo, setContact] = useState("");
  const [topic, setTopic] = useState("");
  const [leadId, setLeadId] = useState("");
  const [opportunityId, setOpportunityId] = useState("");
  const [isInfoAvailable, setInfoAvailable] = useState(false);
  const identity = user?.email;
  const reverseString = (str) => {
    return str.split('').reverse().join('');
}


useEffect(() => {
  transcriptionsRef.current = transcriptions;
}, [transcriptions]);


  useEffect(() => {

    // console.log(transcriptions)
    const getToken = async () => {
     const tokenData = await callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/generate-incoming-call-token",{identity:identity},"post")
      return tokenData.data.token;
    };

    const setupDevice = async () => {
        const token = await getToken();
        // console.log('Token:', token);
        const twilioDevice = intitializeDeviceIncoming(token)
        
        
        twilioDevice.on('ready', () => {
            console.log('VE Device ready to receive calls');
          });
  
          twilioDevice.on('error', async(error) => {
            console.error('Twilio Device error:', error);
            if(error.code === 20104){
              console.log("Token expired or about to expire. Refreshing token...");
              // Call a function to refresh the token
              const token = await getToken();
              twilioDevice.updateToken(token.data);
            }
            else {
              // Handle other errors
              console.log('Device Error: ' + error.message);
          }
          });
  
          
  
          twilioDevice.on('offline', () => {
            console.log('VE Device is offline');
          });
  
          twilioDevice.on('registered', () => {
            console.log('VE Device registered');
          });
  
          twilioDevice.on('unregistered', () => {
            console.log('VE Device unregistered');
          });
  
          twilioDevice.on('registrationFailed', (error) => {
            console.error('VE Device registration failed:', error);
          });
  
        twilioDevice.on('incoming', async(call) => {
          // console.log('Incoming call:', call);
          
          

          call.on('cancel', (e) => {
            
            // console.log('Call canceled by caller');
            localStorage.setItem("currentlyOnIncomingCall",false)
            localStorage.setItem("receiver_email",null)
            setInfoAvailable(false)
            setIncomingCall(null); // Clear incoming call state or perform other actions
            setTopic(null)
            setLeadId(null)
            setOpportunityId(null)
            handleInfo(null, null,null)
           
            
          });

          call.on('accept', async(call) => {
            setShowLiveTranscript(true)
            setCallConnected(true)
            setShowLiveTranscript(true)
            // console.log('call accepted');
           
            if(socket)
            {
              socket.send(JSON.stringify({
                event: 'callreceived',
                callsid: call?.parameters?.CallSid, // Replace with dynamic speaker information as needed
                receiver_email: user?.email,
                receiver_name: user?.displayName,
                user_type: userRole?userRole:localStorage.getItem('userRole')

            }))
            }
            
               
            const encodedStr = btoa(btoa(btoa(btoa(call.parameters.From))))
            // console.log(encodedStr)
            
  
            const reverse = reverseString(encodedStr)
            setContact(reverse)
  
            callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/get-name",{id:reverse},"post").then(async(clientInfo)=>{
              await callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/save-incoming-call",{call_sid:call?.parameters?.CallSid,receiver_email:user?.email,receiver_name:user?.displayName,client_name:clientInfo.data.name,from:call.parameters.From,country:clientInfo.data.country,user_type:userRole},"post");
            })

            localStorage.setItem("currentlyOnIncomingCall",true)
            
          });

          call.on('disconnect', call => {
            setCallConnected(false)
            callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/upload-incoming-call-transcript",{callSid:call.parameters.CallSid,fullTranscript:transcriptionsRef.current,type:"inboundcall"},"post").then((uploadRes)=>{
              // console.log(uploadRes.status)
              if(uploadRes.status === 200){
                setTranscriptions_([])
                transcriptionsRef.current = [];
                localStorage.setItem("currentlyOnIncomingCall",false)
                localStorage.setItem("receiver_email",null)
                
                setInfoAvailable(false)
                // console.log('The call has been disconnected.');
                
               
                setTopic(null)
                setLeadId(null)
                setOpportunityId(null)
                handleInfo(null, null,null)
              }
              
            })
           
           });

           call.on('mute', (isMuted, call) => {
            // console.log("in mute section")
            // isMuted is true if the input audio is currently muted
            // i.e. The remote participant CANNOT hear local device's input
           
            // isMuted is false if the input audio is currently unmuted
            // i.e. The remote participant CAN hear local device's input
           
            isMuted ? console.log('muted') : console.log('unmuted');
           });

           call.on('reconnected', () => {
            console.log('The call has regained connectivity.')
          });

          call.on('reject', () => {
            // console.log('The call was rejected.');
            setInfoAvailable(false)
            setTopic(null)
            setLeadId(null)
            setOpportunityId(null)
            handleInfo(null, null,null)
           });

           call.on('ringing', hasEarlyMedia => {
            // console.log("ringing....")
            // showRingingIndicator();
            if (!hasEarlyMedia) {
              // playOutgoingRinging();
            }
          });

          const encodedStr = btoa(btoa(btoa(btoa(call.parameters.From))))
          // console.log(encodedStr)
          

          const reverse = reverseString(encodedStr)
          setContact(reverse)

          //  const getName = await callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/get-name",{id:reverse},"post");
          //  setDisplayName(getName.data.name);
          // setCountryName(getName.data.country);

          setCurrentCall(call);
          setIncomingCall(call);

          if(reverse.length > 0){
            const getPreviousCall = await callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/get-previous-call-details",{id:reverse},"post");

          if(getPreviousCall.data){
            setInfoAvailable(true)
           
            setDisplayName(getPreviousCall.data.client_name)
            setTopic(getPreviousCall.data.topic)
            setLeadId(getPreviousCall.data.leadId)
            setCountryName(getPreviousCall.data.country)
            setOpportunityId(getPreviousCall.data.opportunityid)
          }
          }

          

        });

        
  
        
      };
      if(user?.email)
      setupDevice();

    return () => {
      if (device) {
        device.destroy();
      }
    };
  }, [user?.email]);

  const acceptCall = () => {
    if (incomingCall) {
      incomingCall.accept();
      setIncomingCall(null);
      
    }
  };

  const rejectCall = () => {
    if (incomingCall) {
      incomingCall.reject();
      setIncomingCall(null);
      setTopic(null)
            setLeadId(null)
            handleInfo(null, null,null)
     
    }
  };

  const disconnectCall = () => {
    if (currentCall) {
      currentCall.disconnect();
      // console.log('Call disconnected');
      setCurrentCall(null);
      setTopic(null)
      setLeadId(null)
      setOpportunityId(null)
      handleInfo(null, null,null)
     
    }
  };

  const handleInfo = (leadId, topic,opportunityId) =>{
    // console.log("handle Info:::",leadId, topic)
    // document.getElementById("SheelaModal").style.display = "block";
    // document.getElementById("SheelaModal").classList.add("show");
    // setSheelaView(true);
    // e.preventDefault();
    navigate('/call-info',{ state: { leadId: leadId,topic:topic,opportunityId:opportunityId} });
  }

// console.log(transcriptions)

  return (
    <>
     {incomingCall ? 
       <div id="incomingCallPopup" className="incoming-call-modal bg-white rounded-3 shadow overflow-hidden text-center p-3 m-1">
  <div className='text-muted fs-14'>Incoming Call</div>
  {isInfoAvailable?
  <>
  
   <div className='caller-name text-black fs-18 fw-semibold text-capitalize mb-1'>{displayName} {leadId && leadId.length > 0 ? `[${country}]` : null}</div>
  <div className='caller-name text-primary fs-14 fw-semibold text-capitalize mb-1'>{topic}</div>
  </>
  :<div className="loader-qq"></div>
 
}
  <div className="btn-cover d-flex flex-wrap align-items-center justify-content-center gap-2 mt-3">
    <Button variant='danger' className="px-4" onClick={rejectCall}>Decline</Button>
    <Button variant='success' className="px-4" onClick={acceptCall}>Accept</Button>
    {isInfoAvailable && leadId && leadId.length > 0?
    <Button variant='warning' className="px-4" onClick={(e)=>{handleInfo(leadId,topic,opportunityId)}}>Info</Button>
    :null}
  </div>
</div>
:null}

{/* Calling Strip Alert */}
{callConnected?
<>
<div id="callingStrip" className="callingStrip position-fixed top-0 start-50 translate-middle-x bg-white border border-danger rounded shadow overflow-hidden w-100" style={{zIndex: '1055', maxWidth:"400px"}}>
        <div className="bg-danger bg-opacity-25 text-danger d-flex align-items-center justify-content-between gap-2 text-center fs-14 fw-semibold ps-3 pe-2 py-1">
        {/* <i class="bi bi-chat-left-text pointer" onClick={toggleTranscriptChat} style={{color:"navy"}} title='View Transcription'></i> */}
          <span className='caller-name fw-semibold text-truncate text-capitalize'>{displayName} </span>
          <div className='wrapper d-flex gap-2'>
          <span className='timer text-success'><Timer /></span>
          <i className="d-block bi bi-info-circle pointer text-primary" onClick={(e)=>{handleInfo(leadId,topic,opportunityId)}}></i> 
          </div>
          

        
            
          <Button variant='danger' size='sm' title='End call' className='d-flex align-align-items-center justify-content-center gap-2 rounded-pill text-nowrap lh-sm px-3' onClick={disconnectCall}>
            <i className="d-block bi bi-telephone-fill" style={{transform: 'rotate(135deg)'}}></i> End call
          </Button>
        </div>
        {showLiveTranscript && (
          <div className="chat-transcript-wrapper w-100 overflow-auto p-2" style={{maxHeight:'300px'}} id='messagesincoming-Area'>
            {/* <div className="message-outside">
              <div className="box-wrapper mb-2 d-flex align-items-baseline gap-2">
                <div className="user-pic position-relative" style={{ top: '23px' }}>
                  <div className="user-chip d-flex align-items-center justify-content-center text-uppercase bg-primary bg-opacity-10 fs-12 fw-semibold text-primary border border-primary rounded-circle" style={{ height: '25px', width: '25px' }}>AS</div>
                </div>
                <div className="message-card fs-12">
                  <div className="msg-label text-capitalize text-muted small" style={{ marginBottom: '2px' }}>12-01-2023 01:43 PM</div>
                  <div className="msg bg-secondary bg-opacity-10 text-body px-2 py-1 rounded">pahle se hi online the</div>
                </div>
              </div>
            </div> */}

            
          </div>
        )}
      </div>

      

</>

      
      :null}
      <SheelaModal setSheelaView={setSheelaView} isSheelaView={isSheelaView} sheelaData={sheelaData} />
    </>
   
    
  );
};



export default IncomingCallModal;
