import React, { useState, useEffect } from 'react';
import AmChart4Wrapper from "react-amcharts4";
import { PieChart } from "@amcharts/amcharts4/charts";

// Configure any reguired theme
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_material from "@amcharts/amcharts4/themes/material";

// Bar Chart
import * as am4charts from "@amcharts/amcharts4/charts";
import { MapChart } from './MapChart';
am4core.useTheme(am4themes_material);


const ReportsList = (props) => {

    const config = {
        // Create pie series
        series: [
            {
                type: "PieSeries",
                "innerRadius": 40,
                "ticks": {
                    "disabled": true,
                },
                // "children": [{
                //     "type": "Label",
                //     "forceCreate": true,
                //     "text": "${values.value.sum}",
                //     "horizontalCenter": "middle",
                //     "verticalCenter": "middle","fill": "#fff","fontSize": 14,
                // }],
                "labels": {
                    "bent": true,
                    "paddingTop": 0,
                    "paddingRight": 0,
                    "paddingBottom": 0,
                    "paddingLeft": 0,
                    "fontSize": 14,
                    "text": "{value.percent.formatNumber('#.0')}%",
                    "radius": "-20%",
                    "fill": "#fff",
                    "relativeRotation": 90
                },
                dataFields: {
                    value: "litres",
                    category: "country"
                },
                alignLabels: false,
            }
        ],

        // Add data
        data: [
            {
                country: "Lithuania",
                litres: 501.9
            },
            {
                country: "Czech Republic",
                litres: 301.9
            },
            {
                country: "Ireland",
                litres: 201.1
            },
            {
                country: "Germany",
                litres: 165.8
            },
        ]
    };

    const config1 = {
        // Create pie series
        series: [{
            type: "PieSeries",
            "innerRadius": 30,
            "ticks": {
                "disabled": true,
            },
            "labels": {
                "disabled": true,
            },
            dataFields: {
                value: "litres",
                category: "country"
            }
        }],

        "legend": {
            itemContainers: {
                template: {
                    paddingTop: 2, // default 8
                    paddingBottom: 0, // default 8
                    fontSize: 14,
                }
            },
            "labels": {
                "truncate": true,
                "wrap": true,
            },
            "wrap": true,
            "useDefaultMarker": true,
            "markers": {
                "template": {
                    paddingTop: 3,
                    "children": [{
                        width: 14,
                        height: 14,
                        cornerRadiusTopLeft: 14,
                        cornerRadiusTopRight: 14,
                        cornerRadiusBottomRight: 14,
                        cornerRadiusBottomLeft: 14,
                    }],
                },
            }
        },

        // Add data
        data: [
            {
                country: "Lithuania",
                litres: 501.9
            },
            {
                country: "Czech Republic",
                litres: 301.9
            },
            {
                country: "Ireland",
                litres: 201.1
            },
            {
                country: "Germany",
                litres: 165.8
            }
        ]
    };

    const config3 = {
        // Create pie series
        series: [
            {
                type: "PieSeries",
                "ticks": {
                    "disabled": true,
                },
                "labels": {
                    "bent": true,
                    "paddingTop": 0,
                    "paddingRight": 0,
                    "paddingBottom": 0,
                    "paddingLeft": 0,
                    "radius": 10,
                    // fill: "#fff",
                    fontSize: 14,

                },
                dataFields: {
                    value: "litres",
                    category: "country"
                },
                alignLabels: false,
            }
        ],

        // Add data
        data: [
            {
                country: "Lithuania",
                litres: 501.9
            },
            {
                country: "Czech Republic",
                litres: 301.9
            },
            {
                country: "Ireland",
                litres: 201.1
            },
            {
                country: "Germany",
                litres: 165.8
            },
        ]
    };

    const config4 = {
        // Create pie series
        series: [
            {
                type: "PieSeries",
                dataFields: {
                    value: "litres",
                    category: "country"
                },
                "slices": {
                    "stroke": "#fff",
                    "strokeWidth": 1,
                    "strokeOpacity": 1
                }
                //alignLabels: false,
            }
        ],


        // Add data
        data: [
            {
                country: "Lithuania",
                litres: 501.9
            },
            {
                country: "Czech Republic",
                litres: 301.9
            },
            {
                country: "Ireland",
                litres: 201.1
            },
            {
                country: "Germany",
                litres: 165.8
            }, {
                country: "Lithuania",
                litres: 501.9
            },
            {
                country: "Czech Republic",
                litres: 301.9
            },
            {
                country: "Ireland",
                litres: 201.1
            },
            {
                country: "Germany",
                litres: 165.8
            },
        ]
    };


    useEffect(() => {
        // Create chart instance
        let chart = am4core.create("chartdiv", am4charts.XYChart3D);
        // Bar Chart // Create chart instance
        chart.data = [{
            "country": "Lithuania",
            "research": 501.9,
            "marketing": 250,
            "sales": 199
        }, {
            "country": "Czech Republic",
            "research": 301.9,
            "marketing": 222,
            "sales": 251
        }, {
            "country": "Ireland",
            "research": 201.1,
            "marketing": 170,
            "sales": 199
        }, {
            "country": "Germany",
            "research": 165.8,
            "marketing": 122,
            "sales": 90
        }, {
            "country": "Australia",
            "research": 139.9,
            "marketing": 99,
            "sales": 252
        }, {
            "country": "Austria",
            "research": 128.3,
            "marketing": 85,
            "sales": 84
        }, {
            "country": "UK",
            "research": 99,
            "marketing": 93,
            "sales": 142
        }, {
            "country": "Belgium",
            "research": 60,
            "marketing": 50,
            "sales": 55
        }, {
            "country": "The Netherlands",
            "research": 50,
            "marketing": 42,
            "sales": 25
        }];

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "country";
        categoryAxis.title.text = "Local country offices";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Expenditure (M)";

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "research";
        series.dataFields.categoryX = "country";
        series.name = "Research";
        series.tooltipText = "{name}: [bold]{valueY}[/]";
        series.stacked = true;

        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "marketing";
        series2.dataFields.categoryX = "country";
        series2.name = "Marketing";
        series2.tooltipText = "{name}: [bold]{valueY}[/]";
        series2.stacked = true;

        var series3 = chart.series.push(new am4charts.ColumnSeries());
        series3.dataFields.valueY = "sales";
        series3.dataFields.categoryX = "country";
        series3.name = "Sales";
        series3.tooltipText = "{name}: [bold]{valueY}[/]";
        series3.stacked = true;
        series3.columns.template.column.cornerRadiusTopLeft = 10;
        series3.columns.template.column.cornerRadiusTopRight = 10;

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        // Add legend
        chart.legend = new am4charts.Legend();

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // Modify chart's colors
        chart.colors.list = [
            am4core.color("#845EC2"),
            am4core.color("#D65DB1"),
            am4core.color("#FF6F91"),
            am4core.color("#FF9671"),
            am4core.color("#FFC75F"),
            am4core.color("#F9F871"),
        ];


        // Second Bar Chart
        // Create chart instance
        var chart1 = am4core.create("chartdivv", am4charts.XYChart);

        // Add data
        chart1.data = [{
            "date": new Date(2018, 0, 1),
            "value": 450,
            "value2": 362,
            "value3": 699
        }, {
            "date": new Date(2018, 0, 2),
            "value": 269,
            "value2": 450,
            "value3": 841
        }, {
            "date": new Date(2018, 0, 3),
            "value": 700,
            "value2": 358,
            "value3": 699
        }, {
            "date": new Date(2018, 0, 4),
            "value": 490,
            "value2": 367,
            "value3": 500
        }, {
            "date": new Date(2018, 0, 5),
            "value": 500,
            "value2": 485,
            "value3": 369
        }, {
            "date": new Date(2018, 0, 6),
            "value": 550,
            "value2": 354,
            "value3": 250
        }, {
            "date": new Date(2018, 0, 7),
            "value": 420,
            "value2": 350,
            "value3": 600
        }];

        // Create axes
        var dateAxis = chart1.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 30;

        var valueAxis = chart1.yAxes.push(new am4charts.ValueAxis());

        valueAxis.events.on("ready", function (ev) {
            ev.target.min = ev.target.min;
            ev.target.max = ev.target.max;
        })

        // Create series
        function createSeries(field, name) {
            var series = chart1.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.name = name;
            series.tooltipText = "{dateX}: [b]{valueY}[/]";
            series.strokeWidth = 2;

            return series;
        }

        createSeries("value", "Series #1");
        createSeries("value2", "Series #2");
        createSeries("value3", "Series #3");

        chart1.legend = new am4charts.Legend();
        chart1.cursor = new am4charts.XYCursor();
        chart1.scrollbarX = new am4core.Scrollbar();
    });


    return (
        <div className="container py-4" >
            <div className="main-heading text-white text-opacity-75 mb-3">Reports Listing</div>
            <MapChart />
            <div className="amchart-wrapper mb-3">
                <div className="accordion accordion-flush" id="pieChart">
                    {/* First */}
                    <div className="accordion-item border-0 rounded overflow-hidden mb-3">
                        <h2 className="accordion-header" id="heading1">
                            <button className="accordion-button collapsed bg-primary bg-gradient text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                Pie Chart Analysis 1
                            </button>
                        </h2>
                        <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#pieChart">
                            <div className="accordion-body rounded-bottom p-3">
                                <div className="alert alert-danger alert-dismissible fade show small text-md-center" role="alert">
                                    <strong>Note: </strong>This data is taking test data, too, into consideration in these reports. In the coming days, it will get filtered.
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div className="row g-3">
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="chart-heading">How many clients clicked on Join A Live Button vs not?</div>
                                        <div style={{ height: '300px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config}
                                                id="amcharts-11"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="chart-heading">How many clients connected today vs not?</div>
                                        <div style={{ height: '300px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config}
                                                id="amcharts-22"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="chart-heading">How many clients connected today vs not?</div>
                                        <div style={{ height: '300px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config}
                                                id="amcharts-33"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Second */}
                    <div className="accordion-item border-0 rounded overflow-hidden mb-3">
                        <h2 className="accordion-header" id="heading2">
                            <button className="accordion-button collapsed bg-primary bg-gradient text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                Pie Chart Analysis 2
                            </button>
                        </h2>
                        <div id="collapse2" className="accordion-collapse collapse show" aria-labelledby="heading2" data-bs-parent="#pieChart">
                            <div className="accordion-body rounded-bottom p-3">
                                <div className="alert alert-danger alert-dismissible fade show small text-md-center" role="alert">
                                    <strong>Note: </strong>This data is taking test data, too, into consideration in these reports. In the coming days, it will get filtered.
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div className="row g-3">
                                    <div className="col-sm-6 col-lg-3">
                                        <div className="chart-heading">How many clients clicked on Join A Live Button vs not?</div>
                                        <div style={{ height: '340px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config1}
                                                id="amcharts-1"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <div className="chart-heading">How many clients connected today vs not?</div>
                                        <div style={{ height: '340px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config1}
                                                id="amcharts-2"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <div className="chart-heading">Total Missed By CAM vs Joined By CAM Today?</div>
                                        <div style={{ height: '340px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config1}
                                                id="amcharts-3"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <div className="chart-heading">How many leads submit to CRM vs Not?</div>
                                        <div style={{ height: '340px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config1}
                                                id="amcharts-4"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Third */}
                    <div className="accordion-item border-0 rounded overflow-hidden mb-3">
                        <h2 className="accordion-header" id="heading3">
                            <button className="accordion-button collapsed bg-primary bg-gradient text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                Pie Chart Analysis 3
                            </button>
                        </h2>
                        <div id="collapse3" className="accordion-collapse collapse show" aria-labelledby="heading3" data-bs-parent="#pieChart">
                            <div className="accordion-body rounded-bottom p-3">
                                <div className="alert alert-danger alert-dismissible fade show small text-md-center" role="alert">
                                    <strong>Note: </strong>This data is taking test data, too, into consideration in these reports. In the coming days, it will get filtered.
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div className="row g-3">
                                    <div className="col-sm-6 col-lg-3">
                                        <div className="chart-heading">How many clients clicked on Join A Live Button vs not?</div>
                                        <div style={{ height: '340px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config3}
                                                id="amcharts-5"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <div className="chart-heading">How many clients connected today vs not?</div>
                                        <div style={{ height: '340px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config3}
                                                id="amcharts-6"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <div className="chart-heading">Total Missed By CAM vs Joined By CAM Today?</div>
                                        <div style={{ height: '340px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config3}
                                                id="amcharts-7"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <div className="chart-heading">How many leads submit to CRM vs Not?</div>
                                        <div style={{ height: '340px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config3}
                                                id="amcharts-8"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Fourth */}
                    <div className="accordion-item border-0 rounded overflow-hidden mb-3">
                        <h2 className="accordion-header" id="heading4">
                            <button className="accordion-button collapsed bg-primary bg-gradient text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                Pie Chart Analysis 4
                            </button>
                        </h2>
                        <div id="collapse4" className="accordion-collapse collapse show" aria-labelledby="heading4" data-bs-parent="#pieChart">
                            <div className="accordion-body rounded-bottom p-3">
                                <div className="alert alert-danger alert-dismissible fade show small text-md-center" role="alert">
                                    <strong>Note: </strong>This data is taking test data, too, into consideration in these reports. In the coming days, it will get filtered.
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div className="row g-3">
                                    <div className="col-sm-12">
                                        <div className="chart-heading">How many clients clicked on Join A Live Button vs not?</div>
                                        <div style={{ height: '400px', width: '100%' }}>
                                            <AmChart4Wrapper
                                                config={config4}
                                                id="amcharts-00"
                                                chartTypeClass={PieChart}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Fifth */}
                    <div className="accordion-item border-0 rounded overflow-hidden mb-3">
                        <h2 className="accordion-header" id="heading5">
                            <button className="accordion-button collapsed bg-primary bg-gradient text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                Bar Chart Analysis
                            </button>
                        </h2>
                        <div id="collapse5" className="accordion-collapse collapse show" aria-labelledby="heading5" data-bs-parent="#pieChart">
                            <div className="accordion-body rounded-bottom p-3">
                                <div className="alert alert-danger alert-dismissible fade show small text-md-center" role="alert">
                                    <strong>Note: </strong>This data is taking test data, too, into consideration in these reports. In the coming days, it will get filtered.
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div className="row g-3">
                                    <div className="col-sm-12">
                                        <div className="chart-heading">How many clients clicked on Join A Live Button vs not?</div>
                                        <div id="chartdiv" style={{ height: '400px', width: '100%' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Sixth */}
                    <div className="accordion-item border-0 rounded overflow-hidden mb-3">
                        <h2 className="accordion-header" id="heading6">
                            <button className="accordion-button collapsed bg-primary bg-gradient text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                Bar Chart Analysis
                            </button>
                        </h2>
                        <div id="collapse6" className="accordion-collapse collapse show" aria-labelledby="heading6" data-bs-parent="#pieChart">
                            <div className="accordion-body rounded-bottom p-3">
                                <div className="alert alert-danger alert-dismissible fade show small text-md-center" role="alert">
                                    <strong>Note: </strong>This data is taking test data, too, into consideration in these reports. In the coming days, it will get filtered.
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div className="row g-3">
                                    <div className="col-sm-12">
                                        <div className="chart-heading">How many clients clicked on Join A Live Button vs not?</div>
                                        <div id="chartdivv" style={{ height: '400px', width: '100%' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ReportsList;