import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class CamJoinedMissed extends Component {

  componentDidMount() {
     this.initChart()
  }
  componentDidUpdate(prevProps) {
    
    // if(prevProps) {
    //   this.chart.dispose();
    // }
    this.initChart();
    
  }
  
  initChart(){
    am4core.useTheme(am4themes_animated);

    var chart = am4core.create("cam-joined-or-not", am4charts.PieChart);

    chart.hiddenState.properties.opacity = 0;
    
    chart.data = [
        {
            category: this.props.label1,
            value: this.props.joined?this.props.joined:0,
            color:  am4core.color("#235789")
        },
        {
            category: this.props.label2,
            value: this.props.notJoined?this.props.notJoined:0,
            color: am4core.color("#F1D302")
        },
        
      ];


    var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
            
        // Let's cut a hole in our Pie chart the size of 40% the radius
        chart.innerRadius = am4core.percent(40);
        
        // Put a thick white border around each Slice
        pieSeries.slices.template.stroke = am4core.color("#4a2abb");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;

        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
        pieSeries.colors.list = [
            am4core.color("#008000"),
            am4core.color("#D22B2B"),
        ];
    
    
        // Add a legend
        chart.legend = new am4charts.Legend();

        chart.legend.markers.template.paddingTop= 3;
        chart.legend.position = 'bottom'
        // chart.legend.markers.template.width = 13;
        // chart.legend.markers.template.height = 13;
        
        chart.legend.useDefaultMarker = true;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        marker.height = 14;
        marker.width = 14;
        chart.legend.itemContainers.template.paddingTop = 2;
        chart.legend.itemContainers.template.paddingBottom = 0;
        chart.legend.itemContainers.template.fontSize = 14;
            
  }
    

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  
  render() {
    return <div id="cam-joined-or-not" style={{ width: "100%", height: "270px", margin: "0 auto" }} />;
  }

 
}

export default CamJoinedMissed;
