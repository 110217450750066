import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, database, auth } from "../../firebaseConfig";
import { callAxios, callCrmApi } from "../../utils/utils";
import { environment } from "../../environment";
import { Modal, Button } from 'react-bootstrap';
import $ from "jquery";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, doc, getDocs, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { useEffect } from "react";

export const SheelaUpdate = ({show, setShow, setCrmPayload, crmPayload, setSpeakerRating, roomId,setshowCallPerformance, setShowCrmUpdate,setShowLoadr, room}) => {
//   console.log(crmPayload)
  const [user, loading, error] = useAuthState(auth);
  const transcript_url = '';
  const handleClose = () => setShow(false);
    
  
    const [isLoader, setIsLoader] = useState(true)
    const [description, setdescription] = useState('')

    const [getSheelaUpdate, setSheelaUpdate] = useState({});

    const [isAgree, setIsAgree] = useState(true)


  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return { year, month, day };
  };

  useEffect(()=>{
    // console.log("in call evaluation::",environment.REACT_APP_TRANSCRIPTION_URL)
    const { year, month, day } = getCurrentDate();
    callAxios(`${environment.REACT_APP_TRANSCRIPTION_URL}`,{transcription_url:`https://video-call-transcription.s3.us-east-1.amazonaws.com/video-transcription/${year}/${month}/${day}/${room?.sid}.json`},"post").then((callEvaluationData)=>{
        setIsLoader(false)
        //   console.log("call evaluation:::", callEvaluationData.data)
        setSheelaUpdate(callEvaluationData.data)
    })
},[])




  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  const StarRating = () => {
    return (
      <div className="rating-wrapper d-flex gap-2 fs-5">
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <i
              className={
                index <= (hover || rating)
                  ? "bi bi-star-fill text-primary"
                  : "bi bi-star text-secondary"
              }
              key={"star-" + index}
              onClick={() => setRating(index)}
              onDoubleClick={() => {
                setRating(0);
                setHover(0);
              }}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            ></i>
          );
        })}
      </div>
    );
  };



  const redirect = (url) => {
    //window.location.href = url;
    window.close();
  };


const submitCRMUpdate = async(crmUpdateData, allDataJson) =>{
  //  console.log(allDataJson)
 
  setShowCrmUpdate(false)
  setshowCallPerformance(true)
 
  if(allDataJson && allDataJson.speakers_rating.ratings.length > 0){
      allDataJson.speakers_rating.ratings.forEach((rating)=>{
          if(rating.user_type === "CAM")
              setSpeakerRating({
                  rating:rating.rating,
                  suggestion:rating.reason.suggestion_for_improvement,
                  reason:rating.reason.details
          })
      })
  }
  
  let key_points_discussed_items = ''
  crmUpdateData.key_points_discussed.forEach((item)=>{
      key_points_discussed_items += `<li>${item}</li>`
  })

  let actionItems = ''
  crmUpdateData.action_items.forEach((item)=>{
      actionItems += `<li>${item}</li>`
  })

  let next_steps = ''
  crmUpdateData.next_steps.forEach((item)=>{
      next_steps += `<li>${item}</li>`
  })

 

  let bodyHTML = `${crmPayload.notetext}<div style="padding:10px;margin-top:10px;color: #0d6efd;font-size: 1rem;font-weight:bold;border:2px solid #0d6efd29">CRM Updates Ready by Sheela</div><div class="CRM-updates-wrapper" style="background-color:#f4f4f4;padding:30px 20px;">
  <div class="content-info" style="margin-bottom:20px">
      <div class="label-name" style="color:#000;font-size:16px;font-weight:600">Participants on call</div>
      <div class="label-description" style="color:#2b3036;font-size:14px">${crmUpdateData.participants.join(", ")}</div>
  </div>
  <div class="content-info" style="margin-bottom:20px">
      <div class="label-name" style="color:#000;font-size:16px;font-weight:600">Meeting Details</div>
      <div class="label-description" style="color:#2b3036;font-size:14px">${crmUpdateData.meeting_details}</div>
  </div>
  <div class="content-info" style="margin-bottom:20px">
      <div class="label-name" style="color:#000;font-size:16px;font-weight:600">Meeting Objective</div>
      <div class="label-description" style="color:#2b3036;font-size:14px">${crmUpdateData.meeting_objectives}</div>
  </div>
  <div class="content-info" style="margin-bottom:20px">
      <div class="label-name" style="color:#000;font-size:16px;font-weight:600">Meeting Outcome</div>
      <div class="label-description" style="color:#2b3036;font-size:14px">${crmUpdateData.meeting_outcome}</div>
  </div>
  <div class="content-info" style="margin-bottom:20px">
      <div class="label-name" style="color:#000;font-size:16px;font-weight:600">Key Points Discussed</div>
      <div class="label-description" style="color:#2b3036;font-size:14px">
      <ul>
      ${key_points_discussed_items}
      </ul>
      </div>
  </div>
  <div class="content-info" style="margin-bottom:20px">
      <div class="label-name" style="color:#000;font-size:16px;font-weight:600">Next Steps</div>
      <div class="label-description" style="color:#2b3036;font-size:14px">
       <ul>
      ${next_steps}
      </ul>
      </div>
  </div>
  <div class="content-info" style="margin-bottom:20px">
      <div class="label-name" style="color:#000;font-size:16px;font-weight:600">Action Items</div>
      <div class="label-description" style="color:#2b3036;font-size:14px">
      <ul>
      ${actionItems}
      </ul>
      </div>
  </div>
  <div class="content-info" style="margin-bottom:20px">
      <div class="label-name" style="color:#000;font-size:16px;font-weight:600">Follow Ups</div>
      <div class="label-name" style="color:#000;font-size:14px;font-weight:600">Is Follow Up Required: <span class="text-uppercase">${crmUpdateData.follow_up.required}</span></div>
      <div class="label-description" style="color:#2b3036;font-size:14px">${crmUpdateData.follow_up.details}</div>
  </div>
    
</div>`;
    
  // console.log(data)
  let apiData;
  if(crmPayload.userType === "cam"){
    apiData = {
        description_cam:allDataJson,
        roomId:roomId,
        leadId:crmPayload.leadid,
        crm_title:crmPayload.new_leadid,
        is_sheela_updated_cam:true,
        type:"update"
   }
  } else{
    apiData = {
        description_bdm:allDataJson,
        roomId:roomId,
        leadId:crmPayload.leadid,
        crm_title:crmPayload.new_leadid,
        is_sheela_updated_bdm:true,
        type:"update"
   }
  }
  
 

  callAxios(environment.REACT_APP_API_URL_Live+"api/v2/crm/save-crm-update-video",apiData,"post").then((res)=>{
      // console.log(res.status,res.data.systemuserid, crmData)
      if(res.status === 200){
        window.onbeforeunload = null;

      
      let data;
    
          data = JSON.stringify({
              "operation":crmPayload.operation,
              "leadid":crmPayload.leadid,
              "subject":crmPayload.subject,
              "notetext":bodyHTML,
              "MSCRMCallerID":crmPayload.MSCRMCallerID
          })
      
      
      callCrmApi(data).then(async(res)=>{
          // console.log(res.data.data)
          let apiUpdateData;
          if(crmPayload.userType === "cam"){
            apiUpdateData = {
                roomId:roomId,
                type:"update",
                crm_api_response_cam:res.data.data
           }
          }
          else{
            apiUpdateData = {
                roomId:roomId,
                type:"update",
                crm_api_response_bdm:res.data.data
           }
          }
          
         await callAxios(environment.REACT_APP_API_URL_Live+"api/v2/crm/save-crm-update-video",apiUpdateData,"post")
         window.onbeforeunload = null;
         setShowLoadr(false)
      }).catch((err)=>{
          console.log(err)
      })
      }
     

  })
  
  
}
  return (
    <div>
      {/* <ToastContainer /> */}
      {/* BDM Question After Call Section */}
      <div className="bdm-question-after-call" >
      <Modal  show={show} onHide={handleClose} centered size='lg' backdrop="static" keyboard={false} contentClassName="border border-5 border-black">
            {isLoader ?
            <>
            <div className='loading-wrapper bg-primary bg-opacity-10 text-center p-4'>
                <div className='img-wrapper mb-4 text-center'>
                    <img src={require("../../resources/images/meeting-girl2.png")} className='img-fluid' alt='loader' style={{maxWidth:"150"}} />
                </div>
                <div className="spinner-grow text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className='loading-text text-black fs-18 fw-medium'>Please wait a moment! Sheela is analysing..</div>
            </div>

            {/* <div className="meeting-pointer-spinner p-5"></div> */}
            </>
            :
            
            <Modal.Body className="bg-secondary bg-opacity-25 px-3 px-lg-4 py-4">
                {getSheelaUpdate?
                <div className="CRM-updates-wrapper">
                    <div className="header mb-4">
                        <h5 className="text-primary fw-semibold mb-0">CRM Updates Ready:</h5>
                        <div className="text-body fs-12">This call update has been prepared by me (Sheela). I have included all the key discussions, next steps and follow-up details if required.</div>
                    </div>
                    <div className="content-info mb-3 pb-1">
                        <div className="label-name text-black fs-6 fw-semibold">Participants on call</div>
                        <div className="label-description fs-14 text-body">{getSheelaUpdate?.crm_update?.participants?.join(", ")}</div>
                    </div>
                    <div className="content-info mb-3 pb-1">
                        <div className="label-name text-black fs-6 fw-semibold">Meeting Details</div>
                        <div className="label-description fs-14 text-body">{getSheelaUpdate?.crm_update?.meeting_details}</div>
                    </div>
                    <div className="content-info mb-3 pb-1">
                        <div className="label-name text-black fs-6 fw-semibold">Meeting Objective</div>
                        <div className="label-description fs-14 text-body">{getSheelaUpdate?.crm_update?.meeting_objectives}</div>
                    </div>
                    <div className="content-info mb-3 pb-1">
                        <div className="label-name text-black fs-6 fw-semibold">Meeting Outcome</div>
                        <div className="label-description fs-14 text-body">{getSheelaUpdate?.crm_update?.meeting_outcome}</div>
                    </div>
                    <div className="content-info mb-3 pb-1">
                        <div className="label-name text-black fs-6 fw-semibold">Key Points Discussed</div>
                        <div className="label-description fs-14 text-body">
                        <ul>
                        {getSheelaUpdate?.crm_update?.key_points_discussed.map((item)=>{
                            return <li>{item}</li>
                        })}
                        </ul>
                        
                        </div>
                    </div>
                    <div className="content-info mb-3 pb-1">
                        <div className="label-name text-black fs-6 fw-semibold">Next Steps</div>
                        <div className="label-description fs-14 text-body">
                        <ul>
                        {getSheelaUpdate?.crm_update?.next_steps.map((item)=>{
                            return <li>{item}</li>
                        })}
                        </ul>
                        </div>
                    </div>
                    <div className="content-info mb-3 pb-1">
                        <div className="label-name text-black fs-6 fw-semibold">Action Items</div>
                        <div className="label-description fs-14 text-body">
                            <ul>
                            {getSheelaUpdate?.crm_update?.action_items.map((item)=>{
                            return <li>{item}</li>
                        })}
                            </ul>
                             
                        </div>
                    </div>
                    <div className="content-info mb-3 pb-1">
                        <div className="label-name text-black fs-6 fw-semibold">Follow Ups</div>
                        <div className="text-black fs-14 fw-semibold">Is Follow Up Required: <span className="text-uppercase">{getSheelaUpdate?.crm_update?.follow_up?.required}</span></div>
                        <div className="label-description fs-14 text-body">{getSheelaUpdate?.crm_update?.follow_up?.details}</div>
                    </div>
                    <div className="content-info mb-3 pb-1">
                        <div className="label-name text-black fs-14 fw-semibold mb-2">In case you need to add something extra that Sheela missed.</div>
                        <div className="">
                            <textarea name="" rows={4} id="" className="form-control fs-14 shadow-none rounded-0 p-3" style={{backgroundColor:'#aca7a8'}} onChange={(e)=>{setdescription(e.target.value)}}></textarea>
                        </div>
                    </div>
                    <div class="form-check d-lg-flex justify-content-lg-center gap-2 mb-3">
                        <input class="form-check-input" type="checkbox" value="" id="checkAgreement" checked />
                        <label class="form-check-label text-black fs-14" for="checkAgreement" style={{marginTop:'2px'}}>
                            Yes, I, {user?.displayName}, confirm that Sheela's update is correct and includes all the details.
                        </label>
                    </div>
                    {isAgree?
                    <div className="btn-wrapper text-center">
                        <Button variant='success' size='sm' className="rounded-pill px-4 py-2" onClick={()=>{submitCRMUpdate(getSheelaUpdate.crm_update,getSheelaUpdate)}}>Submit To CRM</Button>
                    </div>
                    :
                    <div className="btn-wrapper text-center">
                    <Button variant='success' size='sm' className="rounded-pill px-4 py-2" disabled>Submit To CRM</Button>
                </div>
                    }
                </div>
                :null}
            </Modal.Body>
            }

        </Modal>
        
      </div>

    </div>
  );
};