import React, { useState } from 'react'
import SendMessageButton from '../../resources/images/sendMessageButton.svg'
import { sendMessageUsingDataChannel } from '../../utils/twilioUtils'

const NewMessage = () => {
    const [message, setMessage] = useState("")

    const sendMessage = () => {
        //sendMessage to other user
        // console.log(message)
        sendMessageUsingDataChannel(message, true,"chat")
        setMessage('')
    }

    const handleKeyPressed = (event) => {
        if (event.key == 'Enter') {
            event.preventDefault()
            //sendMessage to other user
            sendMessage()
        }
    }

    const handleTextChange = (event) => {
        setMessage(event.target.value)
    }

    return (
        <div className='new_message_container d-flex align-items-center position-relative border-top border-black p-3'>
            <input className='new_message_input bg-transparent rounded-pill'
                value={message}
                onChange={handleTextChange}
                placeholder='Type your message'
                type='text'
                onKeyDown={handleKeyPressed}

            />
            <img
                className='new_messsage_button'
                src={SendMessageButton}
                onClick={sendMessage}

            />
        </div>
    )
}

export default NewMessage