import React, { useState } from 'react'
import VideoButtons from './VideoButtons'
import Videos from './Videos'
import { connectToRoom } from '../../utils/twilioUtils'
import { useEffect } from 'react'
import VideoButtonsPrivate from './VideoButtonsPrivate'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { database } from '../../firebaseConfig'
import { environment } from '../../environment'
import VideosPrivate from './VideosPrivate'

 const VideoSectionPrivate = (props) => {
  const { comingFrom, isCam, camera, isMicMuted, isRejoin,roomId,docId,callType,isTryingToJoining } = props;

  //console.log(roomId)
  
  const [room, setRoom] = useState(null);

 

  
  return (
    <>
      <VideoButtonsPrivate room={room} comingFrom={comingFrom} isCam={isCam} setFormIdAction={null} camera={camera} isMicMuted={isMicMuted} RMID={roomId} docId={docId} callType={callType}/>
      <div className='video_section_container'>
       
        <VideosPrivate room={room} setRoom={setRoom} isCam={isCam} comingFrom={comingFrom} camera={camera} isMicMuted={isMicMuted} RMID={roomId} isTryingToJoining={isTryingToJoining}/>
      </div>
    </>

  )
  
}

export default VideoSectionPrivate