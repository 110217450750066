import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { callAxios, getProfileData } from '../../utils/utils';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
import { Confirm } from './Confirm';

export const Presentation = ({ showPresentationModal, closePresentationModal,  setProfileData, profileId, setShowPresentationModal, isUpdateAction, updateData, setIsLoading }) => {

	const [title, setTitle] = useState("");
	const [url, setUrl] = useState("");
 	const [description, setDescription] = useState("");


	useEffect(() => {
		if (isUpdateAction) {
      		setTitle(updateData?.title);
			setUrl(updateData?.url);
			setDescription(updateData?.description)
		} else {
			setTitle("");
			setUrl("");
      		setDescription("")
		}
	}, [updateData]);

  const handlePresentationSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true)
		setShowPresentationModal(false);
		// console.log(socialProfileName, socialUrl, socialDescription)
		if (isUpdateAction) {
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-presentation", { profile_id: profileId, title: title, url: url, description: description, type: "update", id: updateData?.id }, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
					setProfileData(await getProfileData(profileId,setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
					
				} else {
					setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		} else {
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/add-presentation", { profile_id: profileId, title: title, url: url, description: description }, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
					setProfileData(await getProfileData(profileId,setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
				
				} else {
					setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		}

	}
	

	// Show Delete Profile Modal
	const [showDeleteProfile, setShowDeleteProfile] = useState(false);
	const closeDeleteProfile = () => setShowDeleteProfile(false);
	const openDeleteProfile = () => setShowDeleteProfile(true);

	return (
		<>
			<Modal show={showPresentationModal} centered size='lg' onHide={closePresentationModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
			<Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
				<div className="close-wrapper text-end">
					<Button variant="link" onClick={closePresentationModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
				</div>

				<div className="heading-wrapper mb-4">
					<div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
						<span className='widgetTitle text-black fs-5 fw-semibold'>Presentation</span>
						{/* This button will show only when you click to edit the details */}
						{isUpdateAction?
							<span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0 pointer' onClick={openDeleteProfile}>Delete</span>
							:null}
					</div>
					<p className='subTitle fs-14'>Add links to your online presentations (e.g. Slideshare presentation links etc.).</p>
				</div>

				<Form action="" className="form-cover" onSubmit={handlePresentationSubmit}>
					<Form.Group className="mb-3">
						<Form.Label>Presentation title<span className='text-danger'>*</span></Form.Label>
						<Form.Control type="text" placeholder="Enter presentation title" autoComplete='off' value={title} onChange={(e) => { setTitle(e.target.value) }} required />
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>URL<span className='text-danger'>*</span></Form.Label>
						<Form.Control type="url" value={url} onChange={(e) => { setUrl(e.target.value) }} placeholder="Enter URL here" autoComplete='off' required />
					</Form.Group>

					<Form.Group>
						<Form.Label>Description</Form.Label>
						<Form.Control as="textarea" value={description} onChange={(e) => { setDescription(e.target.value) }} rows={5} placeholder='Type here...' maxLength={500} autoComplete='off' />
						<div className="charLenght fs-14 text-clr2 text-end mt-2">500 character(s) left</div>
					</Form.Group>
					
					<div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
						<Button variant="danger" onClick={closePresentationModal} className='rounded-pill px-4'>Cancel</Button>
						<Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>

		<Confirm showDeleteProfile={showDeleteProfile} closeDeleteProfile={closeDeleteProfile} profileId={profileId} id={updateData?.id} message={updateData?.title} setShowPresentationModal={setShowPresentationModal} setShowDeleteProfile={setShowDeleteProfile} setIsLoading={setIsLoading} setProfileData={setProfileData} type="presentation"/>

		</>
		
	)
}

