import { convertReadable, convertReadableTime } from "../../utils/utils";

const ProfileCreated = ({ profileData, otherData, cardType, userType }) => {
	// console.log(props.otherData)
	// const intTime = parseInt(profileData.interview_time)> 12 && parseInt(profileData.interview_time) < 24 ? profileData.interview_time + " PM" : profileData.interview_time + " AM";
	if(userType === "SA" || userType === "SSA"){
		return (
			<div className="info-cards int-profile-created small rounded pointer overflow-auto mt-2">
				<div className="info-header fs-6 text-black text-end px-3 py-2">{cardType} on <span className='fw-bold'>NM-Interview</span> By Candidate</div>
				<div className="info-body p-3">
					<div className="row g-3 mb-3">
						<div className="col-sm-6">
							<div className="cand-name text-primary fs-6 fw-medium text-capitalize">{profileData.display_name}</div>
							<div className="cand-role text-muted text-capitalize">{profileData.job_title}</div>
							<div className="cand-company text-black text-capitalize">{profileData.current_company}</div>
						</div>
						<div className="col-sm-6 text-xl-end">
							<div className="cand-date text-muted text-capitalize">{convertReadable(profileData.createdAt) + ' ' + convertReadableTime(profileData.createdAt)}</div>
							<div className="cand-status fw-medium text-danger text-capitalize">{profileData.job_status}</div>
						</div>
					</div>
	
					<div className="row g-3">
						<div className="col-sm-6 col-xl-3">
							<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
								<div className="label-title text-black">Total Experience:</div>
								<div className="label-value text-danger">{profileData.total_exp} Years</div>
							</div>
							{otherData && otherData.pass_year ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
									<div className="label-title text-black">Pass Year:</div>
									<div className="label-value text-danger">{otherData.pass_year}</div>
								</div>
								: null}
							{otherData && otherData.higest_qualification ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
									<div className="label-title text-black">Highest Qualification:</div>
									<div className="label-value text-danger">{otherData.higest_qualification}</div>
								</div>
								: null}
						</div>
						<div className="col-sm-6 col-xl-3">
							{otherData && otherData.current_city ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
									<div className="label-title text-black">Current City:</div>
									<div className="label-value text-danger">{otherData.current_city}</div>
								</div>
								: null}
							{otherData && otherData.university ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
									<div className="label-title text-black">University:</div>
									<div className="label-value text-danger">{otherData.university}</div>
								</div>
								: null}
						</div>
						<div className="col-sm-6 col-xl-3">
							{otherData && otherData.course_type ?
								<div className="info-box fw-medium text-capitalize d-flex flex-wrap align-items-center column-gap-2 mb-1">
									<div className="label-title text-black">Course Type:</div>
									<div className="label-value text-danger">{otherData.course_type}</div>
								</div>
								: null}
							{/* <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
								<div className="label-title text-black">Interview Time:</div>
								<div className="label-value text-danger">{intTime}</div>
							</div> */}
						</div>
						<div className="col-sm-6 col-xl-3">
							{otherData && otherData.notice_period ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
									<div className="label-title text-black">Notice Period:</div>
									<div className="label-value text-danger">{otherData.notice_period}</div>
								</div>
								: null}
							{otherData && otherData.key_skill ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
									<div className="label-title text-black">Key Skills:</div>
									<div className="label-value text-danger">{otherData.key_skill}</div>
								</div>
								: null}
						</div>
					</div>
				</div>
			</div>
		)
	} else{

		if(!profileData.added_by_email.endsWith("@gmail.com")){
		return (
			<div className="info-cards int-profile-created small rounded pointer overflow-auto mt-2">
				<div className="info-header fs-6 text-black text-end px-3 py-2">{cardType} on <span className='fw-bold'>NM-Interview</span> By Candidate</div>
				<div className="info-body p-3">
					<div className="row g-3 mb-3">
						<div className="col-sm-6">
							<div className="cand-name text-primary fs-6 fw-medium text-capitalize">{profileData.display_name}</div>
							<div className="cand-role text-muted text-capitalize">{profileData.job_title}</div>
							<div className="cand-company text-black text-capitalize">{profileData.current_company}</div>
						</div>
						<div className="col-sm-6 text-xl-end">
							<div className="cand-date text-muted text-capitalize">{convertReadable(profileData.createdAt) + ' ' + convertReadableTime(profileData.createdAt)}</div>
							<div className="cand-status fw-medium text-danger text-capitalize">{profileData.job_status}</div>
						</div>
					</div>
	
					<div className="row g-3">
						<div className="col-sm-6 col-xl-3">
							<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
								<div className="label-title text-black">Total Experience:</div>
								<div className="label-value text-danger">{profileData.total_exp} Years</div>
							</div>
							{otherData && otherData.pass_year ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
									<div className="label-title text-black">Pass Year:</div>
									<div className="label-value text-danger">{otherData.pass_year}</div>
								</div>
								: null}
							{otherData && otherData.higest_qualification ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
									<div className="label-title text-black">Highest Qualification:</div>
									<div className="label-value text-danger">{otherData.higest_qualification}</div>
								</div>
								: null}
						</div>
						<div className="col-sm-6 col-xl-3">
							{otherData && otherData.current_city ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
									<div className="label-title text-black">Current City:</div>
									<div className="label-value text-danger">{otherData.current_city}</div>
								</div>
								: null}
							{otherData && otherData.university ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
									<div className="label-title text-black">University:</div>
									<div className="label-value text-danger">{otherData.university}</div>
								</div>
								: null}
						</div>
						<div className="col-sm-6 col-xl-3">
							{otherData && otherData.course_type ?
								<div className="info-box fw-medium text-capitalize d-flex flex-wrap align-items-center column-gap-2 mb-1">
									<div className="label-title text-black">Course Type:</div>
									<div className="label-value text-danger">{otherData.course_type}</div>
								</div>
								: null}
							{/* <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
								<div className="label-title text-black">Interview Time:</div>
								<div className="label-value text-danger">{intTime}</div>
							</div> */}
						</div>
						<div className="col-sm-6 col-xl-3">
							{otherData && otherData.notice_period ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
									<div className="label-title text-black">Notice Period:</div>
									<div className="label-value text-danger">{otherData.notice_period}</div>
								</div>
								: null}
							{otherData && otherData.key_skill ?
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
									<div className="label-title text-black">Key Skills:</div>
									<div className="label-value text-danger">{otherData.key_skill}</div>
								</div>
								: null}
						</div>
					</div>
				</div>
			</div>
		)
		}
	}
	
}

export default ProfileCreated;