import React, { useRef, useEffect, useContext, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import "../../../static/style.css";
import "../../../RoomPage/RoomPage.css";
import { connect, useSelector } from "react-redux";
import {
  setBackgroundImage,
  setIsRemovedBackground,
  setBackgroundPreviewImage,
  setIsClientOnCall,
} from "../../../store/actions";

// env import
import { environment } from "../../../environment";

import {
  blurBackground,
  imageBackground,
  changeImageBackground,
  RemovedBackground,
} from "../../../utils/twilioUtils";

import {
  collection,
  addDoc,
  doc,
  setDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  orderBy,
  updateDoc,
  documentId,
  serverTimestamp,
} from "firebase/firestore";
import { database } from "../../../firebaseConfig";
import { callAxios } from "../../../utils/utils";

// import for network signals
import useParticipantNetworkQualityLevel from "../../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel";
import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import { store } from "../../../store/store";
import NetworkSignals from "../../../hooks/useParticipantNetworkQualityLevel/NetworkSignals";

const STEP = 3;
const BARS_ARRAY = [0, 1, 2, 3, 4];

const VideoTrack = ({
  track,
  setBackgroundImageAction,
  comingFrom,
  participant,
  tracks,
  isMobile,
}) => {
  // if (track !== null) {
  //   standardizedStats.frameWidthInput = track.frameWidth;
  // }
  // console.log(room.sid)
  const roomId = useSelector((state) => state.roomId);
  const roomSid = useSelector((state) => state.roomSid);
  const updateClientInfo = async (roomId, type) => {
    const formData = query(
      collection(database, "playerstreamer"),
      where("roomId", "==", roomId)
    );
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
      //console.log(docData.id, ' => ', docData.data());
      const formRef = doc(
        database,
        environment.REACT_APP_FIREBASE_DB,
        docData.id
      );
      if (type === "client") {
        updateDoc(formRef, {
          clientJoinedTime: serverTimestamp(),
        })
          .then(() => {
            // console.log("clientJoined successfully");
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (type === "onlyClient") {
        updateDoc(formRef, {
          isClientOnCall: true,
        })
          .then(() => {
            // console.log("clientJoined successfully");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        updateDoc(formRef, {
          BDMJoinedTime: serverTimestamp(),
        })
          .then(() => {
            //console.log("bdm Joined successfully");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  let participantId = "";
  useEffect(()=>{
    if (
      participant?.identity.includes("+17862041697-") ||
      participant?.identity.includes("_CL_")
    ) {
      updateClientInfo(roomId, "onlyClient");
      store.dispatch(setIsClientOnCall(true));
    }
  },[participant])
  
  const identityArray = participant?.identity.split("_");
  if (participant?.identity.includes("+17862041697-")) {
    participantId = participant?.sid;
  }

  



  const handleDisconnectParticipant = async () => {
    //console.log("in voice disconnect");
    await callAxios(
      "https://new-live-7455.twil.io/participant-disconnect",
      { roomSid: roomSid, participantSid: participantId },
      "post"
    );
  };
  const videTrack = tracks.find((track) => track.kind === "video");
  //console.log(videTrack.isEnabled)
  const audioTrack = tracks.find((track) => track.kind === "audio");
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle(audioTrack);
  const [isVideoEnabled, toggleVideoEnabled] = useLocalAudioToggle(videTrack);
  const formId = useSelector((state) => state.formId);

  const clientInfo = useSelector((state) => state.clientInfo);

  // const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  //const calculation = useMemo(() => useParticipantNetworkQualityLevel(participant), [signal]);

  if (comingFrom === true || comingFrom === "Bdm") {
    // document.getElementById("LeadDetailsSidenav").style.transform = "translateX(0px)";
    // document.getElementById("main").style.marginRight = "300px";
    // document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    // document.getElementById("leadDetails").classList.add('active');
  } else if (comingFrom === "Bdm") {
    updateClientInfo(roomId, "bdm");
  } else {
    // console.log(formId,roomId, comingFrom)
    updateClientInfo(roomId, "client");
  }

  let bgImage = useSelector((state) => state.bgImage);
  const isRemovedBackground = useSelector((state) => state.isRemoved);

  //if (isRemovedBackground === true) {
  if (localStorage.getItem("setLocalImg") === "undefined") {
    RemovedBackground(track);
    //setBackgroundImageAction(undefined);
    //setBackgroundPreviewImage(undefined);
    bgImage = undefined;
  }

  track.processor = null;

  if (bgImage === "blur-image.jpg") {
    /*
     * Image Blur on Video track
     */

    if (
      track && track.name !== "screen-share-track" &&
      track._log.name.indexOf("LocalVideoTrack") != -1
    ) {
      blurBackground(track); // uncomment this when you need blur background
    }
    /*
     * End blur background
     */
  } else {
    /*
     * Image background on Video track
     */
    // if (typeof bgImage !== "undefined" && isRemovedBackground === false) {
    if (typeof localStorage.getItem("setLocalImg") !== "undefined") {
      if (
        track && track.name !== "screen-share-track" &&
        track._log.name.indexOf("LocalVideoTrack") != -1
      ) {
        imageBackground(track, localStorage.getItem("setLocalImg"));
      }
    }

    /*
     * End image background
     */
  }

  // console.log("track details::", track);

  const trackRef = useRef();

  useEffect(() => {
    if (track) {
      const child = track.attach();
      trackRef.current.appendChild(child);

      trackRef.current.classList.add(track.kind);
      //child.style.transform = "scale(-1, 1)";

      if (track.name === "screen-share-track") {
        child.addEventListener("click", () => {
          zoomTrack();
        });
      }
    }
    const videosPortal = document.getElementById("videos_portal");

    if (!videosPortal.classList.contains("videos_portal_styles")) {
      videosPortal.classList.add("videos_portal_styles");
    }
  }, []);


  const htmlContent = (
    <div className="video_track_container border border-1 border-secondary" id="video-container">
      {useMemo(() => (
        <NetworkSignals participant={participant} />
      ), [participant])}
      {/* <div className="network-signals d-flex align-items-end gap-1">
        {BARS_ARRAY.map((level) => (
          <div
            className="signal"
            key={level}
            style={{
              height: `${STEP * (level + 1)}px`,
              background:
                networkQualityLevel > level
                  ? "white"
                  : "rgba(255, 255, 255, 0.2)",
            }}
          />
        ))}
      </div> */}

      <div className="mute-unmute d-flex align-items-end gap-1">
        {isAudioEnabled ? (
          <i className="bi bi-mic-fill text-white"></i>
        ) : (
          <i className="bi bi-mic-mute-fill text-white"></i>
        )}
        {/* {identityArray[1] === "CL"? null :identityArray[1] + " : "} */}
        {isMobile?
        "CL : " + clientInfo?.cname
        :
        identityArray[1] + " : " + identityArray[2]
        }
      </div>

      <div ref={trackRef}>
        {isMobile ? (
          <div className="audio-user-wrapper text-center w-100 overflow-hidden">
            <img src={require("../../../resources/images/Avatar.png")} className="img-fluid border rounded-circle w-25 h-25" alt="Mobile User" />

            <div className="username text-capitalize text-truncate mt-1 mt-xl-2">
              <i className="bi bi-phone-flip text-warning"></i> {clientInfo?.cname}
            </div>
            <div className="text-center mt-1">
              {" "}
              <button
                className="btn btn-danger rounded-1 py-1 px-3"
                onClick={handleDisconnectParticipant}
              >
                {" "}
                <i className="bi bi-telephone-fill"></i> Disconnect{" "}
              </button>{" "}
            </div>
          </div>
        ) : null}
        {/* { !isVideoEnabled && !isMobile ? <div className="audio-user-wrapper text-center w-100 overflow-hidden" style={{position:'absolute'}}> <img src={require('../../../resources/images/Avatar.png')} className="img-fluid border rounded-circle w-25 h-25" alt="Mobile User" /> </div>:null }  */}

        {/* { videTrack.isEnabled?null:<div className="audio-user-wrapper text-center w-100 overflow-hidden" style={{position:'absolute'}}>

          <img src={require('../../../resources/images/Avatar.png')} className="img-fluid border rounded-circle w-25 h-25" alt="Mobile User" />
        </div>
        
        }     */}
      </div>
    </div>
  );

  document.getElementById("main").className =
    "room_container main-section p-lg-3 overflow-hidden";

  document.getElementById("videos_portal").className =
    "when-normal d-flex flex-wrap h-100 overflow-auto position-relative";

  document.getElementById("shared_screen").classList.add("hide");
  // document.getElementById("shared_screen").classList.remove("show");

  if (track.name === "screen-share-track") {
    document.getElementById("main").className =
      "d-lg-flex room_container main-section p-lg-3 overflow-hidden gap-lg-3";

    document.getElementById("videos_portal").className =
      "when-shared d-flex flex-wrap align-content-start w-100 h-100 overflow-auto position-relative flex-shrink-1 p-lg-3";

    document.getElementById("shared_screen").classList.add("show");
    document.getElementById("shared_screen").classList.remove("hide");

    //htmlContent = <div ref={trackRef}></div>;
    const content = <div ref={trackRef}></div>;
    ReactDOM.createPortal(content, document.getElementById("shared_screen"));
    return ReactDOM.createPortal(
      content,
      document.getElementById("shared_screen")
    );
  }

  function zoomTrack() {
    if (
      !document
        .getElementById("shared_screen")
        .classList.contains("participantZoomed")
    ) {
      document
        .getElementById("shared_screen")
        .classList.add("participantZoomed");
    } else {
      document
        .getElementById("shared_screen")
        .classList.remove("participantZoomed");
    }
  }

  return ReactDOM.createPortal(
    htmlContent,
    document.getElementById("videos_portal")
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setBackgroundImageAction: (image) => dispatch(setBackgroundImage(image)),
    setIsRemovedBackgroundAction: (isremoved) =>
      dispatch(setIsRemovedBackground(isremoved)),
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default VideoTrack;
//export default connect(mapStoreStateToProps, mapDispatchToProps)(VideoTrack);
