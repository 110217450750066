import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CareerProfile } from './Modals/CareerProfile';

export const CareerProfileCard = ({profileId, profileData, setProfileData, setIsLoading}) => {

	

	const [isCareerProfileAction, setCareerProfileAction] = useState(false)
    const [updateCareerProfileData, setCareerProfileData] = useState({})
	

    const showCertificationModalUpdate = (item) =>{
        setShowCareerProfileModal(true);
        setCareerProfileAction(true);
        setCareerProfileData(item);
    }

	// Show Career profile Modal
	const [showCareerProfileModal, setShowCareerProfileModal] = useState(false);
	const closeCareerProfileModal = () => {
		setCareerProfileData({})
		setCareerProfileAction(false)
		setShowCareerProfileModal(false);
	}
	const openCareerProfileModal = () => {
		if(profileData?.career_profile){
			setCareerProfileData(profileData?.career_profile)
		setCareerProfileAction(true)
		setShowCareerProfileModal(true);
		}  else{
			
		setShowCareerProfileModal(true);
		}
		
	}
	let perCity;
	if(profileData?.career_profile){
		perCity = JSON.parse(profileData?.career_profile?.preferred_work_location).map((item)=>{
		return item.value;
	})
	}
	
	// console.log(perCity)

	return (
		<>
			<div id="Career-Profile" className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
				<div className='widgetHead mb-3'>
					<div className='widgetTitle text-white fs-6 fw-medium'>Career profile <span className="edit-icon ms-3 pointer"><i className="bi bi-pencil fs-6 text-clr1 lh-1" onClick={openCareerProfileModal}></i></span></div>
					{/* Uncomment this for normal case */}
					{/* <div className='emp-desc mt-3'>Add details about your current and preferred career profile. This helps us personalise your job recommendations.</div> */}
				</div>

				<div className='widgetCont'>
					<div className="row g-3">
						<div className="col-sm-6">
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Current industry</div>
								{profileData?.career_profile && profileData?.career_profile?.current_industry?
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{profileData?.career_profile?.current_industry}</p>
									{/* <Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openCareerProfileModal}>Add current industry</Link> */}
								</div>
								:
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openCareerProfileModal}>Add current industry</Link>
								</div>
								}
							</div>
						</div>
						<div className="col-sm-6">
						{profileData?.career_profile && profileData?.career_profile?.department?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Department</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{profileData?.career_profile?.department}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Department</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openCareerProfileModal}>Add department</Link>
								</div>
							</div>
						}
						</div>
						<div className="col-sm-6">
							{profileData?.career_profile && profileData?.career_profile?.role_category?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Role category</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{profileData?.career_profile?.role_category}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Role category</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openCareerProfileModal}>Add department</Link>
								</div>
							</div>
							}
						</div>
						<div className="col-sm-6">
						{profileData?.career_profile && profileData?.career_profile?.job_role?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Job role</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{profileData?.career_profile?.job_role}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Job role</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openCareerProfileModal}>Add Job Role</Link>
								</div>
							</div>
						}
						</div>
						<div className="col-sm-6">
						{profileData?.career_profile && JSON.parse(profileData?.career_profile?.desire_job_type).length > 0?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Desired job type</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{JSON.parse(profileData?.career_profile?.desire_job_type).join(", ")}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Desired job type</div>
								<div className="info-desc fw-medium">
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openCareerProfileModal}>Add desired job type</Link>
								</div>
							</div>
							}
						</div>
						<div className="col-sm-6">
						{profileData?.career_profile && JSON.parse(profileData?.career_profile?.desired_employement_type).length> 0?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Desired employment type</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{JSON.parse(profileData?.career_profile?.desired_employement_type).join(", ")}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Desired employment type</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openCareerProfileModal}>Add desired employment type</Link>
								</div>
							</div>
						}
						</div>
						<div className="col-sm-6">
						{profileData?.career_profile && profileData?.career_profile?.preferred_shift?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Preferred shift</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{profileData?.career_profile?.preferred_shift}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Preferred shift</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openCareerProfileModal}>Add preferred shift</Link>
								</div>
							</div>
						}
						</div>
						<div className="col-sm-6">
						{profileData?.career_profile && profileData?.career_profile?.preferred_work_location?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Preferred work location</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{perCity.join(", ")}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Preferred work location</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openCareerProfileModal}>Add preferred work location</Link>
								</div>
							</div>
						}
						</div>
						<div className="col-sm-6">
						{profileData?.career_profile && profileData?.career_profile?.expected_salary ?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Expected salary</div>
								<div className="info-desc fw-medium">

									<p className='text-clr1 text-capitalize m-0'>{profileData?.career_profile?.currency === "INR"?"₹":"$"} {profileData?.career_profile?.expected_salary}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Expected salary</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openCareerProfileModal}>Add expected salary</Link>
								</div>
							</div>
						}
						</div>
					</div>
				</div>
			</div>

			<CareerProfile showCareerProfileModal={showCareerProfileModal} closeCareerProfileModal={closeCareerProfileModal} setShowCareerProfileModal={setShowCareerProfileModal} profileId={profileId} setProfileData={setProfileData} isUpdateAction={isCareerProfileAction} updateData={updateCareerProfileData} profileData={profileData} setIsLoading={setIsLoading} />
		</>
	)
}
