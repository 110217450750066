import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class AttemptedCallsFormFilledOrNot extends Component {
   
  componentDidMount() {
    this.initChart();
  }
  componentDidUpdate(prevProps) {
    
    if(chart._super) {
      chart.dispose();
    }
    this.initChart();
    
  }
  
  whoTookHowManyCalls = (arr) =>
  arr.reduce((p, c) => {
 
    var name = c.attendantName;

    if (!p.hasOwnProperty(name)) {
      p[name] = 0;
    }
   
    p[name]++;
    
    return p;
  }, {});

  groupByArray = (arr) =>
    
   arr.reduce((group, product) => {
      const { attendantName } = product;
      group[attendantName] = group[attendantName] ?? [];
      group[attendantName].push(product);
      return group;
    }, {});
   
  
  


  
  initChart(){
    am4core.useTheme(am4themes_animated);

    // const result = this.props.data.group(({ attendantName }) => attendantName);
   //console.log(result)
    chart = am4core.create("Form-filled-or-not-attemptedCalls", am4charts.XYChart);


    const groupData = Object.entries(this.groupByArray(this.props.data));
    

    let fullData = [];
    let connected = 0;
    for(let y=0;y < groupData.length; y++){
      let innerObj = {};
      if(groupData[y][0] != 'undefined'){
          let formFilled = 0;
          let formNotFilled = 0;
          for(let x =0; x<groupData[y][1].length;x++){
              if(!groupData[y][1][x].clientSubmitTime){
                //innerObj.liveCallConnected = [].push(groupData[y][1][x].clientJoinedLiveTime);
                formNotFilled += 1;
              }

              if(groupData[y][1][x].clientSubmitTime){
                //innerObj.mobileCallConnected = [].push(groupData[y][1][x].clientJoinedLiveTime);
                formFilled += 1;
              }
              innerObj.FormNotFilled = formNotFilled;
              innerObj.FormFilled = formFilled;
            
          }
          innerObj.name = groupData[y][0];
          innerObj.totalCalls = groupData[y][1].length;

          fullData.push(innerObj)
        
        

      }
    }
    //console.log(fullData)
    // for(let y=0; y< barData.length; y++){
    //     if(barData[y][0] != 'undefined'){
    //         fullData.push({
    //             name: barData[y][0],
    //             number: barData[y][1]
    //         });
    //     }
        
    // }
    //console.log("chart dAta:",fullData);
    //chart.data = fullData;
    // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // categoryAxis.dataFields.category = "name";
    // categoryAxis.renderer.grid.template.location = 0;
    // categoryAxis.renderer.minGridDistance = 30;
    // categoryAxis.renderer.labels.template.horizontalCenter = "right";
    // categoryAxis.renderer.labels.template.verticalCenter = "middle";
    // categoryAxis.renderer.labels.template.rotation = 270;
    // categoryAxis.tooltip.disabled = true;
    // categoryAxis.renderer.minHeight = 110;
    // categoryAxis.renderer.labels.template.fontSize = 14;
    
    
    // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.renderer.minWidth = 0;
    // valueAxis.renderer.labels.template.fontSize = 14;
    // // Create series
    // let series = chart.series.push(new am4charts.ColumnSeries());
    // series.sequencedInterpolation = true;
    // series.dataFields.valueY = "number";
    // series.dataFields.categoryX = "name";
    // series.tooltipText = "[{categoryX}: bold]{categoryX}:({valueY})[/]";
    // series.columns.template.strokeWidth = 0;

    // series.tooltip.pointerOrientation = "vertical";

    // series.columns.template.column.cornerRadiusTopLeft = 10;
    // series.columns.template.column.cornerRadiusTopRight = 10;
    // series.columns.template.column.fillOpacity = 0.8;

    // // on hover, make corner radiuses bigger
    // let hoverState = series.columns.template.column.states.create("hover");
    // hoverState.properties.cornerRadiusTopLeft = 0;
    // hoverState.properties.cornerRadiusTopRight = 0;
    // hoverState.properties.fillOpacity = 1;

   
    // series.columns.template.adapter.add("fill", function(fill, target) {
    //   return chart.colors.getIndex(target.dataItem.index);
    // });

    // // Cursor
    // chart.cursor = new am4charts.XYCursor();

    //new bar column stack chart
    // chart.data = [{
    //   "name": "Abhishek",
    //   "europe": 2.5,
    //   "namerica": 2.5,
    //   "asia": 2.1,
    //   "lamerica": 0.3,
    //   "meast": 0.2,
    //   "africa": 0.1
    // }, {
    //   "name": "Rahul",
    //   "europe": 2.6,
    //   "namerica": 2.7,
    //   "asia": 2.2,
    //   "lamerica": 0.3,
    //   "meast": 0.3,
    //   "africa": 0.1
    // }, {
    //   "name": "Amit",
    //   "europe": 2.8,
    //   "namerica": 2.9,
    //   "asia": 2.4,
    //   "lamerica": 0.3,
    //   "meast": 0.3,
    //   "africa": 0.1
    // }];
   // console.log(fullData)
    chart.data = fullData;

    chart.colors.list = [
      am4core.color("yellow"),
      am4core.color("blue"),
      am4core.color("#FF6F91")
    ];
    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;

     // categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.minHeight = 110;
    categoryAxis.renderer.labels.template.fontSize = 14;
    
    
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
   
    valueAxis.min = 0;
    
    // Create series
    function createSeries(field, name) {
      
      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "name";
      series.sequencedInterpolation = true;
      
      // Make it stacked
      series.stacked = true;
      
      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText = "[{categoryX}: bold]{categoryX}:({valueY})[/]";//"[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

      // series.columns.template.adapter.add("fill", function(fill, target) {
      //     return chart.colors.getIndex(target.dataItem.index);
      //   });
      
    //      series.columns.template.column.cornerRadiusTopLeft = 10;
    // series.columns.template.column.cornerRadiusTopRight = 10;
    // series.columns.template.column.fillOpacity = 0.8;
      // Add label
      // let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      // labelBullet.label.text = "{valueY}";
      // labelBullet.locationY = 0.5;
      // labelBullet.label.hideOversized = true;
      
      return series;
    }
    createSeries("totalCalls", "Total Calls");
    createSeries("FormFilled", "Form Filled");
    createSeries("FormNotFilled", "Form Not Filled");
    // createSeries("mobilecall", "Mobile Call");
    // createSeries("meast", "Middle-East");
    // createSeries("africa", "Africa");

    chart.legend = new am4charts.Legend();
  
  }
    

  componentWillUnmount() {
    if (chart) {
      chart.dispose();
    }
  }
  
  render() {
    return <div id="Form-filled-or-not-attemptedCalls" style={{ width: "100%", height: "400px", margin: "0 auto" }} />;
  }
}

export default AttemptedCallsFormFilledOrNot;