import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import axios from 'axios';
import WarningModal from '../Components/Warningmodal';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
import { generateMonthOptions, generateYearOptions, getProfileData } from '../../utils/utils';

export const AddProject = ({ showProjectModal, closeProjectModal, toasterData, getprojects, getprojectdata, listemployment, profileId, setProfileData,setShowProjectModal, setIsLoading }) => {
	//  console.log(getprojectdata)
	// Show more info on button click
	// const getUserId = process.env.REACT_APP_USER_ID
	const today = new Date();
	const currentYear = today.getFullYear();
	const currentMonth = today.getMonth() + 1; // Months are 0-based
	const [isDisplayed, setIsDisplayed] = useState(false);
	const [showworkedtill, setWorkedtill] = useState(false)
	const [deleteprojectdata, setDeleteproject] = useState(false)
	const [showModal, setShowModal] = useState(false);
	const [textmessage, setTextmessage] = useState('');
	const [countWords, setCountwords] = useState('')
	const [selectedYear, setSelectedYear] = useState(currentYear);
	const [selectedYear_, setSelectedYear_] = useState(currentYear);

	// countWords
	const maxWords = 1000; // Define the maximum number of characters

	const [formData, setFormData] = useState({
		profile_id: profileId,
		project_title: "",
		company_name: "",
		client: "",
		project_location:"",
		project_status: "In Progress",
		worked_tillyear: "",
		worked_tillmonth: "",
		worked_fromyear: "",
		worked_frommonth: "",
		worked_from: "",
		worked_till: "",
		project_details: "",
		team_size: "",
		role: "",
		role_description: "",
		skills_used: "",
		nature_of_employment: "",
		project_site: "",
		type: getprojectdata?.type ? getprojectdata?.type : ""
	})

	const handleInputChange = (e) => {
		if(e.target.name === 'worked_fromyear'){
			setSelectedYear(parseInt(e.target.value));
		}
		if(e.target.name === 'worked_tillyear'){
			setSelectedYear_(parseInt(e.target.value));
		}
		if (e.target.name === 'project_details') {
			setCountwords(e.target.value)
		}
		if (e.target.name === "project_status" && e.target.value === "Finished") {
			setWorkedtill(true)
		}
		if (e.target.name === "project_status" && e.target.value === "In Progress") {
			setWorkedtill(false)
		}
		const { name, value } = e.target;

		setFormData({
			...formData,
			[name]: value,
		});
	};

	// Calculate the number of characters remaining
	const charRemaining = maxWords - countWords.length;

	const showMoreInfo = () => {
		setIsDisplayed(!isDisplayed);
	};

	const convertDate = (dateString) => {
		// Create a new Date object from the dateString
		const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		const [year, month] = dateString.split('-');
		const formattedMonth = months.findIndex(m => m === month) + 1; // Find index of month in months array
		return `${year}-${formattedMonth.toString().padStart(2, '0')}`;
	}

	const formatDate = (inputDate, type) => {
		// console.log(inputDate)
		// return
		if (inputDate !== "" && inputDate !== undefined) {
			const dateParts = inputDate.split('-');
			const year = dateParts[0];
			const month = new Date(inputDate + '-01').toLocaleString('en-US', { month: 'long' });
			if (type === "year") {
				return year
			}
			if (type === "month") {
				return month
			}
		}
		// return year + '-' + month;
	}

	const sendData = (e) => {
		e.preventDefault()
		const workedfromdata = formData.worked_fromyear + "-" + formData.worked_frommonth
		formData.worked_from = convertDate(workedfromdata)
		if (formData.project_status === "Finished") {
			formData.worked_till = convertDate(formData.worked_tillyear + "-" + formData.worked_tillmonth)
		}
		if (formData.project_status === "In Progress") {
			formData.worked_till = ""
		}
		console.log(formData)
		addData(formData)
	}

	const addData = async (postData) => {
		var apiname = 'add-project'
		if (postData.type === "update" || postData.type === "delete") {
			var apiname = 'update-delete-project'
		}
		try {
			setIsLoading(true)
			setShowProjectModal(false)
			const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/${apiname}`, postData, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const responsedata = response?.data
			if (responsedata?.statusCode === 200 || responsedata?.statusCode === 201 || response?.data) {
				setProfileData(await getProfileData(profileId, setIsLoading))
				const sendMsg = responsedata?.message
				toast.success(sendMsg, {
					position: 'bottom-center'
				})
				
				
			}
		} catch (error) {
			setProfileData(await getProfileData(profileId, setIsLoading))
			console.log('Error: ', error)
		}
	}

	// deleteProject
	const deleteProject = () => {
		const getId = getprojectdata?.id
		setShowModal(true)
		setTextmessage('Are you sure you want to delete this Project?')
		const sendData = {
			"profile_id": profileId ? profileId : '',
			"id": getId,
			"type": "delete"
		}
		setDeleteproject(sendData)
	}

	const handleCloseModal = () => {
		setShowModal(false)
	}
	// deleteProject

	useEffect(() => {
		setFormData({
			project_title: getprojectdata?.project_title || '',
			company_name: getprojectdata?.company_name || '',
			client: getprojectdata?.client || '',
			project_status: getprojectdata?.project_status || 'In Progress',
			worked_tillyear: formatDate(getprojectdata?.worked_till, 'year') || '',
			worked_tillmonth: formatDate(getprojectdata?.worked_till, 'month') || '',
			worked_fromyear: formatDate(getprojectdata?.worked_from, 'year') || '',
			worked_frommonth: formatDate(getprojectdata?.worked_from, 'month') || '',
			worked_from: getprojectdata?.worked_from || '',
			worked_till: getprojectdata?.worked_till || '2023-03',
			project_details: getprojectdata?.project_details || '',
			team_size: getprojectdata?.team_size || '',
			role: getprojectdata?.role || '',
			role_description: getprojectdata?.role_description || '',
			skills_used: getprojectdata?.skills_used || '',
			nature_of_employment: getprojectdata?.nature_of_employment || '',
			project_site: getprojectdata?.project_site || '',
			project_location:getprojectdata?.project_location || '',
			profile_id: profileId,
			type: getprojectdata?.type || '',
			id: getprojectdata?.id || '',
		});
		setCountwords(getprojectdata?.project_details ? getprojectdata?.project_details : '')
	}, [showProjectModal])

	console.log(formData)

	return (
		<Modal show={showProjectModal} centered size='lg' onHide={closeProjectModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
			<Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
				<div className="close-wrapper text-end">
					<Button variant="link" onClick={closeProjectModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
				</div>
				
				<WarningModal show={showModal} onClose={handleCloseModal} textmessage={textmessage} updateEmp={addData} deleteEmp={deleteprojectdata} />
				<div className="heading-wrapper mb-4">
						<div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
							<span className='widgetTitle text-black fs-5 fw-semibold'>Project</span>
							{/* This button will show only when you click to edit the details */}
							 {getprojectdata.type === "update"? 
							<span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0 pointer' onClick={() => deleteProject(true)}>Delete</span>
							 :null} 
						</div>
						<p className='subTitle fs-14'>Stand out for employers by adding details about projects you have done in college, internships, or at work</p>
					</div>

				<Form action="POST" className="form-cover" onSubmit={sendData}>
					<Form.Group className="mb-3">
						<Form.Label>Project title<span className='text-danger'>*</span></Form.Label>
						<Form.Control type="text" name="project_title" value={formData.project_title} onChange={handleInputChange} placeholder="Enter project title" autoComplete='off' required />
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Tag this project with your employment/education</Form.Label>
						<Form.Select name="company_name" value={formData.company_name} onChange={handleInputChange} required>
							<option selected hidden value=''>Select employment/education</option>
							{listemployment?.map(employment => (
								<option key={employment?.id} value={employment?.id}>
									{employment?.job_title?employment?.job_title+" - ":""}{employment?.company_name}
								</option>
							))}
						</Form.Select>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Client<span className='text-danger'>*</span></Form.Label>
						<Form.Control type="text" name="client" value={formData.client} onChange={handleInputChange} placeholder="Enter client name" autoComplete='off' required />
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label className="mb-2">Project status</Form.Label>
						<div className='radio-wrapper'>
							<Form.Check inline type="radio" className='w-25' id='inProgress' name='project_status' value="In Progress" checked={formData.project_status === "In Progress"} onChange={handleInputChange} label='In Progress' />
							<Form.Check inline type="radio" className='w-25' id='finished' name='project_status' value="Finished" checked={formData.project_status === "Finished"} onChange={handleInputChange} label='Finished' />
						</div>
					</Form.Group>

					<Form.Group>
						<Form.Label>Worked from<span className='text-danger'>*</span></Form.Label>
						<div className="row gx-3">
							<div className="col-6">
								<Form.Group className="mb-3">
									<Form.Select name="worked_fromyear" value={formData.worked_fromyear} onChange={handleInputChange} required>
									<option selected hidden value=''>Select year</option>
									{generateYearOptions().map(year => (
										<option key={year} value={year}>{year}</option>
									))}
										
									</Form.Select>
								</Form.Group>
							</div>

							<div className="col-6">
								<Form.Group className="mb-3">
									<Form.Select name="worked_frommonth" value={formData.worked_frommonth} onChange={handleInputChange} required>
									<option selected hidden value=''>Select month</option>
									{generateMonthOptions(selectedYear).map(month => (
										<option key={month.value} value={month.label}>{month.label}</option>
									))}
										
									</Form.Select>
								</Form.Group>
							</div>
						</div>
					</Form.Group>

					{/* Show if choosen finished only */}
					{showworkedtill ?
						<Form.Group>
							<Form.Label>Worked till<span className='text-danger'>*</span></Form.Label>
							<div className="row gx-3">
								<div className="col-6">
									<Form.Group className="mb-3">
										<Form.Select name="worked_tillyear" value={formData.worked_tillyear} onChange={handleInputChange} required>
										<option selected hidden value=''>Select year</option>
										{generateYearOptions().map(year => (
											<option key={year} value={year}>{year}</option>
										))}
											
										</Form.Select>
									</Form.Group>
								</div>

								<div className="col-6">
									<Form.Group className="mb-3">
										<Form.Select name="worked_tillmonth" value={formData.worked_tillmonth} onChange={handleInputChange} required>
										<option selected hidden value=''>Select month</option>
										{generateMonthOptions(selectedYear_).map(month => (
										<option key={month.value} value={month.label}>{month.label}</option>
									))}
										</Form.Select>
									</Form.Group>
								</div>
							</div>
						</Form.Group> : ""}
					{/* Show if choosen finished only */}

					<Form.Group className="mb-3">
						<Form.Label>Details of project<span className='text-danger'>*</span></Form.Label>
						<Form.Control as="textarea" rows={4} name="project_details" value={formData.project_details} onChange={handleInputChange} placeholder="Type here..." maxLength={1000} autoComplete='off' required />
						<div className="charLenght fs-14 text-clr2 text-end mt-2">{charRemaining > 0 ? charRemaining : 0} character(s) left</div>
					</Form.Group>

					{isDisplayed ?
						<div id='addMoreInfo' className="addmore-wrapper">
							<Form.Group className="mb-3">
                                <Form.Label>Project location</Form.Label>
                                <Form.Control type="text" name="project_location" value={formData.project_location} onChange={handleInputChange} placeholder="Enter project location" autoComplete='off' required />
                            </Form.Group>

							<Form.Group className="mb-3">
								<Form.Label className="mb-2">Project site</Form.Label>
								<div className='radio-wrapper'>
									<Form.Check inline type="radio" className='me-4' id='Offsite' name='project_site' value="Offsite" checked={formData.project_site === "Offsite"} onChange={handleInputChange} label='Offsite' />
									<Form.Check inline type="radio" className='me-4' id='Onsite' name='project_site' value="Onsite" checked={formData.project_site === "Onsite"} onChange={handleInputChange} label='Onsite' />
								</div>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label className="mb-2">Nature of employment</Form.Label>
								<div className='radio-wrapper'>
									<Form.Check inline type="radio" className='me-4' id='Full time' name='nature_of_employment' value="Full time" checked={formData.nature_of_employment === "Full time"} onChange={handleInputChange} label='Full time' />
									<Form.Check inline type="radio" className='me-4' id='Part time' name='nature_of_employment' value="Part time" checked={formData.nature_of_employment === "Part time"} onChange={handleInputChange} label='Part time' />
									<Form.Check inline type="radio" className='me-4' id='Contractual' name='nature_of_employment' value="Contractual" checked={formData.nature_of_employment === "Contractual"} onChange={handleInputChange} label='Contractual' />
								</div>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Team size</Form.Label>
								<Form.Select name="team_size" value={formData.team_size} onChange={handleInputChange} >
									<option selected hidden value=''>Select team size</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
									<option value="7">7</option>
									<option value="8">8</option>
									<option value="9">9</option>
									<option value="10">10</option>
									<option value="11">11</option>
									<option value="12">12</option>
									<option value="13">13</option>
									<option value="14">14</option>
									<option value="15">15</option>
									<option value="16">16</option>
									<option value="17">17</option>
									<option value="18">18</option>
									<option value="19">19</option>
									<option value="20">20</option>
								</Form.Select>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Role</Form.Label>
								<Form.Select name="role" value={formData.role} onChange={handleInputChange}>
									<option selected hidden value=''>Select role</option>
									<option value="Domain Expert">Domain Expert</option>
									<option value="Sr. Project Leader">Sr. Project Leader</option>
									<option value="Solution Architect">Solution Architect</option>
									<option value="Quality Analyst">Quality Analyst</option>
									<option value="Database Architect / DBA">Database Architect / DBA</option>
									<option value="Network / System Administrator">Network / System Administrator</option>
									<option value="Project Leader">Project Leader</option>
									<option value="Module Leader">Module Leader</option>
									<option value="Sr. Programmer">Sr. Programmer</option>
									<option value="Programmer">Programmer</option>
									<option value="Test Engineer">Test Engineer</option>
									<option value="Other">Other</option>
								</Form.Select>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Role description</Form.Label>
								<Form.Control as="textarea" rows={4} name="role_description" value={formData.role_description} onChange={handleInputChange} placeholder="Type here..." maxLength={250} autoComplete='off' />
								<div className="charLenght fs-14 text-clr2 text-end mt-2">250 character(s) left</div>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Skills used</Form.Label>
								<Form.Control type="text" name="skills_used" value={formData.skills_used} onChange={handleInputChange} placeholder="Enter skills used" maxLength={500} autoComplete='off' />
								<div className="charLenght fs-14 text-clr2 text-end mt-2">500 character(s) left</div>
							</Form.Group>
						</div> :
						<div className="btn-wrapper mb-3">
							<Button variant='link' onClick={showMoreInfo} className='fw-semibold text-decoration-none p-0'>Add more details</Button>
						</div>
					}

					<div className="btn-wrapper d-flex flex-wrap gap-2 pt-2">
						<Button variant="danger" onClick={closeProjectModal} className='rounded-pill px-4'>Cancel</Button>
						<Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal >
	)
}

