import axios from 'axios';
import { environment } from '../environment';
import { getAuth  } from "firebase/auth";
import moment from 'moment';
import { toast } from 'react-toastify';
import { callAxios } from './utils';

const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth() + 1; // Months are 0-based



const getAccessToken = async() => {
  const auth = getAuth();
const email = "abishekbatham@virtualemployee.com";

//console.log(email)
const accessToken = "eyJ0eXAiOiJKV1QiLCJub25jZSI6IkVyLVhmZzlMczU0YUlZWExVc2tEazl0bEFsOHRPZFNmYkk1eU1wUnZFRDQiLCJhbGciOiJSUzI1NiIsIng1dCI6IktRMnRBY3JFN2xCYVZWR0JtYzVGb2JnZEpvNCIsImtpZCI6IktRMnRBY3JFN2xCYVZWR0JtYzVGb2JnZEpvNCJ9.eyJhdWQiOiJodHRwczovL2dyYXBoLm1pY3Jvc29mdC5jb20iLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9hMjhlNmYyNy03OTE0LTQwZWItOGIwNS1kYzRhOThkN2IyNDEvIiwiaWF0IjoxNzI0NzcxMDk1LCJuYmYiOjE3MjQ3NzEwOTUsImV4cCI6MTcyNDc3NDk5NSwiYWlvIjoiRTJkZ1lHaFV0bW1zRFNuTC8zeG54OC9mUmE4TEFBPT0iLCJhcHBfZGlzcGxheW5hbWUiOiJETGl2ZSIsImFwcGlkIjoiOGQ2NjU4OTAtYzk3MC00ZDg5LWI0YjEtYTBiZDljOTBkZThkIiwiYXBwaWRhY3IiOiIxIiwiaWRwIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvYTI4ZTZmMjctNzkxNC00MGViLThiMDUtZGM0YTk4ZDdiMjQxLyIsImlkdHlwIjoiYXBwIiwib2lkIjoiNDZiOWRiMzItZjMwNy00ZDBhLWFiOTUtYWI2NzUxMGNhYjBjIiwicmgiOiIwLkFTc0FKMi1Pb2hSNTYwQ0xCZHhLbU5leVFRTUFBQUFBQUFBQXdBQUFBQUFBQUFBckFBQS4iLCJyb2xlcyI6WyJNYWlsLlJlYWRXcml0ZSIsIlNpdGVzLlJlYWQuQWxsIiwiVXNlci5SZWFkLkFsbCIsIk1haWwuUmVhZCJdLCJzdWIiOiI0NmI5ZGIzMi1mMzA3LTRkMGEtYWI5NS1hYjY3NTEwY2FiMGMiLCJ0ZW5hbnRfcmVnaW9uX3Njb3BlIjoiQVMiLCJ0aWQiOiJhMjhlNmYyNy03OTE0LTQwZWItOGIwNS1kYzRhOThkN2IyNDEiLCJ1dGkiOiJGTTUtbmkyMUQwR3Q2QzdrVkFkM0FBIiwidmVyIjoiMS4wIiwid2lkcyI6WyIwOTk3YTFkMC0wZDFkLTRhY2ItYjQwOC1kNWNhNzMxMjFlOTAiXSwieG1zX2lkcmVsIjoiNyAyNiIsInhtc190Y2R0IjoxNTIzNjE1ODM3fQ.FX1CPiIEMQnr5vZbryvjFscoxDvWHnuXX3xmi4RdGYOG-p4Iukw07wR7wpcLfzZ9PZ2RteZ8YplD5vLHCoJ-f7r6Z9INNa4Vsaav7PizVhBytEutjMI2RHSeS-1eyCiS8UHGeSCLGA9z9xOHEt32AzfE0nL6lZhOHwulOkXCJKaHt8f5BaW9o4TikcuXPRUGZWfIUCAtDnBcDMnmkVbW01W8TZqtoJUBWugzeyKLmaPglG2E9HqjBIiHtlwcF45cosH_UyI09V2RCzDnJtfYzsYlEaUskosP93oW9tJiS6U-l3y-6gJR3RsZoq_m2AJM0kDBVjK6_Jz6CM_8R-7qTw"

 return {accessToken,email};

}

  function timeSince(date) {
      // Get current date and time in milliseconds
      const now = Date.now();
  
      // Calculate the difference in milliseconds
      const difference = now - date;
  
      // Calculate seconds, minutes, hours, days, and months (rounded down)
      const seconds = Math.floor(difference / 1000) % 60;
      const minutes = Math.floor(difference / (1000 * 60)) % 60;
      const hours = Math.floor(difference / (1000 * 60 * 60)) % 24;
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const months = Math.floor(difference / (1000 * 60 * 60 * 24 * 30));
  
      // Determine the largest non-zero time unit
      let largestUnit = '';
      let largestValue = 0;
      if (months > 0) {
      largestUnit = 'month';
      largestValue = months;
      } else if (days > 0) {
      largestUnit = 'day';
      largestValue = days;
      } else if (hours > 0) {
      largestUnit = 'hour';
      largestValue = hours;
      } else if (minutes > 0) {
      largestUnit = 'minute';
      largestValue = minutes;
      } else {
      largestUnit = 'second';
      largestValue = seconds;
      }
  
      // Construct a human-readable string
      let timeString = largestValue + ' ' + largestUnit;
  
      // Handle pluralization correctly
      if (largestValue > 1) {
      timeString += 's';
      }
  
      // //console.log(seconds, minutes, hours, largestUnit, timeString);
      // Optionally add additional time units if desired
      if (seconds > 0 && largestUnit !== 'second' && largestUnit !== 'hour') {
      timeString += ', ' + seconds + ' second' + (seconds > 1 ? 's' : '');
      }
      if (minutes > 0 && largestUnit !== 'minute' && largestUnit !== 'second') {
      timeString += ', ' + minutes + ' minute' + (minutes > 1 ? 's' : '');
      }
      if (hours > 0 && largestUnit !== 'hour' && largestUnit !== 'minute' && largestUnit !== 'second') {
      timeString += ', ' + hours + ' hour' + (hours > 1 ? 's' : '');
      }
  
      return timeString+' ago';
  }

  export const fetchData = async (currentEmailType = 'Inbox', top, pageNumber=0,setEmails,setIsLoading,setShowCurrentEmail,setCurrentEmail,setCurrentEmailBody,setSelectedItem,setPage,setEmailType,setLastEmailTime,setLastEmailId, setMidCntr, setPagedata,setEmailListLoading) => {
    // //console.log(pageNumber);
   
    if(pageNumber < 2){
    setEmails([]);
    setIsLoading(true);
    setShowCurrentEmail(false);
    setCurrentEmail("");
    setCurrentEmailBody("");
    setSelectedItem(null);
    setPage(1)
    }else{
     setEmailListLoading(true);
    }
    try {
      const sessionInfo = await getAccessToken();
      const accessToken = sessionInfo.accessToken;
      const email = sessionInfo.email
      // //console.log(accessToken)
    setEmailType(currentEmailType);
    // //console.log('emailType');
    let url_params = '?$top='+top;
    if(pageNumber){
    url_params+='&$skip='+(pageNumber - 1) * top;
    }
    // const emailsResponse = await axios.get('https://graph.microsoft.com/v1.0/users/'+email+'/mailFolders/'+currentEmailType+'/messages'+url_params, {
    //     headers: {
    //         Authorization: `Bearer ${accessToken}`
    //     }
    // });

    const emails = await callAxios(environment.REACT_APP_API_URL_Live+"api/v1/outlook/mails",{email: email, currentEmailType:currentEmailType,url_params:url_params},"post")

   
    const groupByMake = emails.data.reduce((acc, inbox) => {
      (acc[inbox.conversationId] = acc[inbox.conversationId] || []).push(inbox);
      return acc;
  }, {});
  // //console.log(emails.data.length)
  const groupedArray = Object.keys(groupByMake).map((key, index) => {
    return groupByMake[key];
});
    //  //console.log(groupedArray[1])
    if(emails.data.length > 0) {
        handleItemClick(emails.data[0],0);
        setLastEmailTime(emails.data[0].receivedDateTime);
        localStorage.setItem('receivedDateTime', emails.data[0].receivedDateTime);
        setLastEmailId(emails.data[0].id);
        const updatedEmails = await groupedArray.map((mail)=>{
          // //console.log(mail)
        if(moment(mail[0]?.receivedDateTime).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
            const pastDate = new Date(moment(mail[0]?.receivedDateTime).format('YYYY-MM-DD HH:mm:ss'))
            const timeAgo =   timeSince(pastDate);
            mail[0].mailDate = timeAgo;
        }else{
            const formattedDate = moment(mail?.receivedDateTime).format('DD MMM YYYY - HH:mm A');
            mail[0].mailDate = formattedDate;
        }
        return mail;
        });
        setIsLoading(false);
        // setEmails(updatedEmails);
         //console.log(updatedEmails);
        setEmails((prevMessages) => [...prevMessages, ...updatedEmails]);
        
        setEmailListLoading(false);
    }else{
        setMidCntr(true);
        setPagedata('No data to show.')
    }
    } catch (error) {
    
    setIsLoading(false);
    console.error("Error fetching emails:", error);
    }
};

export const fetchDataRefresh = async (currentEmailType = 'Inbox', top, pageNumber=0,setEmails, email, setEmailListLoading) => {
// console.log(currentEmailType = 'Inbox', top, pageNumber=0,setEmails, email)
  try {
       // //console.log(accessToken)

  // //console.log('emailType');
  setEmailListLoading(true)
  let url_params = '?$top='+top;
  if(pageNumber){
  url_params+='&$skip='+(pageNumber - 1) * top;
  }
  console.log({email: email, currentEmailType:currentEmailType,url_params:url_params})
  const emails = await callAxios(environment.REACT_APP_API_URL_Live+"api/v1/outlook/mails",{email: email, currentEmailType:currentEmailType,url_params:url_params},"post")

 
  const groupByMake = emails.data.reduce((acc, inbox) => {
    (acc[inbox.conversationId] = acc[inbox.conversationId] || []).push(inbox);
    return acc;
}, {});
// //console.log(emails.data.length)
const groupedArray = Object.keys(groupByMake).map((key, index) => {
  return groupByMake[key];
});
  //  //console.log(groupedArray[1])
  if(emails.data.length > 0) {
     
      const updatedEmails = await groupedArray.map((mail)=>{
      
      if(moment(mail[0]?.receivedDateTime).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
          const pastDate = new Date(moment(mail[0]?.receivedDateTime).format('YYYY-MM-DD HH:mm:ss'))
          const timeAgo =   timeSince(pastDate);
          mail[0].mailDate = timeAgo;
      }else{
          const formattedDate = moment(mail?.receivedDateTime).format('DD MMM YYYY - HH:mm A');
          mail[0].mailDate = formattedDate;
      }
      return mail;
      });
     
    
      setEmails((prevMessages) => [...updatedEmails]);
     
      // setEmailListLoading(false);
  }
  } catch (error) {
  

  console.error("Error fetching emails:", error);
  }
};

export const fetchNewEmails = async (setLastEmailTime,emails,setLastEmailId,setEmails) => {
  
  const receivedDateTime = localStorage.getItem('receivedDateTime');
  //console.log(receivedDateTime);
  
  try {
      const response = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/outlook/fetch-new-email",{receivedDateTime:receivedDateTime},"post")
      const newEmails = response.data.filter(email => !emails.some(e => e.id === email.id));
      if(newEmails.length > 0){


      
      //console.log(newEmails[0].receivedDateTime);
      
      setLastEmailTime(newEmails[0].receivedDateTime);
      
      localStorage.setItem('receivedDateTime', newEmails[0].receivedDateTime);
      //console.log(localStorage.getItem('receivedDateTime'));



      const updatedEmails = newEmails.map((mail) => {
          if (moment(mail?.receivedDateTime).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
          const pastDate = new Date(moment(mail?.receivedDateTime).format('YYYY-MM-DD HH:mm:ss'));
          const timeAgo = timeSince(pastDate);
          mail.mailDate = timeAgo;
          } else {
              const formattedDate = moment(mail?.receivedDateTime).format('DD MMM YYYY - HH:mm A');
              mail.mailDate = formattedDate;
          }
          return mail;
      });
      setLastEmailId(newEmails[0].id);
      // //console.log(lastEmailTime);
      setEmails((oldEmails) =>[...updatedEmails, ...oldEmails]);
      }
      // Add new emails to the beginning of the list
  } catch (error) {
      console.error('Error fetching emails:', error);
  }
};

export const checkValidation = (key, val) => {
  if (key === "subject" && val !== "") {
  const subjectStyle = document.getElementById("subject");
  if (subjectStyle)
      subjectStyle.style.border = "none";
  }
  if (key === "email" && val !== "") {
  const emailStyle = document.getElementById("email");
  if (emailStyle)
      emailStyle.style.border = "none";

  }
  if (key === "description" && val !== "") {
  const issueDescStyle = document.getElementById("issue-description")
  if (issueDescStyle)
      issueDescStyle.style.border = "none";
  }

  if (key === "changeStatus" && val !== "") {
  const changeStatusStyle = document.getElementById("changeStatus");
  if (changeStatusStyle)
      changeStatusStyle.style.border = "none";
  }
};

export   const handleFormSubmit = async (e, subject, email, description, setIsSaving, base64String, fileName,fileType, setShow, toRecipient, ccRecipient,bccRecipient,attachmentsForEmail,setInboxClick,setNewClick) => {
  e.preventDefault();

  const sessionInfo = await getAccessToken();
  const accessToken = sessionInfo.accessToken;
  const uemail = sessionInfo.email
  if (subject === "") {
    toast.error("Subject could not empty", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  const subjectStyle = document.getElementById("subject");
  if (subjectStyle)
      subjectStyle.style.border = "1px solid red";
  return false;
  } else if (toRecipient.length === 0) {
    toast.error("To recipient could not empty", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  const emailStyle = document.getElementById("email");

  if (emailStyle)
      emailStyle.style.border = "1px solid red";
  return false;
  } else if (description === "") {
    toast.error("Email body could not empty", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  const descStyle = document.getElementById("issue-description");
  if (descStyle)
      descStyle.style.border = "1px solid red";
  return false;
  } else {
    setInboxClick(true)
    setNewClick(false)
  // const emailArray = email.split(',');
  // const emailAddress = emailArray.map((useremail)=>{
  //   return {"address":useremail}
  // })
  // //console.log(emailArray, emailAddress);

  setIsSaving(true)
  
  let formData = {};
  if(attachmentsForEmail.length > 0){

      formData = {
      "message": {
          "subject": `${subject}`,
          "body": {
          "contentType": "HTML",
          "content": `${description}`,
          },
          // "toRecipients": emailAddress
          "toRecipients": toRecipient,
          "ccRecipients": ccRecipient,   
          "bccRecipients": bccRecipient,  
          "Attachments": attachmentsForEmail
      },
      
      "saveToSentItems": true
      }
  }else{
      formData = {
      "message": {
          "subject": `${subject}`,
          "body": {
          "contentType": "HTML",
          "content": `${description}`,
          },
          "toRecipients": toRecipient,
          "ccRecipients": ccRecipient,   
          "bccRecipients": bccRecipient,  
      },
      
      "saveToSentItems": true
      }
  }
  // //console.log(formData)
  // return false;
//   //console.log(formData);
  const msOauthAccessToken = localStorage.getItem('idToken');
  var headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
  };
  const response = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/outlook/send-email",{formData:formData}, "post")
  //console.log(response)
  if (response?.data === 202) {
    //console.log("email sent")
    
      setShow(false);
      resetForm();
      setIsSaving(false)
      toast.success("eMail sent", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  } 
  
  }
};

export const resetForm = async (setSubject, setEmail, setDescription, setFile, setBase64String, setFileName, setFileType) => {
    
  setSubject("");
  setEmail("");
  setDescription("");
  setFile("");
  setBase64String("")
  setFileName("");
  setFileType("");
  // renderFileContent()
  const element = document.getElementsByClassName("ql-editor");
  if (element)
  element[0].innerHTML = "";
  const formReset = document.getElementById("mailForm");
  if (formReset)
  formReset.reset()

  const cancelForm = document.getElementById("closeMailForm");
  if (cancelForm)
  cancelForm.click()

};


export const handleItemClick = async (
  info, 
  index, 
  setHasAttachments, 
  setShowCurrentEmail, 
  setCurrentEmail, 
  setCurrentEmailBody, 
  setSelectedItem, 
  selectedItem, 
  setAttachments, 
  setIsLoading,
  setEmailsById,
  setNewClick,
  setInboxClick,
  setShowReply
) => {
  try {
    
    setHasAttachments(false);
    setIsLoading(true);
    setNewClick(false);
    setInboxClick(true)
    setShowReply(false)

    // Fetch all messages by conversation ID
    // const allMessages = await axios.get('https://graph.microsoft.com/v1.0/users/'+email+'/messages', {
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    //   params: {
    //     $filter: `conversationId eq '${info.conversationId}'` // Dynamically use the conversationId from the clicked email
    //   },
    // });

    const allMessages = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/outlook/get-email-by-id",{conversationId:info.conversationId, id:info.id},"post")

    // Sort the messages by createdDateTime in descending order
    allMessages.data.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime));

    if (allMessages.data.length > 0) {
      const emailsByConversationId = await Promise.all(allMessages.data.map(async (message, indexx) => {
        let attachments = [];

        // Set the current email details
        setCurrentEmail(message);
        setShowCurrentEmail(true);
        setCurrentEmailBody(message.body.content);
        setSelectedItem(index === selectedItem ? null : index);

        // Fetch attachments if present
        if (message.hasAttachments) {
          try {
            const attachmentsResponse = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/outlook/get-attachments",{messageId:message.id},"post")

            if (attachmentsResponse.data.length > 0) {
              attachments = attachmentsResponse.data;
              setAttachments(attachments);
              setHasAttachments(true);
            }
          } catch (error) {
            console.error("Error fetching attachments:", error);
          }
        }

              

        return {
          message,
          attachments,
        };
      }));

      // Set the emails by conversation ID
      //console.log(emailsByConversationId)
      setEmailsById(emailsByConversationId);
    }

    setIsLoading(false); // Stop the loading indicator after processing
  } catch (error) {
    console.error("Error handling item click:", error);
    setIsLoading(false); // Ensure loading stops in case of an error
  }
};


const createReplyDraft = async (messageId) => {
  const sessionInfo = await getAccessToken();
      const accessToken = sessionInfo.accessToken;
      const email = sessionInfo.email
  const url = `https://graph.microsoft.com/v1.0/users/${email}/messages/${messageId}/createReply`;
  
  const response = await axios.post(url, {}, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
  });

  return response.data.id; // Returns the draft message ID
};

const addAttachmentsToDraft = async (draftMessageId, attachments) => {
    const sessionInfo = await getAccessToken();
    const accessToken = sessionInfo.accessToken;
    const email = sessionInfo.email
  const url = `https://graph.microsoft.com/v1.0/users/${email}/messages/${draftMessageId}/attachments`;
  
  const response = await axios.post(url, { attachments }, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
  });

  return response.data;
};



const replyToEmail = async (accessToken, messageId, replyContent, ccRecipients, toRecipients, setShowReply, bccRecipients, attachmentsForEmail, subject) => {

//   const sessionInfo = await getAccessToken();
//   const email = sessionInfo.email

// const replyUrl = `https://graph.microsoft.com/v1.0/users/${email}/messages/${messageId}/reply`;

// const toRecipientsList = toRecipients.map(({ name, address }) => ({
//   emailAddress: { name, address },
// }));
// let formData = {};
// formData = {
//   "message": {
//   "toRecipients": toRecipients,
//   "ccRecipients": ccRecipients,   
//   "bccRecipients": bccRecipients, 
//   },
//   comment: replyContent,
//   "saveToSentItems": true
// }
// if(attachmentsForEmail.length > 0){
//     formData.Attachments = attachmentsForEmail;
// }

const formData = {
  "message": {
    "subject": subject,
    "toRecipients": toRecipients,
  "ccRecipients": ccRecipients,   
  "bccRecipients": bccRecipients, 
	"attachments": attachmentsForEmail	
    },	
    "comment": replyContent,
    "saveToSentItems": true
}

// const config = {
//   headers: {
//   'Authorization': `Bearer ${accessToken}`,
//   'Content-Type': 'application/json'
//   },
// };
// //console.log(formData);
// return false;
try {
  // const response = await axios.post(replyUrl, formData, config);
  const response = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/outlook/reply",{formData:formData, messageId:messageId},"post")
  //console.log(response)
  if (response?.data === 202) {
  
  setShowReply(false);
  
//   resetForm();

  toast.success("Reply sent!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
  });
  } 
  //console.log('Reply sent successfully', response.data);
} catch (error) {
  console.error('Error sending reply:', error.response.data);
}
};


export const handelReplyToEmail = async(e, description,currentEmail,setShowReply, toRecipient,ccRecipient,bccRecipient,attachmentsForEmail) =>{
  e.preventDefault();
//console.log(toRecipient,ccRecipient,bccRecipient,attachmentsForEmail)
const sessionInfo = await getAccessToken();
const accessToken = sessionInfo.accessToken;
const email = sessionInfo.email
if (description === "") {
  const descStyle = document.getElementById("issue-replydescription");
  if (descStyle)
  descStyle.style.border = "1px solid red";
  return false;
} else {
 
  const messageId = currentEmail?.message.id; // Replace with the ID of the message you want to reply to
  const replyContent = description;
  const ccRecipients = ccRecipient; // CC recipients with names
  const toRecipients = toRecipient; // TO recipients with names
  const bccRecipients = bccRecipient;
  replyToEmail(accessToken, messageId, replyContent, ccRecipients, toRecipients, setShowReply, bccRecipients, attachmentsForEmail,currentEmail?.message?.subject);
}
}


// const handleEmailType = async(type,) => {
//   //console.log(type);
//   setIsLoading(true);
//   fetchData(type);
//   setEmailType(type)
// };

export const handleFileInputChange = (event, setAttachmentsForSend, attachmentsForEmail) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;
      const fileDetails = [];
  
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = () => {
          fileDetails.push({
            "@odata.type": "#microsoft.graph.fileAttachment",
            Name: file.name,
            contentType: file.type,
            ContentBytes: reader.result.split(',')[1], // Removing the metadata from the base64 string
          });
  
          // Update the state with the newly selected files
          if (fileDetails.length === files.length) {
            setAttachmentsForSend([...attachmentsForEmail, ...fileDetails]);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  export const handleFileInputChangeReply = (event, setAttachmentsForSend, attachmentsForEmail) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;
      const fileDetails = [];
  
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = () => {
          fileDetails.push({
            "@odata.type": "#microsoft.graph.fileAttachment",
            name: file.name,
            contentType: file.type,
            contentBytes: reader.result.split(',')[1], // Removing the metadata from the base64 string
          });
  
          // Update the state with the newly selected files
          if (fileDetails.length === files.length) {
            setAttachmentsForSend([...attachmentsForEmail, ...fileDetails]);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };
  

// export const handleFileInputChange = (event, setAttachmentsForSend) => {
//     if(event.target.files && event.target.files.length > 0) {
//     const files = event.target.files;
//     const fileDetails = [];
  
//     Array.from(files).forEach(file => {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         fileDetails.push({
//           fileName: file.name,
//           fileType: file.type,
//           base64String: e.target.result.split(',')[1], // Removing the metadata from the base64 string
//         });
  
//         // Once all files are processed, update the states
//         // if (fileDetails.length === files.length) {
//         //   const fileNames = fileDetails.map(file => file.fileName);
//         //   const fileTypes = fileDetails.map(file => file.fileType);
//         //   const base64Strings = fileDetails.map(file => file.base64String);
  
          
         
//         // }
//       };
//       reader.readAsDataURL(file);
//     });

//     setAttachmentsForSend(fileDetails);
// }
//   };
  
export const renderFileContent = (fileName,fileType,base64String) => {
  if (fileType.startsWith('image') ) {
  return <img class='AttachmentImage' title={fileName}  src={`data:${fileType};base64,${base64String}`} alt={fileName} height={77} width={100} />;
  }
 
     else if (fileType === 'application/pdf') {

    return <div class="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2">
    <i class="bi bi-file-earmark-pdf-fill text-light"></i>
    <span class="text-truncate" title={fileName}>{fileName}</span>
    <a href={`data:${fileType};base64,${base64String}`} title={fileName} download={fileName} style={{textDecoration:"none"}}><i class="bi bi-cloud-download ms-1"></i></a>
    
</div>

  } else if(fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
    return <div class="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2">
    <i class="bi bi-file-earmark-word-fill text-light"></i>
    <span class="text-truncate">{fileName}</span>
    <a title={fileName} href={`data:${fileType};base64,${base64String}`} download={fileName} style={{textDecoration:"none"}}><i class="bi bi-cloud-download ms-1"></i></a>
    </div>
  }
  else if(fileType === "application/x-zip-compressed"){
    return <div class="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2">
     <i class="bi bi-file-earmark-zip-fill text-light"></i>
    <span class="text-truncate">{fileName}</span>
    <a title={fileName} href={`data:${fileType};base64,${base64String}`} download={fileName} style={{textDecoration:"none"}}><i class="bi bi-cloud-download ms-1"></i></a>
    </div>
  }
  else {
   return <div class="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2">
  <i class="bi bi-file-earmark"></i>
 <span class="text-truncate">{fileName}</span>
 <a title={fileName} href={`data:${fileType};base64,${base64String}`} download={fileName} style={{textDecoration:"none"}}><i class="bi bi-cloud-download ms-1"></i></a>
 </div>;
  }
};

export const showatachedFile = (base64StringData, fileTypeData,fileNameData) => {
  let iData = <i class="bi bi-file-earmark-pdf-fill text-light"></i>
  if(fileTypeData.startsWith('image')) {
      iData = <i class="bi bi-file-earmark-image-fill text-light"></i>
  }else if(fileTypeData.startsWith('video')) {
      iData = <i class="bi bi-file-earmark-image-fill text-light"></i>
  }
  return <div class="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2">
      {iData}
      <span class="text-truncate">{fileNameData}</span>
      <a href={`data:${fileTypeData};base64,${base64StringData}`} download={fileNameData}><i class="bi bi-cloud-download ms-1"></i></a>
  </div>;

};

export const getShortName = (fullName) => {
    const nameParts = fullName.split(' ');
    let initials = '';

    for (let i = 0; i < nameParts.length; i++) {
        if (nameParts[i].length > 0) {
            initials += nameParts[i][0].toUpperCase();
        }
    }

    return initials;
}

export const handleSelectChange = (selectedOption, setToRecipient,setCCRecipient,setBCCRecipient, type) => {
    const toRecipient = [];
    const ccRecipient = [];
    const bccRecipient = [];
    if (selectedOption) {
      if (Array.isArray(selectedOption)) {
        if(type === "to"){
            selectedOption.forEach(option => {
                toRecipient.push({
                  "emailAddress": {
                    'name': option.label,
                    "address": option.value,
                  }
                });
              });
              setToRecipient(toRecipient);
        }
        if(type === "cc"){
            selectedOption.forEach(option => {
                ccRecipient.push({
                  "emailAddress": {
                    'name': option.label,
                    "address": option.value,
                  }
                });
              });
              setCCRecipient(ccRecipient)
        }
        if(type === "bcc"){
            selectedOption.forEach(option => {
                bccRecipient.push({
                  "emailAddress": {
                    'name': option.label,
                    "address": option.value,
                  }
                });
              });
              setBCCRecipient(bccRecipient)
        }
       
      } else {
        //console.log('Value:', selectedOption.value);
        //console.log('Label:', selectedOption.label);
      }
    }
  
    // //console.log(toRecipient);
     // Update the state with the selected recipients
  };
  export const removeEmailAddresses = (htmlContent)=> {
    // //console.log(htmlContent)
    const mailtoRegex = /<a\s+[^>]*href\s*=\s*['"][^'"]*mailto:[^'"]*['"][^>]*>[^<]*<\/a>/gi;

   
    // Replace all matching <a> tags with an empty string
    const ht =  htmlContent.replace(mailtoRegex, '');
    return ht.replace(/<[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}>/g, '')
  }

  export const getObjectByConversationId = (array, conversationIndex) => {
    //console.log(array, conversationIndex)
    const index = array.findIndex(object => {
      return object.message.conversationIndex === conversationIndex;
    });
  
    return index;
  }

  export const handleEmailType = async(type,setEmailType) => {
    //console.log(type);
    
    fetchData(type);
    setEmailType(type)
  };
