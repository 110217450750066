import React, { useEffect, useState } from "react";
import './email.scss';
import { Button, Form, InputGroup, NavLink } from 'react-bootstrap';
import Select from "react-select";
import makeAnimated from 'react-select/animated';

// Quill Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EmailListing } from "./EmailListing";
import { checkValidation, handleFileInputChange, handleFormSubmit, handleSelectChange, renderFileContent } from "../utils/emailUtils";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";

const animatedComponents = makeAnimated();

export const WriteEmail = ({setInboxClick,setNewClick, setShowCurrentEmail, currentEmail, setCurrentEmail, currentEmailBody, setCurrentEmailBody, setAttachments, setEmailsById, showCurrentEmail}) =>{

    const [subject, setSubject] = useState("");
    const [users, setUsers] = useState([])
    const [email, setEmail] = useState("");
    const [toRecipient, setToRecipient] = useState([]);
    const [ccRecipient, setCCRecipient] = useState([]);
    const [bccRecipient, setBCCRecipient] = useState([]);
    
    const [description, setDescription] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [base64String, setBase64String] = useState([]);
    const [fileName, setFileName] = useState([]);
    const [fileType, setFileType] = useState([]);

    const [attachmentsForEmail, setAttachmentsForSend] = useState([]);
    const [showAttachmentAfterAttach, setShowAttachments] = useState('');

    const [show, setShow] = useState(false);
     // Initialize state to track the active menu
     const [activeMenu, setActiveMenu] = useState(0);

     // For Sidebar list selected card
     const [active, setActive] = useState(0);
     const [showBCCField, setshowBCCField] = useState(false);
 
     // Handler function to update the active Menu
     const handleClick = (index) => {
         setActiveMenu(index);
     };
 
     // React-slick npm style customisation
     const customStyles = {
         control: (provided, state) => ({
             ...provided,
             borderColor: "var(--bs-secondary)",
             borderRadius: "0 .375rem .375rem 0",
             backgroundColor: "var(--dark-theme-bg)",
             fontSize: "15px",
             padding: "5px",
             boxShadow: 'none'
         }),
         placeholder: (provided) => ({
             ...provided,
             color: "var(--text-secondary)", // Change the color of the placeholder text
             fontSize: "15px",
             padding: "0",
         }),
     };

     useEffect(()=>{
        callAxios(environment.REACT_APP_API_URL_Live + "api/v1/outlook/getInfo",{},"post").then((res)=>{
                const dusers = atob(atob(atob(res.data.id)))
                const usser = JSON.parse(dusers)
            setUsers(usser)
        })
     },[])
 
     const UserList = users
 
     // Custom react-select component with animate with hide dropdown 
     const customComponents = {
         ...animatedComponents,
         DropdownIndicator: () => null,
         IndicatorSeparator: () => null
     };
 
     // Define the quill toolbar modules
     const toolbarModules = {
         toolbar: {
             container: '#toolbar', // Reference the ID of the custom toolbar
             handlers: {
                 // Add custom handlers if necessary
             }
         },
         toolbar: [
             [{ 'font': [] }],
             [{ 'header': '1' }, { 'header': '2' }],
             ['bold', 'italic', 'underline', 'strike'],
             [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
             [{ 'script': 'sub' }, { 'script': 'super' }],
             [{ 'list': 'bullet' }, { 'list': 'ordered' }],
             // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
             // [{ 'color': [] }, { 'background': [] }],
             ['link', 'image', 'video', 'formula'],
             ['blockquote', 'code-block'], // Quote
             ['clean'] // Remove formatting button
         ],
     };
 
     // Define the quill formats that are allowed
     const formats = [
         'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
     ];

     console.log(attachmentsForEmail)
     useEffect(() => {
        if (attachmentsForEmail && attachmentsForEmail.length > 0) {
          const attachmentElements = attachmentsForEmail.map(item => 
            renderFileContent(item.Name, item.contentType, item.ContentBytes)
          );
          setShowAttachments(attachmentElements);
        } else {
          setShowAttachments([]); // Clear the attachments if the array is empty
        }
      }, [attachmentsForEmail]);

const [showReply, setShowReply] = useState(false);
const handleReplyClose = () => {setShowReply(false);};
const handleReplyShow = () => setShowReply(true);

// console.log(JSON.stringify(users))
      
return (
<>

                            <div className="col-md-8 col-xxl-9">
                                <div className="user-details-section w-100 overflow-hidden">
                                    <div className="info-card rounded-3 h-100 p-3">
                                        <Form className="user-form-details d-flex flex-column h-100 overflow-auto" onSubmit={(e)=>{handleFormSubmit(e,subject, email, description, setIsSaving, base64String, fileName,fileType, setShow, toRecipient,ccRecipient,bccRecipient,attachmentsForEmail,setInboxClick,setNewClick)}}>
                                            <div>
                                                <InputGroup className="flex flex-nowrap mb-2">
                                                    <InputGroup.Text id="emailTo" className="d-grid dark-theme-bg border-secondary text-light fw-medium text-center p-2" style={{ width: '50px' }}>To</InputGroup.Text>
                                                    <Select className='custom-select text-dark w-100' classNamePrefix='custom-select'
                                                        isMulti
                                                        name="emailTo"
                                                        closeMenuOnSelect={true}
                                                        components={customComponents}
                                                        options={UserList}
                                                        styles={customStyles}
                                                        placeholder="Add email"
                                                        onChange={(selectedOption) => handleSelectChange(selectedOption, setToRecipient,setCCRecipient,setBCCRecipient, "to")} 
                                                    />
                                                </InputGroup>

                                                <InputGroup className="flex flex-nowrap mb-2">
                                                    <InputGroup.Text id="emailToCC" className="d-grid dark-theme-bg border-secondary text-light fw-medium text-center p-2" style={{ width: '50px' }}>Cc</InputGroup.Text>
                                                    <Select className='custom-select text-dark w-100 cc-user-input-css' classNamePrefix='custom-select'
                                                        isMulti
                                                        name="emailToCC"
                                                        closeMenuOnSelect={true}
                                                        components={customComponents}
                                                        options={UserList}
                                                        styles={customStyles}
                                                        placeholder="Add email"
                                                        onChange={(selectedOption) => handleSelectChange(selectedOption, setToRecipient,setCCRecipient,setBCCRecipient, "cc")} 
                                                    />
                                                    <InputGroup.Text id="emailToCC" className="d-grid dark-theme-bg border-secondary text-light text-center pointer p-2" onClick={() => setshowBCCField(!showBCCField)}>Bcc</InputGroup.Text>
                                                </InputGroup>

                                                {showBCCField && (
                                                    <InputGroup className="flex flex-nowrap mb-2">
                                                        <InputGroup.Text id="emailToBCC" className="d-grid dark-theme-bg border-secondary text-light fw-medium text-center p-2" style={{ width: '50px' }}>Bcc</InputGroup.Text>
                                                        <Select className='custom-select text-dark w-100' classNamePrefix='custom-select'
                                                            isMulti
                                                            // menuIsOpen
                                                            name="emailToBCC"
                                                            closeMenuOnSelect={true}
                                                            components={customComponents}
                                                            options={UserList}
                                                            styles={customStyles}
                                                            placeholder="Add email"
                                                            onChange={(selectedOption) => handleSelectChange(selectedOption, setToRecipient,setCCRecipient,setBCCRecipient, "bcc")} 
                                                        />
                                                    </InputGroup>
                                                )}

                                                <Form.Group className="mb-2" controlId="addSubject">
                                                    <Form.Control type="text" className="fs-14 user-subject-input-css border border-secondary shadow-none py-2" placeholder="Add a subject" onChange={(e) => {
                    setSubject(e.target.value);
                    checkValidation("subject", e.target.value);
                  }} />
                                                </Form.Group>
                                            </div>

                                            <div className="editor-section border border-secondary rounded d-flex flex-column flex-fill overflow-auto p-2" style={{ minHeight: '405px' }}>

                                                {/* React Quill Editor */}
                                                <ReactQuill id="note-description" className="custom-quill-editor d-flex flex-column flex-fill overflow-auto" theme="snow" placeholder="Please enter description here.."
                                                    modules={toolbarModules}
                                                    formats={formats}
                                                    onChange={setDescription} 
                                                />

                                                <div className="end-section px-2">
                                                    <div className="files-wrapper d-flex flex-wrap gap-2 mb-2">
                                                        
                                                        {showAttachmentAfterAttach}
                                                    </div>
                                                    <div className="file-upload-wrapper">
                                                        <Form.Control type="file" multiple id="attachFile" className="d-none" onChange={(event)=>{handleFileInputChange(event,setAttachmentsForSend,attachmentsForEmail)}} />
                                                        <label for="attachFile" className="submit-msg-wrapper d-flex align-items-center justify-content-between gap-2 rounded-3 p-2">
                                                            <div className="d-flex align-items-center gap-1 text-white text-opacity-75 pointer"><i className="bi bi-paperclip fs-5"></i> Attach file</div>
                                                            <Button type="submit" variant="primary" size="sm" className="border-light rounded-3 px-4"  >Send</Button>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        
</>
)


}