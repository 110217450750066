import React, { useState, useEffect } from 'react';

// Configure any reguired theme
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// Map Chart
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";

// Apply the themes
am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

export const MapChart = () => {
    useEffect(() => {
        // Create map instance
        let map = am4core.create("chartdix", am4maps.MapChart);

        let mapData = [
            { "id": "AF", "name": "Afghanistan", "value": 32358260, "color": map.colors.getIndex(0) },
            { "id": "DZ", "name": "Algeria", "value": 35980193, "color": map.colors.getIndex(2) },
            { "id": "AO", "name": "Angola", "value": 19618432, "color": map.colors.getIndex(2) },
            { "id": "AR", "name": "Argentina", "value": 40764561, "color": map.colors.getIndex(3) },
            { "id": "AM", "name": "Armenia", "value": 3100236, "color": map.colors.getIndex(1) },
            { "id": "AU", "name": "Australia", "value": 22605732, "color": "#8aabb0" },
            { "id": "BH", "name": "Bahrain", "value": 1323535, "color": map.colors.getIndex(0) },
            { "id": "BD", "name": "Bangladesh", "value": 150493658, "color": map.colors.getIndex(0) },
            { "id": "BY", "name": "Belarus", "value": 9559441, "color": map.colors.getIndex(1) },
            { "id": "BE", "name": "Belgium", "value": 10754056, "color": map.colors.getIndex(1) },
            { "id": "BJ", "name": "Benin", "value": 9099922, "color": map.colors.getIndex(2) },
            { "id": "BO", "name": "Bolivia", "value": 10088108, "color": map.colors.getIndex(3) },
            { "id": "BW", "name": "Botswana", "value": 2030738, "color": map.colors.getIndex(2) },
            { "id": "BR", "name": "Brazil", "value": 196655014, "color": map.colors.getIndex(3) },
            { "id": "BN", "name": "Brunei", "value": 405938, "color": map.colors.getIndex(0) },
            { "id": "KH", "name": "Cambodia", "value": 14305183, "color": map.colors.getIndex(0) },
            { "id": "CM", "name": "Cameroon", "value": 20030362, "color": map.colors.getIndex(2) },
            { "id": "CA", "name": "Canada", "value": 34349561, "color": map.colors.getIndex(4) }
        ];


        // Set map definition
        map.geodata = am4geodata_worldLow;

        // Set projection
        map.projection = new am4maps.projections.Miller();
        map.chartContainer.wheelable = false;
        
        // Create map polygon series
        let polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.exclude = ["AQ"];
        polygonSeries.useGeodata = true;
        polygonSeries.nonScalingStroke = true;
        polygonSeries.strokeWidth = 0.5;
        polygonSeries.calculateVisualCenter = true;

        polygonSeries.events.on("validated", function () {
            imageSeries.invalidate();
        })


        let imageSeries = map.series.push(new am4maps.MapImageSeries());
        imageSeries.data = mapData;
        imageSeries.dataFields.value = "value";

        let imageTemplate = imageSeries.mapImages.template;
        imageTemplate.nonScaling = true
        imageTemplate.adapter.add("latitude", function (latitude, target) {
            let polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
            if (polygon) {
                return polygon.visualLatitude;
            }
            return latitude;
        })

        imageTemplate.adapter.add("longitude", function (longitude, target) {
            let polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
            if (polygon) {
                return polygon.visualLongitude;
            }
            return longitude;
        })

        let circle = imageTemplate.createChild(am4core.Circle);
        circle.fillOpacity = 0.7;
        circle.propertyFields.fill = "color";
        circle.tooltipText = "{name}: [bold]{value}[/]";

        imageSeries.heatRules.push({
            "target": circle,
            "property": "radius",
            "min": 4,
            "max": 30,
            "dataField": "value"
        })

        let label = imageTemplate.createChild(am4core.Label);
        label.text = "{name}"
        label.horizontalCenter = "middle";
        label.padding(0, 0, 0, 0);
        label.adapter.add("dy", function (dy, target) {
            let circle = target.parent.children.getIndex(0);
            return circle.pixelRadius;
        })
        // cxvsfvfdv
    });

    return (
        <div className='mb-4'>
            <div className='bg-white border border-3 border-primary rounded-3 p-4'>
                <div id="chartdix" style={{ height: '60vh', width: '100%' }}></div>
            </div>
        </div>
    )
}
