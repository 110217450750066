import React, { useRef } from "react";
import { useState } from "react";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../firebaseConfig";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";


// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import preloader css
import "../LeadsListingPage/LeadsListingPage.css";
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


const ReplyListing = () => {
  const [userRole, setUserRole] = useState({});
  const { state } = useLocation();
  const [reply, setReply] = useState("");
  const [replies, setReplies] = useState([]);
  const [user, loading, error] = useAuthState(auth);
  let navigate = useNavigate();

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      const getUserRole = async () => {
        if (user) {
          const q = query(
            collection(database, environment.REACT_APP_USERS_DB),
            where("email", "==", user?.email),
            orderBy("timeStamp", "asc"),
            limit(1)
          );
          onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const id = doc.id;
              const data = doc.data();
              data["id"] = doc.id;
              setUserRole(data);
            });
          });
        }
      };
      getUserRole();

      const getIssues = async () => {
        await callAxios(
          environment.REACT_APP_API_URL_Live + "api/v1/issues/get-reply",
          { issueId: state?.issueId },
          "post"
        ).then((replies) => {
          setReplies(replies);
        });
      };
      getIssues();
    }
  }, [user, loading]);

  //console.log(userRole);

  const formatAMPM = (date) => {
    //console.log("LOCAL TIME:",date)
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  // const checkValidation =  => {
  //   if (key === "reply" && val !== "") {
  //     document.getElementById("editorBorder").style.border = "none";
  //   }
  // };
  if (reply) {
    document.getElementById("editorBorder").style.border = "none";
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (reply === "") {
      document.getElementById("editorBorder").style.border = "1px solid red";
      return false;
    } else {
      document.getElementById("replies-preloader").classList.remove("d-none");
      const formData = {
        issue_id: state?.issueId,
        uid: user?.uid,
        reply: reply,
        name: user?.displayName,
        email: user?.email,
        userType: userRole?.userType,
      };
      //console.log(formData)

      const response = await callAxios(
        environment.REACT_APP_API_URL_Live + "api/v1/issues/save-reply",
        formData,
        "post"
      );
      if (response?.data?.status === 200) {
        resetForm();
        toast.success("Reply Submitted!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        document.getElementById("replies-preloader").classList.add("d-none");
        toast.error("Network Issue!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const resetForm = async () => {
    setReply("");
    const element = document.getElementsByClassName("ql-editor");
    element[0].innerHTML = "";
    await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v1/issues/get-reply",
      { issueId: state?.issueId },
      "post"
    ).then((replies) => {
      setReplies(replies);
    });
    document.getElementById("ReplyForm").reset();
    document.getElementById("closeReplyForm").click();
    document.getElementById("replies-preloader").classList.add("d-none");
  };

  let htmlContent;
  if (replies?.data?.length > 0) {
    htmlContent = replies?.data?.map((item, index) => {
      return (
        <div
          className="list-group-item border-secondary py-3 lh-sm"
          id="list-container"
          key={index}
        >
          <div className="mb-2 small item-description" dangerouslySetInnerHTML={{ __html: item?.reply }}></div>
          <div className="issue-date fw-medium mb-2 small text-warning text-opacity-75">
            {new Date(item.createdAt).getDate() +
              " " +
              new Date(item.createdAt).toLocaleString("default", {
                month: "long",
              }) +
              " " +
              new Date(item.createdAt).getFullYear() +
              " " +
              formatAMPM(new Date(item.createdAt))}
          </div>
          <div className="replied-by">
            {userRole?.userType === "SA" || userRole?.userType === "SSA" ? (
              item.userType === "BDM" || item.userType === "CAM" ? (
                <span
                  className="badge rounded-pill text-bg-success text-capitalize"
                  style={{ minWidth: "70px" }}
                >
                  Replied By {item.name}
                </span>
              ) : (
                <span
                  className="badge rounded-pill text-bg-success text-capitalize"
                  style={{ minWidth: "70px" }}
                >
                  Replied By Admin
                </span>
              )
            ) : item.userType == "SA" || item.userType == "SSA" ? (
              <span
                className="badge rounded-pill text-bg-success text-capitalize"
                style={{ minWidth: "70px" }}
              >
                Replied By Admin
              </span>
            ) : (
              <span
                className="badge rounded-pill text-bg-info text-capitalize"
                style={{ minWidth: "70px" }}
              >
                Replied By User
              </span>
            )}
          </div>
        </div>
      );
    });
  } else {
    htmlContent = (
      <div className="border-top pt-3 text-warning border-secondary">
        No replies found yet!
      </div>
    );
  }


  return (
    <>
      {/* <ToastContainer /> */}

      <section className="py-5">
        <div className="container">
          {/* Button trigger modal */}
          <div className="text-end">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#ReplyModel"
            >
              Submit Your Reply <i className="bi bi-plus-circle"></i>
            </button>
          </div>

          <div className="issues-listing px-3 px-md-4 py-4 mt-4">
            <h5 className="pb-3 mb-0">
              <NavLink to="/issues-list">
                <i className="bi bi-arrow-left"></i>
              </NavLink>{" "}
              Recent Replies -{" "}
              <span
                className="fw-medium text-capitalize"
                style={{ color: "var(--theme-blue1)" }}
              >
                {state.subject}
              </span>
            </h5>
            <div className="list-group scrollarea">{htmlContent}</div>
          </div>
        </div>
      </section>

      {/* Reply of Issue Chat Modal */}
      <div
        className="modal fade"
        id="ReplyModel"
        tabIndex={-1}
        data-bs-backdrop="static"
        aria-labelledby="ReplyModelLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content text-body">
            <div className="modal-header bg-primary bg-gradient text-white px-md-4">
              <h1
                className="modal-title fs-6 text-capitalize"
                id="ReplyModelLabel"
              >
                Submit Your Reply
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ filter: "invert(100)" }}
              ></button>
            </div>
            <div className="modal-body px-md-4 py-4">
              <form id="ReplyForm">
                <div className="mb-4" id="editorBorder">

                  {/* modules={modules} */}
                  <ReactQuill theme="snow" placeholder="Please write reply here..." onChange={setReply} />

                </div>

                <div className="btn-wrapper text-end">
                  <button
                    type="button"
                    id="closeReplyForm"
                    className="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    style={{ minWidth: "80px" }}
                  >
                    Close
                  </button>
                  <button
                    onClick={handleFormSubmit}
                    type="submit"
                    className="btn btn-primary px-4"
                  >
                    Submit
                  </button>

                  <div
                    className="d-none"
                    id="replies-preloader"
                    style={{
                      position: "absolute",
                      margin: "-34px -21px 0px 1px",
                    }}
                  >
                    <div
                      style={{ marginLeft: "12px", float: "right" }}
                      className="replies-spinner"
                    ></div>{" "}
                    Please wait ....
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReplyListing;
