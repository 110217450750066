import React, { useEffect, useState } from "react"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import WarningModal from '../Components/Warningmodal';

export const EditOptionNo = ({ closeEditEmploymentModal, sendmydata, getskills, updateEmp, isCurrentEmployement, profileId,setRemainingCharacters, employmentData, remainingCharacters }) => {
    // const getUserId = process.env.REACT_APP_USER_ID
    const [showModal, setShowModal] = useState(false);
    const [textmessage, setTextmessage] = useState('');
    const [deleteEmp, setDeleteEmp] = useState('')

    // convert year and monthname
    const workingfromfnc = (data) => {
        const dateStr = data;
        const date = new Date(dateStr);
        const year = date.getFullYear(); // Get the year
        const month = date.getMonth() + 1; // Get the month (Note: January is 0, so we add 1)
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}`; // Pad the month with leading zero if necessary
        return formattedDate
    }

    const [formData, setFormData] = useState({
        currCmp:sendmydata?.company_name ? sendmydata?.company_name : '',
        currJobtitle:sendmydata?.job_title ? sendmydata?.job_title : '',
        workedtill: sendmydata?.worked_till ? workingfromfnc(sendmydata?.worked_till) : '',
        joiningDate:sendmydata?.joining_date ? workingfromfnc(sendmydata?.joining_date) : '',
        jobProfile:sendmydata?.job_profile ? sendmydata?.job_profile : '',
    });

    const handleInputChange = (e) => {
        
        const { name, value } = e.target;

        if(name === "jobProfile"){
            const remaining = 4000 - value.length;
            setRemainingCharacters(remaining);
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // convert year and monthname
    const yearmonthname = (data, type) => {
        const dateString = data;
  
        // Create a Date object with the input string
        const date = new Date(dateString);
      
        // Get the year
        if(type == 'year'){
            const year = date.getFullYear();
            return year
        }
        
        // Get the month name
        if(type == 'month'){
            const month = new Intl.DateTimeFormat('en-US', { month: '2-digit' }).format(date);
            return month
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        // Add your form submission logic here
        const postData = {
            "profileId":profileId ? profileId : '',
            "employementId":sendmydata?.id, 
            "type":"update",
            "isCurrentEmployement": isCurrentEmployement,
            "companyName":formData?.currCmp, 
            "jobTitle":formData?.currJobtitle,
            "workedTillYear":yearmonthname(formData?.workedtill, 'year'),
            "workedTillMonth":yearmonthname(formData?.workedtill, 'month'),
            "joiningDateYear":yearmonthname(formData?.joiningDate, 'year'),
            "joiningDateMonth":yearmonthname(formData?.joiningDate, 'month'),
            "workingFromYear":yearmonthname(formData?.joiningDate, 'year'),
            "workingFromMonth":yearmonthname(formData?.joiningDate, 'month'),
            "jobProfile":formData?.jobProfile, 
        }
        updateEmp(postData)
    };
    // Edit Data

    // deleteemployment
    const deleteemployment = () => {
        setShowModal(true)
        setTextmessage('Are you sure you want to delete this employment?')
        const sendData = {
            "profileId":profileId ? profileId : '',
            "employementId":sendmydata?.id, 
            "type":"delete"
        }
        setDeleteEmp(sendData)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    useEffect(() => {
        
        if(sendmydata?.job_profile){
            const remaining = 4000 - sendmydata?.job_profile.length;
        setRemainingCharacters(remaining);
        }
        
        
      }, [sendmydata?.job_profile]);

    // deleteemployment
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1 and pad with leading zero
    
    // Create the max value for the input
    const maxMonth = `${currentYear}-${currentMonth}`;
    return(
        <>
            {/* <span className='delete-btn' onClick={() => deleteemployment(true)}><i class="bi bi-trash"></i></span>
            <WarningModal show={showModal} onClose={handleCloseModal} textmessage={textmessage} updateEmp={updateEmp} deleteEmp={deleteEmp} /> */}
            <Form action="" className="form-cover" onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Previous company name<span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="text" name="currCmp" value={formData.currCmp} onChange={handleInputChange} placeholder="Type your orgainization" autoComplete='off' required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Previous job title<span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="text" name="currJobtitle" value={formData.currJobtitle} onChange={handleInputChange} placeholder="Type your designation" autoComplete='off' required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Joining date<span className='text-danger'>*</span></Form.Label>
                    <Form.Control max={maxMonth} type="month" name="joiningDate" value={formData.joiningDate} onChange={handleInputChange} autoComplete='off' required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Job profile<span className='text-danger'>*</span></Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder='Type here...' autoComplete='off' name="jobProfile" value={formData.jobProfile} onChange={handleInputChange} required />
                    <div className="charLenght fs-14 text-secondary text-end mt-2">{remainingCharacters} character(s) left</div>
                </Form.Group>
                <Form.Group>
                    <div className="row g-2">
                        <div className="col-12">
                            <Form.Group className="mb-3">
                                <Form.Label>Worked till<span className='text-danger'>*</span></Form.Label>
                                <Form.Control type="month" name="workedtill" value={formData.workedtill} onChange={handleInputChange} autoComplete='off' required />
                            </Form.Group>
                        </div>
                    </div>
                </Form.Group>
                <div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
                    <Button variant="danger" onClick={closeEditEmploymentModal} className='rounded-pill px-4'>Cancel</Button>
                    <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
                </div>
            </Form>
        </>
    )
}