import React from 'react';

export const RefreshApp = ({currentAppVersion}) => {
    //console.log("in refreshing.",currentAppVersion)
    const refreshApp = () =>{
        localStorage.setItem("camJoinedLead", null);
        localStorage.setItem("bdmJoinedLead", null);
        localStorage.setItem("checkAudio", null);
        //console.log("in refreshing.",currentAppVersion)
        localStorage.setItem("appVersion",currentAppVersion)
        window.location.reload(true);
    }
        
        return (
        <div className='join-call-model'>
            {/* Modal */}
            <div className="modal fade" id="refreshApp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: '650px' }}>
                    <div className="modal-content bg-dark border-top border-5 border-primary rounded-0 shadow">
                        <div className="modal-body p-4 p-md-5 text-center">
                            <div className='fs-5 fw-semibold mb-4' id='DeivceErrorMessage----'></div>
                            <div className='fs-5 fw-semibold mb-4'>Your NM App Version {localStorage.getItem("appVersion")} is no longer up to date!</div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div id="available-version" className='mb-3 text-start'></div>
                                    <button className='btn btn-success' onClick={refreshApp}>Update</button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            
        </div>


    )
}