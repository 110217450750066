import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { callAxios, getProfileData } from '../../utils/utils';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
import { Confirm } from './Confirm';

export const CareerProfile = ({ showCareerProfileModal, closeCareerProfileModal, setProfileData, profileId, isUpdateAction, updateData, setShowCareerProfileModal,setIsLoading }) => {
    // console.log(isUpdateAction,updateData)
    const [current_industry, set_current_industry] = useState("");
	const [department, set_department] = useState("");
    const [role_category, set_role_category] = useState("");
    const [job_role, set_job_role] = useState("");
    const [desire_job_type, set_desire_job_type] = useState("");
    const [desired_employement_type, set_desired_employement_type] = useState("");
    const [preferred_shift, set_preferred_shift] = useState("");
	const [preferred_work_location, set_preferred_work_location] = useState("");
    const [expected_salary, set_expected_salary] = useState("");
    const [currency, set_currency] = useState("INR")
	

    
	useEffect(() => {
		if (isUpdateAction) {
            set_current_industry(updateData?.current_industry);
            set_department(updateData?.department);
            set_role_category(updateData?.role_category);
            set_job_role(updateData?.job_role)
            set_desire_job_type(updateData?.desire_job_type);
            set_desired_employement_type(updateData?.desired_employement_type);
            set_preferred_shift(updateData?.preferred_shift);
            set_preferred_work_location(updateData?.preferred_work_location);
            set_expected_salary(updateData?.expected_salary);
            set_currency(updateData?.currency);
            setDesiredJobTypes(JSON.parse(updateData?.desire_job_type))
            setDesiredEmploymentTypes(JSON.parse(updateData?.desired_employement_type))
            setPreferredShift(updateData?.preferred_shift)
            setPreferredWorkLocation(JSON.parse(updateData?.preferred_work_location))
           

		} else {
			set_current_industry("");
            set_department("");
            set_role_category("");
            set_job_role("")
            set_desire_job_type("");
            set_desired_employement_type("");
            set_preferred_shift("");
            set_preferred_work_location("");
            set_expected_salary("");
            set_currency("");
            setDesiredJobTypes("")
            setDesiredEmploymentTypes("")
            setPreferredShift("")
            setPreferredWorkLocation("")
		}
	}, [updateData]);

    const saveCareerProfile = async (e) => {
		e.preventDefault();
		setIsLoading(true);
        setShowCareerProfileModal(false);
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/add-update-career-profile", { profile_id: profileId, current_industry: current_industry, department: department, role_category: role_category, job_role:job_role, desire_job_type:JSON.stringify(desiredJobTypes), desired_employement_type: JSON.stringify(desiredEmploymentTypes), preferred_shift:preferredShift, preferred_work_location:JSON.stringify(preferredWorkLocation), expected_salary:expected_salary, currency:currency}, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 201 || socialResponse.data.statusCode === 200) {
					
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					setProfileData(await getProfileData(profileId, setIsLoading))
					
				} else {
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
                    setProfileData(await getProfileData(profileId, setIsLoading))
				}

			})
		

	}
    

    // Show Delete Profile Modal
	const [showDeleteProfile, setShowDeleteProfile] = useState(false);
	const closeDeleteProfile = () => setShowDeleteProfile(false);
	const openDeleteProfile = () => setShowDeleteProfile(true);

    const animatedComponents = makeAnimated();
    const preferredLocation = [
        { value: 'Bengaluru', label: 'Bengaluru' },
        { value: 'Mumbai', label: 'Mumbai' },
        { value: 'Pune', label: 'Pune' },
        { value: 'Chennai', label: 'Chennai' },
        { value: 'Hyderabad', label: 'Hyderabad' },
        { value: 'Gurugram', label: 'Gurugram' },
        { value: 'Noida', label: 'Noida' },
        { value: 'Ahmedabad', label: 'Ahmedabad' },
        { value: 'Kolkata', label: 'Kolkata' },
        { value: 'Delhi / NCR', label: 'Delhi / NCR' },
        { value: 'Remote', label: 'Remote' }
    ]
    // React-slick npm style customisation
    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--theme-color4)', // Change the color of the placeholder text
            fontSize: '14px',
            fontWeight: '400',
            padding: '0',
        }),
    };

    const [desiredJobTypes, setDesiredJobTypes] = useState([]);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        if (checked) {
            setDesiredJobTypes([...desiredJobTypes, name]);
        } else {
            setDesiredJobTypes(desiredJobTypes.filter(jobType => jobType !== name));
        }
    };

    const [desiredEmploymentTypes, setDesiredEmploymentTypes] = useState([]);

    const handleCheckboxChange1 = (event) => {
        const { name, checked } = event.target;

        if (checked) {
            setDesiredEmploymentTypes([...desiredEmploymentTypes, name]);
        } else {
            setDesiredEmploymentTypes(desiredEmploymentTypes.filter(type => type !== name));
        }
    };

    const [preferredShift, setPreferredShift] = useState('');

    const handleRadioChange = (event) => {
        setPreferredShift(event.target.value);
    };

    const [preferredWorkLocation, setPreferredWorkLocation] = useState([]);

    const handleChange = (selectedOptions) => {
        if (selectedOptions.length <= 10) {
            setPreferredWorkLocation(selectedOptions);
        } else {
            alert("You can select up to 10 locations only.");
        }
    };

console.log(desiredJobTypes,desiredEmploymentTypes,preferredWorkLocation)
    return (
        <>
         <Modal show={showCareerProfileModal} centered size='lg' onHide={closeCareerProfileModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
            <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
                <div className="close-wrapper text-end">
                    <Button variant="link" onClick={closeCareerProfileModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
                </div>

                <div className="heading-wrapper mb-4">
                    <div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
                        <span className='widgetTitle text-black fs-5 fw-semibold'>Career profile</span>
                        {/* This button will show only when you click to edit the details */}
                        {/* <span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0'>Delete</span> */}
                    </div>
                    <p className='subTitle fs-14'>Add details about your current and preferred job profile. This helps us personalise your job recommendations.</p>
                </div>

                <Form action="" className="form-cover" onSubmit={saveCareerProfile}>
                    <Form.Group className="mb-3">
                        <Form.Label>Current industry<span className='text-danger'>*</span></Form.Label>
                        <Form.Select name="currentIndustry" required value={current_industry} onChange={(e)=>{set_current_industry(e.target.value)}} >
                            <option selected hidden value=''>Select industry of your current company</option>
                            <option value="Financial Services">Financial Services</option>
<option value="Analytics / KPO / Research">Analytics / KPO / Research</option>
<option value="BPO / Call Centre">BPO / Call Centre</option>
<option value="IT Services &amp; Consulting">IT Services &amp; Consulting</option>
<option value="Electronic Components / Semiconductors">Electronic Components / Semiconductors</option>
<option value="Electronics Manufacturing">Electronics Manufacturing</option>
<option value="Electronic Manufacturing Services (EMS)">Electronic Manufacturing Services (EMS)</option>
<option value="Emerging Technologies">Emerging Technologies</option>
<option value="3D Printing">3D Printing</option>
<option value="AI/ML">AI/ML</option>
<option value="AR/VR">AR/VR</option>
<option value="Blockchain">Blockchain</option>
<option value="Cloud">Cloud</option>
<option value="Cybersecurity">Cybersecurity</option>
<option value="Drones/Robotics">Drones/Robotics</option>
<option value="IoT">IoT</option>
<option value="Nanotechnology">Nanotechnology</option>
<option value="Hardware &amp; Networking">Hardware &amp; Networking</option>
<option value="Internet">Internet</option>
<option value="E-Commerce">E-Commerce</option>
<option value="OTT">OTT</option>
<option value="Software Product">Software Product</option>
<option value="Banking">Banking</option>
<option value="Financial Services">Financial Services</option>
<option value="Asset Management">Asset Management</option>
<option value="Broking">Broking</option>
<option value="FinTech / Payments">FinTech / Payments</option>
<option value="Insurance">Insurance</option>
<option value="Investment Banking / Venture Capital / Private Equity">Investment Banking / Venture Capital / Private Equity</option>
<option value="NBFC">NBFC</option>
<option value="Micro Finance">Micro Finance</option>
<option value="Education / Training">Education / Training</option>
<option value="E-Learning / EdTech">E-Learning / EdTech</option>
<option value="Auto Components">Auto Components</option>
<option value="Tyre">Tyre</option>
<option value="Automobile">Automobile</option>
<option value="Automobile Dealers">Automobile Dealers</option>
<option value="Electric Vehicle (EV)">Electric Vehicle (EV)</option>
<option value="Building Material">Building Material</option>
<option value="Cement">Cement</option>
<option value="Ceramic">Ceramic</option>
<option value="Glass">Glass</option>
<option value="Chemicals">Chemicals</option>
<option value="Paints">Paints</option>
<option value="Defence &amp; Aerospace">Defence &amp; Aerospace</option>
<option value="Electrical Equipment">Electrical Equipment</option>
<option value="Fertilizers / Pesticides / Agro chemicals">Fertilizers / Pesticides / Agro chemicals</option>
<option value="Industrial Automation">Industrial Automation</option>
<option value="Industrial Equipment / Machinery">Industrial Equipment / Machinery</option>
<option value="Construction Equipment">Construction Equipment</option>
<option value="Machine Tools">Machine Tools</option>
<option value="Iron &amp; Steel">Iron &amp; Steel</option>
<option value="Metals &amp; Mining">Metals &amp; Mining</option>
<option value="Packaging &amp; Containers">Packaging &amp; Containers</option>
<option value="Petrochemical / Plastics / Rubber">Petrochemical / Plastics / Rubber</option>
<option value="Pulp &amp; Paper">Pulp &amp; Paper</option>
<option value="Aviation">Aviation</option>
<option value="Courier / Logistics">Courier / Logistics</option>
<option value="Logistics Tech">Logistics Tech</option>
<option value="Engineering &amp; Construction">Engineering &amp; Construction</option>
<option value="Oil &amp; Gas">Oil &amp; Gas</option>
<option value="Ports &amp; Shipping">Ports &amp; Shipping</option>
<option value="Shipbuilding">Shipbuilding</option>
<option value="Power">Power</option>
<option value="Hydro">Hydro</option>
<option value="Nuclear">Nuclear</option>
<option value="Solar">Solar</option>
<option value="Wind">Wind</option>
<option value="Railways">Railways</option>
<option value="Real Estate">Real Estate</option>
<option value="Co-working">Co-working</option>
<option value="Urban Transport">Urban Transport</option>
<option value="Water Treatment / Waste Management">Water Treatment / Waste Management</option>
<option value="Beauty &amp; Personal Care">Beauty &amp; Personal Care</option>
<option value="Beverage">Beverage</option>
<option value="Brewery / Distillery">Brewery / Distillery</option>
<option value="Consumer Electronics &amp; Appliances">Consumer Electronics &amp; Appliances</option>
<option value="Fitness &amp; Wellness">Fitness &amp; Wellness</option>
<option value="FMCG">FMCG</option>
<option value="Tobacco">Tobacco</option>
<option value="Food Processing">Food Processing</option>
<option value="Dairy">Dairy</option>
<option value="Meat / Poultry">Meat / Poultry</option>
<option value="Sugar">Sugar</option>
<option value="Furniture &amp; Furnishing">Furniture &amp; Furnishing</option>
<option value="Gems &amp; Jewellery">Gems &amp; Jewellery</option>
<option value="Hotels &amp; Restaurants">Hotels &amp; Restaurants</option>
<option value="Leather">Leather</option>
<option value="Retail">Retail</option>
<option value="Textile &amp; Apparel">Textile &amp; Apparel</option>
<option value="Fashion">Fashion</option>
<option value="Handicraft">Handicraft</option>
<option value="Home Textile">Home Textile</option>
<option value="Technical Textile">Technical Textile</option>
<option value="Yarn &amp; Fabric">Yarn &amp; Fabric</option>
<option value="Travel &amp; Tourism">Travel &amp; Tourism</option>
<option value="Biotechnology">Biotechnology</option>
<option value="Clinical Research / Contract Research">Clinical Research / Contract Research</option>
<option value="Medical Devices &amp; Equipment">Medical Devices &amp; Equipment</option>
<option value="Medical Services / Hospital">Medical Services / Hospital</option>
<option value="Diagnostics">Diagnostics</option>
<option value="Pharmaceutical &amp; Life Sciences">Pharmaceutical &amp; Life Sciences</option>
<option value="Advertising &amp; Marketing">Advertising &amp; Marketing</option>
<option value="Digital Marketing">Digital Marketing</option>
<option value="Public Relations">Public Relations</option>
<option value="Animation &amp; VFX">Animation &amp; VFX</option>
<option value="Events / Live Entertainment">Events / Live Entertainment</option>
<option value="Film / Music / Entertainment">Film / Music / Entertainment</option>
<option value="Gaming">Gaming</option>
<option value="Printing &amp; Publishing">Printing &amp; Publishing</option>
<option value="Sports / Leisure &amp; Recreation">Sports / Leisure &amp; Recreation</option>
<option value="Telecom / ISP">Telecom / ISP</option>
<option value="TV / Radio">TV / Radio</option>
<option value="Accounting / Auditing">Accounting / Auditing</option>
<option value="Architecture / Interior Design">Architecture / Interior Design</option>
<option value="Content Development / Language">Content Development / Language</option>
<option value="Design">Design</option>
<option value="Facility Management Services">Facility Management Services</option>
<option value="Law Enforcement / Security Services">Law Enforcement / Security Services</option>
<option value="Legal">Legal</option>
<option value="Management Consulting">Management Consulting</option>
<option value="Recruitment / Staffing">Recruitment / Staffing</option>
<option value="Agriculture / Forestry / Fishing">Agriculture / Forestry / Fishing</option>
<option value="Agri-tech">Agri-tech</option>
<option value="Government / Public Administration">Government / Public Administration</option>
<option value="Import &amp; Export">Import &amp; Export</option>
<option value="Miscellaneous">Miscellaneous</option>
<option value="NGO / Social Services / Industry Associations">NGO / Social Services / Industry Associations</option>

                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Department<span className='text-danger'>*</span></Form.Label>
                        <Form.Select name="industryDepartment" required value={department} onChange={(e)=>{set_department(e.target.value)}}>
                            <option selected hidden value=''>Select your department / area of expertise</option>
                            <option value="Business Analysis and Strategy">Business Analysis and Strategy</option>
<option value="Cybersecurity">Cybersecurity</option>
<option value="Customer Relationship Management (CRM)">Customer Relationship Management (CRM)</option>
<option value="Data Science and Analytics">Data Science and Analytics</option>
<option value="Database Administration">Database Administration</option>
<option value="DevOps and System Integration">DevOps and System Integration</option>
<option value="Enterprise Architecture">Enterprise Architecture</option>
<option value="Enterprise Resource Planning (ERP)">Enterprise Resource Planning (ERP)</option>
<option value="IT Management">IT Management</option>
<option value="IT Procurement and Asset Management">IT Procurement and Asset Management</option>
<option value="IT Support and Help Desk">IT Support and Help Desk</option>
<option value="Infrastructure and Operations">Infrastructure and Operations</option>
<option value="Project Management">Project Management</option>
<option value="Quality Assurance and Testing">Quality Assurance and Testing</option>
<option value="Research and Development (R&D)">Research and Development (R&D)</option>
<option value="Software Development">Software Development</option>
<option value="Telecommunications and Network Engineering">Telecommunications and Network Engineering</option>

                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Role category<span className='text-danger'>*</span></Form.Label>
                        <Form.Select name="roleCategory" required value={role_category} onChange={(e)=>{set_role_category(e.target.value)}}>
                            <option selected hidden value=''>Select your role category in  Finance & Accounting</option>
                            <option value="Application Development">Application Development</option>
<option value="Business Analysis">Business Analysis</option>
<option value="Cloud Computing">Cloud Computing</option>
<option value="Cybersecurity">Cybersecurity</option>
<option value="Data Science and Analytics">Data Science and Analytics</option>
<option value="Database Administration">Database Administration</option>
<option value="DevOps">DevOps</option>
<option value="Enterprise Architecture">Enterprise Architecture</option>
<option value="IT Management">IT Management</option>
<option value="IT Support">IT Support</option>
<option value="Network Engineering">Network Engineering</option>
<option value="Project Management">Project Management</option>
<option value="Quality Assurance">Quality Assurance</option>
<option value="Research and Development">Research and Development</option>
<option value="System Administration">System Administration</option>
<option value="Technical Support">Technical Support</option>
<option value="Telecommunications">Telecommunications</option>
<option value="Web Development">Web Development</option>

                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Job role<span className='text-danger'>*</span></Form.Label>
                        <Form.Select name="jobRole" required value={job_role} onChange={(e) => {set_job_role(e.target.value)}}>
                            <option selected hidden value=''>Select your job role</option>
                            <option value="Application Developer">Application Developer</option>
<option value="Business Analyst">Business Analyst</option>
<option value="Chief Information Officer (CIO)">Chief Information Officer (CIO)</option>
<option value="Cloud Engineer">Cloud Engineer</option>
<option value="Cybersecurity Analyst">Cybersecurity Analyst</option>
<option value="Data Analyst">Data Analyst</option>
<option value="Data Scientist">Data Scientist</option>
<option value="Database Administrator (DBA)">Database Administrator (DBA)</option>
<option value="DevOps Engineer">DevOps Engineer</option>
<option value="Help Desk Technician">Help Desk Technician</option>
<option value="IT Director">IT Director</option>
<option value="IT Manager">IT Manager</option>
<option value="IT Project Manager">IT Project Manager</option>
<option value="Network Administrator">Network Administrator</option>
<option value="Network Engineer">Network Engineer</option>
<option value="Quality Assurance (QA) Engineer">Quality Assurance (QA) Engineer</option>
<option value="Scrum Master">Scrum Master</option>
<option value="Software Developer">Software Developer</option>
<option value="Solutions Architect">Solutions Architect</option>
<option value="System Administrator">System Administrator</option>
<option value="Technical Support Specialist">Technical Support Specialist</option>
<option value="Telecommunications Specialist">Telecommunications Specialist</option>
<option value="Web Developer">Web Developer</option>

                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3 pt-2">
            <Form.Label>Desired job type</Form.Label>
            <div className='radio-wrapper d-flex flex-wrap column-gap-4 gap-lg-5 ms-1'>
                <div className="form-check form-check-inline d-flex align-items-center gap-2">
                    <input 
                        type="checkbox" 
                        checked={desiredJobTypes.includes('permanent')} 
                        onChange={handleCheckboxChange} 
                        className="form-check-input fs-6 border border-secondary" 
                        id="permanent" 
                        name="permanent" 
                    />
                    <label className="form-check-label ms-1 pt-1" htmlFor="permanent">Permanent</label>
                </div>
                <div className="form-check form-check-inline d-flex align-items-center gap-2">
                    <input 
                        type="checkbox" 
                        checked={desiredJobTypes.includes('contractual')} 
                        onChange={handleCheckboxChange} 
                        className="form-check-input fs-6 border border-secondary" 
                        id="contractual" 
                        name="contractual" 
                    />
                    <label className="form-check-label ms-1 pt-1" htmlFor="contractual">Contractual</label>
                </div>
            </div>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Desired employment type</Form.Label>
            <div className='radio-wrapper d-flex flex-wrap column-gap-4 gap-lg-5 ms-1'>
                <div className="form-check form-check-inline d-flex align-items-center gap-2">
                    <input 
                        type="checkbox" 
                        checked={desiredEmploymentTypes.includes('fullTime')} 
                        onChange={handleCheckboxChange1} 
                        className="form-check-input fs-6 border border-secondary" 
                        id="fulltime" 
                        name="fullTime" 
                    />
                    <label className="form-check-label ms-1 pt-1" htmlFor="fulltime">Full time</label>
                </div>
                <div className="form-check form-check-inline d-flex align-items-center gap-2">
                    <input 
                        type="checkbox" 
                        checked={desiredEmploymentTypes.includes('partTime')} 
                        onChange={handleCheckboxChange1} 
                        className="form-check-input fs-6 border border-secondary" 
                        id="parttime" 
                        name="partTime" 
                    />
                    <label className="form-check-label ms-1 pt-1" htmlFor="parttime">Part time</label>
                </div>
            </div>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Preferred shift</Form.Label>
            <div className='radio-wrapper d-flex flex-wrap column-gap-3 gap-lg-5 ms-1'>
                <div className="form-check form-check-inline d-flex align-items-center gap-2">
                    <input 
                        type="radio" 
                        value="Day" 
                        checked={preferredShift === 'Day'} 
                        onChange={handleRadioChange} 
                        className="form-check-input fs-6 border border-secondary" 
                        id="day" 
                        name="preferredShift" 
                    />
                    <label className="form-check-label ms-1 pt-1" htmlFor="day">Day</label>
                </div>
                <div className="form-check form-check-inline d-flex align-items-center gap-2">
                    <input 
                        type="radio" 
                        value="Night" 
                        checked={preferredShift === 'Night'} 
                        onChange={handleRadioChange} 
                        className="form-check-input fs-6 border border-secondary" 
                        id="night" 
                        name="preferredShift" 
                    />
                    <label className="form-check-label ms-1 pt-1" htmlFor="night">Night</label>
                </div>
                <div className="form-check form-check-inline d-flex align-items-center gap-2">
                    <input 
                        type="radio" 
                        value="Flexible" 
                        checked={preferredShift === 'Flexible'} 
                        onChange={handleRadioChange} 
                        className="form-check-input fs-6 border border-secondary" 
                        id="flexible" 
                        name="preferredShift" 
                    />
                    <label className="form-check-label ms-1 pt-1" htmlFor="flexible">Flexible</label>
                </div>
            </div>
        </Form.Group>

        <Form.Group className="mb-3 pt-2">
            <Form.Label>Preferred work location (Max 10)</Form.Label>
            <Select 
                isMulti
                name="preferredLocation"
                closeMenuOnSelect={false}
                className='custom-select text-dark'
                classNamePrefix='custom-select'
                components={animatedComponents}
                options={preferredLocation}  // replace colourOptions with your preferredLocation options
                styles={customStyles}
                placeholder="Tell us your location preferences to work"

                value={preferredWorkLocation}
                onChange={handleChange}
            />
        </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Monthly stipend</Form.Label>
                        <div className='d-flex justify-content-between gap-2'>
                            <Form.Select name="currentSalary" className='shadow-none text-center' style={{ maxWidth: '75px' }} value={currency} onChange={(e)=>{set_currency(e.target.value)}}>
                                <option value="INR">₹</option>
                                <option value="Doller">$</option>
                            </Form.Select>
                            <Form.Control type="text" value={expected_salary} onChange={(e)=>{set_expected_salary(e.target.value)}} placeholder="Eg. 4,50,000" maxlength="10" autoComplete='off' />
                        </div>
                    </Form.Group>

                    <div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
                        <Button variant="danger" onClick={closeCareerProfileModal} className='rounded-pill px-4'>Cancel</Button>
                        <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
         <Confirm showDeleteProfile={showDeleteProfile} closeDeleteProfile={closeDeleteProfile} profileId={profileId} id={updateData?.id} message={updateData?.work_title + " Work sample"} setShowCareerProfileModal={setShowCareerProfileModal} setShowDeleteProfile={setShowDeleteProfile} setIsLoading={setIsLoading} setProfileData={setProfileData} type="careerProfile"/>
        </>
       
    )
}

