import React from 'react'
import ChatLabel from './ChatLabel'
import Messages from './Messages'


const TranscriptionSection = () => {
  return (
    <div className='chat_section_container d-flex flex-column justify-content-between h-100'>
        <ChatLabel />
        <Messages />
       
    </div>
  )
}

export default TranscriptionSection