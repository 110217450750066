import React from "react"
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export const NoIntern = ({formData, handleInputChange}) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1 and pad with leading zero
    
    // Create the max value for the input
    const maxMonth = `${currentYear}-${currentMonth}`;
    return(
        <>
            <Form.Group className="mb-3">
                <Form.Label>Previous company name<span className='text-danger'>*</span></Form.Label>
                <Form.Control type="text" name="currCmp" value={formData.currCmp} onChange={handleInputChange} placeholder="Type your orgainization" autoComplete='off' required />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Location<span className='text-danger'>*</span></Form.Label>
                <Form.Select name="location" value={formData.location} onChange={handleInputChange} required>
                    <option selected disabled value=''>Select your location</option>
                    <option value="Bengaluru">Bengaluru</option>
                    <option value="Mumbai">Mumbai</option>
                    <option value="Pune">Pune</option>
                    <option value="Chennai">Chennai</option>
                    <option value="Hyderabad">Hyderabad</option>
                    <option value="Gurugram">Gurugram</option>
                    <option value="Noida">Noida</option>
                    <option value="Ahmedabad">Ahmedabad</option>
                    <option value="Kolkata">Kolkata</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Department<span className='text-danger'>*</span></Form.Label>
                <Form.Select name="department" value={formData.department} onChange={handleInputChange} required>
                    <option selected disabled value=''>Select your department / area of expertise</option>
                    <option value="Bengaluru">Bengaluru</option>
                    <option value="Mumbai">Mumbai</option>
                    <option value="Pune">Pune</option>
                    <option value="Chennai">Chennai</option>
                    <option value="Hyderabad">Hyderabad</option>
                    <option value="Gurugram">Gurugram</option>
                    <option value="Noida">Noida</option>
                    <option value="Ahmedabad">Ahmedabad</option>
                    <option value="Kolkata">Kolkata</option>
                </Form.Select>
            </Form.Group>
            <Form.Group>
                <div className="row g-2">
                    <div className="col-sm-6">
                        <Form.Group className="mb-3">
                            <Form.Label>Working from<span className='text-danger'>*</span></Form.Label>
                            <Form.Control max={maxMonth} type="month" name="workingfrom" value={formData.workingfrom} onChange={handleInputChange} autoComplete='off' required />
                        </Form.Group>
                    </div>
                    <div className="col-sm-6">
                        <Form.Group className="mb-3">
                            <Form.Label>Monthly stipend</Form.Label>
                            <InputGroup>
                                <Form.Select name="salary_type" value={formData.salary_type} onChange={handleInputChange} className='shadow-none' style={{ maxWidth: '65px' }}>
                                    <option value="INR">₹</option>
                                    <option value="USD">$</option>
                                </Form.Select>
                                <Form.Control type="text" name="salary" value={formData.salary} onChange={handleInputChange} placeholder="Eg. 15,000" autoComplete='off' />
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="col-12">
                        <Form.Group className="mb-3">
                            <Form.Label>Worked till<span className='text-danger'>*</span></Form.Label>
                            <Form.Control max={maxMonth} type="month" name="workedtill" value={formData.workedtill} onChange={handleInputChange} autoComplete='off' required />
                        </Form.Group>
                    </div>
                </div>
            </Form.Group>
        </>
    )
}