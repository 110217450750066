import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
import { generateYearOptions, generateYearOptionsFuture10Years, getProfileData } from '../../utils/utils';

export const AllEducation = ({ closeAllEducationModal, showAllEducationModal, empdataload, toasterData, profileId, setProfileData, setShowAllEducationModal, setIsLoading }) => {
    const [showfield, setShowfield] = useState(false)
    const [showUniv, setUniv] = useState(false)
    const [higherEdu, setHigheredu]=useState(true)
    const [belowgrad, setBelowgrad]=useState(false)

    const getUserId = environment.REACT_APP_USER_ID

    const [formData, setFormData] = useState({
        board: "", 
        passing_out_year: "", 
        school_medium: "", 
        english_marks: "", 
        math_marks: "", 
        marks: "",
        university: "",
        course: "",
        specialization: "",
        course_type: "",
        startingYear: "",
        endingYear: "",
        grading_system: "",
        profile_id: getUserId,
        course_duration: "",
        other_course_name: "",
        education: "" // Add the 'education' field here
    });
    

    const handleChange = (event) => {
        const getcoursevalue = event.target.value
        const getcoursetype = event.target.name
        const getValue = event.target.value
        if(getValue === "Scale 10 Grading System" || getValue === "Scale 4 Grading System" || getValue === "% Marks of 100 Maximum"){
            setShowfield(true)
        }
        if(event.target.name === "grading_system" && getValue === "Course Requires a Pass"){
            setShowfield(false)
        }
        if(event.target.name === "course" && getValue === 'Other'){
           setUniv(true)
        }
        if(event.target.name === "course" && getValue !== 'Other'){
            setUniv(false)
        }
        if(getcoursetype == 'education' && (getcoursevalue == '10th' || getcoursevalue == '12th')){
            setBelowgrad(true)
            setHigheredu(false)
        }
        if(getcoursetype == 'education' && (getcoursevalue == 'Doctorate/PhD' || getcoursevalue == 'Masters/Post-Graduation' || getcoursevalue == 'Graduation/Diploma')){
            setBelowgrad(false)
            setHigheredu(true)
        }
        if(getcoursetype == 'education' && getcoursevalue == 'Below 10th'){
            setBelowgrad(false)
            setHigheredu(false)
        }
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const sendData = (e) => {
        setShowfield(false)
        e.preventDefault()
        formData.profile_id = getUserId
        if(formData.education != '10th' && formData.education != '12th' && formData.education != 'Below 10th'){
            formData.course_duration = formData.startingYear+'-'+formData.endingYear
            if(formData.grading_system == "Course Requires a Pass"){
                formData.marks = ""
            }
            if(formData.course !== "Other"){
                formData.other_course_name = ""
            }
        }
        // console.log(formData)
        addEducationData(formData)
        closeAllEducationModal()
    }

    const addEducationData = async(postData) => { 
        // setLoading(true)
        var getpostdata = postData
        if (getpostdata.education === '10th' || getpostdata.education === '12th') {
            getpostdata.university = "";
            getpostdata.course = "";
            getpostdata.specialization = "";
            getpostdata.course_type = "";
            getpostdata.startingYear = "";
            getpostdata.endingYear = "";
            getpostdata.grading_system = "";
            getpostdata.course_duration = "";
            getpostdata.other_course_name = ""; 
        }  
        if(getpostdata.education === 'Below 10th'){
            getpostdata.board = ""; 
            getpostdata.passing_out_year = ""; 
            getpostdata.school_medium = "";  
            getpostdata.english_marks = ""; 
            getpostdata.math_marks = "";  
            getpostdata.marks = ""; 
            getpostdata.university = ""; 
            getpostdata.course = ""; 
            getpostdata.specialization = ""; 
            getpostdata.course_type = ""; 
            getpostdata.startingYear = ""; 
            getpostdata.endingYear = ""; 
            getpostdata.grading_system = ""; 
            getpostdata.course_duration = ""; 
            getpostdata.other_course_name = ""; 
        }    
        var apiname = 'add-education'
        try {
            setIsLoading(true)
            setShowAllEducationModal(false)
			const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/${apiname}`, getpostdata, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const responsedata = response?.data
			if (responsedata?.statusCode === 200 || responsedata?.statusCode === 201 || response?.data) {              
				setProfileData(await getProfileData(profileId,setIsLoading));
                const sendMsg = responsedata?.message
                toast.success(sendMsg, {
					position: 'bottom-center'
				})
				
              
			}
            // setLoading(false)
		} catch (error) {
            setProfileData(await getProfileData(profileId,setIsLoading));
			console.log('Error: ', error)
		}
    }

    useEffect(() => {
        setFormData({})
    }, [showAllEducationModal]);

    return (
        <>
        <Modal show={showAllEducationModal} centered size='lg' onHide={closeAllEducationModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
            <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
                
                <div className="close-wrapper text-end">
                    <Button variant="link" onClick={closeAllEducationModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
                </div>
                <div className="heading-wrapper mb-4">
                    <div className='widgetTitle text-black fs-5 fw-semibold mb-1'>Education <span className='profileComScore fs-14 text-success ms-1'>Add 8%</span></div>
                    <p className='subTitle fs-14'>Details like course, university, and more, help recruiters identify your educational background</p>
                </div>
                <Form action="POST" className="form-cover" onSubmit={sendData}>
                    <Form.Group className="mb-3">
                        <Form.Label>Education<span className='text-danger'>*</span></Form.Label>
                        <Form.Select name="education" value={formData.education} onChange={handleChange} required>
                            <option selected hidden value=''>Select education</option>
                            <option value="Doctorate/PhD">Doctorate/PhD</option>
                            <option value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                            <option value="Graduation/Diploma">Graduation/Diploma</option>
                            <option value="12th">12th</option>
                            <option value="10th">10th</option>
                            <option value="Below 10th">Below 10th</option>
                        </Form.Select>
                    </Form.Group>

                    {/* higher Education */}
                    {higherEdu ? 
                    <>
                    <Form.Group className="mb-3">
                        <Form.Label>University/Institute<span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="search" name="university" value={formData.university} onChange={handleChange} placeholder="Select university/institute" autoComplete='off' required />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Course<span className='text-danger'>*</span></Form.Label>
                        <Form.Select name="course" value={formData.course} onChange={handleChange} required>
                            <option selected hidden value=''>Select course</option>
                            <option value="B.A">B.A</option>
                            <option value="BSC">BSC</option>
                            <option value="BCA">BCA</option>
                            <option value="MCA">MCA</option>
                            <option value="MSC">MSC</option>
                            <option value="M.COM">M.COM</option>
                            <option value="Other">Other</option>
                        </Form.Select>

                        {/* Show if above choosen other */}
                        {showUniv ? <Form.Control type="text" className='mt-2' name="other_course_name" value={formData.other_course_name} onChange={handleChange} placeholder="Enter course" autoComplete='off' /> : ''}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Specialization<span className='text-danger'>*</span></Form.Label>
                        <Form.Select name="specialization" value={formData.specialization} onChange={handleChange} required>
                            <option selected hidden value=''>Select Specialization</option>
                            <option value="Business Analytics">Business Analytics</option>
                            <option value="Information Technology">Information Technology</option>
                            <option value="Finance">Finance</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Economics">Economics</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="mb-2">Course type<span className='text-danger'>*</span></Form.Label>
                        <div className='radio-wrapper'>
                            <Form.Check inline type="radio" className='me-4' id='Fulltime' name="course_type" value="Full Time" checked={formData.course_type === "Full Time"} onChange={handleChange}  label='Full time' required />
                            <Form.Check inline type="radio" className='me-4' id='Parttime' name="course_type" value="Part Time" checked={formData.course_type === "Part Time"} onChange={handleChange} label='Part time' />
                            <Form.Check inline type="radio" className='me-4' id='Correspondence' name="course_type" value="Correspondence/Distance learning" checked={formData.course_type === "Correspondence/Distance learning"} onChange={handleChange} label='Correspondence/Distance learning' />
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Course duration<span className='text-danger'>*</span></Form.Label>
                        <div className="d-flex align-items-center gap-2">
                            <div className="flex-fill">
                                <Form.Select name="startingYear" value={formData.startingYear} onChange={handleChange} required>
                                    <option selected hidden value=''>Starting year</option>
                                    {generateYearOptions().map(year => (
										<option key={year} value={year}>{year}</option>
									))}
                                </Form.Select>
                            </div>
                            <div className='fs-14 fw-semibold'>To</div>
                            <div className="flex-fill">
                                <Form.Select name="endingYear" value={formData.endingYear} onChange={handleChange} required>
                                    <option selected hidden value=''>Ending year</option>
                                    {generateYearOptionsFuture10Years().map(year => (
										<option key={year} value={year}>{year}</option>
									))}
                                </Form.Select>
                            </div>
                        </div>
                    </Form.Group>
                    
                    <Form.Group className="mb-3">
                        <Form.Label>Grading system<span className='text-danger'>*</span></Form.Label>
                        <Form.Select name="grading_system" value={formData.grading_system} onChange={handleChange}>
                            <option selected hidden value=''>Select grading system</option>
                            <option value="Scale 10 Grading System">Scale 10 Grading System</option>
                            <option value="Scale 4 Grading System">Scale 4 Grading System</option>
                            <option value="% Marks of 100 Maximum">% Marks of 100 Maximum</option>
                            <option value="Course Requires a Pass">Course Requires a Pass</option>
                        </Form.Select>
                    </Form.Group>
                    
                    {showfield ? 
                    <Form.Group className="mb-3">
                        <Form.Label>Marks<span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="text" name="marks" value={formData.marks} onChange={handleChange} placeholder="% marks of 100 maximum" maxLength={3} autoComplete='off' required />
                    </Form.Group>: "" }
                    </> : belowgrad ? <>
                            <Form.Group className="mb-3">
                                <Form.Label>Board<span className='text-danger'>*</span></Form.Label>
                                <Form.Select name="board" value={formData.board} onChange={handleChange} required>
                                    <option selected hidden value=''>Select board</option>
                                    <option disabled>-----All India-----</option>
                                    <option value="CISCE(ICSE/ISC)">CISCE(ICSE/ISC)</option>
                                    <option value="Diploma">Diploma</option>
                                    <option value="National Open School">National Open School</option>
                                    <option value="IB(International Baccalaureate)">IB(International Baccalaureate)</option>
                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                    <option value="Assam">Assam</option>
                                    <option value="Bihar">Bihar</option>
                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                    <option value="Goa">Goa</option>
                                    <option value="Gujarat">Gujarat</option>
                                    <option value="Haryana">Haryana</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Passing out year<span className='text-danger'>*</span></Form.Label>
                                <Form.Select name="passing_out_year" value={formData.passing_out_year} onChange={handleChange} required>
                                    <option selected hidden value=''>Select passing out year</option>
                                    <option value="2024">2024</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                                    <option value="2018">2018</option>
                                    <option value="2017">2017</option>
                                    <option value="2016">2016</option>
                                    <option value="2015">2015</option>
                                    <option value="2014">2014</option>
                                    <option value="2013">2013</option>
                                    <option value="2012">2012</option>
                                    <option value="2011">2011</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>School medium<span className='text-danger'>*</span></Form.Label>
                                <Form.Select name="school_medium" value={formData.school_medium} onChange={handleChange}>
                                    <option selected hidden value=''>Select medium</option>
                                    <option value="Assamese / Asomiya">Assamese / Asomiya</option>
                                    <option value="Bengali / Bangla">Bengali / Bangla</option>
                                    <option value="English">English</option>
                                    <option value="Gujarati">Gujarati</option>
                                    <option value="Hindi">Hindi</option>
                                    <option value="Kannada">Kannada</option>
                                    <option value="Kashmiri">Kashmiri</option>
                                    <option value="Konkani">Konkani</option>
                                    <option value="Malayalam">Malayalam</option>
                                    <option value="Manipuri">Manipuri</option>
                                    <option value="Marathi">Marathi</option>
                                    <option value="Oriya">Oriya</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Marks<span className='text-danger'>*</span></Form.Label>
                                <Form.Control type="text" name="marks" value={formData.marks} onChange={handleChange} placeholder="% marks of 100 maximum" maxLength={3} autoComplete='off' required />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>English marks</Form.Label>
                                <Form.Control type="text" name="english_marks" value={formData.english_marks} onChange={handleChange} placeholder="Marks (out of 100)" maxLength={3} autoComplete='off' required />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Maths marks</Form.Label>
                                <Form.Control type="text" name="math_marks" value={formData.math_marks} onChange={handleChange} placeholder="Marks (out of 100)" maxLength={3} autoComplete='off' required />
                            </Form.Group>
                    </> : "" }                  
                    {/* End: IF Choosen Add class XII */}

                    <div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
                        <Button variant="danger" onClick={closeAllEducationModal} className='rounded-pill px-4'>Cancel</Button>
                        <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>        
           
        </>
    )
}
