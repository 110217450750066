import { useEffect, useState } from "react";

const STEP = 3;
const BARS_ARRAY = [0, 1, 2, 3, 4];
const NetworkSignals = ({participant}) =>{

    const [networkQualityLevel, setNetworkQualityLevel] = useState(participant.networkQualityLevel);

    useEffect(() => {
        const handleNewtorkQualityLevelChange = (newNetworkQualityLevel) =>
          setNetworkQualityLevel(newNetworkQualityLevel);
    
        setNetworkQualityLevel(participant.networkQualityLevel);
        participant.on('networkQualityLevelChanged', handleNewtorkQualityLevelChange);
        return () => {
          participant.off('networkQualityLevelChanged', handleNewtorkQualityLevelChange);
        };
      }, [participant]);
    //   console.log("from networkSignals.js:::", networkQualityLevel)
    return (
        <div className="network-signals d-flex align-items-end gap-1">
        {BARS_ARRAY.map((level) => (
          <div
            className="signal"
            key={level}
            style={{
              height: `${STEP * (level + 1)}px`,
              background:
                networkQualityLevel > level
                  ? "white"
                  : "rgba(255, 255, 255, 0.2)",
            }}
          />
        ))}
      </div>
    )
}

export default NetworkSignals;