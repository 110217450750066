
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { callAxios, getProfileData } from '../../utils/utils';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
import { Confirm } from './Confirm';

export const OnlineProfile = ({ showOnlineProfileModal, closeOnlineProfileModal, setProfileData, profileId, setShowOnlineProfileModal, isUpdateAction, updateData, setIsLoading }) => {
	// console.log(isUpdateAction, updateData)
	const [socialProfileName, setSocialProfileName] = useState("");
	const [socialUrl, setSocialUrl] = useState("");
	const [socialDescription, setSocialDescription] = useState("");
	

	useEffect(() => {
		if (isUpdateAction) {
			setSocialProfileName(updateData?.social_profile);
			setSocialUrl(updateData?.url);
			setSocialDescription(updateData?.description)
		} else {
			setSocialProfileName("");
			setSocialUrl("");
			setSocialDescription("")
		}
	}, [updateData?.social_profile]);

	// console.log(socialProfileName)

	const saveSocialProfiles = async (e) => {
		e.preventDefault();
		setShowOnlineProfileModal(false);
		setIsLoading(true)
		if (isUpdateAction) {
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-profiles", { profileId: profileId, socialProfile: socialProfileName, url: socialUrl, description: socialDescription, type: "update", id: updateData?.id }, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
					setProfileData(await getProfileData(profileId,setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
					
				} else {
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		} else {
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/add-online-profiles", { profileId: profileId, socialProfile: socialProfileName, url: socialUrl, description: socialDescription }, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
					setProfileData(await getProfileData(profileId,setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
				} else {
					setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})

				}

			})
		}

	}
	

	// Show Delete Profile Modal
	const [showDeleteProfile, setShowDeleteProfile] = useState(false);
	const closeDeleteProfile = () => setShowDeleteProfile(false);
	const openDeleteProfile = () => setShowDeleteProfile(true);

	return (
		<>
			<Modal show={showOnlineProfileModal} centered size='lg' onHide={closeOnlineProfileModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
				<Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
					<div className="close-wrapper text-end">
						<Button variant="link" onClick={closeOnlineProfileModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
					</div>
					<div className="heading-wrapper mb-4">
						<div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
							<span className='widgetTitle text-black fs-5 fw-semibold'>Online profiles</span>
							{/* This button will show only when you click to edit the details */}
							{isUpdateAction?
							<span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0 pointer' onClick={openDeleteProfile}>Delete</span>
							:null}
						</div>
						<p className='subTitle fs-14'>Add link to online professional profiles (e.g. LinkedIn, etc.)</p>
					</div>


					<Form action="" className="form-cover" onSubmit={saveSocialProfiles}>
						<Form.Group className="mb-3">
							<Form.Label>Social profile<span className='text-danger'>*</span></Form.Label>
							<Form.Control type="text" value={socialProfileName} placeholder="Enter Social Profile Name" autoComplete='off' onChange={(e) => { setSocialProfileName(e.target.value) }} required />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>URL<span className='text-danger'>*</span></Form.Label>
							<Form.Control type="url" value={socialUrl} placeholder="Enter Social Profile URL" autoComplete='off' onChange={(e) => { setSocialUrl(e.target.value) }} required />
						</Form.Group>

						<Form.Group>
							<Form.Label>Description</Form.Label>
							<Form.Control as="textarea" value={socialDescription} rows={5} placeholder='Type here...' onChange={(e) => { setSocialDescription(e.target.value) }} maxLength={500} autoComplete='off' />
							<div className="charLenght fs-14 text-clr2 text-end mt-2">500 character(s) left</div>
						</Form.Group>
						
								

						<div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
						
							<Button variant="danger" onClick={closeOnlineProfileModal} className='rounded-pill px-4'>Cancel</Button>
							<Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
						</div>
						
					</Form>
				</Modal.Body>
			</Modal>

			<Confirm showDeleteProfile={showDeleteProfile} closeDeleteProfile={closeDeleteProfile} profileId={profileId} id={updateData?.id} message={socialProfileName + " profile"} setShowOnlineProfileModal={setShowOnlineProfileModal} setShowDeleteProfile={setShowDeleteProfile} setIsLoading={setIsLoading} setProfileData={setProfileData} type="onlineProfiles"/>
		</>



	)
}
