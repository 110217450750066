import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { callAxios, getProfileData } from '../../utils/utils';
import { environment } from '../../environment';
import { Confirm } from './Confirm';

export const Publication = ({ showPublicationModal, closePublicationModal, setProfileData, profileId, setShowPublicationModal, isUpdateAction, updateData, profileData, setIsLoading }) => {
// console.log(isUpdateAction,updateData)
  const [title, setTitle] = useState("");
	const [url, setUrl] = useState("");
  const [publishedYear, setPublishedYear] = useState("");
  const [publishedMonth, setPublishedMonth] = useState("");
	const [description, setDescription] = useState("");

	useEffect(() => {
		if (isUpdateAction) {
      // console.log("in condition")
      const publishedOn = updateData?.published_on;
			setTitle(updateData?.title);
			setUrl(updateData?.url);
			setPublishedYear(publishedOn.split(" ")[1])
      setPublishedMonth(publishedOn.split(" ")[0]);
      setDescription(updateData?.description)
		} else {
			setTitle("");
			setUrl("");
			setPublishedYear("")
      setPublishedMonth("");
      setDescription("")
		}
	}, [updateData]);

  const handlePublicationSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true)
    setShowPublicationModal(false);
		// console.log(socialProfileName, socialUrl, socialDescription)
		if (isUpdateAction) {
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-journal-entry", { profile_id: profileId, title: title, url: url, published_on: publishedMonth +" "+publishedYear, description: description, type: "update", id: updateData?.id }, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
					setProfileData(await getProfileData(profileId,setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
					
				} else {
          setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		} else {
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/add-journal-entry", { profile_id: profileId, title: title, url: url, published_on: publishedMonth +" "+publishedYear, description: description }, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
				setProfileData(await getProfileData(profileId,setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
					
				} else {
          setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		}

	}
	

	// Show Delete Profile Modal
	const [showDeleteProfile, setShowDeleteProfile] = useState(false);
	const closeDeleteProfile = () => setShowDeleteProfile(false);
	const openDeleteProfile = () => setShowDeleteProfile(true);

  return (
    <>
    <Modal show={showPublicationModal} centered size='lg' onHide={closePublicationModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
      <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
        <div className="close-wrapper text-end">
          <Button variant="link" onClick={closePublicationModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
        </div>

        <div className="heading-wrapper mb-4">
          <div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
            <span className='widgetTitle text-black fs-5 fw-semibold'>White paper / Research publication / Journal entry</span>
            {/* This button will show only when you click to edit the details */}
            {isUpdateAction?
							<span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0 pointer' onClick={openDeleteProfile}>Delete</span>
							:null}
          </div>
          <p className='subTitle fs-14'>Add links to your online publications</p>
        </div>

        <Form action="" className="form-cover" onSubmit={handlePublicationSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title<span className='text-danger'>*</span></Form.Label>
            <Form.Control type="text" value={title} placeholder="Enter title" autoComplete='off' onChange={(e) => { setTitle(e.target.value) }} required />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>URL<span className='text-danger'>*</span></Form.Label>
            <Form.Control type="url" value={url} placeholder="Enter URL here" autoComplete='off' onChange={(e) => { setUrl(e.target.value) }} required />
          </Form.Group>

          <Form.Group>
            <Form.Label>Published on</Form.Label>
            <div className="row gx-3">
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Select name="publishedOn" value={publishedYear} onChange={(e) => { setPublishedYear(e.target.value) }}>
                    <option selected hidden value=''>Select year</option>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                    <option value="2018">2018</option>
                    <option value="2017">2017</option>
                    <option value="2016">2016</option>
                    <option value="2015">2015</option>
                    <option value="2014">2014</option>
                    <option value="2013">2013</option>
                    <option value="2012">2012</option>
                    <option value="2011">2011</option>
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Select name="publishedOnMonth" value={publishedMonth} onChange={(e) => { setPublishedMonth(e.target.value) }}>
                    <option selected hidden value=''>Select month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" value={description} rows={5} placeholder='Type here...' maxLength={500} autoComplete='off' onChange={(e) => { setDescription(e.target.value) }} />
            <div className="charLenght fs-14 text-clr2 text-end mt-2">500 character(s) left</div>
          </Form.Group>
          
          <div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
            <Button variant="danger" onClick={closePublicationModal} className='rounded-pill px-4'>Cancel</Button>
            <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>

    <Confirm showDeleteProfile={showDeleteProfile} closeDeleteProfile={closeDeleteProfile} profileId={profileId} id={updateData?.id} message={updateData?.title} setShowPublicationModal={setShowPublicationModal} setShowDeleteProfile={setShowDeleteProfile} setIsLoading={setIsLoading} setProfileData={setProfileData} type="journalEntry"/>
    </>
    
  )
}

