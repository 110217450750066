import { Accordion, Button, NavLink } from 'react-bootstrap'
import { getObjectByConversationId, getShortName, handelReplyToEmail, removeEmailAddresses, showatachedFile } from '../utils/emailUtils'
import { useEffect, useMemo, useState } from 'react';
import $ from "jquery";
import { ReplyEmail } from './ReplyEmail';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
// Custom Accordion
function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <div className="d-flex flex-wrap flex-sm-nowrap gap-2" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

export const ThreadEmail = ({ showCurrentEmail, currentEmail, currentEmailBody, attachments, handleReplyShow, showReply, emailsById, setShowReply }) => {
    let multipleEmails;
    //  console.log(emailsById)
    const [clickedReply, setClickedReply] = useState({})
    const [getConversationIndex, setConversationIndex] = useState('')
    const [emailContent, setEmailContent] = useState(false)
    const toggleEmailContent = () =>{
        setEmailContent(!emailContent)
    }
    useMemo(() => {
        setClickedReply(emailsById[getObjectByConversationId(emailsById, getConversationIndex)])
    }, [getConversationIndex])
    if (emailsById && emailsById.length > 0) {
        multipleEmails = emailsById.map((currentEmail, index) => {
            return (
                // {currentEmail?.message?.subject.replace('Re: ', '')}
                
                <div className="info-card rounded-3 p-3">
                                            <CustomToggle eventKey={`${index}`}>
                                                <div className="user-img bg-warning bg-opacity-75 rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '40px', width: '40px', minWidth: '40px' }}>
                                                    {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" /> */}
                                                    <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">{getShortName(currentEmail?.message?.from?.emailAddress?.name)}</div>
                                                </div>
                                                <div className="user-email-info w-100">
                                                    <div className="row g-2">
                                                        <div className="col-xl-8">
                                                            <div className="user-name fs-6 fw-medium text-white text-truncate text-capitalize mb-2">{currentEmail?.message?.from?.emailAddress?.name}</div>
                                                            <div className="cc-user fs-14 d-flex gap-2 mb-1">
                                                                <div>To:</div>
                                                                <div className="cc-user-wrapper fs-14 text-white text-opacity-75 d-flex flex-wrap row-gap-1 column-gap-2">
                                                                {currentEmail?.message?.toRecipients.map((recipient, index) => (
                                                <span className="cc-name">{recipient?.emailAddress?.name} {index < (currentEmail?.message?.toRecipients.length - 1) ? ', ' : ''}</span>
                                            ))}
                                                                </div>
                                                            </div>
                                                            <div className="cc-user fs-14 d-flex gap-2">
                                        {currentEmail?.message?.ccRecipients.length ?
                                            <>
                                                <div>Cc:</div>
                                                <div className="cc-user-wrapper fs-14 text-white text-opacity-75 d-flex flex-wrap row-gap-1 column-gap-2">
                                                    <span className="cc-name">{currentEmail?.message?.ccRecipients && currentEmail?.message?.ccRecipients.map((recipient, index) => (
                                                        <span class="job-department mr-2"> {recipient?.emailAddress?.name + ', '}  </span>
                                                    ))}
                                                    </span>
                                                </div>
                                            </>
                                            : <></>
                                        }
                                    </div>
                                                        </div>
                                                        <div className="col-xl-4">
                                                        <div className="text-white fs-14 fw-medium d-flex flex-wrap justify-content-xl-end gap-1 mb-2">
                                        <span className="name text-uppercase">{currentEmail?.message?.mailDate}</span>

                                    </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CustomToggle>
                                            <Accordion.Collapse eventKey={`${index}`}>
                                                <div className="user-details mt-4 ms-sm-5">
                                                    <div className="description-wrapper fs-14 text-light text-opacity-75 mb-3" dangerouslySetInnerHTML={{ __html: removeEmailAddresses(currentEmail?.message?.body?.content) }} />
                                                    

                                                    <div className="files-wrapper d-flex flex-wrap gap-2 mb-2">
                            {currentEmail?.attachments?.map((attachment, index) => (
                                <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3">

                                    <span className="text-truncate">{showatachedFile(attachment.contentBytes, attachment.contentType, attachment.name)}</span>


                                </div>
                            ))}
                        </div>
                        <div className='dot-wrapper mb-3'>
                            {/* <span className='bg-secondary bg-opacity-25 border rounded p-1' onClick={toggleEmailContent}>
                                <i className='bi bi-three-dots'></i>
                            </span> */}
                            {emailContent && 
                            <div className='mail-description' id="targetDiv">
                                hello test
                            </div>
                            }
                        </div>
                        <div className="reply-wrapper d-flex flex-wrap gap-2">
                            <Button variant="outline-light" size="sm" className="border-secondary rounded-3 px-3" onClick={() => { setConversationIndex(currentEmail?.message?.conversationIndex);  handleReplyShow(); }}><i className="bi bi-reply"></i> Reply</Button>
                            <Button variant="outline-light" size="sm" className="border-secondary rounded-3 px-3" onClick={() => {  setConversationIndex(currentEmail?.message?.conversationIndex); handleReplyShow(); }}><i className="bi bi-reply-all"></i> Reply All</Button>
                            {/* <Button variant="outline-light" size="sm" className="border-secondary rounded-3 px-3"><i className="bi bi-reply d-inline-block" style={{ transform: 'scaleX(-1)' }}></i> Forword</Button> */}
                        </div>
                                                </div>

                                            </Accordion.Collapse>
                                        </div>
                                       
            )
        })
    }
    $(document).ready(function () {
        // $('.gmail_quote').addClass("collapse")
        // var $button = $('<button class="my-button">Click Me</button>');

        // Add the button as the first child of each .info-card
        $('.info-card').each(function () {
            // Check if the .info-card already has a button with class "my-button"
            if ($(this).find('.my-button').length === 0) {
                // Find the <div dir="auto"> within the current .info-card and insert the button after it
                $(this).find('div[dir="auto"]').each(function () {
                    // Insert the button after the <div dir="auto">
                    //$(this).first().after($button.clone());
                });
            }
        });


        // Toggle visibility of all .gmail_quote divs




        // $(document).on('click','.my-button',function(){
        //     console.log("clicked")

        //         // Toggle visibility of all .gmail_quote divs
        //         $('.gmail_quote').each(function() {
        //             if ($(this).css('display') === 'none') {
        //                 console.log("in 1")
        //                 $(this).css('display', 'block'); // Show
        //             } else {
        //                 $(this).css('display', 'none'); // Hide
        //                 console.log("in 2")
        //             }
        //         });

        //         // Change button text based on current visibility state
        //         var isVisible = $('.gmail_quote').first().css('display') === 'block';
        //         $(this).text(isVisible ? 'Show All' : 'Hide All');
        //     });
        // $('.gmail_quote').css("display","block")

    });
    // console.log(clickedReply)
    if (showReply) {
        const div = document.getElementById('userDetailsSectionw100');

        // Scroll the div to the top
        div.scrollTop = 0;
    }

    //  $(document).ready(function() {
    //         // Move the entire divRplyFwdMsg and div[dir="ltr"] into targetDiv
    //         $('#divRplyFwdMsg').appendTo('#targetDiv');
    //         $('div[dir="ltr"]').appendTo('#targetDiv');
    //     });
    $(document).ready(function() {
        // Iterate over each .info-card element
        $(".info-card").each(function() {
            // Find the <hr> element with ID 'Signature' within the current .info-card
            var hrElement = $(this).find('#Signature');
            var rpFw = $(this).find("#divRplyFwdMsg")
            // Remove all content after the <hr> element
            hrElement.nextAll().remove();
            rpFw.remove();
        });
    });
    return (
        <>
            {/* <div className="user-details-section w-100 d-flex flex-column gap-2" id='userDetailsSectionw100'> */}
            <Accordion defaultActiveKey="0" alwaysOpen className="user-details-section w-100 d-flex flex-column gap-2" id='userDetailsSectionw100'>
            {showCurrentEmail?
            <div className="info-card rounded-3 p-3">

                    <div className="user-name fs-6 fw-medium text-white text-truncate text-capitalize mb-2">{emailsById[0]?.message?.subject.replace('Re: ', '')}</div>
                </div>
                :null}
                {showReply ? <ReplyEmail currentEmail={clickedReply} setShowReply={setShowReply} showReply={showReply}/> : null}
                

                {showCurrentEmail ?
                    multipleEmails
                  
                    :
                    null
                }
             </Accordion>
        </>
    )
}