import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class PstnCamCharts extends Component {

  componentDidMount() {
   
     this.initChart()
  }
  componentDidUpdate(prevProps) {
    
   
    this.initChart();
    
  }
  

  initChart() {
    am4core.useTheme(am4themes_animated);
    var chart = am4core.create("pstn-call-Cam-chart", am4charts.XYChart);

    chart.data = this.props.chartData;

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "caller_name_with_total";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.labels.template.rotation = 270;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = "Total Calls";

    // Concatenate caller_name with total number
    this.props.chartData.forEach(function(item) {
        item.caller_name_with_total = item.caller_name + " (" + item.totalCalls + ")";
    });

    // Create series
    function createSeries(field, name, stacked, color) {
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "caller_name_with_total";
        series.name = name;
        series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
        series.stacked = stacked;
        series.columns.template.width = am4core.percent(95);
        series.columns.template.fill = am4core.color(color);
        series.columns.template.stroke = am4core.color("none");

       
    }

    createSeries("connected", "Connected",true, "green");
    createSeries("failed_count", "Failed",true, "red");
    createSeries("busy_count", "Busy",true, "yellow");
    createSeries("no_answer_count", "No Answer",true, "blue");
    createSeries("canceled_count", "Canceled",true, "orange");

    // Add legend
    chart.legend = new am4charts.Legend();

  //   chart.events.on("datavalidated", function () {
  //     chart.series.each(function (series) {
  //         series.columns.each(function (column) {
  //             column.showTooltipOn = "always";
  //             column.tooltip.show();
  //         });
  //     });
  // });
}







    

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  
  render() {
    return <div id="pstn-call-Cam-chart" style={{ width: "100%", height: "500px", margin: "0 auto" }} />;
  }

 
}

export default PstnCamCharts;
