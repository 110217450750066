import React, { useState } from "react"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import WarningModal from '../Components/Warningmodal';

export const EditYesIntern = ({ closeEditEmploymentModal, sendmydata, getskills, updateEmp, isCurrentEmployement, profileId }) => {
    // const getUserId = process.env.REACT_APP_USER_ID
    const [showModal, setShowModal] = useState(false);
    const [textmessage, setTextmessage] = useState('');
    const [deleteEmp, setDeleteEmp] = useState('')

    // convert year and monthname
    const workingfromfnc = (data) => {
        const dateStr = data;
        const date = new Date(dateStr);
        const year = date.getFullYear(); // Get the year
        const month = date.getMonth() + 1; // Get the month (Note: January is 0, so we add 1)
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}`; // Pad the month with leading zero if necessary
        return formattedDate
    }

    const [formData, setFormData] = useState({
        currCmp:sendmydata?.company_name ? sendmydata?.company_name : '',
        location:sendmydata?.location ? sendmydata?.location : '',
        department: sendmydata?.department ? sendmydata?.department : '',
        workingfrom: sendmydata?.working_from ? workingfromfnc(sendmydata?.working_from) : '',
        salary_type:sendmydata?.salaryType ? sendmydata?.salaryType : '',
        salary:sendmydata?.salary ? sendmydata?.salary : '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // convert year and monthname
    const yearmonthname = (data, type) => {
        const dateString = data;
  
        // Create a Date object with the input string
        const date = new Date(dateString);
      
        // Get the year
        if(type == 'year'){
            const year = date.getFullYear();
            return year
        }
        
        // Get the month name
        if(type == 'month'){
            const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
            return month
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your form submission logic here
        const postData = {
            "profileId":profileId ? profileId : '',
            "employementId":sendmydata?.id, 
            "type":"update",
            "isCurrentEmployement": isCurrentEmployement,
            "companyName":formData?.currCmp, 
            "location":formData?.location,
            "department":formData?.department,
            "workingFromYear":yearmonthname(formData?.workingfrom, 'year'),
            "workingFromMonth":yearmonthname(formData?.workingfrom, 'month'),
            "salary":formData?.salary,
            "salaryType":formData?.salary_type,
        }
        updateEmp(postData)
    }

    // deleteemployment
    const deleteemployment = () => {
        setShowModal(true)
        setTextmessage('Are you sure you want to delete this employment?')
        const sendData = {
            "profileId":profileId ? profileId : '',
            "employementId":sendmydata?.id, 
            "type":"delete"
        }
        setDeleteEmp(sendData)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }
    // deleteemployment

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1 and pad with leading zero
    
    // Create the max value for the input
    const maxMonth = `${currentYear}-${currentMonth}`;
    return(
        <>
           
            <Form action="" className="form-cover" onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Current company name<span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="text" name="currCmp" value={formData.currCmp} onChange={handleInputChange} placeholder="Type your orgainization" autoComplete='off' required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Location<span className='text-danger'>*</span></Form.Label>
                    <Form.Select name="location" value={formData.location} onChange={handleInputChange} required>
                        <option selected disabled value=''>Select your location</option>
                        <option value="Bengaluru">Bengaluru</option>
                        <option value="Mumbai">Mumbai</option>
                        <option value="Pune">Pune</option>
                        <option value="Chennai">Chennai</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Gurugram">Gurugram</option>
                        <option value="Noida">Noida</option>
                        <option value="Ahmedabad">Ahmedabad</option>
                        <option value="Kolkata">Kolkata</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Department<span className='text-danger'>*</span></Form.Label>
                    <Form.Select name="department" value={formData.department} onChange={handleInputChange} required>
                        <option selected disabled value=''>Select your department / area of expertise</option>
                        <option value="Bengaluru">Bengaluru</option>
                        <option value="Mumbai">Mumbai</option>
                        <option value="Pune">Pune</option>
                        <option value="Chennai">Chennai</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Gurugram">Gurugram</option>
                        <option value="Noida">Noida</option>
                        <option value="Ahmedabad">Ahmedabad</option>
                        <option value="Kolkata">Kolkata</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <div className="row g-2">
                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Working from<span className='text-danger'>*</span></Form.Label>
                                <Form.Control max={maxMonth} type="month" name="workingfrom" value={formData.workingfrom} onChange={handleInputChange} autoComplete='off' required />
                            </Form.Group>
                        </div>
                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Monthly stipend</Form.Label>
                                <InputGroup>
                                    <Form.Select name="salary_type" value={formData.salary_type} onChange={handleInputChange} className='shadow-none' style={{ maxWidth: '65px' }}>
                                        <option value="INR">₹</option>
                                        <option value="USD">$</option>
                                    </Form.Select>
                                    <Form.Control type="text" name="salary" value={formData.salary} onChange={handleInputChange} placeholder="Eg. 15,000" autoComplete='off' />
                                </InputGroup>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
                        <Button variant="danger" onClick={closeEditEmploymentModal} className='rounded-pill px-4'>Cancel</Button>
                        <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
                    </div>
                </Form.Group>
            </Form>
        </>
    )
}