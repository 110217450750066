import React, { useState, useEffect } from 'react';

function Timer() {
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const timer = setInterval(() => {
      // Update seconds
      setTime(prevTime => {
        const newSeconds = prevTime.seconds + 1;
        if (newSeconds === 60) {
          // Increment minutes when seconds reach 60
          return { ...prevTime, seconds: 0, minutes: prevTime.minutes + 1 };
        } else {
          return { ...prevTime, seconds: newSeconds };
        }
      });
    }, 1000); // Update every second

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []); // Run effect only once when component mounts

  return `${time.hours.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`
}

export default Timer;
