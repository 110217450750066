import React, { useRef } from "react";
import Header from "../Header/Header";
import Sidebar from "../Header/Sidebar";
import { useState } from "react";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import Accordion from 'react-bootstrap/Accordion';

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import preloader css
import "../LeadsListingPage/LeadsListingPage.css";
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";

import ReactQuill, { quill } from 'react-quill';
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";



const IssuesListing = ({ setLoggedinUser }) => {
  const [userRole, setUserRole] = useState({});
  const [subject, setSubject] = useState("");
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [issues, setIssues] = useState([]);
  const [issueCounts, setIssuesCount] = useState([]);
  const [status, setStatus] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [isLoading, setIsloading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {

      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };

      setLoggedinUser(userInfo);

    }
  }, [user, loading]);


  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      // ["link", "image", "video"],
      ["clean"],
    ],
  };
  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      const getUserRole = async () => {
        if (user) {
          const q = query(
            collection(database, environment.REACT_APP_USERS_DB),
            where("email", "==", user?.email),
            orderBy("timeStamp", "asc"),
            limit(1)
          );
          onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const id = doc.id;
              const data = doc.data();
              data["id"] = doc.id;
              setUserRole(data);
              
            });
          });
        }
      };
      getUserRole();

      const getIssues = async () => {
        await callAxios(
          environment.REACT_APP_API_URL_Live + "api/v1/issues",
          {},
          "get"
        ).then((issues) => {
          setIssues(issues);
        });

        await callAxios(
          environment.REACT_APP_API_URL_Live + "api/v1/issues/get-issue-dashboard",
          {},
          "get"
        ).then((counts) => {
          setIssuesCount(counts.data);
          setIsloading(false);
        });
      };
      getIssues();
    }
  }, [user, loading]);

  const formatAMPM = (date) => {
    //console.log("LOCAL TIME:",date)
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const checkValidation = (key, val) => {
    if (key === "subject" && val !== "") {
      document.getElementById("subject").style.border = "none";
    }
    if (key === "priority" && val !== "") {
      document.getElementById("priority").style.border = "none";
    }
    if (key === "description" && val !== "") {
      document.getElementById("issue-description").style.border = "none";
    }

    if (key === "changeStatus" && val !== "") {
      document.getElementById("changeStatus").style.border = "none";
    }
  };

  if (description) {
    document.getElementById("issue-description").style.border = "none";
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (subject === "") {
      document.getElementById("subject").style.border = "1px solid red";
      return false;
    } else if (priority === "") {
      document.getElementById("priority").style.border = "1px solid red";
      return false;
    } else if (description === "") {
      document.getElementById("issue-description").style.border =
        "1px solid red";
      return false;
    } else {
      document.getElementById("issues-preloader").classList.remove("d-none");
      const formData = {
        uid: user?.uid,
        subject: subject,
        priority: priority,
        status: "open",
        description: description,
        name: user?.displayName,
        email: user?.email,
        userType: userRole?.userType,
      };
      // console.log(formData)

      const response = await callAxios(
        environment.REACT_APP_API_URL_Live + "api/v1/issues",
        formData,
        "post"
      );

      if (response?.data?.status === 200) {
        resetForm();
        toast.success("Ticket Submitted!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        document.getElementById("issues-preloader").classList.add("d-none");
        toast.error("Network Issue!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const resetForm = async () => {
    //console.log("In reset form");
    setSubject("");
    setPriority("");
    setDescription("");
    const element = document.getElementsByClassName("ql-editor");
    element[0].innerHTML = "";
    await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v1/issues",
      {},
      "get"
    ).then((issues) => {
      setIssues(issues);
    });
    document.getElementById("issueForm").reset();

    document.getElementById("closeIssueForm").click();
    document.getElementById("issues-preloader").classList.add("d-none");
  };

  const ReplyListingPage = (issueId, subject) => {
    navigate("/replies-list", {
      state: { issueId: issueId, subject: subject },
    });
  };

  const updateStatus = async (status, id) => {
    //console.log(ee)
    const formData = {
      status: status,
      id: id,
    };
    //console.log(formData)

    const response = await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v1/issues/update-status",
      formData,
      "post"
    );
    if (response?.data?.status === 200) {
      resetForm();
      toast.success("Status updated!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      await callAxios(
        environment.REACT_APP_API_URL_Live + "api/v1/issues/get-issue-dashboard",
        {},
        "get"
      ).then((counts) => {
        setIssuesCount(counts.data);
        setIsloading(false);
      });
    } else {
      //document.getElementById("replies-preloader").classList.add('d-none');
      toast.error("Network Issue!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  let htmlContent;

  if (issues?.data?.length > 0) {
    htmlContent = issues?.data?.map((item, index) => {
      // const isSelected = item.status === "" ?"selected" : null;
      const raisedBy = item.name
        ? "- By: " + item.name + " [ " + item.userType + " ]"
        : null;

      return (
        <div className="list-group-item border-secondary py-3 lh-sm" key={index}>
          <div className="d-flex flex-wrap flex-md-nowrap gap-2 w-100 align-items-start justify-content-between mb-2">
            <div className="fw-medium text-capitalize" style={{ color: "var(--theme-blue1)" }}>
              {item?.subject}
              {userRole?.userType === "SA" || userRole?.userType === "SSA" ? (
                item.userType === "BDM" || item.userType === "CAM" ? (
                  <span className="text-warning ms-1">{raisedBy}</span>
                ) : (
                  <span className="text-warning ms-1">
                    By Admin
                  </span>
                )
              ) : item.userType == "SA" || item.userType == "SSA" ? (
                <span className="text-warning ms-1">
                  By Admin
                </span>
              ) : (
                <span className="text-warning ms-1">
                  By User
                </span>
              )}
            </div>

            {item?.priority === "normal" ? (
              <div className="issue-priority">
                <span className="badge rounded-pill text-bg-secondary text-capitalize" style={{ minWidth: "60px" }}>{item?.priority}</span>
              </div>
            ) : null}

            {item?.priority === "medium" ? (
              <div className="issue-priority">
                <span className="badge rounded-pill text-bg-warning text-capitalize" style={{ minWidth: "60px" }}>
                  {item?.priority}
                </span>
              </div>
            ) : null}

            {item?.priority === "high" ? (
              <div className="issue-priority">
                <span
                  className="badge rounded-pill text-bg-danger text-capitalize"
                  style={{ minWidth: "60px" }}
                >
                  {item?.priority}
                </span>
              </div>
            ) : null}
          </div>
          <div className="col-md-10 mb-2 small item-description" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
          <div className="issue-date fw-medium small text-warning text-opacity-75 mb-3">
            {new Date(item.createdAt).getDate() +
              " " +
              new Date(item.createdAt).toLocaleString("default", {
                month: "long",
              }) +
              " " +
              new Date(item.createdAt).getFullYear() +
              " " +
              formatAMPM(new Date(item.createdAt))}
          </div>
          <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-2">
            {item?.status === "open" ? (
              <div className="issue-status d-flex flex-column flex-md-row gap-2">
                <button
                  className="btn btn-warning py-1 text-capitalize"
                  style={{ minWidth: "100px" }}
                >
                  {item?.status}
                </button>
                <button
                  className="btn btn-primary py-1 text-capitalize"
                  onClick={() => {
                    ReplyListingPage(item.id, item.subject);
                  }}
                  style={{ minWidth: "100px" }}
                >
                  View Updates
                </button>
              </div>
            ) : item?.status === "In Progress" ? (
              <div className="issue-status d-flex flex-column flex-md-row gap-2">
                <button
                  className="btn btn-info py-1 text-capitalize"
                  style={{ minWidth: "100px" }}
                >
                  {item?.status}
                </button>
                <button
                  className="btn btn-primary py-1 text-capitalize"
                  onClick={() => {
                    ReplyListingPage(item.id, item.subject);
                  }}
                  style={{ minWidth: "100px" }}
                >
                  View Updates
                </button>
              </div>
            ) : item?.status === "In Discussion" ? (
              <div className="issue-status d-flex flex-column flex-md-row gap-2">
                <button
                  className="btn btn-secondary py-1 text-capitalize"
                  style={{ minWidth: "100px" }}
                >
                  {item?.status}
                </button>
                <button
                  className="btn btn-primary py-1 text-capitalize"
                  onClick={() => {
                    ReplyListingPage(item.id, item.subject);
                  }}
                  style={{ minWidth: "100px" }}
                >
                  View Updates
                </button>
              </div>
            ) : (
              <div className="issue-status d-flex flex-column flex-md-row gap-2">
                <button
                  className="btn btn-success py-1 text-capitalize"
                  style={{ minWidth: "100px" }}
                >
                  {item?.status}
                </button>
                <button
                  className="btn btn-primary py-1 text-capitalize"
                  onClick={() => {
                    ReplyListingPage(item.id, item.subject);
                  }}
                  style={{ minWidth: "100px" }}
                >
                  View Updates
                </button>
              </div>
            )}
            {userRole.userType === "SSA" ? (
              <div className="issue-status d-flex flex-column flex-md-row gap-2">
                <select
                  value={item.status}
                  id="changeStatus"
                  className="form-control"
                  onChange={(e) => {
                    updateStatus(e.target.value, item.id);
                  }}
                >
                  <option value="open">Open</option>
                  <option value="In Progress">In Progress</option>
                  <option value="In Discussion">In Discussion</option>
                  <option value="closed">Closed</option>
                </select>
              </div>
            ) : null}

            <div className="total-update-count fs-6 fw-medium">
              Total Updates:
              <span className="text-warning ms-1"> {item.totalUpdates}</span>
            </div>
          </div>
          {item.lastUpdate ? (
            <div className="chat-replies-sec bg-secondary bg-opacity-25 border border-secondary rounded-2 p-3 mt-3 ">
              {" "}
              <div className="label-text text-info mb-2">Latest Update</div>
              <div className="reply-description small" dangerouslySetInnerHTML={{ __html: item?.lastUpdate }}></div>
            </div>
          ) : null}
        </div>
      );
    });
  } else {
    htmlContent = (
      <div className="border-top pt-3 text-warning border-secondary">
        No issues found yet!
      </div>
    );
  }
// console.log(issueCounts)
  return (
    <>
      {/* <ToastContainer /> */}
      <section className="py-3">
        <div className="container">
          {/* Main Info Box Section */}
          <div className="text-end mb-2">
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Raise your issue <i className="bi bi-plus-circle"></i>
            </button>
          </div>
         {/* Main Info Box Section */}
         {!isLoading?
         <div className="info-card-sec mb-3">
            <div className="row row-cols-sm-2 row-cols-md-3 row-cols-xl-5 g-2 g-md-3">
              <div className="box-cover">
                <div className="bg-primary bg-opacity-50 text-white border-bottom border-4 border-primary rounded-3 pointer h-100 p-3">
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div className="card-details">
                      <div className="label-value fs-3 fw-bold lh-1 mb-1">{issueCounts.totalIssues}</div>
                      <div className="label-title small text-light text-opacity-75 lh-sm">Total Issues</div>
                    </div>
                    <div className="card-iconn text-primary lh-1">
                      <i className="bi bi-bug-fill fs-2"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-cover">
                <div className="bg-warning bg-opacity-50 text-white border-bottom border-4 border-warning rounded-3 pointer h-100 p-3">
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div className="card-details">
                      <div className="label-value fs-3 fw-bold lh-1 mb-1">{issueCounts.openCount}</div>
                      <div className="label-title small text-light text-opacity-75 lh-sm">Open Issues</div>
                    </div>
                    <div className="card-iconn text-warning lh-1">
                      <i className="bi bi-bug-fill fs-2"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-cover">
                <div className="bg-info bg-opacity-50 text-white border-bottom border-4 border-info rounded-3 pointer h-100 p-3">
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div className="card-details">
                      <div className="label-value fs-3 fw-bold lh-1 mb-1">{issueCounts.inPCount}</div>
                      <div className="label-title small text-light text-opacity-75 lh-sm">In Progress</div>
                    </div>
                    <div className="card-iconn text-info lh-1">
                      <i className="bi bi-bug-fill fs-2"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-cover">
                <div className="bg-success bg-opacity-50 text-white border-bottom border-4 border-success rounded-3 pointer h-100 p-3">
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div className="card-details">
                      <div className="label-value fs-3 fw-bold lh-1 mb-1">{issueCounts.inDCount}</div>
                      <div className="label-title small text-light text-opacity-75 lh-sm">In Discussion</div>
                    </div>
                    <div className="card-iconn text-success lh-1">
                      <i className="bi bi-bug-fill fs-2"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-cover">
                <div className="bg-danger bg-opacity-50 text-white border-bottom border-4 border-danger rounded-3 pointer h-100 p-3">
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div className="card-details">
                      <div className="label-value fs-3 fw-bold lh-1 mb-1">{issueCounts.closeCount}</div>
                      <div className="label-title small text-light text-opacity-75 lh-sm">Closed Issues</div>
                    </div>
                    <div className="card-iconn text-danger lh-1">
                      <i className="bi bi-bug-fill fs-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :null}

          {/* Accordion Section */}
          <div className="accordion-info-wrapper mb-3">
            <Accordion> 
              <Accordion.Item eventKey="0" className="border-primary">
                <Accordion.Button className="bg-primary bg-opacity-50 shadow-none"><b>Note -</b> </Accordion.Button>
                <Accordion.Body className="fs-14 bg-secondary bg-opacity-10 p-3">
                  <div className="mb-3">
                    This section is dedicated to raising tickets whenever you encounter any issue with the NM-Live System. 
                    You can describe your issue in text and if needed, you can also drag and drop a screenshot of the issue with the ticket.
                  </div>
                  <div className="mb-3">
                    <ul className="m-0 ps-3">
                      <li><span className="text-black fw-semibold">Open</span> - The initial status when a ticket is raised.</li>
                      <li><span className="text-black fw-semibold">In-Progress</span> -  When work has started on your issue.</li>
                      <li><span className="text-black fw-semibold">In-Discussion</span> - When the issue requires management-level discussion.</li>
                      <li><span className="text-black fw-semibold">Closed</span> -  When the issue has been resolved.</li>
                    </ul>
                  </div>
                  <div>
                    Please check your tickets regularly for status updates. If additional input is required from you, 
                    you will receive a reply on the ticket in its detail view where you can continue discussing the issue in more detail.
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          {/* Button trigger modal */}
          

          <div className="issues-listing p-3 mt-3">
            <h5 className="pb-3 mb-0">Recent Issues</h5>
            {isLoading ?
              <div className="" id="issues-preloader---" style={{
                position: "absolute",
                margin: "21px 0px 0px 0px",
                left: "44%"
              }}>
                <div style={{ marginLeft: "12px", float: "right" }} className="issues-spinner"></div>{" "}Please wait ....</div>
              :
              (<div className="list-group scrollarea">{htmlContent}</div>)
            }
          </div>
        </div>
      </section>

      {/* Modal */}
      <div className="modal fade" id="exampleModal" tabIndex={-1} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content text-body">
            <div className="modal-header bg-primary bg-gradient text-white px-4">
              <h1 className="modal-title fs-6" id="exampleModalLabel">Create Ticket</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ filter: "invert(100)" }}></button>
            </div>
            <div className="modal-body px-4">
              <form id="issueForm">
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">Subject</label>
                  <input type="text" className="form-control" id="subject" aria-describedby="subjectlHelp" placeholder="Enter your subject"
                    onChange={(e) => {
                      setSubject(e.target.value);
                      checkValidation("subject", e.target.value);
                    }}
                    required
                  />
                  {/* <div id="subjectlHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                </div>
                <div className="mb-3">
                  <label htmlFor="" className="form-label">Priority</label>
                  <select className="form-select" id="priority" aria-label="Default select example"
                    onChange={(e) => {
                      setPriority(e.target.value);
                      checkValidation("priority", e.target.value);
                    }}
                  >
                    <option value="">Please Select</option>
                    <option value="normal">Normal</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="issue-description" className="form-label">Issue Description</label>
                  <ReactQuill id="issue-description" theme="snow" placeholder="Please enter issue here.." onChange={setDescription} />
                </div>
              </form>
            </div>
            <div className="modal-footer px-4">
              <button type="button" id="closeIssueForm" className="btn btn-secondary me-2" data-bs-dismiss="modal" style={{ minWidth: "80px" }}>Close</button>
              <button onClick={handleFormSubmit} type="button" className="btn btn-primary px-4">Submit</button>
              <div className="d-none" id="issues-preloader">
                <div style={{ marginLeft: "12px", float: "right" }} className="issues-spinner"></div>{" "}Please wait ....</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(IssuesListing);

