import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Skills from './Skills';

import ReactQuill, { quill } from 'react-quill';
import "react-quill/dist/quill.snow.css";

import { RangeSlider } from 'rsuite';

import { languageOptions } from "./constants/languageOptions";

const SkillsVal = Skills.map((skill, index) => ({
	id: index + 1,
	skill_name: skill,
	value: skill,
	label: skill
}));

const NewRecruitment = (props) => {
	const { setLoggedinUser } = props;
	const [Skills, setSelectedOption] = useState(null);
	const [jobTitle, setJobTitle] = useState("");
	const [primaryRole, setPrimaryRole] = useState("");
	const [clientName, setclientName] = useState("");
	const [minimumExp, setMinimumExp] = useState("");
	const [department, setDepartment] = useState("");
	const [jobDescription, setJobDescription] = useState("");
	const [keySkills, setKeySkills] = useState("");
	const [location, setLocation] = useState("");
	const [minimumQualification, setMinimumQualification] = useState("");
	const [reqiredBy, setReqiredBy] = useState("");
	const [employmentType, setEmploymentType] = useState("");
	const [shiftType, setShiftType] = useState("");
	const [jobNatureType, setNatureOfJob] = useState("");
	const [isClientName, setIsClientName] = useState(false);
	const [isloading, setIsloading] = useState(false);
	const [preferedLang, setPreferedLang] = useState({});
	const [range, setRange] = useState([1, 5]);

	let navigate = useNavigate();
	//  console.log(minimumExp)

	const [user, loading] = useAuthState(auth);

	useEffect(() => {
		if (loading) return;
		if (!user) navigate("/");
		if (user) {
			const userInfo = {
				uid: user?.uid,
				email: user?.email,
				displayName: user?.displayName,
				accessToken: user?.accessToken,
				idToken: user?.idToken,
			};
			setLoggedinUser(userInfo);
		}
	}, [user, loading]);


	const enableClientText = () => {
		const primaryRole = document.getElementById("primary-role-id").value;
		if (primaryRole === "Ongoing Client" || primaryRole === "New Client") {
			setIsClientName(true)
		} else {
			setIsClientName(false)
		}
	}
	const handleSubmitRequisition = async (e) => {
		e.preventDefault();
		const submitButton = document.getElementById("Add-requisition-button");
		if (submitButton) {
			submitButton.disabled = true;
		}
		setIsloading(true)
		const addRequisition = {
			jobTitle: jobTitle,
			department: department,
			jobDescription: jobDescription,
			keySkills: keySkills,
			location: location,
			minimumExp: minimumExp,
			minimumQualification: minimumQualification,
			primaryRole: primaryRole,
			clientName: clientName,
			reqiredBy: reqiredBy,
			employmentType: employmentType,
			shiftType: shiftType,
			jobNatureType: jobNatureType,
			requested_by: user?.displayName,
			requested_by_email: user?.email,
			prefered_lang:preferedLang,
			budget:range
		}

		const requisitionResponse = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/add-requisition", addRequisition, "post");

		if (requisitionResponse.data.statusCode === 200) {
			setIsloading(false)
			submitButton.disabled = false;
			document.getElementById("requisitionForm").reset();
			setIsClientName(false)
			setJobTitle("");
			setPrimaryRole("");
			setclientName("")
			setMinimumExp("");
			setDepartment("");
			setJobDescription("");
			setKeySkills("");
			setLocation("");
			setMinimumQualification("");
			setReqiredBy("");
			setEmploymentType("");
			setShiftType("");
			setNatureOfJob("");
			setPreferedLang({})
			toast.success("Requisition added successfully!", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});

			navigate("/requisitions")
		}
		if (requisitionResponse.data.statusCode === 400) {
			setIsloading(false)
			submitButton.disabled = false;
			toast.error(requisitionResponse.data.message, {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	}

	// for keyskills
	const skills = [
		{
			skill_name: "PHP",
		},
		{
			skill_name: "JAVA",
		},
		{
			skill_name: "REACT",
		},
		{
			skill_name: "NODE JS",
		}
	]
	let options_ = [];
	if (skills.length > 0) {
		skills.map((item) => {
			const options = { value: item.skill_name, label: item.skill_name, role: item.skill_name };
			return options_.push(options)
		})
	}

	// React-slick npm style customisation
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			backgroundColor: 'red',
			border: 0,
			borderRadius: '0.5rem',
			backgroundColor: 'var(--custom-bg)',
			padding: '0.625rem 0.15rem',
		}),
		placeholder: (provided) => ({
			...provided,
			color: 'rga(108,117,125)', // Change the color of the placeholder text
		}),
	};

	const handleBack = () => {
		navigate("/requisitions");
	}

	//  console.log(preferedLang, range)

	return (
		<>
			{/* <ToastContainer /> */}
			<div className="recruitment-page mt-3 mb-4">
				<div className="container">
					<div className="fs-3 fw-semibold text-white text-opacity-75 lh-sm mb-3">Add new requisition</div>
					<div className="card dark-theme-bg rounded-4 p-3 p-md-4 p-xl-5">
						<Form data-bs-theme="dark" method="post" id="requisitionForm">
							<div className="row g-3">
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Job Title</Form.Label>
										</div>
										<div className="col-lg-8">
											<Form.Control type="text" className="border-0 rounded-3 py-3" placeholder="Enter Job Title" autoComplete="off" required onChange={(e) => { setJobTitle(e.target.value) }} tabIndex="0" />
										</div>
									</Form.Group>
								</div>

								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Minimum Experience</Form.Label>
										</div>
										<div className="col-lg-8">
											<Form.Control type="number" className="border-0 rounded-3 py-3" placeholder="Years" autoComplete="off" required
											value={minimumExp}
											 onChange={(e) => { 
												let value = e.target.value;
												value = value < 0 ? 0 : value;
												setMinimumExp(value);
											  }}  tabIndex="2"  
											 min="0" />
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Primary Role</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example" id="primary-role-id" required onChange={(e) => { setPrimaryRole(e.target.value); enableClientText() }} tabIndex="11">
													<option value="">Select</option>
													<option value="In House">In House</option>
													<option value="Ongoing Client">Ongoing Client</option>
													<option value="New Client">New Client</option>
													<option value="For Bench">For Bench</option>
													<option value="Dedicated Client">Dedicated Client</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								{isClientName ?
									<div className="col-sm-6">
										<Form.Group className="row align-items-center g-2 g-lg-3">
											<div className="col-lg-4 text-lg-end">
												<Form.Label className="m-0">Client Name</Form.Label>
											</div>
											<div className="col-lg-8">
												<Form.Control type="text" className="border-0 rounded-3 py-3" placeholder="Enter Client Name" autoComplete="off" required onChange={(e) => { setclientName(e.target.value) }} />
											</div>
										</Form.Group>
									</div>
									: null}
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Minimum Qualification</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example" required onChange={(e) => { setMinimumQualification(e.target.value) }} tabIndex="3">
													<option value="">Select</option>
													<option value="Diploma">Diploma</option>
													<option value="Graduate">Graduate</option>
													<option value="Post Graduate">Post Graduate</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Job Location</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example" required onChange={(e) => { setLocation(e.target.value) }} tabIndex="4">
													<option value="">Select Location</option>
													<option value="Noida">Noida</option>
													<option value="Kolkata">Kolkata</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>

								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Department</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example" required onChange={(e) => { setDepartment(e.target.value) }} tabIndex="5">
													<option value="">Select Department</option>
													<option value="AI and Data Science">AI and Data Science</option>
													<option value="Account and Finance">Account and Finance</option>
													<option value="Back End Support">Back End Support</option>
													<option value="Billing">Billing</option>
													<option value="Business Operation">Business Operation</option>
													<option value="CAM">CAM</option>
													<option value="Content">Content</option>
													<option value="Coordination">Coordination</option>
													<option value="Customer Service">Customer Service</option>
													<option value="Data Science">Data Science</option>
													<option value="Digital Marketing">Digital Marketing</option>
													<option value="Embedded">Embedded</option>
													<option value="Enterprise Applications">Enterprise Applications</option>
													<option value="Foreign Language Expert">Foreign Language Expert</option>
													<option value="GAPM">GAPM</option>
													<option value="HR">HR</option>
													<option value="IT">IT</option>
													<option value="IT Support">IT Support</option>
													<option value="JAVA">JAVA</option>
													<option value="Legal / Paralegal">Legal / Paralegal</option>
													<option value="Management">Management</option>
													<option value="Microsoft Tech Stack">Microsoft Tech Stack</option>
													<option value="Mobile Apps">Mobile Apps</option>
													<option value="Multimedia">Multimedia</option>
													<option value="Operations">Operations</option>
													<option value="Open Source">Open Source</option>
													<option value="Quality">Quality</option>
													<option value="Recruitment">Recruitment</option>
													<option value="Research">Research</option>
													<option value="Sales">Sales</option>
													<option value="SEO / Marketing">SEO / Marketing</option>
													<option value="Testing">Testing</option>
													<option value="Virtual Assistant">Virtual Assistant</option>
													<option value="Web Designer">Web Designer</option>
													{/* <option value="Open Source">Open Source</option>
													<option value="Embedded">Embedded</option>
													<option value="Content">Content</option>
													<option value="Mobile Apps">Mobile Apps</option>
													<option value="SEO / Marketing">SEO / Marketing</option>
													<option value="HR">HR</option>
													<option value="IT">IT</option>
													<option value=".Net">.Net</option>
													<option value="Management">Management</option>

													<option value="GAPM">GAPM</option>
													<option value="CAM">CAM</option>
													<option value="Coordination">Coordination</option>
													<option value="Quality">Quality</option>
													<option value="Account and Finance">Account and Finance</option>
													<option value="Billing">Billing</option>
													<option value="Virtual Assistant">Virtual Assistant</option>
													<option value="Sales">Sales</option>
													<option value="Digital Marketing">Digital Marketing</option>
													<option value="Legal / Paralegal">Legal / Paralegal</option>

													<option value="Back End Support">Back End Support</option>
													<option value="Business Operation">Business Operation</option>
													<option value="Customer Service">Customer Service</option>
													<option value="Data Science">Data Science</option>
													<option value="Foreign Language Expert">Foreign Language Expert</option>
													<option value="IT Support">IT Support</option>
													<option value="Multimedia">Multimedia</option>
													<option value="Operations">Operations</option>
													<option value="Research">Research</option>
													<option value="Web Designer">Web Designer</option> */}
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Job Type</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example" required onChange={(e) => { setEmploymentType(e.target.value) }} tabIndex="6">
													<option value="">Select Employment</option>
													<option value="Full Time">Full Time</option>
													<option value="Part Time">Part Time</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Required By</Form.Label>
										</div>
										<div className="col-lg-8">
											<Form.Control type="date" className="border-0 rounded-3 py-3" required onChange={(e) => { setReqiredBy(e.target.value) }} tabIndex="7" />
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Job Shift Type</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example" required onChange={(e) => { setShiftType(e.target.value) }} tabIndex="8">
													<option value="">Select Shift Type</option>
													<option value="Day Shift">Day</option>
													<option value="Night Shift">Night</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Job Nature</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example" required onChange={(e) => { setNatureOfJob(e.target.value) }} tabIndex="9">
													<option value="">Select Job Nature</option>
													<option value="WFO">Work from Office</option>
													<option value="WFH">Work from Home</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="mt-lg-3 mb-0">Key Skills</Form.Label>
										</div>
										<div className="col-lg-8">
											<CreatableSelect isMulti className='custom-select' classNamePrefix='custom-select' required
												closeMenuOnSelect={false}
												name="skills"
												options={SkillsVal}
												styles={customStyles}
												onChange={(value) => {
													setKeySkills(value);
													// setErrors((prevErrors) => ({
													//     ...prevErrors,
													//     skills: '',
													// }));
												}
												}
												placeholder="Add your skills"
												tabIndex={10}
											/>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="mt-lg-3 mb-0">Technical Round Language</Form.Label>
										</div>
										<div className="col-lg-8 ">
										<div className="custom-select">
										<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example"  
										onChange={(e) => {
											
											setPreferedLang({id:e.target.value, value:e.target.options[e.target.selectedIndex].text});
											}}
											>
													<option value="">Select Language</option>
													{languageOptions.map((item) => (
													<option key={item.id} value={item.id}>
														{item.name}
													</option>
													))}
												</Form.Select>
										
										</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="mb-0">Budget [1 - 100 (Lakhs)]</Form.Label>
										</div>
										<div className="col-lg-8 ">
										<div className="">
											<span className="text-warning">Selected: [{range[0] +" - "+ range[1]} Lakhs]</span>
										<RangeSlider
											value={range}
											onChange={(value) => setRange(value)}
											min={0}
											max={50}
											step={1}
										/>									
										</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-12">
									<Form.Group className="row g-2 g-lg-3">
										<div className="col-lg-2 text-lg-end">
											<Form.Label className="mt-lg-3 mb-0">Job Description</Form.Label>
										</div>
										<div className="col-lg-10 recruitment-editor">
											{/* <Form.Control as="textarea" className="border-0 rounded-3 py-3" placeholder="Enter job description here..." rows={4} required onChange={(e) => { setJobDescription(e.target.value) }} tabIndex="11" /> */}

											<ReactQuill 
											style={{backgroundColor:"#fff",height: "250px"}} 
											theme="snow" 
											placeholder="Enter job description here..." 
											required 
											onChange={(content) => setJobDescription(content)} tabIndex=
											{12}
											className="mb-5"

											
												/>
										</div>
									</Form.Group>
								</div>
								<div className="col-12 btn_wrapper d-flex justify-content-center gap-3 mt-4 pt-xl-3">
									<Button type="button" variant="secondary" className="py-2 px-5" onClick={handleBack}>Back</Button>
									<Button type="submit" variant="success" className="py-2 px-5" onClick={(e) => { handleSubmitRequisition(e) }} id="Add-requisition-button">Add Requisition</Button>
									{isloading ?
										<div style={{ textAlign: 'center' }}>
											<span className="spinner-profile"></span>
										</div>
										: null}
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	)
}

// export default NewRecruitment

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedinUser: (user) => dispatch(setUserInfo(user)),
	};
};

const mapStoreStateToProps = (state) => {
	return {
		...state,
	};
};

export default connect(
	mapStoreStateToProps,
	mapDispatchToProps
)(NewRecruitment);