import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
import { callAxios, getProfileData } from '../../utils/utils';

export const ProfilePhotoUpload = ({ showProfileModal, closeProfileModal,profileId, setShowProfileModal,setProfileData,image, setIsLoading }) => {
    
    const [uploadProfile, setUploadProfile] = useState(null);
    // const [imagePreview, setImagePreview] = useState(null);
    const [uploadErrorMessage, setUploadErrorMessage] = useState('');

    // Show Delete Profile Modal
    const [showDeleteProfile, setShowDeleteProfile] = useState(false);
    const closeDeleteProfile = () => setShowDeleteProfile(false);
    // const openDeleteProfile = () => setShowDeleteProfile(true);

    // Input Files Code
    // const handleUploadChange = (event) => {
    //     // Access the selected file from the input element
    //     const file = event.target.files[0];
    //     setUploadProfile(file);

    //     // Check if file size is within the limit
    //     const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
    //     if (file.size > maxSize) {
    //         setUploadProfile(null);
    //         setUploadErrorMessage('File size exceeds 2 MB limit. Please select a smaller file.');
    //         return;
    //     }

    //     setUploadProfile(file);
    //     setUploadErrorMessage('');

    //     // Display the image preview
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         setImagePreview(reader.result);
    //     };
    //     reader.readAsDataURL(file);
    // };

    // Delete uploaded image
    const handleProfileDelete = async() => {
        setUploadProfile(null);
        closeDeleteProfile();
        setShowDeleteProfile(true)

        
    };

    const confirmDelete = async() =>{
        setIsLoading(true)
        setShowDeleteProfile(false)
            setShowProfileModal(false);
        callAxios(environment.REACT_APP_API_URL_Live+"api/v1/profile/delete-photo",{profileId:profileId},"post").then(async(result)=>{
            
            setProfileData(await getProfileData(profileId, setIsLoading))
            toast.success("Profile photo deleted", {
                position: 'bottom-center'
            })
           
        })
    }

    const [imagePreview, setImagePreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const closePPModal = () =>{
    setShowProfileModal(false)
    const EE = image?image:null;
    setImagePreview(EE);
  }

  const handleUploadChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('photo', selectedFile);
    formData.append('profileId',profileId)

    try {
        setIsLoading(true)
        setShowProfileModal(false);
      const response = await fetch(environment.REACT_APP_API_URL_Live+"api/v1/profile/upload-photo", {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // console.log('Upload successful:', data.location);
        if(data.location.length >0){
            setImagePreview(data.location)
            // setLoading(false)
            setProfileData(await getProfileData(profileId, setIsLoading))
					toast.success("Profile photo updated", {
						position: 'bottom-center'
					})
					
					
        }
        // Handle successful upload response (e.g., update profile picture in the state)
      } else {
        // console.error('Upload failed:', response.statusText);
      }
    } catch (error) {
        setProfileData(await getProfileData(profileId, setIsLoading))
      console.error('Error uploading file:', error);
    }
  };

  const openDeleteProfile = () => {
    setShowDeleteProfile(true)
    setImagePreview(null);
    setSelectedFile(null);
  };


    return (
        <>
            <Modal show={showProfileModal} centered size='lg' onHide={closeProfileModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
                <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
                    <div className="close-wrapper text-end">
                        <Button variant="link" onClick={closePPModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
                    </div>
                    <div className="text-center">
                        <div className='widgetTitle text-black fs-5 fw-semibold mb-1'>Profile photo upload</div>
                        <p className='text-black fs-14 fw-semibold m-0'>Profile with photo has 40% higher chances of getting noticed by recruiters.</p>

                        <div className='text-center my-4 pb-lg-3'>
                            

<div className='wrapper'>
      {image ? (
        <>
          <img src={imagePreview?imagePreview:image} alt="Preview" className='img-fluid border rounded-circle object-fit-cover mb-4' style={{ width: '144px', height: '144px' }} />
          <div className="d-flex justify-content-center gap-3 mb-2">
            <Button variant='' onClick={openDeleteProfile} className='text-danger fs-14 fw-semibold border-0 rounded-pill text-decoration-none p-0'>Delete photo</Button>
            <input type='file' name='uploadProfile' id='uploadProfile' accept="image/*" onChange={handleUploadChange} style={{ display: 'none' }} />
            <label htmlFor='uploadProfile' className='btn btn-primary rounded-pill fs-14 fw-medium px-3 py-2'>Change photo</label>
          </div>
        </>
      ) : (
        <>
        {imagePreview?
        <img src={imagePreview} alt="Preview" className='img-fluid border rounded-circle object-fit-cover mb-4' style={{ width: '144px', height: '144px' }} />
        :null}
        <div className="text-center mb-2">
          <input type='file' name='uploadProfile' id='uploadProfile' accept="image/*" onChange={handleUploadChange} style={{ display: 'none' }} />
          <label htmlFor='uploadProfile' className='btn btn-primary rounded-pill fs-14 fw-medium px-3 py-2'>Upload photo</label>
        </div>
        </>
        
      )}
      <Button onClick={handleUpload} disabled={!selectedFile} className='btn btn-success rounded-pill fs-14 fw-medium px-3 py-2 mt-3'>Save Photo</Button>
    </div>


                            {uploadErrorMessage && <div className='text-danger'>{uploadErrorMessage}</div>}
                            <div className='subTitle text-clr2'>Supported file format: png, jpg, jpeg, gif - upto 2MB</div>
                        </div>

                        <div className="text-clr2 fw-light">
                            By uploading your photograph, you certify that naukri.com has the right to display this photograph
                            to the recruiters and that the uploaded file does not violate our
                            <Link to={"#"} target="_blank" className="text-primary fw-medium text-decoration-none ms-1">Terms of services.</Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteProfile} centered onHide={closeDeleteProfile} backdrop="static" keyboard={false} dialogClassName='profileCustomModal mw-auto small text-dark' contentClassName='bg-light rounded-4 overflow-hidden'>
                <Modal.Body className="p-md-4 p-lg-5 text-center">
                    <div className="fs-5 fw-semibold lh-sm mb-4">Are you sure you want to delete photo ?</div>
                    <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-2">
                        <Button variant="danger" onClick={closeDeleteProfile} className='rounded-pill px-4'>Cancel</Button>
                        <Button type='submit' variant="primary" onClick={confirmDelete} className='rounded-pill px-4'>Delete</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
