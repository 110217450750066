import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import WarningModal from '../Components/Warningmodal';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
import { generateYearOptions, getProfileData } from '../../utils/utils';

export const AddITSkills = ({ showItSkills, closeItSkills, sendSkillsdata, sendactiontype, toasterData, Itskills, profileId, setShowItSkills, setProfileData, setItSkillsCliked, itSkillsBtnClicked, setIsLoading }) => {
	// console.log(sendactiontype)
	const [showModal, setShowModal] = useState(false);
    const [textmessage, setTextmessage] = useState('');
    const [deleteSkill, setDeleteSkill] = useState('')
	const [skillformData, setskillformData] = useState({
        skill_software_name: sendSkillsdata?.skill_software_name ? sendSkillsdata?.skill_software_name : "", 
        software_version: sendSkillsdata?.software_version ? sendSkillsdata?.software_version : "", 
        last_used: sendSkillsdata?.last_used ? sendSkillsdata?.last_used : "", 
        experience_year: sendSkillsdata?.experience_year ? sendSkillsdata?.experience_year : "",
        experience_month: sendSkillsdata?.experience_month ? sendSkillsdata?.experience_month : "",
        profile_id: "",
        type:sendactiontype,
        id:sendSkillsdata?.id ? sendSkillsdata?.id : "",
    });

	const handleChange = (event) => {
        const { name, value } = event.target;
        setskillformData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const sendData = (e) => {
        e.preventDefault()
        skillformData.profile_id = profileId
        dataAction(skillformData)
    }

	// deleteskill
    const deleteskill = () => {
        const getId = sendSkillsdata?.id
        setShowModal(true)
        setTextmessage('Are you sure you want to delete this skill?')
        const sendData = {
            "profile_id":profileId ? profileId : '',
            "id":getId, 
            "type":"delete"
        }
        setDeleteSkill(sendData)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }
    // deleteskill

	// data Action
	const dataAction = async(postData) => { 
        // setLoading(true)
        var apiname = 'add-it-skills'
        if(postData.type === "update" || postData.type === "delete"){
            apiname = 'update-delete-it-skills'
        }
        try {
			setIsLoading(true)
			setShowItSkills(false)
			const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/${apiname}`, postData, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const responsedata = response?.data
			if (responsedata?.statusCode === 200) {   
				setProfileData(await getProfileData(profileId, setIsLoading))
                const sendMsg = responsedata?.message
				toast.success(sendMsg, {
					position: 'bottom-center'
				})
				
                
			}
            // setLoading(false)
		} catch (error) {
			setProfileData(await getProfileData(profileId, setIsLoading))
			console.log('Error: ', error)
		}
    }

	useEffect(() => {
		setskillformData({ ...skillformData, "data":'dummy'})
	},[sendSkillsdata])

	useEffect(() => {
		setskillformData({
		  skill_software_name: sendSkillsdata?.skill_software_name || '',
		  software_version: sendSkillsdata?.software_version || '',
		  last_used: sendSkillsdata?.last_used || '',
		  experience_year: sendSkillsdata?.experience_year || '',
		  experience_month: sendSkillsdata?.experience_month || '',
		  profile_id: profileId || '',
		  type: sendSkillsdata?.actiontype || '',
		  id: sendSkillsdata?.id || ''
		});
	  }, [showItSkills]);

	return (
		<>
			<Modal show={showItSkills} centered size='lg' onHide={closeItSkills} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
			<Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
				<div className="close-wrapper text-end">
					<Button variant="link" onClick={closeItSkills} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
				</div>
				
            	<WarningModal show={showModal} onClose={handleCloseModal} textmessage={textmessage} updateEmp={dataAction} deleteEmp={deleteSkill} />		
				<div className="heading-wrapper mb-4">
					<div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
						<span className='widgetTitle text-black fs-5 fw-semibold'>IT skills <span className='profileComScore fs-6 fw-normal text-success ms-1'>Add 10%</span></span>
						{/* This button will show only when you click to edit the details */}
						{itSkillsBtnClicked? 
							<span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0 pointer' onClick={() => deleteskill(true)}>Delete</span>
							 :null} 
					</div>
					<p className='subTitle fs-14'>Mention skills like programming languages (Java, Python), softwares (Microsoft Word, Excel) and more, to show your technical expertise.</p>
				</div>

				<Form action="POST" className="form-cover" onSubmit={sendData}>
					<Form.Group className="mb-3">
						<Form.Label>Skill / software name<span className='text-danger'>*</span></Form.Label>
						<Form.Control type="text" name="skill_software_name" value={skillformData?.skill_software_name} onChange={handleChange} placeholder="Skill / Software name" autoComplete='off' required />
					</Form.Group>

					<Form.Group>
						<div className="row gx-3">
							<div className="col-6">
								<Form.Group className="mb-3">
									<Form.Label>Software version</Form.Label>
									<Form.Control type="text" name="software_version" value={skillformData.software_version} onChange={handleChange} placeholder="Software version" autoComplete='off' />
								</Form.Group>
							</div>

							<div className="col-6">
								<Form.Group className="mb-3">
									<Form.Label>Last used</Form.Label>
									<Form.Select name="last_used" value={skillformData.last_used} onChange={handleChange}>
										<option selected hidden value=''>Last used</option>
										{generateYearOptions().map(year => (
										<option key={year} value={year}>{year}</option>
									))}
									</Form.Select>
								</Form.Group>
							</div>
						</div>
					</Form.Group>

					<Form.Group>
						<Form.Label>Experience</Form.Label>
						<div className="row gx-3">
							<div className="col-6">
								<Form.Group className="mb-3">
									<Form.Select name="experience_year" value={skillformData.experience_year} onChange={handleChange}>
										<option selected hidden value=''>Years</option>
										<option value="0 Year">0 Year</option>
                                        <option value="1 Year">1 Year</option>
                                        <option value="2 Years">2 Years</option>
                                        <option value="3 Years">3 Years</option>
                                        <option value="4 Years">4 Years</option>
                                        <option value="5 Years">5 Years</option>
                                        <option value="6 Years">6 Years</option>
                                        <option value="7 Years">7 Years</option>
                                        <option value="8 Years">8 Years</option>
                                        <option value="9 Years">9 Years</option>
                                        <option value="10 Years">10 Years</option>
                                        <option value="11 Years">11 Years</option>
                                        <option value="12 Years">12 Years</option>
                                        <option value="13 Years">13 Years</option>
                                        <option value="14 Years">14 Years</option>
                                        <option value="15 Years">15 Years</option>
                                        <option value="16 Years">16 Years</option>
                                        <option value="17 Years">17 Years</option>
                                        <option value="18 Years">18 Years</option>
                                        <option value="19 Years">19 Years</option>
                                        <option value="20 Years">20 Years</option>
                                        <option value="21 Years">21 Years</option>
                                        <option value="22 Years">22 Years</option>
                                        <option value="23 Years">23 Years</option>
                                        <option value="24 Years">24 Years</option>
                                        <option value="25 Years">25 Years</option>
                                        <option value="26 Years">26 Years</option>
                                        <option value="27 Years">27 Years</option>
                                        <option value="28 Years">28 Years</option>
                                        <option value="29 Years">29 Years</option>
                                        <option value="30 Years">30 Years</option>
                                        <option value="30+ Years">30+ Years</option>
									</Form.Select>
								</Form.Group>
							</div>

							<div className="col-6">
								<Form.Group className="mb-3">
									<Form.Select name="experience_month" value={skillformData.experience_month} onChange={handleChange} required>
										<option selected hidden value=''>Months</option>
										<option value="0 Month">0 Month</option>
                                        <option value="1 Month">1 Month</option>
                                        <option value="2 Months">2 Months</option>
                                        <option value="3 Months">3 Months</option>
                                        <option value="4 Months">4 Months</option>
                                        <option value="5 Months">5 Months</option>
                                        <option value="6 Months">6 Months</option>
                                        <option value="7 Months">7 Months</option>
                                        <option value="8 Months">8 Months</option>
                                        <option value="9 Months">9 Months</option>
                                        <option value="10 Months">10 Months</option>
                                        <option value="11 Months">11 Months</option>
									</Form.Select>
								</Form.Group>
							</div>
						</div>
					</Form.Group>

					<div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
						<Button variant="danger" onClick={closeItSkills} className='rounded-pill px-4'>Cancel</Button>
						<Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
					</div>
				</Form>
			</Modal.Body>
			</Modal>
		</>
	)
}
