import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const WarningModal = ({ show, onClose, textmessage, updateEmp, deleteEmp }) => {

  // delete emp
  const handleSubmit = async (e) => {
    e.preventDefault();
    updateEmp(deleteEmp)
    onClose()
  };
  // delete emp

  return (
    <Modal show={show} onHide={onClose}  centered backdrop="static" backdropClassName="child-modal-backdrop" keyboard={false} dialogClassName='profileCustomModal editMobileNumModal small text-dark mx-auto' contentClassName='rounded-4'>
      {/* <div className="modal-overlay"> */}
        {/* <Modal.Dialog> */}
          <Modal.Body className='p-md-4 p-lg-5 text-center'>
          <div className='fs-5 fw-semibold lh-sm mb-4'>{textmessage}</div>
          {/* <Modal.Footer> */}
          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-2 mb-4">
            <Button variant="danger" type="button" className='rounded-pill px-4' onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="button" className='rounded-pill px-4' onClick={handleSubmit}>
              Submit
            </Button>
          </div>
          </Modal.Body>
          {/* You can add more buttons as needed */}
          {/* </Modal.Footer> */}
        {/* </Modal.Dialog> */}
      {/* </div> */}
    </Modal>

    // <Modal show={show} centered onHide={onClose} backdrop="static" keyboard={false} dialogClassName='profileCustomModal mw-auto small text-dark' contentClassName='bg-light rounded-4 overflow-hidden'>
    //   <div className="modal-overlay">
    //     <Modal.Body className="p-md-4 p-lg-5 text-center">
    //         <div className="fs-5 fw-semibold lh-sm mb-4">{textmessage}</div>
    //         <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-2">
    //             <Button variant="danger" onClick={onClose} className='rounded-pill px-4'>Cancel</Button>
    //             <Button type='submit' variant="primary" onClick={handleSubmit} className='rounded-pill px-4'>Delete</Button>
    //         </div>
    //     </Modal.Body>
    //   </div>
    // </Modal>
  );
};

export default WarningModal;
