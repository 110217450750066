import { Rating } from 'react-simple-star-rating';
import { convertReadable, convertReadableTime } from '../../utils/utils';

const InterviewScheduledcard = ({ scheduleData, cardType, userType }) => {
	const intTime = parseInt(scheduleData.interview_time) > 12 && parseInt(scheduleData.interview_time) < 24 ? scheduleData.interview_time + " PM" : scheduleData.interview_time + " AM";
	if(userType === "SA" || userType === "SSA"){
		return (
			<div className="info-cards int-scheduled small rounded pointer overflow-auto mt-2">
				<div className="info-header fs-6 text-white text-capitalize text-end px-3 py-2">{cardType}</div>
				<div className="info-body p-3">
					<div className="row g-3 mb-3">
						<div className="col-sm-6">
							<div className="cand-name text-primary fs-6 fw-medium text-capitalize">{scheduleData.user_name}</div>
							<div className="cand-role text-muted text-capitalize">{scheduleData.user_name}</div>
							<div className="cand-company text-black text-capitalize">{scheduleData.current_company}</div>
						</div>
						<div className="col-sm-6 text-xl-end">
							{/* <div className="cand-date text-muted text-capitalize">{convertReadable(scheduleData.createdAt) + ' ' + convertReadableTime(scheduleData.createdAt)}</div> */}
							<div className="cand-status fw-medium text-danger text-capitalize">{scheduleData.job_status}</div>
						</div>
					</div>
	
					<div className="row g-3">
						<div className="col-sm-6 col-xl-3">
							<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
								<div className="label-title text-black">Current CTC:</div>
								<div className="label-value text-danger">{scheduleData.current_ctc} Lakh</div>
							</div>
							<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
								<div className="label-title text-black">Interview Medium:</div>
								<div className="label-value text-danger">{scheduleData.interview_medium}</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-3">
							<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
								<div className="label-title text-black">Expected CTC:</div>
								<div className="label-value text-danger">{scheduleData.expected_ctc} Lakh</div>
							</div>
							<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
								<div className="label-title text-black">Interview Date:</div>
								<div className="label-value text-danger">{convertReadable(scheduleData.interview_date)}</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-3">
							<div className="info-box fw-medium text-capitalize d-flex flex-wrap align-items-center column-gap-2 mb-1">
								<div className="label-title text-black">Com Skills:</div>
								<Rating size={18} style={{ marginTop: '-6px' }}
									initialValue={parseFloat(scheduleData.comm_skill_rating)}
									allowFraction
									// showTooltip
									readonly
									fillColor={"#E43545"}
								/>
							</div>
							<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
								<div className="label-title text-black">Interview Time:</div>
								<div className="label-value text-danger">{intTime}</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-3">
							<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
								<div className="label-title text-black">Interview Scheduled By:</div>
								<div className="label-value text-danger">{scheduleData.added_by_name}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	} else{
		if(!scheduleData.added_by_email.endsWith("@gmail.com")){
			return (
				<div className="info-cards int-scheduled small rounded pointer overflow-auto mt-2">
					<div className="info-header fs-6 text-white text-capitalize text-end px-3 py-2">{cardType}</div>
					<div className="info-body p-3">
						<div className="row g-3 mb-3">
							<div className="col-sm-6">
								<div className="cand-name text-primary fs-6 fw-medium text-capitalize">{scheduleData.user_name}</div>
								<div className="cand-role text-muted text-capitalize">{scheduleData.user_name}</div>
								<div className="cand-company text-black text-capitalize">{scheduleData.current_company}</div>
							</div>
							<div className="col-sm-6 text-xl-end">
								{/* <div className="cand-date text-muted text-capitalize">{convertReadable(scheduleData.createdAt) + ' ' + convertReadableTime(scheduleData.createdAt)}</div> */}
								<div className="cand-status fw-medium text-danger text-capitalize">{scheduleData.job_status}</div>
							</div>
						</div>
		
						<div className="row g-3">
							<div className="col-sm-6 col-xl-3">
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
									<div className="label-title text-black">Current CTC:</div>
									<div className="label-value text-danger">{scheduleData.current_ctc} Lakh</div>
								</div>
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
									<div className="label-title text-black">Interview Medium:</div>
									<div className="label-value text-danger">{scheduleData.interview_medium}</div>
								</div>
							</div>
							<div className="col-sm-6 col-xl-3">
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
									<div className="label-title text-black">Expected CTC:</div>
									<div className="label-value text-danger">{scheduleData.expected_ctc} Lakh</div>
								</div>
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
									<div className="label-title text-black">Interview Date:</div>
									<div className="label-value text-danger">{convertReadable(scheduleData.interview_date)}</div>
								</div>
							</div>
							<div className="col-sm-6 col-xl-3">
								<div className="info-box fw-medium text-capitalize d-flex flex-wrap align-items-center column-gap-2 mb-1">
									<div className="label-title text-black">Com Skills:</div>
									<Rating size={18} style={{ marginTop: '-6px' }}
										initialValue={parseFloat(scheduleData.comm_skill_rating)}
										allowFraction
										// showTooltip
										readonly
										fillColor={"#E43545"}
									/>
								</div>
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
									<div className="label-title text-black">Interview Time:</div>
									<div className="label-value text-danger">{intTime}</div>
								</div>
							</div>
							<div className="col-sm-6 col-xl-3">
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
									<div className="label-title text-black">Interview Scheduled By:</div>
									<div className="label-value text-danger">{scheduleData.added_by_name}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		
	}
	
}

export default InterviewScheduledcard;