import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import makeAnimated from 'react-select/animated';

import CreatableSelect from "react-select/creatable";
import Skills from "../../Recruitment/Skills";

const SkillsVal = Skills.map((skill, index) => ({
    value: skill,
    label: skill,
  }));

const animatedComponents = makeAnimated();
export const OptionYes = ({ sendmydata, formData, handleInputChange, getskills,employmentData, setEmploymentData,setSelectedSkills,remainingCharacters }) => {
    const [inputValue, setInputValue] = useState([]);
    const [filterSkills, setFilterSkills] = useState([]);
   

  
    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          borderColor: "var(--bs-border-color) !important",
          color: "#000",
          borderRadius: "1rem",
          backgroundColor: "#fff",
          padding: "5px",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "var(--theme-color4)", // Change the color of the placeholder text
          fontSize: "14px",
          fontWeight: "400",
          padding: "0",
        }),
      };
    const options = [
        { value: 'HTML5', label: 'HTML5', id: 1 },
        { value: 'CSS3', label: 'CSS3', id: 2 },
        { value: 'JAVASCRIPT', label: 'JAVASCRIPT', id: 3 },
        { value: 'JQUERY', label: 'JQUERY', id: 4 },
        { value: 'BOOTSTRAP', label: 'BOOTSTRAP', id: 5 },
        { value: 'REACT', label: 'REACT', id: 6 }
    ]
    const handleSkillChange = async (selectedOptions) => {
        // console.log(profileData?.key_skills, selectedOptions);
        // const exists = profileData?.key_skills.some((item) => {
        //   console.log(item.key_skills);
        // });
        // console.log(exists);
        setSelectedSkills(selectedOptions);
        
     getskills(selectedOptions)
      };
      useEffect(() => {
        const savedKeySkills = new Set(
            employmentData?.employment_skills &&
            employmentData.employment_skills.map((item) => item.skill)
        );
    
        const filteredMain = SkillsVal.filter(
          (item) => !savedKeySkills.has(item.value)
        );
    
        setFilterSkills(filteredMain);
      }, [employmentData?.employment_skills]);
    const removeSkill = (id) => {
        const updatedList = inputValue.filter(item => item.id !== id);
        setInputValue(updatedList);
        getskills(updatedList)
    }

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1 and pad with leading zero
  
    // Create the max value for the input
    const maxMonth = `${currentYear}-${currentMonth}`;

    return(
       <>
        <Form.Group className="mb-3">
            <Form.Label>Total experience<span className='text-danger'>*</span></Form.Label>
            <div className="row g-2">
                <div className="col-6">
                    <Form.Select name="years" value={formData.years} onChange={handleInputChange} required>
                        <option selected disabled value=''>Years</option>
                        <option value="0 Year">0 Year</option>
                                        <option value="1 Year">1 Year</option>
                                        <option value="2 Years">2 Years</option>
                                        <option value="3 Years">3 Years</option>
                                        <option value="4 Years">4 Years</option>
                                        <option value="5 Years">5 Years</option>
                                        <option value="6 Years">6 Years</option>
                                        <option value="7 Years">7 Years</option>
                                        <option value="8 Years">8 Years</option>
                                        <option value="9 Years">9 Years</option>
                                        <option value="10 Years">10 Years</option>
                                        <option value="11 Years">11 Years</option>
                                        <option value="12 Years">12 Years</option>
                                        <option value="13 Years">13 Years</option>
                                        <option value="14 Years">14 Years</option>
                                        <option value="15 Years">15 Years</option>
                                        <option value="16 Years">16 Years</option>
                                        <option value="17 Years">17 Years</option>
                                        <option value="18 Years">18 Years</option>
                                        <option value="19 Years">19 Years</option>
                                        <option value="20 Years">20 Years</option>
                                        <option value="21 Years">21 Years</option>
                                        <option value="22 Years">22 Years</option>
                                        <option value="23 Years">23 Years</option>
                                        <option value="24 Years">24 Years</option>
                                        <option value="25 Years">25 Years</option>
                                        <option value="26 Years">26 Years</option>
                                        <option value="27 Years">27 Years</option>
                                        <option value="28 Years">28 Years</option>
                                        <option value="29 Years">29 Years</option>
                                        <option value="30 Years">30 Years</option>
                                        <option value="30+ Years">30+ Years</option>
                    </Form.Select>
                </div>
                <div className="col-6">
                    <Form.Select name="months" value={formData.months} onChange={handleInputChange} required>
                        <option selected disabled value=''>Months</option>
                        <option value="0 Month">0 Month</option>
                                        <option value="1 Month">1 Month</option>
                                        <option value="2 Months">2 Months</option>
                                        <option value="3 Months">3 Months</option>
                                        <option value="4 Months">4 Months</option>
                                        <option value="5 Months">5 Months</option>
                                        <option value="6 Months">6 Months</option>
                                        <option value="7 Months">7 Months</option>
                                        <option value="8 Months">8 Months</option>
                                        <option value="9 Months">9 Months</option>
                                        <option value="10 Months">10 Months</option>
                                        <option value="11 Months">11 Months</option>
                    </Form.Select>
                </div>
            </div>
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Current company name<span className='text-danger'>*</span></Form.Label>
            <Form.Control type="text" name="currCmp" value={formData.currCmp} onChange={handleInputChange} placeholder="Type your organization" autoComplete='off' required />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Current job title<span className='text-danger'>*</span></Form.Label>
            <Form.Control type="text" name="currJobtitle" value={formData.currJobtitle} onChange={handleInputChange} placeholder="Type your designation" autoComplete='off' required />
        </Form.Group>
        <Form.Group>
            <div className="row g-2">
                <div className="col-sm-6">
                    <Form.Group className="mb-3">
                        <Form.Label>Joining date<span className='text-danger'>*</span></Form.Label>
                        <Form.Control max={maxMonth}  type="month" name="joiningDate" value={formData.joiningDate} onChange={handleInputChange} autoComplete='off' required />
                    </Form.Group>
                </div>
                <div className="col-sm-6">
                    <Form.Group className="mb-3">
                        <Form.Label>Current salary<span className='text-danger'>*</span></Form.Label>
                        <InputGroup>
                            <Form.Select className='shadow-none' name="salaryType" value={formData.salaryType} onChange={handleInputChange}  style={{ maxWidth: '65px' }}>
                                <option value="INR">₹</option>
                                <option value="USD">$</option>
                            </Form.Select>
                            <Form.Control type="text" name="currentSalary" value={formData.currentSalary} onChange={handleInputChange} placeholder="Eg. 4,50,000" autoComplete='off' required />
                        </InputGroup>
                    </Form.Group>

                    
                </div>
            </div>
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Skills used<span className='text-danger'>*</span></Form.Label>
            <div className="skills-wrapper mb-3">
                <div className="d-flex flex-wrap gap-2">
                    <Form.Control type="text" name="inputValue" value={formData.inputValue} onChange={handleInputChange} autoComplete='off' hidden />
                    {inputValue?.map((item) => (
                        <button key={item.id} type='button' name="addSkill" value={formData.addSkill} onChange={handleInputChange} className='btn btn-sm btn-outline-secondary rounded-pill py-1 px-3'>{item.skill} <span onClick={() => removeSkill(item.id)}><i class="bi bi-x fs-18"></i></span></button>
                    ))}
                </div>
            </div>
            {/* <Select 
                value={inputValue}
                onChange={handleSkillChange}
                options={options}
            /> */}
             <CreatableSelect
              isMulti
              className="custom-select"
              classNamePrefix="custom-select"   
              closeMenuOnSelect={false}
              name="skills"
              options={filterSkills}
              styles={customStyles}
              onChange={(value) => {
                handleSkillChange(value);
                // setErrors((prevErrors) => ({
                //     ...prevErrors,
                //     skills: '',
                // }));
              }}
              placeholder="Add your skills"
            />
            {/* <Form.Control type="text" name="addSkill" value={formData.addSkill} onChange={handleInputChange} placeholder="Add skills" autoComplete='off' required /> */}
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Job profile<span className='text-danger'>*</span></Form.Label>
            <Form.Control as="textarea" rows={3} placeholder='Type here...' autoComplete='off' name="jobProfile" value={formData.jobProfile} onChange={handleInputChange} required />
            <div className="charLenght fs-14 text-secondary text-end mt-2">{remainingCharacters} character(s) left</div>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Notice period<span className='text-danger'>*</span></Form.Label>
            <Form.Select name="noticePeriod" value={formData.noticePeriod} onChange={handleInputChange} required>
                <option selected disabled value=''>Months</option>
                <option value="15 Days or less">15 Days or less</option>
                <option value="1 Month">1 Month</option>
                <option value="2 Months">2 Months</option>
                <option value="3 Months">3 Months</option>
                <option value="More than 3 Months">More than 3 Months</option>
                <option value="Serving Notice Period">Serving Notice Period</option>
            </Form.Select>
        </Form.Group>
       </> 
    )
}