import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../firebaseConfig";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { environment } from "../environment";
import { setUserRole } from "../store/actions";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const Unauthorized = ({userRole, setUserRoleAction}) =>{
  let navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const getUserRole = async (email) => {
    //console.log("getting role")
   
      const q = query(
        collection(database, environment.REACT_APP_USERS_DB),
        where("email", "==", email)
      );
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;
          //setUserRole(data);
          setUserRoleAction(data?.userType);
        });
      });
    
  };
  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/lead-listing");
    else {
     
      getUserRole(user?.email);
      
    }
  }, [user, loading]);
//console.log(user,userRole)
    return (
        <div
          className="list-group-item border-secondary py-3 lh-sm"
          key="unautohiri"
          
        >
            <div className="row">
                <div className="col-lg-12 text-center text-danger">
                <h2 >Not authorized for this request</h2>
                </div>
            </div>
            
        </div>
      );
}

// export default Unauthorized;

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserRoleAction: (userRole) => dispatch(setUserRole(userRole)),
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(Unauthorized);