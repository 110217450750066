import React, { useEffect, useState } from "react";
import { app, database, auth } from "../firebaseConfig";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  orderBy,
  limit
} from "firebase/firestore";
import notificationSound from "../static/Notification.mp3";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import axios from "axios";
import * as qs from "qs";
//const qs = require('qs');
import { setIdentity, setRoomId, setUserInfo } from "../store/actions";

import { useNavigate, Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import "./OngoingCallsPage.css";
//import { db } from '../../../firebase/firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import Header from "../Header/Header";
import ProgressBar from 'react-animated-progress-bar';
import {convertMilisecondsToTime} from "../utils/utils";
import CircularProgressBar from '../CircularProgressBar/CircularProgressBar';

// env import
import {environment} from '../environment';

const OngoingCallsPage = (props) => {
  const {
    setRoomIdAction,
    setIdentityAction,
    roomId,
    participants,
    setLoggedinUser,
  } = props;
  const [user, loading, error] = useAuthState(auth);

  let navigate = useNavigate();

  const listItemCss = {
    padding: "0 15px",
    margin: "5px 0",
    borderRight: "2px solid #ccc",
    boxSizing: "border-box",
    backgroundColor: "red",
  };

  const [allData, setAllDate] = useState([]);
  const [audio, setAudio] = useState(new Audio(notificationSound));
  const [progressRoom, setProgressRoom] = useState([]);

  const dbInstance = collection(database, environment.REACT_APP_FIREBASE_DB);
  const addPlayerStremerWithID = collection(database, "playerstreamer");
  const end = new Date();
  const start = new Date(end.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
  // const q = query(collection(database, environment.REACT_APP_FIREBASE_DB),where('timeStamp','>',start),where('timeStamp','<',end));
  //const q = query(collection(database, "playerstreamer"), orderBy("timeStamp","desc"), limit(5));

  var startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  var endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);
  const queryConstraints = [];
   //queryConstraints.push(where('RoomStatus', '==', "in-progress"));
  queryConstraints.push(where('timeStamp', '>=', startOfToday));
  queryConstraints.push(where('timeStamp', '<=', endOfToday));

  queryConstraints.push(orderBy('timeStamp', 'desc'));
  const q = query(collection(database, environment.REACT_APP_FIREBASE_DB), ...queryConstraints);

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    //getRoomwithStatus();
    const userInfo = {
      uid: user?.uid,
      email: user?.email,
      displayName: user?.displayName,
      accessToken: user?.accessToken,
      idToken: user?.idToken,
    };
    // saveUserLogged(userInfo);
    setLoggedinUser(userInfo);
  }, [user, loading]);

  useEffect(() => {


    onSnapshot(q, (querySnapshot) => {
      let forms = [];
      querySnapshot.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          if (data.formstatus > 0) forms.push({ ...doc.data(), id: doc.id });
          // if (data?.isnew && data?.RoomStatus === 'in-progress') {
          //     playSound();
          // }
      });
      setAllDate(forms);
  });
  }, []);
//console.log("Ongoing DAta:", allData)


  //Join Room Admin
  const joinRoomAdmin = (roomId) => {
    //getRoomwithName(roomID);
   
    // change by abhishek
    window.open("/Adminroom?name=Admin&id=" + roomId, "_blank");
  };
const noResults = allData.length === 0?

  <tr>
      <td colSpan="5" >No ongoing calls found!</td>
  </tr>
  :null;
  const vlisting = allData.map((item, index) => {
    if(item.RoomStatus === "in-progress" && (!item.camLeftAt)){
    const formStartFillingAt = convertMilisecondsToTime(item.timeStamp.seconds);
  
    let total = 0;
  total += item.name !== ""? 1 : 0;
  total += item.email !== ""? 1 : 0;
  total += item.phone !== ""? 1 : 0;
  total += item.companyweb !== ""? 1 : 0;
  total += item.projduration !== ""? 1 : 0;
  total += item.description !== ""? 1 : 0; 

  const percentage = parseFloat(total * 100 / 6).toFixed(0);
    var stringified = item.timeStamp.toDate().toISOString();
    //console.log(stringified);
    var split1 = stringified.split("T");
    var date = split1[0];
    var time = split1[1].split(".");
    let timeStampfor = date + " " + time[0];
    
    return (
      <tr
        className="list-item-wrapper mt-3 rounded-2 px-2 py-3 p-lg-0"
        key={index}
      >
        <td className="list-item">
          <div className="client-name text-capitalize mb-1">{item.name}</div>
          <div className="country-name text-capitalize text-warning">
            {item.country}
          </div>
          {/* Progressbar Code */}
        <div className="client-progress-status mt-1">
          {/* Multi Color */}
          <div className="progressbar-label">Client's Form Filling Status</div>
          <CircularProgressBar percentage={percentage} />
        </div>
        </td>
        {/* <td className="list-item">{item.email}</td>
          <td className="list-item">{item.phone}</td> */}
        <td className="list-item">
          {item.callStatus == 1 ? (
            <div className="d-flex align-items-center total-participants active text-capitalize lh-1 mb-1">
              <span>
                <i className="bi bi-camera-video me-2"></i>
              </span>
              Created
            </div>
          ) : (
            <div className="d-flex align-items-center total-participants text-capitalize lh-1 mb-1">
              <span>
                <i className="bi bi-camera-video me-2"></i>
              </span>{" "}
              Not Yet Created
            </div>
          )}
          <div className="d-flex align-items-center total-participants text-capitalize">
            <span className="live-btn rounded-1 me-2">LIVE</span> Not visible To
            client
          </div>
        </td>
        <td className="list-item">
          {item?.participants && item?.participants?.length > 0 ? (
            <div className="d-flex align-items-center total-participants active text-capitalize lh-1">
              <span>
                <i className="bi bi-people-fill me-2"></i>
              </span>{" "}
              Total Participants{" "}
              {item?.participants ? item?.participants?.length : 0}
            </div>
          ) : (
            <div className="d-flex align-items-center total-participants text-capitalize lh-1">
              <span>
                <i className="bi bi-people-fill me-2"></i>
              </span>{" "}
              Total Participants 0
            </div>
          )}
        </td>
        <td className="list-item ">
          {item.timeStamp ?
        <div className="time-state d-flex gap-2 justify-content-between">Client Started Filling Form AT: <span className="text-nowrap">{item.timeStamp?convertMilisecondsToTime(item.timeStamp.seconds,item.timeStamp.nanoseconds):""}</span></div>
        :null }

        {item.camJoinedAt?
        <div className="time-state d-flex gap-2 justify-content-between">CAM Joined AT: <span className="text-nowrap">{item.camJoinedAt?convertMilisecondsToTime(item.camJoinedAt.seconds, item.camJoinedAt.nanoseconds):""}</span></div>
        :null} 

        {item.clientSubmitTime?
        <div className="time-state d-flex gap-2 justify-content-between">Client Submitted Form AT: <span className="text-nowrap">{item.clientSubmitTime?convertMilisecondsToTime(item.clientSubmitTime.seconds, item.clientSubmitTime.nanoseconds):""}</span></div>
        :null}

        {item.clientJoinedLiveTime?
        <div className="time-state d-flex gap-2 justify-content-between">Client Click On Join Live Button AT: <span className="text-nowrap">{item.clientJoinedLiveTime?convertMilisecondsToTime(item.clientJoinedLiveTime.seconds, item.clientJoinedLiveTime.nanoseconds):""}</span></div>
        :null }

        {item.clientLeftTime?
        <div className="time-state d-flex gap-2 justify-content-between">Client Left Video Room AT: <span className="text-nowrap">{item.clientLeftTime?convertMilisecondsToTime(item.clientLeftTime.seconds, item.clientLeftTime.nanoseconds):""}</span></div>
        :null }
       
        {item.camLeftAt?
        <div className="time-state d-flex gap-2 justify-content-between">CAM LEFT Video Room AT: <span className="text-nowrap">{item.camLeftAt?convertMilisecondsToTime(item.camLeftAt.seconds, item.camLeftAt.nanoseconds):""}</span></div>
          :null }
        </td>       
        <td className="list-item">
          <div className="date-time-state mb-1">{timeStampfor}</div>
          <div className="department-name text-capitalize mb-1">
            {item?.participants && item?.participants?.length > 0
              ? "Attn By :-" + item?.attendantName
              : ""}
          </div>

          {item?.participants && item?.participants?.length > 0 ? (
            <button
              onClick={() => joinRoomAdmin(item.roomId)}
              className="btn btn-warning btn-custom rounded-1 btn-next mt-1"
            >
              Join Silently
            </button>
          ) : (
            <button
              onClick={() => console.log("no participant")}
              className="btn btn-info btn-custom rounded-1 btn-next mt-1"
            >
              No Participant
            </button>
          )}
        </td>
      </tr>
    );
          }
  });


  const isLoggedIn = useSelector((state) => state.isLoggedIn);

  const userInfo = useSelector((state) => state.userInfo);

  return (
    <>
      {/* <Header user={userInfo} /> */}
      <div className="container">
        <div className="live-listing-wrapper py-3">
          <div className="row align-items-center mb-lg-3">
            <div className="col-md-8">
              <div className="main-heading text-white text-opacity-75">Ongoing Calls</div>
            </div>
            <div className="col-md-4 text-md-end"></div>
          </div>

          <div className="table-responsive rounded-3">
            <table className="table table-hoverr table-borderless data-list mb-0">
            <thead>
                <tr className="list-item-wrapper">
                  <th className="list-item">Name</th>
                  <th className="list-item">Status</th>
                  <th className="list-item">Participants</th>
                  <th className="list-item">Time Stats</th>
                  <th className="list-item">Action</th>
                </tr>
              </thead>
              <tbody>{ allData.length > 0?vlisting:<tr><td colSpan={5} className="noResult">No ongoing calls found!</td></tr>}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (id) => dispatch(setRoomId(id)),
    setLoggedinUser: (user) => dispatch(setUserInfo(user)),
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(OngoingCallsPage);