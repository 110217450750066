


import React, { useState } from "react";
import '../Chatboat/Chat.css';
import imgDefault from '../resources/images/Avatar.png';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const MessageNotification = ({notification,isShow}) => {
  
    let navigate = useNavigate();

    const [messageCard, setMessageCard] = useState(false);

    const shortName = notification.title.split(" ").map((shortName) => {
        return shortName[0];
      });

    
    useEffect(()=>{
       // console.log("calling useEffect")
        setMessageCard(isShow);
    },[notification])

     //console.log(notification,messageCard)

    const messageClose = () => setMessageCard(false);

    const handleReply = (e) => {
        e.preventDefault();
        setMessageCard(false)
        navigate("/chats");
    }
    
    return (
        <>
            {messageCard?  (
                <div className="chat-message-notify">
                    <div className="msg-wrapper bg-primary text-white rounded-4 overflow-hidden mb-1">
                        <div className="d-flex align-items-start justify-content-between gap-2 p-3">
                            <div className="chip-wrapper">
                                <div className="chip-img d-flex align-items-center justify-content-center rounded-circle border border-1 overflow-hidden mx-auto" style={{ height: "45px", width: "45px" }}>
                                    <div className='user-shortname fs-6 fw-medium'>{shortName}</div>
                                    {/* <img className="img-fluid w-100 object-fit-cover" src={imgDefault} alt="User Image" /> */}
                                </div>
                            </div>
                            <div className="w-75">
                                <div className="caller-name fs-6 fw-medium text-truncate text-capitalize">{notification.title}</div>
                                <div className="message-desc small">{notification.body}</div>
                            </div>
                            <button type="button" className="bg-transparent border-0 text-white p-0" onClick={messageClose}>
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>
                        <div className="reply-block text-white rounded-bottom-4 px-3 py-2">
                            <button type="button" className="bg-transparent border-0 text-white p-0" onClick={handleReply}>
                                <i className="bi bi-reply"></i> Reply
                            </button>
                        </div>
                    </div>
                    {/* <div className="msg-wrapper bg-primary text-white rounded-4 overflow-hidden mb-1">
                        <div className="d-flex align-items-start justify-content-between gap-2 p-3">
                            <div className="chip-wrapper">
                                <div className="chip-img d-flex align-items-center justify-content-center rounded-circle border border-1 overflow-hidden mx-auto" style={{ height: "45px", width: "45px" }}>
                                
                                    <img className="img-fluid w-100 object-fit-cover" src={imgDefault} alt="User Image" />
                                </div>
                            </div>
                            <div className="w-75">
                                <div className="caller-name fs-6 fw-medium text-truncate text-capitalize">Vasheem Ahmad</div>
                                <div className="message-desc small">Loirem ipsum is simply dummy text Loirem ipsum is simply dummy text Loirem ipsum is simply dummy text Loirem ipsum is simply dummy text.</div>
                            </div>
                            <button type="button" className="bg-transparent border-0 text-white p-0">
                                <i className="bi bi-chat-text fs-18"></i>
                            </button>
                        </div>
                        <div className="reply-block text-white rounded-bottom-4 px-3 py-2">
                            <button type="button" className="bg-transparent border-0 text-white p-0">
                                <i className="bi bi-reply"></i> Reply
                            </button>
                        </div>
                    </div>
                    <div className="msg-wrapper bg-primary text-white rounded-4 overflow-hidden mb-1">
                        <div className="d-flex align-items-start justify-content-between gap-2 p-3">
                            <div className="chip-wrapper">
                                <div className="chip-img d-flex align-items-center justify-content-center rounded-circle border border-1 overflow-hidden mx-auto" style={{ height: "45px", width: "45px" }}>
                                   
                                    <img className="img-fluid w-100 object-fit-cover" src={imgDefault} alt="User Image" />
                                </div>
                            </div>
                            <div className="w-75">
                                <div className="caller-name fs-6 fw-medium text-truncate text-capitalize">Vasheem Ahmad</div>
                                <div className="message-desc small">Loirem ipsum is simply dummy text.</div>
                            </div>
                            <button type="button" className="bg-transparent border-0 text-white p-0">
                                <i className="bi bi-chat-text fs-18"></i>
                            </button>
                        </div>
                        <div className="reply-block text-white rounded-bottom-4 px-3 py-2">
                            <button type="button" className="bg-transparent border-0 text-white p-0">
                                <i className="bi bi-reply"></i> Reply
                            </button>
                        </div>
                    </div> */}
                </div>
            ): null}
        </>
    )
}