import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { environment } from '../../environment';
import { ExperienceYear, callAxios, getEmploymentData, getProfileData } from '../../utils/utils';
import { toast } from 'react-toastify';

import { Changemobilenumber } from './Changemobilenumber';
import { Changeemail } from './ChangeEmail';

import Autosuggest from 'react-autosuggest';

const getSuggestions = async (value, is_outside_india) => {
    const response = await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/profile/search-city', { cityName: value,is_outside_india:is_outside_india }, "post");
    return response.data;
};

// Function to get the suggestion value to display in the input
const getSuggestionValue = suggestion => suggestion.name;

// Function to render each suggestion
const renderSuggestion = suggestion => (
    <div className='pointer'>
        {suggestion.name}
    </div>
);

export const BasicDetails = ({ showBasicDetailsModal, closeBasicDetailsModal, empInfo, currempdata, LoadUserDetails, toasterData, setShowBasicDetailsModal, setIsLoading, profileId, setProfileData,setEmploymentData, setCurrempdata }) => {
  
    const [editmobile, setEditMobile] = useState(false)
    const [editemail, setEditEmail] = useState(false)
    const [showexperr, setShowexperr] = useState(false)
    const [showexp, setShowexp] = useState(false);
    const [showsalarybreak, setShowsalarybreak] = useState(false);
    const [showcitycntry, setShowcitycntry] = useState(false)
    const [incsalaryinfo, setIncsalaryinfo] = useState(false)
    const [cntrytype, setCntrytype] = useState('')
    const [countryList, setCountryList] = useState([])
    const [cityList, setCityList] = useState([])
    const [isOutSideIndia, setIsOutSideIndia] = useState(LoadUserDetails?.is_outside_india)
    const [mobileNumber, setMobileNumber]= useState('');
    const [email, setEmail]= useState('');
    const [eventType, setEventType] = useState('')

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        current_city: "",
        location: '',
        current_country: "",
        availability: "",
        totalExpYears: '',
        totalExpMonths: '',
        salaryType: '',
        salary_type: '',
        salary: '',
        job_profile: '',
        jobProfile: '',
        notice_period: '',
        noticePeriod: '',
        salary_break_down: '',
        WorkStatus: "",
        fixed_salary: "",
        variable_salary: "",
        country_code: "",
        area_code: "",
        phone_no: "",
        employementId: currempdata?.id || '',
        profileId: currempdata?.profile_id || '',
        profile_id: currempdata?.profile_id || '',
        is_outside_india: false
    })
    
    
    console.log(countryList,cityList)
    // EditMobile
    const openEditModal = (mobile, type) => { 
        console.log(mobile, type)
        setEditMobile(true) 
        setMobileNumber(mobile)
        setEventType(type)
       
    }
    const closeEditModal = () => { 
        setMobileNumber('')
        setEventType('')
        setEditMobile(false) 
    }

    // EditEmail
    const openEditEmailModal = (email,type) => { 
        setEmail(email)
        setEventType(type)
        setEditEmail(true) 
    }
    const closeEditEmailModal = () => { 
        setEmail('')
        setEventType('')
        setEditEmail(false) 
    }

    // autocomplete
    const [value, setValue] = useState('');
    const [items, setItems] = useState([]);

    const exptomon = (data) => {
        const completeExp = data
        const splitexp = completeExp.split('-')
        const splityear = splitexp[0].split(' ')
        const splitmonth = splitexp[1].split(' ')
        const monthsInYears = splityear[0] * 12; // 2 years in months
        const remainingMonths = splitmonth[0]; // 1 month
        const totalMonths = parseFloat(monthsInYears) + parseFloat(remainingMonths);
        if (totalMonths >= 1) {
            return "experienced"
        } else {
            return "fresher"
        }
    }

    const handleInputChange = (e) => {
        if (e.target.name === "current_city" && cntrytype === 'currIndia') {
            setValue(e.target.value)
            
        }
       
        if (e.target.name === "WorkStatus" && e.target.value === "experienced") {
            setShowexp(true)
        }
        if (e.target.name === "WorkStatus" && e.target.value === "fresher") {
            const getempstatus = exptomon(currempdata?.total_experience)
            if (getempstatus === "experienced") {
                formData.WorkStatus = "experienced"
                setShowexperr(true)
                return
            }
            if (getempstatus === "fresher") {
                setShowexperr(false)
            }
            setShowexp(false)
        }
        if (e.target.name === "salary_break_down" && e.target.value === "Fixed") {
            setShowsalarybreak(false)
        }
        if (e.target.name === "salary_break_down" && e.target.value === "Fixed+Variable") {
            setShowsalarybreak(true)
        }
        if (e.target.name === "CurrentLocation" && e.target.value === "currIndia") {
            setShowcitycntry(false)
            setCntrytype('currIndia')
            formData.is_outside_india = false
            setIsOutSideIndia(false)
            formData.current_city = LoadUserDetails?.is_outside_india?'':LoadUserDetails?.current_city;

        }
        if (e.target.name === "CurrentLocation" && e.target.value === "OutsideIndia") {
            setShowcitycntry(true)
            setCntrytype('currIndia')
            formData.is_outside_india = true
            setIsOutSideIndia(true)
            formData.current_city = !LoadUserDetails?.is_outside_india?'':LoadUserDetails?.current_city;
            
        }
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }

    const basicdetailsdata = async (e) => {
        e.preventDefault()
        if (formData.WorkStatus === "fresher") {
            formData.years = ""
            formData.months = ""
        }
        if (formData.salary_break_down === "Fixed") {
            formData.fixed_salary = ""
            formData.variable_salary = ""
        }
        if (formData.salary_break_down === "Fixed+Variable") {
            const addsalary = parseFloat(formData.fixed_salary) + parseFloat(formData.variable_salary);
            if (addsalary > parseFloat(formData.salary)) {
                setIncsalaryinfo(true)
                return;
            }
        }
        if (formData.CurrentLocation === "currIndia") {
            formData.current_country = ""
            formData.country_code = ""
            formData.area_code = ""
            formData.phone_no = ""
        }
        formData.current_city = formData.current_city ? formData.current_city : value
        formData.type = "update"
        formData.isUpdateFromBasic = true
        formData.profileId = profileId
        const postData = formData
        var apiname = 'update-delete-employment'
        try {
            setIsLoading(true)
            setShowBasicDetailsModal(false)
            const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/${apiname}`, postData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const responsedata = response?.data
            if (responsedata?.statusCode === 200 || responsedata?.statusCode === 201 || response?.data) {
                callApi(postData)

            }
        } catch (error) {
            setProfileData(await getProfileData(profileId, setIsLoading))
            console.log('Error: ', error)
        }
    }

    // callApi
    const callApi = async (postData) => {
        postData.type = postData.WorkStatus
        postData.current_city = value;
        var apiname = 'update-profile'
        try {
            const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/${apiname}`, postData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const responsedata = response?.data
            if (responsedata?.statusCode === 200 || responsedata?.statusCode === 201 || response?.data) {
                // LoadUserDetails()
                const sendMsg = responsedata?.message
                const getData = await getEmploymentData(profileId, setIsLoading)
            setProfileData(getData.profileData);
            setEmploymentData(getData.employmentData)
            const getiscurremp = getData.employmentData
            const currentEmployments = getiscurremp.filter(employment => employment.is_current_employment === 1);

            // Find the employment with the highest id among the filtered employments
            const maxId = Math.max(...currentEmployments.map(employment => employment.id));

            // Filter again to get the employment with the maximum id value
            const highestEmployment = currentEmployments.find(
                employment => employment.id === maxId
            );
            // console.log(highestEmployment)
            setCurrempdata(highestEmployment)
                // setProfileData(await getProfileData(profileId, setIsLoading))
                toast.success(sendMsg, {
                    position: 'bottom-center'
                })
            }
        } catch (error) {
            const getData = await getEmploymentData(profileId, setIsLoading)
            setProfileData(getData.profileData);
            setEmploymentData(getData.employmentData)
            const getiscurremp = getData.employmentData
            const currentEmployments = getiscurremp.filter(employment => employment.is_current_employment === 1);

            // Find the employment with the highest id among the filtered employments
            const maxId = Math.max(...currentEmployments.map(employment => employment.id));

            // Filter again to get the employment with the maximum id value
            const highestEmployment = currentEmployments.find(
                employment => employment.id === maxId
            );
            console.log(highestEmployment)
            setCurrempdata(highestEmployment)
            console.log('Error: ', error)
        }
    }
    // callApi

    const getYearMonth = (data, type) => {
        const splitexp = data.split('-')
        if (type === 'year') {
            return splitexp[0]
        }
        if (type === 'month') {
            return splitexp[1]
        }
    }

    useEffect(() => {
        if (empInfo?.is_outside_india === true) {
            setShowcitycntry(true)
        }
        if (empInfo?.is_outside_india === false) {
            setShowcitycntry(false)
        }
        if (currempdata?.salary_break_down === "Fixed+Variable") {
            setShowsalarybreak(true)
        }
        if (currempdata?.salary_break_down === "Fixed") {
            setShowsalarybreak(false)
        }
        if (empInfo?.type === "experienced") {
            setShowexp(true)
        }
        if (empInfo?.type === "fresher") {
            setShowexp(false)
        }
        setFormData({
            name: empInfo?.name || '',
            email: empInfo?.email || '',
            mobile: empInfo?.mobile || '',
            current_city: empInfo?.current_city || '',
            location: empInfo?.current_city || '',
            current_country: empInfo?.current_country || '',
            availability: "",
            totalExpYears: currempdata?.total_experience ? getYearMonth(currempdata?.total_experience, 'year') : '',
            totalExpMonths: currempdata?.total_experience ? getYearMonth(currempdata?.total_experience, 'month') : '',
            salary_type: currempdata?.salary_type || '',
            salaryType: currempdata?.salary_type || '',
            salary: currempdata?.salary || '',
            job_profile: currempdata?.job_profile || '',
            jobProfile: currempdata?.job_profile || '',
            notice_period: currempdata?.notice_period || '',
            noticePeriod: currempdata?.notice_period || '',
            // salary_break_down: currempdata?.notice_period || '',
            WorkStatus: empInfo?.type,
            employementId: currempdata?.id || '',
            profileId: currempdata?.profile_id || '',
            company_name: currempdata?.company_name || '',
            salary_break_down: currempdata?.salary_break_down || '',
            fixed_salary: currempdata?.fixed_salary || '',
            variable_salary: currempdata?.variable_salary || '',
            is_outside_india: empInfo?.is_outside_india || '',
            country_code: empInfo?.country_code || '',
            area_code: empInfo?.area_code || '',
            phone_no: empInfo?.phone_no || '',
            profile_id: currempdata?.profile_id || '',
            CurrentLocation: empInfo?.is_outside_india === true ? "OutsideIndia" : "currIndia" || '',
        })
    }, [showBasicDetailsModal])

    // const getSuggestions = value => {
    //     const inputValue = value.trim().toLowerCase();
    //     const inputLength = inputValue.length;
      
    //     return inputLength === 0 ? [] : languages.filter(lang =>
    //       lang.name.toLowerCase().slice(0, inputLength) === inputValue
    //     );
    //   };

  
    const [suggestions, setSuggestions] = useState([]);

    const onChange = (event, { newValue }) => {
        
        setValue(newValue);
        setFormData({ ...formData, current_city: newValue });
    };

    const onSuggestionsFetchRequested = async ({ value }) => {
        // console.log(LoadUserDetails)
        const fetchedSuggestions = await getSuggestions(value,isOutSideIndia);
        setSuggestions(fetchedSuggestions);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const inputProps = {
        placeholder: formData?.current_city,
        type: 'text',
        autoComplete: 'off',
        className:'form-control',
        value:formData.current_city,
        required:true,
        onChange
    };
     console.log(editmobile)
    return (
        <Modal show={showBasicDetailsModal} centered size='lg' onHide={closeBasicDetailsModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
            <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
                <div className="close-wrapper text-end">
                    <Button variant="link" onClick={closeBasicDetailsModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
                </div>
                <div className="heading-wrapper mb-4">
                    <div className='widgetTitle text-black fs-5 fw-semibold mb-1'>Basic details</div>
                    {/* <p className='subTitle fs-14'>Details like course, university, and more, help recruiters identify your educational background</p> */}
                </div>

                <Form action="POST" className="form-cover" onSubmit={basicdetailsdata}>
                    <Form.Group className="mb-4">
                        <Form.Label>Name<span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="text" name="name" value={formData.name} onChange={handleInputChange} placeholder='Enter Your Name' maxLength={35} autoComplete='off' required />
                    </Form.Group>

                    {currempdata?.job_title ?
                        <Form.Group className="mb-4">
                            <div className="wrapper mb-2">
                                <Form.Label className="mb-0">{currempdata?.job_title} at {currempdata?.company_name}</Form.Label>
                                <div className="text-clr2 fs-14">To edit go to Employment section. <br />Please remove your current employment if you want to mark yourself as fresher</div>
                            </div>
                        </Form.Group>
                        : ""}
                    <Form.Group className="mb-4">
                        <div className="wrapper mb-2">
                            <Form.Label className="mb-0">Work status</Form.Label>
                            <div className="text-clr2 fs-14">We will personalise your Naukri experience based on this</div>
                        </div>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="w-25 form-check form-check-inline d-flex align-items-center gap-2 ms-1">
                                <input type="radio" className="form-check-input fs-6 border border-secondary" id="Fresher" name="WorkStatus" value="fresher" checked={formData.WorkStatus === "fresher"} onChange={handleInputChange} />
                                <label className="form-check-label ms-1 pt-1" htmlFor="Fresher">Fresher</label>
                            </div>
                            <div className="w-25 form-check form-check-inline d-flex align-items-center gap-2 ms-1">
                                <input type="radio" className="form-check-input fs-6 border border-secondary" id="Experienced" name="WorkStatus" value="experienced" checked={formData.WorkStatus === "experienced"} onChange={handleInputChange} />
                                <label className="form-check-label ms-1 pt-1" htmlFor="Experienced">Experienced</label>
                            </div>
                        </div>
                        {showexperr ? <div className="fs-12 text-danger mt-1">Please remove your employment if you want to mark yourself as fresher</div> : ""}
                    </Form.Group>

                    {showexp ?
                        <Form.Group className="mb-4">
                            <div className="wrapper mb-2">
                                <Form.Label className="mb-0">Total experience<span className='text-danger'>*</span></Form.Label>
                                <div className="text-clr2 fs-14">This helps recruiters know your years of experience</div>
                            </div>
                            <div className="row g-2">
                                <div className="col-6">
                                    <Form.Select name="totalExpYears" value={formData.totalExpYears} onChange={handleInputChange} required>
                                        <option selected hidden value=''>Select Year</option>
                                        <option value="0 Year">0 Year</option>
                                        <option value="1 Year">1 Year</option>
                                        <option value="2 Years">2 Years</option>
                                        <option value="3 Years">3 Years</option>
                                        <option value="4 Years">4 Years</option>
                                        <option value="5 Years">5 Years</option>
                                        <option value="6 Years">6 Years</option>
                                        <option value="7 Years">7 Years</option>
                                        <option value="8 Years">8 Years</option>
                                        <option value="9 Years">9 Years</option>
                                        <option value="10 Years">10 Years</option>
                                        <option value="11 Years">11 Years</option>
                                        <option value="12 Years">12 Years</option>
                                        <option value="13 Years">13 Years</option>
                                        <option value="14 Years">14 Years</option>
                                        <option value="15 Years">15 Years</option>
                                        <option value="16 Years">16 Years</option>
                                        <option value="17 Years">17 Years</option>
                                        <option value="18 Years">18 Years</option>
                                        <option value="19 Years">19 Years</option>
                                        <option value="20 Years">20 Years</option>
                                        <option value="21 Years">21 Years</option>
                                        <option value="22 Years">22 Years</option>
                                        <option value="23 Years">23 Years</option>
                                        <option value="24 Years">24 Years</option>
                                        <option value="25 Years">25 Years</option>
                                        <option value="26 Years">26 Years</option>
                                        <option value="27 Years">27 Years</option>
                                        <option value="28 Years">28 Years</option>
                                        <option value="29 Years">29 Years</option>
                                        <option value="30 Years">30 Years</option>
                                        <option value="30+ Years">30+ Years</option>

                                    </Form.Select>
                                </div>
                                <div className="col-6">
                                    <Form.Select name="totalExpMonths" value={formData.totalExpMonths} onChange={handleInputChange} required>
                                        <option selected hidden value=''>Select Month</option>
                                        <option value="0 Month">0 Month</option>
                                        <option value="1 Month">1 Month</option>
                                        <option value="2 Months">2 Months</option>
                                        <option value="3 Months">3 Months</option>
                                        <option value="4 Months">4 Months</option>
                                        <option value="5 Months">5 Months</option>
                                        <option value="6 Months">6 Months</option>
                                        <option value="7 Months">7 Months</option>
                                        <option value="8 Months">8 Months</option>
                                        <option value="9 Months">9 Months</option>
                                        <option value="10 Months">10 Months</option>
                                        <option value="11 Months">11 Months</option>
                                    </Form.Select>
                                </div>
                            </div>
                        </Form.Group> : ""}

                    <Form.Group className="mb-4">
                        <Form.Label>Current salary<span className='text-danger'>*</span></Form.Label>
                        <div className='d-flex justify-content-between gap-2'>
                            <Form.Select name="salaryType" value={formData.salaryType} onChange={handleInputChange} className='shadow-none text-center' style={{ maxWidth: '75px' }}>
                                <option value="INR">₹</option>
                                <option value="USD">$</option>
                            </Form.Select>
                            <Form.Control type="text" name="salary" value={formData.salary} onChange={handleInputChange} placeholder="Eg. 4,50,000" defaultValue={'20,00,000'} maxlength="10" autoComplete='off' required />
                        </div>
                        {/* <div className="fs-12 text-success mt-1">Twenty lakh rupees</div> */}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label>Salary breakdown<span className='text-danger'>*</span></Form.Label>
                        <Form.Select name="salary_break_down" value={formData.salary_break_down} onChange={handleInputChange} required>
                            <option selected hidden value=''>Select</option>
                            <option value="Fixed">Fixed</option>
                            <option value="Fixed+Variable">Fixed + Variable</option>
                        </Form.Select>

                        {/* If selected Fixed */}
                        {!showsalarybreak ? <div className="fs-12 text-success mt-1">Your total salary has been considered as fixed component</div> : ""}
                    </Form.Group>

                    {/* salary_break_down */}
                    {showsalarybreak ?
                        <>
                            <Form.Group className="mb-4">
                                <Form.Label>Fixed salary<span className='text-danger'>*</span></Form.Label>
                                <div className='d-flex justify-content-between gap-2'>
                                    <Form.Select name="salaryType" value={formData.salaryType} onChange={handleInputChange} className='shadow-none text-center' style={{ maxWidth: '75px' }}>
                                        <option value="INR">₹</option>
                                        <option value="USD">$</option>
                                    </Form.Select>
                                    <Form.Control type="text" name="fixed_salary" value={formData.fixed_salary} onChange={handleInputChange} placeholder="Eg. 4,50,000" defaultValue={'20,00,000'} maxlength="10" autoComplete='off' required />
                                </div>
                                {incsalaryinfo ? <div className="fs-12 text-danger mt-1">Fixed + Variable can not be more than your total salary</div> : ""}
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>Variable salary<span className='text-danger'>*</span></Form.Label>
                                <div className='d-flex justify-content-between gap-2'>
                                    <Form.Select name="salaryType" value={formData.salaryType} onChange={handleInputChange} className='shadow-none text-center' style={{ maxWidth: '75px' }}>
                                        <option value="INR">₹</option>
                                        <option value="USD">$</option>
                                    </Form.Select>
                                    <Form.Control type="text" name="variable_salary" value={formData.variable_salary} onChange={handleInputChange} placeholder="Eg. 4,50,000" defaultValue={'20,00,000'} maxlength="10" autoComplete='off' required />
                                </div>
                                {incsalaryinfo ? <div className="fs-12 text-danger mt-1">Fixed + Variable can not be more than your total salary</div> : ""}
                            </Form.Group>
                        </> : ""}

                    {/* salary_break_down */}

                    <Form.Group className="mb-4">
                        <div className="wrapper mb-2">
                            <Form.Label className="mb-0">Current location<span className='text-danger'>*</span></Form.Label>
                            <div className="text-clr2 fs-14">This helps us match you to relevant jobs</div>
                        </div>
                        <div className="d-flex flex-wrap gap-2 mb-2 pb-1">
                            <div className="w-25 form-check form-check-inline d-flex align-items-center gap-2 ms-1">
                                <input type="radio" className="form-check-input fs-6 border border-secondary" id="India" name="CurrentLocation" value="currIndia" checked={formData.CurrentLocation === "currIndia"} onChange={handleInputChange} />
                                <label className="form-check-label ms-1 pt-1" htmlFor="India">India</label>
                            </div>
                            <div className="w-25 form-check form-check-inline d-flex align-items-center gap-2 ms-1">
                                <input type="radio" className="form-check-input fs-6 border border-secondary" id="OutsideIndia" name="CurrentLocation" value="OutsideIndia" checked={formData.CurrentLocation === "OutsideIndia"} onChange={handleInputChange} />
                                <label className="form-check-label ms-1 pt-1" htmlFor="OutsideIndia">Outside India</label>
                            </div>
                        </div>

                        <div className="row g-3">
                          
                                <div className="col-lg-6">
                                     {/* <Form.Control type="text" name="current_city" value={formData.current_city} onChange={handleInputChange} placeholder='Tell us about your Current Location' defaultValue={'Lucknow'} maxLength={250} autoComplete='off' /> */}
                                        <Autosuggest
                                        
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps}
                                          
                                    />
                                </div>
                              
                            {/* Show this field too if choosen outside india */}
                            {/* {showcitycntry ?
                                <>
                                   
                                    <div className="col-lg-6">
                                        <Form.Select name="current_country" value={formData.current_country} onChange={handleInputChange} required>
                                            <option selected hidden value=''>Select country</option>
                                            {countryList.map((item)=>(
                                            <option value={item.name}>{item.name}</option>

                                            ))}
                                            
                                        </Form.Select>
                                    </div>
                                     <div className="col-lg-6">
                                        <Form.Control type="text" name="current_city" value={formData.current_city} onChange={handleInputChange} placeholder='Tell us about your Current Location' defaultValue={'Lucknow'} maxLength={250} autoComplete='off' />
                                    </div>
                                </> : ""} */}
                        </div>

                    </Form.Group>

                    <Form.Group className="mb-4">
                        <div className="wrapper mb-2">
                            <Form.Label className="mb-0">Mobile number<span className='text-danger'>*</span></Form.Label>
                            <div className="text-clr2 fs-14">Recruiters will contact you on this number</div>
                            <div className="text-clr2 fs-14 d-flex align-items-center gap-2 mt-1">
                                <span className='fw-medium'>{empInfo?.mobile}</span>
                                {empInfo?.is_mobile_verified?
                                <Button variant='link' size='sm' className='text-primary fw-semibold text-decoration-none border-0 p-0' onClick={(e)=>{openEditModal(empInfo?.mobile,"changeMobile")}}>Change Mobile Number</Button>
                                :
                                <Button variant='link' size='sm' className='text-primary fw-semibold text-decoration-none border-0 p-0' onClick={(e)=>{openEditModal(empInfo?.mobile,"verifyMobile")}}>Verify</Button>
                                }
                            </div>
                        </div>
                    </Form.Group>

                    {/* Start: Show this field too if choosen outside india */}
                    {showcitycntry ?
                        <Form.Group className="mb-4">
                            <Form.Label>Telephone number</Form.Label>
                            <div className="row g-2">
                                <div className="col-6 col-lg-3">
                                    <Form.Control type="tel" name="country_code" value={formData.country_code} onChange={handleInputChange} placeholder='Country code' autoComplete='off' />
                                </div>
                                <div className="col-6 col-lg-3">
                                    <Form.Control type="tel" name="area_code" value={formData.area_code} onChange={handleInputChange} placeholder='Area code' maxLength={10} autoComplete='off'  />
                                </div>
                                <div className="col-lg-6">
                                    <Form.Control type="tel" name="phone_no" value={formData.phone_no} onChange={handleInputChange} placeholder='Phone number' maxLength={15} autoComplete='off'  />
                                </div>
                            </div>
                        </Form.Group> : ""}
                    {/* End: Show this field too if choosen outside india */}

                    <Form.Group className="mb-4">
                        <div className="wrapper mb-2">
                            <Form.Label className="mb-0">Email address<span className='text-danger'>*</span></Form.Label>
                            <div className="text-clr2 fs-14">We will send relevant jobs and updates to this email</div>
                            <div className="text-clr2 fs-14 d-flex align-items-center gap-2 mt-1">
                                <span className='fw-medium'>{empInfo?.email}</span>
                                {empInfo?.is_email_verified?
                                <Button variant='link' size='sm' className='text-primary fw-semibold text-decoration-none border-0 p-0' onClick={()=>{openEditEmailModal(empInfo?.email, "changeEmail")}}>Change Email</Button>
                                :
                                <Button variant='link' size='sm' className='text-primary fw-semibold text-decoration-none border-0 p-0' onClick={()=>{openEditEmailModal(empInfo?.email,"verifyEmail")}}>Verify Email</Button>
                                }
                            </div>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <div className="wrapper mb-2">
                            <Form.Label className="mb-0">Notice period<span className='text-danger'>*</span></Form.Label>
                            <div className="text-clr2 fs-14">Lets recruiters know your availability to join</div>
                        </div>
                        <div className="radio-wrapper d-flex flex-wrap gap-2">
                            <div className="item">
                                <input type="radio" class="btn-check" name="noticePeriod" value="15 Days or less" checked={formData.noticePeriod === "15 Days or less"} onChange={handleInputChange} id="15 Days or less" autocomplete="off" required />
                                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="15 Days or less">15 Days or less</label>
                            </div>

                            <div className="item">
                                <input type="radio" class="btn-check" name="noticePeriod" value="1 Month" checked={formData.noticePeriod === "1 Month"} onChange={handleInputChange} id="1 Month" autocomplete="off" />
                                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="1 Month">1 Month</label>
                            </div>

                            <div className="item">
                                <input type="radio" class="btn-check" name="noticePeriod" value="2 Months" checked={formData.noticePeriod === "2 Months"} onChange={handleInputChange} id="2 Months" autocomplete="off" />
                                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="2 Months">2 Months</label>
                            </div>

                            <div className="item">
                                <input type="radio" class="btn-check" name="noticePeriod" value="3 Months" checked={formData.noticePeriod === "3 Months"} onChange={handleInputChange} id="3 Months" autocomplete="off" />
                                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="3 Months">3 Months</label>
                            </div>

                            <div className="item">
                                <input type="radio" class="btn-check" name="noticePeriod" value="More than 3 Months" checked={formData.noticePeriod === "More than 3 Months"} onChange={handleInputChange} id="More than 3 Months" autocomplete="off" />
                                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="More than 3 Months">More than 3 Months</label>
                            </div>

                            <div className="item">
                                <input type="radio" class="btn-check" name="noticePeriod" value="Serving Notice Period" checked={formData.noticePeriod === "Serving Notice Period"} onChange={handleInputChange} id="Serving Notice Period" autocomplete="off" />
                                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Serving Notice Period">Serving Notice Period</label>
                            </div>
                        </div>
                    </Form.Group>

                    {/* Start: If Choosen Serving Notice Period option */}
                    {/* <Form.Group className="mb-4">
                        <Form.Label>Expected last working day<span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="date" name="lastworking" value={formData.lastworking} onChange={handleInputChange} autoComplete='off' required />
                    </Form.Group> */}
                    {/* End: If Choosen Serving Notice Period option */}

                    <div className="btn-wrapper d-flex flex-wrap gap-2 pt-2">
                        <Button variant="danger" onClick={closeBasicDetailsModal} className='rounded-pill px-4'>Cancel</Button>
                        <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
                    </div>
                </Form>
                {/* EditModal */}
                    <Changemobilenumber editmobile={editmobile} closeEditModal={closeEditModal} profileData={LoadUserDetails} mobileNumber={mobileNumber} eventType={eventType} setIsLoading={setIsLoading}  setProfileData={setProfileData} setShowBasicDetailsModal={setShowBasicDetailsModal} setEditMobile={setEditMobile} setMobileNumber_={setMobileNumber}/>
                <Changeemail editemail={editemail} closeEditEmailModal={closeEditEmailModal} profileData={LoadUserDetails} email={email} eventType={eventType} setIsLoading={setIsLoading}  setProfileData={setProfileData} setShowBasicDetailsModal={setShowBasicDetailsModal} setEditEmail={setEditEmail} setEmail_={setEmail}/> 
                {/* EditModal */}
            </Modal.Body>
        </Modal>
    )
}
