import React, { useEffect, useRef, useState } from "react";
import "../../LeadsListingPage/LeadsListingPage.css";
import $ from "jquery";
import { callAxios } from "../../utils/utils";
import { environment } from "../../environment";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const CallFeedBack = ({ showFeedback }) => {
  // console.log("FeedbackForm:::",callInfo)
  const [show, setShow] = useState(showFeedback);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const closePreview = () => {
  //   document.getElementById("CallFeedbackForm").style.display = "none";
  //   setCallBack(false);
  // };

  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(true)
  const FeedbackSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)

    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/call-feature-feedback", { feedback: description }, "post").then(async (response) => {
      const systemuserid = response.data.systemuserid
      if (response.status === 200) {
        setShow(false)

        toast.success("Your feedback shared!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });


      } else {
        toast.error("network problem occured!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

      }
    })
  }
  useEffect(() => {
    if (description && description.length > 0) {
      $("#feedback-pstn").prop('disabled', false)
    } else {
      $("#feedback-pstn").prop('disabled', true)
    }
  }, [description])

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Body>
          <form id="callfeedbackShare" className="d-flex flex-column overflow-hidden" method="post" onSubmit={FeedbackSubmit} >
            <div className="modal-header bg-primary text-white px-lg-4 py-2">
              <div>

              </div>
              {isLoading ?
                <div className="meeting-pointer-spinner"
                  style={{ marginRight: "10px" }}
                ></div>
                : null}

              <button type="submit" id="feedback-pstn" className="btn btn-success border-white" style={{ minWidth: "80px" }} disabled={isDisabled} >
                Share Feedback
              </button>
            </div>
            <div className="modal-body px-lg-4 py-4 small">
              <div className="mb-3">
                <label htmlFor="noting" className="form-label text-muted">
                  Share Your Experience/Feedback on the New Call Feature from NM-Live to Phone
                </label>
              </div>
              <div className="mb-3" id="call-description">
                <textarea className="form-control" rows="4" placeholder="" required
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDisabled(false)
                  }}
                ></textarea>
              </div>
            </div>
          </form>

          {/* <Button variant="secondary" className="" onClick={handleClose} id="beforeCallingCancel">
            Close
          </Button> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CallFeedBack;
