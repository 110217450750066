import React, { useEffect, useState } from "react";
import { app, database, auth } from "../firebaseConfig";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  orderBy,
  updateDoc,
  limit,
  serverTimestamp,
} from "firebase/firestore";

import { connect, useSelector } from "react-redux";

//const qs = require('qs');
import {
  setIdentity,
  setRoomId,
  setFormId,
  setUserInfo,
} from "../store/actions";

import { useNavigate } from "react-router-dom";


import { useAuthState } from "react-firebase-hooks/auth";

import { callAxios } from "../utils/utils";


// env import
import { environment } from '../environment';

const MyActiveCalls = (props) => {
  const {
    setRoomIdAction,
    setIdentityAction,
    roomId,
    setFormIdAction,
    setLoggedinUser
  } = props;
  const [user, loading, error] = useAuthState(auth);

  let navigate = useNavigate();

    const [meetingDetails, setMeetingDetails] = useState([]);

    const [roomStatus, setRoomStatus] = useState({});

    const [userRole, setUserRole] = useState({});

 
    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
                    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };

          setLoggedinUser(userInfo);
         
        }
      }, [user, loading]);
    

    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);

    const queryConstraints = [];
    queryConstraints.push(where('timeStamp', '>=', startOfToday));
    queryConstraints.push(where('timeStamp', '<=', endOfToday));
    //queryConstraints.push(where('email', '=', user?.emal));
    queryConstraints.push(orderBy('timeStamp', 'desc'));
    queryConstraints.push(limit(1));

    const q = query(collection(database, environment.REACT_APP_FIREBASE_DB), ...queryConstraints);

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
        
    }
  }, [user, loading]);
  //console.log("last meeting::", meetingDetails)
  //Update isTest in Firebase ..
 

  //console.log("userINFO::", userInfo);
  useEffect(() => {
    onSnapshot(q, (querySnapshot) => {
        let lastCall = [];
        querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            data["id"] = doc.id;
            //console.log(data.attendantEmail);
            if(userRole?.userType === "BDM"){
              if (data.attendantBdmEmail == user?.email) lastCall.push({ ...doc.data(), id: doc.id });
            }

            if(userRole?.userType === "CAM"){
              if (data.attendantEmail == user?.email) lastCall.push({ ...doc.data(), id: doc.id });
            }

           
            

        });
        setMeetingDetails(lastCall);
    });
     //if(meetingDetails[0]){
        const getRoomDetails = ()=> {
            callAxios(environment.REACT_APP_API_URL_Live+'api/v1/twilio/check-room-status',{roomId:meetingDetails[0]?.roomId}, "post" ).then((roomInfo)=>{
                setRoomStatus(roomInfo);
            })
        }
        getRoomDetails();
     //}
   

    const getUserRole = async () => {
        if (user) {
          const q = query(
            collection(database, environment.REACT_APP_USERS_DB),
            where("email", "==", user?.email),
            orderBy("timeStamp","asc"),
            limit(1)
          );
          onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const id = doc.id;
              const data = doc.data();
              data["id"] = doc.id;
              setUserRole(data);
            });
          });
        }
      };
      getUserRole();
  },[roomStatus?.data]);
    
  let meetingHtml;

 

  const handleRejoin = async(dataId, identity, roomId) =>{
    setIdentityAction(identity);
    setRoomIdAction(roomId);
    setFormIdAction(dataId);
    if(userRole?.userType === "CAM"){
        navigate("/room", { state: { id: 0, camera: true, isMicMuted:false}});
    }

    if(userRole?.userType === "BDM"){
        navigate("/bdmroom", { state: { id: 0, camera: true, isMicMuted:false}});
    }
      
  }
 
  if (meetingDetails.length > 0 && roomStatus && roomStatus?.data?.status === "in-progress" && typeof roomStatus?.data?.status !== 'undefined') {
      meetingHtml = meetingDetails.map((meeting, idx) => { 
          const fireBaseTime = new Date(
              meeting?.timeStamp?.seconds * 1000 + meeting?.timeStamp?.nanoseconds / 1000000
            );
      
          const meetingDate = fireBaseTime.getDate() + ' '+ fireBaseTime.toLocaleString('default', { month: 'short' }) +' '+fireBaseTime.getFullYear();
          
          const timeFrom = new Date(
              meeting?.timeStamp?.seconds * 1000 + meeting?.timeStamp?.nanoseconds / 1000000
            );
      
          const atTimeFrom = timeFrom.toLocaleTimeString();
      
          const timeTo = new Date(
              meeting?.timeStamp?.seconds * 1000 + meeting?.timeStamp?.nanoseconds / 1000000
            );
      
          const atTimeTo = timeTo.toLocaleTimeString();

          return (
              <div className="list-item-wrapper small border-5 border-top border-success p-3 mb-3" key={idx}>
                  <div className="row">
                      <div className="col-sm-5">
                          <div className="client-name text-capitalize mb-1">{meeting.name}</div>
                          <div className="d-flex align-items-center total-participants active text-capitalize mb-1">
                              <i className="bi bi-people-fill me-2 fs-5 lh-1"></i>Total Participants {meeting.countParticipants}
                          </div>
                      </div>
                      <div className="col-sm-7 mt-1 mt-sm-0">
                          <div className='attn-date d-flex gap-2 mb-1'>
                              <div className='title-label'>Meeting Date :</div>
                              <div className='title-value'><i className="bi bi-calendar-check-fill me-2"></i>{meetingDate}</div>
                          </div>
                          {/* <div className='attn-login d-flex gap-2'>
                              <div className='title-label'>Calling Time :</div>
                              <div className='title-value'><i className="bi bi-clock-fill me-2"></i>{atTimeFrom} - {atTimeTo}</div>
                          </div> */}
                      </div>
                  </div>

                  {user.userType === "CAM" && meeting.attendantBdmName ?
                      <div className='title-label bdm-name w-100 mt-1'><span className='text-warning me-2'>BDM Name :</span> {meeting.attendantBdmName}</div>
                  : null}

                  {user.userType === "BDM" && meeting.attendantName ?
                      <div className='title-label bdm-name'><span className='text-warning me-2'>CAM Name :</span> {meeting.attendantName}</div>
                  : null}

                  <hr className='my-2' />

                  <div className="col-md-12">
                      {meeting.description ?
                          <div className="requrement-label text-white text-opacity-75 mb-1">Requirement Details</div>
                      : null}
                      {meeting.description ? null :
                          <div className="requrement-label text-danger mb-1">Client didn't fill the requirement yet</div>
                      }
                      {meeting.description ?
                          <div className="client-requrement">{meeting.description}</div>
                      : null}

                      <div className='btn-wrapper mt-2'>
                          <button className='btn btn-success px-3' onClick={() => handleRejoin(meeting.id, "_"+userRole?.userType+"_"+user?.displayName, meeting.roomId)}>Rejoin Call</button>
                      </div>
                  </div>
              </div>
          )
         
          
      }
      );
  } else {
      meetingHtml = 'No active call for rejoin yet!';
  }

  const formatAMPM = (date) => {
      //console.log("LOCAL TIME:",date)
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
  }

  return (
      <div className='active-meetings-page py-3'>
          <div className='container'>
              <div className="main-heading text-white text-opacity-75 mb-3">My Active Calls</div>
              <div className='call-history-section live-listing-wrapper'>
                  {meetingHtml}
              </div>
          </div>
      </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (id) => dispatch(setRoomId(id)),
    setFormIdAction: (id) => dispatch(setFormId(id)),
    setLoggedinUser: (user) => dispatch(setUserInfo(user)),
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(MyActiveCalls);