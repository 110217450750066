import { Rating } from 'react-simple-star-rating';
import { convertReadable, convertReadableTime } from '../../utils/utils';

const StatusCard = ({ requisitionStatus, cardType, candidateInfo, userType }) => {
	// console.log(candidateInfo)
	// const intTime = parseInt(requisitionStatus.interview_time)> 12 && parseInt(requisitionStatus.interview_time) < 24 ? requisitionStatus.interview_time + " PM" : requisitionStatus.interview_time + " AM";
	if(userType === "SA" || userType === "SSA"){
		return (
			<div className="info-cards int-status small rounded pointer overflow-auto mt-2">
				<div className="info-header fs-6 text-white text-capitalize text-end px-3 py-2">{"Status: " + requisitionStatus.status}</div>
				<div className="info-body p-3">
					<div className="row g-3 mb-3">
						<div className="col-sm-6">
							<div className="cand-name text-primary fs-6 fw-medium text-capitalize">{requisitionStatus.name}</div>
							<div className="cand-role text-muted text-capitalize">{requisitionStatus.display_name}</div>
							{candidateInfo ?
								<div className="cand-company text-black text-capitalize">{candidateInfo.current_company}</div>
								: null}
						</div>
						<div className="col-sm-6 text-xl-end">
							<div className="cand-date text-muted text-capitalize">{convertReadable(requisitionStatus.createdAt) + ' ' + convertReadableTime(requisitionStatus.createdAt)}</div>
							{candidateInfo ?
								<div className="cand-status fw-medium text-danger text-capitalize">{candidateInfo.contact_no}</div>
								: null}
						</div>
					</div>
	
					<div className="row g-3">
						{requisitionStatus.email ?
							<div className="col-sm-6 col-xl-3">
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
									<div className="label-title text-black">Email:</div>
									<div className="label-value text-danger">{requisitionStatus.email} </div>
								</div>
								{candidateInfo ?
									<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
										<div className="label-title text-black">Job Status:</div>
	
										<div className="label-value text-danger">{candidateInfo.job_status} </div>
	
									</div>
									: null}
	
							</div>
							: null}
						{requisitionStatus.note ?
							<div className="col-sm-6 col-xl-5">
	
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
									<div className="label-title text-black">Note:</div>
									<div className="label-value text-danger">{requisitionStatus.note}</div>
								</div>
							</div>
							: null}
						{requisitionStatus.nextStep ?
							<div className="col-sm-6 col-xl-2">
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
									<div className="label-title text-black">Next Step:</div>
									<div className="label-value text-danger">{requisitionStatus.nextStep}</div>
								</div>
							</div>
							: null}
						{requisitionStatus.addedBy ?
							<div className="col-sm-6 col-xl-2">
								<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
									<div className="label-title text-black">Added By:</div>
									<div className="label-value text-danger">{requisitionStatus.addedBy}</div>
								</div>
							</div>
							: null}
					</div>
				</div>
			</div>
		)
	} else{
		if(!requisitionStatus.addedByEmail.endsWith("@gmail.com")){
			return (
				<div className="info-cards int-status small rounded pointer overflow-auto mt-2">
					<div className="info-header fs-6 text-white text-capitalize text-end px-3 py-2">{"Status: " + requisitionStatus.status}</div>
					<div className="info-body p-3">
						<div className="row g-3 mb-3">
							<div className="col-sm-6">
								<div className="cand-name text-primary fs-6 fw-medium text-capitalize">{requisitionStatus.name}</div>
								<div className="cand-role text-muted text-capitalize">{requisitionStatus.display_name}</div>
								{candidateInfo ?
									<div className="cand-company text-black text-capitalize">{candidateInfo.current_company}</div>
									: null}
							</div>
							<div className="col-sm-6 text-xl-end">
								<div className="cand-date text-muted text-capitalize">{convertReadable(requisitionStatus.createdAt) + ' ' + convertReadableTime(requisitionStatus.createdAt)}</div>
								{candidateInfo ?
									<div className="cand-status fw-medium text-danger text-capitalize">{candidateInfo.contact_no}</div>
									: null}
							</div>
						</div>
		
						<div className="row g-3">
							{requisitionStatus.email ?
								<div className="col-sm-6 col-xl-3">
									<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
										<div className="label-title text-black">Email:</div>
										<div className="label-value text-danger">{requisitionStatus.email} </div>
									</div>
									{candidateInfo ?
										<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
											<div className="label-title text-black">Job Status:</div>
		
											<div className="label-value text-danger">{candidateInfo.job_status} </div>
		
										</div>
										: null}
		
								</div>
								: null}
							{requisitionStatus.note ?
								<div className="col-sm-6 col-xl-5">
		
									<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
										<div className="label-title text-black">Note:</div>
										<div className="label-value text-danger">{requisitionStatus.note}</div>
									</div>
								</div>
								: null}
							{requisitionStatus.nextStep ?
								<div className="col-sm-6 col-xl-2">
									<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
										<div className="label-title text-black">Next Step:</div>
										<div className="label-value text-danger">{requisitionStatus.nextStep}</div>
									</div>
								</div>
								: null}
							{requisitionStatus.addedBy ?
								<div className="col-sm-6 col-xl-2">
									<div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
										<div className="label-title text-black">Added By:</div>
										<div className="label-value text-danger">{requisitionStatus.addedBy}</div>
									</div>
								</div>
								: null}
						</div>
					</div>
				</div>
			)
		}
	}
	
}

export default StatusCard;