import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';

const HrListing = ({ showModal, setShowModal, whoWorkingonthis, userType }) => {
    // console.log(whoWorkingonthis)
    const [isEditMessage, setIsEditMessage] = useState(true);
    const closeEditMessage = () => setIsEditMessage(false);
    // For Discard model
    const [modalShow, setShowModal1] = useState(showModal);
    const handleClose = () => {
        setShowModal(false)
        setIsEditMessage(false)
    };

    const handlehideshow = () => {
        setShowModal(false);
        setShowModal1(false)
    }
    let inHR = [];
    let outHR = [];

    whoWorkingonthis.forEach((hr)=>{
        if (hr.veuser.hrType == 1) {
            inHR.push(hr)
        } else {
            outHR.push(hr)
        }
    })
    let inhouse;
    if(inHR.length >0){
        inhouse = inHR.map((item, index) => {
            if (item.veuser.hrType == 1) {
                return (
                    <tr>
                        <td>{index + 1}</td>
                        <td><img src={item.veuser.profile_image ? item.veuser.profile_image : require("../../resources/images/Avatar.png")} className="border rounded-circle" style={{ width: "45px", height:"45px" }} /></td>
                        <td>{item.veuser.name}</td>
                        <td>{item.veuser.email}</td>
                    </tr>
                )
            }
    
        })
    }
    else{
        inhouse = 
                <tr>
                    <td colSpan={4}>No record found!!</td>
                 
                </tr>
                
    }
   
    let outhouse;
    if(outHR.length > 0){
        outhouse = outHR.map((item, index) => {
            if (item.veuser.hrType == 2) {
                return (
                    <tr>
                        <td>{index + 1}</td>
                        <td><img src={item.veuser.profile_image ? item.veuser.profile_image : require("../../resources/images/Avatar.png")} className="border rounded-circle" style={{ width: "45px", height:"45px" }} /></td>
                        <td>{item.veuser.name}</td>
                        <td>{item.veuser.email}</td>
                    </tr>
                )
            }
    
        })
    } else {
        outhouse = 
                <tr>
                    <td colSpan={4}>No record found!!</td>
                 
                </tr>
    }
    

    return (
        <>
            { userType === "SSA" || userType === "SA"?
            <Modal show={modalShow} onHide={() => setShowModal(true)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="shadow p-2" dialogClassName="p-4">
                <Modal.Body className="p-4">
                    <Tabs
                        defaultActiveKey="in"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="in" title="In House HR">

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Profile Image</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inhouse}
                                </tbody>
                            </Table>

                        </Tab>
                        <Tab eventKey="out" title="Out House HR">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Profile Image</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {outhouse}
                                </tbody>
                            </Table>
                        </Tab>

                    </Tabs>

                    <Button variant='danger' onClick={handlehideshow} className='px-3'>Cancel</Button>


                </Modal.Body>
            </Modal>
            :
            <Modal show={modalShow} onHide={() => setShowModal(true)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="shadow p-2" dialogClassName="p-4">
                <Modal.Body className="p-4">
                    <Tabs
                        defaultActiveKey="in"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="in" title="Other Working HR">

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Profile Image</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inhouse}
                                </tbody>
                            </Table>

                        </Tab>

                    </Tabs>

                    <Button variant='danger' onClick={handlehideshow} className='px-3'>Cancel</Button>


                </Modal.Body>
            </Modal>
            }
        </>
    )
}

export default HrListing;