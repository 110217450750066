import Actions from "./actions";

const initState = {
  identity: "",
  isRoomHost: false,
  connectOnlyWithAudio: false,
  roomId: null,
  twilioAccessToken: null,
  showOverlay: true,
  participants: [],
  messages: [],
  isLoggedIn: false,
  isAdmin: false,
  formId: null,
  userInfo: null,
  clientInfo: null,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case Actions.SET_IDENTITY:
      return {
        ...state,
        identity: action.identity,
      };
    case Actions.SET_IS_ROOM_HOST:
      return {
        ...state,
        isRoomHost: action.isRoomHost,
      };
    case Actions.SET_CONNECT_ONLY_WITH_AUDIO:
      return {
        ...state,
        connectOnlyWithAudio: action.onlyWithAudio,
      };
    case Actions.SET_ROOM_ID:
      return {
        ...state,
        roomId: action.roomId,
      };
    case Actions.SET_FORM_ID:
      return {
        ...state,
        formId: action.formId,
      };

    case Actions.SET_TWILIO_ACCESS_TOKEN:
      return {
        ...state,
        twilioAccessToken: action.token,
      };
    case Actions.SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.participants,
      };
    case Actions.SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };
    case Actions.SET_SHOW_OVERLAY:
      return {
        ...state,
        showOverlay: action.showOverlay,
      };
    case Actions.SET_ADMIN_CALL:
      return {
        ...state,
        isAdmin: action.isAdmin,
      };

    case Actions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };

    case Actions.SET_USER:
      return {
        ...state,
        userInfo: action.userInfo,
      };

    case Actions.SET_BGIMAGE:
      return {
        ...state,
        bgImage: action.bgImage,
      };

    case Actions.SET_BGIMAGE_PREV:
      return {
        ...state,
        bgImagePrev: action.bgImagePrev,
      };

    case Actions.IS_REMOVED_BACKGROUND:
      return {
        ...state,
        isRemoved: action.isRemoved,
      };

    case Actions.USER_BREAK:
      return {
        ...state,
        userBreaks: action.userBreaks,
      };

    case Actions.SET_CLIENTDATA:
      return {
        ...state,
        clientInfo: action.clientInfo,
      };
    case Actions.SET_ROOM_SID:
      return {
        ...state,
        roomSid: action.roomSid,
      };
    case Actions.SET_IS_CLIENT_ON_CALL:
      return {
        ...state,
        clientOnCall: action.clientOnCall,
      };
      case Actions.SET_USER_ROLE:
        return {
          ...state,
          userRole: action.userRole,
        };

        case Actions.SET_TOKEN:
        return {
          ...state,
          token: action.token,
        };

        case Actions.SET_PARTICIPANTS_LIST:
          return {
            ...state,
            participant: action.participant,
          };
    default:
      return state;
  }
};

export default reducer;
