import { addDoc, collection, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { database } from "../../firebaseConfig";
import { environment } from "../../environment";
import { ToastContainer, toast } from "react-toastify";

export function FormTodo({ memberType, user, userRole }) {
	// console.log(user,userRole,memberType)

	let navigate = useNavigate();
	const { state } = useLocation();
	const [emailVal, setEmail] = useState("");

	const handleSubmitToUsers = async (e) => {

		const email = emailVal
		const role = memberType;
		//console.log(email,role)
		e.preventDefault();
		if (!email || !role) return;
		//console.log("saving data:",email,role)
		const dbRef = collection(database, environment.REACT_APP_USERS_DB);
		const user = {
			email: email,
			userType: role,
			timeStamp: serverTimestamp(),
			isBlocked: 0,
			isDeleted: 0,
			type: role
		};
		const checkIsUserExists = query(dbRef,
			where("email", "==", email),
			where("type", "==", role)
		);
		getDocs(checkIsUserExists)
			.then(function (querySnapshot) {
				if (!querySnapshot.empty) {
					toast.error("Sorry, User already exists with email: " + email, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "dark",
					});
				}
				else {
					addDoc(dbRef, user)
						.then(docRef => {
							toast.success("Member added with email: " + email, {
								position: "top-right",
								autoClose: 3000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "dark",
							});
							//console.log("Document has been added successfully", docRef); 
							setEmail("");
							document.getElementById("closeModal").click();
						})
						.catch(error => {
							toast.error("Error while adding member!", {
								position: "top-right",
								autoClose: 3000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "dark",
							});
							console.log(error);
							setEmail("");
							alert("Error while adding record!")
							document.getElementById("closeModal").click();
						})
				}
			});
	};

	const handleDeletedUser = (e) => {
		e.preventDefault();
		// console.log("indelete users",memberType,userRole)
		navigate('/deleted-member', { state: { userRole: userRole, memberType: memberType } });
	}

	const handleBlockedUser = (e) => {
		e.preventDefault();
		// console.log("inBlock users")
		navigate('/blocked-member', { state: { userRole: userRole, memberType: memberType } });
	}


	return (
		<>
			{/* <ToastContainer /> */}
			<div className='text-end mb-3'>
				<button type="button" className="btn btn-warning rounded-1 " onClick={handleBlockedUser}  >
					<i className="bi bi-person-lock"></i> Blocked Members
				</button>
				<button type="button" className="btn btn-danger rounded-1 ms-2" onClick={handleDeletedUser}>
					<i className="bi bi-person-delete"></i> Deleted Members
				</button>
				<button type="button" className="btn btn-primary rounded-1 ms-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
					<i className="bi bi-person-plus"></i> Add Member
				</button>
			</div>

			{/* Add Member Modal */}
			<div className="modal fade" id="exampleModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
					<div className="modal-content">
						<div>
							<div className="modal-header bg-primary text-white">
								<h1 className="modal-title fs-6" id="exampleModalLabel">Add Member Email</h1>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ filter: 'invert(1)' }}></button>
							</div>
							<div className="modal-body py-4">
								<input type="email" name="userEmail" id="userEmail" value={emailVal} className="form-control rounded-1" onChange={e => setEmail(e.target.value)} placeholder="Enter member email" required />
							</div>
							<div className="modal-footer border-0 pt-0">
								<button type="button" id="closeModal" className="btn btn-secondary rounded-1 py-2 px-4" data-bs-dismiss="modal">Cancel</button>
								<button type="submit" className="btn btn-primary rounded-1 py-2 px-4" onClick={handleSubmitToUsers}>Save</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <form className="mb-3" onSubmit={handleSubmit}>
				<label htmlFor="" className="form-label">Add Todo</label>
				<div className="input-group">
					<input type="text" name="" id="" className="form-control" value={value} onChange={e => setValue(e.target.value)} placeholder="Add member email" aria-describedby="helpId" />
					<button className="btn btn-primary px-3" type="submit"><i className="bi bi-person-plus"></i> Add Member</button>
				</div>
			</form> */}
		</>
	);
}