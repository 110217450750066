import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { callAxios, getProfileData } from '../../utils/utils';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
import { Confirm } from './Confirm';

export const WorkSample = ({ showWorkSampleModal, closeWorkSampleModal, setProfileData, profileId, isUpdateAction, updateData, setShowWorkSampleModal, setIsLoading }) => {
    // console.log(isUpdateAction,updateData)
    const [workTitle, setWorkTitle] = useState("");
	const [url, setUrl] = useState("");
    const [durationfromYear, setDurationfromYear] = useState("");
    const [durationfromMonth, setDurationfromMonth] = useState("");
    const [durationtoYear, setDurationtoYear] = useState("");
    const [durationtoMonth, setDurationtoMonth] = useState("");
    const [currentlyWorking, setIsCurrently] = useState(false);
	const [description, setDescription] = useState("");


	useEffect(() => {
		if (isUpdateAction) {
            if(updateData?.is_currently_working_this){
                const duration_from = updateData?.duration_from;
                setWorkTitle(updateData?.work_title);
                setUrl(updateData?.url);
                setDurationfromYear(duration_from.split(" ")[1])
                setDurationfromMonth(duration_from.split(" ")[0])
                setDurationtoYear()
                setDurationtoMonth()
                setIsCurrently(updateData?.is_currently_working_this)
                setDescription(updateData?.description)
            }else{
                const duration_from = updateData?.duration_from;
                const duration_to = updateData?.duration_to;
                setWorkTitle(updateData?.work_title);
                setUrl(updateData?.url);
                setDurationfromYear(duration_from.split(" ")[1])
                setDurationfromMonth(duration_from.split(" ")[0])
                setDurationtoYear(duration_to.split(" ")[1])
                setDurationtoMonth(duration_to.split(" ")[0])
                setIsCurrently(updateData?.is_currently_working_this)
                setDescription(updateData?.description)
            }
           

		} else {
			setWorkTitle("");
			setUrl("");
			setDurationfromYear("")
            setDurationfromMonth("")
            setDurationtoYear("")
            setDurationtoMonth("")
            setIsCurrently(false)
            setDescription("")
		}
	}, [updateData]);

    const saveWorkSamples = async (e) => {
		e.preventDefault();
		setIsLoading(true)
        setShowWorkSampleModal(false);
		// console.log(socialProfileName, socialUrl, socialDescription)
		if (isUpdateAction) {
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-work-sample", { profileId: profileId, workTitle: workTitle, url: url, durationFrom: durationfromMonth+' '+durationfromYear, durationTo:durationtoMonth+' '+durationtoYear, isCurrentlyWorkingThis:currentlyWorking, description: description, type: "update", id: updateData?.id }, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
                    setProfileData(await getProfileData(profileId,setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
					
				} else {
                    setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		} else {
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/add-work-sample", { profileId: profileId, workTitle: workTitle, url: url, durationFrom: durationfromMonth+' '+durationfromYear, durationTo:durationtoMonth+' '+durationtoYear, isCurrentlyWorkingThis:currentlyWorking, description: description }, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
                    setProfileData(await getProfileData(profileId,setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
				} else {
                    setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		}

	}
    useEffect(()=>{
        const currentlyInput = document.getElementById('durationTo');
        if(currentlyWorking){
            // document.getElementById("durationTo").style.display = "none";
            if(currentlyInput)
            document.getElementById('durationTo').style.display = "none";
        } else {
            
            if(currentlyInput)
                document.getElementById('durationTo').style.display = "block";
        }
    },[currentlyWorking])
    const checkISCurrently = (event) =>{
        console.log(event.target)
        setIsCurrently(event.target.checked);
    }
    console.log(currentlyWorking)

    // Show Delete Profile Modal
	const [showDeleteProfile, setShowDeleteProfile] = useState(false);
	const closeDeleteProfile = () => setShowDeleteProfile(false);
	const openDeleteProfile = () => setShowDeleteProfile(true);
    return (
        <>
         <Modal show={showWorkSampleModal} centered size='lg' onHide={closeWorkSampleModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
            <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
                <div className="close-wrapper text-end">
                    <Button variant="link" onClick={closeWorkSampleModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
                </div>
                <div className="heading-wrapper mb-4">
						<div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
							<span className='widgetTitle text-black fs-5 fw-semibold'>Work samples</span>
							{/* This button will show only when you click to edit the details */}
							{isUpdateAction?
							<span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0 pointer' onClick={openDeleteProfile}>Delete</span>
							:null}
						</div>
						<p className='subTitle fs-14'>Link relevant work samples (e.g. Github, Behance)</p>
					</div>
                {/* <div className="heading-wrapper mb-4">
                    <span className='widgetTitle text-black fs-5 fw-semibold'>Work samples</span>
                    <p className='subTitle fs-14'>Link relevant work samples (e.g. Github, Behance)</p>
                </div> */}

                <Form action="" className="form-cover" onSubmit={saveWorkSamples}>
                    <Form.Group className="mb-3">
                        <Form.Label>Work title<span className='text-danger'>*</span></Form.Label>
                        <Form.Control value={workTitle} type="text" placeholder="Enter work title" onChange={(e) => { setWorkTitle(e.target.value) }} autoComplete='off' required />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>URL<span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="url" value={url} placeholder="Enter URL here" onChange={(e) => { setUrl(e.target.value) }} autoComplete='off' required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Duration from</Form.Label>
                        <div className="row gx-3">
                            <div className="col-6">
                                <Form.Group className="mb-3">
                                    <Form.Select name="durationFrom" onChange={(e) => { setDurationfromYear(e.target.value) }} value={durationfromYear}>
                                        <option selected hidden value=''>Select year</option>
                                        <option value="2024">2024</option>
                                        <option value="2023">2023</option>
                                        <option value="2022">2022</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>

                            <div className="col-6">
                                <Form.Group className="mb-3">
                                    <Form.Select name="durationFromMonth" onChange={(e) => { setDurationfromMonth(e.target.value) }} value={durationfromMonth}>
                                        <option selected hidden value=''>Select month</option>
                                        <option value="January">January</option>
                                        <option value="February">February</option>
                                        <option value="March">March</option>
                                        <option value="April">April</option>
                                        <option value="May">May</option>
                                        <option value="June">June</option>
                                        <option value="July">July</option>
                                        <option value="August">August</option>
                                        <option value="September">September</option>
                                        <option value="October">October</option>
                                        <option value="November">November</option>
                                        <option value="December">December</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                    </Form.Group>
                    {!currentlyWorking?
                    <Form.Group id='durationTo'>
                        <Form.Label>Duration to</Form.Label>
                        <div className="row gx-3">
                            <div className="col-6">
                                <Form.Group className="mb-3">
                                    <Form.Select name="durationTo" onChange={(e) => { setDurationtoYear(e.target.value) }} value={durationtoYear}>
                                        <option selected hidden value=''>Select year</option>
                                        <option value="2024">2024</option>
                                        <option value="2023">2023</option>
                                        <option value="2022">2022</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>

                            <div className="col-6">
                                <Form.Group className="mb-3">
                                    <Form.Select name="durationToMonth" onChange={(e) => { setDurationtoMonth(e.target.value) }} value={durationtoMonth}>
                                        <option selected hidden value=''>Select month</option>
                                        <option value="January">January</option>
                                        <option value="February">February</option>
                                        <option value="March">March</option>
                                        <option value="April">April</option>
                                        <option value="May">May</option>
                                        <option value="June">June</option>
                                        <option value="July">July</option>
                                        <option value="August">August</option>
                                        <option value="September">September</option>
                                        <option value="October">October</option>
                                        <option value="November">November</option>
                                        <option value="December">December</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                    </Form.Group>
                    : null}

                    <Form.Group className="mb-3">
                        <div className="form-check d-flex align-items-center gap-2">
                            <input type="checkbox" className="form-check-input border-2 border-secondary" id="workingStatus" checked={currentlyWorking} style={{width:'18px',height:'18px'}} value={currentlyWorking} onClick={(e) => { checkISCurrently(e) }} />
                            <label className="form-check-label ms-1 pt-1" for="workingStatus">I am currently working on this</label>
                        </div>
					</Form.Group>

                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={5} placeholder='Type here...' maxLength={500} autoComplete='off' value={description} onChange={(e) => { setDescription(e.target.value) }} />
                        <div className="charLenght fs-14 text-clr2 text-end mt-2">500 character(s) left</div>
                    </Form.Group>
                    
                    <div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
                        <Button variant="danger" onClick={closeWorkSampleModal} className='rounded-pill px-4'>Cancel</Button>
                        <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
        <Confirm showDeleteProfile={showDeleteProfile} closeDeleteProfile={closeDeleteProfile} profileId={profileId} id={updateData?.id} message={updateData?.work_title + " Work sample"} setShowWorkSampleModal={setShowWorkSampleModal} setShowDeleteProfile={setShowDeleteProfile} setIsLoading={setIsLoading} setProfileData={setProfileData} type="workSample"/>
        </>
       
    )
}

