import React from 'react'
import { useSelector } from 'react-redux';
import {Navigate, useLocation} from "react-router-dom"
// import { database } from '../firebaseConfig';
// import { environment } from '../environment';
// import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';

const ProtectedRoute = ({children, page, email,userRoleArray}) => {
    //console.log(email)
   
    let location = useLocation();
    const userRoleStore = useSelector((state) => state.userRole);
    const userRole = userRoleStore? userRoleStore:localStorage.getItem("userRole");
     //console.log("Store userRole:",userRole)   
    // console.log(userRole,page)



    //console.log(userRoleArray,page)

    const marketingPages = ["marketingDashboard","clientQuries","marketingDetails"];
    const allRolePages = ["myactivecalls","leadlist", "profile", "issueslist","replieslist","meetingDetails","callInfo"];
    const chartReport = ["CAM","SA","SSA"];
    const SSABDM =['BDM','SA','SSA'];
    const ReuisitionPages = ["requisition","createRequisitions","requisitionDetails","editRequisition"];
    const SASSA = ["SA","SSA"];
    
    const SASSAPAGES = ["ongoingcalls","memberlist","composemedia","accountdetails","blockedUsers","deletedUsers", "leadReport","test","clientpstncalls","callReport","requisitionApprovalList","leadsBysheels","teamsCallAdmin","profileBio","incomingCall"];
    const CAMSSA =['CAM','SSA'];
    // console.log(page,"--",SSABDM,"--",userRole,"--",userRoleArray)
    if((ReuisitionPages.includes(page)) && (userRoleArray.includes("TM") || (SSABDM.includes(userRole)))){

    // console.log("in requisition")
        return children;
    } 
    else if(userRole === "CAM" && page === "clientCalls"){

        return children;
    } 
    else if((page === "callReport") && (userRoleArray.includes("CAM MANAGER") || (chartReport.includes(userRole)))){
        return children;
    } 
    else if(allRolePages.includes(page) && (userRole === "CAM" || userRole === "BDM" || userRole === "SA" || userRole === "SSA")) {
        return children;
    } 
    
    
    else if((page ==="reports" || page === "opportunityReport") && chartReport.includes(userRole)){
        return children;
    }
    else if(marketingPages.includes(page) && (userRoleArray.includes("MAM") || userRole === "SA" || userRole === "SSA")){
        return children;
    }
    else if(SASSAPAGES.includes(page) && SASSA.includes(userRole)){
        return children;
    } 
    else if(page === "leaddescription" && (userRole === "BDM" || userRole === "SA" || userRole === "SSA")) {
        return children;
    }
    else if(page === "adminChats" && (userRole === "SSA")){
        return children;
    }
    else{
        // return <Navigate to="/unauthorized" state={{ from: location}} />
        return <Navigate to="/chats" state={{ from: location}} />
    }
    

};

export default ProtectedRoute;