import React, { useEffect, useState } from "react";
import ChatSection from "./ChatSection/ChatSection";
import LeadDetailsSection from "./LeadSection/LeadDetailsSection";
import TranscriptionSection from "./Transcription/ChatSection";
import ParticipantsSection from "./ParticipantsSection/ParticipantsSection";
import "./RoomPage.css";
import VideoSection from "./VideoSection/VideoSection";
import { connect } from "react-redux";
import { setFormId, setIdentity, setRoomId, setTwilioAccessToken, setUserInfo, setUserRole } from "../store/actions";
import { getTokenFromTwilio } from "../utils/twilioUtils";
import { useNavigate, useLocation,useSearchParams } from "react-router-dom";
import Overlay from "./Overlay";
import BackgroundSetting from "./BgSettingSection/BackgroundSetting";
import { CallOnMobile } from "./VideoSection/CallOnMobile";
import notificationSound from "../static/NotificationNew.mp3";
import RejoinRoom from "./RejoinRoom";

 function RoomPage(props) {
  const { setTwilioAccessTokenAction, showOverlay, setIdentityAction, setRoomIdAction, setFormIdAction, setLoggedinUserAction, setUserRoleAction  } = props;
  //console.log(props)
  let navigate = useNavigate();
  const [audio, setAudio] = useState(new Audio(notificationSound));
  // const { state } = useLocation();
  // const { id, camera, isMicMuted, isRejoin} = state;
  
  const [searchParams, setSearchParams] = useSearchParams();
  const state_ = searchParams.get("id");

  const objStr = atob(state_)

  const state_obj = JSON.parse(objStr)
   //console.log(state_obj)

  const camera = state_obj.camera;
  const isMicMuted = state_obj.isMicMuted;
  const isRejoin = state_obj.isRejoin;
  const roomId = state_obj.roomId;
  const formId = state_obj.formID;
  const identity = "_CAM_" + state_obj.identity;
  const user = state_obj.user;
  const userRole = state_obj.userRole;

  const userInfo = {
    uid: state_obj.uid,
    email: state_obj.email,
    displayName: state_obj.displayName,
    accessToken: state_obj.accessToken,
    idToken: state_obj.idToken,
  };

//console.log(state_obj)

  //
  useEffect(() => {
    if (!identity || !roomId) {
      navigate("/");
    } else {
      getTokenFromTwilio(setTwilioAccessTokenAction, identity);
    }
  }, []);

//console.log(props)

setIdentityAction(identity);
setRoomIdAction(roomId);
setFormIdAction(formId);
setUserInfo(userInfo);
 setUserRoleAction(userRole);

  return (
    <>
      <VideoSection comingFrom={true} isCam={true} camera={camera} isMicMuted = {isMicMuted} isRejoin={isRejoin}/>
      {/* <VideoSection comingFrom={true} isCam={true} camera={camera} isMicMuted = {isMicMuted}/> */}
      {/* Main Video Section */}
      {/* <div id="main" className='room_container main-section p-lg-3 overflow-hidden'>
        <div id='videos_portal' className='when-normal d-flex flex-wrap h-100 overflow-auto position-relative'>
        </div>
      </div> */}

      {/* Main Video Section (When Shared Screen) */}
      <div
        id="main"
        className="room_container main-section p-lg-3 overflow-hidden"
      >
        <div
          id="shared_screen"
          className="shared_screen_preview d-flex flex-wrap w-100 h-100 overflow-hidden position-relative border border-1 border-info hide"
        ></div>
        <div
          id="videos_portal"
          className="when-normal d-flex flex-wrap h-100 overflow-auto position-relative"
        ></div>
      </div>

      {/* Chat Sidenav */}
      <div id="chatSidenav" className="sidebar border-start border-black">
        <ChatSection />
        {showOverlay && <Overlay />}
      </div>

      {/* Participants Sidenav */}
      <div id="partcipantSidenav" className="sidebar border-start border-black">
        <ParticipantsSection />
      </div>

      {/* Participants Sidenav */}
      <div
        id="LeadDetailsSidenav"
        className="sidebar border-start border-black"
      >
        <LeadDetailsSection />
      </div>

       {/* Transcription Sidenav */}
       <div
        id="TranscriptionSection"
        className="sidebar border-start border-black"
      >
        <TranscriptionSection />
      </div>

       {/* Background Setting Sidenav */}
       <div id="BgSettingSidenav" className="sidebar border-start border-black">
        <BackgroundSetting comingFrom={true}/>
      </div>
    {/* User Mobile Call Model */}
    <CallOnMobile formId={formId} audio={audio}/>

    {/* Rejoin Modal */}

    {/* <RejoinRoom roomId={roomId}  identity={identity} twilioAccessToken={props.twilioAccessToken} showOverlay={showOverlay} formId={formId} camera={camera} isMicMuted = {isMicMuted} isRejoin={isRejoin} /> */}

    </>
  );
}



const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setTwilioAccessTokenAction: (token) => dispatch(setTwilioAccessToken(token)),
      setIdentityAction: (identity) => dispatch(setIdentity(identity)),
      setRoomIdAction: (id) => dispatch(setRoomId(id)),
      setFormIdAction: (id) => dispatch(setFormId(id)),
       setUserInfo: (user) => dispatch(setUserInfo(user)),
       setUserRoleAction: (userRole) => dispatch(setUserRole(userRole)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(RoomPage);
