import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from '../../environment';
import { getProfileData } from '../../utils/utils';

export const ResumeHeadline = ({ show, handleClose, resumetoast,profileId,profileData, setProfileData, setIsLoading,setShowHeadlineModal }) => {
    const [textareaValue, setTextareaValue] = useState(profileData?.resume_headline);
    const [remainingCharacters, setRemainingCharacters] = useState(250);

    const handleTextareaChange = (event) => {
        const value = event.target.value;
        setTextareaValue(value);
        const remaining = 250 - value.length;
        setRemainingCharacters(remaining);
    };

    useEffect(() => {
        if (profileData?.resume_headline) {
          setTextareaValue(profileData.resume_headline);
          const remaining = 250 - profileData.resume_headline.length;
          setRemainingCharacters(remaining);
        }
      }, [profileData]);

    const handleSave = async(e) => {
        e.preventDefault();
        setIsLoading(true)

        const postData = {
            "profileId": profileId, 
            "type" : "headline",
            "resumeHeadLine":`${textareaValue}`, 
        }
        
        try {
            setShowHeadlineModal(false)
            const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/resume`, postData, {
            headers: {
                    'Content-Type': 'application/json'
                }
            });	
            const responseData = response?.data
            if(responseData?.statusCode === 200){
                setProfileData(await getProfileData(profileId, setIsLoading))
                setTextareaValue('')
                toast.success(responseData?.message, {
                    position: 'bottom-center'
                });
                setTimeout(() => {
                    handleClose(); 
                }, 1000);
            }
        }catch (error) {
            setProfileData(await getProfileData(profileId, setIsLoading))
            toast.error(error, {
                position: 'bottom-center'
            });
            console.error('Error uploading file:', error);
        }
    };
    
    return (
        <Modal show={show} centered size='lg' onHide={handleClose} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
            <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
                <div className="close-wrapper text-end mb-2">
                    <Button variant="link" onClick={handleClose} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
                </div>
                <div className="heading-wrapper mb-4">
                    <div className='widgetTitle text-black fs-18 fw-semibold mb-1'>Resume headline 
                    {!profileData?.resume_headline?
                    <span className='profileComScore fs-6 fw-normal text-success ms-1'>Add 8%</span>
                    :null}
                    
                    </div>
                    <p className='subTitle fs-14'>It is the first thing recruiters notice in your profile. Write a concise headline introducing yourself to employers. (Minimum 5 words)</p>
                </div>
                <Form method='post' className="form-cover" onSubmit={handleSave}>
                    
                    <Form.Group>
                        <Form.Control as="textarea" 
                        value={textareaValue}
                        onChange={handleTextareaChange} rows={5} placeholder='Minimum 5 words. Sample headlines: Sales Manager well versed in Excel and Dynamics CRM. Senior-level Interior Designer with expertise in 3D modeling.' autoComplete='off' required />
                        <div className="charLenght fs-14 text-secondary text-end mt-2">{remainingCharacters} character(s) left</div>
                    </Form.Group>
                    <div className="btn-wrapper d-flex flex-wrap gap-2 mt-4">
                        <Button variant="danger" onClick={handleClose} className='rounded-pill px-4'>Cancel</Button>
                        <Button type='submit' variant="primary" className='rounded-pill px-4' >Save</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
