import React, { useEffect, useState } from "react";
import { update_Group_Name } from "../utils/twilioConversation";


export const RenameGroup = (props) => {
    const [groupName, setGroupName] = useState(null);
   
    useEffect(()=>{
        console.log("in useEffect group renaming")
        const groupName = document.getElementById("showCurrentChatName").innerHTML;
        setGroupName(groupName);
    },[])

 

    const updateGroupName = async()=>{
        console.log("updating group naming........",groupName)
        update_Group_Name(groupName);
        setGroupName(null);
        //console.log(Participants)
       // createGroupConversation(Participants);

    }
    
    return (
        <div className="add-participants bg-white position-absolute start-1 shadow p-3">
            <form>
                <div className="mb-3">
                    <div className="form-label mb-1">Group Name</div>
                    <input type="text" id="update-group-rename" className="form-control"  placeholder="Type a group name" required onChange={(e)=>{setGroupName(e.target.value)}} value={groupName}/>
                </div>

               
            </form>
            <div className="btn-wrapper d-flex gap-2 justify-content-end">
                <button type="button" className="btn btn-sm btn-danger" onClick={props.closeCard} id="cancel-update-rename-group">Cancel</button>
                <button type="button" className="btn btn-sm btn-primary" onClick={updateGroupName}>Save</button>
            </div>
        </div>
    )
}