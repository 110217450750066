import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PersonalDetails } from './Modals/PersonalDetails';
import { Button } from 'react-bootstrap';
import { convertReadable } from '../utils/utils';

export const PersonalDetailsCard = ({profileId, profileData, setProfileData, setIsLoading, currempdata}) => {

	const [isPersonalDetailsAction, setPersonalDetailsAction] = useState(false)
    const [updatePersonalDetailsData, setPersonalDetailsData] = useState({})

	// Show Personal Details Modal
	const [showPersonalDetailsModal, setShowPersonalDetailsModal] = useState(false);
	const closePersonalDetailsModal = () => {
		setPersonalDetailsData({})
			setPersonalDetailsAction(false)
		setShowPersonalDetailsModal(false);
	}
	const openPersonalDetailsModal = () => {
		if(profileData?.personal_detail){
			setPersonalDetailsData(profileData?.personal_detail)
			setPersonalDetailsAction(true)
		setShowPersonalDetailsModal(true);
		}  else{
			
			setShowPersonalDetailsModal(true);
		}
		
	}

	let perCity;
	if(profileData?.personal_detail){
		perCity = JSON.parse(profileData?.personal_detail?.work_permit_other_contry).map((item)=>{
		return item.value;
	})
	}
	let Addressss = '';
	if(profileData?.personal_detail?.house_flat_no){
		Addressss += profileData?.personal_detail?.house_flat_no+", "
	}
	if(profileData?.personal_detail?.street_name){
		Addressss +=profileData?.personal_detail?.street_name +", "
	}
	if(profileData?.personal_detail?.locality_area){
		Addressss +=profileData?.personal_detail?.locality_area +", " 
	}
	if(profileData?.personal_detail?.city){
		Addressss +=profileData?.personal_detail?.city +", "
	}
	if(profileData?.personal_detail?.state){
		Addressss +=profileData?.personal_detail?.state_label +", "
	}
	if(profileData?.personal_detail?.country){
		Addressss +=profileData?.personal_detail?.country_label
	}
	const languageRen = profileData?.language_proficiencies && profileData?.language_proficiencies.map((item)=>{
		return(<tr>
			<td className='text-clr1 text-nowrap text-capitalize'>{item.language}</td>
			<td className='text-clr1 text-nowrap text-capitalize'>{item.proficiency}</td>
			<td className='text-clr1 text-nowrap'><span>{item.read_?<i class="bi bi-check-circle fs-6"></i>:<i className="bi bi-x-circle"></i>}</span></td>
			<td className='text-clr1 text-nowrap'><span>{item.write_?<i class="bi bi-check-circle fs-6"></i>:<i className="bi bi-x-circle"></i>}</span></td>
			<td className='text-clr1 text-nowrap'><span>{item.speak?<i class="bi bi-check-circle fs-6"></i>:<i className="bi bi-x-circle"></i>}</span></td>
		</tr>)
		});
     
		
	return (
		<>
			<div id="Personal-Details" className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
				<div className='widgetHead mb-3'>
					<div className='widgetTitle text-white fs-6 fw-medium'>Personal details <span className="btn-link edit-icon ms-3"><i className="bi bi-pencil fs-6 text-clr1 lh-1" onClick={openPersonalDetailsModal}></i></span></div>
					{/* Uncomment this for normal case */}
					{/* <div className='emp-desc mt-3'>This information is very important and must be accurate. Please be careful when inputting your answers here. </div> */}
				</div>

				<div className='widgetCont'>
					<div className="row g-3">
						<div className="col-sm-6">
							{profileData?.personal_detail && profileData?.personal_detail?.gender?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Personal</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{profileData?.personal_detail?.gender +", "+profileData?.personal_detail?.marital_status}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Personal</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openPersonalDetailsModal}>Add gender, marital status, more info</Link>
								</div>
							</div>
							}
						</div>
						<div className="col-sm-6">
						{profileData?.personal_detail && profileData?.personal_detail?.taken_carrer_break?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Career break</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{profileData?.personal_detail?.taken_carrer_break?"Yes":"No"}, {profileData?.personal_detail?.break_start_from} - {profileData?.personal_detail?.currently_on_break?"Present":profileData?.personal_detail?.break_ended_in}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Career break</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openPersonalDetailsModal}>Add Career break</Link>
								</div>
							</div>
						}
						</div>
						<div className="col-sm-6">
						{profileData?.personal_detail && profileData?.personal_detail?.dob?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Date of birth</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{convertReadable(profileData?.personal_detail?.dob)}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Date of birth</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openPersonalDetailsModal}>Add Date of birth</Link>
								</div>
							</div>
						}
						</div>
						<div className="col-sm-6">
						{profileData?.personal_detail && profileData?.personal_detail?.work_permit_usa?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Work permit</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{profileData?.personal_detail?.work_permit_usa +", "+perCity.join(", ")}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Work permit</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openPersonalDetailsModal}>Add Work permit</Link>
								</div>
							</div>
						}
						</div>
						<div className="col-sm-6">
						{profileData?.personal_detail && profileData?.personal_detail?.pincode?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Pincode</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{profileData?.personal_detail?.pincode}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Pincode</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openPersonalDetailsModal}>Add Pincode</Link>
								</div>
							</div>
						}
						</div>
						<div className="col-sm-6">
						{profileData?.personal_detail && profileData?.personal_detail?.house_flat_no?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Address</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>{Addressss}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Address</div>
								<div className="info-desc fw-medium">
									
									<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openPersonalDetailsModal}>Add Address</Link>
								</div>
							</div>
						}
						</div>
						<div className="col-sm-6">
						{profileData?.personal_detail && profileData?.personal_detail?.is_differently_abled?
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Differently abled</div>
								<div className="info-desc fw-medium">
									<p className='text-clr1 text-capitalize m-0'>Yes, {profileData?.personal_detail?.type}</p>
									
								</div>
							</div>
							:
							<div className="info-box">
								<div className="info-title text-light text-opacity-75">Differently abled</div>
								<div className="info-desc fw-medium">
									
								<p className='text-clr1 text-capitalize m-0'>No</p>
								</div>
							</div>
						}
						</div>
						<div className="col-sm-6">
						{profileData?.language_proficiencies && profileData?.language_proficiencies.length === 0?
							<div className="info-box">
							<div className="info-title text-light text-opacity-75">Languages</div>
							<div className="info-desc fw-medium" id="ADD-LANGUAGE-SECTION">
								
								<Link to={'#'} className='btn-link text-primary text-decoration-none' onClick={openPersonalDetailsModal}>Add Languages</Link>
							</div>
						</div>
							:
							null
						}
						</div>
					</div>
					{profileData?.language_proficiencies && profileData?.language_proficiencies.length > 0?
					<div>
						<hr className='my-4' />
						<div className="d-flex flex-wrap justify-content-between gap-2 mb-2">
							<div className="text-white fs-6 fw-medium">Languages</div>
							<Button variant="link" className='fw-semibold text-primary text-decoration-none border-0 p-0' onClick={openPersonalDetailsModal}>Add languages</Button>
						</div>
						<div className="table-responsive">
							<table className='table table-dark table-custom border-secondary border-opacity-75 mb-0'>
								<thead>
									<tr>
										<th className='text-white text-opacity-75 fw-medium text-nowrap'>Languages</th>
										<th className='text-white text-opacity-75 fw-medium text-nowrap'>Proficiency</th>
										<th className='text-white text-opacity-75 fw-medium text-nowrap'>Read</th>
										<th className='text-white text-opacity-75 fw-medium text-nowrap'>Write</th>
										<th className='text-white text-opacity-75 fw-medium text-nowrap'>Speak</th>
									</tr>
								</thead>
								<tbody>
									{languageRen}
									
								</tbody>
							</table>
						</div>
					</div>
					:null}
				</div>
			</div>
			<PersonalDetails showPersonalDetailsModal={showPersonalDetailsModal} closePersonalDetailsModal={closePersonalDetailsModal} setShowPersonalDetailsModal={setShowPersonalDetailsModal} profileId={profileId} setProfileData={setProfileData} isUpdateAction={isPersonalDetailsAction} updateData={updatePersonalDetailsData} profileData={profileData} setIsLoading={setIsLoading} currempdata={currempdata}/>
		</>
	)
}
