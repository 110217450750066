import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from '../firebaseConfig';
import { connect } from 'react-redux';
import { setUserInfo } from '../store/actions';
import { callAxios } from '../utils/utils';
import { environment } from '../environment';

const MarketingData = ({ setLoggedinUser }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true);
  const [user, loading, error] = useAuthState(auth);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };

      setLoggedinUser(userInfo);
      callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/attendance/get-client-filters', {}, "post").then((filters) => {
        setFilters(filters.data);
        setIsLoading(false)
      })
    }
  }, [user, loading]);

  const clientQueries = () => {
    navigate('/client-queries', { state: { filters: filters } })
  }

  return (
    <>
      {isLoading ? (
        <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3" id="lead-list-spinner" style={{ position: "absolute",margin: "21px 0px 0px 0px",left: "44%"}}>
          <div className="lead-list-spinner"></div>
          {" "} Please wait ....
        </div>
      ) :
        <div className='MarketingData my-3'>
          <div className="container">
            <div className="main-heading text-white text-opacity-75 mb-2">Marketing Data</div>
            <div className="row g-3">
              <div className="col-sm-6 col-md-4 col-xl-3">
                <div className="card dark-theme-bg pointer p-3 p-md-4" onClick={clientQueries}>
                  <div className="icon-box fs-1">
                    <i className="bi bi-list-stars"></i>
                  </div>
                  <h6 className='text-light text-capitalize'>Live client queries</h6>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-xl-3"></div>
              <div className="col-sm-6 col-md-4 col-xl-3"></div>
            </div>
          </div>
        </div>
      }
    </>
  )
}


const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(MarketingData);
