import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RejoinRoom = (props)=>{
    
    let navigate = useNavigate();
    const handleReJoinNow = () =>{
        const urlArray = window.location.href.split("/");
       document.getElementById("rejoinRoom").classList.add("show")
       document.getElementById("rejoinRoom").style.display = "none";
       //console.log(window.location.href.split("/"));
       navigate("/" + urlArray[3] +"&isRejoin="+true);
       
      }

      const handleCloseRejoinBox = () =>{
        document.getElementById("rejoinRoom").style.display = "none";
      }
    return (
        <>
         <div className='join-call-model'>
            <div className="modal fade" id="rejoinRoom" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: '650px' }}>
                    <div className="modal-content bg-dark border-top border-5 border-primary rounded-0 shadow">
                        <div className="modal-body p-4 p-md-5 text-center">
                            <div className='fs-5 fw-semibold mb-4'>Rejoin Room</div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='video-sec position-relative h-100 border border-1 border-secondary' id="clientRejoin">
                                        
                                    </div>
                                </div>
                               
                            </div>
                            <div className='btn-wrapper border-1 border-top pt-4 mt-4'>
                                {/* <button onClick={handleCloseRejoinBox} type="button" className="btn btn-secondary bg-gradient rounded-1 px-4 py-2 me-2" id="CloseRejoin" data-bs-dismiss="modal">Cancel</button> */}
                                <button type="button" className="btn btn-primary bg-gradient rounded-1 px-4 py-2" onClick={handleReJoinNow}>Re-Join</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            </div>
        </>
    )
}

export default RejoinRoom;