import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class PstnCallChart extends Component {

  componentDidMount() {
   
     this.initChart()
  }
  componentDidUpdate(prevProps) {
    
   
    this.initChart();
    
  }
  
//  initChart(){
//   var chart = am4core.create("pstn-call-total-connected-chart", am4charts.XYChart);
//   chart.paddingBottom = 30;
// chart.angle = 35;

// // Add data
// chart.data = [{
//   "country": "Total Calls",
//   "visits": this.props.chartData.totalCalls
// }, {
//   "country": "Connected Calls",
//   "visits": this.props.chartData.clientConnected + this.props.chartData.voiceMail
// }];

// this.props.chartData.callStatus.map((item)=>{
//   chart.data.push(item)
// })

// chart.padding(40, 40, 40, 40);

// let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
// categoryAxis.renderer.grid.template.location = 0;
// categoryAxis.dataFields.category = "country";
// categoryAxis.renderer.minGridDistance = 60;
// categoryAxis.renderer.inversed = false;
// categoryAxis.renderer.grid.template.disabled = true;

// let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
// valueAxis.min = 0;
// valueAxis.extraMax = 0.1;
// //valueAxis.rangeChangeEasing = am4core.ease.linear;
// //valueAxis.rangeChangeDuration = 1500;

// let series = chart.series.push(new am4charts.ColumnSeries());
// series.dataFields.categoryX = "country";
// series.dataFields.valueY = "visits";
// series.tooltipText = "{valueY.value}"
// series.columns.template.strokeOpacity = 0;
// series.columns.template.column.cornerRadiusTopRight = 10;
// series.columns.template.column.cornerRadiusTopLeft = 10;

// series.columns.template.width = am4core.percent(40);


// //series.interpolationDuration = 1500;
// //series.interpolationEasing = am4core.ease.linear;
// let labelBullet = series.bullets.push(new am4charts.LabelBullet());
// labelBullet.label.verticalCenter = "bottom";
// labelBullet.label.dy = -10;
// labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";

// chart.zoomOutButton.disabled = true;

// // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
// series.columns.template.adapter.add("fill", function (fill, target) {
//  return chart.colors.getIndex(target.dataItem.index);
// });


//  }

initChart() {
  let invalidCalls = 0;
  var chart = am4core.create("pstn-call-total-connected-chart", am4charts.XYChart);
  chart.paddingBottom = 30;
  chart.angle = 35;

  // Add data
  let data = [{
      "country": "Total Calls",
      "visits": this.props.chartData.totalCalls
  }, {
      "country": "Connected Calls",
      "visits": this.props.chartData.clientConnected + this.props.chartData.voiceMail
  }];
  let statusTotal = 0;
  this.props.chartData.callStatus.forEach(item => {
      if (item.country && item.visits) { // Make sure data is not null
        statusTotal +=item.visits;

          data.push(item);
      }
  });

  chart.data = data;
  
  invalidCalls = this.props.chartData.totalCalls - (this.props.chartData.clientConnected + this.props.chartData.voiceMail+statusTotal)
  chart.data.push({country:"Invalid Number",visits:invalidCalls});
  // Define axes
  // console.log("invalid Calls:",invalidCalls)
  let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.dataFields.category = "country";
  categoryAxis.renderer.minGridDistance = 60;
  categoryAxis.renderer.inversed = false;
  categoryAxis.renderer.grid.template.disabled = true;

  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.min = 0;
  valueAxis.extraMax = 0.1;

  // Define series
  let series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.categoryX = "country";
  series.dataFields.valueY = "visits";
  series.tooltipText = "{valueY.value}";
  series.columns.template.strokeOpacity = 0;
  series.columns.template.column.cornerRadiusTopRight = 10;
  series.columns.template.column.cornerRadiusTopLeft = 10;
  series.columns.template.width = am4core.percent(40);

  // Define label bullet
  let labelBullet = series.bullets.push(new am4charts.LabelBullet());
  labelBullet.label.verticalCenter = "bottom";
  labelBullet.label.dy = -10;
  labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";

  chart.zoomOutButton.disabled = true;

  // Set colors
  series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
  });

  // Log data to console for debugging
  // console.log("Chart Data:", chart.data);

  // Ensure tooltips are enabled for debugging
  chart.cursor = new am4charts.XYCursor();

  // Log any errors to console for debugging
  chart.events.on("error", function (ev) {
      console.error("Chart Error:", ev);
  });
}


  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  
  render() {
    return <div id="pstn-call-total-connected-chart" style={{ width: "100%", height: "450px", margin: "0 auto" }} />;
  }

 
}

export default PstnCallChart;
