import React from "react";
import { useNavigate } from "react-router-dom";
import { convertReadable, convertReadableTime, convertSecondsToMinutes } from "../utils/utils";

const ClientQueryCard = ({item,index}) =>{
    //console.log(item)
    const navigate = useNavigate()


    let participantsArray = JSON.parse(item?.participants).reduce(function (array, participant) {
        //console.log(participant)
        if (participant.identity.includes("_Guest By")) {
            array.push(participant);
        } 
        return array;
     }, []);

    //  item?.participants.map((item)=>{
    //     console.log(item.identity)
    //  })
    // console.log(participantsArray)
    const ppcPramUrl = item.ppcurl.split("?")[1];
    
    const urlParams = new URLSearchParams(ppcPramUrl);

const marketingDetails = (item,participantsArray) => {
        // navigate('/marketing-details',)

        navigate("/marketing-details", {
            state: { leadData: item,  participantsArray:participantsArray },
          });
    }

    const regionNames = new Intl.DisplayNames(
        ['en'], {type: 'region'}
      );

      const goLeadPage = (e) =>{
        //console.log(leadData.ppcurl);
        e.stopPropagation();
        const leadUrl = item.ppcurl.replace("[","").replace('"','').replace("]");
        window.open("https://"+leadUrl,"_blank")
    }
    return (
        <div className="info-card rounded-2 mt-3 pointer" key={"client-query-"+index} onClick={() =>{marketingDetails(item,participantsArray)}}>
                            <div className="info-card-details overflow-auto p-3">
                                <div className="row gx-3">
                                    <div className="col-md-4 col-lg-5">
                                        {item.name?
                                        <div className="client-name text-white fs-6 fw-medium text-capitalize mb-1">{item.name}</div>
                                        :null}
                                        {item.timeStamp?
                                        <div className="date-time-state text-white text-opacity-75 mb-1">{item.displayTime}</div>
                                        :null}
                                        {item.callTimeInSeconds !== null?
                                        <div className="date-time-state text-white text-opacity-75 mb-1">Call Duration: {convertSecondsToMinutes(item.callTimeInSeconds)}</div>
                                        :null}
                                        <div className="countryy-name text-capitalize text-warning text-opacity-75">Client Location - <span className="d-inline-block"><img className="img-fluid" title={regionNames.of(item.country)} style={{width:"30px"}} alt={item.country} src={"http://purecatamphetamine.github.io/country-flag-icons/3x2/"+item.country.toUpperCase()+".svg"}/></span></div>
                                    </div>
                                    {urlParams.get("source") ? (
                                    <div className="col-sm-6 col-md-4 col-lg-4 mt-2 mt-md-0">
                                         {urlParams.get("adgroup") ? (
                                        <div className="client-name text-white text-opacity-75 text-capitalize mb-1">Lead Ad Group: <span className='text-white fw-medium'>{urlParams.get("adgroup").toUpperCase()}</span></div>
                                        ):null }

                                         {urlParams.get("medium") ? (
                                        <div className="date-time-state text-white text-opacity-75 mb-1">Campaign Type: <span className='text-white fw-medium'>{urlParams.get("medium").toUpperCase()}</span></div>
                                         ):null}

                                         {urlParams.get("source") ? (
                                        <div className="date-time-state text-white text-opacity-75 mb-1">Lead Source: <span className='text-white fw-medium'>{urlParams.get("source").toUpperCase()}</span></div>
                                         )
                                        : null }
                                        {urlParams.get("campaign") ? (
                                        <div className="date-time-state text-white text-opacity-75 mb-1">Lead Campaign: <span className='text-white fw-medium'>{urlParams.get("campaign").toUpperCase()}</span></div>
                                        ):null}

                                        <div className="date-time-state text-white text-opacity-75">Lead Page: <button className='btn btn-sm btn-outline-success lh-sm px-4 pointer' onClick={goLeadPage}>Visit</button></div>
                                    </div>
                                    ):null
                                    }
                                    <div className="col-sm-6 col-md-4 col-lg-3 text-md-end mt-2 mt-md-0">
                                        {item.attendantName?
                                        <div className="client-name text-white text-opacity-75 text-capitalize mb-1">{item.attendantName +" [CAM]"}</div>
                                        : null }
                                     {item.attendantBdmName?
                                        <div className="client-name text-white text-opacity-75 text-capitalize mb-1">{item.attendantBdmName + " [BDM]"}</div>
                                        :null}

                                    

                                        {participantsArray.length > 0?
                                            participantsArray.map((participant)=>{
                                                // console.log(item.name,participant)
                                                return <div className="client-name text-white text-opacity-75 text-capitalize">{participant.identity.split("_")[2] + " ["+participant.identity.split("_")[1]+"]"}</div>
                                            })
                                        :null}

                                        {item.name?
                                        <div className="client-name text-white text-opacity-75 text-capitalize mb-1">{item.name + " [Client]"}</div>
                                        :null}
                                        {/*<div className="client-name text-white text-opacity-75 text-capitalize mb-1">Edward Jonathan</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
    )
}

export default ClientQueryCard;