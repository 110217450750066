import React, { useEffect, useRef, useState } from 'react';
import { Link, json, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ResumeHeadline } from './Modals/ResumeHeadline';
import { AddKeySkills } from './Modals/AddKeySkills';
import { AddEmployment } from './Modals/AddEmployment';
import { EditEmployment } from './Modals/EditEmployment';
import { AddEducation } from './Modals/AddEducation';
import { AllEducation } from './Modals/AllEducation';
import { ProfilePhotoUpload } from './Modals/ProfilePhotoUpload';
import { AddITSkills } from './Modals/AddITSkills';
import { AddProject } from './Modals/AddProject';
import { AddProfileSummary } from './Modals/AddProfileSummary';
import { AccomplishmentsCard } from './AccomplishmentsCard';
// import { EmailVerification } from './Modals/EmailVerification';

import { setUserInfo } from '../store/actions';
import { connect } from 'react-redux';

import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';

import { useNavigate } from "react-router-dom";
import { calculateDuration, callAxios, getCurrentYearMonth, getProfileData, searchByKeyValue } from '../utils/utils';
import { environment } from '../environment';

import { CareerProfileCard } from './CareerProfileCard';
import { PersonalDetailsCard } from './PersonalDetailsCard';
import { BasicDetails } from './Modals/BasicDetails';

import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import WarningModal from './Components/Warningmodal';

import "./loader.css";



const ProfileBio = (props) => {
  const { setLoggedinUser } = props;
  const {state} = useLocation();
  const [user, loading, error] = useAuthState(auth);
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadDate, setUploadDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [userSkills, setUserskills] = useState([])
  const [getEmployment, setEmployment] = useState([])
  const [sendempdata, setempdata] = useState('')
  const [empInfo, setEmpinfo] = useState('')
  const [profileData, setProfileData] = useState({})
  const [employmentData, setEmploymentData] = useState([])
  const [pathcolor, setPathColor] = useState("")

  const [sendactiontype, setActiontype] = useState('')
  const [sendeducationData, setEducationData] = useState([]);
  const [edutype, setEdutype] = useState('')
  const [sendSkillsdata, setSkillsdata] = useState([])
  const [getprojectdata, setProjectsdata] = useState([])
  const [selectedEducation, setselectedEducation] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [textmessage, setTextmessage] = useState('');
	const [deleteresumedata, setDeleteresumedata] = useState('')
  const [currempdata, setCurrempdata] = useState('');
  const [missingDetails, setMissingDetails] = useState([]);

  const fileInputRef = useRef(null);
// let profile_id;
  const profile_id = environment.REACT_APP_USER_ID
  // const { profileId } = state;
  // profile_id= profileId?profileId:environment.REACT_APP_USER_ID
  // Input Files Code
  
   // Profile progressbar code
  //  const percentage = 70;

 
  

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      //   console.log("calling useeffect")
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };
      setLoggedinUser(userInfo);

      
      callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/get-profile-data", { profile_id: profile_id }, "post").then((profileData) => {
        // console.log(profileData.data)
        setEmploymentData(profileData.data.employments)
        setProfileData(profileData.data.profileData)
        const eduationData = profileData.data.profileData?.education
				const educationSet = new Set(eduationData?.map(item => item.education));
				const uniqueEducationsArray = [...educationSet];
				setselectedEducation(uniqueEducationsArray)
        const getiscurremp = profileData?.data?.employments
				const currentEmployments = getiscurremp.filter(employment => employment.is_current_employment === 1);

				// Find the employment with the highest id among the filtered employments
				const maxId = Math.max(...currentEmployments.map(employment => employment.id));

				// Filter again to get the employment with the maximum id value
				const highestEmployment = currentEmployments.find(
					employment => employment.id === maxId
				);
				setCurrempdata(highestEmployment)
        setIsLoading(false);
      })


    }
  }, [user, loading]);

useEffect(()=>{
  let count = 0;
  let zeroValueFields = [];
  for (const key in profileData?.profile_percentage) {
      if (profileData?.profile_percentage.hasOwnProperty(key) && profileData?.profile_percentage[key] === 0) {
          count++;
          zeroValueFields.push({ key: key });
      }
  }


console.log(zeroValueFields)
  setMissingDetails(zeroValueFields)
  console.log(`Number of fields with value 0: `);
 
  const addMissing = document.getElementById("add-missing-details");
  if(addMissing)
    addMissing.innerHTML= count;
},[profileData?.profile_percentage])
console.log(missingDetails)

const getMissingDetils = missingDetails.length >0 && missingDetails.map((item)=>{
  // const handleClick = item && item.key === "profile_summary" ? openProfileSummaryModal : () => {};
  return (
    <div className='detail-item d-flex align-items-center justify-content-between gap-2 mb-2 pb-1'>
                      <div className='w-75 d-flex align-items-center gap-3'>
                        <span className='pend-icon bg-white d-flex align-items-center justify-content-center rounded-circle p-1 gap-2'>
                          <i className='bi bi-envelope-check fs-18 text-dark'></i>
                        </span>
                        
                          {
                            item.key === "email"? <span className='action-name text-warning fw-medium lh-sm pointer' onClick={()=>{openBasicDetailsModal()}}>Verify Email</span>
                            :item.key === "mobile"? <span className='action-name text-warning fw-medium lh-sm pointer' onClick={()=>{openBasicDetailsModal()}}>Verify Mobile</span>
                            :item.key === "photo"? <span className='action-name text-warning fw-medium lh-sm pointer' onClick={()=>{openProfileModal()}}>Add Photo</span>
                            :item.key === "resume_headline"?<span className='action-name text-warning fw-medium lh-sm pointer' onClick={() => scrollToSection('resumeHeadline')}>Add Resume Headline</span>
                            :item.key === "profile_summary" ? <span className='action-name text-warning fw-medium lh-sm pointer' onClick={()=>{scrollToSection('Profile-Summary')}}>Add Profile Summary</span>
                            :item.key === "personal_details" ? <span className='action-name text-warning fw-medium lh-sm pointer' onClick={()=>{scrollToSection('Personal-Details')}}>Add Personal Details</span>
                            :item.key === "job_profile" ? <span className='action-name text-warning fw-medium lh-sm pointer'>Add Job Profile</span>
                            :item.key === "language_proficiency" ? <span className='action-name text-warning fw-medium lh-sm pointer' onClick={()=>{scrollToSection('ADD-LANGUAGE-SECTION')}}>Add Language Proficiency</span>
                            :item.key === "key_skills" ? <span className='action-name text-warning fw-medium lh-sm pointer' onClick={()=>{scrollToSection('keySkills')}}>Add Key Skills</span>
                            
                            :item.key === "education" ? <span className='action-name text-warning fw-medium lh-sm pointer' onClick={()=>{scrollToSection('Education--')}}>Add Education</span>
                            :item.key === "resume" ? <span className='action-name text-warning fw-medium lh-sm pointer'>Add Resume</span>
                            :item.key === "perferred_location" ? <span className='action-name text-warning fw-medium lh-sm pointer'  onClick={()=>{openBasicDetailsModal()}}>Add Perferred Location</span>
                            :item.key === "employment" ? <span className='action-name text-warning fw-medium lh-sm pointer'>Add Employment</span>
                            :item.key === "project" ? <span className='action-name text-warning fw-medium lh-sm pointer' onClick={()=>{scrollToSection('Project--')}}>Add Project</span>

                            :<span className='action-name text-warning fw-medium lh-sm pointer'>Add Department</span>
                          
                          }

                        
                      </div>
                      {
                            item.key === "email"?  <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑10%</span>

                            :item.key === "mobile"? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑5%</span>

                            :item.key === "photo"? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑3%</span>

                            :item.key === "resume_headline"?<span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑8%</span>

                            :item.key === "profile_summary" ? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑8%</span>

                            :item.key === "personal_details" ? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑2%</span>

                            :item.key === "job_profile" ? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑8%</span>

                            :item.key === "language_proficiency" ? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑2%</span>

                            :item.key === "key_skills" ? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑8%</span>

                            :item.key === "education" ? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑8%</span>

                            :item.key === "resume" ? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑10%</span>

                            :item.key === "perferred_location" ? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑2%</span>

                            :item.key === "employment" ? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑8%</span>

                            :item.key === "project" ? <span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑8%</span>

                            :<span className='fs-12 fw-medium bg-white text-success rounded-pill pend-item-progress'>↑10%</span>
                          
                          }
                      
                      
                    </div>
  )
})
  // console.log(profileData)
const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const profilePercentage = profileData?.profile_percentage || {};
  
    const TotalPer = 
      (profilePercentage.photo ?? 0) +
      (profilePercentage.mobile ?? 0) +
      (profilePercentage.resume_headline ?? 0) +
      (profilePercentage.profile_summary ?? 0) +
      (profilePercentage.personal_details ?? 0) +
      (profilePercentage.job_profile ?? 0) +
      (profilePercentage.language_proficiency ?? 0) +
      (profilePercentage.key_skills ?? 0) +
      (profilePercentage.education ?? 0) +
      (profilePercentage.resume ?? 0) +
      (profilePercentage.email ?? 0) +
      (profilePercentage.perferred_location ?? 0) +
      (profilePercentage.employment ?? 0) +
      (profilePercentage.project ?? 0) +
      (profilePercentage.department ?? 0);
  
    const Total = isNaN(TotalPer) ? 0 : TotalPer;
    setPercentage(Total);
  
    if (Total < 25) {
      setPathColor('#dc3545');
    } else if (Total < 50) {
      setPathColor('orangered');
    } else if (Total < 70) {
      setPathColor('#ffc107');
    } else if (Total <= 100) {
      setPathColor('#008000');
    }
  }, [profileData?.profile_percentage]);
  // Show Email Verification Modal
  const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);
  const closeEmailVerifyModal = () => setShowEmailVerifyModal(false);
  const openEmailVerifyModal = () => setShowEmailVerifyModal(true);

  // Show Basic Details Modal
const [showBasicDetailsModal, setShowBasicDetailsModal] = useState(false);
const closeBasicDetailsModal = () => setShowBasicDetailsModal(false);
const openBasicDetailsModal = () => setShowBasicDetailsModal(true);

  // Show Upload Profile Modal
  const [showProfileModal, setShowProfileModal] = useState(false);
  const closeProfileModal = () => setShowProfileModal(false);
  const openProfileModal = () => setShowProfileModal(true);

  // Show Resume Headline Modal
  const [showHeadlineModal, setShowHeadlineModal] = useState(false);
  const closeHeadlineModal = () => setShowHeadlineModal(false);
  const openHeadlineModal = () => setShowHeadlineModal(true);

  // Show Key skills Modal
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const closeSkillsModal = () => setShowSkillsModal(false);
  const openSkillsModal = () => setShowSkillsModal(true);

  // Show Add Employment Modal
  const [showEmploymentModal, setShowEmploymentModal] = useState(false);
  const closeEmploymentModal = () => setShowEmploymentModal(false);
  const openEmploymentModal = () => {
    setempdata('')
    setShowEmploymentModal(true)
  };

  // Show Edit Employment Modal
  const [editEmploymentModal, setEditEmploymentModal] = useState(false);
  const closeEditEmploymentModal = () => setEditEmploymentModal(false);
  const openEditEmploymentModal = () => {
    setempdata('')
    setEditEmploymentModal(true)
  };

  // Show All Education Modal
	const [showAllEducationModal, setShowAllEducationModal] = useState(false);
	const closeAllEducationModal = () => setShowAllEducationModal(false);
	const openAllEducationModal = () => { setShowAllEducationModal(true) }

  // Show AddEducation Modal
	const [showEducationModal, setShowEducationModal] = useState(false);
	const closeEducationModal = () => setShowEducationModal(false);
	const openEducationModal = (data, actiontype) => {
		// console.log(data)
		setActiontype(actiontype)
		setShowEducationModal(true)
		setEducationData('')
		if (actiontype === 'add') {
			setEdutype(data)
		}
		if (actiontype === 'update') {
			if (data?.education === "Doctorate/PhD") {
				setEdutype('phd')
				setEducationData(data)
			}
			if (data?.education === "Masters/Post-Graduation") {
				setEdutype('masters')
				setEducationData(data)
			}
			if (data?.education === "Graduation/Diploma") {
				setEdutype('graduation')
				setEducationData(data)
			}
			if (data?.education === "12th") {
				setEdutype('higherSecondary')
				setEducationData(data)
			}
			if (data?.education === "10th") {
				setEdutype('secondary')
				setEducationData(data)
			}
			if (data?.education === "Below 10th") {
				setEdutype('belowten')
				setEducationData(data)
			}
		}
	};

  // // Show AddEducation Modal
  // const [showEducationModal, setShowEducationModal] = useState(false);
  // const closeEducationModal = () => setShowEducationModal(false);
  // const openEducationModal = () => setShowEducationModal(true);

 // Show IT skills Modal
 const [showItSkills, setShowItSkills] = useState(false);
 const [itSkillsBtnClicked, setItSkillsCliked] = useState(false);
 const closeItSkills = () => setShowItSkills(false);
 const openItSkills = (data, actiontype) => {
  if(actiontype === "add"){
    setItSkillsCliked(false)
  } else{
    setItSkillsCliked(true)
  }
 
   setSkillsdata('')
   setShowItSkills(true)
   if (actiontype == 'update') {
     data.actiontype = 'update'
     setSkillsdata(data)
   }
 }

 
  // Show Add Project Modal
  const [showProjectModal, setShowProjectModal] = useState(false);
	const closeProjectModal = () => setShowProjectModal(false);
	const openProjectModal = (data) => {
		setProjectsdata('')
		if (data !== 'add') {
			data.type = 'update'
			setProjectsdata(data)
		}
		setShowProjectModal(true)
	};

  // Show Profile summary Modal
  const [showProfileSummaryModal, setShowProfileSummaryModal] = useState(false);
  const closeProfileSummaryModal = () => setShowProfileSummaryModal(false);
  const openProfileSummaryModal = () => setShowProfileSummaryModal(true);


  // resume headlines
  const resumeHeadLines = async () => {
    toast.success('Resume headlines have been updated.', {
      position: 'bottom-center'
    });
  }

  // load Skills
  const loadskills = async () => {
    const postData = {
      "profileId": profile_id,
    }
    try {
      const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/get-key-skills`, postData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const responsedata = response?.data
      if (responsedata?.statusCode === 200) {
        setUserskills(responsedata?.skills)
      }
    } catch (error) {
      console.log('Error: ', error)
    }
  }
  
  // Edit Employment
  const editEmp = (data) => {
    console.log("Edit emp",data)
    setEditEmploymentModal(true)
    setempdata(data)
  }

  // EmployeeDetails

  // Toaster
  const toasterData = (data) => {
    toast.success(data, {
      position: 'bottom-center'
    });
  }
  // Toaster

 

  const formatDate = (dateString) => {
    console.log(dateString)
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    if (monthIndex >= 0 && monthIndex < monthNames.length) {
      return `${day} ${monthNames[monthIndex].substring(0, 3)}, ${year}`;
    } else {
      // If month index is invalid, return an empty string or handle the error accordingly
      return "";
    }
  };

  const resumeName = (url) => {
    // console.log(url)
    const parts = url.split('/');
    const filename = parts[parts.length - 1];
    return filename
  }

  // downloadResume
  const handleDownload = (url) => {
   
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'resume.docx'); // Set the filename for download
    link.style.display = 'none'; // Hide the link

    // Append the link to the body
    document.body.appendChild(link);
    console.log(link)
    // Trigger the click event
    link.click();

    // Clean up
    document.body.removeChild(link);
  };

  

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <>
            {isReadMore ? text.slice(0, 350) : text}
            {text.length > 350 &&
                <span onClick={toggleReadMore} className="text-info pointer">
                    {isReadMore ? "...Read More" : " Read Less"}
                </span>
            }
        </>
    );
};

// resume section
const handleFileChange = async (event) => {
  // Access the selected file from the input element
  const file = event.target.files[0];
  // Clear the input field
  fileInputRef.current.value = '';

  setSelectedFile(file);
  setUploadDate(new Date()); // Capture the upload date

  // Check if the selected file type is supported
  const supportedTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/rtf', 'application/pdf'];
  if (!supportedTypes.includes(file.type)) {
    setSelectedFile(null);
    setErrorMessage('Unsupported file type. Please select a DOC, DOCX, RTF, or PDF file.');
    return;
  }

  // Check if file size is within the limit
  const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
  if (file.size > maxSize) {
    setSelectedFile(null);
    setErrorMessage('File size exceeds 2 MB limit. Please select a smaller file.');
    return;
  }

  // apiWork
  const formData = new FormData();
  formData.append('profileId', profile_id);
  formData.append('resume', file);
  // setLoading(true)
  try {
    setIsLoading(true)
    const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/upload-resume`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response?.data) {
      const getresponse = response?.data
      const getresumeUrl = getresponse?.location
      const postdata = {
        "profileId": profile_id,
        "type": "resume",
        "resumeHeadLine": "resumeHeadLine",
        "resumeUrl": `${getresumeUrl}`
      }
      const dataStringyfy = JSON.stringify(postdata)
      const resumeresponse = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/resume`, dataStringyfy, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (resumeresponse?.data.statusCode === 200) {
        setProfileData(await getProfileData(profile_id, setIsLoading))
        toast.success('Resume has been successfully uploaded.', {
          position: 'bottom-center'
        });
        // const getMsg = "Resume has been successfully uploaded."
        // callToast(getMsg)
        
        // setLoading(false)
      }
      else {
        setProfileData(await getProfileData(profile_id, setIsLoading))
        toast.error('Something went wrong.', {
          position: 'bottom-center'
        });
      }
    }
  } catch (error) {
    toast.error(error, {
      position: 'bottom-center'
    });
    console.error('Error uploading file:', error);
  }
  // apiWork

  setSelectedFile(file);
  setErrorMessage('');
};

const handleFileDelete = () => {
  setSelectedFile(null);
  setUploadDate(null);
  setTextmessage('Are you sure you want to delete resume?')
  setShowModal(true)
  const sendData = {
    "profileId": profile_id ? profile_id : ''
  }
  setDeleteresumedata(sendData)
  
};
const handleCloseModal = () => {
  setShowModal(false)
}

const deleteResume = async (postData) => {
  try {
    setIsLoading(true)
    const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/delete-resume`, postData, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const responsedata = response?.data
    if (responsedata?.statusCode === 200 || responsedata?.statusCode === 201 || response?.data) {
      setProfileData(await getProfileData(profile_id, setIsLoading))
      const sendMsg = responsedata?.message
      toast.success(sendMsg, {
        position: 'bottom-center'
      })
      // toasterData(sendMsg)
      handleCloseModal()
    }
  } catch (error) {
    setProfileData(await getProfileData(profile_id, setIsLoading))
    console.log('Error: ', error)
  }
}
// end resume section


// console.log("is nan::",isNaN(percentage))
// console.log("PROFILE LOADING.....", isLoading)

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    // section.scrollIntoView({ behavior: 'smooth' });
    section.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
};

  return (

    
    <div className='profilePage py-4'>
      
      <div className='container'>
        <div className='dashboard-component'>
          <div className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
          {isLoading? <div className="loading">Loading&#8230;</div>:null}
           {missingDetails && missingDetails.length > 0 ?
            <div className='row align-items-center g-4'>
              <div className='col-lg-7 col-xl-8'>
                <div className='d-flex flex-column flex-sm-row align-items-center gap-3 gap-xl-4'>
                  
                  <div className='img-wrapper d-flex align-items-center justify-content-center pointer position-relative'>
                    <CircularProgressbarWithChildren value={isNaN(percentage)?0:percentage} strokeWidth={4}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: `${pathcolor}`,
                        trailColor: "#cdcdcd",
                        backgroundColor: '#3e98c7',

                        // Rotation of path and trail, in number of turns (0-1)
                        rotation: 0.5
                      })}
                    >
                      
                      {profileData?.image && profileData?.image.length >0 ?
                    <div className='photoTrigger d-flex position-relative border rounded-circle m-0'>
                     <img src={profileData?.image} alt={profileData?.name} className='img-fluid rounded-circle' style={{ width: '130px', height: '130px' }} />
                      <div className='user-percentage text-danger bg-white rounded-pill position-absolute start-50 bottom-0 translate-middle-x z-1'>{`${percentage}%`}</div>
                      <div className='photoBadge align-items-center justify-content-center position-absolute rounded-circle no-img'>
                        <div className='content-wrap text-center' onClick={openProfileModal}>
                          <div className='icon-wrap bg-secondary bg-opacity-50 rounded-circle d-flex align-items-center justify-content-center mx-auto mb-1 p-2' style={{ width: '30px', height: '30px' }}>
                            <i className='bi bi-plus-circle-fill fs-5'></i>
                          </div>
                          <div className='label fs-6 fw-medium lh-sm'>Change Photo</div>
                        </div>
                      </div>
                      
                    </div>
                    :
                    <div className='photoTrigger d-flex position-relative border rounded-circle m-0'>
                    <img src={require('../resources/images/Avatar.png')} alt='User Avatar' className='img-fluid rounded-circle' style={{ width: '130px', height: '130px' }} />
                    <div className='user-percentage text-danger rounded-pill position-absolute start-50 bottom-0 translate-middle-x z-1'>{`${percentage}%`}</div>
                    <div className='photoBadge align-items-center justify-content-center position-absolute rounded-circle no-img'>
                      <div className='content-wrap text-center' onClick={openProfileModal}>
                        <div className='icon-wrap bg-secondary bg-opacity-50 rounded-circle d-flex align-items-center justify-content-center mx-auto mb-1 p-2' style={{ width: '30px', height: '30px' }}>
                          <i className='bi bi-plus-circle-fill fs-5'></i>
                        </div>
                        <div className='label fs-6 fw-medium lh-sm'>Add Photo</div>
                      </div>
                    </div>
                    
                  </div>
                    }
                    </CircularProgressbarWithChildren>
                  </div>


                  <div className='profile-info flex-fill'>
                    {profileData?.name ?
                      <div className='profile-name'>
                        <div className='fs-4 text-white text-capitalize lh-sm'>{profileData?.name} <i className='bi bi-pencil fs-6 ms-3 pointer' onClick={openBasicDetailsModal}></i></div>
                        <div className="fs-6 text-clr1 text-capitalize">{currempdata?.job_title}</div>
                        <div className="d-flex flex-wrap justify-content-between column-gap-2">
                        {currempdata?.company_name?
                          <div className="job-place text-light text-opacity-75">at <span className='text-capitalize'>{currempdata?.company_name}</span></div>
                          :null}

                          <div className='profile-update-date text-clr1 fw-light'>Profile last updated - <span className='text-white text-opacity-75 fw-medium text-capitalize'>{formatDate(profileData?.profile_percentage?.updatedAt)}</span></div></div>
                      </div>

                      : null}
                    <hr />
                    <div className='d-flex flex-column flex-xl-row gap-2 gap-xl-3'>
                      <div className='left-box flex-fill pe-3'>
                        {profileData?.is_outside_india ?
                          <div className='detail-item d-flex gap-2 lh-sm mb-2 mb-xl-3'>
                            <span className='icon'><i className='bi bi-geo-alt text-white text-opacity-75'></i></span>
                            <span className='fw-medium text-primary' onClick={openBasicDetailsModal}>{profileData?.current_city ? profileData?.current_city +", "+profileData?.current_country : 'Add location'}</span>
                          </div>
                          : <div className='detail-item d-flex gap-2 lh-sm mb-2 mb-xl-3'>
                          <span className='icon'><i className='bi bi-geo-alt text-white text-opacity-75'></i></span>
                          <span className='fw-medium text-primary' onClick={openBasicDetailsModal}>{profileData?.current_city ? profileData?.current_city : 'Add location'}</span>
                        </div>}
                        {profileData?.type ?
                          <div className='detail-item d-flex gap-2 lh-sm mb-2 mb-xl-3'>
                            <span className='icon'><i className='bi bi-box-seam text-white text-opacity-75'></i></span>
                            <span className='text-capitalize'>{profileData?.type}</span>
                          </div>
                          : null}
                        <div className='detail-item d-flex gap-2 lh-sm'>
                          <span className='icon'><i className='bi bi-wallet2 text-white text-opacity-75'></i></span>
                          <span>{currempdata?.salary_type === 'Inr' ? "₹" : '$'} {currempdata?.salary ? currempdata?.salary : ""}</span>
                        </div>

                      </div>
                      <div className='right-box flex-fill'>
                        {profileData?.mobile ?
                          <div className='detail-item d-flex gap-2 lh-sm mb-2 mb-xl-3'>
                            <span className='icon'><i className='bi bi-telephone text-white text-opacity-75'></i></span>
                            <span className='d-flex align-items-center gap-2'>{profileData?.mobile} {profileData?.is_mobile_verified == 1 ? <span className='availibility-icon'><i className='bi bi-patch-check-fill fs-6 text-success position-relative'></i></span> : ''}</span>
                          </div>
                          : null}
                        {profileData?.email ?
                          <div className='detail-item d-flex gap-2 lh-sm mb-2 mb-xl-3'>
                            <span className='icon'><i className='bi bi-envelope text-white text-opacity-75'></i></span>
                            <div>
                              <span className='text-lowercase me-2'>{profileData?.email}</span>
                              <span className='fw-medium text-primary'>{profileData?.is_email_verified ? <span className='availibility-icon'><i className='bi bi-patch-check-fill fs-6 text-success position-relative'></i></span> : ''}</span>
                            </div>
                          </div>
                          : null}
                        {/* {profileData?.availability ? */}
                          <div className='detail-item d-flex gap-2 lh-sm'>
                            <span className='icon'><i className='bi bi-calendar-week text-white text-opacity-75'></i></span>
                            <span>{currempdata?.notice_period? currempdata?.notice_period : "Add availability to join"}</span>
                          </div>
                          {/* : null} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
              <div className='col-lg-5 col-xl-4' style={{maxHeight:"200px", overflowY:"scroll"}}>
                <div className='pending-actions card bg-primary bg-opacity-50 rounded-3 p-3'>
                  <div className='detail-list mt-1'>
                    {getMissingDetils}
                    
                  </div>
                  <div className='btn-wrapper text-center mt-2 mb-1'>
                    <Link to={'#'} className='btn btn-danger fw-medium rounded-pill px-3' >Add <span id='add-missing-details'></span> missing details</Link>
                  </div>
                </div>
              </div>
            
            </div>

            :
            <div className='row align-items-center g-4'>
              <div className='col-lg-12 col-xl-12'>
                <div className='d-flex flex-column flex-sm-row align-items-center gap-3 gap-xl-4'>
                  
                  <div className='img-wrapper d-flex align-items-center justify-content-center pointer position-relative'>
                    <CircularProgressbarWithChildren value={isNaN(percentage)?0:percentage} strokeWidth={4}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: `${pathcolor}`,
                        trailColor: "#cdcdcd",
                        backgroundColor: '#3e98c7',

                        // Rotation of path and trail, in number of turns (0-1)
                        rotation: 0.5
                      })}
                    >
                      
                      {profileData?.image && profileData?.image.length >0 ?
                    <div className='photoTrigger d-flex position-relative border rounded-circle m-0'>
                     <img src={profileData?.image} alt={profileData?.name} className='img-fluid rounded-circle' style={{ width: '130px', height: '130px' }} />
                      <div className='user-percentage text-danger bg-white rounded-pill position-absolute start-50 bottom-0 translate-middle-x z-1'>{`${percentage}%`}</div>
                      <div className='photoBadge align-items-center justify-content-center position-absolute rounded-circle no-img'>
                        <div className='content-wrap text-center' onClick={openProfileModal}>
                          <div className='icon-wrap bg-secondary bg-opacity-50 rounded-circle d-flex align-items-center justify-content-center mx-auto mb-1 p-2' style={{ width: '30px', height: '30px' }}>
                            <i className='bi bi-plus-circle-fill fs-5'></i>
                          </div>
                          <div className='label fs-6 fw-medium lh-sm'>Change Photo</div>
                        </div>
                      </div>
                      
                    </div>
                    :
                    <div className='photoTrigger d-flex position-relative border rounded-circle m-0'>
                    <img src={require('../resources/images/Avatar.png')} alt='User Avatar' className='img-fluid rounded-circle' style={{ width: '130px', height: '130px' }} />
                    <div className='user-percentage text-danger rounded-pill position-absolute start-50 bottom-0 translate-middle-x z-1'>{`${percentage}%`}</div>
                    <div className='photoBadge align-items-center justify-content-center position-absolute rounded-circle no-img'>
                      <div className='content-wrap text-center' onClick={openProfileModal}>
                        <div className='icon-wrap bg-secondary bg-opacity-50 rounded-circle d-flex align-items-center justify-content-center mx-auto mb-1 p-2' style={{ width: '30px', height: '30px' }}>
                          <i className='bi bi-plus-circle-fill fs-5'></i>
                        </div>
                        <div className='label fs-6 fw-medium lh-sm'>Add Photo</div>
                      </div>
                    </div>
                    
                  </div>
                    }
                    </CircularProgressbarWithChildren>
                  </div>


                  <div className='profile-info flex-fill'>
                    {profileData?.name ?
                      <div className='profile-name'>
                        <div className='fs-4 text-white text-capitalize lh-sm'>{profileData?.name} <i className='bi bi-pencil fs-6 ms-3 pointer' onClick={openBasicDetailsModal}></i></div>
                        <div className="fs-6 text-clr1 text-capitalize">{currempdata?.job_title}</div>
                        <div className="d-flex flex-wrap justify-content-between column-gap-2">
                        {currempdata?.company_name?
                          <div className="job-place text-light text-opacity-75">at <span className='text-capitalize'>{currempdata?.company_name}</span></div>
                          :null}

                          <div className='profile-update-date text-clr1 fw-light'>Profile last updated - <span className='text-white text-opacity-75 fw-medium text-capitalize'>{formatDate(profileData?.profile_percentage?.updatedAt)}</span></div></div>
                      </div>

                      : null}
                    <hr />
                    <div className='d-flex flex-column flex-xl-row gap-2 gap-xl-3'>
                      <div className='left-box flex-fill pe-3'>
                        {profileData?.is_outside_india ?
                          <div className='detail-item d-flex gap-2 lh-sm mb-2 mb-xl-3'>
                            <span className='icon'><i className='bi bi-geo-alt text-white text-opacity-75'></i></span>
                            <span className='fw-medium text-primary' onClick={openBasicDetailsModal}>{profileData?.current_city ? profileData?.current_city +", "+profileData?.current_country : 'Add location'}</span>
                          </div>
                          : <div className='detail-item d-flex gap-2 lh-sm mb-2 mb-xl-3'>
                          <span className='icon'><i className='bi bi-geo-alt text-white text-opacity-75'></i></span>
                          <span className='fw-medium text-primary' onClick={openBasicDetailsModal}>{profileData?.current_city ? profileData?.current_city : 'Add location'}</span>
                        </div>}
                        {profileData?.type ?
                          <div className='detail-item d-flex gap-2 lh-sm mb-2 mb-xl-3'>
                            <span className='icon'><i className='bi bi-box-seam text-white text-opacity-75'></i></span>
                            <span className='text-capitalize'>{profileData?.type}</span>
                          </div>
                          : null}
                        <div className='detail-item d-flex gap-2 lh-sm'>
                          <span className='icon'><i className='bi bi-wallet2 text-white text-opacity-75'></i></span>
                          <span>{currempdata?.salary_type === 'Inr' ? "₹" : '$'} {currempdata?.salary ? currempdata?.salary : ""}</span>
                        </div>

                      </div>
                      <div className='right-box flex-fill'>
                        {profileData?.mobile ?
                          <div className='detail-item d-flex gap-2 lh-sm mb-2 mb-xl-3'>
                            <span className='icon'><i className='bi bi-telephone text-white text-opacity-75'></i></span>
                            <span className='d-flex align-items-center gap-2'>{profileData?.mobile} {profileData?.is_mobile_verified == 1 ? <span className='availibility-icon'><i className='bi bi-patch-check-fill fs-6 text-success position-relative'></i></span> : ''}</span>
                          </div>
                          : null}
                        {profileData?.email ?
                          <div className='detail-item d-flex gap-2 lh-sm mb-2 mb-xl-3'>
                            <span className='icon'><i className='bi bi-envelope text-white text-opacity-75'></i></span>
                            <div>
                              <span className='text-lowercase me-2'>{profileData?.email}</span>
                              <span className='fw-medium text-primary'>{profileData?.is_email_verified ? <span className='availibility-icon'><i className='bi bi-patch-check-fill fs-6 text-success position-relative'></i></span> : ''}</span>
                            </div>
                          </div>
                          : null}
                        {/* {profileData?.availability ? */}
                          <div className='detail-item d-flex gap-2 lh-sm'>
                            <span className='icon'><i className='bi bi-calendar-week text-white text-opacity-75'></i></span>
                            <span>{currempdata?.notice_period? currempdata?.notice_period : "Add availability to join"}</span>
                          </div>
                          {/* : null} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
              
            
            </div>
                        }
          </div>
        </div>

        <div className='row card-info-section mt-3'>
          <div className='col-md-4 col-xl-3'>
            <div className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 p-3'>
              <h5 className='text-white mb-0 p-2'>Quick Links</h5>
              <div className='menu-links d-flex justify-content-between gap-2 rounded-pill mt-2'>
                <div className='qlinks-name text-white text-opacity-75 text-truncate'>Resume</div>
                {/* {profileData?.resume?.resume_url ?
                <Link to={'#'} className='qlinks-action fs-14 btn btn-link text-decoration-none text-primary fw-medium lh-sm p-0' htmlFor='file-upload'>Update</Link>
                :
                <Link to={'#'} className='qlinks-action fs-14 btn btn-link text-decoration-none text-primary fw-medium lh-sm p-0' htmlFor='file-upload'>Upload</Link>
                } */}
              </div>
              <div className='menu-links d-flex justify-content-between gap-2 rounded-pill mt-2'>
                <div className='qlinks-name text-white text-opacity-75 text-truncate pointer' onClick={() => scrollToSection('resumeHeadline')}>Resume headline</div>
                {/* <Link to={'#'} onClick={openHeadlineModal} className='qlinks-action fs-14 btn btn-link text-decoration-none text-primary fw-medium lh-sm p-0'>Add</Link> */}
              </div>
              <div className='menu-links d-flex justify-content-between gap-2 rounded-pill mt-2'>
                <div className='qlinks-name text-white text-opacity-75 text-truncate pointer' onClick={()=>{scrollToSection('keySkills')}}>Key skills</div>
                {/* <Link to={'#'} onClick={openSkillsModal} className='qlinks-action fs-14 btn btn-link text-decoration-none text-primary fw-medium lh-sm p-0'>Add</Link> */}
              </div>
              <div className='menu-links d-flex justify-content-between gap-2 rounded-pill mt-2 pointer' onClick={()=>{scrollToSection('Education--')}}>
                <div className='qlinks-name text-white text-opacity-75 text-truncate'>Education</div>
                <Link to={'#'} className='qlinks-action fs-14 btn btn-link text-decoration-none text-primary fw-medium lh-sm p-0' onClick={openAllEducationModal}>Add</Link>
              </div>
              <div className='menu-links d-flex justify-content-between gap-2 rounded-pill mt-2'>
                <div className='qlinks-name text-white text-opacity-75 text-truncate pointer' onClick={()=>{scrollToSection('ITSKILLS')}}>IT skills</div>
                <div className='qlinks-action'></div>
              </div>
              <div className='menu-links d-flex justify-content-between gap-2 rounded-pill mt-2 pointer' onClick={()=>{scrollToSection('Project--')}}>
                <div className='qlinks-name text-white text-opacity-75 text-truncate'>Projects</div>
                <Link to={'#'} className='qlinks-action fs-14 btn btn-link text-decoration-none text-primary fw-medium lh-sm p-0' onClick={() => openProjectModal('add')}>Add</Link>
              </div>
              <div className='menu-links d-flex justify-content-between gap-2 rounded-pill mt-2'>
                <div className='qlinks-name text-white text-opacity-75 text-truncate pointer' onClick={()=>{scrollToSection('Profile-Summary')}}>Profile summary</div>
                {/* <Link to={'#'} className='qlinks-action fs-14 btn btn-link text-decoration-none text-primary fw-medium lh-sm p-0'>Add</Link> */}
              </div>
              <div className='menu-links d-flex justify-content-between gap-2 rounded-pill mt-2'>
                <div className='qlinks-name text-white text-opacity-75 text-truncate pointer' onClick={()=>{scrollToSection('Accomplishments')}}>Accomplishments</div>
                <div className='qlinks-action fs-14 btn btn-link text-decoration-none text-primary fw-medium lh-sm p-0'></div>
              </div>
              <div className='menu-links d-flex justify-content-between gap-2 rounded-pill mt-2'>
                <div className='qlinks-name text-white text-opacity-75 text-truncate pointer' onClick={()=>{scrollToSection('Career-Profile')}}>Career profile</div>
                <div className='qlinks-action fs-14 btn btn-link text-decoration-none text-primary fw-medium lh-sm p-0'></div>
              </div>
              <div className='menu-links d-flex justify-content-between gap-2 rounded-pill mt-2'>
                <div className='qlinks-name text-white text-opacity-75 text-truncate pointer' onClick={()=>{scrollToSection('Personal-Details')}}>Personal details</div>
                <div className='qlinks-action fs-14 btn btn-link text-decoration-none text-primary fw-medium lh-sm p-0'></div>
              </div>
            </div>
          </div>
          <div className='col-md-8 col-xl-9 mt-3 mt-md-0'>
            <div className='d-flex flex-column gap-3'>

              {/* Resume Upload Card Section */}
							<div className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
								{/* Show this section when you edit or add any changes */}
								{/* <div className="successAlert d-flex gap-3 border-bottom border-success mb-3 pb-3">
									<i className="bi bi-check-circle-fill text-success fs-5"></i>
									<div className="right text-white">
										<div className="head fs-18 fw-medium">Success</div>
										<div className="msg">Resume Headline has been successfully saved.</div>
									</div>
								</div> */}
								{/* {activetoast ? <SuccessToast sucessmessage={sendtoastmsg} /> : ""} */}
								<div className="heading-wrapper mb-3">
									<div className='widgetTitle text-white fs-6 fw-medium mb-3'>Resume <span className='profileComScore fs-14 text-success ms-1'>Add 10%</span></div>
									{/* <div className="emp-desc">70% of recruiters discover candidates through their resume</div> */}
								</div>
								<div className='upload-resume-sec'>
									{/* {selectedFile &&  */}
									{profileData?.resume?.resume_url ?
										(
											<div className='d-flex align-items-center justify-content-between mb-3'>
												<div className='left-sec'>
													<div className='fileInfoWrapper'>
														<div className='fileName text-warning fs-14'>{resumeName(profileData?.resume?.resume_url)}</div>
														{profileData?.resume?.updatedAt && (
															<div className='fileDate text-info fs-14 fw-light'>Uploaded on {formatDate(profileData?.resume?.updatedAt)}</div>
														)}
													</div>
												</div>
												<div className='d-flex flex-wrap gap-3'>
													{/* {selectedFile.name} */}
													{/* <Link to={profileData?.resume?.resume_url} className='icon bg-primary bg-opacity-25 d-flex align-items-center justify-content-center rounded-circle pointer' download style={{ height: '35px', width: '35px' }}>
														<i className='bi bi-download text-white' onClick={()=>{handleDownload(profileData?.resume?.resume_url)}}></i>
													</Link> */}
                          <div>
      <a
        href={profileData?.resume?.resume_url}
        className='icon bg-primary bg-opacity-25 d-flex align-items-center justify-content-center rounded-circle pointer'
        target="_blank"
        rel="noopener noreferrer"
        download
        style={{ height: '35px', width: '35px' }}
        // onClick={() => handleDownload(profileData?.resume?.resume_url)}
      >
        <i className='bi bi-download text-white'></i>
      </a>
    </div>
													<span onClick={handleFileDelete} className='icon bg-primary bg-opacity-25 d-flex align-items-center justify-content-center rounded-circle pointer' style={{ height: '35px', width: '35px' }}>
														<i className='bi bi-trash3 text-white'></i>
													</span>
												</div>
											</div>
										) : (<div className="emp-desc mb-2">70% of recruiters discover candidates through their resume</div>)}
								</div>
								<div className='border-1 rounded-3 text-center p-4' style={{ borderStyle: 'dashed' }}>
									<input type='file' ref={fileInputRef} name='file' id='file-upload' accept=".doc,.docx,.rtf,.pdf" onChange={(e) => handleFileChange(e)} style={{ display: 'none' }} />
									<label htmlFor='file-upload' className='btn btn-sm btn-outline-primary rounded-pill px-3 mt-1 mb-2'>{profileData?.resume?.resume_url === null ? "Upload resume" : "Update resume"}</label>
									{errorMessage && <div className='text-danger my-1'>{errorMessage}</div>}
									<div className='widgetCont text-white text-opacity-75'>Supported Formats: doc, docx, rtf, pdf, upto 2 MB</div>
								</div>
							</div>

              {/* Resume headline Card Section */}
              <div id="resumeHeadline" className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
                <div className='widgetHead d-flex align-items-center justify-content-between gap-1 mb-3'>
                  <span className='widgetTitle text-white fs-6 fw-medium'>Resume headline
                    {profileData?.resume?.resume_headline ?
                    <span className="edit-icon ms-3 pointer"><i className="bi bi-pencil fs-6 text-clr1 lh-1" onClick={openHeadlineModal}></i></span>
                      
                      :
                      <span className='profileComScore fs-14 text-success ms-1'>Add 8%</span>}
                  </span>
                  {!profileData?.resume?.resume_headline ?
                    <Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={openHeadlineModal}>Add resume headline</Link>
                    : null}
                </div>

                
                <div className='widgetCont text-white text-opacity-75'>
                  {profileData?.resume?.resume_headline ? profileData?.resume?.resume_headline : 'Add a summary of your resume to introduce yourself to recruiters'}
                </div>
              </div>

              {/* Add Key skills Card Section */}
              <div id='keySkills' className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
              {profileData?.key_skills && profileData?.key_skills.length ===0?
                <div className='widgetHead d-flex align-items-center justify-content-between gap-1 mb-3'>
                 
                 
                  <span className='widgetTitle text-white fs-6 fw-medium'>Key skills 
                  
                   
                  <span className='profileComScore fs-14 text-success ms-1'>Add 8%</span>
                  
                  </span>
                  <Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={openSkillsModal} >Add key skills</Link>
                 
                  
               
                </div>
                :
                <div className='widgetHead d-flex align-items-center justify-content-between gap-1 mb-3'>
                 
                 
                  <span className='widgetTitle text-white fs-6 fw-medium'>Key skills 
                  
                   
                  <span className="edit-icon ms-3 pointer"><i className="bi bi-pencil fs-6 text-clr1 lh-1" onClick={openSkillsModal}></i></span>
                  
                  </span>
                 
                 
                  
               
                </div>
              }


                <div className='widgetCont text-white text-opacity-75'>
                  {profileData?.key_skills && profileData?.key_skills.length > 0?
                    <div className="skills-wrapper d-flex flex-wrap gap-2 mt-3">
                      {profileData?.key_skills.map((item) => (
                        <button key={item?.id} type='button' className='btn btn-sm btn-secondary bg-opacity-50 rounded-pill py-1 px-3'>{item?.key_skills}</button>
                      ))}
                    </div>
                    : <div>Recruiters look for candidates with specific key skills</div>}
                </div>
              </div>

              {/* Add Employment Card Section */}
              <div className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
                <div className='widgetHead d-flex align-items-center justify-content-between gap-1 mb-3'>
                  <span className='widgetTitle text-white fs-6 fw-medium'>Employment <span className='profileComScore fs-14 text-success ms-1'>Add 8%</span></span>
                  {employmentData && employmentData?.length > 0 && employmentData[0].company_name === null?
                  <Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={() => editEmp(employmentData[0])}>Add Employment</Link>
                  :
                  <Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={openEmploymentModal}>Add Employment</Link>
                  }
                </div>

                <div className='widgetCont employment-info-wrapper d-flex flex-column gap-4'>
                  {employmentData && employmentData?.length > 0 && employmentData[0].company_name != null && employmentData.map((item) => (
                    <div className="employment-info">
                      {item?.job_title?
                      <div className='d-flex gap-3 mb-1'>
                        <span className='emp-desg fw-semibold text-truncate text-white text-opacity-75' title={item?.job_title}>{item?.job_title}</span>
                        <span className='edit-icon' onClick={() => editEmp(item)}><i className='bi bi-pencil fs-6 text-clr1 lh-1'></i></span>
                      </div>
                      :null}
                      {item?.company_name?
                      <div class='emp-org fw-medium text-white text-opacity-75' title={item?.company_name}>{item?.company_name}</div>
                      :null}
                      {item?.employment_type?
                      <div class='experienceType d-flex gap-1 text-muted'>
                        <span class='expType text-truncate'>{item?.employment_type}</span>
                        <span class='ver-line'>|</span>
                        <span class='text-truncate'>{item?.is_current_employment === 1 ? new Date(item?.joining_date).toLocaleString('default', { month: 'long', year: 'numeric' }) + " To Present" : new Date(item?.working_from).toLocaleString('default', { month: 'long', year: 'numeric' }) +" - "+new Date(item?.worked_till).toLocaleString('default', { month: 'long', year: 'numeric' })} ({item?.is_current_employment === 1 ?calculateDuration(item?.joining_date,getCurrentYearMonth()):calculateDuration(item?.working_from,item?.worked_till)})</span>
                      </div>
                      :null}
                      {item?.notice_period?
                      <div className="noticePeriod text-warning text-opacity-75">{item?.notice_period} Notice Period</div>
                      :null}
                      {item?.job_profile?
                      <div className="emp-desc mt-1">
                        <ReadMore>{item?.job_profile}</ReadMore>
                      </div>
                      :null}
                      {item?.employment_skills.length > 0?
                      <div className="top-skills d-flex flex-wrap mt-1">
                        <span className='label text-light text-opacity-75 fw-medium me-2'>Top 5 key skills:</span>
                        <span className='skillsList text-clr1' style={{ wordBreak: 'break-all' }}>{item?.employment_skills.map(skill => skill.skill).join(', ')}</span>
                      </div>
                      :null}
                      {/* <div className="project-list mt-1">
                        <div className="label text-clr1 mb-1">Projects</div>
                        <span className="d-inline-block text-light text-opacity-75 border border-light border-opacity-75 rounded-pill py-2 px-3 lh-sm">Naukri Interview 2.0</span>
                      </div> */}
                    </div>
                  )) }

                  {employmentData && employmentData?.length > 0 && employmentData[0].company_name === null?
                  "Your employment details will help recruiters understand your experience":null}
                </div>
              </div>

              
              {/* Add Education Card Section */}
							<div id="Education--" className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
								<div className='widgetHead d-flex flex-wrap flex-wrap align-items-center justify-content-between gap-1 mb-3'>
									<span className='widgetTitle text-white fs-6 fw-medium'>Education <span className='profileComScore fs-14 text-success ms-1'>Add 8%</span></span>
									<Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={openAllEducationModal}>Add Education</Link>
								</div>

								<div className='widgetCont education-info-wrapper d-flex flex-column gap-3'>
								
									{profileData?.education && profileData?.education?.map((elementInArray, index) => (
										<div className="education-info">
											<div className='d-flex gap-3 mb-1'>
												<span className='emp-course fw-semibold text-truncate text-white text-opacity-75' title={elementInArray?.course !== '' ? elementInArray?.course : elementInArray?.education}>{elementInArray?.course !== '' ? elementInArray?.course : elementInArray?.education}</span>
												<span className='edit-icon' onClick={() => openEducationModal(elementInArray, 'update')}><i className='bi bi-pencil fs-6 text-clr1 lh-1'></i></span>
											</div>
											<div className='emp-university text-white text-opacity-75' title={elementInArray?.university !== '' ? elementInArray?.university : elementInArray?.board}>{elementInArray?.university !== '' ? elementInArray?.university : elementInArray?.board}</div>
											<div className='eduInfo text-muted'>{elementInArray?.course_duration ? elementInArray?.course_duration : elementInArray?.passing_out_year !== 0 ? elementInArray?.passing_out_year : ""} {elementInArray?.course_type ? '| ' + elementInArray?.course_type : ''}</div>
											{/* <div className="project-list mt-1">
												<div className="label text-clr1 mb-1">Projects</div>
												<span className="d-inline-block text-light text-opacity-75 border border-light border-opacity-75 rounded-pill py-2 px-3 lh-sm">Naukri Interview 2.0</span>
											</div> */}
										</div>
									))}
								</div>

								<div className='widgetCont mt-3'>
                {profileData?.education && profileData?.education?.length >= 1 ? '' : <div className='emp-desc'>Your qualifications help employers know your educational background</div>}
									<div className='educationLinks d-flex flex-column gap-3 mt-3'>
										{selectedEducation?.includes("Doctorate/PhD") ? '' : <Button variant='link' onClick={() => openEducationModal('phd', 'add')} className='fs-14 fw-medium text-decoration-none text-start border-0 p-0'>Add doctorate/PhD</Button>}
										{selectedEducation?.includes("Masters/Post-Graduation") ? '' : <Button variant='link' onClick={() => openEducationModal('masters', 'add')} className='fs-14 fw-medium text-decoration-none text-start border-0 p-0'>Add masters/post-graduation</Button>}
										{selectedEducation?.includes("Graduation/Diploma") ? '' : <Button variant='link' onClick={() => openEducationModal('graduation', 'add')} className='fs-14 fw-medium text-decoration-none text-start border-0 p-0'>Add graduation/diploma</Button>}
										{selectedEducation?.includes("12th") ? '' : <Button variant='link' onClick={() => openEducationModal('higherSecondary', 'add')} className='fs-14 fw-medium text-decoration-none text-start border-0 p-0'>Add class XII</Button>}
										{selectedEducation?.includes("10th") ? '' : <Button variant='link' onClick={() => openEducationModal('secondary', 'add')} className='fs-14 fw-medium text-decoration-none text-start border-0 p-0'>Add class X</Button>}
										{selectedEducation?.includes("Below 10th") ? '' : <Button variant='link' onClick={() => openEducationModal('belowten', 'add')} className='fs-14 fw-medium text-decoration-none text-start border-0 p-0'>Add below 10th</Button>}
									</div>
								</div>
							</div>

             

              {/* IT Skills Card Section */}
							<div id="ITSKILLS" className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
								<div className='widgetHead d-flex flex-wrap align-items-center justify-content-between gap-1 mb-3'>
									<span className='widgetTitle text-white fs-6 fw-medium'>IT skills</span>
									<Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={() => openItSkills('data', 'add')} >Add details</Link>
								</div>

								<div className='widgetCont'>
									{profileData?.it_skills && profileData?.it_skills?.length ===0?
									<div className='emp-desc'>Recruiters look for candidates with specific key skills</div>
                  :

									<div className="table-responsive">
										<table className='table table-dark table-custom border-secondary border-opacity-75 mb-0'>
											<thead>
												<tr>
													<th className='text-white text-opacity-75 fw-medium text-nowrap'>Skills</th>
													<th className='text-white text-opacity-75 fw-medium text-nowrap'>Version</th>
													<th className='text-white text-opacity-75 fw-medium text-nowrap'>Last used</th>
													<th className='text-white text-opacity-75 fw-medium text-nowrap'>Experience</th>
													<th className='text-white text-opacity-75 fw-medium text-nowrap'></th>
												</tr>
											</thead>
											<tbody>
												{profileData?.it_skills && profileData?.it_skills?.map((elementInArray, index) => (
													<tr>
														<td className='text-clr1 text-nowrap'>{elementInArray?.skill_software_name}</td>
														<td className='text-clr1 text-nowrap'>{elementInArray?.software_version}</td>
														<td className='text-clr1 text-nowrap'>{elementInArray?.last_used}</td>
														<td className='text-clr1 text-nowrap'>{elementInArray?.experience_year + " " + elementInArray?.experience_month}</td>
														<td className='text-clr1 text-nowrap'><span className="edit-icon" onClick={() => openItSkills(elementInArray, 'update')}><i className="bi bi-pencil fs-6 text-clr1 lh-1"></i></span></td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
                  }
								</div>
							</div>

              {/* Add Projects Card Section */}
							<div id="Project--" className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
								<div className='widgetHead d-flex flex-wrap align-items-center justify-content-between gap-1 mb-3'>
									<span className='widgetTitle text-white fs-6 fw-medium'>Projects <span className='profileComScore fs-14 text-success ms-1'>Add 8%</span></span>
									<Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={() => openProjectModal('add')}>Add project</Link>
								</div>

								<div className='widgetCont project-info-wrapper d-flex flex-column gap-4'>
									{profileData?.projects && profileData?.projects?.map((elementInArray, index) => (
										<div className="project-info">
											<div className='d-flex gap-3 mb-1'>
												<span className='proj-name fw-semibold text-truncate text-white text-opacity-75' title={elementInArray?.project_title}>{elementInArray?.project_title}</span>
												<span className='edit-icon' onClick={() => openProjectModal(elementInArray)}><i className='bi bi-pencil fs-6 text-clr1 lh-1'></i></span>
											</div>
											<div className='client-name text-white text-opacity-75' title={elementInArray?.client ? elementInArray?.client : ""}>{elementInArray?.client ? elementInArray?.client : ""} ({elementInArray?.project_location ? elementInArray?.project_location + ":" : ""} {elementInArray?.project_site ? elementInArray?.project_site : "Onsite"})</div>
											<div className='projInfo text-clr1'>{elementInArray?.worked_from ? elementInArray?.worked_from : ""} to {elementInArray?.worked_till ? elementInArray?.worked_till : "Present"} ({elementInArray?.nature_of_employment ? elementInArray?.nature_of_employment : "Full Time"})</div>
											<div className="proj-desc mt-1">
												<ReadMore>
													{elementInArray?.project_details ? elementInArray?.project_details : ""}
												</ReadMore>
											</div>
										</div>
									))}
								</div>
							</div>

              {/* Add Profile summary Card Section */}
							<div id="Profile-Summary" className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
								<div className='widgetHead d-flex flex-wrap align-items-center justify-content-between gap-1 mb-3'>
									{profileData?.profile_summary ? 
                  
                  <span className='widgetTitle text-white fs-6 fw-medium'>Profile summary <span className="edit-icon ms-3" onClick={openProfileSummaryModal}><i className="bi bi-pencil fs-6 text-clr1 lh-1"></i></span></span>
										: 
                    
                    <><span className='widgetTitle text-white fs-6 fw-medium'>Profile summary</span> <Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={openProfileSummaryModal}>Add profile summary</Link></>}
								</div>

								<div className='widgetCont'>
									{/* Uncomment this for normal case */}

									{profileData?.profile_summary ?

										<div className='summary-desc'>
											<ReadMore>{profileData?.profile_summary?.profile_summary}</ReadMore>
										</div>
                    
                    : <div className='emp-desc'>Highlight your key career achievements to help employers know your potential</div>}

								</div>
							</div>

              {/* Accomplishments Card Section */}
              <AccomplishmentsCard profileId={profile_id} setProfileData={setProfileData} profileData={profileData} setIsLoading={setIsLoading}/>

             {/* Career Profile Card Section */}
             
            <CareerProfileCard profileId={profile_id} setProfileData={setProfileData} profileData={profileData} setIsLoading={setIsLoading}/>

            {/* Personal Details Card Section */}
            <PersonalDetailsCard profileId={profile_id} setProfileData={setProfileData} profileData={profileData} setIsLoading={setIsLoading} currempdata={currempdata}/>
            </div>
          </div>
        </div>

      </div>

      {/* Toaster */}
      <WarningModal show={showModal} onClose={handleCloseModal} textmessage={textmessage} updateEmp={deleteResume} deleteEmp={deleteresumedata} />
      {/* <ToastContainer /> */}
      {/* Toaster */}

      {/* All Modal List Section */}
      <ProfilePhotoUpload showProfileModal={showProfileModal} closeProfileModal={closeProfileModal} profileId={profile_id} setProfileData={setProfileData} setShowProfileModal={setShowProfileModal} image={profileData?.image} setIsLoading={setIsLoading}/>
      
      {/* <BasicDetails showBasicDetailsModal={showBasicDetailsModal} closeBasicDetailsModal={closeBasicDetailsModal} /> */}

      <BasicDetails showBasicDetailsModal={showBasicDetailsModal} closeBasicDetailsModal={closeBasicDetailsModal} empInfo={profileData} currempdata={currempdata} setCurrempdata={setCurrempdata} LoadUserDetails={profileData} toasterData={toasterData} profileId={profile_id} setProfileData={setProfileData} setIsLoading={setIsLoading} setShowBasicDetailsModal={setShowBasicDetailsModal} setEmploymentData={setEmploymentData}/>

      <ResumeHeadline show={showHeadlineModal} handleClose={closeHeadlineModal} resumetoast={resumeHeadLines} empinformation={profileData} profileData={profileData?.resume} profileId={profile_id} setProfileData={setProfileData} setIsLoading={setIsLoading} setShowHeadlineModal={setShowHeadlineModal}/>

      <AddKeySkills showSkillsModal={showSkillsModal} closeSkillsModal={closeSkillsModal} profileData={profileData} profileId={profile_id} setProfileData={setProfileData} setShowSkillsModal={setShowSkillsModal} setIsLoading={setIsLoading}/>

      <AddEmployment showEmploymentModal={showEmploymentModal} closeEmploymentModal={closeEmploymentModal} sendEmploymentdata={sendempdata} empdataload={profileData} profileId={profile_id} setIsLoading={setIsLoading} employmentData={employmentData} setEmploymentData={setEmploymentData} setProfileData={setProfileData} setShowEmploymentModal={setShowEmploymentModal} setCurrempdata={setCurrempdata}/>

      <EditEmployment editEmploymentModal={editEmploymentModal} closeEditEmploymentModal={closeEditEmploymentModal} sendEmploymentdata={sendempdata} empdataload={profileData} toasterData={toasterData} profileId={profile_id} setProfileData={setProfileData} setEditEmploymentModal={setEditEmploymentModal} profileData={profileData} setIsLoading={setIsLoading} employmentData={employmentData} setEmploymentData={setEmploymentData} setCurrempdata={setCurrempdata}/>

      <AddEducation showEducationModal={showEducationModal} closeEducationModal={closeEducationModal} edutype={edutype} toasterData={toasterData} profileId={profile_id} setShowEducationModal={setShowEducationModal} setProfileData={setProfileData} sendeducationData={sendeducationData} sendactiontype={sendactiontype} profileData={profileData} setIsLoading={setIsLoading} />

      <AllEducation showAllEducationModal={showAllEducationModal} closeAllEducationModal={closeAllEducationModal} toasterData={toasterData} empdataload={profileData} setProfileData={setProfileData} setShowAllEducationModal={setShowAllEducationModal} profileId={profile_id} setIsLoading={setIsLoading}/>

      <AddITSkills showItSkills={showItSkills} closeItSkills={closeItSkills} toasterData={toasterData} Itskills={profileData} sendSkillsdata={sendSkillsdata} profileId={profile_id} setShowItSkills={setShowItSkills} setProfileData={setProfileData} setItSkillsCliked={setItSkillsCliked} itSkillsBtnClicked={itSkillsBtnClicked} setIsLoading={setIsLoading}/>

      <AddProject showProjectModal={showProjectModal} closeProjectModal={closeProjectModal} profileData={profileData} profileId={profile_id} setProfileData={setProfileData} setShowProjectModal={setShowProjectModal}  getprojects={profileData?.projects} getprojectdata={getprojectdata} listemployment={employmentData} setIsLoading={setIsLoading}/>

      <AddProfileSummary showProfileSummaryModal={showProfileSummaryModal} closeProfileSummaryModal={closeProfileSummaryModal}  profileId={profile_id} setProfileData={setProfileData} sendprofileSummarydata={profileData?.profile_summary?.profile_summary} profileData={profileData} setShowProfileSummaryModal={setShowProfileSummaryModal} setIsLoading={setIsLoading}/>


     
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(ProfileBio);

