import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { callAxios, getProfileData } from '../../utils/utils';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
import { Confirm } from './Confirm';

export const Patent = ({ showPatentModal, closePatentModal, setProfileData, profileId, setShowPatentModal, isUpdateAction, updateData,setIsLoading }) => {
	const [patentStatus, setPatentStatus] = useState('Patent issued');

	const [title, setTitle] = useState("");
	const [url, setUrl] = useState("");
	const [patentOffice, setPatentOffice] = useState("");
	const [applicationNo, setApplicationNo] = useState("");
	const [issueDateYear, setIssueDateYear] = useState("");
	const [issueDateMonth, setIssueDateMonth] = useState("");
 	const [description, setDescription] = useState("");


	useEffect(() => {
		if (isUpdateAction) {
			const issueDate = updateData?.issue_date.split(" ");
      		setTitle(updateData?.title);
			setUrl(updateData?.url);
			setPatentOffice(updateData?.patent_office)
			setPatentStatus(updateData?.status)
			setApplicationNo(updateData?.application_number)
			setIssueDateYear(issueDate[1])
			setIssueDateMonth(issueDate[0])
			setDescription(updateData?.description)
		} else {
			setTitle("");
			setUrl("");
			setPatentOffice("")
			setPatentStatus('Patent issued')
			setApplicationNo("")
			setIssueDateYear("")
			setIssueDateMonth("")
			setDescription("")
		}
	}, [updateData]);

  const handlePatentSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true)
		setShowPatentModal(false);
		// console.log(socialProfileName, socialUrl, socialDescription)
		if (isUpdateAction) {
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-patent", { profile_id: profileId, title: title, url: url, patent_office:patentOffice, status:patentStatus, application_number:applicationNo, issue_date:issueDateMonth +" "+ issueDateYear, description: description, type: "update", id: updateData?.id }, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
					setProfileData(await getProfileData(profileId,setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
					
				} else {
					setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		} else {
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/add-patent", { profile_id: profileId, title: title, url: url, patent_office:patentOffice, status:patentStatus, application_number:applicationNo, issue_date:issueDateMonth +" "+ issueDateYear, description: description }, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
					setProfileData(await getProfileData(profileId,setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
					
				} else {
					setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		}

	}
	

	// Show Delete Profile Modal
	const [showDeleteProfile, setShowDeleteProfile] = useState(false);
	const closeDeleteProfile = () => setShowDeleteProfile(false);
	const openDeleteProfile = () => setShowDeleteProfile(true);

	return (
		<>
		<Modal show={showPatentModal} centered size='lg' onHide={closePatentModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
			<Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
				<div className="close-wrapper text-end">
					<Button variant="link" onClick={closePatentModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
				</div>

				<div className="heading-wrapper mb-4">
					<div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
						<span className='widgetTitle text-black fs-5 fw-semibold'>Patent</span>
						{/* This button will show only when you click to edit the details */}
						{isUpdateAction?
							<span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0 pointer' onClick={openDeleteProfile}>Delete</span>
							:null}
					</div>
					<p className='subTitle fs-14'>Add details of patents you have filed</p>
				</div>

				<Form action="" className="form-cover" onSubmit={handlePatentSubmit}>
					<Form.Group className="mb-3">
						<Form.Label>Patent title<span className='text-danger'>*</span></Form.Label>
						<Form.Control type="text" value={title} onChange={(e)=>{setTitle(e.target.value)}} placeholder="Enter title" autoComplete='off' required />
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>URL<span className='text-danger'>*</span></Form.Label>
						<Form.Control type="url" value={url} onChange={(e)=>{setUrl(e.target.value)}} placeholder="Enter patent URL here" autoComplete='off' required />
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Patent office</Form.Label>
						<Form.Control type="text" value={patentOffice} onChange={(e)=>{setPatentOffice(e.target.value)}} placeholder="Enter patent office" autoComplete='off' />
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label className="mb-2">Status</Form.Label>
						<div className='radio-wrapper'>
							<Form.Check inline type="radio" className='me-4' id='status-issued' name='patentStatus' label='Patent issued' value="Patent issued"  checked={patentStatus === 'Patent issued'} onChange={() => setPatentStatus('Patent issued')} />
							<Form.Check inline type="radio" className='me-4' id='status-pending' name='patentStatus' label='Patent pending' value="Patent pending" checked={patentStatus === 'Patent pending'} onChange={() => setPatentStatus('Patent pending')} />
						</div>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Application number</Form.Label>
						<Form.Control type="text" value={applicationNo} onChange={(e)=>{setApplicationNo(e.target.value)}} placeholder="Enter application number" autoComplete='off' />
					</Form.Group>

					{/* Show only if Patent issued is checked */}
					{patentStatus === 'Patent issued' && (
					<Form.Group>
						<Form.Label>Issue date</Form.Label>
						<div className="row gx-3">
							<div className="col-6">
								<Form.Group className="mb-3">
									<Form.Select name="IssuedYear" value={issueDateYear} onChange={(e)=>{setIssueDateYear(e.target.value)}}>
										<option selected hidden value=''>Select year</option>
										<option value="2024">2024</option>
										<option value="2023">2023</option>
										<option value="2022">2022</option>
										<option value="2021">2021</option>
										<option value="2020">2020</option>
										<option value="2019">2019</option>
										<option value="2018">2018</option>
										<option value="2017">2017</option>
										<option value="2016">2016</option>
										<option value="2015">2015</option>
										<option value="2014">2014</option>
										<option value="2013">2013</option>
										<option value="2012">2012</option>
										<option value="2011">2011</option>
									</Form.Select>
								</Form.Group>
							</div>

							<div className="col-6">
								<Form.Group className="mb-3">
									<Form.Select name="IssuedMonth" value={issueDateMonth} onChange={(e)=>{setIssueDateMonth(e.target.value)}}>
										<option selected hidden value=''>Select month</option>
										<option value="January">January</option>
										<option value="February">February</option>
										<option value="March">March</option>
										<option value="April">April</option>
										<option value="May">May</option>
										<option value="June">June</option>
										<option value="July">July</option>
										<option value="August">August</option>
										<option value="September">September</option>
										<option value="October">October</option>
										<option value="November">November</option>
										<option value="December">December</option>
									</Form.Select>
								</Form.Group>
							</div>
						</div>
					</Form.Group>
					)}

					<Form.Group>
						<Form.Label>Description</Form.Label>
						<Form.Control as="textarea" value={description} onChange={(e)=>{setDescription(e.target.value)}} rows={5} placeholder='Type here...' maxLength={500} autoComplete='off' />
						<div className="charLenght fs-14 text-clr2 text-end mt-2">500 character(s) left</div>
					</Form.Group>
					
					<div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
						<Button variant="danger" onClick={closePatentModal} className='rounded-pill px-4'>Cancel</Button>
						<Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>

		<Confirm showDeleteProfile={showDeleteProfile} closeDeleteProfile={closeDeleteProfile} profileId={profileId} id={updateData?.id} message={updateData?.title} setShowPatentModal={setShowPatentModal} setShowDeleteProfile={setShowDeleteProfile} setIsLoading={setIsLoading} setProfileData={setProfileData} type="patent"/>
		</>
		
	)
}

