import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import searchIcon from '../resources/images/search.svg'
import '../custom.scss';
import { connect } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';
import { setUserInfo } from "../store/actions";
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import ProfileCreated from './cards/ProfileCreatedCard';
import InterviewScheduledcard from './cards/InterviewScheduledCard';
import ResultCard from './cards/ResultCard';
import InterviewStarted from './cards/InterviewStartedCard';
import StatusCard from './cards/StatusCard';

const RecruitmentDetails = (props) => {
	let navigate = useNavigate();

	const { state } = useLocation();
	//  console.log(state)
	const { setLoggedinUser } = props;
	const [user, loading] = useAuthState(auth);
	const [cards, setMemberCards] = useState([]);
	const [limit, setLimit] = useState(10);
	const [isloading, setIsloading] = useState(true);
	const [optionFilter, setOptionFilter] = useState('');

	useEffect(() => {
		if (loading) return;
		if (!user) navigate("/");

		if (user) {
			const userInfo = {
				uid: user?.uid,
				email: user?.email,
				displayName: user?.displayName,
				accessToken: user?.accessToken,
				idToken: user?.idToken,
			};
			setLoggedinUser(userInfo);
		}

		callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/get-requisition-member-cards-by-id", { requisitionId: state.requisitionId, page: 1, limit: limit }, "post").then((memberCards) => {
			// console.log(memberCards.data)
			setMemberCards(memberCards.data)
			setIsloading(false)
			setOptionFilter('')
		})
	}, [user, loading]);

	const userType = state.usertype

	// console.log("data::",cards)
	let memberCard;
	if (cards.memberCards && cards.memberCards.length > 0) {
		memberCard = cards.memberCards.map((item, index) => {
			if (item.card_type === "Profile Created") {
				return (
					<>
						<ProfileCreated profileData={item.candidateInfo} otherData={item.user_other_info} cardType={item.card_type} userType={userType} />
					</>
				)
			}
			else if (item.card_type === "Interview Scheduled") {
				return (
					<>
						<InterviewScheduledcard scheduleData={item.interview_schedule} cardType={item.card_type} userType={userType} />
					</>
				)
			}
			else if (item.card_type === "Interview Started") {
				return (
					<>
						<InterviewStarted interviewStartedData={item.nminterviewdatum} cardType={item.card_type} userType={userType}/>
					</>
				)
			}
			else if (item.card_type === "Status") {
				return (
					<>
						<StatusCard requisitionStatus={item.requisition_status} cardType={item.card_type} candidateInfo={item.candidateInfo} userType={userType}/>
					</>
				)
			}
			else {
				return (
					<>
						{/* <ResultCard cardType={item.card_type} /> */}
					</>
				)
			}
		})
	}
	else {
		memberCard = <div className='text-center'>No details found for this requisition</div>
	}
	//  console.log(memberCard)

	const handleBackToRequistionListing = () => {
		navigate("/requisitions");
	}

	const onchangeFilter = (optionVal) => {
		setIsloading(true)
		// console.log(optionVal)
		callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/get-requisition-member-cards-by-id", { requisitionId: state.requisitionId, page: 1, limit: limit, isFilter: true, filterOption: optionVal }, "post").then((memberCards) => {
			// console.log(memberCards.data)
			setMemberCards(memberCards.data)
			setOptionFilter(optionVal)
			setIsloading(false)
		})
	}

	const options = state.filterOptions.map((op) => {
		const isSelected = optionFilter === op.card_type ? "selected" : "";
		return <option value={op.card_type} selected={isSelected}>{op.card_type}</option>
	})

	const searchByName = (value) => {
		setIsloading(true)
		// console.log(value)
		callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/get-requisition-member-cards-by-id", { requisitionId: state.requisitionId, page: 1, limit: limit, isFilterByName: true, filterOption: value }, "post").then((memberCards) => {
			// console.log(memberCards.data)
			setMemberCards(memberCards.data)
			setIsloading(false)
		})
	}

	const doPaginate = (page) => {
		setIsloading(true)
		// console.log("clicked page::",page)
		callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/get-requisition-member-cards-by-id", { requisitionId: state.requisitionId, page: page, limit: limit }, "post").then((memberCards) => {
			// console.log(memberCards.data)
			setMemberCards(memberCards.data)
			setIsloading(false)
		})
	}

	let pagination;

	if (cards.pagination) {
		// console.log(cards)
		if (cards.pagination.totalPage > 1) {
			pagination = [...Array(cards.pagination.totalPage)].map((item, index) => {

				const isActive = cards.pagination.currentPage === index + 1 ? "page-link active" : "page-link";
				return (
					<li className="page-item"><button className={isActive} onClick={() => { doPaginate(index + 1) }}>{index + 1}</button></li>
				)
			})
		}
		// console.log(pagination,isLastpage)
	}
	//  console.log(optionFilter)


	return (
		<div className="my-requisition-details py-3">
			<div className="container">
				{isloading ?
					<div className="main-item" id="leftLoader-chat-----" style={{ height: "100px" }}>
						<div className="animated-background-requisition"></div>
					</div> :
					<>
						<div className="dashboard-home">
							<div className="info-card-sec">
								<div className="fs-5 fw-medium mb-2"> <i className="bi bi-arrow-left pointer bold" title='Back to requisition listing' onClick={handleBackToRequistionListing}></i>  Requisition Detail</div>
								{cards.counts ?
									<div className="row g-2 g-md-3 mb-4">
										<div className="col-sm-6 col-lg-4">
											<div className="card h-100 border-primary border border-3 rounded-3 p-3 p-xxl-4">
												<div className="d-flex gap-3">
													<div className="card-icon bg-primary bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center border border-2 border-primary">
														<i className="bi bi-person-fill-lock fs-4 text-primary"></i>
													</div>
													<div className="card-details">
														<div className="label-value fs-2 fw-bold text-black lh-1">{cards.counts.interviewScheduled}</div>
														<div className="label-title text-muted lh-sm mt-1">Interview scheduled</div>
													</div>
												</div>
											</div>
										</div>

										<div className="col-sm-6 col-lg-4">
											<div className="card h-100 border-info border border-3 rounded-3 p-3 p-xxl-4">
												<div className="d-flex gap-3">
													<div className="card-icon bg-info bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center border border-2 border-info">
														<i className="bi bi-person-badge fs-4 text-info"></i>
													</div>
													<div className="card-details">
														<div className="label-value fs-2 fw-bold text-black lh-1">{cards.counts.profileCreated}</div>
														<div className="label-title text-muted lh-sm mt-1">Profile created</div>
													</div>
												</div>
											</div>
										</div>

										<div className="col-sm-6 col-lg-4">
											<div className="card h-100 border-danger border border-3 rounded-3 p-3 p-xxl-4">
												<div className="d-flex gap-3">
													<div className="card-icon bg-danger bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center border border-2 border-danger">
														<i className="bi bi-person-dash-fill fs-4 text-danger"></i>
													</div>
													<div className="card-details">
														<div className="label-value fs-2 fw-bold text-black lh-1">{cards.counts.interviewStarted}</div>
														<div className="label-title text-muted lh-sm mt-1">Interview started</div>
													</div>
												</div>
											</div>
										</div>

										<div className="col-sm-6 col-lg-4">
											<div className="card h-100 border-secondary border border-3 rounded-3 p-3 p-xxl-4">
												<div className="d-flex gap-3">
													<div className="card-icon bg-secondary bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center border border-2 border-secondary">
														<i className="bi bi-person-fill-check fs-4 text-secondary"></i>
													</div>
													<div className="card-details">
														<div className="label-value fs-2 fw-bold text-black lh-1">{cards.counts.pass}</div>
														<div className="label-title text-muted lh-sm mt-1">Pass</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-sm-6 col-lg-4">
											<div className="card h-100 border-success border border-3 rounded-3 p-3 p-xxl-4">
												<div className="d-flex gap-3">
													<div className="card-icon bg-success bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center border border-2 border-success">
														<i className="bi bi-person-fill-down fs-4 text-success"></i>
													</div>
													<div className="card-details">
														<div className="label-value fs-2 fw-bold text-black lh-1">{cards.counts.fail}</div>
														<div className="label-title text-muted lh-sm mt-1">Fail</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-sm-6 col-lg-4">
											<div className="card h-100 border-warning border border-3 rounded-3 p-3 p-xxl-4">
												<div className="d-flex gap-3">
													<div className="card-icon bg-warning bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center border border-2 border-warning">
														<i className="bi bi-shield-fill-check fs-5 text-warning"></i>
													</div>
													<div className="card-details">
														<div className="label-value fs-2 fw-bold text-black lh-1">{cards.counts.offerGiven}</div>
														<div className="label-title text-muted lh-sm mt-1">Offer given</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									: null}
							</div>
						</div>

						<div className='listing-sec'>
							<div className='d-flex align-items-sm-center justify-content-sm-between flex-column flex-sm-row gap-2 mb-3'>
								<div className='left-sec fs-5 fw-medium'>
									<i className="bi bi-journal-album"></i> Requisition Candidates List
								</div>
							</div>

							<div className='dark-theme-bg rounded px-2 px-md-3 py-3'>
								<div className='filter-sec mb-3'>
									<div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between gap-2">
										<div className="filter-label fw-medium pointer"><i className="bi bi-filter-circle-fill"></i> Filter By -</div>
										<div className="d-flex flex-column flex-sm-row gap-2">
											<div className="searchfield-wrapper position-relative">
												<input type="search" className="form-control rounded-2" id="search" placeholder="Search by name..." onBlur={(e) => { searchByName(e.target.value) }} autoComplete='off' />
												<img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
											</div>
											<div className="Result-filter">
												<select className="form-select" required onChange={(e) => onchangeFilter(e.target.value)}>
													<option value="" >Choose here...</option>
													{options}
												</select>
											</div>
										</div>
									</div>
								</div>

								<div className='cards-listing'>
									{memberCard}
								</div>

								{cards.pagination ?
									<nav className='mt-3'>
										<ul className="pagination justify-content-center m-0">
											{cards.pagination.currentPage > limit ?
												<li className="page-item"><button className="page-link" onClick={() => { doPaginate(cards.pagination.currentPage - 1) }} >Previous</button></li>
												: null}
											{pagination}
											{cards.pagination.totalPage > 1 && cards.pagination.currentPage !== cards.pagination.totalPage ?
												<li className="page-item"><button className="page-link" onClick={() => { doPaginate(cards.pagination.currentPage + 1) }}>Next</button></li>
												: null}
										</ul>
									</nav>
									: null}
							</div>
						</div>
					</>
				}
			</div>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedinUser: (user) => dispatch(setUserInfo(user)),
	};
};

const mapStoreStateToProps = (state) => {
	return {
		...state,
	};
};

export default connect(
	mapStoreStateToProps,
	mapDispatchToProps
)(RecruitmentDetails);
