import React from "react"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export const YesIntern = ({ formData, handleInputChange }) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1 and pad with leading zero
    
    // Create the max value for the input
    const maxMonth = `${currentYear}-${currentMonth}`;
    return(
        <>
            <Form.Group className="mb-3">
                <Form.Label>Current company name<span className='text-danger'>*</span></Form.Label>
                <Form.Control type="text" name="currCmp" value={formData.currCmp} onChange={handleInputChange} placeholder="Type your orgainization" autoComplete='off' required />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Location<span className='text-danger'>*</span></Form.Label>
                <Form.Select name="location" value={formData.location} onChange={handleInputChange} required>
                    <option selected disabled value=''>Select your location</option>
                    <option value="Bengaluru">Bengaluru</option>
                    <option value="Mumbai">Mumbai</option>
                    <option value="Pune">Pune</option>
                    <option value="Chennai">Chennai</option>
                    <option value="Hyderabad">Hyderabad</option>
                    <option value="Gurugram">Gurugram</option>
                    <option value="Noida">Noida</option>
                    <option value="Ahmedabad">Ahmedabad</option>
                    <option value="Kolkata">Kolkata</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Department<span className='text-danger'>*</span></Form.Label>
                <Form.Select name="department" value={formData.department} onChange={handleInputChange} required>
                    <option selected disabled value=''>Select your department / area of expertise</option>
                    <option value="Business Analysis and Strategy">Business Analysis and Strategy</option>
                    <option value="Cybersecurity">Cybersecurity</option>
                    <option value="Customer Relationship Management (CRM)">Customer Relationship Management (CRM)</option>
                    <option value="Data Science and Analytics">Data Science and Analytics</option>
                    <option value="Database Administration">Database Administration</option>
                    <option value="DevOps and System Integration">DevOps and System Integration</option>
                    <option value="Enterprise Architecture">Enterprise Architecture</option>
                    <option value="Enterprise Resource Planning (ERP)">Enterprise Resource Planning (ERP)</option>
                    <option value="IT Management">IT Management</option>
                    <option value="IT Procurement and Asset Management">IT Procurement and Asset Management</option>
                    <option value="IT Support and Help Desk">IT Support and Help Desk</option>
                    <option value="Infrastructure and Operations">Infrastructure and Operations</option>
                    <option value="Project Management">Project Management</option>
                    <option value="Quality Assurance and Testing">Quality Assurance and Testing</option>
                    <option value="Research and Development (R&D)">Research and Development (R&D)</option>
                    <option value="Software Development">Software Development</option>
                    <option value="Telecommunications and Network Engineering">Telecommunications and Network Engineering</option>

                </Form.Select>
            </Form.Group>
            <Form.Group>
                <div className="row g-2">
                    <div className="col-sm-6">
                        <Form.Group className="mb-3">
                            <Form.Label>Working from<span className='text-danger'>*</span></Form.Label>
                            <Form.Control max={maxMonth} type="month" name="workingfrom" value={formData.workingfrom} onChange={handleInputChange} autoComplete='off' required />
                        </Form.Group>
                    </div>
                    <div className="col-sm-6">
                        <Form.Group className="mb-3">
                            <Form.Label>Monthly stipend</Form.Label>
                            <InputGroup>
                                <Form.Select name="salary_type" value={formData.salary_type} onChange={handleInputChange} className='shadow-none' style={{ maxWidth: '65px' }}>
                                    <option value="INR">₹</option>
                                    <option value="USD">$</option>
                                </Form.Select>
                                <Form.Control type="text" name="salary" value={formData.salary} onChange={handleInputChange} placeholder="Eg. 15,000" autoComplete='off' />
                            </InputGroup>
                        </Form.Group>
                    </div>
                </div>
            </Form.Group>
        </>
    )
}