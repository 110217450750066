import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { callAxios, getProfileData } from '../../utils/utils';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
export const Confirm = ({showDeleteProfile,closeDeleteProfile, profileId, id, message, setShowOnlineProfileModal, setShowDeleteProfile, setProfileData, setIsLoading, type, setShowWorkSampleModal,setShowPublicationModal,setShowPresentationModal, setShowPatentModal,setShowCertificationModal}) =>{
    const handleDeleteAction = () =>{
        
        setIsLoading(true)
        setShowDeleteProfile(false)
        if(type === "onlineProfiles"){
            setShowOnlineProfileModal(false);
            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-profiles", { profileId: profileId, type: "delete", id: id }, "post").then(async (deleteResponse) => {
                if (deleteResponse.data.statusCode === 200) {
                    setProfileData(await getProfileData(profileId, setIsLoading))
                    toast.success(deleteResponse.data.message, {
                        position: 'bottom-center'
                    })
                    
                    
                   
                } else {
                    setProfileData(await getProfileData(profileId, setIsLoading))
                    toast.error("something went wrong!", {
                        position: 'bottom-center'
                    })
                }
    
            })
        } 
        else if(type === "journalEntry"){
            setShowPublicationModal(false);
            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-journal-entry", { profile_id: profileId, type: "delete", id: id }, "post").then(async (deleteResponse) => {
                if (deleteResponse.data.statusCode === 200) {
                    setProfileData(await getProfileData(profileId,setIsLoading))
                    toast.success(deleteResponse.data.message, {
                        position: 'bottom-center'
                    })
                    
                    
                   
                }  else {
                    setProfileData(await getProfileData(profileId, setIsLoading))
                    toast.error("something went wrong!", {
                        position: 'bottom-center'
                    })
                }
    
            })     
        }
        else if(type === "presentation"){
            setShowPresentationModal(false);
            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-presentation", { profile_id: profileId, type: "delete", id: id }, "post").then(async (deleteResponse) => {
                if (deleteResponse.data.statusCode === 200) {
                    setProfileData(await getProfileData(profileId, setIsLoading))
                    toast.success(deleteResponse.data.message, {
                        position: 'bottom-center'
                    })
                    
                    
                   
                }  else {
                    setProfileData(await getProfileData(profileId, setIsLoading))
                    toast.error("something went wrong!", {
                        position: 'bottom-center'
                    })
                }
    
            })     
        }
        else if(type === "patent"){
            setShowPatentModal(false);
            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-patent", { profile_id: profileId, type: "delete", id: id }, "post").then(async (deleteResponse) => {
                if (deleteResponse.data.statusCode === 200) {
                    setProfileData(await getProfileData(profileId, setIsLoading))
                    toast.success(deleteResponse.data.message, {
                        position: 'bottom-center'
                    })
                    
                    
                   
                }  else {
                    setProfileData(await getProfileData(profileId, setIsLoading))
                    toast.error("something went wrong!", {
                        position: 'bottom-center'
                    })
                }
    
            })     
        }
        else if(type === "certification"){
            setShowCertificationModal(false);
            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-certification", { profile_id: profileId, type: "delete", id: id }, "post").then(async (deleteResponse) => {
                if (deleteResponse.data.statusCode === 200) {
                    setProfileData(await getProfileData(profileId, setIsLoading))
                    toast.success(deleteResponse.data.message, {
                        position: 'bottom-center'
                    })
                    
                    
                   
                }  else {
                    setProfileData(await getProfileData(profileId, setIsLoading))
                    toast.error("something went wrong!", {
                        position: 'bottom-center'
                    })
                }
    
            })     
        }
        else {
            setShowWorkSampleModal(false);
            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-work-sample", { profileId: profileId, type: "delete", id: id }, "post").then(async (deleteResponse) => {
                if (deleteResponse.data.statusCode === 200) {
                    
                    toast.success(deleteResponse.data.message, {
                        position: 'bottom-center'
                    })
                    setProfileData(await getProfileData(profileId, setIsLoading))
                    
                   
                }  else {
                    setProfileData(await getProfileData(profileId, setIsLoading))
                    toast.error("something went wrong!", {
                        position: 'bottom-center'
                    })
                }
    
            })
        }
       
    }
    return (
        <Modal show={showDeleteProfile} centered onHide={closeDeleteProfile} backdrop="static" keyboard={false} dialogClassName='profileCustomModal mw-auto small text-dark' contentClassName='bg-light rounded-4 overflow-hidden'>
				<Modal.Body className="p-md-4 p-lg-5 text-center">
					<div className="fs-5 fw-semibold lh-sm mb-4">Are you sure you want to delete {message}?</div>
					<div className="btn-wrapper d-flex flex-wrap justify-content-center gap-2">
						<Button variant="danger" onClick={closeDeleteProfile} className='rounded-pill px-4'>Cancel</Button>
						<Button type='submit' variant="primary" onClick={handleDeleteAction} className='rounded-pill px-4'>Delete</Button>
					</div>
				</Modal.Body>
			</Modal>
    )
}