import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { OnlineProfile } from './Modals/OnlineProfile';
import { WorkSample } from './Modals/WorkSample';
import { Publication } from './Modals/Publication';
import { Presentation } from './Modals/Presentation';
import { Patent } from './Modals/Patent';
import { Certification } from './Modals/Certification';

export const AccomplishmentsCard = ({profileId, profileData, setProfileData, setIsLoading}) => {
    // console.log(profileData?.online_profiles)
    // check for update
    const [isOnlineProfileAction, setOnlineProfileAction] = useState(false)
    const [updateOnlineProfileData, setOnlineProfileData] = useState({})
    

    const openOnlineProfileModalUpdate = (item) =>{
        setShowOnlineProfileModal(true);
        setOnlineProfileAction(true);
        setOnlineProfileData(item);
    } 

    const [isWorkSampleAction, setWorkSampleAction] = useState(false)
    const [updateWorkSampleData, setWorkSampleData] = useState({})

    const openWorkSampleModalUpdate = (item) =>{
        setShowWorkSampleModal(true);
        setWorkSampleAction(true);
        setWorkSampleData(item);
    } 

    const [isPublicationAction, setPublicationAction] = useState(false)
    const [updatePublicationData, setPublicationData] = useState({})

    const openPublicationModalUpdate = (item) =>{
        setShowPublicationModal(true);
        setPublicationAction(true);
        setPublicationData(item);
    } 

    const [isPresentationAction, setPresentationAction] = useState(false)
    const [updatePresentationData, setPresentationData] = useState({})

    const showPresentationModalUpdate = (item) =>{
        setShowPresentationModal(true);
        setPresentationAction(true);
        setPresentationData(item);
    }

    const [isPatentAction, setPatentAction] = useState(false)
    const [updatePatentData, setPatentData] = useState({})

    const showPatentModalUpdate = (item) =>{
        setShowPatentModal(true);
        setPatentAction(true);
        setPatentData(item);
    }

    const [isCertificationAction, setCertificationAction] = useState(false)
    const [updateCertificationData, setCertificationData] = useState({})

    const showCertificationModalUpdate = (item) =>{
        setShowCertificationModal(true);
        setCertificationAction(true);
        setCertificationData(item);
    }

    // Show Online profile Modal
    const [showOnlineProfileModal, setShowOnlineProfileModal] = useState(false);
    const closeOnlineProfileModal = () => setShowOnlineProfileModal(false);
    const openOnlineProfileModal = () => {
        setOnlineProfileAction(false)
        setOnlineProfileData(null)
        setShowOnlineProfileModal(true)
      
    };

    // Show Work sample Modal
    const [showWorkSampleModal, setShowWorkSampleModal] = useState(false);
    const closeWorkSampleModal = () => setShowWorkSampleModal(false);
    const openWorkSampleModal = () => {
        setWorkSampleAction(false)
        setWorkSampleData(null)
        setShowWorkSampleModal(true);
    }

    // Show Publication Modal
    const [showPublicationModal, setShowPublicationModal] = useState(false);
    const closePublicationModal = () => setShowPublicationModal(false);
    const openPublicationModal = () => {
        setPublicationAction(false)
        setPublicationData(null)
        setShowPublicationModal(true);
    }

    // Show Presentation Modal
    const [showPresentationModal, setShowPresentationModal] = useState(false);
    const closePresentationModal = () => setShowPresentationModal(false);
    const openPresentationModal = () => {
        setPresentationAction(false);
        setPresentationData({});
        setShowPresentationModal(true);
    }

    // Show Patent Modal
    const [showPatentModal, setShowPatentModal] = useState(false);
    const closePatentModal = () => setShowPatentModal(false);
    const openPatentModal = () =>{
        setPatentAction(false);
        setPatentData({});
        setShowPatentModal(true);
    } 

    // Show Certifications Modal
    const [showCertificationModal, setShowCertificationModal] = useState(false);
    const closeCertificationModal = () => setShowCertificationModal(false);
    const openCertificationModal = () => {
        setCertificationAction(false);
        setCertificationData({});
        setShowCertificationModal(true);
    }

    // For Read more read less functionality
    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);

        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <>
                {isReadMore ? text.slice(0, 350) : text}
                {text.length > 350 &&
                    <span onClick={toggleReadMore} className="text-info pointer">
                        {isReadMore ? "...Read More" : " Read Less"}
                    </span>
                }
            </>
        );
    };

    return (
        <>
            <div id="Accomplishments" className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
                <div className='widgetHead mb-3'>
                    <div className='widgetTitle text-white fs-6 fw-medium'>Accomplishments</div>
                    {/* Uncomment this for normal case */}
                    {/* <div className='emp-desc mt-3'>Showcase your credentials by adding relevant certifications, work samples, online profiles, etc.</div> */}
                </div>

                <div className='widgetCont'>
                    <div className="acm-list">
                        <div className="acm-list-item border-bottom border-secondary border-opacity-50 pb-3 mb-3">
                            <div className="listHead">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-white fs-6 fw-medium">Online profile</div>
                                    <Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={openOnlineProfileModal}>Add</Link>
                                </div>
                                <div className="emp-desc">Add link to online professional profiles (e.g. LinkedIn, etc.)</div>
                            </div>

                            <div className="listCont list-info-wrapper d-flex flex-column gap-4 mt-3">
                                {profileData?.online_profiles && profileData?.online_profiles.map((item, index) => (
                                    <div className="list-info" key={index}>
                                        <div className='d-flex gap-3'>
                                            <span className='patform-name fw-medium text-truncate text-white text-opacity-75' title={item.social_profile}>{item.social_profile}</span>
                                            <span className='edit-icon pointer' onClick={()=>{openOnlineProfileModalUpdate(item)}}><i className='bi bi-pencil fs-6 text-clr1 lh-1'></i></span>
                                        </div>
                                        <div className='profile-url'><Link to={item.url} target='_blank' className='text-decoration-none'>{item.url}</Link></div>
                                        <div className="profile-desc">
                                            <ReadMore>
                                                {item.description}
                                            </ReadMore>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="acm-list-item border-bottom border-secondary border-opacity-50 pb-3 mb-3">
                            <div className="listHead">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-white fs-6 fw-medium">Work sample</div>
                                    <Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={openWorkSampleModal}>Add</Link>
                                </div>
                                <div className="emp-desc">Link relevant work samples (e.g. Github, Behance)</div>
                            </div>

                            <div className="listCont list-info-wrapper d-flex flex-column gap-4 mt-3">
                                {profileData?.work_samples && profileData?.work_samples.map((item, idx) => (
                                    <div className="list-info" key={idx}>
                                        <div className='d-flex gap-3'>
                                            <span className='work-title fw-medium text-truncate text-white text-opacity-75' title='Tour Website Design'>{item.work_title}</span>
                                            <span className='edit-icon pointer' onClick={()=>{openWorkSampleModalUpdate(item)}}><i className='bi bi-pencil fs-6 text-clr1 lh-1'></i></span>
                                        </div>
                                        <div className='work-url'><Link to={item.url} target='_blank' className='text-decoration-none'>{item.url}</Link></div>
                                        <div className='work-duration text-clr1'>{item.is_currently_working_this?item.duration_from +" - Present":item.duration_from +" - "+ item.duration_to}</div>
                                        <div className="work-desc">
                                            <ReadMore>
                                                {item.description}
                                            </ReadMore>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="acm-list-item border-bottom border-secondary border-opacity-50 pb-3 mb-3">
                            <div className="listHead">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-white fs-6 fw-medium">White paper / Research publication / Journal entry</div>
                                    <Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={openPublicationModal}>Add</Link>
                                </div>
                                <div className="emp-desc">Add links to your online publications</div>
                            </div>

                            <div className="listCont list-info-wrapper d-flex flex-column gap-4 mt-3">
                                {profileData?.journal_entries && profileData?.journal_entries.map((item, idx1) => (
                                    <div className="list-info" key={idx1}>
                                        <div className='d-flex gap-3'>
                                            <span className='work-title fw-medium text-truncate text-white text-opacity-75' title={item.title}>{item.title}</span>
                                            <span className='edit-icon pointer' onClick={()=>{openPublicationModalUpdate(item)}}><i className='bi bi-pencil fs-6 text-clr1 lh-1'></i></span>
                                        </div>
                                        <div className='work-url'><Link to={item.url} target='_blank' className='text-decoration-none'>{item.url}</Link></div>
                                        <div className='work-duration text-clr1'>Published on: {item.published_on}</div>
                                        <div className="work-desc">
                                            <ReadMore>
                                                {item.description}
                                            </ReadMore>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="acm-list-item border-bottom border-secondary border-opacity-50 pb-3 mb-3">
                            <div className="listHead">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-white fs-6 fw-medium">Presentation</div>
                                    <Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={openPresentationModal}>Add</Link>
                                </div>
                                <div className="emp-desc">Add links to your online presentations (e.g. Slide-share presentation links etc.)</div>
                            </div>

                            <div className="listCont list-info-wrapper d-flex flex-column gap-4 mt-3">
                                {profileData?.presentations && profileData?.presentations.map((item, idx2) => (
                                    <div className="list-info" key={idx2}>
                                        <div className='d-flex gap-3'>
                                            <span className='proj-name fw-semibold text-truncate text-white text-opacity-75' title={item.title}>{item.title}</span>
                                            <span className='edit-icon pointer' onClick={()=>{showPresentationModalUpdate(item)}}><i className='bi bi-pencil fs-6 text-clr1 lh-1'></i></span>
                                        </div>
                                        <div className='work-url'><Link to={item.url} target='_blank' className='text-decoration-none'>{item.url}</Link></div>
                                        <div className="proj-desc">
                                            <ReadMore>
                                                {item.description}
                                            </ReadMore>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="acm-list-item border-bottom border-secondary border-opacity-50 pb-3 mb-3">
                            <div className="listHead">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-white fs-6 fw-medium">Patent</div>
                                    <Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={openPatentModal}>Add</Link>
                                </div>
                                <div className="emp-desc">Add details of patents you have filed</div>
                            </div>

                            <div className="listCont list-info-wrapper d-flex flex-column gap-4 mt-3">
                                {profileData?.patents && profileData?.patents.map((item, idx3) => (
                                    <div className="list-info">
                                        <div className='d-flex gap-3' key={idx3}>
                                            <span className='patent-name fw-semibold text-truncate text-white text-opacity-75' title={item.title}>{item.title}</span>
                                            <span className='edit-icon pointer' onClick={()=>{showPatentModalUpdate(item)}}><i className='bi bi-pencil fs-6 text-clr1 lh-1'></i></span>
                                        </div>
                                        <div className='work-url'><Link to={item.url} target='_blank' className='text-decoration-none'>{item.url}</Link></div>
                                        {item?.patent_office?
                                        <div className='work-duration text-clr1'>Patent office: {item.patent_office}</div>
                                        :null}
                                        {item.application_number?
                                        <div className='patent-app-number text-clr1'>Application number: {item.application_number}</div>
                                        :null}
                                        {item.status === "Patent issued"?
                                        <div className='patent-iisued-date text-clr1'>Issued on: {item.issue_date}</div>
                                        :<div className='patent-iisued-date text-clr1'>Status: {item.status}</div>}
                                        <div className="patent-desc">
                                            <ReadMore>
                                                {item.description}
                                            </ReadMore>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="acm-list-item">
                            <div className="listHead">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-white fs-6 fw-medium">Certification</div>
                                    <Link to={'#'} className='text-primary fs-6 fw-medium btn btn-link text-decoration-none lh-sm p-0' onClick={openCertificationModal}>Add</Link>
                                </div>
                                <div className="emp-desc">Add details of certifications you have completed</div>
                            </div>

                            <div className="listCont list-info-wrapper d-flex flex-column gap-4 mt-3">
                                {profileData?.certifications && profileData?.certifications.map((item, idx4) => (
                                    <div className="list-info d-flex gap-3" key={idx4}>
                                        <div className="img-sec mt-1">
                                            <img src={require('../resources/images/certification.png')} alt="certification" className='img-fluid border' style={{height: '50px',width:'50px'}} />
                                        </div>
                                        <div className="info-sec">
                                            <div className='d-flex gap-3'>
                                                <span className='proj-name fw-semibold text-truncate text-white text-opacity-75' title={item.certification_name}>{item.certification_name}</span>
                                                <span className='edit-icon pointer' onClick={()=>{showCertificationModalUpdate(item)}}><i className='bi bi-pencil fs-6 text-clr1 lh-1'></i></span>
                                            </div>
                                            <div className='certification-institute text-clr1'>{item.certification_provider}</div>
                                            <div className='institute-website'><Link to={item.certification_url} target='_blank' className='text-decoration-none'>{item.certification_url}</Link></div>
                                            {item.is_certification_expired?
                                            <div className='certificate-validity text-clr1 fw-light'>Valid from {item.certification_validity_from}. Does not expire.</div>
                                            :
                                            <div className='certificate-validity text-clr1 fw-light'>Valid from {item.certification_validity_from} - {item.certification_validity_to}</div>
}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <OnlineProfile showOnlineProfileModal={showOnlineProfileModal} closeOnlineProfileModal={closeOnlineProfileModal} profileId={profileId} setProfileData={setProfileData} setShowOnlineProfileModal={setShowOnlineProfileModal} isUpdateAction={isOnlineProfileAction} updateData={updateOnlineProfileData} profileData={profileData} setIsLoading={setIsLoading}/>

            <WorkSample showWorkSampleModal={showWorkSampleModal} closeWorkSampleModal={closeWorkSampleModal} profileId={profileId} setProfileData={setProfileData} setShowWorkSampleModal={setShowWorkSampleModal} isUpdateAction={isWorkSampleAction} updateData={updateWorkSampleData} profileData={profileData} setIsLoading={setIsLoading}/>

            <Publication showPublicationModal={showPublicationModal} closePublicationModal={closePublicationModal} setShowPublicationModal={setShowPublicationModal} profileId={profileId} setProfileData={setProfileData} isUpdateAction={isPublicationAction} updateData={updatePublicationData} profileData={profileData} setIsLoading={setIsLoading}/>

            <Presentation showPresentationModal={showPresentationModal} closePresentationModal={closePresentationModal} setShowPresentationModal={setShowPresentationModal} profileId={profileId} setProfileData={setProfileData} isUpdateAction={isPresentationAction} updateData={updatePresentationData} profileData={profileData} setIsLoading={setIsLoading}/>


            <Patent showPatentModal={showPatentModal} closePatentModal={closePatentModal} setShowPatentModal={setShowPatentModal} profileId={profileId} setProfileData={setProfileData} isUpdateAction={isPatentAction} updateData={updatePatentData} profileData={profileData} setIsLoading={setIsLoading} />

            <Certification showCertificationModal={showCertificationModal} closeCertificationModal={closeCertificationModal} setShowCertificationModal={setShowCertificationModal} profileId={profileId} setProfileData={setProfileData} isUpdateAction={isCertificationAction} updateData={updateCertificationData} profileData={profileData} setIsLoading={setIsLoading} />
        </>
    )
}
