import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Skills from './Skills';

const SkillsVal = Skills.map((skill, index) => ({
	id: index + 1,
	skill_name: skill,
	value: skill,
	label: skill
}));

const EditRequisition = (props) => {

	const { setLoggedinUser } = props;
	const { state } = useLocation();
	const requisitionData = state.requistionData;
	console.log(requisitionData)
	const [Skills, setSelectedOption] = useState(null);
	const [jobTitle, setJobTitle] = useState(requisitionData.jobTitle);
	const [primaryRole, setPrimaryRole] = useState(requisitionData.primaryRole);
	const [clientName, setclientName] = useState(requisitionData.clientName);
	const [minimumExp, setMinimumExp] = useState(requisitionData.minimumExp);
	const [department, setDepartment] = useState(requisitionData.department);
	const [jobDescription, setJobDescription] = useState(requisitionData.jobDescription);
	const [keySkills, setKeySkills] = useState(() => {
		const skills = [];
		requisitionData.keySkills.split(",").forEach((item) => {
			skills.push({ value: item, label: item });
		})

		return skills;
	});
	const [location, setLocation] = useState(requisitionData.location);
	const [minimumQualification, setMinimumQualification] = useState(requisitionData.minimumQualification);
	const [reqiredBy, setReqiredBy] = useState(requisitionData.reqiredBy);
	const [employmentType, setEmploymentType] = useState(requisitionData.employmentType);
	const [shiftType, setShiftType] = useState(requisitionData.shiftType);
	const [jobNatureType, setNatureOfJob] = useState(requisitionData.jobNatureType);
	const [isClientName, setIsClientName] = useState(() => {
		if (primaryRole === "Ongoing Client" || primaryRole === "New Client") {
			return true;
		} else {
			return false
		}
	});
	const [isloading, setIsloading] = useState(false);

	// useEffect(()=>{
	//     console.log(requisitionData.jobTitle)
	//     setJobTitle(requisitionData.jobTitle)
	// },[requisitionData])

	let navigate = useNavigate();
	const [user, loading] = useAuthState(auth);
	useEffect(() => {
		if (loading) return;
		if (!user) navigate("/");

		if (user) {
			const userInfo = {
				uid: user?.uid,
				email: user?.email,
				displayName: user?.displayName,
				accessToken: user?.accessToken,
				idToken: user?.idToken,
			};
			setLoggedinUser(userInfo);
		}
	}, [user, loading]);


	const enableClientText = () => {
		const primaryRole = document.getElementById("primary-role-id").value;
		if (primaryRole === "Ongoing Client" || primaryRole === "New Client") {
			setIsClientName(true)
		} else {
			setIsClientName(false)
		}
	}
	const updateRequisition = async (e) => {
		e.preventDefault();

		const submitButton = document.getElementById("Add-requisition-button");
		if (submitButton) {
			submitButton.disabled = true;
		}
		setIsloading(true)
		const updateRequisition = {
			jobTitle: jobTitle,
			department: department,
			jobDescription: jobDescription,
			keySkills: keySkills,
			location: location,
			minimumExp: minimumExp,
			minimumQualification: minimumQualification,
			primaryRole: primaryRole,
			clientName: clientName,
			reqiredBy: reqiredBy,
			employmentType: employmentType,
			shiftType: shiftType,
			jobNatureType: jobNatureType,
			updated_by: user?.displayName,
			updated_by_email: user?.email,
			requisitionId: requisitionData.id
		}

		const requisitionResponse = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/update-requisition", updateRequisition, "post");

		if (requisitionResponse.data.statusCode === 200) {
			setIsloading(false)
			submitButton.disabled = false;
			document.getElementById("requisitionForm").reset();
			// setIsClientName(false)
			// setJobTitle("");
			// setPrimaryRole("");
			// setclientName("")
			// setMinimumExp("");
			// setDepartment("");
			// setJobDescription("");
			// setKeySkills("");
			// setLocation("");
			// setMinimumQualification("");
			// setReqiredBy("");
			// setEmploymentType("");
			// setShiftType("");
			// setNatureOfJob("");

			toast.success("Requisition updated successfully!", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
		if (requisitionResponse.data.statusCode === 400) {
			setIsloading(false)
			submitButton.disabled = false;
			toast.error(requisitionResponse.data.message, {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	}

	// for keyskills
	const skills = [
		{
			skill_name: "PHP",
		},
		{
			skill_name: "JAVA",
		},
		{
			skill_name: "REACT",
		},
		{
			skill_name: "NODE JS",
		}
	]

	let options_ = [];
	if (skills.length > 0) {
		skills.map((item) => {
			const options = { value: item.skill_name, label: item.skill_name, role: item.skill_name };
			return options_.push(options)
		})
	}

	// React-slick npm style customisation
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			backgroundColor: 'red',
			border: 0,
			borderRadius: '0.5rem',
			backgroundColor: 'var(--custom-bg)',
			padding: '0.625rem 0.15rem',
		}),
		placeholder: (provided) => ({
			...provided,
			color: 'rga(108,117,125)', // Change the color of the placeholder text
		}),
	};

	const handleBack = () => {
		navigate("/requisitions");
	}

	return (
		<>
			{/* <ToastContainer /> */}
			<div className="recruitment-page mt-3 mb-4">
				<div className="container">
					<div className="fs-3 fw-semibold text-white text-opacity-75 lh-sm mb-3">Update requisition [{requisitionData.jobTitle}]</div>
					<div className="card dark-theme-bg rounded-4 p-3 p-md-4 p-xl-5">
						<Form data-bs-theme="dark" method="post" id="requisitionForm">
							<div className="row g-3">
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Job Title</Form.Label>
										</div>
										<div className="col-lg-8">
											<Form.Control type="text" className="border-0 rounded-3 py-3" placeholder="Enter Job Title" autoComplete="off" required value={jobTitle} onChange={(e) => { setJobTitle(e.target.value) }} tabIndex="0" />
										</div>
									</Form.Group>
								</div>

								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Minimum Experience</Form.Label>
										</div>
										<div className="col-lg-8">
											<Form.Control type="number" className="border-0 rounded-3 py-3" placeholder="Years" autoComplete="off" value={minimumExp} required onChange={(e) => { setMinimumExp(e.target.value) }} tabIndex="2" />
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Primary Role</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example" id="primary-role-id" value={primaryRole} required onChange={(e) => { setPrimaryRole(e.target.value); enableClientText() }} tabIndex="11">
													<option value="">Select</option>
													<option value="In House">In House</option>
													<option value="Ongoing Client">Ongoing Client</option>
													<option value="New Client">New Client</option>
													<option value="For Bench">For Bench</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								{isClientName ?
									<div className="col-sm-6">
										<Form.Group className="row align-items-center g-2 g-lg-3">
											<div className="col-lg-4 text-lg-end">
												<Form.Label className="m-0">Client Name</Form.Label>
											</div>
											<div className="col-lg-8">
												<Form.Control type="text" className="border-0 rounded-3 py-3" placeholder="Enter Client Name" autoComplete="off" value={clientName} required onChange={(e) => { setclientName(e.target.value) }} />
											</div>
										</Form.Group>
									</div>
									: null}
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Minimum Qualification</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example" required value={minimumQualification} onChange={(e) => { setMinimumQualification(e.target.value) }} tabIndex="3">
													<option value="">Select</option>
													<option value="Diploma">Diploma</option>
													<option value="Graduate">Graduate</option>
													<option value="Post Graduate">Post Graduate</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Job Location</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example" value={location} required onChange={(e) => { setLocation(e.target.value) }} tabIndex="4">
													<option value="">Select Location</option>
													<option value="Noida">Noida</option>
													<option value="Kolkata">Kolkata</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>

								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Department</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" value={department} aria-label="Default select example" required onChange={(e) => { setDepartment(e.target.value) }} tabIndex="5">
													<option value="">Select Department</option>
													<option value="Open Source">Open Source</option>
													<option value="Embedded">Embedded</option>
													<option value="Content">Content</option>
													<option value="Mobile Apps">Mobile Apps</option>
													<option value="SEO / Marketing">SEO / Marketing</option>
													<option value="HR">HR</option>
													<option value="IT">IT</option>
													<option value=".Net">.Net</option>
													<option value="Management">Management</option>
													<option value="GAPM">GAPM</option>
													<option value="CAM">CAM</option>
													<option value="Coordination">Coordination</option>
													<option value="Quality">Quality</option>
													<option value="Account and Finance">Account and Finance</option>
													<option value="Billing">Billing</option>
													<option value="Virtual Assistant">Virtual Assistant</option>
													<option value="Sales">Sales</option>
													<option value="Digital Marketing">Digital Marketing</option>
													<option value="Legal / Paralegal">Legal / Paralegal</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Job Type</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" value={employmentType} aria-label="Default select example" required onChange={(e) => { setEmploymentType(e.target.value) }} tabIndex="6">
													<option value="">Select Employment</option>
													<option value="Full Time">Full Time</option>
													<option value="Part Time">Part Time</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Required By</Form.Label>
										</div>
										<div className="col-lg-8">
											<Form.Control type="date" className="border-0 rounded-3 py-3" value={reqiredBy} required onChange={(e) => { setReqiredBy(e.target.value) }} tabIndex="7" />
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Job Shift Type</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" aria-label="Default select example" value={shiftType} required onChange={(e) => { setShiftType(e.target.value) }} tabIndex="8">
													<option value="">Select Shift Type</option>
													<option value="Day Shift">Day</option>
													<option value="Night Shift">Night</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row align-items-center g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="m-0">Job Nature</Form.Label>
										</div>
										<div className="col-lg-8">
											<div className="custom-select">
												<Form.Select className="border-0 rounded-3 py-3" value={jobNatureType} aria-label="Default select example" required onChange={(e) => { setNatureOfJob(e.target.value) }} tabIndex="9">
													<option value="">Select Job Nature</option>
													<option value="WFO">Work from Office</option>
													<option value="WFH">Work from Home</option>
												</Form.Select>
											</div>
										</div>
									</Form.Group>
								</div>
								<div className="col-sm-6">
									<Form.Group className="row g-2 g-lg-3">
										<div className="col-lg-4 text-lg-end">
											<Form.Label className="mt-lg-3 mb-0">Key Skills</Form.Label>
										</div>
										<div className="col-lg-8">
											{/* <Form.Control as="textarea" className="border-0 rounded-3 py-3" placeholder="Enter key skills ...." rows={4} required onChange={(e)=>{setKeySkills(e.target.value)}} tabIndex="8"/> */}
											{/* <Select isMulti classNamePrefix="custom-select"
											defaultValue={Skills}
											onChange={setKeySkills}
											options={options_}
											styles={customStyles}
											name="customSkills"
											formatOptionLabel={info => (
											<div className='username text-body text-uppercase lh-sm'>{info.value.toUpperCase()}</div>
											)}
											placeholder="Enter a skill..." tabIndex={10}/> */}

											<CreatableSelect isMulti className='custom-select' value={keySkills} classNamePrefix='custom-select' required
												closeMenuOnSelect={false}
												name="skills"
												options={SkillsVal}
												styles={customStyles}
												onChange={(value) => {
													setKeySkills(value);
													// setErrors((prevErrors) => ({
													//     ...prevErrors,
													//     skills: '',
													// }));
												}
												}
												placeholder="Add your skills"
											/>
										</div>
									</Form.Group>
								</div>
								<div className="col-12">
									<Form.Group className="row g-2 g-lg-3">
										<div className="col-lg-2 text-lg-end">
											<Form.Label className="mt-lg-3 mb-0">Job Description</Form.Label>
										</div>
										<div className="col-lg-10">
											<Form.Control as="textarea" value={jobDescription} className="border-0 rounded-3 py-3" placeholder="Enter job description here..." rows={4} required onChange={(e) => { setJobDescription(e.target.value) }} tabIndex="11" />
										</div>
									</Form.Group>
								</div>
								<div className="col-12 btn_wrapper d-flex justify-content-center gap-3 mt-4 pt-xl-3">
									<Button type="button" variant="secondary" className="py-2 px-5" onClick={handleBack}>Back</Button>
									<Button type="submit" variant="success" className="py-2 px-5" onClick={(e) => { updateRequisition(e) }} id="Add-requisition-button">Update Requisition</Button>
									{isloading ?
										<div style={{ textAlign: 'center' }}>
											<span className="spinner-profile"></span>
										</div>
										: null}
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>

	)

}

// export default NewRecruitment

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedinUser: (user) => dispatch(setUserInfo(user)),
	};
};

const mapStoreStateToProps = (state) => {
	return {
		...state,
	};
};

export default connect(
	mapStoreStateToProps,
	mapDispatchToProps
)(EditRequisition);