import React from 'react'
import CheckImg from '../resources/images/check.png'

const OnlyWithAudioCheckbox = (props) => {

    const { connectOnlyWithAudio, setConnectOnlyWithAudio } = props

    const handleConnectionTypeChange = () => {
        setConnectOnlyWithAudio(!connectOnlyWithAudio)
    }
    return (
        <div className='checkbox_container w-100'>
            <div className='checkbox_connection' onClick={handleConnectionTypeChange}>
                {connectOnlyWithAudio && (
                    <img className='checkbox_image' src={CheckImg}></img>
                )}
            </div>
            <div className='checkbox_container_paragraph ms-2 ps-1'>Only audio</div>
        </div>

        // <div className="form-check">
        //     <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        //     <label className="form-check-label" htmlFor="flexCheckDefault">
        //         Only Audio
        //     </label>
        // </div>
    )
}

export default OnlyWithAudioCheckbox