import React, {useState} from "react";
 
import AmChart4Wrapper from "react-amcharts4";
 
import { PieChart } from "@amcharts/amcharts4/charts";
 
// Configure any reguired theme
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_material from "@amcharts/amcharts4/themes/material";
 
// am4core.useTheme(am4themes_material);
 

 
const ClientsConnectedChart = ({connected, notConnected}) => {
    
    const config = {
        // Create pie series
        series: [
          {
            type: "PieSeries",
            "ticks": {
              "disabled": true
            },
            "labels": {
              "disabled": true
            },
            dataFields: {
              value: "clicks",
              category: "label"
            },
            "colors": {
                "list": [
                  "#008000",
                  "#d285e3"
                 
                ]
              }
          },
          
        ],
        "labels": {
          "text": "{value.percent.formatNumber('#.0')}%",
          "radius": "-100%",
          "fill": "white",
          "disabled": true
        },
        "legend": {
          itemContainers: {
            template: {
              paddingTop: 2, paddingBottom: 0,
              fontSize: 14,
            }
          },
          "labels": {
            "maxWidth": 100,
            "truncate": true,
            "wrap": true
          },
          "maxHeight": 100,
          "scrollable": true,
            "wrap":true,
           
            "useDefaultMarker": true,
            "markers": {
              "template": {
                paddingTop: 3,
                "children": [
                  {
                    width: 14,
                    height: 14,
                    cornerRadiusTopLeft: 14,
                    cornerRadiusTopRight: 14,
                    cornerRadiusBottomRight: 14,
                    cornerRadiusBottomLeft: 14,
                  }],
              },
            }
          },
        // Add data
        data: [
          {
            label: "Connected",
            clicks: connected? connected:0
          },
          {
              label: "Not Connected",
              clicks: notConnected? notConnected:0
          },
          
        ]
      };
    // console.log("ClientClickedChart Component::",connected,notConnected)
  return (
    <div className="graph-cover">
      <AmChart4Wrapper
        config={config}
        id="amcharts-2"
        chartTypeClass={PieChart}
      />
    </div>
  );
};
 
export default ClientsConnectedChart;