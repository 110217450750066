import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { callAxios, getProfileData } from '../../utils/utils';
import { environment } from '../../environment';
import { toast } from 'react-toastify';

//datepicker import
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const PersonalDetails = ({ showPersonalDetailsModal, closePersonalDetailsModal, setProfileData, profileId, isUpdateAction, updateData, setShowPersonalDetailsModal, profileData, setIsLoading, currempdata }) => {
//  console.log(currempdata)
  const [isVisible, setIsVisible] = useState(false);
  const animatedComponents = makeAnimated();

  const showLanguageProficiency = () => {
    setIsVisible(true);
  }

  const closeLanguageProficiency = () => {
    setIsVisible(false);
  }

  const permitCountries = [
    { value: 'India', label: 'India' },
    { value: 'Canada', label: 'Canada' },
    { value: 'United States', label: 'United States' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'England', label: 'England' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Newzeland', label: 'Newzeland' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Dubai', label: 'Dubai' },
    { value: 'China', label: 'China' }
  ]
  // React-slick npm style customisation
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: 'var(--theme-color4)', // Change the color of the placeholder text
      fontSize: '14px',
      fontWeight: '400',
      padding: '0',
    }),
  };

  const [gender, set_gender] = useState("");
	const [more_information, set_more_information] = useState({
    SingleParent: false,
    WorkingMother: false,
    ServedInMilitary: false,
    Retired: false,
    LGBTQ: false,
  });
    const [marital_status, set_marital_status] = useState("");
    const [dob, set_dob] = useState("");
    const [dobDate, set_dobDate] = useState("");
    const [dobMonth, set_dobMonth] = useState("");
    const [dobYear, set_dobYear] = useState("");
    const [category, set_category] = useState("");
    const [is_differently_abled, set_is_differently_abled] = useState("No");
    const [type, set_type] = useState("");
	const [describe_, set_describe_] = useState("");
    const [need_assistance, set_need_assistance] = useState("");
    const [taken_carrer_break, set_taken_carrer_break] = useState("BreakNo")
    const [has_passport, set_has_passport] = useState("PassportNo")
    const [reason_for_break, set_reason_for_break] = useState("")
    const [break_start_from, set_break_start_from] = useState("")
    const [break_ended_in, set_break_ended_in] = useState("")
    const [work_permit_usa, set_work_permit_usa] = useState("")
    const [work_permit_other_contry, set_work_permit_other_contry] = useState("");
    const [permanent_address, set_permanent_address] = useState("")
   
	  const [isLastLanguageToBeDelete, setIsLastLanguage] = useState(false)

    const [issueDate, setIssueDate] = useState(new Date());
    const [expiryDate, setExpiryDate] = useState(new Date());

    const [house_flat_no, set_house_flat_no] = useState("");
    const [street_name, set_street_name] = useState("")
    const [locality_area, set_locality_area] = useState("")
    const [city, set_city] = useState("")
    const [state, set_state] = useState("")
    const [state_label, set_state_label] = useState("")
    const [postal_zip, set_postal_zip] = useState("")
    const [country, set_country] = useState("")
    const [country_label, set_country_label] = useState("")
    const [landmark, set_landmark] = useState("")

    const [passport_no, set_passport_no] = useState("")
    const [date_of_issue, set_date_of_issue] = useState("")
    const [date_of_expiry, set_date_of_expiry] = useState("")
    const [place_of_issue, set_place_of_issue] = useState("")
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])


    const getFullMonthName = (dateString) => {
      const [year, month, day] = dateString.split('-');
      const monthIndex = parseInt(month, 10) - 1; // Month is 0-indexed
      return months[monthIndex];
    };
    const convertDate = (dateString) => {
      const [year, monthName, day] = dateString.split('-');
      const monthIndex = months.indexOf(monthName) + 1;
      const month = monthIndex.toString().padStart(2, '0');
      const formattedDay = day.padStart(2, '0');
      return `${year}-${month}-${formattedDay}`;
    };

    useEffect(()=>{
       callAxios(environment.REACT_APP_API_URL_Live+"api/v1/profile/get-countries",{},"post").then((country)=>{
        setCountryList(country.data.countries)
       })
    },[])
    useEffect(()=>{
      if (isUpdateAction) {
      callAxios(environment.REACT_APP_API_URL_Live+"api/v1/profile/get-state-by-id",{countryId:updateData?.country},"post").then((country)=>{
        setStateList(country.data.states)
       })
      }
   },[updateData?.country])
    console.log(countryList)
	useEffect(() => {
		if (isUpdateAction) {
            set_gender(updateData?.gender);
            set_more_information(JSON.parse(updateData?.more_information));
            setMoreInformation(JSON.parse(updateData?.more_information))
            set_marital_status(updateData?.marital_status);
            set_dob(updateData?.dob)
            set_dobDate(updateData?.dob.toString().split("-")[2])
            set_dobMonth(getFullMonthName(updateData?.dob))
            set_dobYear(updateData?.dob.toString().split("-")[0])
            set_category(updateData?.category);
            set_is_differently_abled(updateData?.is_differently_abled?"Yes":"No");
            set_type(updateData?.type);
            set_describe_(updateData?.describe_);
            set_need_assistance(updateData?.need_assistance);
            set_taken_carrer_break(updateData?.taken_carrer_break?"BreakYes":"BreakNo");
            set_has_passport(updateData?.has_passport?"PassportYes":"PassportNo")
            set_passport_no(updateData?.passport_no)
            set_date_of_issue(updateData?.date_of_issue)
            set_date_of_expiry(updateData?.date_of_expiry)
            set_place_of_issue(updateData?.place_of_issue)
            set_reason_for_break(updateData?.reason_for_break)
            set_break_start_from(updateData?.break_start_from)
            set_break_ended_in(updateData?.break_ended_in)
            set_work_permit_usa(updateData?.work_permit_usa)
            set_work_permit_other_contry(updateData?.work_permit_other_contry)
            // set_permanent_address(updateData?.permanent_address)
            set_house_flat_no(updateData?.house_flat_no)
            set_street_name(updateData?.street_name)
            set_locality_area(updateData?.locality_area)
            set_city(updateData?.city)
            set_state(updateData?.state)
            set_state_label(updateData?.state_label)
            set_postal_zip(updateData?.pincode)
            set_country(updateData?.country)
            set_country_label(updateData?.country_label)
            set_landmark(updateData?.landmark)
            setPreferredWorkLocation(JSON.parse(updateData?.work_permit_other_contry))
            setLanguages(profileData?.language_proficiencies)
           

		} else {
			set_gender("");
            set_more_information("");
            set_marital_status("");
            set_dob("");
            set_category("");
            set_is_differently_abled("No");
            set_type("");
            set_describe_("");
            set_need_assistance("");
            set_taken_carrer_break("BreakNo");
            set_reason_for_break("");
            set_break_start_from("");
            set_break_ended_in("");
            set_work_permit_usa("");
            set_work_permit_other_contry("");
            set_permanent_address("");
            set_house_flat_no("")
            set_street_name("")
            set_locality_area("")
            set_city("")
            set_state("")
            set_postal_zip("")
            set_country("")
            set_landmark("")

            set_has_passport("PassportNo")
            set_passport_no("")
            set_date_of_issue("")
            set_date_of_expiry("")
            set_place_of_issue("")

            set_dobDate("")
            set_dobMonth("")
            set_dobYear("")
            set_country_label("")
            set_state_label("")
		}
	}, [updateData]);

  const savePersonalDetails = async (e) => {
		e.preventDefault();
		setIsLoading(true)
    setShowPersonalDetailsModal(false);
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/add-update-personal-details", { profile_id: profileId, gender	: gender	, more_information: JSON.stringify(moreInformation), marital_status: marital_status, dob:convertDate(dobYear+"-"+dobMonth+"-"+dobDate), category:category,country_label:country_label, is_differently_abled: is_differently_abled === "Yes"?1:0, type:type, describe_:describe_, need_assistance:need_assistance, taken_carrer_break:taken_carrer_break=== "BreakYes"?true:false,reason_for_break:reason_for_break, break_start_from:break_start_from, break_ended_in:break_ended_in, work_permit_usa:work_permit_usa, work_permit_other_contry:JSON.stringify(preferredWorkLocation),  house_flat_no:house_flat_no, street_name:street_name, locality_area:locality_area,city:city, state:state, state_label:state_label, pincode:postal_zip, country:country, landmark:landmark, currently_on_break:currently_on_break,languages:languages,has_passport:has_passport=== "PassportYes"?true:false, passport_no:passport_no,date_of_issue:issueDate,date_of_expiry:expiryDate,place_of_issue:place_of_issue,isLastLanguageToBeDelete:isLastLanguageToBeDelete}, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
				setProfileData(await getProfileData(profileId, setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
					
				} else {
          setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		

	}

  const handleGenderChange = (event) => {
    set_gender(event.target.id);
  };
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    set_more_information((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const handleRadioChange = (event) => {
    set_marital_status(event.target.id);
  };

  const handleDateChange = (event) => {
    set_dob(event.target.value);
  };

  const handleRadioCategoryChange = (event) => {
    set_category(event.target.id);
  };

  const isDisability = (event) => {
    set_is_differently_abled(event.target.id);
  };

  const isBreakTaken = (event) => {
    set_taken_carrer_break(event.target.id);
  };

  const havePassport = (event) => {
    set_has_passport(event.target.id);
  };

  const [preferredWorkLocation, setPreferredWorkLocation] = useState([]);
  const handleChange = (selectedOptions) => {
    if (selectedOptions.length <= 10) {
        setPreferredWorkLocation(selectedOptions);
    } else {
        alert("You can select up to 10 locations only.");
    }
};

const handleReasonBreankChange = (event) => {
  set_reason_for_break(event.target.id);
};

const [moreInformation, setMoreInformation] = useState([]);

    const handleMoreInformation = (event) => {
        const { name, checked } = event.target;

        if (checked) {
          setMoreInformation([...moreInformation, name]);
        } else {
          setMoreInformation(moreInformation.filter(type => type !== name));
        }
    };

    const [currently_on_break, set_currently_on_break] = useState(false)

    const handleOnBreak = (event) => {
      set_currently_on_break(event.target.checked);
    };

  //   const [languages, setLanguages] = useState([{ id: 1 }]);
  //   // Handler function to add a new language form
  // const handleAddAnotherLanguage = () => {
  //   setLanguages([...languages, { id: languages.length + 1 }]);
  // };

  // // Handler function to remove a language form
  // const handleRemoveLanguage = (id) => {
  //   setLanguages(languages.filter(language => language.id !== id));
  // };
  const [languages, setLanguages] = useState([
    { id: 1, language: '', proficiency: '', read_: false, write_: false, speak: false }
  ]);

  // Handler function to add a new language form
  const handleAddAnotherLanguage = () => {
    setLanguages([
      ...languages,
      { id: languages.length + 1, language: '', proficiency: '', read_: false, write_: false, speak: false }
    ]);
  };

  // Handler function to remove a language form
  const handleRemoveLanguage = (id) => {
    // console.log(id)
    // console.log(languages)
    const updatedLanguages = languages.filter(language => language.id !== id);
      // setLanguages(updatedLanguages);
    
    
    if (updatedLanguages.length === 0) {
      setIsLastLanguage(true)
      setLanguages(updatedLanguages);
      
     } else {
      setLanguages(updatedLanguages);
    }
  };

  // Handler function to update the form field values
  const handleInputChange = (id, field, value) => {
    setLanguages(languages.map(language => 
      language.id === id ? { ...language, [field]: value } : language
    ));
  };
 
  const renderMonthContent = (month, shortMonth, longMonth) => {
		const tooltipText = `Tooltip for month: ${longMonth}`;
		return <span title={tooltipText}>{shortMonth}</span>;
	};

  const setExpirtyDAte = (date) =>{

    setExpiryDate(date)

  }

  const setIssueDAte = (date) =>{

    setIssueDate(date)

  }

  const today = new Date();
  const minDate = new Date(today.getFullYear() - 16, today.getMonth(), today.getDate());

  const breakStartCalculation = (date) =>{
    if(new Date(date) < new Date(currempdata.joining_date)){
      set_break_start_from(date)
    } else{
      toast.error("Break start date should be older than current employer joined date", {
        position: 'bottom-center'
      })
      set_break_start_from("")
    }
    
  }

  const breakEndCalculation = (date) =>{
    if(new Date(date) < new Date(currempdata.joining_date) && new Date(date) > new Date(break_start_from)){
      set_break_ended_in(date)
    } else{
      toast.error("Break end date should be older than current employer joined date and newer than break start date", {
        position: 'bottom-center'
      })
      set_break_ended_in("")
    }
    
  }

  const days = Array.from({ length: 31 }, (_, i) => i + 1);

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const currentYear = new Date().getFullYear();
  // Calculate the maximum year (current year minus 18)
  const maxYear = currentYear - 18;
  
  // Generate an array of years from 1930 to maxYear
  const years = Array.from({ length: maxYear - 1930 + 1 }, (_, i) => maxYear - i);

  const countryies = countryList.map((item)=>{
    return (
      <option key={item.name} value={item.id}>{item.name}</option>
    )
  })



  const getStateById = (id,name) =>{
    // console.log(id,name)
    set_country(id)
    set_country_label(name)
    callAxios(environment.REACT_APP_API_URL_Live+"api/v1/profile/get-state-by-id",{countryId:id},"post").then((country)=>{
      setStateList(country.data.states)
     })
    //  set_city("")
     set_city(updateData?.country === id?updateData?.city:"")
  }
  const setState = (id,name)=>{
    set_state(id)
    set_state_label(name)
    set_city(updateData?.state === id?updateData?.city:"")
  }

  const States = stateList.map((item)=>{
    return (
      <option key={item.name} value={item.id}>{item.name}</option>
    )
  })

  return (
    <Modal show={showPersonalDetailsModal} centered size='lg' onHide={closePersonalDetailsModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
      <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
        <div className="close-wrapper text-end">
          <Button variant="link" onClick={closePersonalDetailsModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
        </div>

        <div className="heading-wrapper mb-3">
          <div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
            <span className='widgetTitle text-black fs-5 fw-semibold'>Personal details</span>
            {/* This button will show only when you click to edit the details */}
            {/* <span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0'>Delete</span> */}
          </div>
          <p className='subTitle fs-14'>This information is very important and must be accurate. Please be careful when inputting your answers here.</p>
        </div>

        <Form action="" className="form-cover" onSubmit={savePersonalDetails}>
        <Form.Group className="mb-4">
      <Form.Label>Gender</Form.Label>
      <div className="radio-wrapper d-flex flex-wrap gap-2">
        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="gender"
            id="Male"
            autoComplete="off"
            checked={gender === 'Male'}
            onChange={handleGenderChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Male"
          >
            Male
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="gender"
            id="Female"
            autoComplete="off"
            checked={gender === 'Female'}
            onChange={handleGenderChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Female"
          >
            Female
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="gender"
            id="Other"
            autoComplete="off"
            checked={gender === 'Other'}
            onChange={handleGenderChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Other"
          >
            Other
          </label>
        </div>
      </div>
    </Form.Group>

          {/* <Form.Group className="mb-4">
            <div className="wrapper mb-2">
              <Form.Label className="mb-0">More information</Form.Label>
              <div className="text-clr2 fs-14">Companies are focusing on equal opportunities and might be looking for candidates from diverse backgrounds.</div>
            </div>
            <div className="radio-wrapper d-flex flex-wrap gap-2">
              <div className="item">
                <input type="checkbox" class="btn-check" name="MoreInformation" id="SingleParent" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="SingleParent">Single parent</label>
              </div>

              <div className="item">
                <input type="checkbox" class="btn-check" name="MoreInformation" id="WorkingMother" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="WorkingMother">Working mother</label>
              </div>

              <div className="item">
                <input type="checkbox" class="btn-check" name="MoreInformation" id="ServedInMilitary" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="ServedInMilitary">Served in military</label>
              </div>

              <div className="item">
                <input type="checkbox" class="btn-check" name="MoreInformation" id="Retired" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Retired">Retired (60+)</label>
              </div>

              <div className="item">
                <input type="checkbox" class="btn-check" name="MoreInformation" id="LGBTQ+" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="LGBTQ+">LGBTQ+ +</label>
              </div>
            </div>
          </Form.Group> */}

{/* <Form.Group className="mb-4">
      <div className="wrapper mb-2">
        <Form.Label className="mb-0">More information</Form.Label>
        <div className="text-clr2 fs-14">
          Companies are focusing on equal opportunities and might be looking for candidates from diverse backgrounds.
        </div>
      </div>
      <div className="radio-wrapper d-flex flex-wrap gap-2">
        <div className="item">
          <input
            type="checkbox"
            className="btn-check"
            name="Single Parent"
            id="SingleParent"
            autoComplete="off"
            checked={moreInformation.includes('Single Parent')} 
            onChange={handleMoreInformation}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="SingleParent"
          >
            Single parent
          </label>
        </div>

        <div className="item">
          <input
            type="checkbox"
            className="btn-check"
            name="Working Mother"
            id="WorkingMother"
            autoComplete="off"
            checked={moreInformation.includes('Working Mother')} 
            onChange={handleMoreInformation}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="WorkingMother"
          >
            Working mother
          </label>
        </div>

        <div className="item">
          <input
            type="checkbox"
            className="btn-check"
            name="Served In Military"
            id="ServedInMilitary"
            autoComplete="off"
            checked={moreInformation.includes('Served In Military')} 
            onChange={handleMoreInformation}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="ServedInMilitary"
          >
            Served in military
          </label>
        </div>

        <div className="item">
          <input
            type="checkbox"
            className="btn-check"
            name="Retired"
            id="Retired"
            autoComplete="off"
            checked={moreInformation.includes('Retired')} 
            onChange={handleMoreInformation}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Retired"
          >
            Retired (60+)
          </label>
        </div>

        <div className="item">
          <input
            type="checkbox"
            className="btn-check"
            name="LGBTQ"
            id="LGBTQ"
            autoComplete="off"
            checked={moreInformation.includes('LGBTQ')} 
            onChange={handleMoreInformation}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="LGBTQ"
          >
            LGBTQ+
          </label>
        </div>
      </div>
    </Form.Group> */}

          {/* <Form.Group className="mb-4">
            <Form.Label>Marital status</Form.Label>
            <div className="radio-wrapper d-flex flex-wrap gap-2">
              <div className="item">
                <input type="radio" class="btn-check" name="maritalStatus" id="Single/unmarried" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Single/unmarried">Single/unmarried</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="maritalStatus" id="Married" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Married">Married</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="maritalStatus" id="Widowed" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Widowed">Widowed</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="maritalStatus" id="Divorced" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Divorced">Divorced</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="maritalStatus" id="Separated" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Separated">Separated</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="maritalStatus" id="Other" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Other">Other</label>
              </div>
            </div>
          </Form.Group> */}

<Form.Group className="mb-4">
      <Form.Label>Marital status</Form.Label>
      <div className="radio-wrapper d-flex flex-wrap gap-2">
        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="maritalStatus"
            id="Single/unmarried"
            autoComplete="off"
            checked={marital_status === 'Single/unmarried'}
            onChange={handleRadioChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Single/unmarried"
          >
            Single/unmarried
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="maritalStatus"
            id="Married"
            autoComplete="off"
            checked={marital_status === 'Married'}
            onChange={handleRadioChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Married"
          >
            Married
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="maritalStatus"
            id="Widowed"
            autoComplete="off"
            checked={marital_status === 'Widowed'}
            onChange={handleRadioChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Widowed"
          >
            Widowed
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="maritalStatus"
            id="Divorced"
            autoComplete="off"
            checked={marital_status === 'Divorced'}
            onChange={handleRadioChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Divorced"
          >
            Divorced
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="maritalStatus"
            id="Separated"
            autoComplete="off"
            checked={marital_status === 'Separated'}
            onChange={handleRadioChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Separated"
          >
            Separated
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="maritalStatus"
            id="Other"
            autoComplete="off"
            checked={marital_status === 'Other'}
            onChange={handleRadioChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Other"
          >
            Other
          </label>
        </div>
      </div>
    </Form.Group>

       



    <div className="row gx-2 gx-lg-3">
    <Form.Label>Date of birth<span className='text-danger'></span></Form.Label>
            <div className="col-4">
            
              <Form.Group className="mb-3">
                
                <Form.Select name="dobDate" value={dobDate}  onChange={(e)=>{set_dobDate(e.target.value)}}>
              <option selected hidden value=''>Select Day</option>
              {days.map(day => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
              
            </Form.Select>
              </Form.Group>
            </div>

            <div className="col-4">
              <Form.Group className="mb-3">
              
                <Form.Select name="dobMonth" value={dobMonth}  onChange={(e)=>{set_dobMonth(e.target.value)}}>
              <option selected hidden value=''>Select Month</option>
              {months.map((month, index) => (
          <option key={index} value={month}>
            {month}
          </option>
        ))}
              
            </Form.Select>
              </Form.Group>
            </div>

            <div className="col-4">
              <Form.Group className="mb-3">
               
                <Form.Select name="dobYear" value={dobYear}  onChange={(e)=>{set_dobYear(e.target.value)}}>
              <option selected hidden value=''>Select Year</option>
              {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
              
            </Form.Select>
              </Form.Group>
            </div>

            
          </div>

          {/* <Form.Group className="mb-4">
            <div className="wrapper mb-2">
              <Form.Label className="mb-0">Category</Form.Label>
              <div className="text-clr2 fs-14">Companies welcome people from various categories to bring equality among all citizens</div>
            </div>
            <div className="radio-wrapper d-flex flex-wrap gap-2">
              <div className="item">
                <input type="radio" class="btn-check" name="Category" id="General" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="General">General</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="Category" id="ScheduledCaste" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="ScheduledCaste">Scheduled Caste (SC)</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="Category" id="scheduledTribe" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="scheduledTribe">Scheduled Tribe (ST)</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="Category" id="OBCCreamy" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="OBCCreamy">OBC - Creamy</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="Category" id="OBCNonCreamy" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="OBCNonCreamy">OBC - Non creamy</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="Category" id="Other1" autocomplete="off" />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Other1">Other</label>
              </div>
            </div>
          </Form.Group> */}

{/* <Form.Group className="mb-4">
      <div className="wrapper mb-2">
        <Form.Label className="mb-0">Category</Form.Label>
        <div className="text-clr2 fs-14">
          Companies welcome people from various categories to bring equality among all citizens.
        </div>
      </div>
      <div className="radio-wrapper d-flex flex-wrap gap-2">
        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="Category"
            id="General"
            autoComplete="off"
            checked={category === 'General'}
            onChange={handleRadioCategoryChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="General"
          >
            General
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="Category"
            id="Scheduled-Caste"
            autoComplete="off"
            checked={category === 'Scheduled-Caste'}
            onChange={handleRadioCategoryChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Scheduled-Caste"
          >
            Scheduled Caste (SC)
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="Category"
            id="Scheduled-Tribe"
            autoComplete="off"
            checked={category === 'Scheduled-Tribe'}
            onChange={handleRadioCategoryChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Scheduled-Tribe"
          >
            Scheduled Tribe (ST)
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="Category"
            id="OBC-Creamy"
            autoComplete="off"
            checked={category === 'OBC-Creamy'}
            onChange={handleRadioCategoryChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="OBC-Creamy"
          >
            OBC - Creamy
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="Category"
            id="OBC-NonCreamy"
            autoComplete="off"
            checked={category === 'OBC-NonCreamy'}
            onChange={handleRadioCategoryChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="OBC-NonCreamy"
          >
            OBC - Non creamy
          </label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="btn-check"
            name="Category"
            id="Other1"
            autoComplete="off"
            checked={category === 'Other1'}
            onChange={handleRadioCategoryChange}
          />
          <label
            className="fs-14 btn btn-outline-secondary rounded-pill"
            htmlFor="Other1"
          >
            Other
          </label>
        </div>
      </div>
    </Form.Group> */}

          {/* <Form.Group className="mb-3">
            <Form.Label>Are you differently abled?</Form.Label>
            <div className='radio-wrapper d-flex flex-wrap column-gap-3 gap-lg-5 ms-1'>
              <div className="form-check form-check-inline d-flex align-items-center gap-2">
                <input type="radio" className="form-check-input fs-6 border border-secondary" id="Yes" name='differentlyAbled' />
                <label className="form-check-label ms-1 pt-1" htmlFor="Yes">Yes</label>
              </div>
              <div className="form-check form-check-inline d-flex align-items-center gap-2">
                <input type="radio" className="form-check-input fs-6 border border-secondary" id="No" name='differentlyAbled' />
                <label className="form-check-label ms-1 pt-1" htmlFor="No">No</label>
              </div>
            </div>
          </Form.Group> */}

<Form.Group className="mb-3">
      <Form.Label>Are you differently abled?</Form.Label>
      <div className='radio-wrapper d-flex flex-wrap column-gap-3 gap-lg-5 ms-1'>
        <div className="form-check form-check-inline d-flex align-items-center gap-2">
          <input
            type="radio"
            className="form-check-input fs-6 border border-secondary"
            id="Yes"
            name='differentlyAbled'
            checked={is_differently_abled === 'Yes'}
            onChange={isDisability}
          />
          <label className="form-check-label ms-1 pt-1" htmlFor="Yes">Yes</label>
        </div>
        <div className="form-check form-check-inline d-flex align-items-center gap-2">
          <input
            type="radio"
            className="form-check-input fs-6 border border-secondary"
            id="No"
            name='differentlyAbled'
            checked={is_differently_abled === 'No'}
            onChange={isDisability}
          />
          <label className="form-check-label ms-1 pt-1" htmlFor="No">No</label>
        </div>
      </div>
    </Form.Group>

          {/* Start: Enable this if differently abled type is yes */}
          {is_differently_abled === "Yes"?
          <>
            <Form.Group className="mb-3">
            <Form.Label>Type<span className='text-danger'>*</span></Form.Label>
            <Form.Select name="Type" value={type} required={is_differently_abled === "Yes"? true:false} onChange={(e)=>{set_type(e.target.value)}}>
              <option selected hidden value=''>Select differently abled type</option>
              <option value="Blindness">Blindness</option>
              <option value="Low Vision">Low Vision</option>
              <option value="Hearing Impairment">Hearing Impairment</option>
              <option value="Speech and Language Disability">Speech and Language Disability</option>
              <option value="Locomotor Disability">Locomotor Disability</option>
              <option value="Leprosy Cured Person">Leprosy Cured Person</option>
              <option value="Others">Others</option>
            </Form.Select>
          </Form.Group>
          {type === "Others"?
          <Form.Group className="mb-3">
            <Form.Label>Describe<span className='text-danger'>*</span></Form.Label>
            <Form.Control type="text" value={describe_} placeholder="Specify the condition which makes you differently abled" maxLength={150} autoComplete='off' onChange={(e)=>{set_describe_(e.target.value)}} required={is_differently_abled? true:false} />
          </Form.Group>
          :null}

          <Form.Group>
            <Form.Label>Do you need assistance at your workplace?</Form.Label>
            <Form.Control as="textarea" value={need_assistance} onChange={(e)=>{set_need_assistance(e.target.value)}} rows={4} placeholder='Type here (example – Need wheelchair)' maxLength={250} autoComplete='off' />
            <div className="charLenght fs-12 fw-medium text-clr2 text-end mt-2">500 character(s) left</div>
          </Form.Group>
          </>
          
           :null}
          {/* End: Enable this if differently abled type is yes */}

          {/* <Form.Group className="mb-3">
            <Form.Label>Have you taken a career break?</Form.Label>
            <div className='radio-wrapper d-flex flex-wrap column-gap-3 gap-lg-5 ms-1'>
              <div className="form-check form-check-inline d-flex align-items-center gap-2">
                <input type="radio" className="form-check-input fs-6 border border-secondary" id="BreakYes" name='careerBreak' />
                <label className="form-check-label ms-1 pt-1" htmlFor="BreakYes">Yes</label>
              </div>
              <div className="form-check form-check-inline d-flex align-items-center gap-2">
                <input type="radio" className="form-check-input fs-6 border border-secondary" id="BreakNo" name='careerBreak' />
                <label className="form-check-label ms-1 pt-1" htmlFor="BreakNo">No</label>
              </div>
            </div>
          </Form.Group> */}

<Form.Group className="mb-3">
      <Form.Label>Have you taken a career break at any moment in your total career before VE? 
      </Form.Label>
      <div className='radio-wrapper d-flex flex-wrap column-gap-3 gap-lg-5 ms-1'>
        <div className="form-check form-check-inline d-flex align-items-center gap-2">
          <input
            type="radio"
            className="form-check-input fs-6 border border-secondary"
            id="BreakYes"
            name="careerBreak"
            checked={taken_carrer_break === 'BreakYes'}
            onChange={isBreakTaken}
          />
          <label className="form-check-label ms-1 pt-1" htmlFor="BreakYes">Yes</label>
        </div>
        <div className="form-check form-check-inline d-flex align-items-center gap-2">
          <input
            type="radio"
            className="form-check-input fs-6 border border-secondary"
            id="BreakNo"
            name="careerBreak"
            checked={taken_carrer_break === 'BreakNo'}
            onChange={isBreakTaken}
          />
          <label className="form-check-label ms-1 pt-1" htmlFor="BreakNo">No</label>
        </div>
      </div>
    </Form.Group>

          {/* Start: Enable this if career break type is yes */}
          {taken_carrer_break === "BreakYes"?
          <>
          
          <Form.Group className="mb-3">
            <Form.Label>Reason of break at that moment<span className='text-danger'>*</span></Form.Label>
            <div className="radio-wrapper d-flex flex-wrap gap-2">
              <div className="item">
                <input type="radio" class="btn-check" name="ReasonOfBreak" id="Child-care" autocomplete="off" required  checked={reason_for_break === 'Child-care'}
            onChange={handleReasonBreankChange}/>
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Child-care">Child care</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="ReasonOfBreak" id="Education" autocomplete="off" checked={reason_for_break === 'Education'}
            onChange={handleReasonBreankChange}/>
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Education">Education</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="ReasonOfBreak" id="Medical" autocomplete="off" checked={reason_for_break === 'Medical'}
            onChange={handleReasonBreankChange}/>
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Medical">Medical</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="ReasonOfBreak" id="Layoff" autocomplete="off" checked={reason_for_break === 'Layoff'}
            onChange={handleReasonBreankChange} />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Layoff">Layoff</label>
              </div>

              <div className="item">
                <input type="radio" class="btn-check" name="ReasonOfBreak" id="Personal" autocomplete="off" checked={reason_for_break === 'Personal'}
            onChange={handleReasonBreankChange} />
                <label class="fs-14 btn btn-outline-secondary rounded-pill" for="Personal">Personal</label>
              </div>
            </div>
          </Form.Group>

          <div className="row gx-2 gx-lg-3">
            <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>Break started from<span className='text-danger'>*</span></Form.Label>
                <Form.Control type="month" autoComplete='off' required onChange={(e)=>{breakStartCalculation(e.target.value)}} value={break_start_from}/>
              </Form.Group>
            </div>

            {/* Start: Hide this if Currently on a break checkbox is tick */}
            {!currently_on_break?
            <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>Break ended in<span className='text-danger'>*</span></Form.Label>
                <Form.Control type="month" autoComplete='off' required onChange={(e)=>{breakEndCalculation(e.target.value)}} value={break_ended_in} />
              </Form.Group>
            </div>
            :null}
            {/* End: Hide this if Currently on a break checkbox is tick */}
            {currently_on_break ?
            <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>To<span className='text-danger'>*</span></Form.Label>
                <Form.Control type="text" value={'Present'} autoComplete='off' disabled readOnly required />
              </Form.Group>
            </div>
            :null}
          </div>

         
          {/* <Form.Group className="mb-3">
      <div className='radio-wrapper d-flex flex-wrap column-gap-4 gap-lg-5 ms-1'>
        <div className="form-check form-check-inline d-flex align-items-center gap-2">
         
          <input
            type="checkbox"
            className="form-check-input fs-6 border border-secondary"
            id="CurrentlyOnBreak"
            name='CurrentlyOnBreak'
            checked={currently_on_break?true:false}
            onChange={handleOnBreak}
          />
          <label className="form-check-label ms-1 pt-1" htmlFor="CurrentlyOnBreak">Currently on a break</label>
        </div>
      </div>
    </Form.Group> */}
          </>
         

          :
          null }
          {/* End: Enable this if career break type is yes */}

          <Form.Group className="mb-3">
      <Form.Label>Do you have a valid passport?</Form.Label>
      <div className='radio-wrapper d-flex flex-wrap column-gap-3 gap-lg-5 ms-1'>
        <div className="form-check form-check-inline d-flex align-items-center gap-2">
          <input
            type="radio"
            className="form-check-input fs-6 border border-secondary"
            id="PassportYes"
            name="passport"
            checked={has_passport === 'PassportYes'}
            onChange={havePassport}
          />
          <label className="form-check-label ms-1 pt-1" htmlFor="PassportYes">Yes</label>
        </div>
        <div className="form-check form-check-inline d-flex align-items-center gap-2">
          <input
            type="radio"
            className="form-check-input fs-6 border border-secondary"
            id="PassportNo"
            name="passport"
            checked={has_passport === 'PassportNo'}
            onChange={havePassport}
          />
          <label className="form-check-label ms-1 pt-1" htmlFor="PassportNo">No</label>
        </div>
      </div>
          </Form.Group>

          {has_passport === "PassportYes"?
          <>
            <div className="row gx-2 gx-lg-3">
            <div className="col-12">
              <Form.Group className="mb-3">
                <Form.Label>Passport Number<span className='text-danger'>*</span></Form.Label>
                <Form.Control type="text" autoComplete='off' required onChange={(e)=>{set_passport_no(e.target.value)}} value={passport_no} placeholder='Enter passport number'/>
              </Form.Group>
            </div>           
          </div>
          <div className="row gx-2 gx-lg-3">
            <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>Date of Issue<span className='text-danger'>*</span></Form.Label>
                {/* <DatePicker showIcon
									// selected={new Date()}
									selected={issueDate}
									onChange={(date) => { setIssueDAte(date) }}
									className="wonDatePicker mb-0 form-control"
									id="wonDatePicker"
									renderMonthContent={renderMonthContent}
									showFullMonthYearPicker
									dateFormat="yyyy-MM-dd"
								/> */}

<DatePicker
      showIcon
      selected={issueDate}
      onChange={(date) => setIssueDate(date)}
      className="wonDatePicker mb-0 form-control"
      id="wonDatePicker"
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {Array.from({ length: 16 }, (_, i) => today.getFullYear() - i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>

          <select
            value={date.getMonth()}
            onChange={({ target: { value } }) => changeMonth(value)}
          >
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((month, index) => (
              <option key={month} value={index}>
                {month}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      dateFormat="yyyy-MM-dd"
      minDate={minDate}
      maxDate={today}
    />
              </Form.Group>
            </div>     
            <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>Date of Expiry<span className='text-danger'>*</span></Form.Label>
                {/* <DatePicker showIcon
									// selected={new Date()}
									selected={expiryDate}
									onChange={(date) => { setExpirtyDAte(date) }}
									className="wonDatePicker mb-0 form-control"
									id="wonDatePicker"
									renderMonthContent={renderMonthContent}
									showFullMonthYearPicker
									dateFormat="yyyy-MM-dd"
								/> */}
                <DatePicker
      selected={expiryDate}
      onChange={(date) => setExpiryDate(date)}
      className="wonDatePicker mb-0 form-control"
      id="wonDatePicker"
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {Array.from({ length: 20 }, (_, i) => i + new Date().getFullYear()).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>

          <select
            value={date.getMonth()}
            onChange={({ target: { value } }) => changeMonth(value)}
          >
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((month, index) => (
              <option key={month} value={index}>
                {month}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      dateFormat="yyyy-MM-dd"
    />
              </Form.Group>
            </div>        
          </div>
          
          <div className="row gx-2 gx-lg-3">
            <div className="col-12">
              <Form.Group className="mb-3">
                <Form.Label>Place of Issue<span className='text-danger'>*</span></Form.Label>
                <Form.Control type="text" autoComplete='off' required onChange={(e)=>{set_place_of_issue(e.target.value)}} value={place_of_issue} placeholder='Enter place of issue'/>
              </Form.Group>
            </div>           
          </div>
          </>
          :null
          }

          {/* <Form.Group className="mb-3">
            <Form.Label>Work permit for USA</Form.Label>
            <Form.Select name="jobRole" value={work_permit_usa} onChange={(e)=>{set_work_permit_usa(e.target.value)}}>
              <option selected hidden value=''>Select work permit</option>
              <option value="Have US H1 Visa">Have US H1 Visa</option>
              <option value="Need US H1 Visa">Need US H1 Visa</option>
              <option value="US TN Permit Holder">US TN Permit Holder</option>
              <option value="US Green Card Holder">US Green Card Holder</option>
              <option value="US Citizen">US Citizen</option>
              <option value="Authorized to work in US">Authorized to work in US</option>
            </Form.Select>
          </Form.Group> */}

          <Form.Group className="mb-3">
            <Form.Label>Do you have work permit for any country</Form.Label>
            <Select isMulti name="permitCountries"
              closeMenuOnSelect={false} className='custom-select text-dark' classNamePrefix='custom-select'
              components={animatedComponents}
              // defaultValue={[colourOptions[4], colourOptions[5]]}
              options={permitCountries}
              styles={customStyles}
              placeholder="Select countries"
              isRequired={false}
              value={preferredWorkLocation}
              onChange={handleChange}
            />
            <div className='text-clr2 fs-12 mt-1'>You can choose 3 countries at max</div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Permanent address</Form.Label>
            <p>Please fill in the following details. If any field does not apply to you, write "N/A". </p>
            {/* <Form.Control type="text" placeholder="Enter your permanent address" maxLength={150} autoComplete='off' onChange={(e)=>{set_permanent_address(e.target.value)}} value={permanent_address} /> */}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>House/Flat Number:</Form.Label>
            <Form.Control type="text" placeholder="Enter your House/flat No." maxLength={25} autoComplete='off' onChange={(e)=>{set_house_flat_no(e.target.value)}}  value={house_flat_no}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Street Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Street name"  autoComplete='off' value={street_name} onChange={(e)=>{set_street_name(e.target.value)}} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Locality / Area</Form.Label>
            <Form.Control type="text" placeholder="Enter Locality / area"  autoComplete='off' value={locality_area} onChange={(e)=>{set_locality_area(e.target.value)}} />
          </Form.Group>

         

          <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            {/* <Form.Control type="text" placeholder="Enter Country"  autoComplete='off' value={country} onChange={(e)=>{set_country(e.target.value)}} /> */}
            <Form.Select name="country" value={country} onChange={(e)=>{getStateById(e.target.value,e.target.options[e.target.selectedIndex].text)}}>
              <option selected hidden value=''>Select Country</option>
              {countryies}
             States
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>State</Form.Label>
            {/* <Form.Control type="text" placeholder="Enter State"  autoComplete='off' value={state} onChange={(e)=>{set_state(e.target.value)}} /> */}
            <Form.Select name="state" value={state} onChange={(e)=>{setState(e.target.value,e.target.options[e.target.selectedIndex].text)}}>
              <option selected hidden value=''>Select State</option>
             
              {States}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>City</Form.Label>
            <Form.Control type="text" placeholder="Enter City"  autoComplete='off' value={city} onChange={(e)=>{set_city(e.target.value)}} />
          </Form.Group>

         

          <Form.Group className="mb-3">
            <Form.Label>Postal / ZIP Code</Form.Label>
            <Form.Control type="text" placeholder="Enter postal code"  autoComplete='off' value={postal_zip} onChange={(e)=>{set_postal_zip(e.target.value)}} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Landmark</Form.Label>
            <Form.Control type="text" placeholder="Enter Landmark"  autoComplete='off' value={landmark} onChange={(e)=>{set_landmark(e.target.value)}} />
          </Form.Group>

          <div className="widgetHead mb-3">
            <div className='widgetTitle text-black fs-5 fw-semibold'>Language proficiency</div>
            <p className='subTitle fs-14'>Let us know your proficiency level in each language you know. This will help us assign clients to you accordingly. Based on your inputs, we might arrange a language test for you. Please be accurate with your choices in this form. </p>
          </div>

  <div>
      {languages.map((language, index) => (
        <div key={language.id} className="row gx-2 gx-lg-3 mb-3">
          <div className="col-6">
            <Form.Group className="mb-3">
              <Form.Label>Language<span className='text-danger'>*</span></Form.Label>
              <Form.Control 
                type="text" 
                placeholder='Enter language' 
                maxLength={10} 
                autoComplete='off' 
                required 
                value={language.language}
                onChange={(e) => handleInputChange(language.id, 'language', e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group className="mb-3">
              <Form.Label>Proficiency<span className='text-danger'>*</span></Form.Label>
              <Form.Select 
                name={`Proficiency_${language.id}`} 
                required 
                value={language.proficiency}
                onChange={(e) => handleInputChange(language.id, 'proficiency', e.target.value)}
              >
                <option hidden value=''>Select proficiency</option>
                <option value="Beginner">Beginner</option>
                <option value="Proficient">Proficient</option>
                <option value="Expert">Expert</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col-12 mb-3">
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
              <div className="form-check form-check-inline d-flex align-items-center gap-2 ms-1">
                <input 
                  type="checkbox" 
                  className="form-check-input fs-6 border border-secondary" 
                  id={`Read_${language.id}`} 
                  name={`Read_${language.id}`}
                  checked={language.read_}
                  onChange={(e) => handleInputChange(language.id, 'read_', e.target.checked)}
                />
                <label className="form-check-label ms-1 pt-1" htmlFor={`Read_${language.id}`}>Read</label>
              </div>
              <div className="form-check form-check-inline d-flex align-items-center gap-2 ms-1">
                <input 
                  type="checkbox" 
                  className="form-check-input fs-6 border border-secondary" 
                  id={`Write_${language.id}`} 
                  name={`Write_${language.id}`}
                  checked={language.write_}
                  onChange={(e) => handleInputChange(language.id, 'write_', e.target.checked)}
                />
                <label className="form-check-label ms-1 pt-1" htmlFor={`Write_${language.id}`}>Write</label>
              </div>
              <div className="form-check form-check-inline d-flex align-items-center gap-2 ms-1">
                <input 
                  type="checkbox" 
                  className="form-check-input fs-6 border border-secondary" 
                  id={`Speak_${language.id}`} 
                  name={`Speak_${language.id}`}
                  checked={language.speak}
                  onChange={(e) => handleInputChange(language.id, 'speak', e.target.checked)}
                />
                <label className="form-check-label ms-1 pt-1" htmlFor={`Speak_${language.id}`}>Speak</label>
              </div>
              <div>
                <Button
                  variant='link'
                  className='fs-14 fw-semibold text-primary text-decoration-none border-0 p-0'
                  onClick={() => handleRemoveLanguage(language.id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
      ))}
      {languages.length  === 0?
      <Form.Group className="mb-2">
        <Button
          variant="link"
          className='fs-14 fw-semibold text-primary text-decoration-none border-0 p-0'
          onClick={handleAddAnotherLanguage}
        >
          Add language
        </Button>
      </Form.Group>
      :
      <Form.Group className="mb-2">
        <Button
          variant="link"
          className='fs-14 fw-semibold text-primary text-decoration-none border-0 p-0'
          onClick={handleAddAnotherLanguage}
        >
          Add another language
        </Button>
      </Form.Group>
  }
    </div>

          <div className="btn-wrapper d-flex flex-wrap justify-content-end gap-2 pt-3">
            <Button variant="danger" onClick={closePersonalDetailsModal} className='rounded-pill px-4'>Cancel</Button>
            <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

