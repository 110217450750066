import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAxios, convertReadable, convertReadableTime, convertSecondsToMinutes } from "../utils/utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { environment } from "../environment";




const Card = ({ item, id, user, active, setActive, setCallDetails, setDetailsLoading,startDate }) => {
  //  console.log(startDate)

  const navigate = useNavigate();

  useEffect(() => {
    if (id === 0) {
      getCallDetails(item.email_)
    }
  }, [])
  let setDetails = []
  const getCallDetails = (email) => {
    //  console.log(email)
    setActive(id);
    setDetailsLoading(true);
     callAxios(environment.REACT_APP_API_URL_Live + "api/v2/crm/get-call-details", { email: email,userType:localStorage.getItem("userRole"),startDate:startDate }, "post").then((callDetails) => {
      // setDetails.push(item)
      setCallDetails(callDetails.data.details);
      setDetailsLoading(false);
     })
  }

  return (

    <>
      <div className={`client-card rounded p-3 mt-2 pointer ${id === active ? "selected" : ""}`} key={id} onClick={() => getCallDetails(item.email_)}>
        {/* <ToastContainer /> */}
        <div className="d-flex justify-content-between gap-2 mb-2">
          <div className="left-cover">
            {item.displayName ?
            <div className="">
            <div className="client-name fs-5 text-warning text-capitalize">{item.displayName}</div>
              <div className="description small text-info">{"Total Teams Calls: "+item.eachCount}</div>
            </div>
              
              : null}
             
            {item.createdAt ?
              <div className="fs-6 text-white text-opacity-75 text-capitalize">{convertReadable(item.createdAt)} <span>{convertReadableTime(item.createdAt)}</span></div>
              : null}
          </div>
          
        </div>
        {/* {item.new_skillsetjobdescription ?
          <div className="requrement-wrapper pe-4">

            <div className="client-location fs-6 text-light mb-1">Requirement Details</div>
            <div className="description small text-light text-opacity-75 ">{item.new_skillsetjobdescription}</div>

          </div> : null} */}
       
        
      </div>

    
    </>




  )
}

export default Card;