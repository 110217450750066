import React, { useEffect, useState } from "react";
import './email.scss';
import { Button, NavLink } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";

import { ReadEmail } from "./ReadEmail";

import { WriteEmail } from "./WriteEmail";
import { ReplyEmail } from "./ReplyEmail";
import { EmailListing } from "./EmailListing";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { setUserInfo } from "../store/actions";
import { connect } from "react-redux";



const MailList = ({setLoggedinUser, setEmails,  emails, emailListLoading, setEmailListLoading}) => {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [isClickNew, setNewClick] = useState(false)
    const [isClickInbox, setInboxClick] = useState(true)

    // Initialize state to track the active menu
    const [activeMenu, setActiveMenu] = useState(0);

    // For Sidebar list selected card
    const [active, setActive] = useState(0);

    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
    
          setLoggedinUser(userInfo);
         
        }
      }, [user, loading]);

    // Handler function to update the active Menu
    const handleClick = (index) => {
        console.log(index)
        setEmailType(index)
        setActiveMenu(index);
        if(index ==="Inbox"){
            setNewClick(false);
            setShowReply(false)
        setInboxClick(true)
        }
        if(index ==="SentItems"){
            setNewClick(false);
            setShowReply(false)
        setInboxClick(true)
        }
    };

    const newMailPage = () => {
        setNewClick(true);
        setInboxClick(false)
        setShowReply(false)
    }
    // console.log(isClickNew, isClickInbox)

    const [showReply, setShowReply] = useState(false);
    const handleReplyClose = () => {setShowReply(false); };
    const handleReplyShow = () => {
        setShowReply(true);
    }


    const [showCurrentEmail, setShowCurrentEmail] = useState(false);
    const [currentEmail, setCurrentEmail] = useState("");
    const [currentEmailBody, setCurrentEmailBody] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [emailsById, setEmailsById] = useState([]);
   
    const [isLoading, setIsLoading] = useState(true);


    const [emailType, setEmailType] = useState("Inbox");

    // console.log(emailType)
    return (
        <div className="MailList-page new-mail-page py-3">
            <div className="container-fluid px-xxl-5">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 border-bottom border-3 border-primary border-opacity-75 mb-2 mb-md-3 pb-2 pb-md-3">
                    <div className="fs-3 fw-semibold text-light">Email</div>
                    <Button variant='light' className="rounded px-3" onClick={newMailPage} ><i className="bi bi-plus-circle text-success me-1"></i> New Mail</Button>
                </div>

                <div className="custom-wrapper d-lg-flex gap-2">
                    <div className="email-menu-section list-inline d-flex flex-nowrap flex-lg-column gap-1 overflow-x-auto mb-2">
                        <li className="nav-item">
                            <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${emailType === "Inbox" ? 'active' : ''}`} onClick={() => handleClick("Inbox")}><i className="bi bi-inboxes"></i> Inbox</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${emailType === "SentItems" ? 'active' : ''}`} onClick={() => handleClick("SentItems")}><i className="bi bi-send"></i> Sent</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${emailType === "JunkEmail" ? 'active' : ''}`} onClick={() => handleClick("JunkEmail")}><i className="bi bi-file-earmark"></i> Junk Email</NavLink>
                        </li>
                    </div>
                    <div className="email-details-section client-detail-wrapperr">
                        <div className="row g-3 g-md-2">
                            <div className="col-md-4 col-xxl-3">
                                <EmailListing showCurrentEmail={showCurrentEmail} setShowCurrentEmail={setShowCurrentEmail} currentEmail={currentEmail} setCurrentEmail={setCurrentEmail} currentEmailBody={currentEmailBody} setCurrentEmailBody={setCurrentEmailBody} setAttachments={setAttachments} setEmailsById={setEmailsById} setNewClick={setNewClick} setInboxClick={setInboxClick} setEmailType={setEmailType} emailType={emailType} setIsLoading={setIsLoading} isLoading={isLoading} setShowReply={setShowReply} setEmails={setEmails} emails={emails} emailListLoading={emailListLoading} setEmailListLoading={setEmailListLoading}/>
                            </div>
                            {isClickInbox? <ReadEmail handleReplyShow={handleReplyShow} showCurrentEmail={showCurrentEmail} setShowCurrentEmail={setShowCurrentEmail} currentEmail={currentEmail} setCurrentEmail={setCurrentEmail} currentEmailBody={currentEmailBody} setCurrentEmailBody={setCurrentEmailBody} setAttachments={setAttachments} setEmailsById={setEmailsById} attachments={attachments} emailsById={emailsById} setShowReply={setShowReply} showReply={showReply}/>: null}

                            {isClickNew?<WriteEmail setInboxClick={setInboxClick} setNewClick={setNewClick} showCurrentEmail={showCurrentEmail} setShowCurrentEmail={setShowCurrentEmail} currentEmail={currentEmail} setCurrentEmail={setCurrentEmail} currentEmailBody={currentEmailBody} setCurrentEmailBody={setCurrentEmailBody} setAttachments={setAttachments} setEmailsById={setEmailsById} /> :null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
  
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(MailList);
