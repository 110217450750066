import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { app, database, auth } from "../firebaseConfig";

// env import
import {environment} from '../environment';

import {callAxios} from "../utils/utils";
import { connect } from 'react-redux';
import { setUserInfo } from '../store/actions';
import { useAuthState } from 'react-firebase-hooks/auth';

const ClientCalls = ({setLoggedinUser}) => {
    //console.log("View Account Props");
let navigate = useNavigate();
// const {state} = useLocation();
// const { user, userRole } = state; // Read values passed on state
   //console.log(user, userRole); 
   const [user, loading, error] = useAuthState(auth);
   useEffect(() => {
       if (loading) return;
       if (!user) navigate("/");
       else {
                   
         const userInfo = {
           uid: user?.uid,
           email: user?.email,
           displayName: user?.displayName,
           accessToken: user?.accessToken,
           idToken: user?.idToken,
         };

         setLoggedinUser(userInfo);
        
       }
     }, [user, loading]);
   const shortName =user?.displayName.split(" ").map((shortName) => {
    return shortName[0];
  });

  

  const navigateToTeamsCall = (e)=>{
    e.preventDefault();
    navigate('/team-calls-admin');
   } 

   const navigateToPSTN = (e)=>{
    e.preventDefault();
    navigate('/client-pstn-calls');
   } 

   const navigateToGoLive = (e)=>{
    e.preventDefault();
    navigate('/go-live-calls');
   } 
   

   
   
    return (
        <div className='account_page py-3'>
            <div className='container'>
                <div class="main-heading text-warning text-opacity-75 mb-4">Client Calls</div>
                <div class="row">
                    
                    <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Teams Calls 
                                    <span><i class="bi bi-microsoft-teams lh-1 fs-2"></i></span>
                                </div>
                                <div class="card-text small mb-5">Access details of all client calls conducted by CAM via Microsoft Teams. Only the calls recorded on Teams will be available here, ensuring you have a comprehensive view of these important interactions.</div>
                                <a href="#" onClick={navigateToTeamsCall} class="btn btn-primary rounded-1 py-2 px-4">Teams Calls <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Calls to Client Phone/Mobile 
                                    <span><i class="bi bi-telephone-outbound lh-1 fs-2"></i></span>
                                </div>
                                <div class="card-text small mb-5">Explore detailed information about all client calls conducted by CAM on clients' mobile or phone numbers. Gain a comprehensive view of these important interactions, ensuring you stay informed and up-to-date.</div>
                                <a href="#" onClick={navigateToPSTN} class="btn btn-primary rounded-1 py-2 px-4">Client Mobile Calls <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Go-Live Calls 
                                    <span><i class="bi bi-camera-reels lh-1 fs-2"></i></span>
                                </div>
                                <div class="card-text small mb-4">In this section, you'll find details of all NM-Live calls where CAM connects with clients in real-time as they fill out our forms. These immediate and golden opportunities for connection offer comprehensive insights into how our CAM handles these critical interactions.</div>
                                <a href="#" onClick={navigateToGoLive} class="btn btn-primary rounded-1 py-2 px-4">Go-Live Calls <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                

                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
  
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(ClientCalls);