import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { OptionNo } from './OptionNo';
import { OptionYes } from './OptionYes';
import { YesIntern } from './YesIntern';
import { NoIntern } from './NoIntern';
import { environment } from '../../environment';
import { getEmploymentData, getProfileData } from '../../utils/utils';


export const AddEmployment = ({ showEmploymentModal, closeEmploymentModal, sendEmploymentdata, empdataload, profileId, setIsLoading, setShowEmploymentModal, setProfileData,setEmploymentData,setCurrempdata }) => {
    // const getUserId = process.env.REACT_APP_USER_ID
    const [curryes, setCurryes] = useState(true)
    const [currno, setCurrno] = useState(false)
    const [empfull, setEmpfull] = useState(true)
    const [empintern, setEmpintern] = useState(false)
    const [showdata, setshowdata] = useState('yesFulltime')
    const [getskilldata, setSkilldata] =  useState([])
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [remainingCharacters, setRemainingCharacters] = useState(4000);
    const [formData, setFormData] = useState({
        formOneData: {},
        formTwoData: {},
        formThreeData: {},
        formFourData: {}
    });
    const selectOption = (value) => {
        setFormData({
            formOneData: {},
            formTwoData: {},
            formThreeData: {},
            formFourData: {}
        });
        if(value == 'yes' && empfull == true){
            setCurrno(false)
            setCurryes(true)
            setshowdata('yesFulltime')
        }
        if(value == 'yes' && empintern == true){
            setCurrno(false)
            setCurryes(true)
            setshowdata('yesIntern')
        }
        if(value == 'no' && empfull == true){
            setCurryes(false)
            setCurrno(true)
            setshowdata('noFulltime')
        }
        if(value == 'no' && empintern == true){
            setCurryes(false)
            setCurrno(true)
            setshowdata('noIntern')
        }
        if(value == 'fulltime' && curryes == true){
            setEmpintern(false)
            setEmpfull(true)
            setshowdata('yesFulltime')
        }
        if(value == 'fulltime' && currno == true){
            setEmpintern(false)
            setEmpfull(true)
            setshowdata('noFulltime')
        }
        if(value == 'intern' && currno == true){
            setEmpfull(false)
            setEmpintern(true)
            setshowdata('noIntern')
        }
        if(value == 'intern' && curryes == true){
            setEmpfull(false)
            setEmpintern(true)
            setshowdata('yesIntern')
        }
    }

    const functoset = (skillData) => {
        setSkilldata(skillData) 
    }
    // skillsForm

    const handleFormChange = (e, formName) => {
        // const { name, value } = e.target;
        // setFormData((prevData) => ({
        //   ...prevData,
        //   [formName]: { ...prevData[formName], [name]: value }
        // }));
        if (e && e.target && e.target.name) {
            const { name, value } = e.target;
            console.log(name,value)
            if(name === "jobProfile"){
                const remaining = 4000 - value.length;
                setRemainingCharacters(remaining);
            }
            setFormData((prevData) => ({
              ...prevData,
              [formName]: { ...prevData[formName], [name]: value }
            }));
          } else {
            console.error("Invalid event or event target in handleFormChange");
          }
    };
    // skillsForm

    // convertYear
    const convertYear = (getdate, type) => {
        const dateString = `${getdate}`;
        const dateComponents = dateString.split('-');
        console.log(dateString)
        if(type == 'year'){
            const year = dateComponents[0];
            return year
        }
        if(type == 'month'){
            const month = dateComponents[1];
            return month
        }
    }
    // convertYear

    // onSubmit
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)
        setShowEmploymentModal(false)
        if(Object.keys(formData.formOneData).length !== 0){
            const formData1 = formData.formOneData;
            const getskillarray = getskilldata.map(({ id, ...rest }) => rest);
            const isCurrentEmployement = true
            const employmentType = 'Full-time'
            addSkilldata(formData1, getskillarray, isCurrentEmployement, employmentType)
            console.log(formData1)
        }
        if(Object.keys(formData.formTwoData).length !== 0){
            const formData2 = formData.formTwoData;
            const getskillarray = ''
            const isCurrentEmployement = false
            const employmentType = 'Full-time'
            addSkilldata(formData2, getskillarray, isCurrentEmployement, employmentType)
            console.log(formData2)
        }
        if(Object.keys(formData.formThreeData).length !== 0){
            const formData3 = formData.formThreeData;
            const getskillarray = ''
            const isCurrentEmployement = true
            const employmentType = 'Internship'
            addSkilldata(formData3, getskillarray, isCurrentEmployement, employmentType)
            console.log(formData3)
        }
        if(Object.keys(formData.formFourData).length !== 0){
            const formData4 = formData.formFourData;
            const getskillarray = ''
            const isCurrentEmployement = false
            const employmentType = 'Internship'
            addSkilldata(formData4, getskillarray, isCurrentEmployement, employmentType)
            console.log(formData4)
        }
        setFormData({
            formOneData: {},
            formTwoData: {},
            formThreeData: {},
            formFourData: {}
        });
        closeEmploymentModal()
        // Add your logic here to submit both form data
    };
    // onSubmit

    // addSkill
    const addSkilldata = async(getData, getskillarray, isCurrentEmployement, employmentType) => {
        console.log(selectedSkills)
       
        const postData = {
            "profileId": profileId, 
            "isCurrentEmployement":isCurrentEmployement, 
            "employmentType":employmentType, 
            "totalExpYears": getData?.years != undefined ? getData?.years : '', 
            "totalExpMonths": getData?.months != undefined ? getData?.months : '', 
            "companyName": getData?.currCmp != undefined ? getData?.currCmp : '', 
            "jobTitle": getData?.currJobtitle != undefined ? getData?.currJobtitle : '', 
            "joiningDateYear": getData?.joiningDate != undefined ? convertYear(getData?.joiningDate,'year') : '',  
            "joiningDateMonth": getData?.joiningDate != undefined ? convertYear(getData?.joiningDate,'month') : '',  
            "salary": getData?.currentSalary != undefined ? getData?.currentSalary : '', 
            "salaryType": getData?.salaryType != undefined && getData?.salaryType != '' ? getData?.salaryType : 'INR', 
            "jobProfile": getData?.jobProfile != undefined ? getData?.jobProfile : '', 
            "noticePeriod": getData?.noticePeriod != undefined ? getData?.noticePeriod : '', 
            "location": getData?.location != undefined ? getData?.location : '', 
            "department": getData?.department != undefined ? getData?.department : '', 
            "workedTillYear": getData?.workedtill != undefined ? convertYear(getData?.workedtill,'year') : '',
            "workedTillMonth": getData?.workedtill != undefined ? convertYear(getData?.workedtill,'month') : '', 
            "workingFromYear": getData?.workingfrom != undefined ? convertYear(getData?.workingfrom,'year') : '', 
            "workingFromMonth": getData?.workingfrom != undefined ? convertYear(getData?.workingfrom,'month') : '', 
            //  "Skills": [{value:"PHP",label:"PHP"},{value:"java",lable:"java"}]
             "Skills": getskilldata
        }
        // console.log(postData)
        try {
            
            const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/employment`, postData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });		
            if(response?.data?.statusCode ===200){
                console.log("in add employment response")
                const getData = await getEmploymentData(profileId, setIsLoading)
                setProfileData(getData.profileData);
                setEmploymentData(getData.employmentData)
                const getiscurremp = getData.employmentData
            const currentEmployments = getiscurremp.filter(employment => employment.is_current_employment === 1);

            // Find the employment with the highest id among the filtered employments
            const maxId = Math.max(...currentEmployments.map(employment => employment.id));

            // Filter again to get the employment with the maximum id value
            const highestEmployment = currentEmployments.find(
                employment => employment.id === maxId
            );
            // console.log(highestEmployment)
            setCurrempdata(highestEmployment)
                    toast.success('Employment has been saved Successfully.', {
                        position: 'bottom-center'
                    });
            }
            } catch (error) {
                const getData = await getEmploymentData(profileId, setIsLoading)
                setProfileData(getData.profileData);
                setEmploymentData(getData.employmentData)
                toast.error(error, {
                    position: 'bottom-center'
                });
                console.error('Error adding Employment:', error);
            }
    }
    // addSkill

    useEffect(() => {
    }, []);

	return (
		<Modal show={showEmploymentModal} centered size='lg' onHide={closeEmploymentModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
			<Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">

				<div className="close-wrapper text-end mb-2">
					<Button variant="link" onClick={closeEmploymentModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
				</div>
				<div className="heading-wrapper mb-4">
					<div className='widgetTitle text-black fs-18 fw-semibold mb-1'>Employment</div>
					<p className='subTitle fs-14'>Details like job title, company name, etc, help employers understand your work</p>
				</div>

				<Form action="" className="form-cover" onSubmit={handleSubmit}>
					<Form.Group className="mb-3">
						<Form.Label className="mb-3">Is this your current employment?</Form.Label>
						<div className='radio-wrapper'>
							<Form.Check inline type="radio" className='w-25' id='Yes' name='currentEmployment' label='Yes' checked={curryes ? true : false} required onClick={() => selectOption('yes')}/>
							<Form.Check inline type="radio" className='w-25' id='No' name='currentEmployment' label='No' checked={currno ? true : false} onClick={() => selectOption('no')}/>
						</div>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label className="mb-3">Employment type</Form.Label>
						<div className='radio-wrapper'>
                            <div>
                            {sendEmploymentdata?.employment_type === 'Full-time' && (
                                <>
                                    <Form.Check 
                                        inline 
                                        type="radio" 
                                        className='w-25' 
                                        id='Full-time' 
                                        name='employmentType' 
                                        label='Full-time' 
                                        required 
                                        checked={true} 
                                        onClick={() => selectOption('fulltime')}
                                    />                                    
                                </>
                            )}

                            {sendEmploymentdata?.employment_type === 'Internship' && (
                                <>
                                    <Form.Check 
                                        inline 
                                        type="radio" 
                                        className='w-25' 
                                        id='Internship' 
                                        name='employmentType' 
                                        label='Internship'  
                                        checked={true} 
                                        onClick={() => selectOption('intern')}
                                    />
                                </>
                            )}

                            {sendEmploymentdata === '' && (
                                <>
                                    <Form.Check 
                                        inline 
                                        type="radio" 
                                        className='w-25' 
                                        id='Full-time' 
                                        name='employmentType' 
                                        label='Full-time' 
                                        required 
                                        checked={empfull ? true : false} 
                                        onClick={() => selectOption('fulltime')}
                                    />
                                    <Form.Check 
                                        inline 
                                        type="radio" 
                                        className='w-25' 
                                        id='Internship' 
                                        name='employmentType' 
                                        label='Internship'  
                                        checked={empintern ? true : false} 
                                        onClick={() => selectOption('intern')}
                                    />
                                </>
                            )}
                            </div>
                            </div> 
					</Form.Group>
                    {showdata == 'yesFulltime' ? 
                    <OptionYes formData={formData.formOneData} handleInputChange={(e) => handleFormChange(e, 'formOneData')} getskills = {functoset} setSelectedSkills={setSelectedSkills} remainingCharacters={remainingCharacters}/> : 
                    showdata == 'noFulltime' ? 
                    <OptionNo formData={formData.formTwoData} handleInputChange={(e) => handleFormChange(e, 'formTwoData')} remainingCharacters={remainingCharacters} /> : 
                    showdata == 'yesIntern' ? 
                    <YesIntern formData={formData.formThreeData} handleInputChange={(e) => handleFormChange(e, 'formThreeData')} remainingCharacters={remainingCharacters} /> : 
                    showdata == 'noIntern' ? 
                    <NoIntern formData={formData.formFourData} handleInputChange={(e) => handleFormChange(e, 'formFourData')} remainingCharacters={remainingCharacters} /> : 
                    ''}

					<div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
						<Button variant="danger" onClick={closeEmploymentModal} className='rounded-pill px-4'>Cancel</Button>
						<Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
					</div>
				</Form>               
			</Modal.Body>
		</Modal>
	)
}
