import React, { useEffect } from "react";
import { useState } from "react";

// import preloader css
import "../../LeadsListingPage/LeadsListingPage.css";
import { callAxios } from "../../utils/utils";
import { environment } from "../../environment";

const SheelaModal = ({ sheelaData, setSheelaView, isSheelaView }) => {
  //  console.log(sheelaData['abstractSummary'])
  const [analysisData, setAnalysisData] = useState({});
  const [actionData, setActionData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const closePreview = () => {
    document.getElementById("SheelaModal").style.display = "none";
    setActionData({})
    setSheelaView(false)
  }
  useEffect(() => {
    // console.log("first time calling useeffect")
  }, [])

  const getAnalysisData = async (action, apiData) => {
    // console.log("in  get data.......",action,apiData['abstractSummary']);

    if (action === "Summary") {
      setActionData(apiData['abstractSummary'])
      // setSummary(apiData['abstractSummary'])
    }

    if (action === "Key Points Of Meeting") {
      setActionData(apiData.keyPoints.Summary ? apiData.keyPoints.Summary : apiData.keyPoints)
      // console.log(apiData.keyPoints.Summary?apiData.keyPoints.Summary:apiData.keyPoints)
    }

    if (action === "Our Actions") {
      setActionData(apiData.action_items)
    }

    if (action === "Meeting Sentiments") {
      setActionData(apiData.sentiment)
    }

    if (action === "Deep Level Meeting Analysis") {
      setActionData(apiData.analysis)
    }

    if (action === "Me As Detective") {
      //console.log("detectinve DAta:::::::::",apiData.detective)
      setActionData(apiData.detective)
    }

    if (action === "All Client Queries In Meeting") {
      setActionData(apiData.quries)
    }
  }

  if (sheelaData && Object.entries(analysisData).length > 0) {
    setAnalysisData(sheelaData)
    //  setActionData(sheelaData['abstractSummary'])
    getAnalysisData("Summary", sheelaData)
    setIsLoading(false)
  }

  // console.log(isLoading)
  // useEffect(()=>{
  //   if(callSid){
  //     callAxios(environment.REACT_APP_API_URL_Live +'api/v1/conversation/call-sheela-pstn',{roomname:callSid, "role":"admin"},"post").then((response)=>{
  //         // console.log(response.data)
  //           setAnalysisData(response.data)
  //         setActionData(response.data['abstractSummary'])
  //          setIsLoading(false)
  //     })
  //   }
  // },[callSid])

  //console.log(analysisData, actionData)

  const renderPoints = (analysisData) => {
    return (
      <ul>
        {Object.entries(analysisData).map(([key, value]) => (
          <li key={key}>
            <strong className="text-capitalize">{key.replace(/_/g, ' ')}</strong>: {typeof value === 'object' ? renderPoints(value) : value}
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    if (sheelaData && Object.entries(analysisData).length > 0) {
      getAnalysisData("Summary", sheelaData)
    }
  }, [])

  return (
    <>
      <div className="modal fade meeting-anaylisis-pop" id="SheelaModal" tabIndex={-1} data-bs-backdrop="static" aria-labelledby="exampleModalLabel-meeting" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content text-body" >
            <div className="modal-header bg-primary bg-gradient text-white">
              <h6 className="modal-title">Analysis</h6>
              <button type="button" className="btn btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"
                onClick={closePreview}
              ></button>
            </div>
            <div className="modal-body small ps-0 pb-0" style={{ backgroundColor: '#f5f5f5' }}>
              <div className="row gx-3">
                <div className="col-4 col-lg-2">
                  <div className="img-wrapper d-flex align-items-end h-100">
                    <img src={require('../../resources/images/meeting-girl3.png')} className="d-block img-fluid" alt="Meeting User" style={{ maxHeight: '300px', transform: 'scaleX(-1)' }} />
                  </div>
                </div>
                <div className="col-8 col-lg-10 ">
                  <div className="tabs-wrapper pb-3">
                    <nav className="bg-white">
                      <div className="nav nav-tabs fw-medium flex-nowrap" id="nav-tab" role="tablist">
                        <button className="nav-link active" id="summaryTab" data-bs-toggle="tab" data-bs-target="#summary" type="button" role="tab" aria-controls="summary" aria-selected="true" onClick={(e) => { getAnalysisData("Summary", sheelaData) }}>Summary</button>
                        <button className="nav-link text-opacity-75" id="meetingTab" data-bs-toggle="tab" data-bs-target="#pointMeeting" type="button" role="tab" aria-controls="pointMeeting" aria-selected="false" onClick={(e) => { getAnalysisData("Key Points Of Meeting", sheelaData) }}>Key Points Of Meeting</button>
                        <button className="nav-link text-opacity-75" id="actionTab" data-bs-toggle="tab" data-bs-target="#ourAction" type="button" role="tab" aria-controls="ourAction" aria-selected="false" onClick={(e) => { getAnalysisData("Our Actions", sheelaData) }}>Our Actions</button>
                        <button className="nav-link text-opacity-75" id="meetSentimentsTab" data-bs-toggle="tab" data-bs-target="#meetingSentiments" type="button" role="tab" aria-controls="meetingSentiments" aria-selected="false" onClick={(e) => { getAnalysisData("Meeting Sentiments", sheelaData) }}>Meeting Sentiments</button>
                        <button className="nav-link text-opacity-75" id="deepLevelMeetingTab" data-bs-toggle="tab" data-bs-target="#deepLevelMeeting" type="button" role="tab" aria-controls="deepLevelMeeting" aria-selected="false" onClick={(e) => { getAnalysisData("Deep Level Meeting Analysis", sheelaData) }}>Deep Level Meeting Analysis</button>
                        <button className="nav-link text-opacity-75" id="meAsDetectiveTab" data-bs-toggle="tab" data-bs-target="#meAsDetective" type="button" role="tab" aria-controls="meAsDetective" aria-selected="false" onClick={(e) => { getAnalysisData("Me As Detective", sheelaData) }}>Me As Detective</button>
                        <button className="nav-link text-opacity-75" id="clientQueriesTab" data-bs-toggle="tab" data-bs-target="#clientQueries" type="button" role="tab" aria-controls="clientQueries" aria-selected="false" onClick={(e) => { getAnalysisData("All Client Queries In Meeting", sheelaData) }}>All Client Queries In Meeting</button>
                      </div>
                    </nav>
                    <div className="tab-content border border-top-0 overflow-auto p-3" id="nav-tabContent" style={{ maxHeight: '50vh' }}>
                      <div className="tab-pane fade show active" id="summary" role="tabpanel" aria-labelledby="summaryTab" tabIndex="0">

                        {/* {sheelaData && sheelaData['abstractSummary']?sheelaData['abstractSummary'].summary:renderPoints(actionData)} */}
                        {sheelaData && sheelaData['abstractSummary'] ? renderPoints(sheelaData['abstractSummary']) : null}
                      </div>
                      <div className="tab-pane fade" id="pointMeeting" role="tabpanel" aria-labelledby="meetingTab" tabIndex="0">
                        {renderPoints(actionData)}
                      </div>
                      <div className="tab-pane fade" id="ourAction" role="tabpanel" aria-labelledby="actionTab" tabIndex="0">
                        {renderPoints(actionData)}
                      </div>
                      <div className="tab-pane fade" id="meetingSentiments" role="tabpanel" aria-labelledby="meetSentimentsTab" tabIndex="0">
                        {renderPoints(actionData)}
                      </div>
                      <div className="tab-pane fade" id="deepLevelMeeting" role="tabpanel" aria-labelledby="deepLevelMeetingTab" tabIndex="0">
                        {renderPoints(actionData)}
                      </div>
                      <div className="tab-pane fade" id="meAsDetective" role="tabpanel" aria-labelledby="meAsDetectiveTab" tabIndex="0">
                        {renderPoints(actionData)}
                      </div>
                      <div className="tab-pane fade" id="clientQueries" role="tabpanel" aria-labelledby="clientQueriesTab" tabIndex="0">
                        {renderPoints(actionData)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="border p-3 rounded mt-3">
                {renderPoints(actionData)}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SheelaModal;

