import React, { useEffect, useState } from "react";
import ChatSection from "./ChatSection/ChatSection";
import ParticipantsSection from "./ParticipantsSection/ParticipantsSection";
import "./RoomPage.css";
import VideoSection from "./VideoSection/VideoSection";
import { connect } from "react-redux";
import {
  setTwilioAccessToken,
  setConnectOnlyWithAudio,
  setIdentity,
  setRoomId,
} from "../store/actions";
import { getTokenFromTwilio } from "../utils/twilioUtils";
import { useNavigate, useLocation } from "react-router-dom";
import Overlay from "./Overlay";
import BackgroundSetting from "./BgSettingSection/BackgroundSetting";
import RejoinRoom from "./RejoinRoom";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";

function RoomPage(props) {
  const {
    identity,
    roomId,
    setTwilioAccessTokenAction,
    showOverlay,
    isRoomHost,
    setConnectOnlyWithAudioAction,
    connectOnlyWithAudio,
    setRoomIdAction,
    setIdentityAction,
  } = props;
  let navigate = useNavigate();

  const search = useLocation().search;
  const name = new URLSearchParams(search).get("nm");
  const joinerRoomId = new URLSearchParams(search).get("id");
  const sharedBy = new URLSearchParams(search).get("sharedBy");
  const isRejoin = new URLSearchParams(search).get("isRejoin");
  const IsRejoin = isRejoin === "true";
  //console.log(IsRejoin, props.room)
  const [comingFrom, setComingFrom] = useState();
  useEffect(() => {
    setIdentityAction(atob(name));
    setRoomIdAction(atob(joinerRoomId));
    // if (sharedBy === "client") {
    //   setComingFrom(false);
    //   getTokenFromTwilio(
    //     setTwilioAccessTokenAction,
    //     "_Guest By CL_" + atob(name)
    //   );
    // } else {
      //console.log("In clinent Side for start recording...")
      // callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/video-media/stop-recording', { RoomSid: room.sid }, "post");
      setComingFrom(false);
      getTokenFromTwilio(setTwilioAccessTokenAction, "_CL_" + atob(name));
    //}

    // if(!identity || !roomId){
    //   navigate('/')
    // }else {
    //   getTokenFromTwilio(setTwilioAccessTokenAction, identity);
    // }
  }, []);

  return (
    <>
      <VideoSection
        comingFrom={comingFrom}
        isCam={false}
        camera={true}
        isMicMuted={false}
        isRejoin={IsRejoin}
        twilioAccessToken={props.twilioAccessToken}
        roomId={props.roomId}
      />

      {/* Main Video Section */}
      {/* <div id="main" className='room_container main-section p-lg-3 overflow-hidden'>
        <div id='videos_portal' className='when-normal d-flex flex-wrap h-100 overflow-auto position-relative'>
        </div>
      </div> */}

      {/* Main Video Section (When Shared Screen) */}
      {/* <div id="main" className='d-lg-flex room_container main-section p-lg-3 overflow-hidden gap-lg-3'>
        <div id='shared_screen' className='shared_screen_preview d-flex flex-wrap w-100 h-100 overflow-hidden position-relative border border-1 border-info'>
          <img src={require('../resources/images/Introduction-Page.png')} className="object-fit-contain img-fluid" alt="Shared Screen Preview" />
        </div>
        <div id='videos_portal' className='when-shared d-flex flex-wrap align-content-start w-100 h-100 overflow-auto position-relative flex-shrink-1 p-lg-3'>
        </div>
      </div> */}

      <div
        id="main"
        className="room_container main-section p-lg-3 overflow-hidden"
      >
        <div
          id="shared_screen"
          className="shared_screen_preview d-flex flex-wrap w-100 h-100 overflow-hidden position-relative border border-1 border-info hide"
        ></div>
        <div
          id="videos_portal"
          className="when-normal d-flex flex-wrap h-100 overflow-auto position-relative"
        ></div>
      </div>

      {/* Chat Sidenav */}
      <div id="chatSidenav" className="sidebar border-start border-black">
        <ChatSection />
        {showOverlay && <Overlay />}
      </div>

      {/* Participants Sidenav */}
      <div id="partcipantSidenav" className="sidebar border-start border-black">
        <ParticipantsSection />
      </div>

      {/* Background Setting Sidenav */}
      <div id="BgSettingSidenav" className="sidebar border-start border-black">
        <BackgroundSetting comingFrom={false} />
      </div>

      {/* Rejoin Modal */}

      <RejoinRoom
        roomId={roomId}
        identity={identity}
        twilioAccessToken={props.twilioAccessToken}
        showOverlay={showOverlay}
        camera={true}
        isMicMuted={false}
      />
    </>
  );
}

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setTwilioAccessTokenAction: (token) =>
      dispatch(setTwilioAccessToken(token)),
    setConnectOnlyWithAudioAction: (onlyWithAudio) =>
      dispatch(setConnectOnlyWithAudio(onlyWithAudio)),
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (id) => dispatch(setRoomId(id)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(RoomPage);
