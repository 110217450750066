import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const LeadSourceChart = (props) =>{
    am4core.useTheme(am4themes_animated);

    // Create chart instance
let chart = am4core.create("lead-source-chart", am4charts.XYChart3D);
chart.paddingBottom = 30;
chart.angle = 35;

// Add data
chart.data = props.leadSourceChart;
// Create axes
let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "name";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.minGridDistance = 20;
categoryAxis.renderer.inside = true;
categoryAxis.renderer.grid.template.disabled = true;

let labelTemplate = categoryAxis.renderer.labels.template;
labelTemplate.rotation = -90;
labelTemplate.horizontalCenter = "left";
labelTemplate.verticalCenter = "middle";
labelTemplate.dy = 10; // moves it a bit down;
labelTemplate.inside = false; // this is done to avoid settings which are not suitable when label is rotated

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.renderer.grid.template.disabled = true;

// Create series
let series = chart.series.push(new am4charts.ConeSeries());
series.dataFields.valueY = "total";
series.dataFields.categoryX = "name";
series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/b]";

let columnTemplate = series.columns.template;
columnTemplate.adapter.add("fill", function(fill, target) {
  return chart.colors.getIndex(target.dataItem.index);
})

columnTemplate.adapter.add("stroke", function(stroke, target) {
  return chart.colors.getIndex(target.dataItem.index);
})


    return <div id="lead-source-chart" style={{ width: "100%", height: "270px", margin: "0 auto" }} />;
  

}

export default LeadSourceChart;