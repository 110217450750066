import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import { callAxios, getProfileData } from '../../utils/utils';
import { environment } from '../../environment';
import { toast } from 'react-toastify';

export const VerifyOTP = ({ verifyotpmodal, closeverifyModal, index,profileData, setIsLoading, setProfileData, setShowBasicDetailsModal, eventType, mobileNumber,setVerifyOTPModal, setEditMobile, setEditEmail,email, }) => {
    console.log(mobileNumber,eventType)
    const [otp, setOTP] = useState(['', '', '', '']);
    const [isValid, setIsValid] = useState(false);
    const [timer, setTimer] = useState(30);
    const [isResending, setIsResending] = useState(false);
  
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);

    useEffect(() => {
        if (otp[0] !== '') input2Ref.current.focus();
    }, [otp[0]]);

    useEffect(() => {
        if (otp[1] !== '') input3Ref.current.focus();
    }, [otp[1]]);

    useEffect(() => {
        if (otp[2] !== '') input4Ref.current.focus();
    }, [otp[2]]);

    useEffect(() => {
        if (otp[3] !== '') input1Ref.current.blur();
    }, [otp[3]]);

    // useEffect(() => {
    //     // Start the timer when component mounts
    //     const interval = setInterval(() => {
    //         if (timer > 0 && !isValid) {
    //             setTimer((prevTimer) => prevTimer - 1);
    //         }
    //     }, 1000);

    //     // Clear the interval when component unmounts
    //     return () => clearInterval(interval);
    // }, [timer, isValid]);

    const generateOTP = () => {
        const digits = '0123456789';
        let otp = '';
        for (let i = 0; i < 4; i++) {
            otp += digits[Math.floor(Math.random() * 10)];
        }
        return otp;
    };

    const handleInputChange = (index, e) => {
        const { value } = e.target;
        // Validate input to allow only single digits
        if (/^\d{0,1}$/.test(value)) {
            const newOTP = [...otp];
            newOTP[index] = value;
            setOTP(newOTP);
        }
    };

    const verifyOTP = (e) => {
        e.preventDefault()
        setIsLoading(true)
        setShowBasicDetailsModal(false)
        const otpNumber = parseInt(otp.join(''));

        console.log(otpNumber)
        if(eventType === "verifyEmail" || eventType === "changeEmail"){
            callAxios(environment.REACT_APP_API_URL_Live+"api/v1/profile/verify-email-otp",{otp:otpNumber, profileId:profileData.id,email:email,eventType:eventType},"post").then(async(response)=>{
                if(response.data.statusCode === 200){
                    setIsValid(true);
                    setShowBasicDetailsModal(false)
                    setEditEmail(false)
                     setVerifyOTPModal(false);
                    setProfileData(await getProfileData(profileData.id,setIsLoading))
                    toast.success("Email verified!", {
                        position: 'bottom-center'
                    })
                } else{
                    // setIsLoading(false)
                    //  setShowBasicDetailsModal(true)
                    //  setEditMobile(true)
                    //  setVerifyOTPModal(true);
    
                    setProfileData(await getProfileData(profileData.id,setIsLoading))
                   
                    toast.error("wrong otp attempted", {
                        position: 'bottom-center'
                    })
                    setIsValid(false);
                }
            })
        } else{
            callAxios(environment.REACT_APP_API_URL_Live+"api/v1/profile/verify-otp",{otp:otpNumber, profileId:profileData.id,mobileNumber:mobileNumber,eventType:eventType},"post").then(async(response)=>{
                if(response.data.statusCode === 200){
                    setIsValid(true);
                    setShowBasicDetailsModal(false)
                     setEditMobile(false)
                     setVerifyOTPModal(false);
                    setProfileData(await getProfileData(profileData.id,setIsLoading))
                    toast.success("Mobile number verified!", {
                        position: 'bottom-center'
                    })
                } else{
                    // setIsLoading(false)
                    //  setShowBasicDetailsModal(true)
                    //  setEditMobile(true)
                    //  setVerifyOTPModal(true);
    
                    setProfileData(await getProfileData(profileData.id,setIsLoading))
                   
                    toast.error("wrong otp attempted", {
                        position: 'bottom-center'
                    })
                    setIsValid(false);
                }
            })
        }
        
        
        closeverifyModal()
    };

    const handleResendOTP = () => {
        const getOtp = generateOTP()
        setTimer(30); // Reset timer
        setIsResending(true);

        // Simulate OTP sending
        setTimeout(() => {
            setIsResending(false);
        }, 2000);
    };

    const gotoProfileBioPage = () => {
        navigate("/profile-bio");
    };

    let navigate = useNavigate();

    return (
        <Modal show={verifyotpmodal} onHide={closeverifyModal} centered backdrop="static" backdropClassName="child-modal-backdrop" keyboard={false} dialogClassName='profileCustomModal editMobileNumModal small text-dark mx-auto' contentClassName='rounded-4'>
            <div className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-md-4 py-4 p-lg-5 pt-lg-4'>
                <div className='heading-wrapper mb-3'>
                    <div className='text-white fs-5 fw-medium mb-3'>OTP Verification</div>
                    <div className='text-light text-opacity-75'>Naukri just sent a text message with verification code to <span className='text-light fw-medium'>{mobileNumber}</span><span className='ms-3'></span></div>
                </div>

                <Form action="POST" className="profileCustomModal form-cover" onSubmit={verifyOTP}>
                    <div className="otpWrapper d-flex gap-2">
                        {otp.map((digit, index) => (
                            <Form.Control
                                key={index}
                                ref={index === 0 ? input1Ref : index === 1 ? input2Ref : index === 2 ? input3Ref : input4Ref}
                                type="text"
                                value={digit}
                                onChange={(e) => handleInputChange(index, e)}
                                maxLength={1}
                                autoComplete='off'
                                className="fs-5 border rounded-3 text-center"
                                style={{ width: '50px' }}
                                required
                            />
                        ))}
                    </div>
                    {/* {isValid ? (
                        <div className='text-success mt-2 mb-3'>OTP Verified Successfully!</div>
                    ) : (
                        <div className='text-danger mt-2 mb-3'>Please enter correct OTP</div>
                    )} */}

                    <div className="mb-3">
                        {timer > 0 && !isValid && (
                            <div className='text-clr1'>Your OTP should arrive in {timer} seconds</div>
                        )}

                        {timer === 0 || isValid ? (
                            <div className="d-flex align-items-center gap-2 text-clr1">
                                Didn’t recieve an OTP?
                                <Button type='submit' variant='link' className='fs-14 text-decoration-none p-0' onClick={handleResendOTP} disabled={isResending}>
                                    {isResending ? 'Resending...' : 'Resend OTP'}
                                </Button>
                            </div>
                        ) : null}
                    </div>

                    <div className="btn-wrapper">
                        <Button type='submit' variant='primary' className='rounded-pill px-4'>Verify</Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default VerifyOTP;
