import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAxios, convertReadable, convertReadableTime, convertSecondsToMinutes } from "../utils/utils";
import { getCountryCallingCode } from "react-phone-number-input";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addMoreParticipant } from "../utils/pstn";
import { environment } from "../environment";
import $ from "jquery";



const Card = ({ item, id, user, active }) => {
  // console.log(id, item.leadid)

  const navigate = useNavigate();
  const [handleShow_, setHandleShow] = useState(false)

  const regionNames = new Intl.DisplayNames(
    ['en'], { type: 'region' }
  );



  useEffect(() => {
    if (id === 0) {
      // getCallDetails(id, item.leadid, item.topic)
    }
  }, [])

  const fireBaseTime = new Date(
    item.timeStamp.seconds * 1000 + item.timeStamp.nanoseconds / 1000000
  );
  const dateNew = fireBaseTime.toDateString();
  const atTime = fireBaseTime.toLocaleTimeString();
  let timeStampfor = dateNew + " " + atTime;

  return (

    <>
      <div className={`client-card rounded p-3 mt-2 pointer ${id === active ? "selected" : ""}`} key={id} >
        {/* <ToastContainer /> */}
        <div className="d-flex justify-content-between gap-2 mb-2">
          <div className="left-cover">
            {item.name ?
            <div className="">
            <div className="client-name fs-5 text-warning text-capitalize">{item.name}</div>
              <div className="description small text-info">{item.topic}</div>
            </div>
              
              : null}
             
            {timeStampfor ?
              <div className="fs-6 text-white text-opacity-75 text-capitalize"> <span>{timeStampfor}</span></div>
              : null}
          </div>
          
        </div>
        {item.description ?
          <div className="requrement-wrapper pe-4">

            <div className="client-location fs-6 text-light mb-1">Requirement Details</div>
            <div className="description small text-light text-opacity-75 ">{item.description}</div>

          </div> : null}
       
        
      </div>

    
    </>




  )
}

export default Card;