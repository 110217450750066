import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import makeAnimated from 'react-select/animated';
import WarningModal from '../Components/Warningmodal';

import CreatableSelect from "react-select/creatable";
import Skills from "../../Recruitment/Skills";

const SkillsVal = Skills.map((skill, index) => ({
    value: skill,
    label: skill,
  }));

const animatedComponents = makeAnimated();
export const EditOptionYes = ({ closeEditEmploymentModal, sendmydata, getskills, updateEmp, isCurrentEmployement, profileId, profileData, employmentData, setRemoveId,setRemainingCharacters, remainingCharacters }) => {
    // console.log(employmentData)
    // const getUserId = process.env.REACT_APP_USER_ID
    const [inputValue, setInputValue] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [textmessage, setTextmessage] = useState('');
    const [deleteEmp, setDeleteEmp] = useState('')

    

    const [filterSkills, setFilterSkills] = useState([]);

    const [selectedSkills, setSelectedSkills] = useState([]);

    const options = [
        { value: 'HTML5', label: 'HTML5', id: 1 },
        { value: 'CSS3', label: 'CSS3', id: 2 },
        { value: 'JAVASCRIPT', label: 'JAVASCRIPT', id: 3 },
        { value: 'JQUERY', label: 'JQUERY', id: 4 },
        { value: 'BOOTSTRAP', label: 'BOOTSTRAP', id: 5 },
        { value: 'REACT', label: 'REACT', id: 6 }

    ]

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          borderColor: "var(--bs-border-color) !important",
          color: "#000",
          borderRadius: "1rem",
          backgroundColor: "#fff",
          padding: "5px",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "var(--theme-color4)", // Change the color of the placeholder text
          fontSize: "14px",
          fontWeight: "400",
          padding: "0",
        }),
      };
      const handleSkillChange = async (selectedOptions) => {
        // console.log(profileData?.key_skills, selectedOptions);
        const exists = profileData?.key_skills.some((item) => {
          console.log(item.key_skills);
        });
        // console.log(exists);
        setSelectedSkills(selectedOptions);
        getskills(selectedSkills)
      };
    // const handleSkillChange = (selectedOption) => {
    //     // const selectedSkillsObj = selectedOptions.value 
    //     setInputValue(selectedOption);
    //     const updatedValue = [...inputValue];
    //     // Push the newly selected value to the updated array with the specific key
    //     updatedValue.push({ skill: selectedOption.value, id:  selectedOption.id});
    //     // Update the state with the updated array
    //     setInputValue(updatedValue);
    //     getskills(updatedValue)

    //     const modifiedData = updatedValue.map(item => {
    //         const { id, ...rest } = item; // Destructure the object, leaving out the 'id' field
    //         return rest; // Return the object without the 'id' field
    //     });
        
    //     // console.log(modifiedData);
    //     // console.log(updatedValue);
    //     // handleSubmit(modifiedData)
    // }
    useEffect(() => {
        const savedKeySkills = new Set(
            employmentData?.employment_skills &&
            employmentData.employment_skills.map((item) => item.skill)
        );
    
        const filteredMain = SkillsVal.filter(
          (item) => !savedKeySkills.has(item.value)
        );
    
        setFilterSkills(filteredMain);
        if(sendmydata?.job_profile){
            const remaining = 4000 - sendmydata?.job_profile.length;
        setRemainingCharacters(remaining);
        }
        
        
      }, [employmentData?.employment_skills,sendmydata?.job_profile]);
      console.log(selectedSkills)
    const removeSkill = (id) => {
              
        document.getElementById("delete-Emp-skill-"+id).style.display = "none"
    setRemoveId(prevRemoveId => [...prevRemoveId, id]);
        // delete-Emp-skill-"+item.id
        
        // const updatedList = inputValue.filter(item => item.id !== id);
        // setInputValue(updatedList);
        // getskills(updatedList)
       
    }
   
    // convert month and year
    const convertmonandyear = (data, type) => {
        const value = data;
        const getmonyrs = value.split('-');
        const yearsValue = getmonyrs[0];
        const monthsValue = getmonyrs[1];
    
        if(type == 'year'){
            return yearsValue
        }
        if(type == 'month'){
            return monthsValue
        }
        // setYears(yearsValue);
        // setMonths(monthsValue);
    };

    // convert year and monthname
    const yearmonthname = (data, type) => {
        const dateString = data;
  
        // Create a Date object with the input string
        const date = new Date(dateString);
      
        // Get the year
        if(type == 'year'){
            const year = date.getFullYear();
            return year
        }
        
        // Get the month name
        if(type == 'month'){
            const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
            return month
        }
    }

    const formatDatedata = (data, type) => {
        const dateString = data;
  
        // Split the input string by '-'
        const parts = dateString.split('-');
        
        // Extract year and month
        const year = parts[0];
        const monthName = parts[1];
        
        // Map month names to numbers
        const monthsMap = {
          "January": "01",
          "February": "02",
          "March": "03",
          "April": "04",
          "May": "05",
          "June": "06",
          "July": "07",
          "August": "08",
          "September": "09",
          "October": "10",
          "November": "11",
          "December": "12"
        };
      
        // Get the month number from the map
        const month = monthsMap[monthName];
      
        // Formatted output string
        const formattedDate = `${year}-${month}`;
        return formattedDate
    }

    // convert year and monthname
    const workingfromfnc = (data) => {
        const dateStr = data;
        const date = new Date(dateStr);
        const year = date.getFullYear(); // Get the year
        const month = date.getMonth() + 1; // Get the month (Note: January is 0, so we add 1)
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}`; // Pad the month with leading zero if necessary
        return formattedDate
    }

    // Edit Data
    const [formData, setFormData] = useState({
        years: sendmydata?.total_experience ? convertmonandyear(sendmydata?.total_experience, 'year') : '',
        months: sendmydata?.total_experience ? convertmonandyear(sendmydata?.total_experience, 'month') : '',
        company_name: sendmydata?.company_name ? sendmydata?.company_name : '',
        job_title: sendmydata?.job_title ? sendmydata?.job_title : '',
        joining_date:sendmydata?.joining_date ? workingfromfnc(sendmydata?.joining_date) : '',
        salary_type:sendmydata?.salary_type ? sendmydata?.salary_type : '',
        salary:sendmydata?.salary ? sendmydata?.salary : '',
        inputValue: '',
        job_profile:sendmydata?.job_profile ? sendmydata?.job_profile : '',
        notice_period:sendmydata?.notice_period ? sendmydata?.notice_period : '',
    });

  
      


    
    const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name,value)
    if(name === "job_profile"){
        const remaining = 4000 - value.length;
        setRemainingCharacters(remaining);
    }
    setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async(e) => {
        e.preventDefault();

        const skillSet = selectedSkills.map((item)=>{
            return {
                skill:item.value
            }
        })
        // Add your form submission logic here
        const postData = {
            "profileId":profileId ? profileId : '',
            "employementId":sendmydata?.id, 
            "type":"update",
            "isCurrentEmployement": isCurrentEmployement,
            "totalExpYears":formData?.years, 
            "totalExpMonths":formData?.months,
            "companyName":formData?.company_name,
            "jobTitle":formData?.job_title,
            "joiningDateYear":yearmonthname(formData?.joining_date, 'year'), 
            "joiningDateMonth":yearmonthname(formData?.joining_date, 'month'), 
            "salary":formData?.salary, 
            "salaryType":formData?.salary_type, 
            "jobProfile":formData?.job_profile, 
            "noticePeriod":formData?.notice_period,
            "Skills":skillSet,
        }
        updateEmp(postData)
    };
    // Edit Data

    // deleteemployment
    const deleteemployment = () => {
        setShowModal(true)
        setTextmessage('Are you sure you want to delete this employment?')
        const sendData = {
            "profileId":profileId ? profileId : '',
            "employementId":sendmydata?.id, 
            "type":"delete"
        }
        setDeleteEmp(sendData)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }
    // deleteemployment

    const employmentSkills =   sendmydata?.employment_skills?.map((item) => {
        // value={formData.addSkill}
        return (
        <button key={item.id} type='button' id={"delete-Emp-skill-"+item.id}  name="addSkill"  onChange={handleInputChange} className='btn btn-sm btn-outline-secondary rounded-pill py-1 px-3'>{item.skill} <span onClick={() => removeSkill(item.id)}><i class="bi bi-x fs-18"></i></span></button>
        )
})
const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1 and pad with leading zero

// Create the max value for the input
const maxMonth = `${currentYear}-${currentMonth}`;
    return(
       <>
        {/* <span className='delete-btn' onClick={() => deleteemployment(true)}><i class="bi bi-trash"></i></span>
        <WarningModal show={showModal} onClose={handleCloseModal} textmessage={textmessage} updateEmp={updateEmp} deleteEmp={deleteEmp} /> */}
        <Form action="" className="form-cover" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Label>Total experience<span className='text-danger'>*</span></Form.Label>
                <div className="row g-2">
                    <div className="col-6">
                        <Form.Select name="years" value={formData.years} onChange={handleInputChange} required>
                            <option selected disabled value=''>Years</option>
                            <option value="0 Year">0 Year</option>
                                        <option value="1 Year">1 Year</option>
                                        <option value="2 Years">2 Years</option>
                                        <option value="3 Years">3 Years</option>
                                        <option value="4 Years">4 Years</option>
                                        <option value="5 Years">5 Years</option>
                                        <option value="6 Years">6 Years</option>
                                        <option value="7 Years">7 Years</option>
                                        <option value="8 Years">8 Years</option>
                                        <option value="9 Years">9 Years</option>
                                        <option value="10 Years">10 Years</option>
                                        <option value="11 Years">11 Years</option>
                                        <option value="12 Years">12 Years</option>
                                        <option value="13 Years">13 Years</option>
                                        <option value="14 Years">14 Years</option>
                                        <option value="15 Years">15 Years</option>
                                        <option value="16 Years">16 Years</option>
                                        <option value="17 Years">17 Years</option>
                                        <option value="18 Years">18 Years</option>
                                        <option value="19 Years">19 Years</option>
                                        <option value="20 Years">20 Years</option>
                                        <option value="21 Years">21 Years</option>
                                        <option value="22 Years">22 Years</option>
                                        <option value="23 Years">23 Years</option>
                                        <option value="24 Years">24 Years</option>
                                        <option value="25 Years">25 Years</option>
                                        <option value="26 Years">26 Years</option>
                                        <option value="27 Years">27 Years</option>
                                        <option value="28 Years">28 Years</option>
                                        <option value="29 Years">29 Years</option>
                                        <option value="30 Years">30 Years</option>
                                        <option value="30+ Years">30+ Years</option>
                        </Form.Select>
                    </div>
                    <div className="col-6">
                        <Form.Select name="months" value={formData.months} onChange={handleInputChange} required>
                            <option selected disabled value=''>Months</option>
                            <option value="0 Month">0 Month</option>
                                        <option value="1 Month">1 Month</option>
                                        <option value="2 Months">2 Months</option>
                                        <option value="3 Months">3 Months</option>
                                        <option value="4 Months">4 Months</option>
                                        <option value="5 Months">5 Months</option>
                                        <option value="6 Months">6 Months</option>
                                        <option value="7 Months">7 Months</option>
                                        <option value="8 Months">8 Months</option>
                                        <option value="9 Months">9 Months</option>
                                        <option value="10 Months">10 Months</option>
                                        <option value="11 Months">11 Months</option>
                        </Form.Select>
                    </div>
                </div>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Current company name<span className='text-danger'>*</span></Form.Label>
                <Form.Control type="text" name="company_name" value={formData.company_name} onChange={handleInputChange} placeholder="Type your organization" autoComplete='off' required />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Current job title<span className='text-danger'>*</span></Form.Label>
                <Form.Control type="text" name="job_title" value={formData.job_title} onChange={handleInputChange} placeholder="Type your designation" autoComplete='off' required />
            </Form.Group>
            <Form.Group>
                <div className="row g-2">
                    <div className="col-sm-6">
                        <Form.Group className="mb-3">
                            <Form.Label>Joining date<span className='text-danger'>*</span></Form.Label>
                            <Form.Control max={maxMonth} type="month" name="joining_date" value={formData.joining_date} onChange={handleInputChange} autoComplete='off' required />
                        </Form.Group>
                    </div>
                    <div className="col-sm-6">
                        <Form.Group className="mb-3">
                            <Form.Label>Current salary<span className='text-danger'>*</span></Form.Label>
                            <InputGroup>
                                <Form.Select className='shadow-none' name="salary_type" value={formData.salary_type} onChange={handleInputChange}  style={{ maxWidth: '65px' }}>
                                    <option value="INR">₹</option>
                                    <option value="USD">$</option>
                                </Form.Select>
                                <Form.Control type="text" name="salary" value={formData.salary} onChange={handleInputChange} placeholder="Eg. 4,50,000" autoComplete='off' required />
                            </InputGroup>
                        </Form.Group>
                    </div>
                </div>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Skills used<span className='text-danger'>*</span></Form.Label>
                <div className="skills-wrapper mb-3">
                    <div className="d-flex flex-wrap gap-2">
                        <Form.Control type="text" name="inputValue" value={formData.inputValue} onChange={handleInputChange} autoComplete='off' hidden />
                        {employmentSkills}
                    </div>
                </div>
                {/* <Select 
                    value={inputValue}
                    onChange={handleSkillChange}
                    options={options}
                /> */}
                <CreatableSelect
              isMulti
              className="custom-select"
              classNamePrefix="custom-select"   
              closeMenuOnSelect={false}
              name="skills"
              options={filterSkills}
              styles={customStyles}
              onChange={(value) => {
                handleSkillChange(value);
                // setErrors((prevErrors) => ({
                //     ...prevErrors,
                //     skills: '',
                // }));
              }}
              placeholder="Add your skills"
            />
                {/* <Form.Control type="text" name="addSkill" value={formData.addSkill} onChange={handleInputChange} placeholder="Add skills" autoComplete='off' required /> */}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Job profile<span className='text-danger'>*</span></Form.Label>
                <Form.Control as="textarea" rows={3} placeholder='Type here...' autoComplete='off' name="job_profile" value={formData.job_profile} onChange={handleInputChange} required />
                <div className="charLenght fs-14 text-secondary text-end mt-2">{remainingCharacters} character(s) left</div>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Notice period<span className='text-danger'>*</span></Form.Label>
                <Form.Select name="notice_period" value={formData.notice_period} onChange={handleInputChange} required>
                    <option selected disabled value=''>Months</option>
                    <option value="15 Days or less">15 Days or less</option>
                    <option value="1 Month">1 Month</option>
                    <option value="2 Months">2 Months</option>
                    <option value="3 Months">3 Months</option>
                    <option value="More than 3 Months">More than 3 Months</option>
                    <option value="Serving Notice Period">Serving Notice Period</option>
                </Form.Select>
            </Form.Group>

            <div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
                <Button variant="danger" onClick={closeEditEmploymentModal} className='rounded-pill px-4'>Cancel</Button>
                <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
            </div>
        </Form>
       </> 
    )
}