import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { connectToRoom, connectToRoomAsAdmin } from "../../utils/twilioUtils";
import RoomLabel from "./RoomLabel";
import TwilioRoom from "./TwilioRoom/TwilioRoom";
import { collection, doc, documentId, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { database } from "../../firebaseConfig";
import { environment } from "../../environment";

const VideosPrivate = ({
  room,
  setRoom,
  RMID,
  twilioAccessToken,
  isAdmin,
  formId,
  isCam,
  comingFrom,
  camera,
  isMicMuted,
  userInfo,
  isTryingToJoining
}) => {
  
    var startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
  
    var endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
  
  
    const queryConstraints = [];
    // queryConstraints.push(where("roomId", "==", RMID));
    queryConstraints.push(where("timeStamp", ">=", startOfToday));
    queryConstraints.push(where("timeStamp", "<=", endOfToday));
    const q = query(
      collection(database, environment.REACT_APP_FIREBASE_DB_CALLS),
      ...queryConstraints
    );
 //console.log("-------------->",RMID)
   const checkAudioVideoCall  = () =>{
     onSnapshot(q, (querySnapshot) => {
     
      // if(user){
         querySnapshot.forEach(async(docu) => {
           const id = docu.id;
           const data = docu.data();
           data["id"] = docu.id;
           //console.log(docu.data())
           const loggedInUser = userInfo?.email? userInfo?.email : localStorage.getItem("userEmail");
             //console.log(RMID, setRoom, comingFrom, camera, isMicMuted)
           if(data.callStatus === "in-progress" && data.callType === "group" && data.roomId ===RMID && (data.callGroupEmails.includes(loggedInUser) || loggedInUser === data.callFromEmail)){
           
            // if(typeof docu.data().joinedMembers == 'undefined'){
             
            //     console.log("In connect room undefined:",loggedInUser)
            //     connectToRoom(twilioAccessToken, RMID, setRoom, comingFrom, camera, isMicMuted)
               
            //   } else{
            //     if(!docu.data().joinedMembers.includes(loggedInUser)){
            //       console.log("In connect room:",loggedInUser)
            //       connectToRoom(twilioAccessToken, RMID, setRoom, comingFrom, camera, isMicMuted)
            //      }
            //   }

            if(isTryingToJoining){
              connectToRoom(twilioAccessToken, RMID, setRoom, comingFrom, camera, isMicMuted)
            }
              if(docu.data().joinedMembers){
                
                if(!docu.data().joinedMembers.includes(loggedInUser)){

                  const formRef2 = doc(
                    database,
                    environment.REACT_APP_FIREBASE_DB_CALLS,
                    docu.id
                  );
                  
                    updateDoc(formRef2, {
                      joinedMembers: docu.data().joinedMembers?[...docu.data().joinedMembers, loggedInUser]:[loggedInUser]
                      
                    })
                      .then(() => {
                        console.log("participant updated more");
                        connectToRoom(twilioAccessToken, RMID, setRoom, comingFrom, camera, isMicMuted)
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                }
              } else{
                
                const formRef2 = doc(
                  database,
                  environment.REACT_APP_FIREBASE_DB_CALLS,
                  docu.id
                );
                updateDoc(formRef2, {
                  joinedMembers: [loggedInUser]
                  
                  
              
                })
                  .then(() => {
                    console.log("participant single");
                    connectToRoom(twilioAccessToken, RMID, setRoom, comingFrom, camera, isMicMuted)
                    
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              
            
              // if(!docu.data().joinedMembers.includes(loggedInUser)){
              //   console.log("In connect room:",loggedInUser)
              //   connectToRoom(twilioAccessToken, RMID, setRoom, comingFrom, camera, isMicMuted)
              //  }
           }
            if(data.callStatus === "in-progress" && data.callType === "private" && data.roomId ===RMID && (loggedInUser === data.callToEmail || loggedInUser === data.callFromEmail)){
              //console.log(RMID, setRoom, comingFrom, camera, isMicMuted)
             connectToRoom(twilioAccessToken, RMID, setRoom, comingFrom, camera, isMicMuted)
            //  console.log("in-progress status:::",data.callStatus);
            //  console.log(twilioAccessToken,RMID)
             }           
           
           
         });
         
        
       //}
      
     
     });
    }
 
    useEffect(()=>{
     
     checkAudioVideoCall();
 
   },[twilioAccessToken])
//   useEffect(() => {
//     if (twilioAccessToken) {
//       if (isAdmin) connectToRoomAsAdmin(twilioAccessToken, roomId, setRoom);
//       else connectToRoom(twilioAccessToken, roomId, setRoom, comingFrom, camera, isMicMuted);
//     }
//   }, [twilioAccessToken]);

  return (
    <div className="videos_container">
      <RoomLabel roomId={RMID} />
      {room && <TwilioRoom room={room} isCam={isCam} comingFrom={comingFrom}/>}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(VideosPrivate);
