import React, { useEffect, useMemo, useState } from "react";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { callAxios, convertReadable, convertReadableTime, secondsToHMS } from "../utils/utils";
import { environment } from "../environment";
import { setUserInfo } from "../store/actions";
import Card from "./Card";
import DateRange from "../Reports/DateRange";
import SheelaModal from "./modal/SheelaModal";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";

const TeamsCall = ({ setLoggedinUser }) => {
  // For Sidebar list selected card
  const [updates, setUpdates] = useState([]);
  const [active, setActive] = useState(null);
  const [isSheelaView, setSheelaView] = useState(false);
  const [summary, setSummary] = useState('')

  // For Read more read less functionality
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <>
        {isReadMore ? text.slice(0, 300) : text}
        {text.length > 300 &&
          <span onClick={toggleReadMore} className="text-info pointer">
            {isReadMore ? "...Read More" : " Read Less"}
          </span>
        }
      </>
    );
  };

  const [accessToken, setAccessToken] = useState(null);
  const [limit, setLimit] = useState(10);
  const [searchType, setSearchType] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  const [sheelaData, setDataForSheela] = useState({});
  const [callDetails, setCallDetails] = useState([]);
  const [isDetailsLoading, setDetailsLoading] = useState(true);
  const [allData, setData] = useState([]);
  const [isShowCalendar, setIsShowCalendar] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };
      setLoggedinUser(userInfo);
    }
  }, [user, loading]);
  // useEffect(() => {
  //   setStartDate(new Date());
  //   setEndDate(new Date());
  // }, [accessToken])

  useEffect(() => {
    // getMeetingData(new Date(), true);
    const searchType = $("#searchType").val();
    const date = new Date();
    const syear = date.getFullYear();
    const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const sday = String(date.getDate()).padStart(2, '0');
    const formattedStartDate = `${syear}-${smonth}-${sday}`;
    setStartDate(formattedStartDate);
    getDataByDate(formattedStartDate, searchType)
  }, [user]);

  //   console.log(charData)
  // console.log(allData)
  const analyzeData = (summary) => {
    setDataForSheela(summary)
    //console.log("sheela here")
    document.getElementById("SheelaModal").style.display = "block";
    document.getElementById("SheelaModal").classList.add("show");
    setSheelaView(true);
  }

  const getDataByDate = (startdate, searchType) => {
    // setStartDate(date)

    setIsLoading(true);
    // console.log(startdate, enddate)
    callAxios(environment.REACT_APP_API_URL_Live + "api/v2/crm/get-call-data", { isDateFilter: true, startdate: startdate, searchType: "cam" }, "post").then((meetingData) => {
      if (meetingData.data.recordings.length > 0) {
        setData(meetingData.data);
        setIsLoading(false);
        setDetailsLoading(false)
      } else {
        setData(meetingData.data);
        setIsLoading(false);
        setDetailsLoading(false)
        setCallDetails([])
      }
    })
  }
console.log(allData)
  const handleNameSearch = () => {
    const clientName = document.getElementById("search-by-client-name").value;
    const searchType = $("#searchType").val();
    // console.log(clientName)
    setIsLoading(true);

    callAxios(environment.REACT_APP_API_URL_Live + "api/v2/crm/get-call-data", { isFilterName: true, page: 1, limit: 100, filterValue: clientName, searchType: "cam", isFilter: false }, "post").then((meetingData) => {
      if (meetingData.data.recordings.length > 0) {
        setData(meetingData.data);
        setIsLoading(false);
      }
      else {
        setData(meetingData.data);
        setCallDetails([])
      }
    })
  }

  const onChange = (startDate) => {
    setIsShowCalendar(false);
    
    const searchType = $("#searchType").val();
    const syear = startDate.getFullYear();
    const smonth = String(startDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const sday = String(startDate.getDate()).padStart(2, '0');
    const formattedStartDate = `${syear}-${smonth}-${sday}`;
    getDataByDate(formattedStartDate, searchType)
    setStartDate(formattedStartDate);
  };

  const showCalendar = () => {
    // setIsShowCalendar(true);
    setIsShowCalendar(!isShowCalendar);
  }

  const renderPoints = (analysisData) => {
    return (
      <ul>
        {Object.entries(analysisData).map(([key, value]) => (
          <li key={key}>
            <strong className="text-capitalize">{key.replace(/_/g, ' ')}</strong>: {typeof value === 'object' ? renderPoints(value) : value}
          </li>
        ))}
      </ul>
    );
  };

    console.log(callDetails)
  let callDetailsS;
  if (callDetails && callDetails.length > 0) {
    callDetailsS = callDetails.map((item, index) => {
    

      return (
        <div className="attn-details-wrapper p-3 mt-3" key={index}>
          <div className="row gx-2 gy-1 mb-2">
            <div className="col-lg-8">
              {item.recording_date_time ?
                <div className="client-name fs-5 fw-semibold text-light text-capitalize mb-1">{convertReadable(item.recording_date_time) + ' ' + convertReadableTime(item.recording_date_time)}</div>
                : null}
              {/* {item.caller_name ?
                <div className="text-capitalize">Called By <span className="attn-name">{item.caller_name}</span></div>
                : null} */}
            </div>
           
            <div className="col-lg-4 text-lg-end">
            {/* {item.call_status ?
                <div className="duration text-light text-opacity-75 mb-1">Call Status - <span className="call-time text-warning text-capitalize">{item.call_status}</span></div>
                : null} */}
              {/* {item.call_duration ?
                <div className="duration text-light text-opacity-75 mb-1">Call Duration - <span className="call-time">{secondsToHMS(item.call_duration)}</span></div>
                : null} */}
            </div>
          </div>

          {item.is_transcription_done && Object.entries(item.transcript).length > 0 ?
            <div className="requrement-wrapper mb-3" style={{ height: "125px", overflowY: "scroll" }}>
              <div className="title-label fs-18 text-light">Summary:</div>
              <div className="description small text-light text-opacity-75">
                <>
                  <ReadMore>

                    {item.is_transcription_done && Object.entries(item.transcript.abstractSummary).map(([key, value]) => (
                      <li key={key}>
                        <strong className="text-capitalize">{key.replace(/_/g, ' ')}</strong>: {typeof value === 'object' ? renderPoints(value) : value}
                      </li>
                    ))}
                  </ReadMore>
                </>
              </div>
            </div> : null}

          <div className="compose-media-page row align-items-center g-3">
            <div className="col-lg-9">
              {item.is_transcription_done && Object.entries(item.transcript).length > 0 ?
                <div className="meeting-details-page d-flex align-items-center gap-3">
                  <div className="comment-part position-relative flex-fill text-white text-opacity-75 rounded py-2 px-2 px-md-3" style={{ backgroundColor: 'var(--custom-bg)' }}>
                    <div className="d-flex flex-column flex-xl-row align-items-xl-center justify-content-between gap-2">
                      <div className="info-sec fs-12">
                        <div className="attn-name fw-medium">{user?.displayName}</div>
                        <p className="m-0">I've completed my work. You can now review all the details.</p>
                      </div>
                      <div className="btn-wrapper">
                        <button className="btn btn-sm btn-success text-nowrap pointer" onClick={() => analyzeData(item.transcript)}>Sheela's Update</button>
                      </div>
                    </div>
                  </div>
                  <div className="img-wrapper">
                    <img className="img-fluid" src={require('../resources/images/meeting-girl.png')} alt="Meeting User" style={{ width: '27px', minWidth: '27px' }} />
                  </div>
                </div>
                : null}
            </div>

            <div className="col-lg-3">
              <div className="icon-wrapper d-flex gap-3">
                
                {item.flag_status ?
                  <div className="play-icon pointer" onClick={() => { handleMeetingViewAndDownload(item.file_path) }}>
                    <i className="bi bi-play-circle-fill text-white lh-1" style={{ fontSize: '40px' }}></i>
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </div>
      )
    })
  } else {
    callDetailsS = "No call details found";
  }

  // listen recording
  const closePlayer = () => {
    var audioSrc = document.getElementById("audioSrc");
    audioSrc.pause();
    document.getElementById("audio-player").style.display = "none";
  }

  const handleMeetingViewAndDownload = async (fileUrl) => {
    

   
      const url = fileUrl;
      var audioSrc = document.getElementById("audioSrc");
      const isSupp = audioSrc.canPlayType("video/mp4");
      if (isSupp == "") {
        audioSrc.src = url;
      } else {
        audioSrc.src = url;
      }
      audioSrc.load();
      audioSrc.play();
      document.getElementById("audio-player").style.display = "block";
    
  }
  // console.log(callDetails)

  const updateDateFilter = (val) => {
    if (val.length > 0) {
      $("#dateFilterField").hide();
      $("#basic-addon2").removeClass("d-none");
    } else {
      $("#dateFilterField").show();
      $("#basic-addon2").addClass("d-none");
    }
  }

  return (
    <div className="client-listing-page call-history-details py-3">
      {/* <ToastContainer /> */}
      <div className="container-xxl">
        <div className="row align-items-center g-2 mb-3">
          <div className="col-lg-4">
            <div className="main-heading text-white text-opacity-75">Call History Details</div>
          </div>

          <div className="col-lg-8">
            <div className="row justify-content-lg-end g-2">
              <div className="col-sm-6 col-md-3 field-cover">
               
              </div>

              <div className="col-sm-6 col-md-6 col-lg-5 field-cover">
                <div className="input-group rounded h-100 overflow-hidden">
                  {/* <input type="search" className="form-control" placeholder="Search by topic name" id='search-by-client-name' autoComplete='off' onChange={(e) => { updateDateFilter(e.target.value) }} /> */}
                  <span className="input-group-text pointer d-none" id="basic-addon2" onClick={handleNameSearch}><i className="bi bi-search"></i></span>
                </div>
              </div>

              <div className="col-12 col-md-3 field-cover" id="dateFilterField">
                <DatePicker wrapperClassName="w-100"
                  // selected={new Date()}
                  selected={startDate}
                  onChange={(date) => { onChange(date) }}
                  className="wonDatePicker w-100 m-0"
                  id="wonDatePicker"
                  // renderMonthContent={renderMonthContent}
                  showFullMonthYearPicker
                  dateFormat="yyyy-MM-dd"
                  showIcon
                />
              </div>
            </div>
          </div>
        </div>

        {/* CAM & BDM Client Info */}
        <div className="client-detail-wrapper">
          <div className="row g-3">
            <div className="col-md-5 col-lg-4">
              <div className="client-list-section">
                {isLoading ? (
                  <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3" id="lead-list-spinner">
                    <div className="lead-list-spinner"></div>
                    {" "} Please wait ....
                  </div>
                ) :
                  allData && allData.recordings.length > 0 ?
                    allData.recordings.map((item, id) => {
                      return <Card startDate={startDate} item={item} id={id} user={user} active={active} setActive={setActive} setCallDetails={setCallDetails} setDetailsLoading={setDetailsLoading} />
                    })
                    :
                    <p className="text-light mt-2"> Data not found!!</p>
                }
              </div>
            </div>

            <div className="col-md-7 col-lg-8">
              <div className="client-info-details p-3">
                <div className="fs-4 fw-medium text-warning"><i className="bi bi-person-vcard"></i> Call History Details</div>
                <div className="info-card-wrapper">
                  {isDetailsLoading ? (
                    <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3 mt-3" id="lead-list-spinner">
                      <div className="lead-list-spinner"></div>
                      {" "} Please wait ....
                    </div>
                  ) : callDetailsS}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="audio-player" className="modal" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <video width="400" controls autoPlay id="audioSrc">
                <source src="" type="video/mp4" ></source>
                Your browser does not support HTML video.
              </video>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal" onClick={closePlayer}>Close</button>
            </div>
          </div>
        </div>
      </div>

      <SheelaModal setSheelaView={setSheelaView} isSheelaView={isSheelaView} sheelaData={sheelaData} />
    </div >
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(TeamsCall);
