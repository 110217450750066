import React, { useEffect, useState } from "react";
import ChatSection from "./ChatSection/ChatSection";
import ParticipantsSection from "./ParticipantsSection/ParticipantsSection";
import "./RoomPage.css";
import VideoSection from "./VideoSection/VideoSection";
import { connect } from "react-redux";
import {
  setTwilioAccessToken,
  setConnectOnlyWithAudio,
  setIdentity,
  setRoomId,
} from "../store/actions";
import { getTokenFromTwilio } from "../utils/twilioUtils";
import { useNavigate, useLocation } from "react-router-dom";
import Overlay from "./Overlay";
import BackgroundSetting from "./BgSettingSection/BackgroundSetting";
import RejoinRoom from "./RejoinRoom";

function RoomPage(props) {
  const {
    identity,
    roomId,
    setTwilioAccessTokenAction,
    showOverlay,
    isRoomHost,
    setConnectOnlyWithAudioAction,
    connectOnlyWithAudio,
    setRoomIdAction,
    setIdentityAction,
  } = props;
  let navigate = useNavigate();

  const search = useLocation().search;
  const name = new URLSearchParams(search).get("nm");
  const joinerRoomId = new URLSearchParams(search).get("id");
  const sharedBy = new URLSearchParams(search).get("sharedBy");
  const isRejoin = new URLSearchParams(search).get("isRejoin");
  const IsRejoin = isRejoin === "true";
  //console.log(IsRejoin, props.room)
  const [comingFrom, setComingFrom] = useState();
  useEffect(() => {
    setIdentityAction(atob(name));
    setRoomIdAction(atob(joinerRoomId));
    if (sharedBy === "cam") {
      setComingFrom("sharedByCam");
      getTokenFromTwilio(
        setTwilioAccessTokenAction,
        "_Guest By CAM_" + atob(name)
      );
    } else if(sharedBy === "bdm") {
      setComingFrom("sharedByBdm");
      getTokenFromTwilio(
        setTwilioAccessTokenAction,
        "_Guest By BDM_" + atob(name)
      );
    } 
  }, []);

  return (
    <>
      <VideoSection
        comingFrom={comingFrom}
        isCam={false}
        camera={true}
        isMicMuted={false}
        isRejoin={IsRejoin}
        twilioAccessToken={props.twilioAccessToken}
        roomId={props.roomId}
      />

      <div
        id="main"
        className="room_container main-section p-lg-3 overflow-hidden"
      >
        <div
          id="shared_screen"
          className="shared_screen_preview d-flex flex-wrap w-100 h-100 overflow-hidden position-relative border border-1 border-info hide"
        ></div>
        <div
          id="videos_portal"
          className="when-normal d-flex flex-wrap h-100 overflow-auto position-relative"
        ></div>
      </div>

      {/* Chat Sidenav */}
      <div id="chatSidenav" className="sidebar border-start border-black">
        <ChatSection />
        {showOverlay && <Overlay />}
      </div>

      {/* Participants Sidenav */}
      <div id="partcipantSidenav" className="sidebar border-start border-black">
        <ParticipantsSection />
      </div>

      {/* Background Setting Sidenav */}
      <div id="BgSettingSidenav" className="sidebar border-start border-black">
        <BackgroundSetting comingFrom={false} />
      </div>

      {/* Rejoin Modal */}

      <RejoinRoom
        roomId={roomId}
        identity={identity}
        twilioAccessToken={props.twilioAccessToken}
        showOverlay={showOverlay}
        camera={true}
        isMicMuted={false}
      />
    </>
  );
}

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setTwilioAccessTokenAction: (token) =>
      dispatch(setTwilioAccessToken(token)),
    setConnectOnlyWithAudioAction: (onlyWithAudio) =>
      dispatch(setConnectOnlyWithAudio(onlyWithAudio)),
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (id) => dispatch(setRoomId(id)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(RoomPage);
