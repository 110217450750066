import React, { useEffect, useMemo, useState } from "react";
import searchIcon from "../resources/images/search.svg";
import notebookPenIcon from "../resources/images/notebook-pen.svg";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../firebaseConfig";
import { callAxios, convertReadable, convertReadableTime, secondsToHMS } from "../utils/utils";
import { environment } from "../environment";
import { setUserInfo } from "../store/actions";
import Card from "./Card";
import DateRange from "../Reports/DateRange";
import $ from "jquery";

import { ToastContainer, toast } from "react-toastify";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";

const Listing = ({ setLoggedinUser }) => {
  // For Sidebar list selected card
  const [updates, setUpdates] = useState([]);
  const [active, setActive] = useState(null);
  const [isSheelaView, setSheelaView] = useState(false);
  const [summary, setSummary] = useState('')
  // For Read more read less functionality
  const [searchType, setSearchType] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  const [sheelaData, setDataForSheela] = useState({});
  const [callDetails, setCallDetails] = useState([]);
  const [isDetailsLoading, setDetailsLoading] = useState(true);
  const [allData, setData] = useState([]);
  const [allSheelaData, setSheelaLeads] = useState([]);
  const [isShowCalendar, setIsShowCalendar] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };
      setLoggedinUser(userInfo);
    }
  }, [user, loading]);
  useEffect(() => {
    setStartDate(new Date());
    setEndDate(new Date());
  }, [])
  useEffect(() => {
    // getMeetingData(new Date(), true);
    const searchType = $("#searchType").val();
    const date = new Date();
    const syear = date.getFullYear();
    const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const sday = String(date.getDate()).padStart(2, '0');
    const formattedStartDate = `${syear}-${smonth}-${sday}`;
    getDataByDate(formattedStartDate, searchType)
  }, [user]);

  //   console.log(charData)

  // console.log(allData)
  const analyzeData = (summary) => {
    setDataForSheela(summary)
    //console.log("sheela here")
    document.getElementById("SheelaModal").style.display = "block";
    document.getElementById("SheelaModal").classList.add("show");
    setSheelaView(true);
  }

  const getDataByDate = (startdate, searchType) => {
    var startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);

    var endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);

    const queryConstraints = [];
    queryConstraints.push(where("timeStamp", ">=", startOfToday));
    queryConstraints.push(where("timeStamp", "<=", endOfToday));
    queryConstraints.push(orderBy("timeStamp", "desc"));
    const q = query(
      collection(database, environment.REACT_APP_FIREBASE_SHEELA_LEADS_DB),
      ...queryConstraints
    );
    onSnapshot(q, (querySnapshot) => {
      let forms = [];
      let sheelaLeads = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        data["id"] = doc.id;
        sheelaLeads.push({ ...doc.data(), id: doc.id });
      });

      setSheelaLeads(sheelaLeads)
      setIsLoading(false)
    });
  }

  const handleNameSearch = () => {
    const clientName = document.getElementById("search-by-client-name").value;
    const searchType = $("#searchType").val();
    // console.log(clientName)
    setIsLoading(true);

    callAxios(environment.REACT_APP_API_URL_Live + "api/v2/crm/get-call-list", { isFilterName: true, page: 1, limit: 100, filterValue: clientName, searchType: searchType, isFilter: false }, "post").then((meetingData) => {
      if (meetingData.data.leads.length > 0) {
        setData(meetingData.data);
        setIsLoading(false);
      }
      else {
        setData(meetingData.data);
        setCallDetails([])
      }
    })
  }

  const onChange = (startDate) => {
    setIsShowCalendar(false);
    setStartDate(startDate);
    const searchType = $("#searchType").val();
    const syear = startDate.getFullYear();
    const smonth = String(startDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const sday = String(startDate.getDate()).padStart(2, '0');
    const formattedStartDate = `${syear}-${smonth}-${sday}`;
    getDataByDate(formattedStartDate, searchType)
  };

  console.log(allSheelaData)
  let callDetailsS;
  if (allSheelaData && allSheelaData.length > 0) {
    callDetailsS = allSheelaData.map((item, index) => {
      const fireBaseTime = new Date(
        item.timeStamp.seconds * 1000 + item.timeStamp.nanoseconds / 1000000
      );
      const dateNew = fireBaseTime.toDateString();
      const atTime = fireBaseTime.toLocaleTimeString();
      let timeStampfor = dateNew + " " + atTime;
      return (
        <div className="attn-details-wrapper p-3 mt-3" key={index}>
          <div className="row gx-2 gy-1 mb-2">
            <div className="col-lg-8">
              {item.createdAt ?
                <div className="client-name fs-5 fw-semibold text-light text-capitalize mb-1">{convertReadable(item.createdAt) + ' ' + convertReadableTime(item.createdAt)}</div>
                : null}

              {item.name ?
                <div className="text-capitalize"><span className="attn-name">{item.name}</span></div>
                : null}

              {item.email ?
                <div className="text-capitalize"><span className="attn-name">{item.email}</span></div>
                : null}
              {item.phone ?
                <div className="text-capitalize"><span className="attn-name">{item.phone}</span></div>
                : null}

              {item.country ?
                <div className="text-capitalize"><span className="attn-name">{item.country}</span></div>
                : null}

              {item.state ?
                <div className="text-capitalize"><span className="attn-name">{item.state}</span></div>
                : null}
              {item.ip ?
                <div className="text-capitalize"><span className="attn-name">{item.ip}</span></div>
                : null}

              {item.ppcurl ?
                <div className="text-capitalize"><span className="attn-name">{item.ppcurl}</span></div>
                : null}
            </div>

            <div className="col-lg-4 text-lg-end">
              {item.timeStampfor ?
                <div className="duration text-light text-opacity-75 mb-1"><span className="call-time">{item.timeStampfor}</span></div>
                : null}
            </div>
          </div>

          <div className="compose-media-page row align-items-center g-3">
            {item.description ?
              <div className="col-lg-9">
                {item.description}
              </div>
              : null}
            <div className="col-lg-3">
              <div className="icon-wrapper d-flex gap-3">
                {item.call_type === 1 ?
                  <div className="group-call">
                    <img src={require('../resources/images/group-call.png')} alt="Group Calling" className="img-fluid" style={{ maxWidth: '40px' }} />
                  </div>
                  :
                  <div className="user-call">
                    <img src={require('../resources/images/user-on-call.png')} alt="Single User Call" className="img-fluid" style={{ maxWidth: '28px' }} />
                  </div>
                }
                {item.is_recording_available === 1 ?
                  <div className="play-icon pointer" onClick={() => { handleMeetingViewAndDownload(item.call_sid) }}>
                    <i className="bi bi-play-circle-fill text-white lh-1" style={{ fontSize: '40px' }}></i>
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </div>
      )
    })
  } else {
    callDetailsS = "No call details found";
  }

  // listen recording
  const closePlayer = () => {
    var audioSrc = document.getElementById("audioSrc");
    audioSrc.pause();
    document.getElementById("audio-player").style.display = "none";
  }
  const handleMeetingViewAndDownload = async (callSid) => {
    //console.log("composition Id:", compositionId, " | type:", type)
    // let mediaResponse;
    // if(type === 'view'){

    const mediaResponse = await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/twilio/get-recording-by-sid', { cid: callSid }, "post");

    if (mediaResponse.data.statusCode === 400) {
      toast.error("Recording not found!!!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      const url = mediaResponse.data.url;

      var audioSrc = document.getElementById("audioSrc");

      const isSupp = audioSrc.canPlayType("video/mp4");
      if (isSupp == "") {
        audioSrc.src = url;
      } else {
        audioSrc.src = url;
      }
      audioSrc.load();
      audioSrc.play();

      document.getElementById("audio-player").style.display = "block";
    }
  }
  // console.log(callDetails)
  const updateDateFilter = (val) => {
    if (val.length > 0) {
      $("#dateFilterField").hide();
      $("#basic-addon2").removeClass("d-none");
    } else {
      $("#dateFilterField").show();
      $("#basic-addon2").addClass("d-none");
    }
  }
  return (
    <div className="client-listing-page call-history-details py-3">

      {/* <ToastContainer /> */}
      <div className="container-fluid container-xxl">
        <div className="row align-items-center g-2 mb-3">
          <div className="col-lg-5">
            <div className="fs-3 fw-semibold text-white text-opacity-75 lh-sm">Leads by Sheela</div>
          </div>

          {/* <div className="col-lg-7 text-lg-end">
            <div className="d-flex d-lg-inline-flex flex-column flex-sm-row gap-2">
              <div class="input-group">
                <select onChange={(e)=>{setSearchType(e.target.value)}} className="form-select me-2" id="searchType">
                  <option value={"lead"}>By Lead</option>
                  <option value={"opportunity"}>By Opportinity</option>
                </select>
                <input type="search" className="form-control" placeholder="Search by topic name" id='search-by-client-name' autoComplete='off' onChange={(e)=>{updateDateFilter(e.target.value)}}/>
                <span className="input-group-text pointer d-none" id="basic-addon2" onClick={handleNameSearch}><i className="bi bi-search"></i></span>
              </div>

              <div className='custom-date-range' id="dateFilterField">
              <DatePicker
                // selected={new Date()}
                selected={startDate}
                onChange={(date) => { onChange(date) }}
                className="wonDatePicker m-0"
                id="wonDatePicker"
                // renderMonthContent={renderMonthContent}
                showFullMonthYearPicker
                dateFormat="yyyy-MM-dd"
                showIcon={false}
              />
              </div>
            </div>
          </div> */}
        </div>
        {/* CAM & BDM Client Info */}
        <div className="client-detail-wrapper">
          <div className="row g-3">
            <div className="col-md-5 col-lg-4">
              <div className="client-list-section">
                {isLoading ? (
                  <div
                    className=""
                    id="lead-list-spinner"
                    style={{
                      position: "relative",
                      margin: "21px 0px 0px 0px",
                      // left: "44%",
                    }}
                  >
                    <div
                      style={{ marginLeft: "12px" }}
                      className="lead-list-spinner"
                    ></div>{" "}
                    Please wait ....
                  </div>
                ) :
                  allSheelaData && allSheelaData.length > 0 ?
                    allSheelaData.map((item, id) => {

                      return <Card startDate={startDate} item={item} id={id} user={user} active={active} />
                    })
                    :
                    <p> Data not found!!</p>
                }
              </div>
            </div>
            <div className="col-md-7 col-lg-8">
              <div className="client-info-details p-3">
                <div className="fs-4 fw-medium text-warning"><i className="bi bi-person-vcard"></i> Live Chats</div>
                <div className="info-card-wrapper">
                  {isDetailsLoading ? (
                    <div
                      className=""
                      id="lead-list-spinner"
                      style={{
                        position: "absolute",
                        margin: "21px 0px 0px 0px",
                        left: "44%",
                      }}
                    >
                      <div
                        style={{ marginLeft: "12px", float: "right" }}
                        className="lead-list-spinner"
                      ></div>{" "}
                      Please wait ....
                    </div>
                  ) : callDetailsS}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div id="audio-player" class="modal" role="dialog">
        <div class="modal-dialog">

          <div class="modal-content">

            <div class="modal-body">
              <video width="400" controls autoplay id="audioSrc">
                <source src="" type="video/mp4" ></source>

                Your browser does not support HTML video.
              </video>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal" onClick={closePlayer}>Close</button>
            </div>
          </div>

        </div>
      </div> */}

      {/* <SheelaModal setSheelaView={setSheelaView} isSheelaView={isSheelaView} sheelaData={sheelaData} /> */}
    </div >
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(Listing);
