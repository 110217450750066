import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Loader } from '../Components/Loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { HigherEducation } from './HigherEducation';
import { BelowGraduation } from './BelowGraduation';
import { environment } from '../../environment';
import { getProfileData } from '../../utils/utils';
import WarningModal from '../Components/Warningmodal';

export const AddEducation = ({ showEducationModal, closeEducationModal, edutype, toasterData, empdataload, sendeducationData, sendactiontype, setShowEducationModal, profileId, setProfileData, setIsLoading }) => {
 
    // console.log(sendactiontype)
    const addEducationData = async(postData) => { 
        setIsLoading(true)
         setShowEducationModal(false)
        var apiname = 'add-education'
        if(postData.type === "update" || postData.type === "delete"){
            apiname = 'update-delete-education'
        }
        try {
			const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/${apiname}`, postData, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const responsedata = response?.data
			if (responsedata?.statusCode === 200 || responsedata?.statusCode === 201 || response?.data) {              
				setProfileData(await getProfileData(profileId, setIsLoading))
                const sendMsg = responsedata?.message
                toast.success(sendMsg, {
					position: 'bottom-center'
				})
				
               
			}
            
		} catch (error) {
            setProfileData(await getProfileData(profileId, setIsLoading))
			console.log('Error: ', error)
		}
    }

    const [showModal, setShowModal] = useState(false);
    const [textmessage, setTextmessage] = useState('');
    const [deleteEducation, setDeleteEducation] = useState('')

    // deleteducation
    const deleteducation = () => {
        const getId = sendeducationData?.id
        setShowModal(true)
        setTextmessage('Are you sure you want to delete this education?')
        const sendData = {
            "profile_id": profileId ? profileId : '',
            "id": getId,
            "type": "delete"
        }
        setDeleteEducation(sendData)
    }
 
    const handleCloseModal = () => {
        setShowModal(false)
    }
    // deleteducation

    return (
        <Modal show={showEducationModal} centered size='lg' onHide={closeEducationModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
            <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
                
                <div className="close-wrapper text-end">
                    <Button variant="link" onClick={closeEducationModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
                </div>
                <WarningModal show={showModal} onClose={handleCloseModal} textmessage={textmessage} updateEmp={addEducationData} deleteEmp={deleteEducation} />
                <div className="heading-wrapper mb-4">
					<div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
						<span className='widgetTitle text-black fs-5 fw-semibold'>Education 
                       
                        <span className='profileComScore fs-6 fw-normal text-success ms-1'>Add 8%</span>
                        
                        </span>
						{/* This button will show only when you click to edit the details */}
						{/* {sendactiontype==="update"?
							<span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0 pointer' onClick={() => deleteducation(true)} >Delete</span>
							 :null} */}
					</div>
					<p className='subTitle fs-14'>Details like course, university, and more, help recruiters identify your educational background</p>
				</div>
               
                {/* Education Components */}
                {(edutype == 'phd' || edutype == 'masters' || edutype == 'graduation') ? 
                    <HigherEducation closeEducationModal={closeEducationModal} edutype={edutype} addEducationData={addEducationData} sendeducationData={sendeducationData} sendactiontype={sendactiontype} profileId={profileId} /> : 
                    (edutype == 'higherSecondary'  || edutype == 'secondary' || edutype == 'belowten') ?
                    <BelowGraduation closeEducationModal={closeEducationModal} edutype={edutype} addEducationData={addEducationData} sendeducationData={sendeducationData} sendactiontype={sendactiontype} profileId={profileId}/> : ''   }
                
                {/* Education Components */}

            </Modal.Body>
        </Modal>
    )
}
