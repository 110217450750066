import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const usePreviousUrl = () => {
  const location = useLocation();
  const previousUrlRef = useRef(null);

  useEffect(() => {
    previousUrlRef.current = location.pathname;
  }, [location]);

  return previousUrlRef.current;
};

export default usePreviousUrl;
