import React, { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { VerifyOTP } from './VerifyOTP';
import { callAxios } from '../../utils/utils';
import { environment } from '../../environment';

export const Changemobilenumber = ({ editmobile, closeEditModal, index,profileData, mobileNumber, eventType, setProfileData, setIsLoading, setShowBasicDetailsModal, setEditMobile, setMobileNumber_ }) => {
    // VerifyOtp
    const [verifyotpmodal, setVerifyOTPModal] = useState(false)
    const openverifyModal = () => { setVerifyOTPModal(true) }
    const closeverifyModal = () => { setVerifyOTPModal(false) }
    // VerifyOtp
    const [formData, setFormData] = useState({
        mobile_number: "",
    })
  
    const setMobileNumber = (e) => {
        const { name, value } = e.target;
        if(name === "mobile_number"){
            setMobileNumber_(value)
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const handleEditMobileNumber = (e) => {
        e.preventDefault()
        callAxios(environment.REACT_APP_API_URL_Live+"api/v1/profile/send-otp",{to:mobileNumber,eventType:eventType,profileId:profileData.id},"post")
        console.log(formData)
        // closeEditModal()
        openverifyModal()
    };
console.log(formData)
    return (
        <>
            <Modal show={editmobile} onHide={closeEditModal} centered backdrop="static" backdropClassName="child-modal-backdrop" keyboard={false} dialogClassName='profileCustomModal editMobileNumModal small text-dark mx-auto' contentClassName='rounded-4'>
                {/* <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4"> */}
                <div className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-md-4 py-4 p-lg-5 pt-lg-4'>
                    <div className='heading-wrapper mb-4'>
                        <div className='text-white fs-5 fw-medium'>Edit mobile number</div>
                        <div className='text-clr1'>Recruiters will contact you on this number</div>
                    </div>

                    <Form action="POST" className="profileCustomModal form-cover" onSubmit={handleEditMobileNumber}>
                        <Form.Group className="mb-4">
                            <Form.Label>Mobile number<span className='text-danger'>*</span></Form.Label>
                            <InputGroup className='rounded-4 overflow-hidden'>
                                <InputGroup.Text id="basic-addon1" className='fs-14 fw-semibold bg-white pe-2'>+91</InputGroup.Text>
                                {eventType === "changeMobile"?
                                <Form.Control type="text" className='border-start-0 ps-2' placeholder="Enter your mobile number" minLength={10} maxLength="10" autoComplete='off'
                                    name="mobile_number"
                                    value={formData.mobile_number}
                                    onChange={setMobileNumber}
                                    required
                                />
                                :
                                <Form.Control type="text" className='border-start-0 ps-2' placeholder="Enter your mobile number" minLength={10} maxLength="10" autoComplete='off'
                                    name="mobile_number"
                                    value={mobileNumber}
                                    onChange={setMobileNumber}
                                    required
                                />
                                }
                            </InputGroup>
                            <div className='text-clr1 fs-12 mt-1'>We will send the verification code to this number</div>
                        </Form.Group>

                        <div className="btn-wrapper d-flex flex-wrap gap-2">
                            <Button type='submit' variant='primary' className='rounded-pill px-4'>Send OTP</Button>
                            <Button variant='danger' className='rounded-pill text-decoration-none px-4' onClick={closeEditModal}>Cancel</Button>
                        </div>
                    </Form>
                </div>
                {/* </Modal.Body> */}
            </Modal>
            {eventType.length > 0?
            <VerifyOTP verifyotpmodal={verifyotpmodal} closeverifyModal={closeverifyModal} profileData={profileData} setProfileData={setProfileData} setIsLoading={setIsLoading} setShowBasicDetailsModal={setShowBasicDetailsModal} mobileNumber={mobileNumber} eventType={eventType} setEditMobile={setEditMobile} setVerifyOTPModal={setVerifyOTPModal}/>
            :null}
        </>
    )
}
