import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class ConnectedVsNot extends Component {

  componentDidMount() {
     this.initChart()
  }
  componentDidUpdate(prevProps) {
    
    // if( this.chart) {
    //   this.chart.dispose();
    // }
    this.initChart();
    
  }
  
  initChart(){
    am4core.useTheme(am4themes_animated);

    var chart = am4core.create("connected-vs-not-connected", am4charts.PieChart);

    chart.hiddenState.properties.opacity = 0;
    
    chart.data = [
        {
            category: "Connected",
          value: this.props.connected?this.props.connected:0,
          color: am4core.color("#008000")
        },
        {
            category: "Not Connected",
          value: this.props.notConnected?this.props.notConnected:0,
          color:am4core.color("#D22B2B")
        },
        
      ];

      
      
    //   var series = chart.series.push(new am4charts.PieSeries());
    //     series.dataFields.value = "value";
    //     series.dataFields.radiusValue = "value";
    //     series.dataFields.category = "category";
    //     // series.slices.template.cornerRadius = 1;
    //     series.colors.step = 6;

    //     series.labels.template.disabled = true;
    //     series.ticks.template.disabled = true;

    //     series.hiddenState.properties.endAngle = -90;

    //     series.labels.template.maxWidth = 30;
    //     series.labels.template.wrap = true;
    //     series.labels.template.fontSize = 11;
    //     series.labels.template.__disabled = true;
    //     series.labels.template.disabled =true;

    //     // series.slices.template.stroke = am4core.color("#4a2abb");
    //     // series.slices.template.strokeWidth = 2;
    //     // series.slices.template.strokeOpacity = 1;

    //     series.slices.template.propertyFields.fill = "color";
        

    //     chart.legend = new am4charts.Legend();

    //     chart.legend.markers.template.paddingTop= 3;
    //     // chart.legend.markers.template.width = 13;
    //     // chart.legend.markers.template.height = 13;
        
    //     chart.legend.useDefaultMarker = true;
    //     let marker = chart.legend.markers.template.children.getIndex(0);
    //     marker.cornerRadius(12, 12, 12, 12);
    //     marker.height = 14;
    //     marker.width = 14;
    //     chart.legend.itemContainers.template.paddingTop = 2;
    //     chart.legend.itemContainers.template.paddingBottom = 0;
    //     chart.legend.itemContainers.template.fontSize = 14;

    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.propertyFields.fill = "color";

    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom'
        chart.legend.markers.template.paddingTop= 3;
        // chart.legend.markers.template.width = 13;
        // chart.legend.markers.template.height = 13;
        
        chart.legend.useDefaultMarker = true;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        marker.height = 14;
        marker.width = 14;
        chart.legend.itemContainers.template.paddingTop = 2;
        chart.legend.itemContainers.template.paddingBottom = 0;
        chart.legend.itemContainers.template.fontSize = 14;
            
  }
    

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  
  render() {
    return <div id="connected-vs-not-connected" style={{ width: "100%", height: "270px", margin: "0 auto" }} />;
  }

 
}

export default ConnectedVsNot;
