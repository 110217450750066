import React from "react"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export const OptionNo = ({ formData, handleInputChange, remainingCharacters }) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1 and pad with leading zero
  
    // Create the max value for the input
    const maxMonth = `${currentYear}-${currentMonth}`;
    return(
        <>
            <Form.Group className="mb-3">
                <Form.Label>Previous company name<span className='text-danger'>*</span></Form.Label>
                <Form.Control type="text" name="currCmp" value={formData.currCmp} onChange={handleInputChange} placeholder="Type your orgainization" autoComplete='off' required />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Previous job title<span className='text-danger'>*</span></Form.Label>
                <Form.Control type="text" name="currJobtitle" value={formData.currJobtitle} onChange={handleInputChange} placeholder="Type your designation" autoComplete='off' required />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Joining date<span className='text-danger'>*</span></Form.Label>
                <Form.Control max={maxMonth} type="month" name="joiningDate" value={formData.joiningDate} onChange={handleInputChange} autoComplete='off' required />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Job profile<span className='text-danger'>*</span></Form.Label>
                <Form.Control as="textarea" rows={3} placeholder='Type here...' autoComplete='off' name="jobProfile" value={formData.jobProfile} onChange={handleInputChange} required />
                <div className="charLenght fs-14 text-secondary text-end mt-2">{remainingCharacters} character(s) left</div>
            </Form.Group>
            <Form.Group>
                <div className="row g-2">
                    <div className="col-12">
                        <Form.Group className="mb-3">
                            <Form.Label>Worked till<span className='text-danger'>*</span></Form.Label>
                            <Form.Control max={maxMonth} type="month" name="workedtill" value={formData.workedtill} onChange={handleInputChange} autoComplete='off' required />
                        </Form.Group>
                    </div>
                </div>
            </Form.Group>
        </>
    )
}