import React, { useEffect, useState } from "react";
import { app, database, auth } from "../firebaseConfig";
import "../LeadsListingPage/LeadsListingPage.css";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import notificationSound from "../static/Notification.mp3";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  setIdentity,
  setRoomId,
  setFormId,
  setUserInfo,
} from "../store/actions";

import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgressBar from '../CircularProgressBar/CircularProgressBar';
// api call import
import { callAxios } from '../utils/utils';
// env import
import { environment } from '../environment';
//datepicker import
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRange from "./DateRange";

const LeadReport = (props) => {
  const { setLoggedinUser } = props;
  const [user, loading, error] = useAuthState(auth);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [type, setType] = useState("");
  const [isShowDate, setShowDates] = useState("");
  const [isShowCalendar, setIsShowCalendar] = useState("");
  let navigate = useNavigate();
  const [allData, setAllDate] = useState([]);
  const [allClientMeetings, setDownloadData] = useState([]);
  let q;

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };
      setLoggedinUser(userInfo);
    }
  }, [user, loading]);

  const closePlayer = () => {
    var videoSrc = document.getElementById("videoSrc");
    videoSrc.pause();
    document.getElementById("video-player").style.display = "none";
  }

  //console.log(allData)
  const scrollCss = {
    width: "100%",
    height: "100px",
    overflow: "auto"
  }

  //console.log(allData)
  let _17 = 0;
  let _33 = 0;
  let _50 = 0;
  let _67 = 0;
  let _83 = 0;
  let _100 = 0;
  let camMissed = 0;
  let bdmMissed = 0;
  let totalLiveCalls = 0;
  let totalMobilesCalls = 0;
  let totalLive_MobilesCalls = 0;
  let clientdidnotlivejoinNumber = 0;
  let clientdidnotmobilejoinNumber = 0;
  let blankJd = 0;
  let mobileclientdidnotjoin = 0;
  let submitted = 0;

  const removeDuplicates = (connectedClientArray) => {
    let freshConnectedLeads = [];
    let uniqueObject = {};
    for (let i in connectedClientArray) {

      const objRoomId = connectedClientArray[i]['roomId'];
      uniqueObject[objRoomId] = connectedClientArray[i];
    }
    for (let i in uniqueObject) {
      freshConnectedLeads.push(uniqueObject[i]);
    }
    return freshConnectedLeads;
  }

  const withparticipantlist = removeDuplicates(allClientMeetings);

  //console.log("CAM+BDM,",withparticipantlist)
  const newArray = [];
  withparticipantlist.map((item, index) => {
    if (item.participants) {
      item.participants.map((p, i) => {
        if (p.identity.includes("_CL")) {
          newArray.push(item)
        }
      })
    }
  })

  //console.log(allData)
  //const newArr = [];
  allData.map((item, index) => {
    if (item.clientSubmitTime && item?.country != "IN") {
      submitted += 1;
    }

    if (item.description == '') {
      blankJd += 1;
    }
    if (!item?.clientJoinedLiveTime && !item.camClickedMobileButton) {
      clientdidnotlivejoinNumber += 1;
    }

    if (item.camClickedMobileButton && (Number(item.Duration) < 50) && !item?.clientJoinedLiveTime) {
      clientdidnotmobilejoinNumber += 1;
    }

    // if(item.participants){
    //     item.participants.map((p) => {
    //     if (!p?.identity.includes("_CL_")) {
    //         totalLiveCalls +=1;
    //     }
    //   })
    // }

    // if(item.camJoinedAt && item.bdmJoinedAt && !item.camClickedMobileButton){
    //     totalLiveCalls +=1;
    // }

    if (item.camJoinedAt && item.bdmJoinedAt && !item.camClickedMobileButton) {
      totalLiveCalls += 1;
    }

    if (item.callStatus === 1 && item?.country != "IN") {

    } else {
      camMissed += 1;
    }
    if (item?.bdmCallStatus === 1 && item?.description !== '' && item?.country !== "IN") {
      bdmMissed += 1;
      //newArr.push(item)
    }

    //else {
    //     bdmMissed += 1;
    // }
    // if(item?.callStatus == 1 &&
    //     item?.RoomStatus === 'in-progress' &&
    //     !item.bdmJoinedAt &&
    //     !item.camLeftAt &&
    //     item.description){

    //     }
    //     else if(item.bdmJoinedAt){

    //      }
    //       else if(item.camJoinedAt && !item.description){

    //       }
    //       else{
    //         if(item.description){
    //              bdmMissed += 1;
    //         }
    //       }
    let total = 0;

    total += item.name !== "" ? 1 : 0;
    total += item.email !== "" ? 1 : 0;
    total += item.phone !== "" ? 1 : 0;
    total += item.companyweb !== "" ? 1 : 0;
    total += item.projduration !== "" ? 1 : 0;
    total += item.description !== "" ? 1 : 0;

    const percentage = parseFloat((total * 100) / 6).toFixed(0);
    //console.log("percentage::", percentage)
    if (percentage == 17) {
      _17 += 1;
    }
    if (percentage == 33) {
      _33 += 1;
    }
    if (percentage == 50) {
      _50 += 1;
    }
    if (percentage == 67) {
      _67 += 1;
    }
    if (percentage == 83) {

      _83 += 1;
    }
    if (percentage == 100) {
      _100 += 1;
    }
  })
  //console.log(newArr)
  const camBdmListing_ = [{
    month: type ? type : "",
    totalLead: allData.length,
    camMissed: camMissed,
    bdmMissed: bdmMissed,
    total: camMissed + bdmMissed
  }];

  const percentageWiseleads = [{
    month: type ? type : "",
    totalLead: allData.length,
    _17: _17,
    _33: _33,
    _50: _50,
    _67: _67,
    _83: _83,
    _100: _100
  }];

  const cambdmlivewithoutclient = [{
    totalLead: allData.length,
    totalLiveCalls: totalLiveCalls,
    clientDidNotJoin: 0//clientdidnotlivejoinNumber
  }];

  const cambdmlivecallwithoutclient = [{
    totalLead: allData.length,
    totalMobileCalls: totalMobilesCalls,
    clientDidNotJoin: 0 //clientdidnotlivejoinNumber
  }];

  const blankJobDescription = [{
    totalLead: allData.length,
    blankJd: blankJd,
    submitted: submitted,
    percentage: Math.round(blankJd * 100 / allData.length).toFixed(2)
  }]
  //console.log(leadswithoutclient_mobile)
  const camMissed_ = camBdmListing_?.map((item, index) => {
    return (
      <>
        <tr key={index + "missed"}>
          <td className="list-item-report d-none d-lg-block">
            <div className="date-time-state"></div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{allData.length}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item.camMissed}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{Math.round(item.camMissed * 100 / allData.length).toFixed(2) + "%"}</div>
          </td>
        </tr>
      </>
    )
  })

  const bdmMissed_ = camBdmListing_?.map((item, index) => {
    return (
      <>
        <tr key={index + "missed"}>
          <td className="list-item-report d-none d-lg-block">
            <div className="date-time-state"></div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{allData.length - camMissed}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item.bdmMissed}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{Math.round(item.bdmMissed * 100 / (allData.length - camMissed)).toFixed(2) + "%"}</div>
          </td>
        </tr>
      </>
    )
  })
  const vlisting = percentageWiseleads?.map((item, index) => {
    return (
      <>
        <tr key={index + "percentage"}>
          <td className="list-item-report d-none d-lg-block">
            <div className="date-time-state"></div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{allData.length}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item._17}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item._33}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item._50}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item._67}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item._83}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item._100}</div>
          </td>
        </tr>
      </>
    );
    //}
  });

  const cam_plus_bdm_without_client = cambdmlivewithoutclient?.map((item, index) => {
    return (
      <>
        <tr key={index + "percentage"}>
          <td className="list-item-report d-none d-lg-block">
            <div className="date-time-state"></div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item.totalLead}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item.totalLiveCalls}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item.clientDidNotJoin}</div>
          </td>
        </tr>
      </>
    );
    //}
  });

  const cam_plus_bdm_mobile_without_client = cambdmlivecallwithoutclient?.map((item, index) => {
    return (
      <>
        <tr key={index + "percentage-lead-mobile"}>
          <td className="list-item-report d-none d-lg-block">
            <div className="date-time-state"></div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item.totalLead}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item.totalMobileCalls}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item.clientDidNotJoin}</div>
          </td>
        </tr>
      </>
    );
    //}
  });

  const blankJobDesc = blankJobDescription?.map((item, index) => {
    return (
      <>
        <tr key={index + "percentage-lead-blank-jd"}>
          <td className="list-item-report d-none d-lg-block">
            <div className="date-time-state"></div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item.totalLead}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item.blankJd}</div>
          </td>
          <td className="list-item-report">
            <div className="date-time-state">{item.submitted}</div>
          </td>
        </tr>
      </>
    );
    //}
  });

  useEffect(() => {
    //console.group("use effect is calling")
    setStartDate(new Date());
    setEndDate(new Date());
    getReportData_(new Date(), new Date());
  }, []);

  const getReportData = async () => {
    // console.log(startDate,endDate)
    let fromDate;
    let endOfToday;
    fromDate = new Date(startDate);
    fromDate.setHours(0, 0, 0, 0);
    // fromDate.setDate(startDate.getDate());
    endOfToday = new Date(endDate);
    endOfToday.setHours(23, 59, 59, 999);
    //console.log(fromDate,endOfToday)
    const queryConstraints = [];
    queryConstraints.push(where('timeStamp', '>=', fromDate));
    queryConstraints.push(where('timeStamp', '<=', endOfToday));
    // queryConstraints.push(where('callStatus', '==', 1));
    queryConstraints.push(orderBy('timeStamp', 'desc'));
    q = query(collection(database, environment.REACT_APP_FIREBASE_DB), ...queryConstraints);

    onSnapshot(q, (querySnapshot) => {
      let forms = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        data["id"] = doc.id;
        if (data.formstatus > 0) forms.push({ ...doc.data(), id: doc.id });
      });
      setAllDate(forms);
    });
  }
  const filteredData = [];
  const checkClient = (participant, item) => {
    if (!participant?.identity.includes("_CL_")) {
      filteredData.push(item);
      //console.log(item)
    }
  }
  const getReportData_ = async (stDate, enDate) => {
    // const filteredData = [];
    // console.log(stDate,enDate)
    let fromDate;
    let endOfToday;
    fromDate = new Date(stDate);
    fromDate.setHours(0, 0, 0, 0);
    // fromDate.setDate(startDate.getDate());
    endOfToday = new Date(enDate);
    endOfToday.setHours(23, 59, 59, 999);
    //console.log(fromDate,endOfToday)
    const queryConstraints = [];
    queryConstraints.push(where('timeStamp', '>=', fromDate));
    queryConstraints.push(where('timeStamp', '<=', endOfToday));
    // queryConstraints.push(where('callStatus', '==', 1));
    queryConstraints.push(orderBy('timeStamp', 'desc'));
    q = query(collection(database, environment.REACT_APP_FIREBASE_DB), ...queryConstraints);

    onSnapshot(q, (querySnapshot) => {
      let forms = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        data["id"] = doc.id;
        forms.push({ ...doc.data(), id: doc.id });
      });
      if (forms.length > 0) {
        forms.map((item, index) => {
          //console.log(index)
          if (item.participants) {
            item.participants.map((p) => {
              if (!p?.identity.includes("_CL_") && item.callStatus === 1 && item.bdmCallStatus === 1 && !item.CallStatus) {
                filteredData.push(item);
                //console.log(item)

              }
            })
            //console.log("filteringData::", filteredData)
            setDownloadData(filteredData)
          }
        });
      }
      setAllDate(forms);
    });
  }
  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const onChange = (ranges) => {
    setIsShowCalendar(false);
    setStartDate(ranges.startDate);
    setEndDate(ranges.endDate);
    getReportData_(ranges.startDate, ranges.endDate);
    //console.log(ranges.startDate, ranges.endDate);
  };

  const showCalendar = () => {
    setIsShowCalendar(!isShowCalendar);
    // console.log(isShowCalendar)
  }

  return (
    <div className="lead-reports-page py-3">
      <div className="container">
        <div className="row g-2 align-items-center mb-4">
          <div className="col-sm-6 col-lg-8">
            <div className="main-heading text-white text-opacity-75">
              Lead Report
            </div>
          </div>

          <div className="col-sm-6 col-lg-4 text-md-end">
            <div className="calendarWrapper position-relative ms-md-auto" style={{ maxWidth: '300px' }}>
              <input placeholder={new Date(startDate).toLocaleString("default", { day: '2-digit', month: 'short', year: 'numeric' }) + ' - ' + new Date(endDate).toLocaleString("default", { day: '2-digit', month: 'short', year: 'numeric' })} type="text" className="form-control" id="calendar" onClick={showCalendar} />

              {isShowCalendar ?
                <div className="position-absolute end-0" style={{ zIndex: 11 }}>
                  <DateRange showIcon onChange={onChange} startDate={startDate} endDate={endDate} />
                </div>
                : null
              }
            </div>
          </div>
        </div>

        <div className="live-listing-wrapper lead-description-wrapper">
          <div className="table-wrapper">
            <div className="table-responsive rounded-3">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th className="list-item-report d-none d-lg-block"></th>
                    <th className="list-item-report">Total Lead</th>
                    <th className="list-item-report">17%</th>
                    <th className="list-item-report">33%</th>
                    <th className="list-item-report">50%</th>
                    <th className="list-item-report">67%</th>
                    <th className="list-item-report">83%</th>
                    <th className="list-item-report">100%</th>
                  </tr>
                </thead>
                <tbody>{vlisting}</tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mt-3 mt-md-4">
              <div className="table-responsive rounded-3">
                <table className="table table-borderless mb-0">
                  <thead>
                    <tr>
                      <th className="list-item-report d-none d-lg-block"></th>
                      <th className="list-item-report">Total Lead</th>
                      <th className="list-item-report">Cam Missed</th>
                      <th className="list-item-report">%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {camMissed_}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-6 mt-3 mt-md-4">
              <div className="table-responsive rounded-3">
                <table className="table table-borderless mb-0">
                  <thead>
                    <tr>
                      <th className="list-item-report d-none d-lg-block"></th>
                      <th className="list-item-report">Total Lead</th>
                      <th className="list-item-report">BDM Missed</th>
                      <th className="list-item-report">%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bdmMissed_}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* <div className="row">
                        <div className="col-md-6 mt-3 mt-md-4">
                            <div className="table-responsive rounded-3">
                                <table className="table table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th className="list-item-report d-none d-lg-block"></th>
                                            <th className="list-item-report">Total Leads</th>
                                            <th className="list-item-report">Total Live Calls</th>
                                            <th className="list-item-report">Client Didn't Join</th>                               
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cam_plus_bdm_without_client}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-md-6 mt-3 mt-md-4">
                            <div className="table-responsive rounded-3">
                                <table className="table table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th className="list-item-report d-none d-lg-block"></th>
                                            <th className="list-item-report">Total Leads</th>
                                            <th className="list-item-report">Total Live Mobile Calls</th>
                                            <th className="list-item-report">Client Didn't Join</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cam_plus_bdm_mobile_without_client}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}

          <div className="table-wrapper mt-3 mt-md-4">
            <div className="table-responsive rounded-3">
              <table className="table table-borderless mb-0">
                <thead>
                  <tr>
                    <th className="list-item-report d-none d-lg-block"></th>
                    <th className="list-item-report">Total Leads</th>
                    <th className="list-item-report">Blank Job Description</th>
                    <th className="list-item-report">Submitted Leads</th>
                  </tr>
                </thead>
                <tbody>
                  {blankJobDesc}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (id) => dispatch(setRoomId(id)),
    setFormIdAction: (id) => dispatch(setFormId(id)),
    setLoggedinUser: (user) => dispatch(setUserInfo(user)),
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(LeadReport);