import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

export const CallPerformanceRating = ({showPerformanceRating,displayName,setshowCallPerformance,speakerRaiting, showLoader}) => {
    const [show, setShow] = useState(showPerformanceRating);
   
    const handleClose = () => {
        window.onbeforeunload = null;
        setshowCallPerformance(false)
        setShow(false);
        window.close()
    }
    const handleShow = () => setShow(true);
    // console.log(speakerRaiting)
    return (
        <Modal show={show} onHide={handleClose} centered size='lg' backdrop="static" keyboard={false} contentClassName="border border-5 border-black">
            <Modal.Body className="bg-secondary bg-opacity-25 px-3 px-lg-4 py-4">
                <div className="performance-rating-wrapper">
                    <h5 className="text-black fw-semibold text-center mb-4 pb-2">Notification: Go Live Call Performance Rating</h5>
                    <div className="content-info mb-3 pb-1">
                        <div className="label-description fs-14 text-body mb-3">
                            <span className="fw-semibold">Hi {displayName},</span> while I'm submitting this update to the CRM, here is some quick feedback for you. 
                            I hope this will help you on the next call.
                        </div>
                        <div className="label-description fs-14 text-body">
                            Good job on your recent call! I've rated it on a scale of 10 and provided feedback to help you improve further. 
                            Keep up the fantastic work and aim for an even higher rating next time!
                        </div>
                    </div>
                    {speakerRaiting.rating?
                    <div className="content-info mb-3 pb-1">
                        <div className="label-name text-black fs-6 fw-semibold">Rating</div>
                        <div className="label-description fs-14 text-body">{speakerRaiting.rating}</div>
                    </div>
                    :null}
                    {speakerRaiting.reason?
                    <div className="content-info mb-3 pb-1">
                        <div className="label-name text-black fs-6 fw-semibold">Reason</div>
                        <div className="label-description fs-14 text-body">Great job, {displayName}! {speakerRaiting.reason}</div>
                    </div>
                    :null}
                    {speakerRaiting.suggestion?
                    <div className="content-info mb-4 pb-3">
                        <div className="label-name text-black fs-6 fw-semibold">Suggestion For Improvement</div>
                        <div className="label-description fs-14 text-body">{speakerRaiting.suggestion}</div>
                    </div>
                    :null}
                    <div className="btn-wrapper text-center mb-4">
                        {showLoader?
                        <>
                        <div className="loader-crm" style={{margin:"0 auto"}}></div>
                        <p className='text-dark'>please wait, we are processing data to CRM </p>
                        </>
                        
                        :
                        <Button variant='success' size='sm' className="rounded-pill px-4 py-2" onClick={handleClose}>Thank You Sheela</Button>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
