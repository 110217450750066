import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import WarningModal from '../Components/Warningmodal';

export const BelowGraduation = ({ closeEducationModal, edutype, addEducationData, sendeducationData, sendactiontype, profileId }) => {
    // const getUserId = process.env.REACT_APP_USER_ID
    const [showModal, setShowModal] = useState(false);
    const [textmessage, setTextmessage] = useState('');
    const [deleteEducation, setDeleteEducation] = useState('')
    const [formData, setFormData] = useState({
        board: sendeducationData?.board ? sendeducationData?.board : "", 
        passing_out_year: sendeducationData?.passing_out_year ? sendeducationData?.passing_out_year : "", 
        school_medium: sendeducationData?.school_medium ? sendeducationData?.school_medium : "", 
        english_marks: sendeducationData?.english_marks ? sendeducationData?.english_marks : "",
        math_marks: sendeducationData?.math_marks ? sendeducationData?.math_marks : "",
        marks: sendeducationData?.marks ? sendeducationData?.marks : "",
        university: "",
        course: "",
        specialization: "",
        course_type: "",
        startingYear: "",
        endingYear: "",
        grading_system: "",
        profile_id: "",
        course_duration: "",
        type:sendactiontype,
        id:sendeducationData?.id ? sendeducationData?.id : "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const sendData = (e) => {
        e.preventDefault()
        formData.profile_id = profileId
        addEducationData(formData)
        closeEducationModal()
    }

    // deleteducation
    const deleteducation = () => {
        const getId = sendeducationData?.id
        setShowModal(true)
        setTextmessage('Are you sure you want to delete this education?')
        const sendData = {
            "profile_id":profileId ? profileId : '',
            "id":getId, 
            "type":"delete"
        }
        setDeleteEducation(sendData)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }
    // deleteducation

    useEffect(() => {
        // Update formData based on edutype
        if (edutype === 'higherSecondary') {
            setFormData({ ...formData, education: "12th" });
        } else if (edutype === 'secondary') {
            setFormData({ ...formData, education: "10th" });
        } else if (edutype === 'belowten') {
            setFormData({ ...formData, education: "Below 10th" });
        }
    }, [edutype]);

    return (
        <>
                 
            <Form action="POST" className="form-cover" onSubmit={sendData}>
                <Form.Group className="mb-3">
                    <Form.Label>Education<span className='text-danger'>*</span></Form.Label>
                    <Form.Select name="Education" disabled>
                        {edutype === 'higherSecondary' && 
                            <option value="12th" hidden>12th</option>
                        }
                        {edutype === 'secondary' && 
                            <option value="10th" hidden>10th</option>
                        }
                        {edutype === 'belowten' && 
                            <option value="Below 10th" hidden>Below 10th</option>
                        }
                    </Form.Select>
                </Form.Group>
                {edutype !== 'belowten' ? 
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Board<span className='text-danger'>*</span></Form.Label>
                            <Form.Select name="board" value={formData.board} onChange={handleChange} required>
                                <option selected hidden value=''>Select board</option>
                                <option disabled>-----All India-----</option>
                                <option value="CISCE(ICSE/ISC)">CISCE(ICSE/ISC)</option>
                                <option value="Diploma">Diploma</option>
                                <option value="National Open School">National Open School</option>
                                <option value="IB(International Baccalaureate)">IB(International Baccalaureate)</option>
                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chhattisgarh">Chhattisgarh</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Passing out year<span className='text-danger'>*</span></Form.Label>
                            <Form.Select name="passing_out_year" value={formData.passing_out_year} onChange={handleChange} required>
                                <option selected hidden value=''>Select passing out year</option>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                                <option value="2013">2013</option>
                                <option value="2012">2012</option>
                                <option value="2011">2011</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>School medium<span className='text-danger'>*</span></Form.Label>
                            <Form.Select name="school_medium" value={formData.school_medium} onChange={handleChange} >
                                <option selected hidden value=''>Select medium</option>
                                <option value="Assamese / Asomiya">Assamese / Asomiya</option>
                                <option value="Bengali / Bangla">Bengali / Bangla</option>
                                <option value="English">English</option>
                                <option value="Gujarati">Gujarati</option>
                                <option value="Hindi">Hindi</option>
                                <option value="Kannada">Kannada</option>
                                <option value="Kashmiri">Kashmiri</option>
                                <option value="Konkani">Konkani</option>
                                <option value="Malayalam">Malayalam</option>
                                <option value="Manipuri">Manipuri</option>
                                <option value="Marathi">Marathi</option>
                                <option value="Oriya">Oriya</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Marks<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="text" name="marks" value={formData.marks} onChange={handleChange} placeholder="% marks of 100 maximum" maxLength={3} autoComplete='off' required />
                        </Form.Group>

                        {edutype !== 'secondary' ? 
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>English marks</Form.Label>
                                    <Form.Control type="text" name="english_marks" value={formData.english_marks} onChange={handleChange} placeholder="Marks (out of 100)" maxLength={3} autoComplete='off' required />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Maths marks</Form.Label>
                                    <Form.Control type="text" name="math_marks" value={formData.math_marks} onChange={handleChange} placeholder="Marks (out of 100)" maxLength={3} autoComplete='off' required />
                                </Form.Group>
                            </> : ''
                        }
                    </>
                :''}         
                <div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
                    <Button variant="danger" onClick={closeEducationModal} className='rounded-pill px-4'>Cancel</Button>
                    <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
                </div>
            </Form>
        </>
    )
}
