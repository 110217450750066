import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { callAxios, getProfileData } from '../../utils/utils';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
import $ from "jquery";
import { Confirm } from './Confirm';

export const Certification = ({ showCertificationModal, closeCertificationModal, setProfileData, profileId, setShowCertificationModal, isUpdateAction, updateData,setIsLoading }) => {
	console.log(isUpdateAction, updateData)
	const [certification_name, set_certification_name] = useState('');
	const [certification_provider, set_certification_provider] = useState("");
	const [certification_completion_id, set_certification_completion_id] = useState("");
	const [certification_url, set_certification_url] = useState("");
	const [is_certification_expired, set_is_certification_expired] = useState(false);
	const [certification_validity_from_Year, set_certification_validity_from_Year] = useState("");
	const [certification_validity_from_Month, set_certification_validity_from_Month] = useState("");
	const [certification_validity_to_Year, set_certification_validity_to_Year] = useState("");
	const [certification_validity_to_Month, set_certification_validity_to_Month] = useState("");
	


	useEffect(() => {
		if (isUpdateAction) {
			if(updateData?.is_certification_expired === 1){
				const certification_validity_from = updateData?.certification_validity_from.split(" ");
				set_certification_validity_from_Year(certification_validity_from[1])
			set_certification_validity_from_Month(certification_validity_from[0])
			} else{
				const certification_validity_from = updateData?.certification_validity_from.split(" ");
				const certification_validity_to = updateData?.certification_validity_to.split(" ");
				set_certification_validity_from_Year(certification_validity_from[1])
			set_certification_validity_from_Month(certification_validity_from[0])
			set_certification_validity_to_Year(certification_validity_to[1])
			set_certification_validity_to_Month(certification_validity_to[0])
			}
			
			
			set_certification_name(updateData?.certification_name);
			set_certification_provider(updateData?.certification_provider);
			set_certification_completion_id(updateData?.certification_completion_id)
			set_certification_url(updateData?.certification_url)
			set_is_certification_expired(updateData?.is_certification_expired)
			
			
		} else {
			set_certification_name("");
			set_certification_provider("");
			set_certification_completion_id("")
			set_certification_url("")
			set_is_certification_expired("")
			set_certification_validity_from_Year("")
			set_certification_validity_from_Month("")
			set_certification_validity_to_Year("")
			set_certification_validity_to_Month("")
		}
	}, [updateData]);

	const handleCertificationSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true)
		setShowCertificationModal(false);
		let formData;

		if (isUpdateAction) {
			if (is_certification_expired) {
				formData = {
					profile_id: profileId,
					certification_name: certification_name,
					certification_provider: certification_provider, certification_completion_id: certification_completion_id,
					certification_url: certification_url,
					is_certification_expired: is_certification_expired, certification_validity_from: certification_validity_from_Month + " " + certification_validity_from_Year,
					type: "update",
					id: updateData?.id
				}
			} else {
				formData = {
					profile_id: profileId,
					certification_name: certification_name,
					certification_provider: certification_provider, certification_completion_id: certification_completion_id,
					certification_url: certification_url,
					is_certification_expired: is_certification_expired, certification_validity_from: certification_validity_from_Month + " " + certification_validity_from_Year,
					certification_validity_to: certification_validity_to_Month + " " + certification_validity_to_Year,
					type: "update",
					id: updateData?.id
				}
			}
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/update-delete-certification", formData, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
					setProfileData(await getProfileData(profileId,setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
					
				} else {
					setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		} else {
			if (is_certification_expired) {
				formData = {
					profile_id: profileId,
					certification_name: certification_name,
					certification_provider: certification_provider, certification_completion_id: certification_completion_id,
					certification_url: certification_url,
					is_certification_expired: is_certification_expired, certification_validity_from: certification_validity_from_Month + " " + certification_validity_from_Year
				}
			} else {
				formData = {
					profile_id: profileId,
					certification_name: certification_name,
					certification_provider: certification_provider, certification_completion_id: certification_completion_id,
					certification_url: certification_url,
					is_certification_expired: is_certification_expired, certification_validity_from: certification_validity_from_Month + " " + certification_validity_from_Year,
					certification_validity_to: certification_validity_to_Month + " " + certification_validity_to_Year

				}
			}
			callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/add-certification", formData, "post").then(async (socialResponse) => {
				if (socialResponse.data.statusCode === 200) {
					setProfileData(await getProfileData(profileId, setIsLoading))
					toast.success(socialResponse.data.message, {
						position: 'bottom-center'
					})
					
					
				} else {
					setProfileData(await getProfileData(profileId, setIsLoading))
					toast.error("something went wrong!", {
						position: 'bottom-center'
					})
				}

			})
		}

	}


	// Show Delete Profile Modal
	const [showDeleteProfile, setShowDeleteProfile] = useState(false);
	const closeDeleteProfile = () => setShowDeleteProfile(false);
	const openDeleteProfile = () => setShowDeleteProfile(true);

	useEffect(() => {

		if (is_certification_expired) {
			// document.getElementById("durationTo").style.display = "none";

			$(".validityCertification").hide();
		} else {


			$(".validityCertification").show();
		}
	}, [is_certification_expired])

	const checkISCurrently = (event) => {
		// console.log(event.target.checked)
		set_is_certification_expired(event.target.checked);
	}
	return (
		<>
			<Modal show={showCertificationModal} centered size='lg' onHide={closeCertificationModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
				<Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
					<div className="close-wrapper text-end">
						<Button variant="link" onClick={closeCertificationModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
					</div>

					<div className="heading-wrapper mb-4">
						<div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
							<span className='widgetTitle text-black fs-5 fw-semibold'>Certifications</span>
							{/* This button will show only when you click to edit the details */}
							{isUpdateAction?
							<span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0 pointer' onClick={openDeleteProfile}>Delete</span>
							:null}
						</div>
						<p className='subTitle fs-14'>Add details of Certifications you have achieved/completed</p>
					</div>

					<Form action="" className="form-cover" onSubmit={handleCertificationSubmit}>
						<Form.Group className="mb-3">
							<Form.Label>Certification name<span className='text-danger'>*</span></Form.Label>
							<Form.Control type="text" value={certification_name} onChange={(e) => { set_certification_name(e.target.value) }} placeholder="Please enter your certification name" autoComplete='off' required />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Certification provider<span className='text-danger'>*</span></Form.Label>
							<Form.Control type="text" value={certification_provider} onChange={(e) => { set_certification_provider(e.target.value) }} placeholder="Please mention your certification provider" autoComplete='off' required />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Certification completion ID</Form.Label>
							<Form.Control type="text" value={certification_completion_id} onChange={(e) => { set_certification_completion_id(e.target.value) }} placeholder="Please mention your course completion ID" autoComplete='off' />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Certification URL</Form.Label>
							<Form.Control type="url" value={certification_url} onChange={(e) => { set_certification_url(e.target.value) }} placeholder="Please mention your certification URL" autoComplete='off' />
						</Form.Group>

						<Form.Group>
							<Form.Label>Certification validity</Form.Label>
							<div className="row flex-lg-nowrap align-items-lg-center gx-3">
								<div className="flex-fill">
									<div className="row g-2">
										<div className="col-6">
											<Form.Group className="mb-3">
												<Form.Select name="CertificationMonth" value={certification_validity_from_Month} onChange={(e) => { set_certification_validity_from_Month(e.target.value) }}>
													<option selected hidden value=''>MM</option>
													<option value="January">January</option>
													<option value="February">February</option>
													<option value="March">March</option>
													<option value="April">April</option>
													<option value="May">May</option>
													<option value="June">June</option>
													<option value="July">July</option>
													<option value="August">August</option>
													<option value="September">September</option>
													<option value="October">October</option>
													<option value="November">November</option>
													<option value="December">December</option>
												</Form.Select>
											</Form.Group>
										</div>

										<div className="col-6">
											<Form.Group className="mb-3">
												<Form.Select name="CertificationYear" value={certification_validity_from_Year} onChange={(e) => { set_certification_validity_from_Year(e.target.value) }}>
													<option selected hidden value=''>YYYY</option>
													<option value="2024">2024</option>
													<option value="2023">2023</option>
													<option value="2022">2022</option>
													<option value="2021">2021</option>
													<option value="2020">2020</option>
													<option value="2019">2019</option>
													<option value="2018">2018</option>
													<option value="2017">2017</option>
													<option value="2016">2016</option>
													<option value="2015">2015</option>
													<option value="2014">2014</option>
													<option value="2013">2013</option>
													<option value="2012">2012</option>
													<option value="2011">2011</option>
												</Form.Select>
											</Form.Group>
										</div>
									</div>
								</div>

								<div className="col-12 col-lg-1 fw-semibold text-center mb-3 validityCertification">To</div>
								<div className="flex-fill validityCertification">
									<div className="row g-2">
										<div className="col-6">
											<Form.Group className="mb-3">
												<Form.Select name="CertificationMonthTo" value={certification_validity_to_Month} onChange={(e) => { set_certification_validity_to_Month(e.target.value) }}>
													<option selected hidden value=''>MM</option>
													<option value="January">January</option>
													<option value="February">February</option>
													<option value="March">March</option>
													<option value="April">April</option>
													<option value="May">May</option>
													<option value="June">June</option>
													<option value="July">July</option>
													<option value="August">August</option>
													<option value="September">September</option>
													<option value="October">October</option>
													<option value="November">November</option>
													<option value="December">December</option>
												</Form.Select>
											</Form.Group>
										</div>

										<div className="col-6">
											<Form.Group className="mb-3">
												<Form.Select name="CertificationYearTo" value={certification_validity_to_Year} onChange={(e) => { set_certification_validity_to_Year(e.target.value) }}>
													<option selected hidden value=''>YYYY</option>
													<option value="2024">2024</option>
													<option value="2023">2023</option>
													<option value="2022">2022</option>
													<option value="2021">2021</option>
													<option value="2020">2020</option>
													<option value="2019">2019</option>
													<option value="2018">2018</option>
													<option value="2017">2017</option>
													<option value="2016">2016</option>
													<option value="2015">2015</option>
													<option value="2014">2014</option>
													<option value="2013">2013</option>
													<option value="2012">2012</option>
													<option value="2011">2011</option>
												</Form.Select>
											</Form.Group>
										</div>
									</div>
								</div>

							</div>
						</Form.Group>
						<Form.Group className="mb-3">
							<div className="form-check d-flex align-items-center gap-2">
								<input type="checkbox" className="form-check-input border-2 border-secondary" id="workingStatus" checked={is_certification_expired} style={{ width: '18px', height: '18px' }} value={is_certification_expired} onChange={(e) => { checkISCurrently(e) }} />
								<label className="form-check-label ms-1 pt-1" for="workingStatus">This certification does not expire</label>
							</div>
						</Form.Group>
						
						<div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
							<Button variant="danger" onClick={closeCertificationModal} className='rounded-pill px-4'>Cancel</Button>
							<Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>

			<Confirm showDeleteProfile={showDeleteProfile} closeDeleteProfile={closeDeleteProfile} profileId={profileId} id={updateData?.id} message={updateData?.certification_name } setShowCertificationModal={setShowCertificationModal} setShowDeleteProfile={setShowDeleteProfile} setIsLoading={setIsLoading} setProfileData={setProfileData} type="certification" />
		</>

	)
}

