import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CameraButton from "./CameraButton";
import LeaveRoomButton from "./LeaveRoomButton";
import MicButton from "./MicButton";
import SwitchToScreenSharingButton from "./SwitchToScreenSharingButton";
import SwitchImg from "../../resources/images/screen_sharing.svg";
import { app, database, auth } from "../../firebaseConfig";
import axios from "axios";
import * as qs from "qs";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
// env import
import { environment } from "../../environment";
import { getCountryCallingCode } from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import { setClientInfo } from "../../store/actions";

import "react-toastify/dist/ReactToastify.css";
import { callAxios } from "../../utils/utils";
import LeaveRoomButtonPrivate from "./LeaveRoomButtonPrivate";

const VideoButtons = (props) => {
  ////console.log(props)
  const {
    room,
    connectOnlyWithAudio,
    comingFrom,
    setClientInfoAction,
    camera,
    isMicMuted,
    RMID,
    docId,
    user,
    callType
  } = props;

  const [participants, setParticipants] = useState([]);
  const [callStatus, setCallStatus] = useState("");

  const queryConstraintsRoomStatus = [];

  // console.log(connectOnlyWithAudio)
  queryConstraintsRoomStatus.push(where("roomId", "==", RMID));
  const RoomStatusQuery = query(
    collection(database, environment.REACT_APP_FIREBASE_DB_CALLS),
    ...queryConstraintsRoomStatus
  );

  useEffect(() => {
    onSnapshot(RoomStatusQuery, (querySnapshot) => {
      querySnapshot.forEach((docu) => {
        const id = docu.id;
        const data = docu.data();
        data["id"] = docu.id;
        setCallStatus(data.callStatus);
      });
    });
  }, [])

  //console.log("CallStatus from VideoButtons::", callStatus);

  const handleRoomDisconnection = async () => {
    // console.log(docId)
    localStorage.setItem("InitiatedCallTime", "");
    localStorage.setItem("callType", "");
    localStorage.setItem("callDocId", "");
    localStorage.setItem("callReceived", "");
    const formRef = doc(
      database,
      environment.REACT_APP_FIREBASE_DB_CALLS,
      docId
    );
    updateDoc(formRef, {
      callStatus: "completed",
    }).then(() => {
      // console.log("room completed");
      localStorage.setItem("InitiatedCallTime", "");
      localStorage.setItem("callType", "");
      localStorage.setItem("callDocId", "");
      localStorage.setItem("callReceived", "");
      room.disconnect();
    })
      .catch((err) => {
        //console.log(err);
      });
  };

  // Close Sidebar
  function closeChatNav() {
    document.getElementById("chatSidenav").style.transform =
      "translateX(300px)";
    document.getElementById("main").style.marginRight = "0";
    //document.body.style.backgroundColor = "white";
    document.getElementById("chat").classList.remove("active");
  }

  function openPrticpntNav() {
    //closeChatNav();
    //if (comingFrom === "private") closeLeadSidenav();
    document.getElementById("partcipantSidenav").style.transform =
      "translateX(0px)";
    document.getElementById("main").style.marginRight = "300px";
    //document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    document.getElementById("partcipant-private").classList.add("active");
  }

  function closePrticpntNav() {
    document.getElementById("partcipantSidenav").style.transform =
      "translateX(300px)";
    document.getElementById("main").style.marginRight = "0";
    //document.body.style.backgroundColor = "white";
    document.getElementById("partcipant").classList.remove("active");
  }

  function closeLeadSidenav() {
    document.getElementById("LeadDetailsSidenav").style.transform =
      "translateX(300px)";
    document.getElementById("main").style.marginRight = "0";
    //document.body.style.backgroundColor = "white";
    document.getElementById("leadDetails").classList.remove("active");
  }

  return (
    <>
      {/* <ToastContainer /> */}
      <nav className="navbar navbar-expand navbar-dark bg-dark topbar sticky-top">
        <div className="container-fluid px-md-3">
          {/* <a className="navbar-brand navbar-timer" href="#">
            <i className="bi bi-stopwatch"></i>
          </a><span id="callTimer"></span> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav ms-auto navbar-nav-scroll align-items-center ">
              {/* {comingFrom ? (
                <li className="nav-item dropdown me-2">
                  <a className="nav-link text-white text-center">
                    {streamStatus == 3 ? (
                      <div>
                        <i className="bi bi-circle-fill now-speak" style={{ color: "#7ed959" }}></i>
                        <div className="menu-title">Now speak</div>
                      </div>
                    ) : streamStatus == 2 ? (
                      <div>
                        <i className="bi bi-circle-fill your-reached" style={{ color: "#ffe300" }}></i>
                        <div className="menu-title">Your Reached</div>
                      </div>
                    ) : (
                      <div>
                        <i className="bi bi-circle-fill in-process" style={{ color: "var(--theme-color2)" }}></i>
                        <div className="menu-title">In Process</div>
                      </div>
                    )}
                  </a>
                </li>
              ) : (
                <li></li>
              )} */}

              {/* {comingFrom ? (
                <li className="nav-item dropdown me-2">
                  <a
                    className="nav-link text-white text-center"
                    onClick={() => openLeadSidenav()}
                    id="leadDetails"
                  >
                    <i className="bi bi-card-list"></i>
                    <div className="menu-title">Lead Details</div>
                  </a>
                </li>
              ) : (
                <li></li>
              )} */}
              {/* <li className="nav-item dropdown me-2 dropdown share-link">
                <a
                  className="nav-link text-white text-center"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  onClick={() => {
                    getShareLink();
                  }}
                  aria-expanded="false"
                >
                  <i className="bi bi-link-45deg"></i>
                  <div className="menu-title">Share Link</div>
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end caret-indicator p-3"
                  style={{ width: "300px" }}
                >
                  <div className="input-group">
                    <input
                      type="text"
                      id="clipboard-area"
                      className="form-control"
                      placeholder="URL"
                      aria-label="Copy URL"
                      aria-describedby="basic-addon2"
                      value={shareUrl}
                      readOnly
                    />
                    <button
                      className="input-group-text pointer"
                      onClick={handleCopyText}
                    >
                      <i className="bi bi-files"></i>
                    </button>
                  </div>
                </ul>
              </li> */}

              {/* {comingFrom === true && comingFrom != "Bdm" && contactNo?.length >= 10 ? (
                <li className="nav-item me-md-2">
                  <a
                    className="nav-link text-white text-center mobile-call"
                    onClick={() => makeACall()}
                    data-bs-toggle="modal"
                    data-bs-target="#audioModel1"
                  >
                    <i className="bi bi-phone"></i>
                    <div className="menu-title">Call Client's Mobile</div>
                  </a>
                </li>
              ) : null} */}

              {/* <li className="nav-item dropdown me-2">
                <a
                  className="nav-link text-white text-center"
                  onClick={openChatNav}
                  id="chat"
                >
                  <i className="bi bi-chat-dots"></i>
                  <div className="menu-title">Chat</div>
                </a>
              </li> */}
              {callStatus === "in-progress" ?
                <>
                  <li className="nav-item dropdown me-2">
                    <a
                      className="nav-link text-white text-center"
                      onClick={openPrticpntNav}
                      id="partcipant-private"
                    >
                      <i className="bi bi-people"></i>
                      <div className="menu-title">Participants</div>
                    </a>
                  </li>

                  <li className="nav-item dropdown me-2 d-none d-lg-block">
                    {!connectOnlyWithAudio && (
                      <CameraButton room={room} camera={camera} />
                    )}
                  </li>
                  <li className="nav-item me-2 d-none d-lg-block">
                    <MicButton room={room} isMicMutEd={isMicMuted} />
                  </li>
                  <li className="nav-item me-3 d-none d-lg-block">
                    <SwitchToScreenSharingButton room={room} />
                  </li>
                </>
                : null}
              {/* <li className="nav-item dropdown me-2">
                  <a
                  className="nav-link text-white text-center"
                  onClick={openPrticpntNav}
                  id="partcipant-private"
                  >
                  <i className="bi bi-people"></i>
                  <div className="menu-title">Participants</div>
                  </a>
                </li>

              <li className="nav-item dropdown me-2 d-none d-lg-block">
                {!connectOnlyWithAudio && (
                  <CameraButton room={room} camera={camera} />
                )}
              </li>
              <li className="nav-item me-2 d-none d-lg-block">
                <MicButton room={room} isMicMutEd={isMicMuted} />
              </li>
              <li className="nav-item me-3 d-none d-lg-block">
                <SwitchToScreenSharingButton room={room} />
              </li> */}

              <li className="nav-item me-3 d-none d-lg-block">
                <LeaveRoomButtonPrivate
                  room={room}
                  comingFrom={comingFrom}
                  handleRoomDisconnection={handleRoomDisconnection}
                  participants={participants}
                  callType={callType}
                  user={user}
                  roomId={RMID}
                  docId={docId}
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <nav className="navbar navbar-expand navbar-dark bg-dark topbar fixed-bottom d-lg-none">
        <div className="container-fluid px-md-4">
          <ul className="navbar-nav mx-auto navbar-nav-scroll align-items-center ">
            {callStatus === "in-progress" ?
              <>
                <li className="nav-item dropdown me-2">
                  {!connectOnlyWithAudio && (
                    <CameraButton room={room} camera={camera} />
                  )}
                </li>
                <li className="nav-item me-2">
                  <MicButton room={room} isMicMutEd={isMicMuted} />
                </li>
                <li className="nav-item">
                  <SwitchToScreenSharingButton room={room} />
                </li>
              </> : null}
            <li className="nav-item ms-3">
              <LeaveRoomButtonPrivate
                room={room}
                comingFrom={comingFrom}
                handleRoomDisconnection={handleRoomDisconnection}
                participants={participants}
                user={user}
                roomId={RMID}
                docId={docId}
                callType={callType}
              />
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setClientInfoAction: (info) => dispatch(setClientInfo(info)),
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(VideoButtons);
