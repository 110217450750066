import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

import { useEffect, useRef, useState } from 'react';
import { environment } from '../environment';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';
import { sendMessageUsingDataChannel } from './twilioUtils';
import { callAxios, callAxiosFile } from './utils';
import { useSelector } from 'react-redux';

const MediaRecorderComponent = ({ room, isClientOnCall, setTranscriptData, roomId }) => {
  const isClientOnCall_ = useSelector((state) => state.clientOnCall);
    const [user, loading, error] = useAuthState(auth);
    const mediaRecorderRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isTranscribing, setisTranscribe] = useState(true)
    let fullTranscript = [];
   
  // console.log("is client on call:::::",isClientOnCall_)
    useEffect(() => {
        const initialize = async () => {
            await register(await connect());
          
            
            const participantDiv = document.getElementById(room?.localParticipant?.identity + "-inner");
            if (participantDiv) {
                const audioElement = participantDiv.getElementsByTagName('audio')[0];
                const audioStream = audioElement?.srcObject;
                
                if (audioStream) {
                    mediaRecorderRef.current = new MediaRecorder(audioStream, { mimeType: 'audio/wav' });
                     recordAudio()
                }
                
            }

            
        };
        if(isClientOnCall_){
            initialize();
         }
        

        // Cleanup function
        return () => {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
                mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
            }
        };
    }, [room,isClientOnCall_]);

 

    function recordAudio() {
        let chunks = [];
        localStorage.setItem("isRecorderStarted",true)
        
        mediaRecorderRef.current.start();
      
        const interval = setInterval(() => {
            mediaRecorderRef.current.stop();
        }, 6000);
      
        mediaRecorderRef.current.onstart = () => {
           console.log('started');
          
          // console.log("Media Status::",localStorage.getItem("isRecorderStarted"))
        };
      
        mediaRecorderRef.current.ondataavailable = (e) => {
          if(localStorage.getItem("isRecorderStarted") === "true"){
            chunks.push(e.data);
          }
            
          
        };
    
        mediaRecorderRef.current.onstop = async (e) => {
          if(localStorage.getItem("isRecorderStarted") === "true"){
            // console.log('recorder stopped');
            const blob = new Blob(chunks, { type: 'audio/wav' });
            uploadAudio(blob);
        
            chunks = [];
            // console.log("in transcribeing", isTranscribing)
            if (isTranscribing) {
                // console.log("in transcribeing", isTranscribing)
                mediaRecorderRef.current.start();
            } else {
                // console.log("in interval")
              clearInterval(interval);
            }
          } else{
            console.log('stopped');
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
          }
        }
      }

    function uploadAudio(blob) {
        const dlUrl = URL.createObjectURL(blob).split('/');
        const filename = `${dlUrl[3]}.wav`;
        const file = new File([blob], filename);
      
        const formData = new FormData();
        formData.append('audio', file);
        formData.append('identity',room.localParticipant.identity);
        formData.append('roomsid',room.sid)
        formData.append('email',user?.email)
        formData.append('roomId', roomId)

        const identityArray = room.localParticipant.identity.split("_");
        let speaker;
        let userType;
        if(identityArray[1] === "CL"){
          speaker = identityArray[2]
          userType = "Client"
        }
        else{
           speaker = identityArray[2]
            userType = identityArray[1].replace(" ","_")
        }
      
        callAxiosFile(environment.REACT_APP_Whisper_Url+'uploadAudio',formData)
            .then(async (response) => {
              const res = await response.data;
              if (res.success) {
                const notinclude = [" [Music]"," [inaudible]"," you", " [BLANK_AUDIO]"]
                if(!notinclude.includes(res.transcript))
                {
                    
                    sendTranscript(res.transcript);
                    // console.log("transcript::",res.transcript,room.localParticipant.identity)
                }
                 
              }
            //   console.log(res);
            })
            .catch((err) => {
              // console.log('err', err);
            });
      };

      function sendTranscript(transcript) {
       
        sendMessageUsingDataChannel(transcript, true,"transcript")
      }
    //   console.log("client on call:::",isClientOnCall)
    // console.log("Media Status outside ::",localStorage.getItem("isRecorderStarted"))
    
    return (
        <></>
    );
};

export default MediaRecorderComponent;
