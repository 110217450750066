import React, { useState, useEffect } from 'react';
import './Attendance.css';
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { callAxios, convertMiliToHours } from "../utils/utils";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setBreakData } from '../store/actions';
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";


export const AdminAttendance = (props) => {
	const { setBreakDataAction } = props;

	// const user = useSelector((state) => state.userInfo);
	const [user, loading, error] = useAuthState(auth);
	const [attendance, setAttendance] = useState([]);
	const [breakData, setBreak] = useState([]);
	const [getMyAttendance, setMyAttendance] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [refreshKey, setRefreshKey] = useState(0);
	let navigate = useNavigate();
	useEffect(() => {
		if (loading) return;
		if (!user) navigate("/");
		if (user) {
			async function fetchData() {
				callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance?uid=' + user.uid, '', "get").then((attendance) =>
					setMyAttendance(attendance));

				callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance/get-last-log?uid=' + user.uid, '', "get").then((attendance) =>
					setAttendance(attendance));

				callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance/getBreak?uid=' + user.uid, '', "get").then((userBreak) =>
					setBreak(userBreak));

				callAxios(process.env.REACT_APP_API_URL + 'api/v1/users', '', "get").then((users) =>
					setAllUsers(users));
			}
			fetchData();
		}
	}, [user, refreshKey])
	//console.log("Break Info::", breakData)

	const loginHandler = async () => {
		console.log("check In action");

		const loginResponse = await callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance', { uid: user.uid, action: 'Check-In' }, "post")
		const attendance = await callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance/get-last-log?uid=' + user.uid, '', "get");
		setAttendance(attendance);
		setRefreshKey(oldKey => oldKey + 1)
		if (loginResponse.data.statusCode === 401) {
			toast.error(loginResponse.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	}

	const logoutAction = async () => {
		console.log("check Out action");

		//await callAxios(process.env.REACT_APP_API_URL+'api/v1/attendance',{uid:user.uid, action: 'Check-Out'}, "post" )
		const attendance = await callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance/get-last-log?uid=' + user.uid, '', "get");
		setAttendance(attendance);
		const logoutTime = new Date().getTime();
		const loggedInTime = new Date(attendance.data.createdAt).getTime();
		const totalWorkingHours = convertMiliToHours(loggedInTime, logoutTime).split(":");
		console.log(totalWorkingHours)
		const totalHours = totalWorkingHours[0] > 0 ? totalWorkingHours[0] + ' Hours' : totalWorkingHours[0] + ' Hour';
		const totalMinutes = totalWorkingHours[1] > 0 ? totalWorkingHours[1] + ' Minutes' : totalWorkingHours[1] + ' Minute';
		if (totalWorkingHours[0] < 5) {
			const heading = "You have completed <span className='text-danger fw-semibold mx-1'>" + totalHours + " and " + totalMinutes + "</span> shift till now. It is sorter than even required half day duration. <br> <b>If you'll logout now, you'll consider as absent today</b>.";
			document.getElementById("setPopUpHeading").innerHTML = heading;
			console.log(totalHours, totalMinutes, heading)
		}
		if (totalWorkingHours[0] > 5 && totalWorkingHours[0] > 8) {
			const heading = "You have completed <span className='text-danger fw-semibold mx-1'>" + totalHours + " and " + totalMinutes + "</span> shift till now. It is sorter than full day duration. <br> <b>If you'll logout now, you'll consider as half day for today</b>.";
			document.getElementById("setPopUpHeading").innerHTML = heading;
			console.log(totalHours, totalMinutes, heading)
		}
		setRefreshKey(oldKey => oldKey + 1)
	}

	const logoutHandler = async () => {
		const isCheckedBox = document.getElementById("logoutCheck").checked;
		console.log(isCheckedBox)
		if (isCheckedBox === true) {
			await callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance', { uid: user.uid, action: 'Check-Out' }, "post")
			const attendance = await callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance/get-last-log?uid=' + user.uid, '', "get")
			setAttendance(attendance);
			setRefreshKey(oldKey => oldKey + 1)
			document.getElementById('closeModal').click();
		}
		else {
			console.log("please check box");
			document.getElementById("logoutCheck").style.border = "2px solid red";
		}
	}

	const breakStartHandler = async () => {
		await callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance/break', { uid: user.uid, action: 'Break-In' }, "post")
		const breakData = await callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance/getBreak?uid=' + user.uid, '', "get");
		setBreak(breakData);
		//setBreakDataAction(breakData.data.action);
		setRefreshKey(oldKey => oldKey + 1)
		setBreakData(breakData.data.action);

	}

	const breakStopHandler = async () => {
		await callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance/break', { uid: user.uid, action: 'Break-Out' }, "post");
		const breakData = await callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance/getBreak?uid=' + user.uid, '', "get");
		setBreak(breakData);
		console.log(breakData.data.action)
		setRefreshKey(oldKey => oldKey + 1)
		setBreakData(breakData.data.action);
	}
	let button, breakButton;


	if (typeof attendance.data !== 'undefined') {
		if (attendance.data === '') {
			console.log(attendance.data, attendance.status)
			button = <button className="btn btn-primary rounded-1 px-4 fw-semibold" onClick={() => loginHandler()}>Login</button>
		} else {
			button = attendance.data.action === 'Check-Out' ? <button className="btn btn-primary rounded-1 px-4 fw-semibold" onClick={() => loginHandler()}>Login</button> : <button className="btn btn-danger rounded-1 px-4 fw-semibold" data-bs-toggle="modal" data-bs-target="#shiftDetailsModal" onClick={() => logoutAction()}>Logout</button>

			if (attendance.data.action === "Check-In") {
				if (breakData.data === '') {
					breakButton = <button className="btn btn-primary rounded-1 px-4 fw-semibold" onClick={() => breakStartHandler()}>Start Break</button>
				} else {
					breakButton = breakData.data.action === 'Break-Out' ? <button className="btn btn-primary rounded-1 px-4 fw-semibold" onClick={() => breakStartHandler()}>Start Break</button> : <button className="btn btn-primary rounded-1 px-4 fw-semibold" onClick={() => breakStopHandler()}>Stop Break</button>
				}
			}
			else {
				breakButton = "";
			}
		}
	}
	const filterUserAttendanceHandler = async (event) => {
		const uid = event.target.value;
		console.log(process.env.REACT_APP_API_URL + 'api/v1/attendance?uid=' + uid)
		const allAttendance = await callAxios(process.env.REACT_APP_API_URL + 'api/v1/attendance?uid=' + uid, '', "get");
		setMyAttendance(allAttendance)
		//console.log("Another user:", allAttendance)
	}

	return (

		<div className='attendance-page py-5'>
			{/* <ToastContainer /> */}
			<div className='container'>
				<div className="alert alert-primary mb-4" role="alert">
					<div className="row align-items-center">
						<div className='col-lg-6'>
							<div className='text-secondary'>
								<span className='text-body fw-bold border-2 border-end border-secondary pe-3 me-2'>Attendance</span>
								You are only allowed single login and logout during a shift.
							</div>
						</div>
						<div className='col-lg-6 mt-3 mt-lg-0'>
							<div className='btn-wrpr d-flex justify-content-lg-end gap-5'>
								<select className='form-control' onChange={filterUserAttendanceHandler}>
									<option value=""> -Select Employee-</option>
									{allUsers.data ?
										allUsers.data.map((item, index) => (
											<option value={item.uid}> {item.display_name}</option>
										)) : null
									}
								</select>
								{breakButton} {button}
								{/* <button className='btn btn-primary rounded-1 px-4 fw-semibold'>Login</button> */}

								{/* Shift Detail's trigger */}
								{/* <button type="button" className="btn btn-danger rounded-1 px-4 fw-semibold" data-bs-toggle="modal" data-bs-target="#shiftDetailsModal">Logout</button> */}

								{/* Start: Shift Detail's Modal */}
								<div className="modal fade" id="shiftDetailsModal" tabIndex="-1" aria-labelledby="shiftDetailsModalLabel" aria-hidden="true">
									<div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
										<div className="modal-content text-body">
											<div className="modal-header" style={{ backgroundColor: '#eee' }}>
												<h1 className="modal-title fs-5" id="exampleModalLabel">Today Shift Detail</h1>
												<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
											</div>
											<div className="modal-body py-4">
												<div className='mb-4' id="setPopUpHeading"></div>
												<div className="form-check mb-2">
													<input className="form-check-input" type="checkbox" value="" id="logoutCheck" />
													<label className="form-check-label" htmlFor="logoutCheck">
														Are you sure you want to log out as absent for today?
													</label>
												</div>
												{/* <div className="d-flex align-items-center flex-wrap gap-2 border-top border-bottom border-grey py-3 my-3">
													<span className='text-danger fw-semibold'>You have not update any work for today.</span>
													<span className='small text-secondary'>Please update about your today's work first</span>
													<button className='btn btn-primary rounded-1 px-4 py-2 fw-semibold'>Update Work</button>
												</div>
												<div className="form-check mb-3">
													<input className="form-check-input" type="checkbox" value="" id="check2" />
													<label className="form-check-label" htmlFor="check2">
														I have not done any work today. So there is no update for today.
													</label>
												</div> */}
												<div className="row align-items-center">
													<div className="col-lg-6">
														<div className='warning-label small text-secondary'>* For Half Day: A minimum of 5 hours are required.</div>
														<div className='warning-label small text-secondary'>* For Full Day: A minimum of 9 hours are required.</div>
													</div>
													<div className="col-lg-6 mt-4 mt-lg-0">
														<div className="d-flex align-items-center gap-3">
															<button className='btn btn-primary rounded-1 px-4 py-2 fw-semibold' onClick={() => logoutHandler()}>Logout</button>
															<button id="closeModal" className='btn btn-danger rounded-1 px-4 py-2 fw-semibold' data-bs-dismiss="modal">Cancel</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* End: Shift Detail's Modal */}
							</div>
						</div>
					</div>
				</div>

				<div className='list-item-cover p-4'>
					{getMyAttendance.data ?
						getMyAttendance.data.map((item, index) => (
							<div className='list-item d-grid gap-1 gap-md-2 border-1 border-bottom border-secondary py-3' key={index}>
								<div className='date fw-semibold'>{new Date(item.createdAt).toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' })}</div>
								<div className='check-in'>{item.action}</div>
								<div className='check-time'>{new Date(item.createdAt).toLocaleString('default', { hour: '2-digit', minute: '2-digit' })}</div>
								{item.time_duration != null ?
									<div className='total-time' >
										{item.time_duration.split(":")[0] > 0 ? item.time_duration.split(":")[0] + ' Hours' : item.time_duration.split(":")[0] + ' Hour'}
										{' and '}
										{item.time_duration.split(":")[1] > 0 ? item.time_duration.split(":")[1] + ' Minutes' : item.time_duration.split(":")[1] + ' Minute'}
									</div>
									: null}
								<div className='day-status'>
									{item.status === "Absent" ?
										<span className='badge rounded-pill text-bg-warning px-3 me-2'>{item.status}</span>
										: null}
									{item.status === "Half Day" ?
										<span className='badge rounded-pill text-bg-warning px-3 me-2'>{item.status}</span>
										: null}
									{item.status === "Full Day" ?
										<span className='badge rounded-pill text-bg-success px-3 me-2'>{item.status}</span>
										: null}

								</div>
							</div>
						)) :
						null
					}
				</div>
			</div>
		</div>
	)
}
const mapDispatchToProps = (dispatch) => {
	return {
		setBreakDataAction: (userBreaks) => dispatch(setBreakData(userBreaks)),
	};
};

const mapStoreStateToProps = (state) => {
	return {
		...state,
	};
};

export default connect(
	mapStoreStateToProps,
	mapDispatchToProps
)(AdminAttendance);
