import React, { useEffect, useMemo, useState } from "react";
import searchIcon from "../resources/images/search.svg";
import notebookPenIcon from "../resources/images/notebook-pen.svg";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { callAxios, callCrmApi, convertReadable, convertReadableTime, getIndexBySystemUserId, secondsToHMS, timeDiffDaysHourMinSec } from "../utils/utils";
import { environment } from "../environment";
import { setUserInfo } from "../store/actions";
import Card from "./Card";
import DateRange from "../Reports/DateRange";
import SheelaModal from "./modal/SheelaModal";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import userData from '../utils/Users.json';
import PhoneCallCard from "../Cards/PhoneCallCard";
import AppointmentCard from "../Cards/AppointmentCard";
import EmailCard from "../Cards/EmailCard";
import TaskCard from "../Cards/TaskCard";
import NoteCard from "../Cards/NoteCard";



const CallHistoryDetailsIncoming = ({ setLoggedinUser }) => {
  const {state} = useLocation();

 
  const { leadId, topic,opportunityId } = state;
  // console.log(state)
  // console.log(opportunityId)
  // console.log("state value::", leadId,topic)
  // For Sidebar list selected card
  const [updates, setUpdates] = useState([]);
  const [active, setActive] = useState(null);
  const [isSheelaView, setSheelaView] = useState(false);
  const [summary, setSummary] = useState('')
  const [noteData, setNoteData] = useState([]);

  // For Read more read less functionality
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <>
        {isReadMore ? text.slice(0, 300) : text}
        {text.length > 300 &&
          <span onClick={toggleReadMore} className="text-info pointer">
            {isReadMore ? "...Read More" : " Read Less"}
          </span>
        }
      </>
    );
  };

  const [accessToken, setAccessToken] = useState(null);
  const [limit, setLimit] = useState(10);
  const [searchType, setSearchType] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  const [clientName, setClientName] = useState(null);
  const [sheelaData, setDataForSheela] = useState({});
  const [callDetails, setCallDetails] = useState([]);
  const [isDetailsLoading, setDetailsLoading] = useState(true);
  const [allData, setData] = useState([]);
  const [description, setDescription] = useState([]);
  const [isShowCalendar, setIsShowCalendar] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };
      setLoggedinUser(userInfo);
    }
  }, [user, loading]);

  useEffect(() => {
 
    if (!leadId) navigate("/lead-listing");
    
  }, [leadId]);

  useEffect(() => {
    setStartDate(new Date());
    setEndDate(new Date());
  }, [accessToken])

  useEffect(() => {
    // getMeetingData(new Date(), true);
    const searchType = $("#searchType").val();
    const date = new Date();
    const syear = date.getFullYear();
    const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const sday = String(date.getDate()).padStart(2, '0');
    const formattedStartDate = `${syear}-${smonth}-${sday}`;
    getCallDetails(leadId, topic)
    getDataByDate(formattedStartDate, searchType)
    getAnnotation(leadId, opportunityId)
  }, [user]);

  //   console.log(charData)
  //  console.log(description)
  const getCallDetails = (leadid, topic) => {
    
    setDetailsLoading(true);
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/get-call-details-by-id", { leadId: leadid,topic:topic,userType:localStorage.getItem("userRole"), email:user?.email }, "post").then((callDetails) => {
      setCallDetails(callDetails.data.details);
      setDetailsLoading(false);
    })
  }

  const analyzeData = (summary) => {
    setDataForSheela(summary)
    //console.log("sheela here")
    document.getElementById("SheelaModal").style.display = "block";
    document.getElementById("SheelaModal").classList.add("show");
    setSheelaView(true);
  }

  const getDataByDate = (startdate, searchType) => {
    // setStartDate(date)

    setIsLoading(true);
    // console.log(startdate, enddate)
    callAxios(environment.REACT_APP_API_URL_Live + "api/v2/crm/get-call-list-by-id", { isDateFilter: true, startdate: startdate, leadId: leadId }, "post").then((meetingData) => {
      if (meetingData.data.leads.length > 0) {
        setData(meetingData.data);
        setDescription(meetingData.data.leads[0].new_skillsetjobdescription)
        setIsLoading(false);
        setDetailsLoading(false)
      } else {
        setData(meetingData.data);
        setIsLoading(false);
        setDetailsLoading(false)
        setCallDetails([])
      }
    })
  }





  const renderPoints = (analysisData) => {
    return (
      <ul>
        {Object.entries(analysisData).map(([key, value]) => (
          <li key={key}>
            <strong className="text-capitalize">{key.replace(/_/g, ' ')}</strong>: {typeof value === 'object' ? renderPoints(value) : value}
          </li>
        ))}
      </ul>
    );
  };

  //  console.log(callDetails)
  let callDetailsS;
  if (callDetails && callDetails.length > 0) {
    callDetailsS = callDetails.map((item, index) => {
    

      return (
        <div className="attn-details-wrapper p-3 mt-3" key={index}>
          <div className="row gx-2 gy-1 mb-2">
            <div className="col-lg-8">
              {item.createdAt ?
                <div className="client-name fs-5 fw-semibold text-light text-capitalize mb-1">{convertReadable(item.createdAt) + ' ' + convertReadableTime(item.createdAt)}</div>
                : null}
              {item.caller_name ?
                <div className="text-capitalize">Called By <span className="attn-name">{item.caller_name}</span></div>
                : null}
            </div>
           
            <div className="col-lg-4 text-lg-end">
            {item.call_status ?
                <div className="duration text-light text-opacity-75 mb-1">Call Status - <span className="call-time text-warning text-capitalize">{item.call_status}</span></div>
                : null}
              {item.call_duration ?
                <div className="duration text-light text-opacity-75 mb-1">Call Duration - <span className="call-time">{secondsToHMS(item.call_duration)}</span></div>
                : null}
            </div>
          </div>

          {Object.entries(item.summary).length > 0 ?
            <div className="requrement-wrapper mb-3" style={{ height: "125px", overflowY: "scroll" }}>
              <div className="title-label fs-18 text-light">Summary:</div>
              <div className="description small text-light text-opacity-75">
                <>
                  <ReadMore>

                    {Object.entries(item.summary.abstractSummary).map(([key, value]) => (
                      <li key={key}>
                        <strong className="text-capitalize">{key.replace(/_/g, ' ')}</strong>: {typeof value === 'object' ? renderPoints(value) : value}
                      </li>
                    ))}
                  </ReadMore>
                </>
              </div>
            </div> : null}

          <div className="compose-media-page row align-items-center g-3">
            <div className="col-lg-9">
              {Object.entries(item.summary).length > 0 ?
                <div className="meeting-details-page d-flex align-items-center gap-3">
                  <div className="comment-part position-relative flex-fill text-white text-opacity-75 rounded py-2 px-2 px-md-3" style={{ backgroundColor: 'var(--custom-bg)' }}>
                    <div className="d-flex flex-column flex-xl-row align-items-xl-center justify-content-between gap-2">
                      <div className="info-sec fs-12">
                        <div className="attn-name fw-medium">{user?.displayName}</div>
                        <p className="m-0">I've completed my work. You can now review all the details.</p>
                      </div>
                      <div className="btn-wrapper">
                        <button className="btn btn-sm btn-success text-nowrap pointer" onClick={() => analyzeData(item.summary)}>Sheela's Update</button>
                      </div>
                    </div>
                  </div>
                  <div className="img-wrapper">
                    <img className="img-fluid" src={require('../resources/images/meeting-girl.png')} alt="Meeting User" style={{ width: '27px', minWidth: '27px' }} />
                  </div>
                </div>
                : null}
            </div>

            <div className="col-lg-3">
              <div className="icon-wrapper d-flex gap-3">
                {item.call_type === 1 ?
                  <div className="group-call">
                    <img src={require('../resources/images/group-call.png')} alt="Group Calling" className="img-fluid" style={{ maxWidth: '40px' }} />
                  </div>
                  :
                  <div className="user-call">
                    <img src={require('../resources/images/user-on-call.png')} alt="Single User Call" className="img-fluid" style={{ maxWidth: '28px' }} />
                  </div>
                }
                {item.is_recording_available === 1 ?
                  <div className="play-icon pointer" onClick={() => { handleMeetingViewAndDownload(item.call_sid) }}>
                    <i className="bi bi-play-circle-fill text-white lh-1" style={{ fontSize: '40px' }}></i>
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </div>
      )
    })
  } else {
    callDetailsS = "No call details found";
  }

  // listen recording
  const closePlayer = () => {
    var audioSrc = document.getElementById("audioSrc");
    audioSrc.pause();
    document.getElementById("audio-player").style.display = "none";
  }

  const handleMeetingViewAndDownload = async (callSid) => {
    //console.log("composition Id:", compositionId, " | type:", type)
    // let mediaResponse;
    // if(type === 'view'){

    const mediaResponse = await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/twilio/get-recording-by-sid', { cid: callSid }, "post");

    if (mediaResponse.data.statusCode === 400) {
      toast.error("Recording not found!!!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      const url = mediaResponse.data.url;
      var audioSrc = document.getElementById("audioSrc");
      const isSupp = audioSrc.canPlayType("video/mp4");
      if (isSupp == "") {
        audioSrc.src = url;
      } else {
        audioSrc.src = url;
      }
      audioSrc.load();
      audioSrc.play();
      document.getElementById("audio-player").style.display = "block";
    }
  }
  // console.log(callDetails)

  const updateDateFilter = (val) => {
    if (val.length > 0) {
      $("#dateFilterField").hide();
      $("#basic-addon2").removeClass("d-none");
    } else {
      $("#dateFilterField").show();
      $("#basic-addon2").addClass("d-none");
    }
  }

  const backToMainScreen = () =>{
    navigate("/clients-call");
  }

  const getAnnotation = async (leadId, opportunityId) => {
    setClientName("Sam Speaker");
    const annotationPayload = JSON.stringify({
       "operation":"fetchannotations",
       "leadid": leadId && leadId.length > 0?leadId:"",
       "opportunityid":opportunityId && opportunityId.length >0?opportunityId:""
   })
 
 
 
 
   const annotationResponse = await callCrmApi(annotationPayload)
    
    //console.log(annotation.data.leadData.new_leadid);
    const fullData = annotationResponse.data.data;
    // console.log("data length:::", fullData)
       
     const TotalEle = fullData.length;
       for(let i=0; i<fullData.length;i++){
         const getArrIndex = getIndexBySystemUserId(userData,fullData[i]._createdby_value);
         // const user = await Users.findOne({ where:{systemuserid: fullData[i]._createdby_value}});
         if(getArrIndex > -1){
           fullData[i]['display_name'] = userData[getArrIndex].display_name;
           fullData[i]['image'] = userData[getArrIndex].image;
          
         }
               if (i + 1 === fullData.length) {
                 ////console.log(TotalEle);
           // Last one.
         } else {
           // Not last one.
           ////console.log(item.createdon, index, index+1,arrayObj[index+1].createdon)
           const diff = timeDiffDaysHourMinSec(new Date(fullData[i].createdon),new Date(fullData[i+1].createdon));
           const totalTime = timeDiffDaysHourMinSec(new Date(fullData[i].createdon),new Date(fullData[TotalEle-1].createdon));
          
           ////console.log("Time Diff::",timeAgo)
           fullData[i]['timeAgo'] = diff;
           fullData[i]['totalTime'] = totalTime;
           fullData[i]['opportunityId'] = "";
           fullData[i]['leadId'] = leadId;
 
 
         }
        
       }
       //console.log(fullData)
      setNoteData(fullData);
     
      
     
     // setIsloading(false);
     } 
    //  console.log(noteData)
  return (
    <div className="client-listing-page call-history-details py-3">
      {/* <ToastContainer /> */}
      <div className="container-xxl">
        <div className="row align-items-center g-2 mb-3">
          <div className="col-lg-4">
          
            <div className="main-heading text-white text-opacity-75">{"Sam Speaker"}</div>
          </div>

          <div className="col-lg-8">
            <div className="row justify-content-lg-end g-2">
              

          
            </div>
          </div>
        </div>

        {/* CAM & BDM Client Info */}
        <div className="client-detail-wrapper">
        <Tabs>
      <TabList>
        <Tab>Call History</Tab>
        <Tab>CRM</Tab>
        
      </TabList>

      <TabPanel>
      <div className="client-info-details p-3">
                <div className="fs-4 fw-medium text-warning"><i className="bi bi-person-vcard"></i> Call History Details</div>
                <div className="info-card-wrapper">
                  {isDetailsLoading ? (
                    <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3 mt-3" id="lead-list-spinner">
                      <div className="lead-list-spinner"></div>
                      {" "} Please wait ....
                    </div>
                  ) : callDetailsS}
                </div>
              </div>
      </TabPanel>
      <TabPanel>
      <div className="client-info-details p-3 pb-4">
      <div style={{ position: 'sticky', top: '0', zIndex: 1000, background:"rgb(111 111 111)" }} className="p-2">
                               

                                
                                {description ?
                                <div className="requrement-label text-warning text-opacity-50 mb-1">Requirement Details</div>
                                :null}
                                <div className="client-requrement text-light">{description}</div>
                            </div>
      <div className="cam-bdm-attn-details mt-4">
      <div className="cam-bdm-wrapper">
                    {
                        noteData.length > 0 ?

                        noteData?.map((item, index) => (
                          <div className="cam-bdm-card d-flex gap-2 gap-lg-3 mt-4">
                            <div className="user-wrapper text-center" style={{
                                  height: "40px",
                                  width: "40px"
                                }}>
                              <img
                                src={
                                  item.image
                                    ? item.image
                                    : require("../resources/images/Avatar.png")
                                }
                                alt=""
                                className="border border-1 rounded-circle shadow overflow-hidden"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  maxWidth: "40px",
                                  maxHeight:"40px"
                                }}
                              />
                              {item.timeAgo?
                              <div className='time-ago text-white text-opacity-75 mt-1 text-capitalize' style={{fontSize:"9px"}}>{item.timeAgo}</div>
                              :null }
                              {/* |
                              {item.timeAgo?
                              <div className='time-ago text-white text-opacity-75 mt-1 text-capitalize' style={{fontSize:"9px"}}>{item.timeAgo}</div>
                              :null } */}
                            </div>
                            {item.activitytypecode === "phonecall"?
                           
                              <PhoneCallCard item={item} clientName={clientName} />
                              :
                              item.activitytypecode === "appointment"?
                              <AppointmentCard item={item} clientName={clientName}  userData={userData} />
                              :
                              item.activitytypecode === "email"?
                              <EmailCard item={item} clientName={clientName}  userData={userData} />
                              :
                              item.activitytypecode === "task"?
                              <TaskCard item={item} clientName={clientName} />
                              :
                              item.objecttypecode === "lead" || item.objecttypecode === "opportunity"?
                              <NoteCard item={item} clientName={clientName} />
                              :
                            null}
                            {/* <ReadMore item={item} /> */}
                         
                          </div>
                        ))
                      :"No data found"
                      }
                  </div>
                  </div>
                  </div>
      </TabPanel>
      
    </Tabs>
          {/* <div className="row g-3">
            <div className="col-md-5 col-lg-4">
              <div className="client-list-section">
                {isLoading ? (
                  <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3" id="lead-list-spinner">
                    <div className="lead-list-spinner"></div>
                    {" "} Please wait ....
                  </div>
                ) :
                  allData && allData.leads.length > 0 ?
                    allData.leads.map((item, id) => {
                      return <Card startDate={startDate} item={item} id={id} user={user} active={active} setActive={setActive} setCallDetails={setCallDetails} setDetailsLoading={setDetailsLoading} />
                    })
                    :
                    <p className="text-light mt-2"> Data not found!!</p>
                }
              </div>
            </div>

            <div className="col-md-7 col-lg-8">
              <div className="client-info-details p-3">
                <div className="fs-4 fw-medium text-warning"><i className="bi bi-person-vcard"></i> Call History Details</div>
                <div className="info-card-wrapper">
                  {isDetailsLoading ? (
                    <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3 mt-3" id="lead-list-spinner">
                      <div className="lead-list-spinner"></div>
                      {" "} Please wait ....
                    </div>
                  ) : callDetailsS}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div id="audio-player" className="modal" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <video width="400" controls autoPlay id="audioSrc">
                <source src="" type="video/mp4" ></source>
                Your browser does not support HTML video.
              </video>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal" onClick={closePlayer}>Close</button>
            </div>
          </div>
        </div>
      </div>

      <SheelaModal setSheelaView={setSheelaView} isSheelaView={isSheelaView} sheelaData={sheelaData} />
    </div >
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(CallHistoryDetailsIncoming);
