import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setConnectOnlyWithAudio, setIdentity, setRoomId } from '../store/actions'
import JoinRoomButton from './JoinRoomButton'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import JoinRoomInput from './JoinRoomInput'
import OnlyWithAudioCheckbox from './OnlyWithAudioCheckbox'
import RoomNotFoundMessage from './RoomNotFoundMessage'
import { checkIfRoomExists } from '../utils/twilioUtils'
import { useEffect } from 'react'

const JoinRoomContent = (props) => {
    //console.log(props.comingFrom)
    const { isRoomHost, setConnectOnlyWithAudioAction, connectOnlyWithAudio, setRoomIdAction, setIdentityAction, roomID } = props
    const [roomIdValue, setRoomIdValue] = useState("");
    const [nameValue, setNameValue] = useState("")
    const [showRoomNotFoundMessage, setShowRoomNotFoundMessage] = useState(false)
    //console.log(props.comingFrom)
    let navigate = useNavigate()
    //On change to Room ID  
    useEffect(() => {
        setRoomIdValue(roomID);
    }, []);

    const handleJoinToRoom = async () => {
        // add logic to join room 
        setIdentityAction(nameValue)
        if (!isRoomHost) {
            //Check if room exist and if yes then join
            const roomExists = await checkIfRoomExists(roomIdValue)
            if (roomExists) {
                setRoomIdAction(roomIdValue)
                //console.log(props.comingFrom, btoa(nameValue), nameValue);
                if(props.comingFrom ==="true"){
                    navigate('/sharedroom?sharedBy=cam&nm=' + btoa(nameValue) + '&id=' + btoa(roomIdValue));
                } 
                else if(props.comingFrom ==="Bdm"){
                    navigate('/sharedroom?sharedBy=bdm&nm=' + btoa(nameValue) + '&id=' + btoa(roomIdValue));
                }
                else{
                    navigate('/sharedroom?sharedBy=client&nm=' + btoa(nameValue) + '&id=' + btoa(roomIdValue));
                }
               
                
            } else {
                setShowRoomNotFoundMessage(true)
            }
        } else {
            setRoomIdAction(uuidv4())
            navigate('/room')
        }
    }

    return (
        <>
            <JoinRoomInput
                roomId={roomIdValue}
                setRoomIdValue={setRoomIdValue}
                nameValue={nameValue}
                setNameValue={setNameValue}
                isRoomHost={isRoomHost}
                comingFrom={props.comingFrom}
            />
            <OnlyWithAudioCheckbox
                setConnectOnlyWithAudio={setConnectOnlyWithAudioAction}
                connectOnlyWithAudio={connectOnlyWithAudio}
            />
            <RoomNotFoundMessage showRoomNotFoundMessage={showRoomNotFoundMessage} />
            <JoinRoomButton
                isRoomHost={isRoomHost}
                handleJoinToRoom={handleJoinToRoom}
            />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setConnectOnlyWithAudioAction: (onlyWithAudio) => dispatch(setConnectOnlyWithAudio(onlyWithAudio)),
        setIdentityAction: (identity) => dispatch(setIdentity(identity)),
        setRoomIdAction: (id) => dispatch(setRoomId(id))
    }
}

const mapStoreStateToProps = (state) => {
    return {
        ...state
    }
}


export default connect(mapStoreStateToProps, mapDispatchToProps)(JoinRoomContent)