import React, { useEffect, useState } from "react";
import { app, database, auth } from "../firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";

import { useNavigate } from "react-router-dom";
import "../LeadsListingPage/LeadsListingPage.css";
import "../Header/Header";
import { useAuthState } from "react-firebase-hooks/auth";

// env import
import { environment } from '../environment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { callAxios } from "../utils/utils";
import WonChart from "../Charts/WonChart";
import NmVsNotNNChart from "../Charts/NmVsNotNMChart";
import LeadSourceChart from "../Charts/LeadSourceChart";
import NmChart from "../Charts/NmChart";
import LeadVsOpportunity from "../Charts/LeadVsOpportunity";
import LeadChart from "../Charts/leadChart";
import Piechart from "../Charts/Piechart";
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";

const OpportunityReport = (props) => {
  const { setLoggedinUser } = props;
  const [isLoadingWonData, setisLoadingWonData] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [startDate, setStartDate] = useState(new Date());
  const [nmCount, setNmCount] = useState(0);
  const [outSideNmCount, setOutSideNmCount] = useState(0);
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [chartData3, setChartData3] = useState([]);
  const [chartData4, setChartData4] = useState([]);
  const [nmCountOpportunity, setNmCountOpportunity] = useState(0);
  const [outSideNmCountOpp, setOutSideNmCountOpp] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [leadSubmitToCRM, setLeadSubmitToCRM] = useState(0);
  const [leadSourceChart, setleadSourceChart] = useState([]);
  const [wonData, setWonData] = useState([]);
  let navigate = useNavigate();

  const getUserImage = async (email) => {
    if (email !== 'undefined') {
      ////console.log(email)
      const queryConstraintsCAM = [];
      queryConstraintsCAM.push(where("userType", "==", "CAM"));
      queryConstraintsCAM.push(where("email", "==", email));
      const camQuery = query(
        collection(database, environment.REACT_APP_USERS_DB),
        ...queryConstraintsCAM
      );
      const user = await getDocs(camQuery);
      ////console.log(user)
      let image = '';
      user.forEach(country => {
        image = country.data().profile_image;
      })
      return image;
    }
    else {
      return "";
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };
      setLoggedinUser(userInfo);
    }
  }, [user, loading]);

  const wonScore = async (date) => {
    setisLoadingWonData(true);
    var currentMonth = new Date(date);
    currentMonth.setDate(date.getDate());
    setStartDate(currentMonth)
    ////console.log(date) //get-opportunites-nm
    //const d = new Date(new Date(date).getFullYear(), new Date(date.setMonth(date.getMonth()+1)), 0);

    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const startMonth = new Date(date).getFullYear() + '-' + new Date(date).toLocaleString('default', { month: '2-digit' }) + '-01';
    const endMonth = new Date(date).getFullYear() + '-' + new Date(date).toLocaleString('default', { month: '2-digit' }) + '-' + new Date(lastDay).toLocaleString('default', { day: '2-digit' });

    const leadData = await callAxios(
      environment.REACT_APP_API_URL_Live +
      "api/v1/leads/get-lead-data",
      { startDate: startMonth, endDate: endMonth, apiKey: "nmlive" },
      "post"
    );

    const opportunityData = await callAxios(
      environment.REACT_APP_API_URL_Live +
      "api/v1/leads/get-opportunites-nm",
      { startDate: startMonth, endDate: endMonth, apiKey: "nmlive" },
      "post"
    );

    //console.log("NM - Lead Response::",leadData.data)
    //console.log("NM - Opportunity Response::",opportunityData.data)
    // //console.log(leadData.data.leadsourceData)

    if (leadData.status === 200) {
      const chartDataFirst = [
        {
          category: "How many total Leads we get direct in NM-Live? Submitted",
          values: leadData.data.submittedCount ? leadData.data.submittedCount[0].submitted : 0
        },
        {
          category: "How many total Leads we get direct in NM-Live? Not Submitted",
          values: leadData.data.notSubmittedCount ? leadData.data.notSubmittedCount[0].notSubmitted : 0
        }
      ];

      const chartDataSecond = [
        {
          category: "How many Leads submit to CRM by Client?",
          values: leadData.data.leadSubmitToCRM ? leadData.data.leadSubmitToCRM : 0
        },
        {
          category: "How many Leads which were not submitted by Client directly in CRM but these pending leads CAM submit in CRM on the behalf of client?",
          values: leadData.data.leadSubmitToCRMByCam ? leadData.data.leadSubmitToCRMByCam : 0
        },
        {
          category: "How many Leads are left only NM-Live?",
          values: leadData.data.leadsLeft ? leadData.data.leadsLeft : 0
        }
      ];

      const chartDataThird = [
        {
          category: "How many total Leads we get direct in CRM?",
          values: leadData.data.total ? leadData.data.total : 0
        },
        {
          category: "How many pending Leads in NM-Live submitted by CAM in CRM?",
          values: leadData.data.pendingleadSubmitToCRMByCam ? leadData.data.pendingleadSubmitToCRMByCam : 0
        }
      ];

      const chartDataFour = [
        {
          category: "How many total Leads qualified as opportunity in CRM?",
          values: leadData.data.leadsQualifiedAsOpportunity ? leadData.data.leadsQualifiedAsOpportunity : 0
        },
        {
          category: "How many total Leads we get direct in CRM?",
          values: leadData.data.nm ? leadData.data.nm : 0
        }
      ];
      //console.log(chartDataFirst)
      setWonData(opportunityData.data.opportinities);
      setNmCount(leadData.data.nm);
      setNmCountOpportunity(opportunityData.data.nm)
      setOutSideNmCountOpp(opportunityData.data.total)
      setOutSideNmCount(leadData.data.total);
      setleadSourceChart(leadData.data.leadsourceData);
      // for map plot
      setTotalLeads(leadData.data.submittedCount);
      setLeadSubmitToCRM(leadData.data.leadSubmitToCRM);
      setisLoadingWonData(false)
      setChartData1(chartDataFirst);
      setChartData2(chartDataSecond);
      setChartData3(chartDataThird);
      setChartData4(chartDataFour);
    }
  }

  useEffect(() => {
    wonScore(new Date())
  }, []);

  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  return (
    <div className="opportunity-reports-page py-3">
      <div className="container">
        <div className="row align-items-center g-2 mb-3">
          <div className="col-lg-6">
            <div className="main-heading text-white text-opacity-75">Opportunity Reports</div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-wrap align-items-center justify-content-lg-end gap-2">
            <label>Filter By Month</label>
            <DatePicker showIcon
              // selected={new Date()}
              selected={startDate}
              onChange={(date) => { wonScore(date) }}
              className="wonDatePicker mb-0"
              renderMonthContent={renderMonthContent}
              showMonthYearPicker
              dateFormat="yyyy-MM"
            /></div>
          </div>
        </div>

        {isLoadingWonData ?
          <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3" id="lead-list-spinner">
            <div className="lead-list-spinner"></div>
            {" "} Please wait ....
          </div> : 
          <div className="box-wrapper">
            <div className="amchart-wrapper">
              <div className="accordion" id="accordionExample-won-report">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="heading1">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBar-won-report" aria-expanded="false" aria-controls="collapseBar">
                      Lead Source Chart
                    </button>
                  </h2>
                  <div id="collapseBar-won-report" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample-won-report">
                    <div className="accordion-body rounded-bottom p-3">
                      <LeadSourceChart leadSourceChart={leadSourceChart} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="amchart-wrapper mt-3">
              <div className="accordion" id="accordionExample-won-report2">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="heading2">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBar-won-report2" aria-expanded="false" aria-controls="collapseBar2">
                    NM Vs Outside NM
                    </button>
                  </h2>
                  <div id="collapseBar-won-report2" className="accordion-collapse collapse show" aria-labelledby="heading2" data-bs-parent="#accordionExample-won-report2">
                    <div className="accordion-body rounded-bottom p-3">            
                      <NmVsNotNNChart nmCount={nmCount} outSideNmCount={outSideNmCount} />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="amchart-wrapper mt-3">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      NM Vs Outside & Opportunity Report
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body rounded-bottom p-3">
                      <div className="row g-6">
                        <div className="col-sm-6 col-lg-6">
                          <div className="chart-heading"></div>
                          <NmVsNotNNChart nmCount={nmCount} outSideNmCount={outSideNmCount} />
                        </div>
                        <div className="col-sm-6 col-lg-6">
                          <div className="chart-heading"></div>
                          <NmChart nmCount={nmCountOpportunity} outSideNmCount={outSideNmCountOpp} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="amchart-wrapper mb-lg-3">
              <div className="accordion" id="accordionExample-won-report3">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="heading3">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBar-won-report3" aria-expanded="false" aria-controls="collapseBar3">
                    NM Vs Outside NM Opportunites
                    </button>
                  </h2>
                  <div id="collapseBar-won-report3" className="accordion-collapse collapse show" aria-labelledby="heading3" data-bs-parent="#accordionExample-won-report3">
                    <div className="accordion-body rounded-bottom p-3">        
                      <NmChart nmCount={nmCountOpportunity} outSideNmCount={outSideNmCountOpp} />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="amchart-wrapper mt-3">
              <div className="accordion" id="accordionExample-won-report4">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="heading4">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBar-won-report4" aria-expanded="false" aria-controls="collapseBar4">
                      Won Report
                    </button>
                  </h2>
                  <div id="collapseBar-won-report4" className="accordion-collapse collapse show" aria-labelledby="heading4" data-bs-parent="#accordionExample-won-report4">
                    <div className="accordion-body rounded-bottom p-3">
                      <WonChart wonData={wonData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="amchart-wrapper mt-3">
              <div className="accordion" id="accordionExample-won-report5">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="heading5">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBar-won-report5" aria-expanded="false" aria-controls="collapseBar5">
                    Leads Vs Opportunity
                    </button>
                  </h2>
                  <div id="collapseBar-won-report5" className="accordion-collapse collapse show" aria-labelledby="heading5" data-bs-parent="#accordionExample-won-report5">
                    <div className="accordion-body rounded-bottom p-3">
                      <LeadVsOpportunity totalLeads={totalLeads} nmCountOpportunity={nmCountOpportunity} />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="amchart-wrapper mt-3">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Analysis Report
                    </button>
                  </h2>

                  <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body rounded-bottom p-3">
                      <div className="row g-3">
                        <div className="col-sm-6 col-lg-6">
                          <div className="chart-heading"></div>
                          <Piechart chartData={chartData1} mapId={"NM-Live-Direct"} color={["green", "red"]} />
                        </div>
                        <div className="col-sm-6 col-lg-6">
                          <div className="chart-heading"></div>
                          <Piechart chartData={chartData2} mapId={"NM-Live-Direct-1"} color={["#FF7F50", "#dc67ce", "yellow"]} />
                        </div>

                        {/* <div className="col-sm-6 col-lg-6">
                              <div className="chart-heading"></div>
                              <Piechart chartData={chartData3} mapId={"NM-Live-Direct-2"} color={["#008B8B","#dc67ce"]}/>
                            </div> */}

                        <div className="col-sm-6 col-lg-6">
                          <div className="chart-heading"></div>
                          <Piechart chartData={chartData4} mapId={"NM-Live-Direct-3"} color={["#8067dc", "#B22222"]} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(OpportunityReport);