import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import React, {useEffect, useState} from 'react'
import { database } from '../../firebaseConfig';
import { environment } from '../../environment';

const MicButton = ({room, isMicMutEd}) => {

  let isTrackEnabled;

  const [isMicMuted, setIsMicMuted] = useState(isMicMutEd);
  const [isFormSubmit, setFormSubmit] = useState('');
  const [isFirstTimeMute, setFirstTimeMute] = useState();
  
  if(room){
    isTrackEnabled = Array.from(room?.localParticipant?.audioTracks?.values())[0]?.track?.isEnabled;
  }
  
  const isMICMUTED = isTrackEnabled?false:true;

  useEffect(() => {
    
    const getMicStatus = async () => {
      if(room?.name){
      const q = query(collection(database,environment.REACT_APP_FIREBASE_DB), where("roomId", '==', room.name));
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;
          setIsMicMuted(data?.isMicMuted);
          setFormSubmit(data?.clientSubmitTime);
          setFirstTimeMute(data?.isFirstTimeMute);
         
        });
      });
     }
    }

    getMicStatus();

    
  }, [room?.name]);
   
  

    const handleMicButtonPressed = () => {
      //console.log("buttonClicked")
      isMICMUTED ? unmute() : mute();
        setIsMicMuted(!isMicMuted);
    };

    const mute = () => {
      //setIsMicMuted(isMICMUTED)
        // mute our microphone so other users will be not able to hear us
        room.localParticipant.audioTracks.forEach((localAudioTrackPublication) => {
            localAudioTrackPublication.track.disable();
        });
    };
    
    const unmute = () => {
        // turn on mic back
        //setIsMicMuted(isMICMUTED)
        room.localParticipant.audioTracks.forEach((localAudioTrackPublication) => {
            localAudioTrackPublication.track.enable();
        });
    };

    const updateMicStatus = async(roomId,value,type) =>{
      const formData = query(collection(database, "playerstreamer"), where('roomId', '==', roomId));
          const querySnapshot = await getDocs(formData);
         // console.log(querySnapshot)
          if(type === "first"){
            querySnapshot.forEach(async (docData) => {
              // console.log(docData.id, ' => ', docData.data());
               const formRef = doc(database, environment.REACT_APP_FIREBASE_DB, docData.id); 
                  updateDoc(formRef, {
                   isMicMuted:value,
                   isFirstTimeMute:false
                   
                 }).then(() => {
                  
               })
               .catch((error) => {
                   console.log(error);
                  
               })
           });
          } else {
            querySnapshot.forEach(async (docData) => {
              // console.log(docData.id, ' => ', docData.data());
               const formRef = doc(database, environment.REACT_APP_FIREBASE_DB, docData.id); 
                  updateDoc(formRef, {
                   isMicMuted:value,
                   
                   
                 }).then(() => {
                  
               })
               .catch((error) => {
                   console.log(error);
                  
               })
           });
    
        
          }
          
    
    }

    if(isFormSubmit && isFirstTimeMute){
      //console.log("executing unmuting...")
      unmute();
      if(room?.name){
      updateMicStatus(room.name,false,"first")
      }
  
    }

    

   
  //  const audioTrack = room?.localParticipant?.audioTracks.map((item,index) =>{
  //   console.log(item)
  //  })
   
  return (
    // <div className="video_button_container">
    //   <img
    //     src={isMicMuted ? MicButtonImgOff : MicButtonImg}
    //     onClick={handleMicButtonPressed}
    //     className="video_button_image"
    //   />
    // </div>
  
  
    <a className='nav-link text-white text-center' onClick={handleMicButtonPressed}>
      {isMICMUTED ? <i className="bi bi-mic-mute-fill"></i> : <i className="bi bi-mic-fill"></i>}
      <div className='menu-title'>Mic</div>
    </a>
  
    
    
  )
}

export default MicButton