import React, { useEffect, useState } from "react";
import Select from 'react-select';
import imgDefault from '../resources/images/Avatar.png';
import { callAxios } from "../utils/utils";
import { environment } from "../environment";
import { createGroupConversation } from "../utils/twilioConversation";

const options = [
    { value: 'Abhishek Kumar', label: 'Abhishek Kumar', role: 'Web Developer', image: imgDefault },
    { value: 'Vasheem Ahmad', label: 'Vasheem Ahmad', role: 'Web Designer', image: imgDefault },
    { value: 'Aman Kumar', label: 'Aman Kumar', role: 'Backend Developer', image: imgDefault },
    { value: 'Varun Kumar', label: 'Varun Kumar', role: 'React Developer', image: imgDefault },
    { value: 'Naseer Ahmad', label: 'Naseer Ahmad', role: 'Digital Marketing Expert', image: imgDefault },
    { value: 'Aiman Khan', label: 'Aiman Khan', role: 'Social Media Manager', image: imgDefault },
];

export const AddParticipants = (props) => {
    const [Participants, setSelectedOption] = useState(null);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        searchUsers();
    }, [])

    const searchUsers = (keyword) => {
        callAxios(
            environment.REACT_APP_API_URL_Live + "api/v1/users/get-all-participants",
            { keyword: keyword },
            "post"
        ).then((users) => {
            setUsers(users.data);
        })
    }
    let options_ = [];
    if (users.length > 0) {
        users.map((item) => {
            let shortName = item.display_name.split(' ').reduce((acc, subname) =>
                acc + subname[0], '')
            const options = { value: item.email, label: item.display_name, role: item.email, image: imgDefault, shortName: shortName };

            return options_.push(options)
        })

    }

    const createGroup = async () => {
        //console.log(Participants)
        createGroupConversation(Participants);
    }

    return (
        <div className="add-participants bg-white position-absolute end-0 shadow p-3">
            <form>
                <div className="mb-3">
                    <div className="form-label mb-1">Add User</div>
                    <Select isMulti classNamePrefix="custom-select"
                        // autoFocus
                        // defaultMenuIsOpen 
                        defaultValue={Participants}
                        onChange={setSelectedOption}
                        options={options_}
                        formatOptionLabel={info => (
                            <div className="user-wrapper d-flex align-items-center gap-2">
                                <div className="chip-img d-flex align-items-center justify-content-center rounded-circle overflow-hidden">
                                    <div className='user-shortname text-light'>{info.shortName}</div>
                                    {/* <img className="img-fluid w-100 object-fit-cover" src={info.image} alt="User" /> */}
                                </div>
                                <div className="info-wrapper">
                                    <div className='username text-truncate text-capitalize lh-sm'>{info.label}</div>
                                    <div className='user_role fs-12 text-truncate text-capitalize text-muted'>{info.role}</div>
                                </div>
                            </div>
                        )}
                        placeholder="Enter a name, email, or tag" />
                </div>

                {/* show When add via group participants */}
                {/* <div className="history_check-wrapper fs-12 mb-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="noChatHistory" required />
                        <label className="form-check-label" htmlFor="noChatHistory">Don't include chat history</label>
                    </div>
                    <div className="form-check d-flex align-items-center gap-2 mb-2">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="limitedChats" style={{ minWidth: '12px', marginTop: '-0.5px' }} />
                        <label className="form-check-label" htmlFor="limitedChats">
                            <span>Include history from the past number of days:</span>
                            <span><input type="text" className="border rounded-1 lh-sm ms-1 p-1" style={{ width: '30px', outline: 'none' }} /></span>
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="allChat" />
                        <label className="form-check-label" htmlFor="allChat">Include all chat history</label>
                    </div>
                </div> */}
            </form>
            <div className="btn-wrapper d-flex gap-2 justify-content-end">
                <button type="button" className="btn btn-sm btn-danger" onClick={props.closeCard} id="cancel-add-participant">Cancel</button>
                <button type="button" className="btn btn-sm btn-primary" onClick={createGroup}>Add</button>
            </div>
        </div>
    )
}
