import React, { useState } from "react";
import './email.scss';
import { Button, NavLink } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";

import { ThreadEmail } from "./ThreadEmail";

import { EmailListing } from "./EmailListing";
import { ReplyEmail } from "./ReplyEmail";

export const ReadEmail = ({showCurrentEmail, setShowCurrentEmail, currentEmail, setCurrentEmail, currentEmailBody, setCurrentEmailBody, setAttachments, setEmailsById, attachments, emailsById,setShowReply, showReply}) =>{
    const navigate = useNavigate();

    // Initialize state to track the active menu
    const [activeMenu, setActiveMenu] = useState(0);

    // For Sidebar list selected card
    const [active, setActive] = useState(0);

    // Handler function to update the active Menu
    const handleClick = (index) => {
        setActiveMenu(index);
    };

    const newMailPage = () => {
        // navigate('/new-mail');
    }

    // const [showCurrentEmail, setShowCurrentEmail] = useState(false);
    // const [currentEmail, setCurrentEmail] = useState("");
    // const [currentEmailBody, setCurrentEmailBody] = useState("");
    // const [attachments, setAttachments] = useState([]);
    // const [emailsById, setEmailsById] = useState([]);


    // const [showReply, setShowReply] = useState(false);
    const handleReplyClose = () => {setShowReply(false); };
    const handleReplyShow = () => setShowReply(true);

   

    return (
        <>
       
            <div className="col-md-8 col-xxl-9">
            
                
                <ThreadEmail showCurrentEmail={showCurrentEmail}  currentEmail={currentEmail} currentEmailBody={currentEmailBody} attachments={attachments} handleReplyShow={handleReplyShow} showReply={showReply} emailsById={emailsById} setShowReply={setShowReply}/>
            </div>
                        
        </>
    )
}