import axios from 'axios';
import { environment } from '../environment';
import { getAuth  } from "firebase/auth";

// console.log("auth info:::",const auth = getAuth();)
const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth() + 1; // Months are 0-based

export const callAxios = async(url, data, reqType) => {
  const auth = getAuth();
  const idToken = await auth?.currentUser?.getIdToken(true);
    const headers = {
      'Authorization':`Bearer ${idToken}`
    }
    if (reqType === 'post') {
        const postData = await axios.post( url, data, {headers:headers})
      // const postData = await axios.post( url, data)
        .catch((error) => {
          console.log(error);
        });
      return postData;
    }

    if (reqType === 'get') {
        
         const getData = await axios.get( url,{headers:headers})
        // const getData = await axios.get( url)
          .catch((error) => {
            console.log(error);
          });
        return getData;
      }

  }

  export const callAxiosFile = async(url, data) => {
    const auth = getAuth();
    const idToken = await auth?.currentUser?.getIdToken(true);
      const headers = {
        'Authorization':`Bearer ${idToken}`,
        'Content-Type': 'multipart/form-data'
      }
     
          const postData = await axios.post( url, data, {headers:headers})
     
          .catch((error) => {
            console.log(error);
          });
        return postData;
      
  
      
  
    }

  export const callAxiosWithMiddleWare = async(url, data, reqType) => {
    console.log("In authentication")
   
    const auth = getAuth();
    const idToken = await auth.currentUser.getIdToken();
      const headers = {
        'authorization':`Bearer ${idToken}`
      }
      if (reqType === 'post') {
        const postData = await axios.post( url, data,{headers:headers})
          .catch((error) => {
            console.log(error);
          });
        return postData;
      }
  
      if (reqType === 'get') {
          
          const getData = await axios.get( url,{headers:headers})
            .catch((error) => {
              console.log(error);
            });
          return getData;
        }
  
    }

export const toLoCale = async (timezone, date, type, dayNumber) => {
    if (type === 'current') {
      const gmtCurrentDateTime = new Date(date).toLocaleString(undefined, {
        timeZone: timezone,
      });
      const localDateTimeArray = gmtCurrentDateTime.split(',')[0].split('/');
      const localDate =
        localDateTimeArray[2] +
        '-' +
        localDateTimeArray[0].padStart(2, '0') +
        '-' +
        localDateTimeArray[1].padStart(2, '0');

      return localDate;
    } 

     else if (type === 'datetime') {
      const gmtCurrentDateTime = new Date(date).toLocaleString(undefined, {
        timeZone: timezone,
      });
      const localDateTimeArray = gmtCurrentDateTime.split(',')[0].split('/');
      const localDate =
        localDateTimeArray[2] +
        '-' +
        localDateTimeArray[0].padStart(2, '0') +
        '-' +
        localDateTimeArray[1].padStart(2, '0') +
        ' ' +
        new Date(gmtCurrentDateTime).getHours().toString().padStart(2, '0') +
        ':' +
        new Date(gmtCurrentDateTime).getMinutes().toString().padStart(2, '0') +
        ':' +
        new Date(gmtCurrentDateTime).getSeconds().toString().padStart(2, '0');

      return localDate;
    } 
    else if (type === 'utc') {
      const localToUTC = new Date(date).toISOString().split('T');
      const localToUTCArray = localToUTC[0] + ' ' + localToUTC[1].split('.')[0];
      return localToUTCArray;
    } 
    else if (type === 'dayAgo') {
      const gmtCurrentDateTime = new Date().toLocaleString(undefined, {
        timeZone: timezone,
      });

      const prev = new Date(gmtCurrentDateTime).getDate() - dayNumber;
      const currentDateKorea = gmtCurrentDateTime.split(',')[0].split('/');
      const previousDate =
        currentDateKorea[2] +
        '-' +
        currentDateKorea[0].padStart(2, '0') +
        '-' +
        prev.toString().padStart(2, '0');
      return previousDate;
    } else if (type === 'plusDate') {
      const gmtCurrentDateTime = new Date().toLocaleString(undefined, {
        timeZone: timezone,
      });

      const prev = new Date(gmtCurrentDateTime).getDate() + dayNumber;
      const currentDateKorea = gmtCurrentDateTime.split(',')[0].split('/');
      const nextDate =
        currentDateKorea[2] +
        '-' +
        currentDateKorea[0].padStart(2, '0') +
        '-' +
        prev.toString().padStart(2, '0');
      return nextDate;
    } else {
      const gmtCurrentDateTime = new Date(date + ' UTC').toLocaleString(
        undefined,
        {
          timeZone: timezone,
        },
      );
      const localDateTimeArray = gmtCurrentDateTime.split(',')[0].split('/');
      const localDate =
        localDateTimeArray[2] +
        '-' +
        localDateTimeArray[0].padStart(2, '0') +
        '-' +
        localDateTimeArray[1].padStart(2, '0');

      return localDate;
    }
  }

  export const convertMilisecondsToTime = (seconds, nanoseconds)=>{
    const fireBaseTime = new Date(
      seconds * 1000 + nanoseconds / 1000000,
    );
    const atTime = fireBaseTime.toLocaleTimeString();
    return atTime;
  }

  export const convertMiliToHours = (loggedInTime, logoutTime) => {
  const ms = (logoutTime - loggedInTime);
  let seconds = Math.floor(ms/1000);

  let minutes = Math.floor(seconds/60);

  let hours = Math.floor(minutes/60);

  seconds = seconds % 60;

  minutes = minutes % 60;

  // hours = hours < 10 ? "0" + hours : hours;

  // minutes = minutes < 10 ? "0" + minutes : minutes;

  // seconds = seconds < 10 ? "0" + seconds : seconds;

  return `${hours}:${minutes}:${seconds}`;
}

 export const convertDateTimeFromIso = ( dateTime ) =>{
  
    var hours = new Date(dateTime).getHours();
    var minutes = new Date(dateTime).getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = new Date(dateTime).getDate() + ' ' + new Date(dateTime).toLocaleString('default', { month: 'long' }) + ' ' + new Date(dateTime).getFullYear() + ' ' +hours + ':' + minutes + ' ' + ampm;
    return strTime;
 }

//  export const convertReadable = ( dateTime ) =>{
//   //console.log(new Date(dateTime) +"IST")
//   var strTime = new Date(dateTime).getDate() + ' ' + new Date(dateTime).toLocaleString('default', { month: 'short' }) + ' ' + new Date(dateTime).getFullYear();
//   return strTime;
// }
export const convertReadable = (dateTime) => {
  const options = {
    timeZone: 'Asia/Kolkata',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };
  
  const strTime = new Date(dateTime).toLocaleString('en-IN', options);
  return strTime;
};

// export const convertReadableTime = ( dateTime ) =>{
  
//   var hours = new Date(dateTime).getHours();
//   var minutes = new Date(dateTime).getMinutes();
//   var ampm = hours >= 12 ? 'PM' : 'AM';
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'
//   minutes = minutes < 10 ? '0'+minutes : minutes;
//   //var strTime = dateTime.split("T")[1].split(".")[0]+ ' ' + ampm;
//   var strTime= hours +":"+minutes + " " +ampm;
//   return strTime;
// }

export const convertReadableTime = (dateTime) => {
  const options = {
    timeZone: 'Asia/Kolkata',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };
  
  const strTime = new Date(dateTime).toLocaleString('en-IN', options);
  return strTime;
};

 export const getIndex = (List,roomId) =>{
  const index = List.findIndex(object => {
      return object.roomId === roomId;
    });

    return index;
}

export const getIndexByEmail = (List,email) =>{
  const index = List.findIndex(object => {
   // console.log(object.email.trim().toLowerCase())
      return object.email.trim().toLowerCase() === email;
    });

    return index;
}

export const getIndexByEmailOpportinites = (List,email) =>{
  const index = List.findIndex(object => {
      return object.emailaddress === email;
    });

    return index;
}

export const getIndexBySystemUserId = (List,systemuserid) =>{
  const index = List.findIndex(object => {
      return object.systemuserid === systemuserid;
    });

    return index;
}

export const convertFirebaseFormat = (createdAt) =>{
  const fireBaseTime = new Date(
    createdAt.seconds * 1000 + createdAt.nanoseconds / 1000000
  );
  const dateNew = fireBaseTime.toDateString();
  const atTime = fireBaseTime.toLocaleTimeString();
  return dateNew + " " + atTime;
}

export const timeDiffDaysHourMinSec = (date_future, date_now) => {
  //console.log("Utils::",date_future, date_now)
  let delta = Math.abs(date_future - date_now) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds

    let dayText = days > 1? " Days": " Day";

    let showDays = days > 0? days + dayText:'';

    let hourText = hours>0? " Hours": " Hour";

    let showHours = hours > 0? hours + hourText:'';

    let minuteText = minutes>0? " Minutes": " Minute";

    let showMinutes = minutes > 0? minutes + minuteText:'';

    return showDays +' '+ showHours + ' ' + showMinutes + ' Ago';
}

export const getToken = (token) =>{
  const Token = token.split(".");
  return Token[1]
  // const decodedToken = atob(Token[1]);
  // return JSON.parse(decodedToken).exp;
}

export const callCrmApi = async(data) =>{
  // const auth = getAuth();
    // const updatedToken = await auth?.currentUser?.getIdToken(true);
    //console.log(updatedToken)
  const config = {
    method: 'POST',
    url: environment.REACT_APP_CRM_LAMBDAURL_V1,
    // headers: {
    //   'Authorization': 'Bearer '+updatedToken,
    //   'Content-Type': 'application/json'
    // },
    data : data
  };


    const postData = await axios( config)
        .catch(async(error) => {
          console.log(error);
          
        });
      return postData;
    //return false;
}

export const convertSecondsToMinutes = (seconds) => {
  // console.log(seconds)
  const minutes = Math.floor(seconds / 60); // Get the number of full minutes
  const remainingSeconds = seconds % 60; // Get the remaining seconds

  // Pad minutes and seconds with 0 if less than 10
  const paddedMinutes = minutes.toString().padStart(2, "0") + "m";
  const paddedSeconds = remainingSeconds.toString().padStart(2, "0") + "s";

  return `${paddedMinutes} ${paddedSeconds}`;
}


export const secondsToHMS = (seconds) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = seconds % 60;

  var timeString = '';

  if (hours > 0) {
    timeString += (hours < 10 ? '0' : '') + hours + ":";
  }

  timeString += (minutes < 10 ? '0' : '') + minutes + ":" +
                (remainingSeconds < 10 ? '0' : '') + remainingSeconds;

  return timeString;
}

export const getProfileData = async(profileId, setIsLoading) =>{
  const response = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/get-profile-data", { profile_id: profileId }, "post");
  if(response.data.statusCode === 200 || response.data.statusCode ===201){
    setIsLoading(false)
  }
  return response.data.profileData;
}

export const getEmploymentData = async(profileId, setIsLoading) =>{
  const response = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/profile/get-profile-data", { profile_id: profileId }, "post");
  if(response.data.statusCode === 200 || response.data.statusCode ===201){
    setIsLoading(false)
  }
  return {
    profileData :response.data.profileData,
    employmentData :response.data.employments
    }
}

export const  searchByKeyValue = (data, key, value) => {
  return data.filter(item => item[key] === value);
}

export const ExperienceYear = () =>{
  const years = `
  <option value="0 Year">0 Year</option>
  <option value="1 Year">1 Year</option>
  <option value="2 Years">2 Years</option>
  <option value="3 Years">3 Years</option>
  <option value="4 Years">4 Years</option>
  <option value="5 Years">5 Years</option>
  <option value="6 Years">6 Years</option>
  <option value="7 Years">7 Years</option>
  <option value="8 Years">8 Years</option>
  <option value="9 Years">9 Years</option>
  <option value="10 Years">10 Years</option>
  <option value="11 Years">11 Years</option>
  <option value="12 Years">12 Years</option>
  <option value="13 Years">13 Years</option>
  <option value="14 Years">14 Years</option>
  <option value="15 Years">15 Years</option>
  <option value="16 Years">16 Years</option>
  <option value="17 Years">17 Years</option>
  <option value="18 Years">18 Years</option>
  <option value="19 Years">19 Years</option>
  <option value="20 Years">20 Years</option>
  <option value="21 Years">21 Years</option>
  <option value="22 Years">22 Years</option>
  <option value="23 Years">23 Years</option>
  <option value="24 Years">24 Years</option>
  <option value="25 Years">25 Years</option>
  <option value="26 Years">26 Years</option>
  <option value="27 Years">27 Years</option>
  <option value="28 Years">28 Years</option>
  <option value="29 Years">29 Years</option>
  <option value="30 Years">30 Years</option>
  <option value="30+ Years">30+ Years</option>

  `;
  return years;
    
}

export const generateYearOptions = () => {
  
  const years = [];
  for (let year = currentYear; year >= currentYear - 100; year--) {
    years.push(year);
  }
  return years;
};

export const generateYearOptionsFuture10Years = () => {
  
  const years = [];
  for (let year = currentYear + 10; year >= currentYear - 100; year--) {
    years.push(year);
  }
  return years;
};

export const generateMonthOptions = (selectedYear) => {
  const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];

  if (selectedYear === currentYear) {
    return months.filter(month => month.value <= currentMonth);
  }

  return months;
};

export const calculateDuration = (workFrom, workTill) => {
  // Parse the input dates
  const [startYear, startMonth] = workFrom.split('-').map(Number);
  const [endYear, endMonth] = workTill.split('-').map(Number);

  // Calculate the difference in months
  const totalMonths = (endYear - startYear) * 12 + (endMonth - startMonth);

  // Convert months to years and remaining months
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  // Format the result
  let result = '';
  if (years > 0) {
    result += `${years} years`;
  }
  if (months > 0) {
    if (result) result += ' and ';
    result += `${months} months`;
  }

  return result;
}

export const getCurrentYearMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based, so add 1
  console.log(`${year}-${month}`)
  return `${year}-${month}`;
}
