import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class NumberReceivedVsNotReceived extends Component {

  componentDidMount() {
   // console.log(this.props.submitted, this.props.notSubmitted)
     this.initChart()
  }
  componentDidUpdate(prevProps) {
    
    // if(prevProps) {
    //   this.chart.dispose();
    // }
    this.initChart();
    
  }
  
  initChart(){
    am4core.useTheme(am4themes_animated);

    var chart = am4core.create("number-received-not-received", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    
    chart.data = [
        {
            category: "Number Received",
            values: this.props.received?this.props.received:0
          },
          {
            category: "Not Received",
            values: this.props.notReceived?this.props.notReceived:0
          }
        
      ];


      var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "values";
        pieSeries.dataFields.category = "category";
        pieSeries.innerRadius = am4core.percent(50);
        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;

        let rgm = new am4core.RadialGradientModifier();
        rgm.brightnesses.push(-0.8, -0.8, -0.5, 0, - 0.5);
        pieSeries.slices.template.fillModifier = rgm;
        pieSeries.slices.template.strokeModifier = rgm;
        pieSeries.slices.template.strokeOpacity = 0.4;
        pieSeries.slices.template.strokeWidth = 0;
        pieSeries.colors.list = [
            am4core.color("#8067dc"),
            am4core.color("#dc67ce"),
        ];

        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        marker.height = 14;
        marker.width = 14;
        chart.legend.itemContainers.template.paddingTop = 2;
        chart.legend.itemContainers.template.paddingBottom = 0;
        chart.legend.itemContainers.template.fontSize = 14;
        
            
  }
    

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  
  render() {
    return <div id="number-received-not-received" style={{ width: "100%", height: "265px", margin: "0 auto" }} />;
  }

 
}

export default NumberReceivedVsNotReceived;
