import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import WarningModal from '../Components/Warningmodal';
import { environment } from '../../environment';
import { toast } from 'react-toastify';
import { getProfileData } from '../../utils/utils';

export const AddProfileSummary = ({ showProfileSummaryModal, closeProfileSummaryModal, toasterData, sendprofileSummary, sendprofileSummarydata,profileData, profileId, setShowProfileSummaryModal, setProfileData, setIsLoading }) => {
    console.log(profileId)
    const [showModal, setShowModal] = useState(false);
    const [textmessage, setTextmessage] = useState('');
    const [deleteSkill, setDeleteSkill] = useState('')
    const [countWords, setCountwords] = useState('')

    // countWords
    const maxWords = 1000; // Define the maximum number of characters

    // countWords

    const [formData, setFormData] = useState({
        profile_summary: "", 
    })

    const handleInputChange = (e) => {
        setCountwords(e.target.value)
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Calculate the number of characters remaining
    const charRemaining = maxWords - countWords.length;

    const handleSubmit = async(e) => {
        e.preventDefault();
        // Add your form submission logic here
        const postData = {
            "profile_id": profileId,
            "profile_summary": formData?.profile_summary,
            "type": "addupdate",
            "id": profileData?.profile_summary?.id ?profileData?.profile_summary?.id : ""
        }
        addData(postData)
    };
    // Edit Data

    // deleteskill
    const deleteskill = () => {
        const getId = sendprofileSummarydata?.id
        setShowModal(true)
        setTextmessage('Are you sure you want to delete this Profile Summary.')
        const sendData = {
            "profile_id":profileId ? profileId : '',
            "id":getId, 
            "type":"delete"
        }
        setDeleteSkill(sendData)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }
    // deleteskill

    // AddProfileSummary
    const addData = async(postData) => {
        setIsLoading(true)
        setShowProfileSummaryModal(false)
        var apiname = 'add-update-delete-profile-summary'
        try {
			const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/${apiname}`, postData, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const responsedata = response?.data
			if (responsedata?.statusCode === 200 || responsedata?.statusCode === 201 || response?.data) {              
				setProfileData(await getProfileData(profileId, setIsLoading))
                const sendMsg = responsedata?.message
                toast.success(sendMsg, {
					position: 'bottom-center'
				})
				
			}
		} catch (error) {
            setProfileData(await getProfileData(profileId, setIsLoading))
			console.log('Error: ', error)
		}
    }
    // AddProfileSummary

    useEffect(() => {
        setFormData({
            profile_summary: profileData?.profile_summary?.profile_summary || '',
          });
          setCountwords(profileData?.profile_summary?.profile_summary ? profileData?.profile_summary?.profile_summary : "")
    },[showProfileSummaryModal])

    return (
        <Modal show={showProfileSummaryModal} centered size='lg' onHide={closeProfileSummaryModal} backdrop="static" keyboard={false} dialogClassName='profileCustomModal small text-dark' contentClassName='rounded-4'>
            <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4">
                <div className="close-wrapper text-end">
                    <Button variant="link" onClick={closeProfileSummaryModal} className='text-decoration-none border-0 p-0'><i className="bi bi-x-lg fs-5 text-secondary"></i></Button>
                </div>
              

            	<WarningModal show={showModal} onClose={handleCloseModal} textmessage={textmessage} updateEmp={addData} deleteEmp={deleteSkill} />		
               
                <div className="heading-wrapper mb-4">
					<div className='widgetHead d-flex align-items-center justify-content-between mb-1'>
						<span className='widgetTitle text-black fs-5 fw-semibold'>Profile summary 
                        {profileData?.profile_summary?
                        <span className='profileComScore fs-6 fw-normal text-success ms-1'>Add 10%</span>
                        :null}
                        </span>
						{/* This button will show only when you click to edit the details */}
                        {profileData?.profile_summary?
							<span className='btn btn-link text-primary fs-6 fw-medium text-decoration-none lh-sm p-0 pointer' onClick={() => deleteskill(true)}>Delete</span>
                            :null}
							
					</div>
					<p className='subTitle fs-14'>Give recruiters a brief overview of the highlights of your career, key achievements, and career goals to help recruiters know your profile better.</p>
				</div>
                <Form action="POST" className="form-cover" onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Control as="textarea" rows={5} name="profile_summary" value={formData.profile_summary} onChange={handleInputChange} placeholder='Type here...' maxLength={1000} autoComplete='off' required />
                        <div className="charLenght fs-14 text-clr2 text-end mt-2">{charRemaining > 0 ? charRemaining : 0} character(s) left</div>
                    </Form.Group>
                    <div className="btn-wrapper d-flex flex-wrap gap-2 mt-4">
                        <Button variant="danger" onClick={closeProfileSummaryModal} className='rounded-pill px-4'>Cancel</Button>
                        <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
