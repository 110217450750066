import React, { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { VerifyOTP } from './VerifyOTP';
import { callAxios } from '../../utils/utils';
import { environment } from '../../environment';
import { toast } from 'react-toastify';

export const Changeemail = ({ editemail, closeEditEmailModal, index,  email, eventType, setProfileData, setIsLoading, setShowBasicDetailsModal, setEditEmail, setEmail_,profileData  }) => {
    // VerifyOtp
    const [verifyotpmodal, setVerifyOTPModal] = useState(false)
    const openverifyModal = () => { setVerifyOTPModal(true) }
    const closeverifyModal = () => { setVerifyOTPModal(false) }
    // VerifyOtp
    const [formData, setFormData] = useState({
        mobile_number: "",
    })
    const generateOTP = () => {
        const digits = '0123456789';
        let otp = '';
        for (let i = 0; i < 4; i++) {
            otp += digits[Math.floor(Math.random() * 10)];
        }
        return otp;
    };
    const setMobileNumber = (e) => {
        const { name, value } = e.target;
        if(name === "mobile_number"){
            setEmail_(value)
        }
        
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const handleEditMobileNumber = (e) => {
        e.preventDefault()
        callAxios(environment.REACT_APP_API_URL_Live+"api/v1/profile/send-email-otp",{email:email,eventType:eventType,profileId:profileData.id},"post").then((response)=>{

            if(response.data.statusCode === 200){
                // closeEditEmailModal()
                openverifyModal()
                toast.success("OTP sent successfully to "+ email, {
                    position: 'bottom-center'
                })
            } else{
                toast.error("Error in sending OTP", {
                    position: 'bottom-center'
                })
            }
            
        })
        
        
    };

    return (
        <>
            <Modal show={editemail} onHide={closeEditEmailModal} centered backdrop="static" backdropClassName="child-modal-backdrop" keyboard={false} dialogClassName='profileCustomModal editMobileNumModal small text-dark mx-auto' contentClassName='rounded-4'>
                {/* <Modal.Body className="px-md-4 py-4 p-lg-5 pt-lg-4"> */}
                <div className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-md-4 py-4 p-lg-5 pt-lg-4'>
                    <div className='heading-wrapper mb-4'>
                        <div className='text-white fs-5 fw-medium'>{eventType === "changeEmail"?"Edit":"Verify"} Email Address</div>
                        <div className='text-clr1'>Recruiters will contact you on this Email Addres</div>
                    </div>

                    <Form action="POST" className="profileCustomModal form-cover" onSubmit={handleEditMobileNumber}>
                        <Form.Group className="mb-4">
                            <Form.Label>Email<span className='text-danger'>*</span></Form.Label>
                            <InputGroup className='rounded-4 overflow-hidden'>
                            {eventType === "changeEmail"?
                                <Form.Control type="email" className='border-start-0 ps-2' placeholder="Enter your email address" autoComplete='off'
                                    name="mobile_number"
                                    value={formData.mobile_number}
                                    onChange={setMobileNumber}
                                    required
                                />
                                :
                                <Form.Control type="email" className='border-start-0 ps-2' placeholder="Enter your email address" autoComplete='off'
                                    name="mobile_number"
                                    value={profileData?.email}
                                    onChange={setMobileNumber}
                                    required
                                />
                            }
                            </InputGroup>
                            <div className='text-clr1 fs-12 mt-1'>We will send the verification code to this email address</div>
                        </Form.Group>

                        <div className="btn-wrapper d-flex flex-wrap gap-2">
                            <Button type='submit' variant='primary' className='rounded-pill px-4'>Send OTP</Button>
                            <Button variant='danger' className='rounded-pill text-decoration-none px-4' onClick={closeEditEmailModal}>Cancel</Button>
                        </div>
                    </Form>
                </div>
                {/* </Modal.Body> */}
            </Modal>
            {eventType.length > 0?
            <VerifyOTP verifyotpmodal={verifyotpmodal} closeverifyModal={closeverifyModal} profileData={profileData} setProfileData={setProfileData} setIsLoading={setIsLoading} setShowBasicDetailsModal={setShowBasicDetailsModal} email={email} eventType={eventType} setEditEmail={setEditEmail} setVerifyOTPModal={setVerifyOTPModal}/>
            :null}
        </>
    )
}
