import React from "react";
import { callAxios, convertFirebaseFormat, convertMilisecondsToTime, getIndex } from "../utils/utils";
import HorizontalBar from "../CircularProgressBar/HorizontalBar";
import { environment } from "../environment";
import { useState } from "react";

// import preloader css
import './LeadsListingPage.css';

const AfterCallCard = ({ isRelevancyloading, index, item, roomParticipant, relavancyList, missedBy, percentage, urlParams, timeStampfor, onlineBDM, Index }) => {
  let participantsArray = roomParticipant.reduce(function (array, participant) {
    if (participant.identity.split("_")[1] === "CAM" || participant.identity.split("_")[1] === "BDM" || participant?.identity.includes("STREAM-")) {
    } else {
      array.push(participant);
    }
    return array;
  }, []);

  if (item.attendantBdmName) {
    participantsArray.unshift({ identity: "90909090-89989_BDM_" })
  }
  if (item.attendantName) {
    participantsArray.unshift({ identity: "90909090-89989_CAM_" })
  }
  const relavancyDataIndex = getIndex(relavancyList, item.roomId);

  //console.log(participantsArray)

  const [active, setActive] = useState(null);
  const getFeedback = async (type, email, bdmEmail, roomId, id) => {
    document.getElementById(roomId + "feedbacklisting").innerHTML = ' <div id="meeting-pointer-Preloader"><div  class="meeting-pointer-spinner" ></div> Please wait ....</div>';
    //console.log(type,email,bdmEmail,roomId,id)
    //   document.getElementById("meeting-pointer-Preloader").style.display="block";
    let emailFeedback;
    if (type === "CAM") {
      emailFeedback = email;
    } else {
      emailFeedback = bdmEmail;
    }
    let feedbackListing = '';
    setActive(id);
    //console.log(email,roomId)
    if(type ==="CAM" || type === "BDM"){
      const getFeedback = await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/cam-bdm-feedback/get-feedback', { email: emailFeedback, roomId:roomId,type:type }, "post");
      //console.log(getFeedback.data)
    //   document.getElementById("meeting-pointer-Preloader").style.display="none";
      if(getFeedback.data.length > 0){
        getFeedback.data.map((feedback,index) =>{
          feedbackListing += '<div class="qna-wrapper bg-dark bg-opacity-75 rounded-1 p-2 mb-2"> <div class="question text-warning fs-6">' +feedback.question + ' </div><div class="answer text-light">'+feedback.answer +'</div></div>';
        });
      }
      else {
        feedbackListing = "No feedback found!";
      }
    }

    // let getFeedback = [];
    // if (item?.camFeedback || item?.bdmFeedback) {
    //   if (type === "CAM") {
    //     //console.log(item?.camFeedback.length)
    //     getFeedback = item?.camFeedback ? item?.camFeedback : [];
    //   }
    //   if (type === "BDM") {
    //     getFeedback = item?.bdmFeedback ? item?.bdmFeedback : [];
    //   }
    // }

    // if (getFeedback.length > 0) {
    //   getFeedback.map((feedback, index) => {
    //     feedbackListing += '<div class="qna-wrapper bg-dark bg-opacity-75 rounded-1 p-2 mb-2"> <div class="question text-warning fs-6">' + feedback.question + ' </div><div class="answer text-light">' + feedback.answer + '</div></div>';
    //   });
    // }
    // else {
    //   feedbackListing = "No feedback found!";
    // }
    document.getElementById(roomId + "feedbacklisting").innerHTML = feedbackListing;
  }

  const getNotReleventQuestionAnswer = async (roomId, email, id2, itemN) => {
    document.getElementById(roomId + "feedbacklisting").innerHTML = ' <div id="meeting-pointer-Preloader"><div  class="meeting-pointer-spinner" ></div> Please wait ....</div>';
    // document.getElementById("meeting-pointer-Preloader").style.display="block";
    //console.log(roomId,email,itemN)
    setActive(id2);
    let relevancyQAns = '';
    const notRelevantQuestionAns = await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/questions/get-relevancy-question-answer', { email: email, roomId: roomId }, "post");
    //console.log(notRelevantQuestionAns.data)
    // document.getElementById("meeting-pointer-Preloader").style.display="none";
    if (notRelevantQuestionAns.data.length > 0) {
      notRelevantQuestionAns.data.map((notRelevant, index) => {
        // relevancyQAns += '<div class="qna-wrapper bg-dark bg-opacity-75 rounded-1 p-2 mb-2"> <div class="question text-warning">' +notRelevant.question + ' </div><div class="answer text-white text-opacity-75"><span class="text-info">'+notRelevant.answer +'</span> ['+notRelevant.description+']</div></div>';
        relevancyQAns += '<div class="qna-wrapper bg-dark bg-opacity-75 rounded-1 p-2 mb-2"> <div class="question text-warning">' + notRelevant.question + ' </div><div class="answer text-white text-opacity-75"><div class="badge-wrapper"> <span class="badge rounded-pill text-bg-danger text-uppercase px-3">' + notRelevant.answer + '</span><div class="relevancy-desc"> ' + notRelevant.description + '</div></div></div></div>';
      });
    }
    // else {
    //   relevancyQAns = "Relevancy not found!";
    // }

    document.getElementById(roomId + "feedbacklisting").innerHTML = relevancyQAns;
  }

  const getReleventQuestionAnswer = async (roomId, email, id2, itemN) => {
    document.getElementById(roomId + "feedbacklisting").innerHTML = ' <div id="meeting-pointer-Preloader"><div  class="meeting-pointer-spinner" ></div> Please wait ....</div>';
    //  console.log(roomId,email,itemN)
    setActive(id2);
    let relevancyQAns = '';
    const RelevantQuestionAns = await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/questions/get-relevancy-question-answer', { email: email, roomId: roomId }, "post");
    //console.log(RelevantQuestionAns.data)
    // document.getElementById("meeting-pointer-Preloader").style.display="none";
    if (RelevantQuestionAns.data.length > 0) {
      RelevantQuestionAns.data.map((Relevant, index) => {
        // relevancyQAns += '<div class="qna-wrapper bg-dark bg-opacity-75 rounded-1 p-2 mb-2"> <div class="question text-warning">' +Relevant.question + ' </div><div class="answer text-white text-opacity-75"><span class="text-info">'+Relevant.answer +'</span> ['+Relevant.description+']</div></div>';
        relevancyQAns += '<div class="qna-wrapper bg-dark bg-opacity-75 rounded-1 p-2 mb-2"> <div class="question text-warning">' + Relevant.question + ' </div><div class="answer text-white text-opacity-75"><div class="badge-wrapper"> <span class="badge rounded-pill text-bg-success text-uppercase px-3">' + Relevant.answer + '</span><div class="relevancy-desc"> ' + Relevant.description + '</div></div></div></div>';
      });
    }
    else {
      relevancyQAns = "Relevancy not found!";
    }

    document.getElementById(roomId + "feedbacklisting").innerHTML = relevancyQAns;
  }

  const resetAllWindow = (roomId) => {
    setActive(null);
    document.getElementById(roomId + "feedbacklisting").innerHTML = '';
  }

  return (
    <div className="info-card rounded-2 mt-3" key={index}>
      {/*urlParams.get("source") ?
        <div className="lead-source bg-secondary fs-6 d-flex justify-content-between gap-3 text-nowrap overflow-auto text-light rounded-top p-3">
            {urlParams.get("device") ?
            <div className="text-capitalize">Lead From: <span className="fw-normal">{urlParams.get("device") === "c"? "Desktop":"Mobile"}</span></div>
            :null }

              {urlParams.get("source")?
            <div className="text-capitalize">Lead Source: <span className="fw-normal">{urlParams.get("source")}</span></div>
            :null }
            
            {urlParams.get("medium") ?
            <div className="text-capitalize">Medium: <span className="fw-normal text-uppercase">{urlParams.get("medium")}</span></div>
            :null } 
            
            {urlParams.get("campaign")?
            <div className="text-capitalize">Lead Campaign: <span className="fw-normal">{urlParams.get("campaign").replace(/_/g, ' ')}</span></div>
            : null }

          {urlParams.get("adgroup")?
            <div className="text-capitalize">Lead Ad Group: <span className="fw-normal">{urlParams.get("adgroup").replace(/_/g, ' ')}</span></div>
          : null }
        </div>
      : */}

      {urlParams.get("adgroup") ?
        <div className="lead-source bg-secondary fs-6 d-flex justify-content-between gap-3 text-nowrap overflow-auto text-light rounded-top p-3">
          <div className="text-capitalize">
            Expected Need: <span className="fw-normal"> {urlParams.get("adgroup").replace(/_/g, " ")}</span>
          </div>
        </div>
        :
        <div className="lead-source bg-secondary fs-6 d-flex justify-content-between gap-3 text-nowrap overflow-auto text-light rounded-top p-3">
          <div className="text-capitalize">
            Lead Source: <span className="fw-normal"> Organic</span>
          </div>
        </div>
      }
      {/* } */}
      <div className="info-card-details overflow-auto p-3">
        <div className="row mb-lg-2">
          <div className="col-lg-6">
            {(item.name) ? <div className="client-name text-capitalize">{item.name}</div> : ''}
            {item.timeStamp ? <div className="date-time-state">{convertFirebaseFormat(item.timeStamp)}</div> : null}
          </div>
          <div className="col-lg-6 text-lg-end">
            {item?.missedBy ?
              <div className="text-light mb-2 ">When this Lead arrived, <span className="text-warning text-capitalize">{item.missedBy.length} BDMs</span> were online in total.</div>
              : null}
          </div>
        </div>

        <div className="row gx-lg-0 mb-3">
          <div className="col-lg-4 pe-lg-3">
            <div className="creted-status mb-2">
              {item.callStatus == 1 ? (
                <div className="d-flex align-items-center total-participants active text-capitalize lh-1">
                  <span>
                    <i className="bi bi-camera-video me-2"></i>
                  </span>
                  Created
                </div>
              ) : (
                <div className="d-flex align-items-center total-participants text-capitalize lh-1">
                  <span>
                    <i className="bi bi-camera-video me-2"></i>
                  </span>{" "}
                  Not Yet Created
                </div>
              )}
              {item.phone ?
                <div className="d-flex align-items-center mob-number text-capitalize lh-1 mt-2">
                  <span>
                    <i className="text-warning bi bi-phone fs-5"></i>
                  </span>
                  Number Received
                </div>
                : null}
            </div>
            <div className="total-participants">
              {item?.participants && item?.participants?.length - 1 > 0 ? (
                <div className="d-flex align-items-center total-participants active text-capitalize lh-1">
                  <span>
                    <i className="bi bi-people-fill me-2"></i>
                  </span>{" "}
                  Total Participants{" "}
                  {item?.participants ? item?.participants?.length - 1 : 0}
                </div>
              ) : (
                <div className="d-flex align-items-center total-participants text-capitalize lh-1">
                  <span>
                    <i className="bi bi-people-fill me-2"></i>
                  </span>{" "}
                  Total Participants 0
                </div>
              )}
            </div>
            <div className="client-progress-status mt-2">
              <div className="progressbar-label text-white text-opacity-75 small mb-1">Client's Form Filling Status</div>
              <div class="progress">
                <HorizontalBar percentage={percentage} />
              </div>
            </div>

            <div className="time-sec mt-2">
              {item.timeStamp ?
                <div className="time-state d-flex gap-2 justify-content-between">Client Started Filling Form AT: <span className="text-nowrap">{item.timeStamp ? convertMilisecondsToTime(item.timeStamp.seconds, item.timeStamp.nanoseconds) : ""}</span></div>
              : null}

              {item.camJoinedAt ?
                <div className="time-state d-flex gap-2 justify-content-between">CAM Joined AT: <span className="text-nowrap">{item.camJoinedAt ? convertMilisecondsToTime(item.camJoinedAt.seconds, item.camJoinedAt.nanoseconds) : ""}</span></div>
              : null}

              {item.clientSubmitTime ?
                <div className="time-state d-flex gap-2 justify-content-between">Client Submitted Form AT: <span className="text-nowrap">{item.clientSubmitTime ? convertMilisecondsToTime(item.clientSubmitTime.seconds, item.clientSubmitTime.nanoseconds) : ""}</span></div>
              : null}

              {item.camClickedMobileButtonTime ? (
                <div className="time-state d-flex gap-2 justify-content-between">
                  CAM Contacted Client's Mobile AT:{" "}
                  <span className="text-nowrap">
                    {item.camClickedMobileButtonTime
                      ? convertMilisecondsToTime(
                        item.camClickedMobileButtonTime.seconds,
                        item.camClickedMobileButtonTime.nanoseconds
                      )
                      : ""}
                  </span>
                </div>
              ) : null}
              {item.CallStatus ? (
                <div className="time-state d-flex gap-2 justify-content-between">
                  Client's Mobile Call Status:{" "}
                  <span className="text-nowrap">
                    {item.CallStatus ? item.CallStatus : ""}
                  </span>
                </div>
              ) : null}
              {item.clientJoinedLiveTime ?
                <div className="time-state d-flex gap-2 justify-content-between">Client Click On Join Live Button AT: <span className="text-nowrap">{item.clientJoinedLiveTime ? convertMilisecondsToTime(item.clientJoinedLiveTime.seconds, item.clientJoinedLiveTime.nanoseconds) : ""}</span></div>
              : null}

              {item.clientLeftTime ?
                <div className="time-state d-flex gap-2 justify-content-between">Client Left Video Room AT: <span className="text-nowrap">{item.clientLeftTime ? convertMilisecondsToTime(item.clientLeftTime.seconds, item.clientLeftTime.nanoseconds) : ""}</span></div>
              : null}

              {item.camLeftAt ?
                <div className="time-state d-flex gap-2 justify-content-between">CAM LEFT Video Room AT: <span className="text-nowrap">{item.camLeftAt ? convertMilisecondsToTime(item.camLeftAt.seconds, item.camLeftAt.nanoseconds) : ""}</span></div>
              : null}
            </div>
          </div>
          <div className="col-lg-4 mt-3 mt-lg-0">
            <div className="tab-sec-cover h-100">
              <ul className="nav nav-pills d-flex" id="pills-tab" role="tablist">
                <li key={"oncall-" + item.roomId} className="nav-item" role="presentation">
                  <button className="btn w-100 h-100 active" id={`pills-home-tab${item.roomId}`} data-bs-toggle="pill" data-bs-target={`#pills-home${item.roomId}`} type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => { resetAllWindow(item.roomId) }}>On Call</button>
                </li>
                <li key={"relevant-key-" + item.roomId} className="nav-item" role="presentation">
                  <button className="btn w-100 h-100" id={`pills-profile-tab${item.roomId}`} data-bs-toggle="pill" data-bs-target={`#pills-profile${item.roomId}`} type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => { resetAllWindow(item.roomId) }}>Relevant To</button>
                </li>
                <li key={"not-relevant-key-" + item.roomId} className="nav-item" role="presentation">
                  <button className="btn w-100 h-100" id={`pills-contact-tab${item.roomId}`} data-bs-toggle="pill" data-bs-target={`#pills-contact${item.roomId}`} type="button" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={() => { resetAllWindow(item.roomId) }}>Not Relevant To</button>
                </li>
              </ul>

              <div className="tab-content text-body" id="pills-tabContent">
                <div className="tab-pane fade show active" id={`pills-home${item.roomId}`} role="tabpanel" aria-labelledby={`pills-home-tab${item.roomId}`} tabIndex="0">
                  {/* BDM on Call */}
                  {
                    participantsArray && participantsArray.length > 0 ?
                      participantsArray.map((participant, id) => (

                        <div className={`user-details mt-1 ${id === active ? 'selected' : ''}`} key={id} onClick={() => getFeedback(participant.identity.split("_")[1], item.attendantEmail, item.attendantBdmEmail, item.roomId, id)}>
                          <div className="user-name fw-medium">
                            {
                              participant.identity.includes("+17862041697-") || participant.identity.includes("_CL_") ? item.name
                                : participant.identity.split("_")[1] === "CAM" ? item.attendantName
                                  : participant.identity.split("_")[1] === "BDM" ? item.attendantBdmName
                                    : participant.identity.split("_")[2]
                            }
                          </div>
                          <div className="d-flex flex-wrap text-primary text-nowrap gap-2 small fw-medium">
                            <div className="label text-uppercase">{participant.identity.includes("+17862041697-") || participant.identity.includes("_CL_") ? "CL" : participant.identity.split("_")[1]}</div>
                            {participant.identity.split("_")[1] === "CAM" ?
                              <div className="date-time-state">{convertFirebaseFormat(item.camJoinedAt)}</div>
                              : participant.identity.split("_")[1] === "BDM" ?
                                <div className="date-time-state">{convertFirebaseFormat(item.bdmJoinedAt)}</div>
                                : participant.identity.split("_")[1] === "CL" || participant.identity.includes("+17862041697-") ? (
                                  <>
                                    {item.clientJoinedTime ? participant.identity.includes("+17862041697-") ? <div className="date-time-state">Via Mobile</div> : <div className="date-time-state"> {convertFirebaseFormat(item.clientJoinedTime)}</div> : null}
                                  </>
                                )
                                  :
                                  // <div className="date-time-state">{participant.identity}</div>
                                  null
                            }
                          </div>
                          {/* <div className="small text-success text-capitalize fw-medium">Via <span className="text-uppercase">cam</span> shared link</div> */}
                        </div>

                      )) : <div className="user-name fw-medium text-center text-white p-2">Participants not found!</div>}
                </div>

                <div className="tab-pane fade" id={`pills-profile${item.roomId}`} role="tabpanel" aria-labelledby={`pills-profile-tab${item.roomId}`} tabIndex="0">
                  {/* Lead also relevant to */}
                  {
                    item?.relevant && item?.relevant?.length > 0 ? (
                      item?.relevant.map((itemRe, idTab) => (
                        <div className={`user-details mt-1 ${idTab === active ? 'selected' : ''}`} key={idTab} onClick={() => getReleventQuestionAnswer(item.roomId, itemRe.email, idTab, itemRe)}>
                          <div className="user-name fw-medium">{itemRe.display_name}</div>
                          <div className="d-flex flex-wrap text-primary text-nowrap gap-2 small fw-medium">
                            <div className="label text-uppercase">BDM</div>
                            <div className="date-time-state">{timeStampfor}</div>
                          </div>
                          {/* <div className="small text-success text-capitalize fw-medium">Via <span className="text-uppercase">cam</span> shared link</div> */}
                        </div>
                      ))
                    )
                      :
                      (<div className="user-name fw-medium text-center text-white p-2">Relevany not found!</div>)}
                </div>

                <div className="tab-pane fade" length={relavancyList[relavancyDataIndex]?.notRelevant.length} id={`pills-contact${item.roomId}`} role="tabpanel" aria-labelledby={`pills-contact-tab${item.roomId}`} tabIndex="0">
                  {/* Lead not relevant to */}
                  {
                    item?.notRelevant && item?.notRelevant?.length > 0 ?
                      item?.notRelevant.map((itemN, index) => (
                        <div className={`user-details mt-1 ${index === active ? 'selected' : ''}`} key={index} onClick={() => getNotReleventQuestionAnswer(item.roomId, itemN.email, index, itemN)}>
                          <div className="user-name fw-medium">{itemN.display_name}</div>
                          <div className="d-flex flex-wrap text-primary text-nowrap gap-2 small fw-medium">
                            <div className="label text-uppercase">BDM</div>
                            <div className="date-time-state">{timeStampfor}</div>
                          </div>
                        </div>
                      ))
                      : <div className="user-name fw-medium text-center text-white p-2">Relevany not found!</div>}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mt-3 mt-lg-0">
            <div className="meeting-pointer-sec h-100 p-2">
              <div className="text-white text-opacity-75 text-center mb-1">Meeting Pointer</div>
              <div className="qna-box-wrapper overflow-auto" style={{ maxHeight: "280px" }} id={item.roomId + "feedbacklisting"}>
              </div>
            </div>
          </div>
        </div>

        <div className="description-sec mb-3">
          {(item.description) ?
            <div className="requrement-label text-white text-opacity-75 mb-1">Requirement Details</div>
            :
            <div className="requrement-label text-danger">Client didn't fill the requirement yet</div>
          }
          {(item.description) ?
            <div className="client-requrement overflow-auto">
              {(item.description) ? item.description : null}
            </div>
            : null}
        </div>

        <div className="cam-wrapper">
          {/* Call missed by */}
          {missedBy && missedBy.length > 0 && item?.description ?
            <div className="d-flex flex-wrap align-items-center gap-2">
              <div className="label text-nowrap text-uppercase fw-semibold text-danger">Call Missed By:</div>
              <div className="d-flex flex-wrap text-nowrap gap-2">
                {missedBy.map((missedBdm, index) => {
                  return (<button className="btn btn-danger text-capitalize">{missedBdm.name}</button>)
                })
                }
              </div>
            </div>
            : null}
        </div>
      </div>
    </div>
  )
}

export default AfterCallCard;