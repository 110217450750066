import React, { useEffect, useState } from "react";
import { database, auth } from "../firebaseConfig";
import {
    collection,
    doc,
    setDoc,
    query,
    where,
    onSnapshot,
    orderBy,
    updateDoc,
    serverTimestamp,
} from "firebase/firestore";
import notificationSound from "../static/Notification.mp3";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
    setIdentity,
    setRoomId,
    setFormId,
    setUserInfo,
} from "../store/actions";
import axios from "axios";

import { useNavigate } from "react-router-dom";

//import { db } from '../../../firebase/firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import Header from "../Header/Header";

// api call import
import { callAxios, getIndex } from '../utils/utils';

// env import
import { environment } from '../environment';

// convertMilisecondsToTime conversion
import { convertMilisecondsToTime } from "../utils/utils";

// BDMCAM list import

const MeetingDetailsPage = (props) => {

    const {
        setRoomIdAction,
        setIdentityAction,
        roomId,
        setFormIdAction,
        setLoggedinUser,
    } = props;
    const [user, loading, error] = useAuthState(auth);

    let navigate = useNavigate();

    const [allData, setAllDate] = useState([]);
    const [audio, setAudio] = useState(new Audio(notificationSound));
    const [isNotify, setNotify] = useState(false);
    const [relavancyList, setRelavancyList] = useState([]);

    const [onlineBDM, setOnlineUsers] = useState([]);

    var startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    var endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
    const queryConstraints = [];
    queryConstraints.push(where('timeStamp', '>=', startOfToday));
    queryConstraints.push(where('timeStamp', '<=', endOfToday));
    queryConstraints.push(where('callStatus', '==', 1));
    queryConstraints.push(orderBy('timeStamp', 'desc'));
    const q = query(collection(database, environment.REACT_APP_FIREBASE_DB), ...queryConstraints);

    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
            const userInfo = {
                uid: user?.uid,
                email: user?.email,
                displayName: user?.displayName,
                accessToken: user?.accessToken,
                idToken: user?.idToken,
            };
            // saveUserLogged(userInfo);
            setLoggedinUser(userInfo);
        }
    }, [user, loading]);


    //console.log(allData)
    const vlisting = allData.map((item, index) => {
        const missedBy = [];

        const Index = getIndex(onlineBDM, item.roomId);
        //console.log(Index, item.roomId)
        onlineBDM[Index]?.names.toString().split(",").map((callMissed, index) => {
            if (item.attendantBdmName !== callMissed) {
                missedBy.push(callMissed)
            }
        })


        const relavancyDataIndex = getIndex(relavancyList, item.roomId);


        // console.log(missedBy)
        //console.log("index is:", relavancyDataIndex)
        //console.log(relavancyList[relavancyDataIndex]?.roomId);
        const ppcPramUrl = item.ppcurl.split("?")[1];
        const urlParams = new URLSearchParams(ppcPramUrl);
        //console.log(urlParams.get("source"))
        // let total = 0;
        // total += item.name !== "" ? 1 : 0;
        // total += item.email !== "" ? 1 : 0;
        // total += item.phone !== "" ? 1 : 0;
        // total += item.companyweb !== "" ? 1 : 0;
        // total += item.projduration !== "" ? 1 : 0;
        // total += item.description !== "" ? 1 : 0;

        // const percentage = parseFloat(total * 100 / 6).toFixed(0);

        const fireBaseTime = new Date(
            item.timeStamp.seconds * 1000 + item.timeStamp.nanoseconds / 1000000
        );

        const dateNew = fireBaseTime.toDateString();
        const atTime = fireBaseTime.toLocaleTimeString();
        let timeStampfor = dateNew + " " + atTime;
        return (
            <>
                <div className="info-card rounded-2 mt-3" key={index}>
                    {urlParams.get("source") ?
                        <div className="lead-source bg-primary fs-6 d-flex justify-content-between gap-3 text-nowrap overflow-auto text-light rounded-top p-3">
                            {urlParams.get("device") ?
                                <div className="fw-semibold text-capitalize">Lead From: <span className="fw-normal">{urlParams.get("device") === "c" ? "Desktop" : "Mobile"}</span></div>
                                : null}

                            {urlParams.get("source") ?
                                <div className="fw-semibold text-capitalize">Lead Source: <span className="fw-normal">{urlParams.get("source")}</span></div>
                                : null}

                            {urlParams.get("medium") ?
                                <div className="fw-semibold text-capitalize">Medium: <span className="fw-normal text-uppercase">{urlParams.get("medium")}</span></div>
                                : null}

                            {urlParams.get("campaign") ?
                                <div className="fw-semibold text-capitalize">Lead Campaign: <span className="fw-normal">{urlParams.get("campaign").replace(/_/g, ' ')}</span></div>
                                : null}

                            {urlParams.get("adgroup") ?
                                <div className="fw-semibold text-capitalize">Lead Ad Group: <span className="fw-normal">{urlParams.get("adgroup").replace(/_/g, ' ')}</span></div>
                                : null}
                        </div>
                        :
                        <div className="lead-source bg-primary fs-6 d-flex justify-content-between gap-3 text-nowrap overflow-auto text-light rounded-top p-3">
                            <div className="fw-semibold text-capitalize">Lead Source: <span className="fw-normal">Organic</span></div>
                        </div>
                    }
                    <div className="info-card-details overflow-auto p-3">
                        <div className="row mb-3">
                            <div className="col-lg-6">
                                <div className="description-sec">
                                    {(item.name) ? <div className="client-name text-capitalize mb-1">{item.name}</div> : ''}

                                    {(item.description) ?
                                        <div className="requrement-label text-white text-opacity-75 mb-1">Requirement Details</div>
                                        :
                                        <div className="requrement-label text-danger mb-1">Client didn't fill the requirement yet</div>
                                    }
                                    {(item.description) ?
                                        <div className="client-requrement overflow-auto" style={{ maxHeight: '100px' }}>
                                            {(item.description) ? item.description : null}
                                        </div>
                                        : null}
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3 mt-lg-0">
                                <div className="time-sec">
                                    {item.timeStamp ?
                                        <div className="time-state d-flex gap-2 justify-content-between">Client Started Filling Form AT: <span className="text-nowrap">{item.timeStamp ? convertMilisecondsToTime(item.timeStamp.seconds, item.timeStamp.nanoseconds) : ""}</span></div>
                                        : null}

                                    {item.camJoinedAt ?
                                        <div className="time-state d-flex gap-2 justify-content-between">CAM Joined AT: <span className="text-nowrap">{item.camJoinedAt ? convertMilisecondsToTime(item.camJoinedAt.seconds, item.camJoinedAt.nanoseconds) : ""}</span></div>
                                        : null}

                                    {item.clientSubmitTime ?
                                        <div className="time-state d-flex gap-2 justify-content-between">Client Submitted Form AT: <span className="text-nowrap">{item.clientSubmitTime ? convertMilisecondsToTime(item.clientSubmitTime.seconds, item.clientSubmitTime.nanoseconds) : ""}</span></div>
                                        : null}

                                    {item.clientJoinedLiveTime ?
                                        <div className="time-state d-flex gap-2 justify-content-between">Client Click On Join Live Button AT: <span className="text-nowrap">{item.clientJoinedLiveTime ? convertMilisecondsToTime(item.clientJoinedLiveTime.seconds, item.clientJoinedLiveTime.nanoseconds) : ""}</span></div>
                                        : null}

                                    {item.clientLeftTime ?
                                        <div className="time-state d-flex gap-2 justify-content-between">Client Left Video Room AT: <span className="text-nowrap">{item.clientLeftTime ? convertMilisecondsToTime(item.clientLeftTime.seconds, item.clientLeftTime.nanoseconds) : ""}</span></div>
                                        : null}

                                    {item.camLeftAt ?
                                        <div className="time-state d-flex gap-2 justify-content-between">CAM LEFT Video Room AT: <span className="text-nowrap">{item.camLeftAt ? convertMilisecondsToTime(item.camLeftAt.seconds, item.camLeftAt.nanoseconds) : ""}</span></div>
                                        : null}
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3 mt-lg-0">
                                <div className="action-sec">
                                    <div className="date-time-state mb-1">{timeStampfor}</div>
                                    {item.attendantName ?
                                        <div className="cam-wrapper">
                                            <div className="label text-warning text-uppercase fw-medium">Cam on call</div>
                                            <button className="btn btn-warning btn-custom btn-next rounded-1 mt-1">{item.attendantName}</button>
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>

                        <div className="cam-wrapper">
                            {missedBy.length > 0 ?
                                <div className="fs-6 fw-medium mb-3 text-light">When this Lead arrived, <span className="text-warning text-capitalize">{missedBy?.length} BDMs</span> were online in total.</div>
                                : null}
                            {/* BDM on Call */}

                            {item.attendantBdmName ?
                                <div className="d-flex flex-column flex-xl-row align-items-xl-center gap-2 mb-3">
                                    <div className="label text-nowrap text-uppercase fw-semibold text-primary">BDM on call:</div>
                                    <div className="d-flex flex-wrap text-nowrap gap-2">
                                        <button className="btn btn-primary text-capitalize"> {item.attendantBdmName}</button>
                                    </div>
                                </div>
                            : null}

                            {/* Lead also relevant to */}

                            {relavancyList[relavancyDataIndex]?.relevantAlso && relavancyList[relavancyDataIndex]?.relevantAlso?.length > 0 ?
                                <div className="d-flex flex-column flex-xl-row align-items-xl-center gap-2 mb-3">
                                    <div className="label text-nowrap text-uppercase fw-semibold text-success">Lead also relevant to:</div>
                                    <div className="d-flex flex-wrap text-nowrap gap-2">

                                        {relavancyList[relavancyDataIndex]?.relevantAlso.map((item, index) => {
                                            return (<button className="btn btn-success text-capitalize">{item.display_name}</button>)
                                        })
                                        }

                                    </div>
                                </div>
                            : null}

                            {/* Lead not relevant to */}
                            {relavancyList[relavancyDataIndex]?.notRelevant && relavancyList[relavancyDataIndex]?.notRelevant?.length > 0 ?
                                <div className="d-flex flex-column flex-xl-row align-items-xl-center gap-2 mb-3">
                                    <div className="label text-nowrap text-uppercase fw-semibold text-secondary">Lead not relevant to:</div>
                                    <div className="d-flex flex-wrap text-nowrap gap-2">
                                        {relavancyList[relavancyDataIndex]?.notRelevant.map((item, index) => {
                                            return (<button className="btn btn-secondary text-capitalize">{item.display_name}</button>)
                                        })
                                        }

                                    </div>
                                </div>
                            : null}

                            {/* Call missed by */}
                            {missedBy.length > 0 ?
                                <div className="d-flex flex-column flex-xl-row align-items-xl-center gap-2 mb-1">
                                    <div className="label text-nowrap text-uppercase fw-semibold text-danger">Call missed by:</div>
                                    <div className="d-flex flex-wrap text-nowrap gap-2">
                                        {missedBy.map((missedBdm, index) => {
                                            return (<button className="btn btn-danger text-capitalize">{missedBdm}</button>)
                                        })}
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            </>
        );
    });

    const isLoggedIn = useSelector((state) => state.isLoggedIn);
    const userInfo = useSelector((state) => state.userInfo);

    useEffect(() => {
        const getrelavanceData = async () => {

            const relavanyList = await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/questions/get-relavancy-list', {}, "post");
            setRelavancyList(relavanyList.data)
        }
        getrelavanceData();

        const getOnlineUsers = async () => {

            const onlineBdm = await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/questions/get-online-bdm-cam', { type: "bdm" }, "post");
            setOnlineUsers(onlineBdm.data)
        }
        getOnlineUsers();
        onSnapshot(q, (querySnapshot) => {
            let rommIds = [];
            let forms = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                data["id"] = doc.id;
                if (data.formstatus > 0 && data.attendantBdmName) {
                    forms.push({ ...doc.data(), id: doc.id });
                    // rommIds.push(data?.roomId)
                }
                // if (data?.RoomStatus === 'in-progress' && !data?.isNotified) {
                //     playSoundOnce(doc.id);
                // }
            });
            setAllDate(forms);


        });


    }, []);

    useEffect(() => {

    }, [allData])


    // const playSoundOnce = async (dataID) => {
    //     const formRef = doc(database, environment.REACT_APP_FIREBASE_DB, dataID);
    //     await updateDoc(formRef, {
    //         isNotified: true
    //     });
    //     audio.play();

    // };

    // const playSound = () => {
    //     audio.play();
    // };
    // const handleHostMeeting = async (dataID, nameValue, roomId) => {
    //     setIdentityAction(nameValue);
    //     setRoomIdAction(roomId);
    //     axios.post(environment.REACT_APP_API_URL_Live + 'api/v1/calls/record-calls-logs', { email: userInfo?.email, joinedCount: 1, type: "joined", roomId: roomId }, "post");
    //     setFormIdAction(dataID);
    //     navigate("/bdmroom", { state: { id: 0 } });

    //     setDoc(doc(database, "playerstreamer", dataID), {
    //         playerStreamerID: "",
    //         roomId: roomId,
    //         isBdmJoined: true,
    //         timeStamp: serverTimestamp(),
    //     })
    //         .then(() => {
    //             //console.log("Data Sent");
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    //     const formRef = doc(database, environment.REACT_APP_FIREBASE_DB, dataID);
    //     await updateDoc(formRef, {
    //         playerStreamerID: "",
    //         roomId: roomId,
    //         callStatus: 1,
    //         attendantBdmName: userInfo?.displayName,
    //         attendantBdmEmail: userInfo?.email,
    //         streamerStatus: 1,
    //         bdmJoinedAt: serverTimestamp()
    //     });
    // };

    // let connectedBdm = 0;
    // let notConnectedBdm = 0;
    // let joinedWithClient = 0;
    // let notJoinedWithClient = 0;

    // for (let i = 0; i < allData.length; i++) {
    //     if (allData[i].bdmJoinedAt) {
    //         connectedBdm += 1;
    //     }
    //     if (allData[i].camJoinedAt && !allData[i].bdmJoinedAt) {

    //         notConnectedBdm += 1;
    //     }
    //     if (allData[i]?.clientLeftTime && allData[i].bdmJoinedAt) {
    //         joinedWithClient += 1;
    //     }
    //     if (allData[i]?.participants?.length > 2 && !allData[i].bdmJoinedAt) {
    //         notJoinedWithClient += 1;
    //     }

    // }
    // end graph data implementation 

    return (
        <>
            <div className="meeting-details-page py-4">
                <div className="container">

                    <div className="row align-items-center mb-3">
                        <div className="col-md-8">
                            <div className="main-heading text-white text-opacity-75">Meeting Details</div>
                        </div>
                        <div className="col-md-4 text-md-end">
                        </div>
                    </div>

                    <div className="live-listing-wrapper rounded-3">
                        <div>{vlisting}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setIdentityAction: (identity) => dispatch(setIdentity(identity)),
        setRoomIdAction: (id) => dispatch(setRoomId(id)),
        setFormIdAction: (id) => dispatch(setFormId(id)),
        setLoggedinUser: (user) => dispatch(setUserInfo(user)),
    };
};

const mapStoreStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
)(MeetingDetailsPage);
