import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import WarningModal from '../Components/Warningmodal';
import { generateYearOptions, generateYearOptionsFuture10Years } from '../../utils/utils';

export const HigherEducation = ({ closeEducationModal, edutype, addEducationData, sendeducationData, sendactiontype, profileId }) => {
    const [showfield, setShowfield] = useState(false)
    const [showUniv, setUniv] = useState(false)
    // const getUserId = process.env.REACT_APP_USER_ID
    const [showModal, setShowModal] = useState(false);
    const [textmessage, setTextmessage] = useState('');
    const [deleteEducation, setDeleteEducation] = useState('')
    const getdataandmonth = (data, yeartype) => {
        const splitdate = data.split('-')
        if(yeartype == 'start'){
            return splitdate[0]
        }
        if(yeartype == 'end'){
            return splitdate[1]
        }
    }

    const [formData, setFormData] = useState({
        board:"", passing_out_year:"", school_medium:"", english_marks:"", math_marks:"", 
        marks: sendeducationData?.marks ? sendeducationData?.marks : "",
        university: sendeducationData?.university ? sendeducationData?.university : "",
        course: sendeducationData?.course ? sendeducationData?.course : "",
        specialization: sendeducationData?.specialization ? sendeducationData?.specialization : "",
        course_type: sendeducationData?.course_type ? sendeducationData?.course_type : "",
        startingYear: sendeducationData?.course_duration ? getdataandmonth(sendeducationData?.course_duration, 'start') : "",
        endingYear: sendeducationData?.course_duration ? getdataandmonth(sendeducationData?.course_duration, 'end') : "",
        grading_system: sendeducationData?.grading_system ? sendeducationData?.grading_system : "",
        profile_id: profileId,
        course_duration: sendeducationData?.university ? sendeducationData?.university : "",
        other_course_name: sendeducationData?.other_course_name ? sendeducationData?.other_course_name : "",
        type: sendactiontype,
        id:sendeducationData?.id ? sendeducationData?.id : "",
    });

    const handleChange = (event) => {
        const getValue = event.target.value
        if(getValue === "Scale 10 Grading System" || getValue === "Scale 4 Grading System" || getValue === "% Marks of 100 Maximum"){
            setShowfield(true)
        }
        if(event.target.name === "grading_system" && getValue === "Course Requires a Pass"){
            setShowfield(false)
        }
        if(event.target.name === "course" && getValue === 'Other'){
           setUniv(true)
        }
        if(event.target.name === "course" && getValue !== 'Other'){
            setUniv(false)
        }
        
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const sendData = (e) => {
        setShowfield(false)
        e.preventDefault()
        formData.profile_id = profileId
        formData.course_duration = formData.startingYear+'-'+formData.endingYear
        if(formData.grading_system == "Course Requires a Pass"){
            formData.marks = ""
        }
        if(formData.course !== "Other"){
            formData.other_course_name = ""
        }
        addEducationData(formData)
        closeEducationModal()
    }

    // deleteducation
    const deleteducation = () => {
        const getId = sendeducationData?.id
        setShowModal(true)
        setTextmessage('Are you sure you want to delete this education?')
        const sendData = {
            "profile_id":profileId ? profileId : '',
            "id":getId, 
            "type":"delete"
        }
        setDeleteEducation(sendData)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }
    // deleteducation

    useEffect(() => {
        // Update formData based on edutype
        if(sendeducationData?.course == "Other"){
            setUniv(true)
        }
        if(sendeducationData?.marks != ''){
            setShowfield(true)
        }
        if (edutype === 'phd') {
            setFormData({ ...formData, education: "Doctorate/PhD" });
        } else if (edutype === 'masters') {
            setFormData({ ...formData, education: "Masters/Post-Graduation" });
        } else if (edutype === 'graduation') {
            setFormData({ ...formData, education: "Graduation/Diploma" });
        }
    }, [edutype, sendeducationData]);

    return (
        <>
          
            <Form action="POST" className="form-cover" onSubmit={sendData}>
                <Form.Group className="mb-3">
                    <Form.Label>Education<span className='text-danger'>*</span></Form.Label>
                    <Form.Select name="Education" disabled value={edutype}>
                        {edutype === 'phd' && 
                            <option value="Doctorate/PhD" hidden>Doctorate/PhD</option>
                        }
                        {edutype === 'masters' && 
                            <option value="Masters/Post-Graduation" hidden>Masters/Post-Graduation</option>
                        }
                        {edutype === 'graduation' && 
                            <option value="Graduation/Diploma" hidden>Graduation/Diploma</option>
                        }
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>University/Institute<span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="search" name="university" value={formData.university} onChange={handleChange} placeholder="Select university/institute" autoComplete='off' required />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Course<span className='text-danger'>*</span></Form.Label>
                    <Form.Select name="course" value={formData.course} onChange={handleChange} required>
                        <option selected hidden value=''>Select course</option>
                        <option value="B.A">B.A</option>
                        <option value="BSC">BSC</option>
                        <option value="BCA">BCA</option>
                        <option value="MCA">MCA</option>
                        <option value="MSC">MSC</option>
                        <option value="M.COM">M.COM</option>
                        <option value="Other">Other</option>
                    </Form.Select>

                    {/* Show if above choosen other */}
                    {showUniv ? <Form.Control type="text" className='mt-2' name="other_course_name" value={formData.other_course_name} onChange={handleChange} placeholder="Enter course" autoComplete='off' /> : ''}
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Specialization<span className='text-danger'>*</span></Form.Label>
                    <Form.Select name="specialization" value={formData.specialization} onChange={handleChange} required>
                        <option selected hidden value=''>Select Specialization</option>
                        <option value="Business Analytics">Business Analytics</option>
                        <option value="Information Technology">Information Technology</option>
                        <option value="Finance">Finance</option>
                        <option value="Marketing">Marketing</option>
                        <option value="Economics">Economics</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label className="mb-2">Course type<span className='text-danger'>*</span></Form.Label>
                    <div className='radio-wrapper'>
                        <Form.Check inline type="radio" className='me-4' id='Fulltime' name="course_type" value="Full Time" checked={formData.course_type === "Full Time"} onChange={handleChange}  label='Full time' required />
                        <Form.Check inline type="radio" className='me-4' id='Parttime' name="course_type" value="Part Time" checked={formData.course_type === "Part Time"} onChange={handleChange} label='Part time' />
                        <Form.Check inline type="radio" className='me-4' id='Correspondence' name="course_type" value="Correspondence/Distance learning" checked={formData.course_type === "Correspondence/Distance learning"} onChange={handleChange} label='Correspondence/Distance learning' />
                    </div>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Course duration<span className='text-danger'>*</span></Form.Label>
                    <div className="d-flex align-items-center gap-2">
                        <div className="flex-fill">
                            <Form.Select name="startingYear" value={formData.startingYear} onChange={handleChange} required>
                                <option selected hidden value=''>Starting year</option>
                                {generateYearOptions().map(year => (
										<option key={year} value={year}>{year}</option>
									))}
                            </Form.Select>
                        </div>
                        <div className='fs-14 fw-semibold'>To</div>
                        <div className="flex-fill">
                            <Form.Select name="endingYear" value={formData.endingYear} onChange={handleChange} required>
                                <option selected hidden value=''>Ending year</option>
                                {generateYearOptionsFuture10Years().map(year => (
										<option key={year} value={year}>{year}</option>
									))}
                            </Form.Select>
                        </div>
                    </div>
                </Form.Group>
                
                <Form.Group className="mb-3">
                    <Form.Label>Grading system<span className='text-danger'>*</span></Form.Label>
                    <Form.Select name="grading_system" value={formData.grading_system} onChange={handleChange}>
                        <option selected hidden value=''>Select grading system</option>
                        <option value="Scale 10 Grading System">Scale 10 Grading System</option>
                        <option value="Scale 4 Grading System">Scale 4 Grading System</option>
                        <option value="% Marks of 100 Maximum">% Marks of 100 Maximum</option>
                        <option value="Course Requires a Pass">Course Requires a Pass</option>
                    </Form.Select>
                </Form.Group>
                
                {showfield ? 
                <Form.Group className="mb-3">
                    <Form.Label>Marks<span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="text" name="marks" value={formData.marks} onChange={handleChange} placeholder="% marks of 100 maximum" maxLength={3} autoComplete='off' required />
                </Form.Group>: "" }

                <div className="btn-wrapper d-flex flex-wrap gap-2 pt-3">
                    <Button variant="danger" onClick={closeEducationModal} className='rounded-pill px-4'>Cancel</Button>
                    <Button type='submit' variant="primary" className='rounded-pill px-4'>Save</Button>
                </div>
            </Form>
        </>
    )
}
